import { trimStart } from 'lodash';
import { useLoggingContext } from '@vaillant-professional-ui/pui-frontend-common';

const correlationIdLabel = 'mproCorrelationId';

const createCorrelationId = () => {
  return ((1 + Math.random()) * 0x10).toString().substring(3, 9);
};

export const buildRedirectUriWithCorrelationParameter = (fallbackPath: string) => {
  let redirectPath = new URLSearchParams(window.location.search).get('redirect') || fallbackPath;
  redirectPath = trimStart(redirectPath, '/');
  const redirectUri = new URL(`${window.location.origin}/${redirectPath}`);
  const correlationId = createCorrelationId();
  redirectUri.searchParams.set(correlationIdLabel, correlationId);
  return { correlationId, redirectUri: redirectUri.toString() };
};

export const useLogCorrelationId = () => {
  const { reportEvent } = useLoggingContext();
  const url = new URL(window.location.href);
  const correlationId = url.searchParams.get(correlationIdLabel);
  if (correlationId) {
    reportEvent('login-comeback', { correlationId });
    url.searchParams.delete(correlationIdLabel);
    window.history.replaceState(null, '', url);
  }
};
