import { I18nKeys } from '@vaillant-professional-ui/pui-frontend-common';
import { EmfDeviceTypeResponse } from '@vaillant-professional-ui/pui-frontend-common/src/api/apis/emf';

export const deviceTypeIntlKey: Record<EmfDeviceTypeResponse, I18nKeys> = {
  BOILER: 'SG_DEVICE_TYPE_BOILER',
  HEATPUMP: 'SG_DEVICE_TYPE_HEATPUMP',
  SOLAR_STATION: 'SG_DEVICE_TYPE_SOLAR_STATION',
  ELECTRIC_AUXILIARY_HEATER: 'SG_DEVICE_TYPE_ELECTRIC_AUXILIARY_HEATER',
  VENTILATION: 'SG_DEVICE_TYPE_VENTILATION',
  UNKNOWN: 'SG_DEVICE_TYPE_UNKNOWN',
};
