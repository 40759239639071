import { styled } from '@vaillant-professional-ui/component-libs-web';
import { Box, BoxProps } from '@mui/material';

const numRowsInGridWithNumElements = (numElements: number) => Math.ceil(numElements / 2);

export const GridContainer = styled(Box)<
  BoxProps & {
    $numChildren: number;
  }
>`
  display: grid;
  column-gap: 0;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(${({ $numChildren }) => numRowsInGridWithNumElements($numChildren)}, 1fr);
    grid-auto-flow: column;
  }
`;

export const GridItem = styled(Box)<
  BoxProps & {
    $numSiblings: number;
  }
>`
  display: flex;
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  margin: 0;
  justify-content: stretch;
  align-items: stretch;
  border-bottom: 1px ${({ theme }) => theme.palette.grey['400']} solid;

  ${({ theme }) => theme.breakpoints.down('md')} {
    &:last-child {
      border-bottom: none;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: ${({ theme }) => theme.spacing(6)};
    padding-right: ${({ theme }) => theme.spacing(6)};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: ${({ theme }) => theme.spacing(6)};
    margin-right: ${({ theme }) => theme.spacing(6)};
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};

    &:last-child:nth-child(even),
    &:nth-child(${({ $numSiblings }) => numRowsInGridWithNumElements($numSiblings)}) {
      border-bottom: none;
    }
  }
`;
