import { DataPoint, useDiagnostics } from '../../../api/apis/diagnostics';
import { DateFormatter } from '../../../libs/hooks';
import { TranslationFnc } from '../../../typings/models';

export type SummaryDataPoint = [
  key:
    | 'centralHeatingWaterPressure'
    | 'modulationLevelPercent'
    | 'centralHeatingFlowTemperature'
    | 'centralHeatingReturnTemperature',
  data: DataPoint[1],
];

type UseSystemSettingsSummary = (
  gatewaySerialNumber: string | null,
  t: TranslationFnc,
  df: DateFormatter,
) => Pick<ReturnType<typeof useDiagnostics>, 'refetch' | 'isLoading' | 'error' | 'dataUpdatedAt'> & {
  data: SummaryDataPoint[] | null;
};

export const useCase: UseSystemSettingsSummary = (gatewaySerialNumber, t, df) => {
  const { data, refetch, isLoading, error, dataUpdatedAt } = useDiagnostics(gatewaySerialNumber ?? '', t, df, {
    enabled: !!gatewaySerialNumber,
  });

  const result = (
    data?.dataPoints
      ? data.dataPoints.filter((element) =>
          [
            'centralHeatingWaterPressure',
            'modulationLevelPercent',
            'centralHeatingFlowTemperature',
            'centralHeatingReturnTemperature',
            'outdoorTemperature',
          ].includes(element[0]),
        )
      : null
  ) as SummaryDataPoint[] | null;
  return { data: result, refetch, isLoading, error, dataUpdatedAt };
};
