import { orderBy } from 'lodash';

import { TranslationFnc } from '../../../../../typings/models';
import { DeviceDetailsResponseTli, TliCircuit } from '../../../../../api/apis/maintenance/v2';
import { createCategory } from '../../../deviceSettings/useCase/model/category';
import { dataPointKeyToTranslationMap } from './translationMap';
import { nonNullable } from '../../../../../util';
import { mapBooleanMeasuredValue, mapEnumMeasuredValue, mapNumericMeasuredValue } from './mapMeasuredValue';

const mapCircuit = (circuit: TliCircuit, t: TranslationFnc) => {
  const circuitPayloadMetadata = { circuitIndex: circuit.index };
  return [
    mapEnumMeasuredValue({ id: 'heatingCircuitType', measuredValue: circuit.type, t }),
    mapEnumMeasuredValue({
      id: 'mixerCircuitTypeExternal',
      action: circuit._actions?.update_mixer_circuit_type_external,
      actionPropertyKey: 'mixerCircuitTypeExternal',
      measuredValue: circuit.mixer_circuit_type_external,
      mutationId: `mixerCircuitTypeExternal.${circuit.index}`,
      payloadMetadata: circuitPayloadMetadata,
      t,
    }),
    mapNumericMeasuredValue({ id: 'heatingCircuitFlowSetpoint', measuredValue: circuit.flow_target_temperature, t }),
    mapNumericMeasuredValue({
      id: 'heatDemandLimitedByOutsideTemperature',
      measuredValue: circuit.heat_demand_limited_by_outside_temperature,
      action: circuit._actions?.update_heat_demand_limited_by_outside_temperature,
      actionPropertyKey: 'heatDemandLimitedByOutsideTemperature',
      mutationId: `heatDemandLimitedByOutsideTemperature.${circuit.index}`,
      payloadMetadata: circuitPayloadMetadata,
      t,
    }),
    {
      title: t(dataPointKeyToTranslationMap['heatCurve']),
      type: 'link' as const,
      id: 'heatCurve' as const,
      metadata: { index: circuit.index },
    },
    mapNumericMeasuredValue({
      id: 'circuitMinFlowTargetTemperature',
      actionPropertyKey: 'minFlowTargetTemperature',
      action: circuit._actions?.update_min_flow_target_temperature,
      measuredValue: circuit.min_flow_target_temperature,
      mutationId: `circuitMinFlowTargetTemperature.${circuit.index}`,
      payloadMetadata: circuitPayloadMetadata,
      t,
    }),
    mapNumericMeasuredValue({
      id: 'circuitMaxFlowTargetTemperature',
      action: circuit._actions?.update_max_flow_target_temperature,
      actionPropertyKey: 'max_flow_target_temperature',
      measuredValue: circuit.max_flow_target_temperature,
      mutationId: `circuitsMaxFlowTargetTemperature.${circuit.index}`,
      payloadMetadata: circuitPayloadMetadata,
      t,
    }),
    mapBooleanMeasuredValue({
      id: 'circuitSetbackModeEnabled',
      action: circuit._actions?.update_setback_mode,
      actionPropertyKey: 'setBackModeEnabled',
      measuredValue: circuit.setback_mode_enabled,
      mutationId: `circuitSetbackModeEnabled.${circuit.index}`,
      payloadMetadata: circuitPayloadMetadata,
      t,
    }),
    mapEnumMeasuredValue({
      id: 'heatingCircuitRoomTemperatureControlMode',
      measuredValue: circuit.room_temperature_control_mode,
      t,
    }),
    mapBooleanMeasuredValue({
      id: 'circuitCoolingAllowed',
      measuredValue: circuit.cooling_allowed,
      mutationId: `circuitCoolingAllowed.${circuit.index}`,
      action: circuit._actions?.update_cooling_allowed,
      actionPropertyKey: 'coolingAllowed',
      payloadMetadata: circuitPayloadMetadata,
      t,
    }),
    mapNumericMeasuredValue({
      id: 'activeCoolingFlowTemperatureMinimumSetpoint',
      action: circuit._actions?.update_cooling_minimal_flow_setpoint,
      actionPropertyKey: 'coolingMinimalFlowSetpoint',
      measuredValue: circuit.active_cooling_flow_temperature_minimum_setpoint,
      mutationId: `activeCoolingFlowTemperatureMinimumSetpoint.${circuit.index}`,
      payloadMetadata: circuitPayloadMetadata,
      t,
    }),
    mapNumericMeasuredValue({ id: 'currentCircuitFlowTemperature', measuredValue: circuit.flow_temperature, t }),
    mapNumericMeasuredValue({
      id: 'heatingCircuitFlowSetpointExcessOffset',
      measuredValue: circuit.flow_target_temperature_excess_offset,
      mutationId: `heatingCircuitFlowSetpointExcessOffset.${circuit.index}`,
      action: circuit._actions?.update_flow_target_temperature_excess_offset,
      actionPropertyKey: 'flowTargetTemperatureExcessOffset',
      payloadMetadata: circuitPayloadMetadata,
      t,
    }),
  ].filter(nonNullable);
};

export const createCircuits = (response: DeviceDetailsResponseTli, t: TranslationFnc) =>
  orderBy(response.circuits, (circuit) => circuit.index, 'asc').map((circuit) =>
    createCategory(t('SG_CIRCUIT_NO', { 0: circuit.index + 1 }), mapCircuit(circuit, t)),
  );
