import React from 'react';
import { Icon66700VgNotification } from '@vaillant-professional-ui/component-libs-web';
import { NotificationsMenuButtonProps } from './NotificationsMenuButton.types';
import { BadgeStyled, IconButtonStyled } from './NotificationsMenu.styles';

const NotificationsMenuButton: React.FC<React.PropsWithChildren<NotificationsMenuButtonProps>> = ({
  numberOfUnreadNotifications = 0,
  onClick,
}) => {
  return (
    <IconButtonStyled data-testid={'openNotifications'} onClick={onClick} size='large'>
      <BadgeStyled color='secondary' badgeContent={numberOfUnreadNotifications}>
        <Icon66700VgNotification height={28} color='common.white' viewBox='0 0 382.675 510.237' />
      </BadgeStyled>
    </IconButtonStyled>
  );
};

export default NotificationsMenuButton;
