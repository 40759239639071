import { groupBy, sortBy } from 'lodash';

import { selectors } from '../../../..';
import { useCustomer } from '../../../api/apis/maintenance/v1';
import { selectF22IconAndTitle } from '../../../api/useCases';
import { DateFormatter, useDateFormatter, useLocalizedDifferenceInDays, useTranslation } from '../../../libs/hooks';
import { getHeatingDeviceType, isSerialNumberUUID } from '../../../selectors/boiler';
import { isOffline } from '../../../selectors/customer';
import { ICustomerDetail, TranslationFnc, TroubleCodes } from '../../../typings/models';
import { nonNullable } from '../../../util';
import { troubleCodeInsightCodeToString } from '../../../util/troubleCodes';

const getTitleDtc = (dtc: TroubleCodes, deviceType: string, t: TranslationFnc) => {
  const titleI18nKey = dtc.type === 'ERROR' ? 'SG_CUSTOMER_BANNER_ERROR' : 'SG_CUSTOMER_BANNER_MAINTENANCE';
  return t(titleI18nKey, {
    dtc: troubleCodeInsightCodeToString(dtc),
    deviceType: deviceType,
  });
};

const getTroubleCodeStatuses = (
  customer: ICustomerDetail,
  deviceType: string,
  showDtc: (dtc: TroubleCodes) => void,

  t: TranslationFnc,
) => {
  const activeTroubleCodes = customer.troubleCodes.filter((dtc) => !dtc.rectified && dtc.type !== 'STATUS');
  const data = sortBy(activeTroubleCodes, 'occurredAt')
    .reverse()
    .map((dtc) => {
      return {
        id: dtc.id,
        iconName: undefined,
        bannerTitle: getTitleDtc(dtc, deviceType, t),
        severity: dtc.type === 'ERROR' ? 'error' : 'warning',
        type: dtc.type,
        open: () => showDtc(dtc),
      };
    });

  const { ERROR: errorStatuses, MAINTENANCE: maintenanceStatuses } = groupBy(data, (v) => v.type);
  return { errorStatuses: errorStatuses ?? [], maintenanceStatuses: maintenanceStatuses ?? [] };
};

const PressureLevels = ['PressureLevel1', 'PressureLevel2', 'PressureLevel3', 'PressureLevel4', 'PressureLevel5'];

type FormatDifference = ReturnType<typeof useLocalizedDifferenceInDays>;

const getPredictionState = (
  customer: ICustomerDetail,
  deviceType: string,
  showF22Prediction: () => void,
  t: TranslationFnc,
  formatDifference: FormatDifference,
) => {
  if (!selectors.customer.hasF22Prediction(customer)) {
    return null;
  }

  const { icon, f22Title } = selectF22IconAndTitle(customer, PressureLevels, t, formatDifference);
  return {
    id: 'f22-prediction',
    iconName: icon,
    bannerTitle: t('SG_CUSTOMER_BANNER_PREDICTION', { deviceType, days: f22Title ?? null }),
    severity: 'warning' as const,
    type: 'PREDICTION',
    open: () => showF22Prediction(),
  };
};

const getOfflineStatus = (customer: ICustomerDetail, t: TranslationFnc, df: DateFormatter) => {
  if (!isOffline(customer)) {
    return null;
  }

  const lastHeartbeat = selectors.customer.getLastHeartbeat(customer);
  const dateTime = lastHeartbeat ? df(lastHeartbeat) : '';

  return {
    id: 'offline-status',
    iconName: 'Disconnected01',
    bannerTitle: t('SG_ALERT_OFFLINE_TITLE'),
    bannerBody: t('SG_ALERT_OFFLINE_BODY', {
      dateTime,
    }),
    severity: 'info' as const,
    type: 'OFFLINE',
    open: undefined,
  };
};

export const transform = (
  customer: ICustomerDetail,
  deviceType: string,
  showDtc: (dtc: TroubleCodes) => void,
  showF22Prediction: () => void,
  getIcon: (iconName: string | undefined) => React.ReactNode,
  t: TranslationFnc,
  df: DateFormatter,
  formatDifference: FormatDifference,
) => {
  const { errorStatuses, maintenanceStatuses } = getTroubleCodeStatuses(customer, deviceType, showDtc, t);
  const offlineStatus = getOfflineStatus(customer, t, df);

  const predictionStatus = getPredictionState(customer, deviceType, showF22Prediction, t, formatDifference);
  return [
    ...errorStatuses,
    ...maintenanceStatuses,
    predictionStatus ? predictionStatus : null,
    offlineStatus ? offlineStatus : null,
  ]
    .filter(nonNullable)
    .map((status) => {
      const { iconName, ...rest } = status;
      return {
        bannerBody: undefined,
        iconName: undefined,
        ...rest,
        icon: getIcon(iconName),
        linkText: rest.open ? t('SG_JOBDETAILS_VIEWDETAILS') : undefined,
      };
    });
};

export const useBanners = (
  boilerSerialNumber: string,
  showDtc: (dtc: TroubleCodes) => void,
  showF22Prediction: () => void,
  getIcon: (iconName: string | undefined) => React.ReactNode,
  /** maximum amount of returned banner entries */
  limit = 2,
  now = new Date(),
) => {
  const t = useTranslation();
  const df = useDateFormatter();
  const formatDifference = useLocalizedDifferenceInDays(now);

  const shouldEnableApiCall = boilerSerialNumber ? !isSerialNumberUUID(boilerSerialNumber) : false;

  const {
    data: detailsData,
    isError,
    isLoading,
  } = useCustomer({
    boilerSerialNumber,
    options: {
      enabled: shouldEnableApiCall,
      refetchInterval: (customer) => (customer && isOffline(customer) ? 60 * 1000 : false),
    },
  });

  const heatingDeviceType = detailsData ? getHeatingDeviceType(detailsData.system.boiler) : null;
  const deviceTypeI18n = t(`SG_DEVICE_TYPE_${heatingDeviceType}`);

  const banners = detailsData
    ? transform(detailsData, deviceTypeI18n, showDtc, showF22Prediction, getIcon, t, df, formatDifference)
    : [];

  return {
    banners: banners.slice(0, limit),
    isError,
    isLoading,
  };
};
