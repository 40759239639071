import * as React from 'react';
import { useSelector } from 'react-redux';
import { api, models, store } from '@vaillant-professional-ui/pui-frontend-common';
import AddCustomer from './AddCustomer';
import { IAddCustomerContainerProps } from './AddCustomer.types';

const AddCustomerContainer: React.FC<React.PropsWithChildren<IAddCustomerContainerProps>> = ({
  boilerSerialNumber,
  closeModal,
}) => {
  const [loyaltyRegistrations, setLoyaltyRegistrations] = React.useState<models.LoyaltyRegistration[] | null>(null);
  const { addCustomer, emailRequired, agreementRequired, localizedAddCustomerResponse, isPending, isError, reset } =
    api.useCases.useAddCustomer();

  const country = useSelector(store.getCountry);
  const { loyaltyRegistrations: apiLoyaltyRegistrations } = api.useCases.useLoyaltyRegistrationsWithPermissions();

  React.useEffect(() => {
    if (!loyaltyRegistrations) {
      setLoyaltyRegistrations(apiLoyaltyRegistrations);
    }
  }, [apiLoyaltyRegistrations, apiLoyaltyRegistrations.length, loyaltyRegistrations]);

  const { userInfo } = api.useCases.useGetUserInfo({ enabled: true });
  const user = userInfo?.user;

  return (
    <AddCustomer
      boilerSerialNumber={boilerSerialNumber}
      closeModal={closeModal}
      contactId={user?.contact?.id}
      loyaltyRegistrations={loyaltyRegistrations || []}
      country={country}
      agreementRequired={agreementRequired}
      emailRequired={emailRequired}
      addCustomerResponse={localizedAddCustomerResponse}
      loading={isPending}
      onResetState={reset}
      isError={isError}
      addCustomer={addCustomer}
    />
  );
};

export default AddCustomerContainer;
