import { maskString } from './string';
import globals from './statics';

const SerialNumber = {
  pattern: /^(21)([0-9]{2}([0-4][0-9]|5[0-3]))([0-9]{6})([0-9]{4}|<{4})([0-9]{10})[N][0-9]$/,

  isValid: function (serialNumber?: string, strict = false): boolean {
    if (!serialNumber || !this.pattern.test(serialNumber)) {
      return false;
    }

    if (strict) {
      const charValueModifier = 32;
      const indexCorrection = 1;

      const charCodes = serialNumber
        .substring(0, serialNumber.length - 1)
        .split('')
        .map((ch) => ch.charCodeAt(0) - charValueModifier);

      const sum = charCodes.reduce((acc, el, index) => acc + el * (index + indexCorrection));

      const checkNumber = sum % 10;

      return serialNumber.endsWith('N' + checkNumber);
    }
    return true;
  },

  isPassing: function (input: string): boolean {
    if (input.length === 0) {
      return true;
    }

    const mask = '21000011111122223333333333N0';
    const mask2 = '210000111111<<<<3333333333N0';
    let neededMask = '';

    if (input.length > 12 && input[12] === '<') {
      neededMask = mask2.substr(input.length);
    } else {
      neededMask = mask.substr(input.length);
    }
    return this.isValid(
      maskString(globals.SERIAL_NUMBER.BOILER.LENGTH, input, neededMask),
      input.length === globals.SERIAL_NUMBER.BOILER.LENGTH,
    );
  },

  extractArticleNumber: function (serialNumber: string): string {
    if (!this.isValid(serialNumber)) {
      return '';
    }
    return serialNumber.substring(6, 16).replace(/</g, '');
  },
};

export default SerialNumber;
