import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  auth,
  keycloak,
  hooks,
  AuthContextProvider,
  config,
  store,
  useLoggingContext,
} from '@vaillant-professional-ui/pui-frontend-common';
import { createAuthAdapter } from '../../services/auth/adapter';
import { useLogCorrelationId } from '../../lib/loginCorrelation';
import ActivitySpinner from '../ActivitySpinner';
import { LogoutHandler } from './LogoutHandler';

export const AuthenticationProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [authService, setAuthService] = useState<auth.AuthService | null>(null);
  const country = useSelector(store.getCountry);
  const previousCountry = hooks.usePrevious(country);
  const appConfig = config.useAppConfig();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reportError } = useLoggingContext();

  useLogCorrelationId();

  useEffect(() => {
    const initAuth = async () => {
      try {
        const idmConfig = keycloak.getIdmConfiguration(country, appConfig, 'web');
        const authService = await auth.createAuth(createAuthAdapter({ idmConfig }));
        setAuthService(authService);
      } catch (e) {
        reportError('configuration error', e as Error);
        dispatch(store.setCountry(undefined));
        navigate('/login', { replace: true });
      }
    };
    if (!authService || country !== previousCountry) {
      void initAuth();
    }
  }, [navigate, previousCountry, appConfig, country, dispatch, authService, reportError]);

  if (!authService) {
    return <ActivitySpinner onScreen={true} loading={true} />;
  }

  const isChangingCountry = country !== previousCountry;

  return (
    <AuthContextProvider service={authService} isChangingCountry={isChangingCountry}>
      <LogoutHandler>{children}</LogoutHandler>
    </AuthContextProvider>
  );
};
