import * as React from 'react';
import { I18nContextProvider, localeFallbackMap, localeMap } from '../I18nProvider';
import { ConfigurationContextType, ConfigurationProviderProps } from './ConfigurationProvider.types';

const defaultConfigurationContext: ConfigurationContextType = {
  themeName: 'green',
  locale: 'en-gb',
  timezone: 'Europe/Berlin',
  testing: { testIdAttributes: [] }
};

export const ConfigurationContext = React.createContext(defaultConfigurationContext);

export const languageToLocale = (languageOrLocale: string): string => {
  const lowerLanguageOrLocale = languageOrLocale.toLowerCase();

  // If full locale exists, use it
  if (Object.keys(localeMap).includes(lowerLanguageOrLocale)) {
    return lowerLanguageOrLocale;
  }

  // Otherwise, if country exists, use it, or fallback
  const bits = lowerLanguageOrLocale.split('-');
  return localeFallbackMap[bits[0]] ?? lowerLanguageOrLocale;
};

export const ConfigurationProvider: React.FC<React.PropsWithChildren<ConfigurationProviderProps>> = ({
  children,
  translate,
  testing,
  locale,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  ...rest
}) => {
  const { testIdAttributes = [] } = testing || {};

  const values = {
    ...rest,
    translate,
    testing: { testIdAttributes },
    locale: locale ? languageToLocale(locale).toLowerCase() : defaultConfigurationContext.locale,
    timezone
  };

  return (
    <ConfigurationContext.Provider value={values}>
      <I18nContextProvider translate={translate}>{children}</I18nContextProvider>
    </ConfigurationContext.Provider>
  );
};
