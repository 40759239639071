import {
  Card as CardBase,
  CardActions as CardActionsBase,
  CardActionArea as CardActionAreaBase,
  CardContent as CardContentBase,
  CardHeader as CardHeaderBase,
  CardMedia as CardMediaBase,
  CardActionsProps,
  CardActionAreaProps,
  CardContentProps,
  CardHeaderProps,
  CardMediaProps,
  CardProps,
} from '@mui/material';
import { VgBaseProps } from '../../components.types';
import { withTestAttributes } from '@vaillant-professional-ui/component-libs-common';

export const Card: React.FC<CardProps & VgBaseProps> = withTestAttributes(CardBase, 'card');
export const CardActions: React.FC<CardActionsProps & VgBaseProps> = withTestAttributes(CardActionsBase, 'actions');

export const CardActionArea: React.FC<CardActionAreaProps & VgBaseProps> = withTestAttributes(
  CardActionAreaBase,
  'action-area',
);

export const CardContent: React.FC<CardContentProps & VgBaseProps> = withTestAttributes(CardContentBase, 'content');
export const CardHeader: React.FC<CardHeaderProps & VgBaseProps> = withTestAttributes(CardHeaderBase, 'header');
export const CardMedia: React.FC<CardMediaProps & VgBaseProps> = withTestAttributes(CardMediaBase, 'media');

export type { CardProps } from './Card.types';
