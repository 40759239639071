/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon16000VgAccessory01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon16000vgaccessory01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M96.58 130.49h68v56.69h90.71v-56.69h68v22.68H346v-22.68a22.67 22.67 0 0 0-22.67-22.68H96.58a22.68 22.68 0 0 0-22.68 22.68V294.9a22.69 22.69 0 0 0 22.68 22.68h175.75V294.9H96.58z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M244 260.88a11.34 11.34 0 1 0 11.33 11.34A11.34 11.34 0 0 0 244 260.88zm0-34.01a11.34 11.34 0 1 0 11.33 11.34A11.34 11.34 0 0 0 244 226.87zm-34 34.01a11.34 11.34 0 1 0 11.33 11.34A11.34 11.34 0 0 0 210 260.88zm0-34.01a11.34 11.34 0 1 0 11.33 11.34A11.34 11.34 0 0 0 210 226.87zm-34 34.01a11.34 11.34 0 1 0 11.34 11.34A11.34 11.34 0 0 0 176 260.88zm0-34.01a11.34 11.34 0 1 0 11.34 11.34A11.34 11.34 0 0 0 176 226.87zm260.74-51.03H317.68A22.68 22.68 0 0 0 295 198.52v272.13a22.67 22.67 0 0 0 22.67 22.67h119.07a22.67 22.67 0 0 0 22.67-22.67V198.52a22.68 22.68 0 0 0-22.67-22.68zm0 294.81H317.68V198.52h119.06z'
        className='cls-1'
      />
    </svg>
  );
};
