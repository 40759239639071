/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon14400VgSpareParts01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon14400vgspareparts01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M320.82 369.2a11.35 11.35 0 0 0-10.14-6.27h-54a11.33 11.33 0 0 0-10.14 6.27l-22.68 45.35a11.33 11.33 0 0 0 0 10.15l22.68 45.35a11.33 11.33 0 0 0 10.14 6.27h54a11.35 11.35 0 0 0 10.14-6.27l22.67-45.35a11.33 11.33 0 0 0 0-10.15zm-37.15 84.44a34 34 0 1 1 34-34 34 34 0 0 1-34 34zM337.53 90.8H229.81a14.17 14.17 0 0 0-14.17 14.2v25.51h39.68v39.68L312 147.5v-17h39.7V105a14.18 14.18 0 0 0-14.17-14.2zm-25.52 79.38-56.69 22.67v28.35l56.69-22.68v-28.34zm-56.69 102.04 56.69-22.68V221.2l-56.69 22.68v28.34zm0 28.35 11.34 11.34h34.02l11.33-11.34v-28.35l-56.69 22.68v5.67z'
      />
    </svg>
  );
};
