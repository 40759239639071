import Keycloak from 'keycloak-js';
import { auth } from '@vaillant-professional-ui/pui-frontend-common';
import { fullyQualify } from '../../Utils/url';

export interface IdmLoginOptions {
  redirectUri?: string;
}

const createTokensFromKeycloak = (keycloakClient: Keycloak): auth.TokenData => {
  const expiryDate = new Date((keycloakClient.tokenParsed?.exp as number) * 1000);
  return {
    accessToken: keycloakClient.token as string,
    refreshToken: keycloakClient.refreshToken as string,
    idToken: keycloakClient.idToken as string,
    accessTokenExpirationDate: expiryDate.toJSON(),
  };
};

export const createAuthAdapter = (params: auth.AuthAdapterParams): auth.AuthAdapter => {
  const { url, realm, clientId } = params.idmConfig;

  // @TODO Inject debug logger
  // @ts-ignore
  const log = (...params) => process.env.NODE_ENV !== 'production' && console.log(...params); // https://reactjs.org/docs/codebase-overview.html#development-and-production

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let onTokenRefreshed: (data: auth.TokenData) => void = () => {};
  let onTokenRefreshError: () => Promise<void> = Promise.resolve;
  let onAuthLogout: () => Promise<void> = Promise.resolve;

  const keycloakClient = new Keycloak({
    url,
    realm,
    clientId,
  });

  keycloakClient.onAuthError = (errorData) => {
    log('onAuthError', errorData);
  };

  keycloakClient.onActionUpdate = (status) => {
    log('onActionUpdate', status);
  };

  keycloakClient.onAuthRefreshError = () => {
    log('onAuthRefreshError');
    void onTokenRefreshError();
  };

  keycloakClient.onAuthRefreshSuccess = () => {
    log('onTokenRefreshed', createTokensFromKeycloak(keycloakClient));
    onTokenRefreshed(createTokensFromKeycloak(keycloakClient));
  };

  keycloakClient.onAuthLogout = () => {
    log('onAuthLogout');
    void onAuthLogout();
  };

  keycloakClient.onAuthSuccess = () => {
    log('onAuthSuccess');
  };

  keycloakClient.onTokenExpired = () => {
    log('onTokenExpired');
    void keycloakClient.updateToken(5);
  };

  return {
    initialize: async ({
      onTokenRefreshed: _onTokenRefreshed,
      onTokenRefreshError: _onTokenRefreshError,
      onAuthLogout: _onAuthLogout,
    }) => {
      onTokenRefreshed = _onTokenRefreshed;
      onTokenRefreshError = _onTokenRefreshError;
      onAuthLogout = _onAuthLogout;

      await keycloakClient.init({
        pkceMethod: 'S256',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      });
      if (keycloakClient.token) {
        return auth.getAuthenticationStatus('idm', createTokensFromKeycloak(keycloakClient));
      }
      return null;
    },

    loginIdm: async (options: IdmLoginOptions = {}): Promise<auth.TokenData> => {
      const { redirectUri } = options;

      return new Promise((resolve, reject) => {
        if (!keycloakClient) {
          return reject();
        }

        keycloakClient
          .login({ redirectUri })
          .then(() => {
            resolve(createTokensFromKeycloak(keycloakClient));
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    logoutIdm: async (_redirectUri?: string) => {
      const redirectUri = fullyQualify(_redirectUri ?? '/login');
      await keycloakClient.logout({ redirectUri });
    },
  };
};
