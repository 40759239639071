import * as React from 'react';
import { useInstanceId, useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { CheckableDeviceInfoProps } from './CheckableDeviceInfo.types';
import { Box, Collapse, FormControlLabel } from '@mui/material';
import { Checkbox } from '../../atoms/Checkbox';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button';
import { Icon02601VgUp01 } from '../../atoms/Icon/Icon02601VgUp01';
import { Icon02501VgDown01 } from '../../atoms/Icon/Icon02501VgDown01';

export const CheckableDeviceInfo: React.FunctionComponent<CheckableDeviceInfoProps> = ({
  testIdKey,
  isChecked,
  onChange,
  deviceCategory,
  marketingName,
  serialNumber,
}) => {
  const testId = useInstanceId('checkabledeviceinfo', testIdKey);
  const t = useTranslation();

  const [isCollapsed, setIsCollapsed] = React.useState(false);

  return (
    <Box
      bgcolor='grey.300'
      display='flex'
      flexDirection='column'
      borderRadius={2.5}
      {...testId('root').testIdAttributes}
    >
      <Box pl={4} py={2} display='flex' justifyContent='space-between' alignItems='center'>
        <FormControlLabel
          checked={isChecked}
          onChange={onChange}
          control={<Checkbox testIdKey={testId('checkbox').key} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
          label={<Typography variant='h4'>{deviceCategory}</Typography>}
        />
        <Button disabled={!serialNumber} onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? (
            <Icon02601VgUp01 color='primary' height={24} width={24} />
          ) : (
            <Icon02501VgDown01 color='primary' height={24} width={24} />
          )}
        </Button>
      </Box>
      {serialNumber ? (
        <Collapse in={isCollapsed}>
          <Box p={6} borderTop={1} borderColor='grey.400'>
            {marketingName ? (
              <Typography variant='h4' bold mb={2}>
                {marketingName}
              </Typography>
            ) : null}
            <Typography variant='body' color='grey.500'>{`${t('VG_SERIALNUMBER_SHORT')}: ${serialNumber}`}</Typography>
          </Box>
        </Collapse>
      ) : null}
    </Box>
  );
};
