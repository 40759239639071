import { groupBy, mapValues, some, max, intersection, sumBy } from 'lodash';
import {
  EmfBucketResolution,
  EmfBucketsRequestParams,
  EmfBucketsResponse,
  EmfBucketsResponseWithDeviceId,
  EmfDevicesResponse,
  EmfEnergyType,
  EmfGraphValueResponse,
  EmfOperationMode,
} from '../../apis/emf/emf.types';
import { DateRangeMapping, DateRangePeriod } from './emf.hooks.types';
import { ConsumptionData, ConsumptionFilters, EmfDataCategory, EmfDevice } from './emf.types';
import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';
import endOfMonth from 'date-fns/endOfMonth';

export const getDefaultConsumptionFilters = (
  _startDate: Date,
  supportedOperationModes: EmfOperationMode[],
): ConsumptionFilters => {
  const lastMonth = subMonths(_startDate, 1);

  return {
    startDate: startOfMonth(lastMonth),
    endDate: endOfMonth(lastMonth),
    dateRangePeriod: 'MONTH',
    // solar stations and pv collectors have own operation modes (SUM, PV) that always have to added to the selected operation modes
    // some informatation can be found here: https://groupspace.vaillant-group.com/x/mDwFEw
    selectedOperationModes: intersection(
      ['HEATING', 'DOMESTIC_HOT_WATER', 'COOLING', 'PV', 'SUM'],
      supportedOperationModes,
    ),
    selectedDevices: 'all',
    supportedOperationModes,
  };
};

export const getEmfBucketsRequestParamsFactory =
  (systemId: string, startDate: Date, endDate: Date, resolution: EmfBucketResolution) =>
  (device: EmfDevicesResponse[0]): EmfBucketsRequestParams | null => {
    const operationModes = Array.from(new Set(device.data.map((v) => v.operation_mode)));
    const energyTypes = Array.from(new Set(device.data.map((v) => v.value_type)));
    if (operationModes.length + energyTypes.length === 0) {
      return null;
    }
    return {
      systemId,
      deviceId: device.device_uuid,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      resolution,
      energyTypes,
      operationModes,
    };
  };

export const dateRangePeriodToResolution = (
  dateRangePeriod: DateRangePeriod,
): EmfBucketsRequestParams['resolution'] => {
  return DateRangeMapping[dateRangePeriod];
};

const getHighestValueByResolution = (buckets: EmfBucketsResponse) => {
  const bucketsGroupedByStartDate = groupBy(
    buckets.flatMap((bucket) => bucket.data),
    (graphValue) => graphValue.startDate,
  );
  const valuesGroupedByStartDate = Object.values(bucketsGroupedByStartDate);
  const sumsForEachDay = valuesGroupedByStartDate.map((data) => sumBy(data, (d) => d.value));
  return max(sumsForEachDay) ?? 0;
};

export const categorySelectorFactory =
  (emfData: EmfBucketsResponse) => (operationMode: EmfOperationMode | 'all', energyType: EmfEnergyType[]) => {
    const buckets = emfData.filter((emf) => {
      const isOperationModeValid = emf.operationMode === operationMode || operationMode === 'all';
      const isSameEnergyType = energyType.includes(emf.energyType);
      return isOperationModeValid && isSameEnergyType;
    });

    const total = sumBy(buckets, (bucket) => sumBy(bucket.data, (graphValue) => graphValue.value));
    const groupedBuckets = mapValues(
      groupBy(buckets, (bucket) => bucket.operationMode),
      (buckets) => {
        const graphValues = buckets.flatMap((bucket) => bucket.data);
        const dates = groupBy(graphValues, (graphValue) => graphValue.startDate);
        return Object.values(dates).map((values) => ({
          startDate: values[0].startDate,
          endDate: values[0].endDate,
          value: sumBy(values, (graphValue) => graphValue.value),
        }));
      },
    ) as Record<EmfOperationMode, EmfGraphValueResponse[]>;

    return { buckets: groupedBuckets, total, highestValueByResolution: getHighestValueByResolution(buckets) };
  };

export const calculateEfficiency = (totalConsumption: number, generatedValue: number) => {
  if (generatedValue == null || !totalConsumption) {
    return 0;
  }
  return generatedValue / totalConsumption;
};

export const getSelectedEmfData = (selectedDevices: 'all' | string[], emfData: EmfBucketsResponseWithDeviceId[]) =>
  selectedDevices === 'all' ? emfData : emfData.filter((data) => selectedDevices.includes(data.deviceId));

/**
 * calculate consumptions and efficiencies based on operation modes, energy types and device types
 *
 * efficiency = heatGenerated / energyConsumption per device
 * note:
 *    only some boiler will emit data of heat generated;
 *    some systems have distinct backup heaters that emit this information;
 *    some heat pumps already aggregate the data of their backup heater with their own data
 *
 * If a device does not emit any heat generated for the selected operation modes, no totlal efficiency will be calculated.
 */
export const getCalculatedConsumption = (
  selectedEmfData: EmfBucketsResponseWithDeviceId[],
  selectedOperationModes: EmfOperationMode[],
) => {
  const emfPerDevice = new Map(
    selectedEmfData.map((bucketResponse) => {
      const energyTypes = new Set(
        Array.from(bucketResponse.data)
          .flat()
          .map((val) => val.energyType),
      );
      return [
        bucketResponse.deviceId,
        { data: bucketResponse.data, canCalculateEfficiency: energyTypes.has('HEAT_GENERATED') },
      ];
    }),
  );

  const canCalculateEfficiency = Array.from(emfPerDevice.values())
    .flatMap((v) => v.canCalculateEfficiency)
    .every(Boolean);

  const flattenedEmfData = Array.from(emfPerDevice.values()).flatMap((v) => v.data);
  const filteredEmfData = flattenedEmfData.filter((emfData) => selectedOperationModes.includes(emfData.operationMode));

  const getCategoryFromFilteredEmfData = categorySelectorFactory(filteredEmfData);

  const electricalConsumed = getCategoryFromFilteredEmfData('all', ['CONSUMED_ELECTRICAL_ENERGY']);
  const primaryConsumed = getCategoryFromFilteredEmfData('all', ['CONSUMED_PRIMARY_ENERGY']);

  const categories: ConsumptionData['categories'] = {
    heatingGenerated: getCategoryFromFilteredEmfData('HEATING', ['HEAT_GENERATED']),
    dhwGenerated: getCategoryFromFilteredEmfData('DOMESTIC_HOT_WATER', ['HEAT_GENERATED']),
    coolingGenerated: getCategoryFromFilteredEmfData('COOLING', ['HEAT_GENERATED']),
    environmentalYield: getCategoryFromFilteredEmfData('all', ['EARNED_ENVIRONMENT_ENERGY']),
    solarYield: getCategoryFromFilteredEmfData('all', ['EARNED_SOLAR_ENERGY']),

    electricalConsumed,
    primaryConsumed,
  };

  const totalEnergyGenerated =
    categories.heatingGenerated.total + categories.dhwGenerated.total + categories.coolingGenerated.total;

  const totalEnergyConsumed = electricalConsumed.total + primaryConsumed.total;

  const totalHeatingEnergyConsumed = getCategoryFromFilteredEmfData('HEATING', [
    'CONSUMED_ELECTRICAL_ENERGY',
    'CONSUMED_PRIMARY_ENERGY',
  ]).total;

  const totalDhwEnergyConsumed = getCategoryFromFilteredEmfData('DOMESTIC_HOT_WATER', [
    'CONSUMED_ELECTRICAL_ENERGY',
    'CONSUMED_PRIMARY_ENERGY',
  ]).total;

  const totalCoolingEnergyConsumed = getCategoryFromFilteredEmfData('COOLING', [
    'CONSUMED_ELECTRICAL_ENERGY',
    'CONSUMED_PRIMARY_ENERGY',
  ]).total;

  const efficiency = {
    total: canCalculateEfficiency ? calculateEfficiency(totalEnergyConsumed, totalEnergyGenerated) : null,
    heating: calculateEfficiency(totalHeatingEnergyConsumed, categories.heatingGenerated.total),
    dhw: calculateEfficiency(totalDhwEnergyConsumed, categories.dhwGenerated.total),
    cooling: calculateEfficiency(totalCoolingEnergyConsumed, categories.coolingGenerated.total),
  };
  return { categories, efficiency };
};

/**
 * This is a temporary fix for erroneous API responses
 * @deprecated after CAG-36811 is fixed
 */
const addMissingValueAttribute = (value: EmfBucketsResponseWithDeviceId['data'][0]['data'][0]['value']) => value ?? 0;

/**
 * Values from the API come in unit Wh but all values in the UI shall be in unit kWh, so we transform the response data
 */
export const transformValuesToKwh = (response: EmfBucketsResponseWithDeviceId): EmfBucketsResponseWithDeviceId => {
  return {
    ...response,
    data: response.data.map((entry) => {
      const data = entry.data.map((d) => {
        const value = addMissingValueAttribute(d.value);
        return { ...d, value: value / 1000 };
      });
      return { ...entry, data };
    }),
  };
};

export const bucketsContainNonemptyValues = (buckets: EmfDataCategory['buckets']) =>
  buckets !== null && some(buckets, (bucket) => some(bucket, (datapoint) => datapoint.value > 0));

export const hasHeatpump = (devices: EmfDevice[]) => devices.some((device) => device.type === 'HEATPUMP');

export const isHybrid = (devices: EmfDevice[]) => {
  const deviceTypes = new Set(devices.map((d) => d.type));
  return deviceTypes.has('BOILER') && deviceTypes.has('HEATPUMP');
};
