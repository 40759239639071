import { models } from '@vaillant-professional-ui/pui-frontend-common';

const titleTranslationsMap: Record<string, string> = {
  consumption: 'SG_EMF_TITLE',
  'electrical-consumption': 'SG_ELECTRICAL_ENERGY_CONSUMPTION',
  'environmental-yield': 'SG_ENVIRONMENTAL_YIELD',
  'solar-thermal': 'SG_SOLAR_THERMAL',
  'heat-generated': 'SG_HEAT_GENERATED',
  'cooling-generated': 'SG_COOLING_GENERATED',
};

export const getTitleByPath = (t: models.TranslationFnc, path?: string) => {
  if (!path || !titleTranslationsMap[path]) {
    return null;
  }
  return t(titleTranslationsMap[path]);
};
