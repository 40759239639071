import * as React from 'react';
import format from 'date-fns/format';
import { Rect } from '../../lib/svg';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryChartProps,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
} from '../../lib/victory';
import { GradientDef } from './GradientDef';
import { colors } from './colors';

const GRAY = '#d2d2d2';

interface Props extends VictoryChartProps {
  valueDomain: { x: [number, number]; y: [number, number] };
  values: { x: number; y: number }[];
  isUnHealthy: boolean;
  isPredictable: boolean;
  criticalTimeStamp: number | null;
  predictionDomain: { x: [number, number]; y: [number, number] };
  predictionLow: { x: number; y: number }[];
  predictionHigh: { x: number; y: number }[];
}

// threshold beyond which all water pressure values would lead to F.22 errors
const F22_THRESHOLD = 0.3;

const formatTimestamp = (timestamp: number) => {
  try {
    return format(timestamp * 1000, 'dd.MM');
  } catch (e) {
    console.trace(e);
    return '';
  }
};

const getXAxisLabels = ({ valueDomain, criticalTimeStamp }: Pick<Props, 'valueDomain' | 'criticalTimeStamp'>) => {
  const yPos = F22_THRESHOLD;
  const startOfMeasurement = {
    x: valueDomain.x[0],
    y: yPos,
    label: { value: formatTimestamp(valueDomain.x[0]), anchor: 'start' },
  };

  const endOfMeasurement = {
    x: valueDomain.x[valueDomain.x.length - 1],
    y: yPos,
    label: { value: formatTimestamp(valueDomain.x[valueDomain.x.length - 1]), anchor: 'middle' },
  };

  const timelineValue = [startOfMeasurement, endOfMeasurement];

  if (criticalTimeStamp) {
    timelineValue.push({
      x: criticalTimeStamp,
      y: yPos,
      label: { value: formatTimestamp(criticalTimeStamp), anchor: 'middle' },
    });
  }

  return timelineValue;
};

export const PredictionChart: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  valueDomain,
  predictionLow,
  predictionHigh,
  predictionDomain,
  criticalTimeStamp,
  isUnHealthy,
  isPredictable,
  ...rest
}) => {
  const theme = isUnHealthy ? 'unhealthy' : 'healthy';
  const gradientMeasurements = theme + 'gradientMeasurements';
  const gradientPredictions = theme + 'predictions';

  const thresholdLineColor = isUnHealthy ? colors[theme][0] : GRAY;

  // If we don't show predictions, we need to reduce the X-Domain of the chart
  const maxExtendX = isPredictable ? predictionDomain.x[1] : valueDomain.x[1];

  const timelineValue = getXAxisLabels({ valueDomain, criticalTimeStamp });

  return (
    <VictoryChart domainPadding={{ x: [0, 50] }} {...rest}>
      <GradientDef
        stops={[
          { offset: 0, color: colors[theme][1] },
          { offset: 89, color: 'white' },
        ]}
        gradientMeasurementsId={gradientMeasurements}
        gradientPredictionsId={gradientPredictions}
      />

      <VictoryAxis
        style={{
          axis: { stroke: 'transparent' },
          ticks: { stroke: 'transparent' },
          tickLabels: { fill: 'transparent' },
        }}
      />

      <VictoryGroup>
        {isPredictable && (
          <VictoryArea
            domain={predictionDomain}
            labels={[]}
            style={{
              data: {
                fill: `url(#${gradientPredictions})`,
                strokeWidth: 0,
              },
            }}
            data={predictionHigh}
          />
        )}

        {isPredictable && (
          <VictoryArea
            domain={predictionDomain}
            style={{
              data: {
                opacity: 1,
                fill: 'white',
                strokeWidth: 0,
              },
            }}
            data={predictionLow}
          />
        )}

        <VictoryArea
          domain={valueDomain}
          style={{
            data: {
              fill: `url(#${gradientMeasurements})`,
              stroke: colors[theme][0],
              strokeWidth: 1,
            },
          }}
          data={values}
        />

        {isPredictable && (
          <VictoryLine
            style={{
              data: { stroke: GRAY, strokeWidth: 1 },
            }}
            data={[
              { x: valueDomain.x[1], y: 0.2 },
              { x: valueDomain.x[1], y: valueDomain.y[1] },
            ]}
          />
        )}
        <VictoryLine
          style={{
            data: { stroke: thresholdLineColor, strokeWidth: 1 },
          }}
          data={[
            { x: valueDomain.x[0], y: F22_THRESHOLD },
            { x: maxExtendX, y: F22_THRESHOLD },
          ]}
          labelComponent={
            <VictoryLabel style={{ fill: thresholdLineColor, fontSize: 10 }} verticalAnchor='middle' dy={0} dx={25} />
          }
          labels={['', `${F22_THRESHOLD} bar`]}
        />
        <VictoryLine
          style={{
            data: { stroke: 'transparent', strokeWidth: 0 },
          }}
          data={timelineValue}
          labels={({ datum }) => datum.label}
          labelComponent={
            <VictoryLabel
              text={({ datum }) => datum.label.value}
              // @ts-ignore
              textAnchor={({ datum }) => datum.label.anchor}
              backgroundComponent={<Rect height={50} />}
              style={{ fill: thresholdLineColor, fontSize: 10 }}
              backgroundStyle={[{ fill: 'transparent' }]}
              dy={15}
            />
          }
        />
      </VictoryGroup>
    </VictoryChart>
  );
};
