import subHours from 'date-fns/subHours';
import { useHistory } from './history.hooks';
import { TimestampRecord } from '../../../typings/models';
import { HistoryDiagnosticModified } from '../../useCases/MonitoringDetails/MonitoringDetails.hooks.types';

type HistoryData = ReturnType<typeof useHistory>['data'];

export const getFiltered = (historyData: HistoryData, lookBackHours: number) => {
  const dateFrom = subHours(new Date(), lookBackHours);
  const compareFn = (value: TimestampRecord<number | string>) => {
    return new Date(value.timestamp) > dateFrom;
  };

  type ValueKey =
    | 'waterPressure'
    | 'flowTemperature'
    | 'returnTemperature'
    | 'dhwTemperature'
    | 'buildPumpPWM'
    | 'evaporationTemperature'
    | 'flowTemperatureSensor'
    | 'heatExchangerOutletTemperature'
    | 'highPressureSensor'
    | 'currentSuperheating'
    | 'currentSubcooling'
    | 'heatExchangerInletTemperature'
    | 'volumeFlow';

  const getValues = (prop: ValueKey) =>
    historyData[prop]
      ? ({
          ...historyData[prop],
          values: historyData[prop]?.values?.filter(compareFn).map((dataPointValue) => ({
            ...dataPointValue,
            value: isNaN(dataPointValue.value) ? dataPointValue.value : Number(dataPointValue.value),
          })),
        } as HistoryDiagnosticModified[ValueKey])
      : undefined;

  return {
    ...historyData,
    dtcStatus: (historyData.dtcStatus ?? []).filter(compareFn),
    dtcMaintenance: (historyData.dtcMaintenance ?? []).filter(compareFn),
    dtcError: (historyData.dtcError ?? []).filter(compareFn),
    waterPressure: getValues('waterPressure'),
    flowTemperature: getValues('flowTemperature'),
    returnTemperature: getValues('returnTemperature'),
    dhwTemperature: getValues('dhwTemperature'),
    buildPumpPWM: getValues('buildPumpPWM'),
    evaporationTemperature: getValues('evaporationTemperature'),
    flowTemperatureSensor: getValues('flowTemperatureSensor'),
    heatExchangerOutletTemperature: getValues('heatExchangerOutletTemperature'),
    highPressureSensor: getValues('highPressureSensor'),
    currentSuperheating: getValues('currentSuperheating'),
    currentSubcooling: getValues('currentSubcooling'),
    heatExchangerInletTemperature: getValues('heatExchangerInletTemperature'),
    volumeFlow: getValues('volumeFlow'),
  };
};
