import { Box, BoxProps } from '@mui/material';
import { styled, Theme, css } from '@vaillant-professional-ui/component-libs-web';

interface Props {
  $isAuthenticated: boolean;
  $indentContent: boolean;
}

type CssProps = { theme: Theme } & Props;

// @ts-ignore
// "css" typing behaviour deviates from "styled" behaviour
const cssAuthenticated = css`
  padding-left: ${({ theme }: CssProps) => theme.spacing(4)};
  padding-right: ${({ theme }: CssProps) => theme.spacing(4)};
  padding-top: ${({ theme }: CssProps) => theme.spacing(22.5 + 6)};

  ${({ theme }: CssProps) => theme.breakpoints.up('sm')} {
    padding-left: ${({ theme }: CssProps) => theme.spacing(6)};
    padding-right: ${({ theme }: CssProps) => theme.spacing(6)};
    padding-bottom: ${({ theme }: CssProps) => theme.spacing(30)};
  }

  ${({ theme }: CssProps) => theme.breakpoints.up('md')} {
    margin-left: ${({ theme, $indentContent }: CssProps) => ($indentContent ? `280px` : theme.spacing(6))};
  }

  ${({ theme }: CssProps) => theme.breakpoints.up('lg')} {
    padding-left: ${({ theme }: CssProps) => theme.spacing(8)};
    padding-right: ${({ theme }: CssProps) => theme.spacing(8)};
    padding-top: ${({ theme }: CssProps) => theme.spacing(22.5 + 8)};
  }

  ${({ theme }: CssProps) => theme.breakpoints.up('xl')} {
    padding-left: ${({ theme }: CssProps) => theme.spacing(12)};
    padding-right: ${({ theme }: CssProps) => theme.spacing(12)};
  }
`;

const cssAnonymous = css`
  padding: 0;
`;

export const ContentBox = styled(Box)<BoxProps & Props>`
  margin-left: 0;
  ${({ $isAuthenticated }) => ($isAuthenticated ? cssAuthenticated : cssAnonymous)}
`;
