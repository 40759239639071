import { useEffect, useMemo, useState } from 'react';
import { util } from '@vaillant-professional-ui/component-libs-common';
import { addNewTimeWindowWith, deleteTimeWindow, manipulateEntry, sortTimeWindows } from './ScheduleEditor.util';
import isEqual from 'lodash/isEqual';

export const useScheduleEditor = <T extends util.TimeWindow>(
  values: T[],
  timeStepWidthInMinutes: number,
  onChange: (values: T[]) => void,
  onCreateDefaultTimeWindow: () => T,
) => {
  const addNewTimeWindow = addNewTimeWindowWith<T>(onCreateDefaultTimeWindow);
  const [managedValues, _setManagedValues] = useState<T[]>(sortTimeWindows(values));
  const sortedValues = useMemo(() => sortTimeWindows(values), [values]);

  const setManagedValues = (v: T[]) => {
    _setManagedValues(sortTimeWindows(v));
  };

  useEffect(
    () => {
      if (!isEqual(sortedValues, managedValues)) {
        setManagedValues(sortedValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values],
  );

  useEffect(
    () => {
      if (!isEqual(sortedValues, managedValues)) {
        onChange(managedValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [managedValues],
  );

  const onAddTimeWindow = () => {
    setManagedValues(addNewTimeWindow(managedValues));
  };

  const deleteTimespan = (index: number) => {
    setManagedValues(deleteTimeWindow(managedValues, index));
  };

  const changeTimeWindow = (index: number, overwrite: Partial<T>) => {
    setManagedValues(manipulateEntry<T>(managedValues, index, overwrite));
  };

  const maximumStartTime = util.formatTimeFromMinutesSinceMidnight(24 * 60 - timeStepWidthInMinutes) as util.TimeString;

  return { managedValues, onAddTimeWindow, deleteTimespan, changeTimeWindow, maximumStartTime };
};
