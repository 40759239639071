import { TranslateFnc } from '@vaillant-professional-ui/component-libs-common';

export const getTranslationForStatusCode = (t: TranslateFnc, statusCode?: number) => {
  if (!statusCode) {
    return t('VG_STATUS_ERROR_LOADING_ERROR');
  }
  const key = `VG_LOADING_ERROR_PANEL_${statusCode}`;
  const translation = t(key);
  if (translation === key) {
    return t('VG_STATUS_ERROR_LOADING_ERROR');
  }
  return translation;
};
