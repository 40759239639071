import { features } from '@vaillant-professional-ui/pui-frontend-common';

import { useBoilerSerialNumberFromPath } from '../../../components/Routes';
import { HistoryCharts } from './HistoryCharts';
import { useParams } from 'react-router';
import { useDateRangePersistor } from '../dateRangePersistor';

export const HistoryChartsContainer: React.FC = () => {
  const boilerSerialNumber = useBoilerSerialNumberFromPath();
  const { start, end } = useDateRangePersistor();

  const { chartTabId } = useParams<Record<'chartTabId', string>>();
  if (!chartTabId) {
    throw new Error('Unexpected path. Missing chartTab id in URL');
  }

  const props = features.historyCharts.historyChartsList.useCardsPresenter({
    boilerSerialNumber,
    chartTabId,
    start,
    end,
  });

  return <HistoryCharts {...props} />;
};
