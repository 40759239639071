import { CardContent } from '@mui/material';
import { DataCard, styled } from '@vaillant-professional-ui/component-libs-web';

export const DataCardStyled = styled(DataCard)`
  & .MuiPaper-root {
    box-shadow: none;
  }
`;

export const CardContentStyled = styled(CardContent)`
  padding-bottom: 8px;
`;
