import React, { useCallback } from 'react';
import { ControllerType, ERelaxScheduleEditorProps, SelectERelaxProfileProps } from './ScheduleEditor.types';
import { createDefaultTimeWindowWithProfileFactory } from './ScheduleEditor.util';
import { useScheduleEditor } from './ScheduleEditor.hooks';
import { useTranslation, util } from '@vaillant-professional-ui/component-libs-common';
import { SelectChangeEvent } from '@mui/material';
import { ScheduleProfileIcon } from '../../atoms/ScheduleProfileIcon';
import { Select } from '../../atoms/Select';
import { ScheduleEditor } from './ScheduleEditor';

const SelectERelaxProfile: React.FC<SelectERelaxProfileProps> = ({ timeWindowIndex, profiles, timespan, onChange }) => {
  const t = useTranslation();
  const handleChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const newProfile = profiles.find((profile) => profile.id === e.target.value);
      if (!newProfile) return;
      const changedProfile: Partial<util.TimeWindowWithProfile> = {
        profile: newProfile,
      };
      onChange(timeWindowIndex, changedProfile);
    },
    [onChange, profiles, timeWindowIndex],
  );

  const options =
    profiles.map((profile) => ({
      label: util.getErelaxProfileTitle(profile, t),
      value: profile.id,
      icon: <ScheduleProfileIcon profile={profile} />,
    })) ?? [];
  return <Select initialValue={timespan.profile.id} options={options} onChange={handleChange} />;
};

export const ErelaxScheduleEditor: React.FC<ERelaxScheduleEditorProps> = ({
  values,
  maxTimeSlotsPerDay: maxTimeWindows,
  timeStepWidthInMinutes,
  profiles,
  onChange,
}) => {
  const onCreateDefaultTimeWindow = createDefaultTimeWindowWithProfileFactory(profiles[0]);
  const { managedValues, onAddTimeWindow, deleteTimespan, changeTimeWindow, maximumStartTime } =
    useScheduleEditor<util.TimeWindowWithProfile>(values, timeStepWidthInMinutes, onChange, onCreateDefaultTimeWindow);
  const renderSelectComponent = (index: number, timespan: any) => (
    <SelectERelaxProfile timeWindowIndex={index} profiles={profiles} onChange={changeTimeWindow} timespan={timespan} />
  );

  return (
    <ScheduleEditor
      controllerType={ControllerType.ERELAX}
      deleteTimespan={deleteTimespan}
      managedValues={managedValues}
      maxTimeWindows={maxTimeWindows}
      maximumStartTime={maximumStartTime}
      onAddTimeWindow={onAddTimeWindow}
      onChangeTimeWindow={changeTimeWindow}
      renderSelectComponent={renderSelectComponent}
      timeStepWidthInMinutes={timeStepWidthInMinutes}
      testIdKey='erelax'
    />
  );
};
