/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon04601VgMenu01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon04601vgmenu01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M419.57 272.13H147.36a11.34 11.34 0 0 0 0 22.67h272.21a11.34 11.34 0 0 0 0-22.67zm0 90.71H147.36a11.34 11.34 0 0 0 0 22.67h272.21a11.34 11.34 0 0 0 0-22.67zM147.36 204.09h272.21a11.34 11.34 0 0 0 0-22.67H147.36a11.34 11.34 0 0 0 0 22.67z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
