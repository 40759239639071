import React from 'react';
import Box from '@mui/material/Box';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';
import { Card, CardContent } from '../../molecules/Card';
import { DataPointCardItem } from '../../molecules/DataPointCardItem';
import { HeroDataPointCardItemProps } from './HeroDataPointCardItem.types';
import { HeroDataPointCardItemSkeleton } from './HeroDataPointCardItem.skeleton';

export const HeroDataPointCardItem: React.FunctionComponent<HeroDataPointCardItemProps> = ({
  testIdKey,
  value,
  label,
  additionalValues,
  isLoading = false,
  ...rest
}) => {
  const testId = useInstanceId('herodatapointcard', testIdKey);

  if (isLoading) {
    return <HeroDataPointCardItemSkeleton testIdKey={testIdKey} />;
  }

  return (
    <Card {...rest} {...testId('root').testIdAttributes}>
      <CardContent>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={[8, 8, 8, 10]}>
          <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
            <Typography variant='hero' bold>
              {value}
            </Typography>

            <Typography textAlign='center' variant='large'>
              {label}
            </Typography>
          </Box>
          <Box display='flex' flexWrap='wrap' gap={1}>
            {additionalValues.map((additionalValue, index) => (
              <DataPointCardItem key={`additional-datapoint-card-${index}`} severityLevel='info' {...additionalValue} />
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
