import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { State, store } from '@vaillant-professional-ui/pui-frontend-common';
import { SettingsPage } from './SettingsPage';
import { Sections, SettingsPageSection } from './SettingsPage.types';
import { createSettingsSections } from './SettingsPage.util';

export const SettingsPageContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const userLanguage = useSelector<State, string | undefined>((state) => store.getUserLanguage(state, true));
  const browserLanguage = useSelector<State, string | undefined>((state) => store.getBrowserLanguage(state, true));
  const country = useSelector(store.getCountry);
  const { section: sectionFromParams } = useParams<{ section?: SettingsPageSection }>();

  const setLanguage = (language: string) => dispatch(store.setUserLanguage(language));
  const sections: Sections = createSettingsSections();
  const section = sections[sectionFromParams ?? 'language'];

  return (
    <SettingsPage
      sections={sections}
      currentSection={section}
      userLanguage={userLanguage}
      browserLanguage={browserLanguage}
      country={country}
      onSetLanguage={setLanguage}
    />
  );
};
