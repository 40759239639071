import { Button as ButtonBase } from '@vaillant-professional-ui/component-libs-web';
import identity from 'lodash/identity';
import React from 'react';
import { useFirebase } from '../../providers/firebase';
import { IButtonProps } from './Button.types';

const Button: React.FC<React.PropsWithChildren<IButtonProps>> = ({
  testId,
  onClick = identity,
  ...rest
}: IButtonProps) => {
  const firebaseService = useFirebase();
  const additionalProps = testId
    ? {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
          firebaseService.logUserAction(testId);
          onClick?.(e);
        },
        'data-testid': testId,
      }
    : { onClick };
  return <ButtonBase {...additionalProps} {...rest} />;
};

export default Button;
