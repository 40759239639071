/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon02601VgUp01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon02601vgup01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M420 357.4a11.3 11.3 0 0 1-8-3.32L283.7 225.8 155.42 354.08a11.34 11.34 0 1 1-16-16l136.3-136.29a11.33 11.33 0 0 1 16 0L428 338a11.34 11.34 0 0 1-8 19.36z'
      />
      <path fill='none' d='M0 0h567.13v567.13H0z' />
    </svg>
  );
};
