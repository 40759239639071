import { groupBy, map } from 'lodash';

import sortedIndexBy from 'lodash/sortedIndexBy';

import { differenceInDays, differenceInHours, differenceInSeconds } from 'date-fns';

import {
  Coordinate,
  DateTimeRange,
  NearestDataPoints,
  NumberCoordinate,
  NumberTimeSeries,
  StringTimeSeries,
  TemporalCoordinate,
  TimeSeries,
} from './timeSeries.types';

export type Resolution = 'minutes' | 'hours' | 'days';

const findNearest = <T extends Coordinate>(coordinates: T[], cursor: number, minX: number, maxX: number) => {
  const start = sortedIndexBy(
    coordinates,
    // @ts-ignore: second value will not be used
    [minX, 0],
    (v) => v[0],
  );

  let candidate: T | null = null;
  let dx = Number.MAX_SAFE_INTEGER;

  for (const i of coordinates.slice(start)) {
    const ts = i[0].getTime();
    if (ts < minX) {
      continue;
    }

    if (ts > maxX) {
      break;
    }

    const delta = Math.abs(cursor - ts);
    if (delta < dx) {
      dx = delta;
      candidate = i;
    } else {
      break;
    }
  }
  return candidate;
};

const getResolution = (dateRange: DateTimeRange): { resolution: Resolution; diffSeconds: number } => {
  const diffSeconds = differenceInSeconds(dateRange[1], dateRange[0]);
  if (differenceInHours(dateRange[1], dateRange[0]) < 1) {
    return { resolution: 'minutes', diffSeconds };
  }
  if (differenceInDays(dateRange[1], dateRange[0]) < 1) {
    return { resolution: 'hours', diffSeconds };
  }
  return { resolution: 'days', diffSeconds };
};

const getEpsilonPaddingMinutes = (dateTimeRange: DateTimeRange | null) => {
  const defaultValue = 30; // n minutes before and after cursor

  if (!dateTimeRange) {
    return defaultValue;
  }
  const resolution = getResolution(dateTimeRange).resolution;
  if (resolution === 'minutes') {
    return 0.5; // 30 seconds before and after cursor
  }
  if (resolution === 'hours') {
    return 5; // 5 minutes before and after cursor
  }
  return defaultValue;
};

export const findNearestDataPoints = (
  cursorPos: Date,
  lines: NumberTimeSeries[],
  dtcs: StringTimeSeries[],
  range: DateTimeRange | null,
): { timeRange: [Date, Date]; dataPoints: NearestDataPoints } | null => {
  const cursor = cursorPos.getTime();
  const epsilonPaddingMs = (getEpsilonPaddingMinutes(range) / 2) * 60 * 1000;

  const minX = cursor - epsilonPaddingMs;
  const maxX = cursor + epsilonPaddingMs;

  const minDate = new Date(minX);
  const maxDate = new Date(maxX);

  const dataPoints: NearestDataPoints = {};
  for (const line of lines) {
    const nearestValue = findNearest(line.coordinates, cursor, minX, maxX);
    if (nearestValue) {
      dataPoints[line.name] = { value: nearestValue, color: line.color, unit: line.unit };
    }
  }

  for (const dtc of dtcs) {
    const nearestValue = findNearest(dtc.coordinates, cursor, minX, maxX);
    if (nearestValue) {
      dataPoints[dtc.name] = { value: nearestValue, color: dtc.color };
    }
  }
  return { timeRange: [minDate, maxDate], dataPoints };
};

export const findFirstEarlierDate = (coordinates: Coordinate[], date: Date) => {
  for (let i = coordinates.length - 1; i >= 0; i--) {
    const currentDate = coordinates[i][0];
    if (currentDate < date) {
      return currentDate;
    }
  }
  return coordinates[0]?.[0] ?? null;
};

export const findFirstLaterDate = (coordinates: Coordinate[], date: Date) => {
  for (let i = 0; i < coordinates.length; i++) {
    const currentDate = coordinates[i][0];
    if (currentDate > date) {
      return currentDate;
    }
  }
  return coordinates[coordinates.length - 1]?.[0] ?? null;
};

export const getFilteredByDate = <T extends Coordinate = Coordinate>(
  coordinates: T[],
  dateTimeRange: DateTimeRange | null,
): T[] => {
  if (!dateTimeRange) {
    return coordinates;
  }

  const minValue = findFirstEarlierDate(coordinates, dateTimeRange[0]);
  const maxValue = findFirstLaterDate(coordinates, dateTimeRange[1]);

  return coordinates.length && minValue && maxValue
    ? coordinates.filter((coordinate) => {
        return coordinate[0] >= minValue && coordinate[0] <= maxValue;
      })
    : coordinates;
};
const minMax = (values: number[]) => [Math.min(...values), Math.max(...values)];
const getMinMax = <T, TS>(
  lines: Array<TimeSeries<TS>>,
  getter: (coordinate: TemporalCoordinate<TS>) => number,
  transform: (val: number) => T,
): [T, T] | null => {
  const allValues = lines.flatMap((line) => line.coordinates.map(getter));
  const [min, max] = minMax(allValues);
  return isFinite(min) ? [transform(min), transform(max)] : null;
};
export const getMinMaxDates = (lines: Array<NumberTimeSeries | StringTimeSeries>): DateTimeRange | null =>
  getMinMax<Date, number | string>(
    lines,
    (v) => v[0].getTime(),
    (v) => new Date(v),
  );
export const getMinMaxValues = (lines: NumberTimeSeries[]): [number, number] | null =>
  getMinMax(
    lines,
    (v) => v[1],
    (v) => v,
  );

const calculateMedian = (group: NumberCoordinate[]) => {
  const values = group.map(([, value]) => value).sort((a, b) => a - b);
  const middle = Math.floor(values.length / 2);
  return values.length % 2 === 0 ? (values[middle - 1] + values[middle]) / 2 : values[middle];
};

export const roundCoordinates = (
  coordinates: NumberCoordinate[] = [],
  roundFn: (date: Date) => Date,
): Array<[Date, NumberCoordinate[1]]> => {
  const groupedData = groupBy(coordinates, ([date]) => roundFn(date));

  return map(groupedData, (group, key) => {
    const median = calculateMedian(group);
    return [new Date(key), median];
  });
};

export const groupedByMinute = (coordinates: NumberCoordinate[] = []) => {
  return roundCoordinates(coordinates, (date) => new Date(new Date(date).setSeconds(0, 0)));
};

export const groupedByHour = (coordinates: NumberCoordinate[]) => {
  return roundCoordinates(coordinates, (date) => new Date(new Date(date).setMinutes(0, 0, 0)));
};

export const groupedByHalfHour = (coordinates: NumberCoordinate[]) => {
  return roundCoordinates(
    coordinates,
    (date) => new Date(new Date(date).setMinutes(Math.round(date.getMinutes() / 30) * 30, 0, 0)),
  );
};

export const groupDate = (
  coordinates: NumberCoordinate[],
  zoomRange: [Date, Date] | null,
): Array<[Date, NumberCoordinate[1]]> => {
  const boundaries: DateTimeRange | null = zoomRange
    ? zoomRange
    : coordinates.length > 0
    ? [coordinates[0][0], coordinates[coordinates.length - 1][0]]
    : null;

  if (!boundaries) {
    return coordinates;
  }

  const diffSeconds = getResolution(boundaries).diffSeconds;

  // One day or less
  if (diffSeconds < 60 * 60 * 24) {
    return groupedByMinute(coordinates);
  }

  // Five day or less
  if (diffSeconds < 60 * 60 * 24 * 5) {
    return groupedByHalfHour(coordinates);
  }

  return groupedByHour(coordinates);
};
