import { util } from '@vaillant-professional-ui/component-libs-common';
import { VgBaseProps } from '../../components.types';

export interface TemperatureConfig {
  minimum: number;
  maximum: number;
  stepSize: number;
}

type ScheduleEditorBaseProps<T extends util.TimeWindow> = {
  values: T[];
  maxTimeSlotsPerDay: number;
  timeStepWidthInMinutes: number;
  profiles?: util.ErelaxProfile[];
  temperatureConfig?: TemperatureConfig;
  onChangeTimeWindow: (index: number, value: T) => void;
  onChange: (values: T[]) => void;
  readOnly?: boolean;
};

export type ScheduleEditorProps<T extends util.TimeWindow> = ScheduleEditorBaseProps<T> & VgBaseProps;

export type TliScheduleEditorCentralHeatingProps = Required<
  Omit<ScheduleEditorBaseProps<util.TimeWindowWithTargetTemperature>, 'profiles' | 'onChangeTimeWindow' | 'readOnly'>
> &
  VgBaseProps;

export type ERelaxScheduleEditorProps = Required<
  Omit<ScheduleEditorBaseProps<util.TimeWindowWithProfile>, 'temperatureConfig' | 'onChangeTimeWindow' | 'readOnly'>
> &
  VgBaseProps;

export type TliScheduleEditorDomesticHotWaterProps = Omit<
  ScheduleEditorBaseProps<util.TimeWindow>,
  'profiles' | 'temperatureConfig' | 'onChangeTimeWindow' | 'readOnly'
> &
  VgBaseProps;

export interface SelectERelaxProfileProps {
  timeWindowIndex: number;
  profiles: util.ErelaxProfile[];
  timespan: util.TimeWindowWithProfile;
  onChange: (index: number, overwrite: Partial<util.TimeWindowWithProfile>) => void;
}

export interface SelectTLITemperatureProps {
  timeWindowIndex: number;
  temperatureConfig: TemperatureConfig;
  timespan: util.TimeWindowWithTargetTemperature;
  onChange: (index: number, overwrite: Partial<util.TimeWindowWithTargetTemperature>) => void;
  testIdKey?: string;
}

export type ScheduleEditorMocks = {
  centralHeating: { props: TliScheduleEditorCentralHeatingProps };
  eRelax: { props: ERelaxScheduleEditorProps };
  dhw: { props: TliScheduleEditorDomesticHotWaterProps };
};

export enum ControllerType {
  TLI,
  TLI_DHW,
  ERELAX,
}
