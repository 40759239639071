import React from 'react';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { models, selectors } from '@vaillant-professional-ui/pui-frontend-common';
import { HighlightText } from '../HighlightText';

export interface DeviceCellProps {
  device: models.Device;
  markText?: string;
}

export const DeviceCell: React.FC<React.PropsWithChildren<DeviceCellProps>> = ({ device, markText }) => {
  const nomenclature = selectors.boiler.getNomenclature(device);
  const marketingName = selectors.boiler.getMarketingName(device);

  if (!nomenclature && !marketingName) {
    return null;
  }

  return (
    <div>
      {nomenclature ? (
        <Typography>
          <HighlightText markText={markText}>{nomenclature}</HighlightText>
        </Typography>
      ) : null}
      {marketingName ? (
        <Typography color='text.secondary'>
          <HighlightText markText={markText}>{marketingName}</HighlightText>
        </Typography>
      ) : null}
    </div>
  );
};
