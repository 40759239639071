import React from 'react';
import AutocompleteBase from '@mui/material/Autocomplete';
import { useInstanceId, useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { AutocompleteProps } from './Autocomplete.types';
import { TextField } from '../TextField';

export const Autocomplete = <T extends {}>({ textFieldProps = {}, testIdKey, ...rest }: AutocompleteProps<T>) => {
  const t = useTranslation();
  const testId = useInstanceId('autocomplete', testIdKey);
  return (
    <AutocompleteBase
      clearText={t('VG_AUTOCOMPLETE_CLEAR')}
      closeText={t('VG_AUTOCOMPLETE_CLOSE')}
      loadingText={t('VG_AUTOCOMPLETE_LOADING')}
      noOptionsText={t('VG_AUTOCOMPLETE_NO_OPTIONS')}
      openText={t('VG_AUTOCOMPLETE_OPEN')}
      {...rest}
      renderInput={(params) => {
        textFieldProps.inputProps = {
          ...(params.inputProps || {}),
          ...(textFieldProps.inputProps || {}),
        };
        return (
          <TextField style={{ width: '100%' }} {...params} {...textFieldProps} testIdKey={testId('textfield').key} />
        );
      }}
    />
  );
};
