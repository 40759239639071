import * as React from 'react';
import { PermissionGateProps } from './PermissionGate.types';
import { usePermissions } from './PermissionGate.hooks';

export const PermissionGate: React.FC<React.PropsWithChildren<PermissionGateProps>> = ({ children, fallbackComponent, requiredPermissions }) => {
  const { hasPermissions } = usePermissions(requiredPermissions);

  if (hasPermissions) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (fallbackComponent) {
    return <React.Fragment>{fallbackComponent}</React.Fragment>;
  }

  return null;
};
