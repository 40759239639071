import { Notification } from '../../apis/notifications';
import { invertedStatusCodes, modes, otherModes, statusCodeIcons } from './LiveMonitor.constants';
import * as models from '../../../typings/models';

import { TroubleCodeInsight } from '../../../typings/models';
import { troubleCodeInsightCodeToString } from '../../../util/troubleCodes';
import { TickerItem } from './LiveMonitor.types';

export const mergeStatusCode = (code: number) => 'S.' + code.toString();

const getTroubleCode = (
  troubleCodeInsightList: TroubleCodeInsight[],
  code: number,
): [string, TroubleCodeInsight | undefined] => {
  const codeName = mergeStatusCode(code);
  const troubleCode = troubleCodeInsightList.find((item) => troubleCodeInsightCodeToString(item) === codeName);
  return [codeName, troubleCode];
};

export const getModeForDtcStatusCode = (code: number): models.LiveMonitorMode | null => {
  return (
    (Object.keys(modes).find((key) => modes[key as models.LiveMonitorMode].includes(code)) as
      | models.LiveMonitorMode
      | undefined) ?? null
  );
};

/**
 * Receives an array of notifications and returns current boiler working mode
 * @param dtcStream
 */
export const deriveMode = (dtcStream: Notification[]): models.LiveMonitorMode | null | undefined =>
  asStatusStream(dtcStream)
    .map((dtc) => getModeForDtcStatusCode(dtc.code))
    .find(Boolean);

export const isStatusCodeActive = (dtcStream: Notification[], statusCode: number): boolean => {
  return Boolean([...asStatusStream(dtcStream)].find((dtc) => dtc.code === statusCode && !dtc.rectified));
};

export const getColor = (dtcStream: Notification[], statusCode: number) => {
  if (isStatusCodeActive(asStatusStream(dtcStream), statusCode)) {
    return 'primary';
  }

  return 'text.secondary';
};

const forceLabel = (codeName?: string) => {
  // For some status codes, the information coming from GRIPS that would be shown to our users are wrong.
  // So we need to provide a different label here.
  if (codeName && invertedStatusCodes.includes(codeName.toUpperCase())) {
    return 'SG_SX0_DEMAND';
  }
  return '';
};

export const createLabel = (str: string, codeName?: string, translate = (val: string) => val) => {
  const forcedLabel = forceLabel(codeName);
  if (forcedLabel) {
    return translate(forcedLabel);
  }
  const bits = str.split(':');
  if (bits.length === 1) {
    return bits[0];
  }
  return bits.slice(1).join(':').trimLeft();
};

export const getModeFromThreeWayValve = (mode?: string): models.LiveMonitorMode | null => {
  const threeWayValveMapping: { [key: string]: models.LiveMonitorMode } = {
    DOMESTIC_HOT_WATER: 'dhw',
    MIDDLE_POSITION: 'storageTank',
    CENTRAL_HEATING: 'heating',
  };

  if (!mode) {
    return null;
  }

  return threeWayValveMapping[mode] ?? null;
};

const asStatusStream = (dtcStream: Notification[]) => dtcStream.filter((dtc) => dtc.type === 'STATUS');

export const getAttributes = (
  dtcStream: Notification[],
  troubleCodeInsightList: models.TroubleCodeInsight[],
  mode: models.LiveMonitorMode,
  translate?: models.TranslationFnc,
): models.ILiveMonitorAttribute[] => {
  return modes[mode].map((statusCode) => {
    const [codeName, troubleCode] = getTroubleCode(troubleCodeInsightList, statusCode);
    const label = createLabel(troubleCode?.title || '', codeName, translate);
    return {
      icon: statusCodeIcons[statusCode],
      label,
      color: getColor(asStatusStream(dtcStream), statusCode),
    };
  });
};

/**
 * Return all active "other" attributes.
 */
export const getOtherAttributes = (
  dtcStream: Notification[],
  troubleCodeInsightList: models.TroubleCodeInsight[],
): models.ILiveMonitorAttribute[] => {
  const unrectified = asStatusStream(dtcStream)
    .filter((dtc) => !dtc.rectified)
    .map((dtc) => dtc.code);
  return otherModes
    .filter((statusCode) => unrectified.indexOf(statusCode) !== -1)
    .map((statusCode) => {
      const [, troubleCode] = getTroubleCode(troubleCodeInsightList, statusCode);
      return {
        icon: statusCodeIcons[statusCode],
        label: troubleCode?.title || '',
        description: troubleCode?.description || '',
        color: 'error',
      };
    });
};

export const getTicker = (
  dtcStream: Notification[],
  troubleCodeInsightList: models.TroubleCodeInsight[],
  dateFormatter: (dt: Date | number | string) => string,
  timeFormatter: (dt: Date | number | string) => string,
): TickerItem[] => {
  return asStatusStream(dtcStream).map((notification) => {
    const [, troubleCode] = getTroubleCode(troubleCodeInsightList, notification.code);
    const description = troubleCode?.title ?? '';
    const occurrence = new Date(notification.occurrenceTimestamp * 1000);
    return {
      icon: statusCodeIcons[notification.code] || '18801-vg-start01',
      color: notification.rectified ? 'text.secondary' : 'primary',
      date: dateFormatter(occurrence),
      time: timeFormatter(occurrence),
      description,
    };
  });
};
