import React from 'react';

import { hooks } from '@vaillant-professional-ui/pui-frontend-common';

export interface WriteBooleanDatapointDrawerContentContainerProps {
  onClose: () => void;
  title: string;
  initialValue: boolean;
  useMutation: () => (selectedValue: boolean) => void;
}

export const usePresenter = ({
  onClose,
  title,
  initialValue,
  useMutation,
}: WriteBooleanDatapointDrawerContentContainerProps) => {
  const t = hooks.useTranslation();
  const mutate = useMutation();

  // More meaningful in this case to keep the naming convention of the drawer state handling logic
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [selectedValue, setSelectedValue] = React.useState<boolean>(initialValue);

  const handleSubmit = () => {
    mutate(selectedValue);
    onClose();
  };

  const subtitle = t('SYSTEM_SETTINGS_SELECT_DATAPOINT_VALUE', { datapoint: title });
  const labelSwitchOn = t('SG_ON');
  const labelSwitchOff = t('SG_OFF');

  const onChange = (value: boolean) => {
    setSelectedValue(value);
  };
  const isSaveButtonDisabled = selectedValue === initialValue;

  return {
    title,
    subtitle,
    labelSwitchOn,
    labelSwitchOff,
    value: selectedValue,
    onChange,
    onClose,
    isSaveButtonDisabled,
    handleSubmit,
  };
};
