export const getStyles = (isActive: boolean) => {
  const baseStyles = {
    sideMenuItem: {
      gap: 8,
    },
  };
  if (isActive) {
    return {
      ...baseStyles,
      icon: {
        color: 'primary.main',
      },
      typography: {
        fontWeight: 'bold',
        color: 'primary.main',
      },
    };
  }
  return {
    ...baseStyles,
    icon: {
      color: 'common.black',
    },
    typography: {
      fontWeight: 'inherit',
      color: 'common.black',
    },
  };
};
