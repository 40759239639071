import { AxiosError } from 'axios';
import { useQuery } from '../../hooks';
import { notificationsApi } from './notifications.endpoints';
import { NotificationParams, Notification } from './notifications.types';

export interface UseNotificationsParams {
  gatewaySerialNumber: string;
  notificationParams: NotificationParams;
}

export const useNotifications = ({ gatewaySerialNumber, notificationParams }: UseNotificationsParams, options = {}) => {
  return useQuery<Notification[], AxiosError, Notification[]>(
    ['systemNotifications', gatewaySerialNumber, notificationParams.type ?? ''],
    notificationsApi.getNotifications(gatewaySerialNumber, notificationParams),
    {
      select: (result) => result.data,
      enabled: !!gatewaySerialNumber,
      ...options,
    },
  );
};
