import { Box, ListItemText } from '@mui/material';
import { Button } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { useFirebase } from '../../providers/firebase';
import { ILinkProps } from '../Link/Link.types';
import { DemoListItem } from './DrawerContent.styles';

export const LogoutButton: React.FC<{
  isDemo?: boolean;
  onLogout: () => void;
  firebaseProps: ILinkProps['firebaseProps'];
}> = ({ firebaseProps, isDemo, onLogout }) => {
  const firebaseService = useFirebase();

  const trackFirebase = () => {
    if (isDemo) {
      return onLogout();
    }
    const { identifier, ...firebaseRest } = firebaseProps || {};
    if (identifier) {
      firebaseService.logUserAction(identifier, { ...firebaseRest });
    }
    onLogout();
  };

  return (
    <DemoListItem>
      <ListItemText>
        <Box display='flex'>
          <Button
            testIdKey='logout'
            variant='outlined'
            color='primary'
            i18nKey={isDemo ? 'SG_DEMO_LEAVE' : 'SG_SETTINGS_LOGOUT'}
            onClick={trackFirebase}
          />
        </Box>
      </ListItemText>
    </DemoListItem>
  );
};
