import { AxiosError, AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { DateFormatter } from '../../../libs/hooks';
import { TranslationFnc } from '../../../typings/models';
import { diagnosticsApi } from './diagnostics.endpoints';
import { DiagnosticsData, DiagnosticsResponse } from './diagnostics.types';
import { selectDiagnosticsResponse } from './diagnostics.util';
import { useQuery } from '../../hooks';

export const useDiagnostics = (
  gatewaySerialNumber: string,
  t: TranslationFnc,
  df: DateFormatter,
  options: UseQueryOptions<AxiosResponse<DiagnosticsResponse>, AxiosError, DiagnosticsData> = {},
) => {
  const select = selectDiagnosticsResponse(df, t);
  return useQuery<DiagnosticsResponse, AxiosError, DiagnosticsData>(
    ['diagnostics', gatewaySerialNumber],
    diagnosticsApi.getDiagnostics(gatewaySerialNumber),
    {
      select,
      staleTime: 1000 * 30,
      enabled: !!gatewaySerialNumber,
      ...options,
    },
  );
};
