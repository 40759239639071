/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon06800VgContact01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon06800vgcontact01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M340.36 164.7v33.72L354.87 219l8.17-1.83V164.7a22.69 22.69 0 0 0-22.68-22.7h-31.42l-3.94 6.26 11.6 16.44zm0 238.11H204.3V164.7h19.28A232.3 232.3 0 0 1 216 142h-11.7a22.68 22.68 0 0 0-22.68 22.68v260.81a22.68 22.68 0 0 0 22.68 22.68h136.06A22.69 22.69 0 0 0 363 425.49V322.81a142.42 142.42 0 0 1-22.68-11.52zm-68 36.85a14.18 14.18 0 1 1 14.17-14.17 14.16 14.16 0 0 1-14.2 14.17z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='m376.27 237.46-18.51 4.14a19.53 19.53 0 0 1-20.21-7.8l-52.3-74.21a19.5 19.5 0 0 1-.55-21.65l10.13-16-29.74-59.47c-7.15-.18-13.48 1.46-18.67 5.12C219 86.9 233.7 154.86 279.18 219.4s104.56 101.21 132 81.91c5.19-3.66 8.85-9.08 11.1-15.88z'
        className='cls-1'
      />
    </svg>
  );
};
