import { ErrorInfo } from 'react';

interface Metadata {
  [key: string]: string | number | boolean;
}

/**
 * Send custom event to Instana.
 *
 * See https://www.ibm.com/docs/en/obi/current?topic=websites-javascript-agent-api#reporting-custom-events
 */
export const reportEvent = (name: string, meta?: Metadata) => {
  window.ineum?.('reportEvent', name, { meta });
  process.env.NODE_ENV === 'development' && console.log('reportEvent', name, meta);
};

/**
 * Send error event to Instana.
 *
 * See https://www.ibm.com/docs/en/obi/current?topic=websites-javascript-agent-api#manual-error-reporting
 */
export const reportError = (name: string, error: Error | string, errorInfo?: ErrorInfo, meta?: Metadata) => {
  const metadata: Metadata = { ...meta, name };
  if (errorInfo) {
    metadata.errorInfo = JSON.stringify(errorInfo);
  }
  window.ineum?.('reportError', error, { meta: metadata });
  process.env.NODE_ENV === 'development' && console.warn('reportError', name, error, metadata);
};
