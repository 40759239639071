import React from 'react';
import { ZoomControl } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { Hidden } from '@mui/material';
import sortBy from 'lodash/sortBy';
import { config, hooks, models } from '@vaillant-professional-ui/pui-frontend-common';
import { SetBounds } from './SetBounds';
import { Markers } from './Markers';
import { FloatingCustomers } from './FloatingCustomers';
import { MapContainer } from './DeviceMap.styles';
import { DeviceMapProps, MapContextProps } from './DeviceMap.types';
import { MapContext } from './MapContext';

export const DeviceMap: React.FC<React.PropsWithChildren<DeviceMapProps>> = ({
  customers: rawCustomers,
  dtcTypes,
  selectedCustomer,
  setSelectedCustomer,
}) => {
  const [highlightedCustomer, setHighlightedCustomer] = React.useState<string | null>(null);
  const appConfig = config.useAppConfig();
  const customers = React.useMemo(
    () => sortBy(rawCustomers, (c: models.ICustomer) => `${c.endUser?.lastName || ' '} ${c.endUser?.firstName || ' '}`),
    [rawCustomers],
  );
  const previousHighlightedCustomer = hooks.usePrevious(highlightedCustomer);

  hooks.useDidUpdate(() => {
    setSelectedCustomer(null);
  }, [dtcTypes]);

  React.useEffect(() => {
    if (previousHighlightedCustomer !== highlightedCustomer) {
      document.getElementById(`floating-customer-${previousHighlightedCustomer}`)?.classList.remove('highlighted');
    }

    if (highlightedCustomer) {
      document.getElementById(`floating-customer-${highlightedCustomer}`)?.classList.add('highlighted');
    }
  }, [highlightedCustomer, previousHighlightedCustomer]);

  const mapContextProps: MapContextProps = {
    dtcTypes,
    selectedCustomer,
    setSelectedCustomer,
    highlightedCustomer,
    setHighlightedCustomer,
    customers,
  };

  return (
    <MapContext {...mapContextProps}>
      <MapContainer zoom={11} scrollWheelZoom={true} zoomControl={false}>
        <ReactLeafletGoogleLayer apiKey={appConfig.googleMapsApiKey} />
        <ZoomControl position='topright' />
        <SetBounds />
        <Markers />
        <Hidden lgDown>
          <FloatingCustomers />
        </Hidden>
      </MapContainer>
    </MapContext>
  );
};
