import { AxiosResponse, AxiosError } from 'axios';
import { useProductIdentification, ProductIdentificationResponse } from '../../apis/productIdentification';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { ProductLine } from '../../../typings/models';
import SerialNumber from '../../../util/serialNumber';

export const useProductLineIdentification = (
  serialNumber: string,
  options?: UseQueryOptions<AxiosResponse<ProductIdentificationResponse>, AxiosError, ProductLine>,
): UseQueryResult<ProductLine> => {
  const boilerArticleNumber = SerialNumber.isValid(serialNumber)
    ? SerialNumber.extractArticleNumber(serialNumber)
    : serialNumber;
  return useProductIdentification<ProductLine>(boilerArticleNumber, {
    select: ({ data: { articleNumber, marketingName, nomenclature } }) => ({
      articleNumber,
      serialNumber,
      marketingName,
      nomenclatures: [nomenclature].filter(Boolean),
    }),
    ...options,
  });
};
