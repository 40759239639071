/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon02501VgDown01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon02501vgdown01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M283.47 368.62a11.28 11.28 0 0 1-8-3.32L139.15 229a11.34 11.34 0 0 1 16-16l128.32 128.25L411.75 213a11.33 11.33 0 0 1 16 16L291.49 365.3a11.3 11.3 0 0 1-8.02 3.32z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
