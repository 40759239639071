import { DeviceSettingsSettingValue } from '../../../../../api/apis/maintenance/v2/maintenance.types';
import { TranslationFnc } from '../../../../../typings/models';

/**
 * Fallback translation for String Values as there come no translation right now from the backend
 */
export const translateStringMeasuredValueToTitle = (
  settingValue: DeviceSettingsSettingValue,
  newValue: string,
  t: TranslationFnc,
) => {
  if (settingValue._actions?.[0].schema.description) {
    try {
      const translations = JSON.parse(settingValue._actions?.[0].schema.description).i18n;
      if (newValue in translations) {
        return translations[newValue];
      }
    } catch (e) {
      console.error(e);
    }
  }
  if (settingValue.id?.includes('heating.operationMode')) {
    return t(`backend.settings.control.vrc700.zone.heating.operationMode.${newValue}`);
  }
  if (settingValue.id?.includes('cooling.operationMode')) {
    return t(`backend.settings.control.vrc700.zone.cooling.operationMode.${newValue}`);
  }
  if (settingValue.id?.includes('operationMode.255')) {
    return t(`backend.settings.control.vrc700.domesticHotWater.operationMode.${newValue}`);
  }
  if (settingValue.id?.includes('operationMode')) {
    // fallback for any operation mode
    return t(`backend.settings.control.vrc700.zone.heating.operationMode.${newValue}`);
  }
  // no match - return value itself
  return newValue;
};
