import { Box, IconButton, Stack, styled } from '@mui/material';
import { Button, Popover, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon45101VgClose201 } from '@vaillant-professional-ui/component-libs-web';
import { useTranslation } from '@vaillant-professional-ui/pui-frontend-common';

interface MaxDatapointsTooltipProps {
  anchor: HTMLElement | null;
  onClose: () => void;
  onDontShowAgain: () => void;
  maxTimeSeries: number;
}

const usePresenter = ({ anchor, onClose, onDontShowAgain, maxTimeSeries }: MaxDatapointsTooltipProps) => {
  const t = useTranslation();

  return {
    isOpen: !!anchor,
    anchor,
    onClose,
    onDontShowAgain,
    title: t('SG_TIME_SERIES_MAX_DATA_POINTS_REACHED'),
    description: t('SG_TIME_SERIES_MAX_DATAPOINTS_HINT', { number: maxTimeSeries }),
    callToAction: t('SG_TIME_SERIES_MAX_DATAPOINTS_HINT_CONFIRM'),
  };
};

export const MaxDatapointsTooltip: React.FC<MaxDatapointsTooltipProps> = (props) => {
  const { isOpen, anchor, onClose, onDontShowAgain, title, description, callToAction } = usePresenter(props);

  return (
    <Popover
      open={isOpen}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Box p={2} maxWidth={260}>
        <Stack direction='column' alignItems='flex-start'>
          <Stack direction='row' width='100%' mb={1} alignItems='center' justifyContent='space-between'>
            <Typography variant='caption' bold>
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <Icon45101VgClose201 color='primary.main' />
            </IconButton>
          </Stack>
          <Typography variant='caption'>{description}</Typography>
          <StyledButton variant='text' onClick={onDontShowAgain}>
            {callToAction}
          </StyledButton>
        </Stack>
      </Box>
    </Popover>
  );
};

const StyledButton = styled(Button)`
  padding: 0;
`;
