import React from 'react';

import * as d3 from 'd3-time-format';

import { getD3Locale, I18nProvider as I18nProviderBase, store } from '@vaillant-professional-ui/pui-frontend-common';
import { useSelector } from 'react-redux';

// Normally, this hook should go to the common library. But using it from there didn't work.
// The new default locale was simply ignored. Setting it up here works.
const useSetD3Locale = () => {
  const country = useSelector(store.getCountry);
  const language = useSelector(store.getLanguage);
  React.useEffect(() => {
    getD3Locale(language, country)
      .then((locale) => {
        d3.timeFormatDefaultLocale(locale);
      })
      .catch(() => {
        console.log('FAILED');
      });
  }, [country, language]);
};

export const I18nProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  useSetD3Locale();
  return <I18nProviderBase>{children}</I18nProviderBase>;
};
