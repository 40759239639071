import { ErelaxProfile } from './erelax';

export interface TimeWindow {
  start: number; // minutes since midnight
  end: number; // minutes since midnight
}

export interface TimeWindowWithTargetTemperature extends TimeWindow {
  temperatureTarget: number;
}

export const applyCustomProfileCount = (profiles: ErelaxProfile[]): ErelaxProfile[] => {
  let nthCustomProfile = 0;
  return profiles.map((profile) => {
    return {
      ...profile,
      index: profile.type === 'CUSTOM' ? nthCustomProfile++ : 0
    };
  });
};

export interface TimeWindowWithProfile extends TimeWindow {
  profile: ErelaxProfile;
}

export const isTimeWindowWithTargetTemperature = (
  timeWindow?: TimeWindow
): timeWindow is TimeWindowWithTargetTemperature => {
  return !!timeWindow?.hasOwnProperty('temperatureTarget');
};

export const areTimeWindowsWithTargetTemperature = (
  timeWindows?: TimeWindow[]
): timeWindows is TimeWindowWithTargetTemperature[] => !!timeWindows?.every(isTimeWindowWithTargetTemperature);

export const isTimeWindowWithProfile = (timeWindow?: TimeWindow): timeWindow is TimeWindowWithProfile => {
  return !!timeWindow?.hasOwnProperty('profile');
};

export const areTimeWindowsWithProfile = (timeWindows?: TimeWindow[]): timeWindows is TimeWindowWithProfile[] =>
  !!timeWindows?.every(isTimeWindowWithProfile);

const timeWindowsCanBeMerged = <T extends TimeWindow>(previous: T | undefined, current: T) => {
  if (isTimeWindowWithTargetTemperature(previous) && isTimeWindowWithTargetTemperature(current)) {
    return previous && previous.end === current.start && previous.temperatureTarget === current.temperatureTarget;
  }

  if (isTimeWindowWithProfile(previous) && isTimeWindowWithProfile(current)) {
    return previous && previous.end === current.start && previous.profile.id === current.profile.id;
  }

  return previous && previous.end === current.start;
};

type HH =
  | '00'
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23';

type MM =
  | '00'
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'
  | '32'
  | '33'
  | '34'
  | '35'
  | '36'
  | '37'
  | '38'
  | '39'
  | '40'
  | '41'
  | '42'
  | '43'
  | '44'
  | '45'
  | '46'
  | '47'
  | '48'
  | '49'
  | '50'
  | '51'
  | '52'
  | '53'
  | '54'
  | '55'
  | '56'
  | '57'
  | '58'
  | '59';

export type TimeString = `${HH}:${MM}` | '24:00';

export const isTimeString = (time: string, allow24h = true): time is TimeString => {
  if (allow24h && time === '24:00') {
    return true;
  }
  const validTimeFormat = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  return Boolean(time?.match(validTimeFormat));
};

export const getHourAndMinute = (time: string) => {
  if (isTimeString(time)) {
    const [hour, minute] = time.split(':', 2).map((v) => parseInt(v, 10));
    return [hour, minute];
  }

  console.error(`getHourAndMinute: invalid time representation '${time}'`);
  return [0, 0]; // FIXME: better throw?
};

export const formatTimeToMinutesSinceMidnight = (time: string): number => {
  const [hour, minute] = getHourAndMinute(time);
  return hour * 60 + minute;
};

export const formatTimeFromMinutesSinceMidnight = (minutes: number): string => {
  if (!(minutes >= 0 && minutes <= 24 * 60)) {
    console.error(`formatTimeFromMinutesSinceMidnight: invalid minutes value '${minutes}'`);
    return '';
  }

  return `${String(Math.floor(minutes / 60)).padStart(2, '0')}:${String(minutes % 60).padStart(2, '0')}`;
};

const isBetween = (time: number, window: TimeWindow) => time >= window.start && time <= window.end;

const doMergeTimeWindows = <T extends TimeWindow>(timeWindows: T[], timeWindowsToInsert: T | T[]): T[] => {
  if (Array.isArray(timeWindowsToInsert)) {
    return timeWindowsToInsert.reduce((acc: T[], newValue: T) => {
      return doMergeTimeWindows(acc, newValue);
    }, timeWindows);
  }
  const editedTimeWindows = timeWindows.reduce((windows: T[], current) => {
    if (current.end <= timeWindowsToInsert.start || current.start >= timeWindowsToInsert.end) {
      return [...windows, current];
    } else if (current.start < timeWindowsToInsert.start && isBetween(current.end, timeWindowsToInsert)) {
      return [...windows, { ...current, end: timeWindowsToInsert.start }];
    } else if (isBetween(current.start, timeWindowsToInsert) && current.end > timeWindowsToInsert.end) {
      return [...windows, { ...current, start: timeWindowsToInsert.end }];
    } else if (isBetween(current.start, timeWindowsToInsert) && isBetween(current.end, timeWindowsToInsert)) {
      return windows;
    } else if (current.start < timeWindowsToInsert.start && current.end > timeWindowsToInsert.end) {
      return [
        ...windows,
        { ...current, end: timeWindowsToInsert.start },
        { ...current, start: timeWindowsToInsert.end }
      ];
    } else {
      return windows;
    }
  }, []);

  const sortedTimeWindows = [...editedTimeWindows, timeWindowsToInsert].sort((a, b) => a.start - b.start);

  return sortedTimeWindows.reduce((windows: T[], current) => {
    const previousWindows = windows.slice(0, windows.length - 1);
    const previous = windows[windows.length - 1];

    if (timeWindowsCanBeMerged(previous, current)) {
      return [...previousWindows, { ...previous, end: current.end }];
    } else {
      return [...windows, current];
    }
  }, []);
};

export const mergeTimeWindows = <T extends TimeWindow>(timeWindows: T[], timeWindowsToInsert: T | T[]): T[] => {
  const sanitizedTimeWindows = doMergeTimeWindows([], timeWindows);
  return doMergeTimeWindows(sanitizedTimeWindows, timeWindowsToInsert);
};
