export const variantMap = {
  default: {
    iconSize: 48,
    title: {
      gap: 0,
      variant: 'h2'
    },
    subtitle: {
      variant: 'h3'
    }
  },
  big: {
    iconSize: 46,
    title: {
      gap: 1,
      variant: 'h1'
    },
    subtitle: {
      variant: 'body'
    }
  }
} as const;
