import { Country, Language, Locale } from '../typings/models';

export interface I18nRecord {
  language: Language;
  locale: Locale;
  full: string;
}

export type I18nConfig = I18nRecord[];

export const i18nConfig: I18nConfig = [
  { language: 'de', locale: 'de-AT', full: 'Österreichisch' },
  { language: 'de', locale: 'de-CH', full: 'Schweizerdeutsch' },
  { language: 'da', locale: 'da-DK', full: 'Dansk' },
  { language: 'et', locale: 'et-EE', full: 'Eesti keel' },
  { language: 'nl', locale: 'nl-BE', full: 'België' },
  { language: 'cs', locale: 'cs-CZ', full: 'Czech' },
  { language: 'de', locale: 'de-DE', full: 'Deutsch' },
  { language: 'en', locale: 'en-GB', full: 'English' },
  { language: 'es', locale: 'es-ES', full: 'Español' },
  { language: 'fi', locale: 'fi-FI', full: 'Suomi' },
  { language: 'fr', locale: 'fr-FR', full: 'Français' },
  { language: 'it', locale: 'it-IT', full: 'Italiano' },
  { language: 'hr', locale: 'hr-HR', full: 'Hrvatska' },
  { language: 'hu', locale: 'hu-HU', full: 'Magyar' },
  { language: 'nl', locale: 'nl-NL', full: 'Nederlands' },
  { language: 'no', locale: 'no-NO', full: 'Norsk' },
  { language: 'pl', locale: 'pl-PL', full: 'Polski' },
  { language: 'ro', locale: 'ro-RO', full: 'Romanian' },
  { language: 'sv', locale: 'sv-SE', full: 'Svenska' },
  { language: 'sk', locale: 'sk-SK', full: 'Slovenský' },
  { language: 'tr', locale: 'tr-TR', full: 'Türkçe' },
  { language: 'uk', locale: 'uk-UA', full: 'yкраїнська' },
];

export const localisedCountries: Record<Country, string> = {
  AT: 'Österreich',
  BE: 'België',
  CH: 'Schweiz',
  CZ: 'Česko',
  DE: 'Deutschland',
  DK: 'Danmark',
  EE: 'Eesti',
  ES: 'España',
  FI: 'Suomi',
  FR: 'France',
  GB: 'United Kingdom',
  HR: 'Hrvatska',
  HU: 'Magyarország',
  IT: 'Italiano',
  NL: 'Nederland',
  NO: 'Norge',
  PL: 'Polski',
  RO: 'România',
  SE: 'Sverige',
  SK: 'Slovensko',
  TR: 'Türkiye',
  UA: 'Україна',
};

export const defaultLanguages: Record<Country, Language> = {
  AT: 'de',
  BE: 'nl',
  CH: 'de',
  CZ: 'cs',
  DE: 'de',
  DK: 'da',
  EE: 'et',
  ES: 'es',
  FI: 'fi',
  FR: 'fr',
  GB: 'en',
  HR: 'hr',
  HU: 'hu',
  IT: 'it',
  NL: 'nl',
  NO: 'no',
  PL: 'pl',
  RO: 'ro',
  SE: 'sv',
  SK: 'sk',
  TR: 'tr',
  UA: 'uk',
} as const;

const defaultCountries: Record<Language, Country> = {
  cs: 'CZ',
  da: 'DK',
  de: 'DE',
  en: 'GB',
  es: 'ES',
  et: 'EE',
  fi: 'FI',
  fr: 'FR',
  hr: 'HR',
  hu: 'HU',
  it: 'IT',
  no: 'NO',
  nl: 'NL',
  pl: 'PL',
  ro: 'RO',
  sk: 'SK',
  sv: 'SE',
  tr: 'TR',
  uk: 'UA',
} as const;

export const countryToLanguage = (country: Country): Language | null => defaultLanguages[country] ?? null;
export const languageToCountry = (language: Language): Country | null => defaultCountries[language] ?? null;

export const languages: Language[] = Object.keys(defaultCountries) as Array<Language>;
export const locales: Locale[] = i18nConfig.map((record) => record.locale);
export const getConfigForLanguage = (language: Language): I18nRecord =>
  i18nConfig.find((record) => record.language === language)!;

/**
 * Return language part of a given locale.
 * @param locale
 * @param returnDefault
 */
export function getLanguageShort(locale: string): Language;
export function getLanguageShort(locale: string, returnDefault: true): Language;
export function getLanguageShort(locale: string, returnDefault: false): Language | null;
export function getLanguageShort(locale = 'en', returnDefault = true): Language | null {
  const short = locale.substring(0, Math.max(2, locale.indexOf('-')));
  if (isLanguageSupported(short)) {
    return short as Language;
  }
  if (returnDefault === true) {
    return 'en';
  }
  return null;
}

export const isLanguageSupported = (language: string): boolean => {
  return i18nConfig.some((record) => record.language === language);
};
