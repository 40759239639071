import React from 'react';
import { PermissionGate as PermissionGateBase } from '@vaillant-professional-ui/component-libs-common';
import { PermissionGateProps } from './PermissionGate.types';
import { areFeaturesAvailableInCountry } from './PermissionGate.util';
import { countryAvailabililty } from '../../../libs/permissions';

export const PermissionGate: React.FC<React.PropsWithChildren<PermissionGateProps>> = (props) => {
  const { country, requiredPermissions } = props;
  const isFeaturesAvailableInCountry = areFeaturesAvailableInCountry(
    requiredPermissions,
    country,
    countryAvailabililty,
  );

  if (!isFeaturesAvailableInCountry) {
    return props.fallbackComponent ?? null;
  }

  return <PermissionGateBase {...props} />;
};
