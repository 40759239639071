import { colors } from '../../../components/organisms/WaterPressurePrediction';
import { TranslationFnc } from '../../../typings/models';
import { DiagnosticsData } from '../../apis/diagnostics';
import { PredictionData } from '../../apis/maintenance/v1';

// Water pressure threshold, beyond which the chard will be marked as unhealthy
const CRITICAL_WATER_PRESSURE = 0.8;

const getLastPressureValueFromPredictionData = (predictionData?: PredictionData): number | null =>
  predictionData?.measurements.values?.length
    ? predictionData.measurements.values[predictionData.measurements.values.length - 1].y
    : null;

export const getWaterPressureFromDiagnosticDataOrFallBackToPredictionData = (
  diagnosticsData: DiagnosticsData | undefined,
  predictionData: PredictionData | undefined,
): number | null => {
  const diagnosticsPressure = diagnosticsData?.data?.boiler?.centralHeating?.waterPressure?.value ?? null;
  const historicPressure = getLastPressureValueFromPredictionData(predictionData);
  const pressure = diagnosticsPressure ?? historicPressure;
  return pressure !== null ? Math.round(pressure * 10) / 10 : null;
};

const errorIsPredicted = (predictionData: PredictionData | null) =>
  Boolean(
    predictionData?.prediction.isPredictable &&
      predictionData?.healthiness.isUnHealthy &&
      predictionData?.prediction.daysUntilCritical !== undefined,
  );

type GetHealthInformation = (
  predictionData: PredictionData | null,
  waterPressure: number | null,
  t: TranslationFnc,
) => { warning: string; isUnHealthy: boolean };

const getHealthInformation: GetHealthInformation = (predictionData, waterPressure, t) => {
  if (errorIsPredicted(predictionData)) {
    return {
      isUnHealthy: true,
      warning:
        predictionData?.prediction.daysUntilCritical === 0
          ? t('SG_LESS_THAN_ONE_DAY')
          : t('SG_CRITICAL_IN_XX_DAYS', { 0: predictionData?.prediction.daysUntilCritical }),
    };
  }
  if ((waterPressure ?? CRITICAL_WATER_PRESSURE) < CRITICAL_WATER_PRESSURE) {
    return {
      isUnHealthy: true,
      warning: t('SG_CRITICAL_VALUE'),
    };
  }
  return { isUnHealthy: false, warning: '' };
};

type GetDisplayParameters = (
  predictionData: PredictionData | null,
  waterPressure: number | null,
  t: TranslationFnc,
) => {
  backgroundColor: string;
  isUnHealthy: boolean;
  warning: string;
  arrowDirection: number;
  textColor: string;
  title: string;
};

export const getDisplayParameters: GetDisplayParameters = (predictionData, waterPressure, t) => {
  const { warning, isUnHealthy } = getHealthInformation(predictionData, waterPressure, t);
  const arrowDirection = isUnHealthy ? 220 : 180;
  const theme = isUnHealthy ? 'unhealthy' : 'healthy';
  const [textColor, backgroundColor] = colors[theme];
  const title = isUnHealthy ? t('SG_MONITORING_WATPR_PREDICTION') : t('SG_MONITORING_WATPR');
  return { warning, isUnHealthy, arrowDirection, textColor, backgroundColor, title };
};

export const overwritePredictionDataIfPresent: (
  predictionData: PredictionData | undefined,
  isUnHealthy: boolean,
) => PredictionData | null = (predictionData, isUnHealthy) => {
  if (predictionData) {
    return { ...predictionData, isUnHealthy };
  }
  return null;
};
