import { Box } from '@mui/material';
import { keyframes } from '@mui/material';
import { styled } from '@vaillant-professional-ui/component-libs-web';
import { Icon12201VgUpdate01 } from '@vaillant-professional-ui/component-libs-web';

const spinAnimation = keyframes`
  0% { transform: rotate(0deg)}
  100% { transform: rotate(360deg)}
`;

export const OuterContainer = styled(Box)`
  display: block;
  text-align: center;
`;

export const IconContainer = styled(Box)<{ $isTransparent: boolean }>`
  padding: 12px;
  display: inline-block;
  border-radius: 50%;
  opacity: ${({ $isTransparent }) => ($isTransparent ? 1 : 0.4)};
  animation: ${spinAnimation} 1.5s linear infinite;
`;

export const Icon = styled(Icon12201VgUpdate01)`
  display: block;
  transform: rotateX(180deg);
`;

export const Modal = styled('div')`
  z-index: 999;
  flex: 1;
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;
