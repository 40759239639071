import { AxiosResponse } from 'axios';

import { DeviceDetailsResponseTli } from '../../../../../api/apis/maintenance/v2';
import { MeasuredValue, TliDhw, TliSystem, TliZone } from '../../../../../api/apis/maintenance/v2/maintenance.types';
import { WithCircuitIndex, WithZoneIndex } from '../../../../../api/apis/settings/settings.types';
import {
  patchCircuitFunctionFactory,
  updateTliCircuits,
  updateTliDhw,
  updateTliZones,
} from '../../../../../api/useCases/TliReadWrite/OptimisticUpdateAgainstSettingsApiUtil';
import { patchTliResponseV2 } from '../../../../../api/useCases/TliReadWrite/OptimisticUpdateAgainstSettingsApiUtil/generalUtil';

export const createZoneUpdater =
  (responseKey: string) =>
  (actionPropertyKey: string) =>
  (
    updateData: Record<string, number | string | boolean> & WithZoneIndex,
    previousValue: AxiosResponse<DeviceDetailsResponseTli>,
  ) => {
    return updateTliZones(updateData, previousValue, (zone: TliZone) => {
      const measuredValue = zone[responseKey as keyof TliZone] as MeasuredValue;
      if (!measuredValue) {
        return zone;
      }

      return {
        ...zone,
        [responseKey]: { ...measuredValue, value: updateData[actionPropertyKey] },
      };
    });
  };

export const createZoneBranchUpdater =
  (branchKey: 'heating') =>
  (responseKey: keyof Required<TliZone>[typeof branchKey]) =>
  (actionPropertyKey: string) =>
  (
    updateData: Record<string, number | string | boolean> & WithZoneIndex,
    previousValue: AxiosResponse<DeviceDetailsResponseTli>,
  ) => {
    return updateTliZones(updateData, previousValue, (zone: TliZone) => {
      const measuredValue = zone[branchKey]?.[responseKey] as unknown as MeasuredValue;
      if (!measuredValue) {
        return zone;
      }

      return {
        ...zone,
        [branchKey]: {
          ...zone[branchKey],
          [responseKey]: { ...measuredValue, value: updateData[actionPropertyKey] },
        },
      };
    });
  };

export const createZoneHeatingUpdater = createZoneBranchUpdater('heating');

export const createCircuitUpdater =
  (responseKey: string) =>
  (actionPropertyKey: string) =>
  (
    updateData: Record<string, number | string | boolean> & WithCircuitIndex,
    previousValue: AxiosResponse<DeviceDetailsResponseTli>,
  ) => {
    const patchFunction = patchCircuitFunctionFactory(updateData[actionPropertyKey], responseKey);
    return updateTliCircuits(updateData, previousValue, patchFunction);
  };

export const createDhwUpdater =
  (responseKey: keyof Omit<TliDhw, 'index' | 'schedule' | '_actions'>) =>
  (actionPropertyKey: string) =>
  (updateData: Record<string, number | string | boolean>, previousValue: AxiosResponse<DeviceDetailsResponseTli>) => {
    return updateTliDhw(previousValue, (dhw: TliDhw) => {
      const measuredValue = dhw[responseKey];
      if (!measuredValue) {
        return dhw;
      }

      return {
        ...dhw,
        [responseKey]: { ...measuredValue, value: updateData[actionPropertyKey] },
      };
    });
  };

export const createSystemUpdater =
  (responseKey: keyof Omit<TliSystem, '_actions'>) =>
  (actionPropertyKey: string) =>
  (updateData: Record<string, number | string | boolean>, previousValue: AxiosResponse<DeviceDetailsResponseTli>) => {
    const measuredValue = previousValue.data.system?.[responseKey] as unknown as MeasuredValue;
    if (!measuredValue) {
      return previousValue;
    }
    return patchTliResponseV2(previousValue, {
      system: {
        ...previousValue.data.system,
        [responseKey]: { ...measuredValue, value: updateData[actionPropertyKey] },
      },
    });
  };

export const createSystemBranchUpdater =
  (branchKey: 'heating' | 'domestic_hot_water' | 'backup_heater' | 'cylinder_temperature_sensor') =>
  (responseKey: string) =>
  (actionPropertyKey: string) =>
  (updateData: Record<string, number | string | boolean>, previousValue: AxiosResponse<DeviceDetailsResponseTli>) => {
    const branch = previousValue.data.system?.[branchKey];
    const measuredValue = branch?.[responseKey as keyof typeof branch] as unknown as MeasuredValue;
    if (!measuredValue) {
      return previousValue;
    }
    return patchTliResponseV2(previousValue, {
      system: {
        ...previousValue.data.system,
        [branchKey]: {
          ...previousValue.data.system?.[branchKey],
          [responseKey]: {
            ...measuredValue,
            value: updateData[actionPropertyKey],
          },
        },
      },
    });
  };

export const createSystemHeatingUpdater = createSystemBranchUpdater('heating');
export const createSystemDomesticHotWaterUpdater = createSystemBranchUpdater('domestic_hot_water');
export const createSystemBackupHeaterUpdater = createSystemBranchUpdater('backup_heater');
export const createSystemCylinderTemperatureSensorUpdater = createSystemBranchUpdater('cylinder_temperature_sensor');
