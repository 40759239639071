import React, { useEffect } from 'react';
import { hooks, store, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { clearTracking } from '../../providers/firebase/provider';
import { reportError } from '../../services/Utility';

export const LogoutHandler: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isDemo, status, prevStatus } = useAuthContext();
  const { shutdownDemo } = hooks.useDemoServer();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (prevStatus.isAuthenticated && !status.isAuthenticated) {
      void (async () => {
        try {
          dispatch(store.resetApp());
          shutdownDemo();
          await queryClient.resetQueries();
          await clearTracking();
        } catch (e) {
          reportError('reset-app', (e as Error).message);
        }
      })();
    }
  }, [dispatch, prevStatus.isAuthenticated, isDemo, queryClient, shutdownDemo, status, navigate]);

  return <>{children}</>;
};

export default LogoutHandler;
