import { util } from '@vaillant-professional-ui/component-libs-common';
import { keyBy, mapKeys, mapValues } from 'lodash';
import { nonNullable } from '../../../util';
import { TliDomesticHotWater, TliResponse } from '../../apis/maintenance/v1/maintenance.types';
import { TliSchedulePayload } from '../../apis/schedules/v2/schedules.types';
import { UpdateFnc } from '../../hooks';

export interface Schedule {
  label: string;
  key: string;
  values: util.TimeWindow[];
}

const shortToLongWeekday: Record<string, string> = {
  mon: 'monday',
  tue: 'tuesday',
  wed: 'wednesday',
  thu: 'thursday',
  fri: 'friday',
  sat: 'saturday',
  sun: 'sunday',
};

export const longToShortWeekday = Object.fromEntries(
  Object.entries(shortToLongWeekday).map(([key, value]) => [value, key]),
);

export const updateSchedules: UpdateFnc<TliResponse, TliSchedulePayload> = (payloadArray, previousValue) => {
  const payload = payloadArray[0];

  const previousDhwSchedule = previousValue.data.control.domesticHotWater?.[0];

  if (!payload.domesticHotWater && !previousDhwSchedule) {
    throw new Error('Payload and previous schedule are missing');
  }

  const newDhwSchedule = payload.domesticHotWater
    ? {
        ...previousDhwSchedule,
        schedule: {
          ...previousDhwSchedule?.schedule,
          ...mapKeys(payload.domesticHotWater.hotWaterSchedule, (_, key) => shortToLongWeekday[key]),
        },
      }
    : previousDhwSchedule;

  const previousZones = previousValue.data.control.zones;
  const newZones = payload.centralHeating
    ? previousZones.map((previousZone) => {
        const newZone = payload.centralHeating?.heatingSchedules?.zones?.find(
          (newZone) => newZone.index === previousZone.index,
        );

        return newZone
          ? {
              ...previousZone,
              heating: {
                ...previousZone.heating,
                schedule: {
                  ...previousZone.heating?.schedule,
                  ...Object.fromEntries(
                    Object.entries(newZone)
                      .map(([key, value]) =>
                        shortToLongWeekday[key] && typeof value !== 'number'
                          ? [
                              shortToLongWeekday[key],
                              value.map((timeslot) => ({
                                ...timeslot,
                                temperatureTarget: timeslot.targetRoomTemperature,
                              })),
                            ]
                          : null,
                      )
                      .filter(nonNullable),
                  ),
                },
              },
            }
          : previousZone;
      })
    : previousZones;

  return {
    ...previousValue,
    data: {
      ...previousValue.data,
      control: {
        ...previousValue.data.control,
        domesticHotWater: [newDhwSchedule as TliDomesticHotWater],
        zones: newZones,
      },
    },
  };
};

export const schedulesToDhwSchedules = (serialNumber: string, schedules: Schedule[]): TliSchedulePayload => {
  const hotWaterSchedule = mapValues(
    keyBy(schedules, (s) => {
      return longToShortWeekday[s.key];
    }),
    (scheduleObject) => {
      return scheduleObject.values;
    },
  );
  return [
    {
      serialNumber,
      type: 'CONTROL/VRT-380',
      domesticHotWater: {
        hotWaterSchedule,
      },
    },
  ];
};
