import { Country, Language } from '../typings/models';
import { languageToCountry } from '../i18n';

type Weekday = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

// @see https://stackoverflow.com/a/60247391/146283
const weekdayDateMap: Record<Weekday, Date> = {
  Mon: new Date('2020-01-06'),
  Tue: new Date('2020-01-07'),
  Wed: new Date('2020-01-08'),
  Thu: new Date('2020-01-09'),
  Fri: new Date('2020-01-10'),
  Sat: new Date('2020-01-11'),
  Sun: new Date('2020-01-12'),
};

const shortWeekdays = Object.keys(weekdayDateMap) as Array<keyof typeof weekdayDateMap>;

export const getDayOfWeek = (shortName: Weekday, locale = 'en-US', length: 'short' | 'long' | 'narrow' = 'short') =>
  new Intl.DateTimeFormat(locale, { weekday: length }).format(weekdayDateMap[shortName]);

export const getDaysOfWeek = (locale = 'en-US', length: 'short' | 'long' | 'narrow' = 'short') =>
  shortWeekdays.map((shortName) => getDayOfWeek(shortName, locale, length));

export const guessCountry = (countries: Country[], _browserLanguage?: string): Country => {
  const [browserLanguage, browserCountry] = _browserLanguage?.split('-') || [];
  if (countries.includes(browserCountry as Country)) {
    return browserCountry as Country;
  }
  const detectedCountry = languageToCountry(browserLanguage as Language);
  if (detectedCountry && countries.includes(detectedCountry)) {
    return detectedCountry;
  }

  return countries[0] as Country;
};
