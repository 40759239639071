import React from 'react';
import { DataPointsTableProps } from './DataPointsTable.types';
import { GridItem, GridContainer } from './DataPointsTable.styled';

export const DataPointsTable: React.FC<DataPointsTableProps> = ({ children: _children }) => {
  const children: React.ReactNode[] = Array.isArray(_children) ? _children : [_children];

  return (
    <GridContainer $numChildren={children.length}>
      {React.Children.toArray(children).map((child, index) => (
        <GridItem key={index} $numSiblings={children.length}>
          {child}
        </GridItem>
      ))}
    </GridContainer>
  );
};
