import { Card } from '@mui/material';
import { styled, css } from '@mui/material';
import { Severity } from './NotificationItem.types';

export const NotificationItemStyled = styled(Card)<{ severity: Severity }>`
  border-radius: 0;
  padding: 0;
  & .content {
    cursor: pointer;
  }
  & .button {
    padding: 8px;
  }
  ${({ severity, theme }) => {
    switch (severity) {
      case 'success':
        return css`
          background-color: ${theme.palette.primary.lightest};
          '& svg': {
            color: ${theme.palette.primary.main};
          }
        `;
      case 'warning':
        return css`
          background-color: ${theme.palette.warning.lightest};
          '& svg': {
            color: ${theme.palette.common.black};
          }
        `;
      case 'error':
        return css`
          background-color: ${theme.palette.error.main};
          & p {
            color: ${theme.palette.common.white};
          }
          '& svg': {
            color: ${theme.palette.common.white};
          }
        `;
      case 'info':
        return css`
          background-color: ${theme.palette.common.white};
        `;
    }
  }}
`;
