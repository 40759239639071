import { Schema } from '../../apis/maintenance/v2/maintenance.types';

const getWriteParams = (schema?: Schema) => {
  if (schema?.minimum == null || schema?.maximum == null || schema?.multipleOf == null) {
    return null;
  }

  return {
    minimum: schema.minimum,
    maximum: schema.maximum,
    stepSize: schema.multipleOf,
  };
};

export { getWriteParams };
