import * as React from 'react';
import { format } from 'date-fns';
import { getDateFormat } from './DateRangePicker.util';
import { DatePickerMode } from './DateRangePicker.types';
import { ButtonProps, DatePickerInputButton } from '@vaillant-professional-ui/component-libs-web';

interface InputProps {
  startDate: Date;
  endDate?: Date;
  locale: Locale;
  mode: DatePickerMode;
  isOpen: () => boolean;
  onClick?: () => void;
}

export const Input = React.forwardRef<React.FC<ButtonProps>, InputProps>(function Input(
  { onClick, mode, startDate, endDate, locale, isOpen },
  ref,
) {
  const formatDate = (date: Date) => format(date, getDateFormat(mode), { locale });

  const formatted =
    mode === 'WEEK' ? `${formatDate(startDate)} – ${endDate ? formatDate(endDate) : ''}` : formatDate(startDate);

  return (
    <DatePickerInputButton ref={ref} variant='text' onClick={isOpen() ? undefined : onClick}>
      {formatted}
    </DatePickerInputButton>
  );
});
