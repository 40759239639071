import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { models, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { useLoginWithToken } from './TokenLogin.hooks';
import ActivitySpinner from '../ActivitySpinner';
import { reportError, reportEvent } from '../../services/Utility';

export const TokenLogin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { country, token, gatewaySerialNumber } = useParams<{
    country: models.Country;
    token: string;
    gatewaySerialNumber: string;
  }>();
  // This is fine, because if one of country, token or gateway serial number is not set, the URL does not match.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { errorCode, boilerSerialNumber } = useLoginWithToken(country!, token!, gatewaySerialNumber!);
  const { isDelegated } = useAuthContext();

  if (isDelegated && boilerSerialNumber) {
    reportEvent('delegated-token-redirect-customer');
    return <Navigate to={`/customers/${boilerSerialNumber}`} />;
  }

  if (errorCode != null) {
    reportError('delegated-token-redirect-permission-denied', `Token login returned error ${errorCode}`);
    return <Navigate to={`/permission-denied/${errorCode}`} />;
  }

  return <ActivitySpinner onScreen={true} loading={true} />;
};
