/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon06201VgConfigurationSettings01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon06201vgconfigurationsettings01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M449.31 244.12h-21a149.1 149.1 0 0 0-14.47-34.93l14.87-14.87a15.78 15.78 0 0 0 0-22.31l-33.46-33.46a15.78 15.78 0 0 0-22.31 0L358 153.42A148.92 148.92 0 0 0 323.11 139v-21a15.79 15.79 0 0 0-15.78-15.78H260A15.78 15.78 0 0 0 244.22 118v21a148.85 148.85 0 0 0-34.92 14.47l-14.88-14.88a15.78 15.78 0 0 0-22.31 0L138.66 172a15.78 15.78 0 0 0 0 22.31l14.87 14.87a148.92 148.92 0 0 0-14.47 34.93H118a15.77 15.77 0 0 0-15.78 15.77v47.33A15.78 15.78 0 0 0 118 323h21a148.85 148.85 0 0 0 14.47 34.92l-14.82 14.88a15.78 15.78 0 0 0 0 22.31l33.47 33.46a15.78 15.78 0 0 0 22.31 0l14.87-14.87a148.92 148.92 0 0 0 34.93 14.47v21A15.77 15.77 0 0 0 260 465h47.33a15.78 15.78 0 0 0 15.77-15.8v-21a148.92 148.92 0 0 0 34.9-14.5l14.88 14.87a15.78 15.78 0 0 0 22.31 0l33.46-33.46a15.78 15.78 0 0 0 0-22.31l-14.87-14.87a148.92 148.92 0 0 0 14.5-34.93h21a15.77 15.77 0 0 0 15.78-15.77V259.9a15.79 15.79 0 0 0-15.75-15.78zM283.46 391.18a107.72 107.72 0 1 1 107.72-107.72 107.71 107.71 0 0 1-107.72 107.72z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
