import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { selectors, models, util } from '@vaillant-professional-ui/pui-frontend-common';
import { CustomerSummary } from '../CustomerSummary';
import { usePermissions } from '../PermissionGate';
import Button from '../Button';
import { Link } from './DeviceMap.styles';

interface CustomerPopupProps {
  dtcTypes?: models.DtcTypeName[];
  customer: models.ICustomer | null;
}

export const CustomerPopup: React.FC<React.PropsWithChildren<CustomerPopupProps>> = ({ customer, dtcTypes }) => {
  const { hasPermissions: formatAddressUK } = usePermissions(['COUNTRY_UK']);
  const navigate = useNavigate();
  const address = customer?.customer?.address;
  if (!customer) {
    return null;
  }

  return (
    <Box p={1}>
      <Box mb={4}>
        <CustomerSummary dtcTypes={dtcTypes} customer={customer} />
      </Box>
      {address ? (
        <Box mb={4}>
          <Typography variant='caption' letterCase='allCaps' color='text.secondary' i18nKey='SG_ADDRESS' />
          <Typography>{util.address.formatAddress(customer.customer.address, formatAddressUK)}</Typography>
          <Typography>
            {address?.postalCode ?? ''} {address?.city ?? ''}
          </Typography>
        </Box>
      ) : null}
      <Box mb={6}>
        <Typography variant='caption' letterCase='allCaps' color='text.secondary' i18nKey='SG_CONTACT' />
        {!!customer?.customer?.phone && (
          <Typography>
            <Link href={`tel:${customer.customer.phone}`}>{customer.customer.phone}</Link>
          </Typography>
        )}
      </Box>
      <Box display='flex' justifyContent='center'>
        <Button
          disabled={!selectors.customer.hasConsentGiven(customer)}
          variant='contained'
          color='primary'
          onClick={() => navigate(`/customers/${selectors.customer.getBoilerSerialNumber(customer)}`)}
          i18nKey='SG_JOBDETAILS_VIEWDETAILS'
          testId='dashboardMapGotoCustomerDetails'
        />
      </Box>
    </Box>
  );
};
