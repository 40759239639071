import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { SelectionBadgeProps } from './SelectionBadge.types';
import { Icon43501VgCheckConfirm201 } from '../../atoms/Icon/Icon43501VgCheckConfirm201';
import { Typography } from '../../atoms/Typography';
import { Box } from '@mui/material';

export const SelectionBadge: React.FunctionComponent<SelectionBadgeProps> = ({ testIdKey, title }) => {
  const testId = useInstanceId('selectionbadge', testIdKey);

  return (
    <Box
      display='inline-flex'
      flexDirection='row'
      bgcolor='primary.lightest'
      alignItems='center'
      borderRadius={1}
      p={1}
      {...testId('root').testIdAttributes}
    >
      <Icon43501VgCheckConfirm201 width={16} height={16} color='primary.main' />
      <Typography ml={1} variant='smallCopy' color='primary.main'>
        {title}
      </Typography>
    </Box>
  );
};
