import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { ChartData, useMonitoringDetails } from '../../../api/useCases/MonitoringDetails';
import { useCustomer } from '../../../api/apis/maintenance/v1/maintenance.hooks';
import { useMemo } from 'react';

const transformChartData = (entry: ChartData, isDtc: boolean = false) => {
  return {
    ...entry,
    values: entry.values
      .map((v) => {
        const modifiedTimestamp = isDtc ? v.timestamp : v.timestamp + 'Z';
        return {
          ...v,
          timestamp: new Date(modifiedTimestamp),
        };
      })
      .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
  };
};

export const useSystemMonitoring = (boilerSerialNumber: string, maxDate: number, hoursToLookBack: number) => {
  const t = useTranslation();
  const {
    error: fetchCustomerError,
    data: customerData,
    isLoading: isLoadingCustomer,
  } = useCustomer({
    boilerSerialNumber,
  });

  const gatewaySerialNumber = customerData?.system.gateway?.serialNumber;

  // The call to useMonitoringDetails is just temporary and needs to be replaced when switching
  // to the new history API of team Atlas
  const {
    isError: isMonitoringDetailsError,
    isLoading: isLoadingMonitoringDetails,
    isSuccess,
    pressureChartData,
    literPerHourChartData,
    temperatureChartData,
  } = useMonitoringDetails({
    boilerSerialNumber,
    gatewaySerialNumber,
    now: maxDate,
    hoursToLookBack,
  });

  const error = fetchCustomerError || isMonitoringDetailsError;
  const isLoading = isLoadingCustomer || isLoadingMonitoringDetails;

  const timeSeriesPressure = useMemo(
    () => pressureChartData.filter((entry) => entry.type === 'timeseries').map((entry) => transformChartData(entry)),
    [pressureChartData],
  );

  const timeSeriesLiterPerHour = useMemo(
    () =>
      literPerHourChartData.filter((entry) => entry.type === 'timeseries').map((entry) => transformChartData(entry)),
    [literPerHourChartData],
  );

  const timeSeriesTemperatureChartData = useMemo(
    () => temperatureChartData.filter((entry) => entry.type === 'timeseries').map((entry) => transformChartData(entry)),
    [temperatureChartData],
  );

  const dtcs = useMemo(
    () => pressureChartData.filter((entry) => entry.type === 'timebar').map((entry) => transformChartData(entry, true)),
    [pressureChartData],
  );

  const data = useMemo(
    () =>
      [
        { title: t('SG_MONITORING_DETAILS_TEMPERATURE_TITLE'), lines: timeSeriesTemperatureChartData, dtcs },
        { title: t('SG_MONITORING_DETAILS_WATER_PRESSURE_TITLE'), lines: timeSeriesPressure, dtcs },
        { title: t('SG_MONITORING_DETAILS_LITER_PER_HOUR'), lines: timeSeriesLiterPerHour, dtcs },
      ].filter((entry) => entry.lines?.length),
    [t, timeSeriesTemperatureChartData, dtcs, timeSeriesPressure, timeSeriesLiterPerHour],
  );

  return { error, isLoading, isSuccess, data };
};
