import { Box } from '@mui/material';

import { WaterPressurePredictionCardContainer } from '../../../components/WaterPressurePrediction/WaterPressurePredictionCard.container';

export const WaterPressurePredictionTab: React.FC = () => {
  return (
    <Box mb={3} maxWidth={1000}>
      <WaterPressurePredictionCardContainer />
    </Box>
  );
};
