import { TranslateFnc } from '@vaillant-professional-ui/component-libs-common';
import { api, models } from '../..';
import { TliCircuit as TliCircuitV2, TliZone as TliZoneV2 } from '../api/apis/maintenance/v2';

export const zoneName = (zone: api.maintenance.v1.TliZone, t: models.TranslationFnc): string => {
  return zone.name || t('SG_ZONE_NO', { 0: zone.index + 1 });
};

export const zoneNameV2 = (zone: TliZoneV2, t: models.TranslationFnc): string => {
  return zone.zone_name?.value || t('SG_ZONE_NO', { 0: zone.index + 1 });
};

export const circuitName = (circuit: api.maintenance.v1.TliCircuit, t: models.TranslationFnc): string => {
  return t('SG_CIRCUIT_NO', { 0: circuit.index + 1 });
};

export const circuitNameV2 = (t: TranslateFnc, circuit: TliCircuitV2): string => {
  const circuitIndex = circuit.index;
  return t('SG_CIRCUIT_NO', { 0: circuitIndex + 1 });
};
