import React from 'react';

import { useParams } from 'react-router-dom';

import { api } from '@vaillant-professional-ui/pui-frontend-common';
import { CustomerDetailBreadcrumbs } from './CustomerDetailBreadcrumbs';

interface ParamsType {
  boilerSerialNumber: string;
}

const usePresenter = () => {
  const { boilerSerialNumber } = useParams() as unknown as ParamsType;
  const { customer } = api.useCases.useCustomerDetails(boilerSerialNumber);
  return customer;
};

export const CustomerDetailBreadcrumbsContainer: React.FC = () => {
  const customer = usePresenter();
  if (!customer) {
    return null;
  }
  return <CustomerDetailBreadcrumbs customer={customer} />;
};
