import { Box, styled, Tabs } from '@mui/material';

import { Tab, Typography } from '@vaillant-professional-ui/component-libs-web';
import { LoadingErrorWrapper } from '../../../components/LoadingErrorWrapper';
import { AxiosError } from 'axios';

interface TabViewModel {
  id: string;
  testIdKey: string;
  title: string;
  icon: JSX.Element | null;
}

interface ChartTabsProps {
  selectedTabId: string;
  setSelectedTabId: (id: string) => void;
  tabs: Array<TabViewModel>;
  error: AxiosError | null;
  isLoading: boolean;
}

export const ChartTabs = ({ setSelectedTabId, selectedTabId, tabs, error, isLoading }: ChartTabsProps) => {
  return (
    <LoadingErrorWrapper error={error} isPending={isLoading}>
      <Container>
        <Tabs
          onChange={(_, value) => setSelectedTabId(value)}
          value={selectedTabId}
          variant='scrollable'
          data-testid='chartTabs'
        >
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab.id}
                value={tab.id}
                testIdKey={`chartTabs_${tab.testIdKey}`}
                label={
                  <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                    {tab.icon}
                    <Typography variant='h3'>{tab.title}</Typography>
                  </Box>
                }
              />
            );
          })}
        </Tabs>
      </Container>
    </LoadingErrorWrapper>
  );
};

const Container = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  display: inline-block;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0 -16px;
  }
`;
