import { colors as redColors } from './colors/red';
import { colors as greenColors } from './colors/green';
import { colors as generalColors } from './colors/general';
import { BaseTheme } from './theme.types';

const redTheme: BaseTheme = {
  colors: {
    ...generalColors,
    ...redColors,
  },
  components: {
    button: {
      borderRadius: 4,
    },
    bottomTabBar: {
      height: 78,
      shouldShowDecoration: false,
    },
  },
  platforms: {
    app: {
      typography: {
        fontFamily: 'Roboto_400Regular',
        fontFamilyBold: 'Roboto_700Bold',
      },
    },
    web: {
      typography: {
        fontFamily: 'Meta Pro',
        fontSize: 12,
      },
    },
  },
};

const greenTheme: BaseTheme = {
  colors: {
    ...generalColors,
    ...greenColors,
  },
  components: {
    button: {
      borderRadius: 32,
    },
    bottomTabBar: {
      height: 78,
      shouldShowDecoration: true,
    },
  },
  platforms: {
    app: {
      typography: {
        fontFamily: 'Vaillant_16-Book',
        fontFamilyBold: 'Vaillant_16-Demi',
      },
    },
    web: {
      typography: {
        fontFamily: 'Vaillant',
        fontSize: 12,
      },
    },
  },
};

export const baseTheme = {
  green: greenTheme,
  red: redTheme,
};
