import { useTranslation } from '../../../../../..';
import { DeviceDetailsResponseTli } from '../../../../../api/apis/maintenance/v2';
import { QueryKey, UpdateFnc, useMutation, useOptimisticQueryOptions } from '../../../../../api/hooks';
import { useWithToastMessage } from '../../../../../api/useCases/TliReadWrite/OptimisticUpdateAgainstSettingsApiUtil';
import { UseMutation } from '../../../deviceSettings/presenter/writeDatapointPresenter/writeDatapointPresentationData';
import { BooleanDataPoint, EnumDataPoint, NumericDataPoint } from '../../../deviceSettings/useCase/model/dataPoint';
import {
  createCircuitUpdater,
  createDhwUpdater,
  createSystemBackupHeaterUpdater,
  createSystemDomesticHotWaterUpdater,
  createSystemHeatingUpdater,
  createSystemUpdater,
  createZoneHeatingUpdater,
  createZoneUpdater,
} from './createUpdater';

const updateFunctions: Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, (actionPropertyKey: string) => UpdateFnc<DeviceDetailsResponseTli, any>>
> = {
  // Zone
  coolingOperationMode: createZoneUpdater('cooling_operation_mode'),
  heatingManualModeSetpoint: createZoneUpdater('manual_mode_setpoint'),
  operationMode: createZoneUpdater('operation_mode'),
  roomTemperatureSetpointCoolingManual: createZoneUpdater('room_temperature_setpoint_cooling_manual'),

  // Zone/Heating
  setbackTemperature: createZoneHeatingUpdater('setback_temperature'),

  // Circuit
  activeCoolingFlowTemperatureMinimumSetpoint: createCircuitUpdater('active_cooling_flow_temperature_minimum_setpoint'),
  circuitCoolingAllowed: createCircuitUpdater('cooling_allowed'),
  circuitMaxFlowTargetTemperature: createCircuitUpdater('max_flow_target_temperature'),
  circuitMinFlowTargetTemperature: createCircuitUpdater('min_flow_target_temperature'),
  circuitSetbackModeEnabled: createCircuitUpdater('setback_mode_enabled'),
  heatDemandLimitedByOutsideTemperature: createCircuitUpdater('heat_demand_limited_by_outside_temperature'),
  heatingCircuitFlowSetpointExcessOffset: createCircuitUpdater('flow_target_temperature_excess_offset'),
  mixerCircuitTypeExternal: createCircuitUpdater('mixer_circuit_type_external'),

  // DHW
  dhwOperationMode: createDhwUpdater('operation_mode'),
  dhwTargetTemperature: createDhwUpdater('target_temperature'),

  // #### System ####
  // General
  alternativePoint: createSystemUpdater('alternative_point'),
  automaticCooling: createSystemUpdater('automatic_cooling'),
  energyProvidePowerCutBehavior: createSystemUpdater('energy_provide_power_cut_behavior'),
  hybridStrategy: createSystemUpdater('hybrid_strategy'),
  maxFlowSetpointHpError: createSystemUpdater('max_flow_setpoint_hp_error'),
  maximumPreheatingTime: createSystemUpdater('maximum_preheating_time'),
  systemScheme: createSystemUpdater('hydraulic_map'),

  // Heating
  adaptiveHeatCurveEnabled: createSystemHeatingUpdater('adaptive_heat_curve'),
  continuousHeatingRoomSetpoint: createSystemHeatingUpdater('continuous_heating_room_setpoint'),
  continuousHeatingStartSetpoint: createSystemHeatingUpdater('continuous_start_setpoint'),
  heatingCircuitBivalencePoint: createSystemHeatingUpdater('bivalence_point'),

  // DHW
  dhwMaximumTemperature: createSystemDomesticHotWaterUpdater('maximum_temperature'),
  domesticHotWaterBivalencePoint: createSystemDomesticHotWaterUpdater('bivalence_point'),
  domesticHotWaterMaximumLoadingTime: createSystemDomesticHotWaterUpdater('maximum_loading_time'),
  dhwHysteresis: createSystemDomesticHotWaterUpdater('hysteresis'),
  domesticHotWaterFlowSetpointOffset: createSystemDomesticHotWaterUpdater('flow_setpoint_offset'),
  domesticHotWaterSetParallelLoading: createSystemDomesticHotWaterUpdater('parallel_loading'),

  // Backup Heater
  backupHeaterAllowedFor: createSystemBackupHeaterUpdater('allowed_for'),
  backupHeaterType: createSystemBackupHeaterUpdater('type'),
};

export const controlSettingsMutationFactory =
  (queryKey: QueryKey) => (dataPoint: NumericDataPoint | EnumDataPoint | BooleanDataPoint) => {
    const useControlSettingsMutation: UseMutation = () => {
      const t = useTranslation();
      const updateFunction = updateFunctions[dataPoint.id];
      if (!updateFunction) {
        throw new Error(`No mutation config defined for datapoint ${dataPoint.id}`);
      }
      const mutationOptions = useOptimisticQueryOptions(
        queryKey,
        updateFunction(dataPoint.writeParams?.actionPropertyKey ?? ''),
      );

      const mutationOptionsWithToast = useWithToastMessage(
        mutationOptions,
        t('SG_TOAST_DATAPOINT_WRITE_SUCCESS', { datapoint: dataPoint.title }),
        t('SG_TOAST_ERROR_MSG'),
        t('SG_TOAST_DATAPOINT_WRITE_FAILED', { datapoint: dataPoint.title }),
      );

      const { mutate } = useMutation((payload) => {
        return {
          url: dataPoint.writeParams?.url,
          method: dataPoint.writeParams?.method,
          data: payload,
        };
      }, mutationOptionsWithToast);

      const { writeParams } = dataPoint;
      if (!writeParams) {
        return () => {};
      }
      return (value) => mutate({ [writeParams.actionPropertyKey]: value, ...writeParams.payloadMetadata });
    };
    return useControlSettingsMutation;
  };
