import * as React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { addDays, isAfter } from 'date-fns';
import { ReactDatePicker } from 'react-datepicker';
import { useConfiguration, useInstanceId, localeMap } from '@vaillant-professional-ui/component-libs-common';
import { ReactDatePickerProps } from 'react-datepicker';

import { Container } from './DateRangePicker.styled';
import { getDateFormat, getHeaderConfig, zoned } from './DateRangePicker.util';

import { Input } from './Input';
import { DatePickerInputField, DatePicker } from '@vaillant-professional-ui/component-libs-web';
import { Icon08101VgLeft01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon08201VgRight01 } from '@vaillant-professional-ui/component-libs-web';

export interface DateRangePickerProps {
  onChange: (startDate: Date, endDate: Date) => void;
  startDate: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  datePickerProps?: Partial<ReactDatePickerProps>;
}

const MAX_ALLOWED_DATE_RANGE = 31;

export const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = ({
  startDate: _startDate,
  endDate: _endDate = null,
  onChange,
  datePickerProps = {},
}) => {
  const { locale } = useConfiguration();
  const dateFnsLocale = localeMap[locale].dateFns;
  const testId = useInstanceId('DateRangePicker', 'historyCharts');
  const datePickerRef = React.useRef<ReactDatePicker>(null);

  const [dateRange, setDateRange] = React.useState<[Date, Date | null]>([_startDate, null]);
  const [startDate, endDate] = dateRange;

  const cancel = () => {
    setDateRange([_startDate, null]);
    datePickerRef.current?.setOpen(false);
  };

  const submit = (_startDate: Date, _endDate: Date) => {
    onChange(_startDate, _endDate);
    datePickerRef.current?.setOpen(false);
  };

  const filterDate = (date: Date) => (!endDate ? !isAfter(date, addDays(startDate, MAX_ALLOWED_DATE_RANGE)) : true);

  return (
    <Container>
      <DatePickerInputField>
        <DatePicker
          ref={datePickerRef}
          allowSameDay={true}
          customInput={
            <Input
              startDate={_startDate}
              endDate={_endDate}
              locale={dateFnsLocale}
              isOpen={datePickerRef.current ? datePickerRef.current.isCalendarOpen : () => false}
              testIdKey={testId('input').key}
            />
          }
          onClickOutside={cancel}
          onClickCancel={cancel}
          onClickSubmit={() => submit(zoned.startOfDay(startDate), zoned.endOfDay(endDate ?? startDate))}
          dateFormat={getDateFormat()}
          disabledKeyboardNavigation={true}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          isClearable={false}
          locale={locale}
          // @ts-ignore issue passing generics
          onChange={setDateRange}
          renderCustomHeader={
            // Cannot outsource component, because for some reason, react complains about a violation of some rules of hooks
            (headerProps) => {
              const headerConfig = getHeaderConfig(dateFnsLocale, headerProps);

              return (
                <Box display={'flex'} flexDirection='column' {...testId('header').testIdAttributes}>
                  <Box ml={4} mb={2} display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography>{headerConfig.title}</Typography>
                    <Box>
                      <IconButton
                        onClick={headerConfig.decreaseFn}
                        disabled={headerConfig.decreaseDisabled}
                        {...testId('decrease').testIdAttributes}
                      >
                        <Icon08101VgLeft01 color={headerConfig.decreaseDisabled ? 'grey.400' : 'primary.main'} />
                      </IconButton>
                      <IconButton
                        onClick={headerConfig.increaseFn}
                        disabled={headerConfig.increaseDisabled}
                        {...testId('increase').testIdAttributes}
                      >
                        <Icon08201VgRight01 color={headerConfig.increaseDisabled ? 'grey.400' : 'primary.main'} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            }
          }
          // @ts-ignore issue passing generics
          selectsRange
          shouldCloseOnSelect={false}
          filterDate={filterDate}
          testIdKey={testId().key}
          {...datePickerProps}
        />
      </DatePickerInputField>
    </Container>
  );
};
