import React from 'react';
import { useIntl } from 'react-intl';
import { Paper } from '@mui/material';
import { ConditionalWrapper } from '@vaillant-professional-ui/component-libs-common';
import { api } from '@vaillant-professional-ui/pui-frontend-common';
import { usePermissions } from '../PermissionGate';
import { RequestConsentContainerProps } from './RequestConsent.types';
import { RequestConsent } from './RequestConsent';

export const RequestConsentContainer: React.FC<React.PropsWithChildren<RequestConsentContainerProps>> = ({
  loyaltyRegistration,
  asCard = true,
}) => {
  const { formatDate } = useIntl();
  const { hasPermissions: formatAddressUK } = usePermissions(['COUNTRY_UK']);
  const {
    addCustomer,
    data,
    emailRequired,
    agreementRequired,
    actionRequired,
    localizedAddCustomerResponse,
    isErrorCode,
    isPending,
    isError,
    reset,
  } = api.useCases.useAddCustomer();

  const handleSubmitEmail = (email: string, agreementGiven?: boolean) => {
    addCustomer(loyaltyRegistration.serialNumber, email, agreementGiven);
  };

  return (
    <ConditionalWrapper condition={asCard} wrapper={(children) => <Paper elevation={1}>{children}</Paper>}>
      <RequestConsent
        addCustomerResponse={localizedAddCustomerResponse}
        actionRequired={actionRequired}
        agreementRequired={agreementRequired}
        emailRequired={emailRequired}
        formatAddressUK={formatAddressUK}
        formatDate={formatDate}
        loyaltyRegistration={loyaltyRegistration}
        isInitial={!data}
        isPending={isPending}
        isError={isError}
        isErrorCode={isErrorCode}
        onResetState={reset}
        onRequestConsent={() => addCustomer(loyaltyRegistration.serialNumber, '')}
        onSubmitEmail={handleSubmitEmail}
      />
    </ConditionalWrapper>
  );
};
