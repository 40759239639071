import React, { ErrorInfo } from 'react';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
    this.props.reportError('caught_exception', error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      const Component = this.props.errorComponent;
      return <Component error={this.state.error} errorInfo={this.state.errorInfo} />;
    }
    return this.props.children;
  }
}
