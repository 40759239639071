import React from 'react';

import { SystemSettingsPage } from './SystemSettingsPage';
import { usePresenter } from './SystemSettingsPage.presenter';

export const SystemSettingsPageContainer: React.FC = () => {
  const props = usePresenter();

  return <SystemSettingsPage {...props} />;
};
