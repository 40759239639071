/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon15501VgDownload01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon15501vgdownload01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M419.53 442.2H147.4a11.34 11.34 0 0 0 0 22.68h272.13a11.34 11.34 0 0 0 0-22.68zm-144.24-48.44a11.32 11.32 0 0 0 16 0l136.3-136.29a11.34 11.34 0 1 0-16-16L294.53 358.49V79.37a11.34 11.34 0 1 0-22.68 0v278.88L155 241.43a11.34 11.34 0 1 0-16 16z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
