import React from 'react';
import { hooks, models, selectors, types } from '@vaillant-professional-ui/pui-frontend-common';
import { IntlShape } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../lib/hooks';
import { DashboardData, TableRow } from './Dashboard.types';
import { getCustomerStatusProps } from '../../Utils/customer';
import { tabMap } from '../../features/dashboard/model';
import { Tab } from './MobileDashboard';
import {
  Icon03500VgWarning01,
  Icon07800VgMaintenance01,
  Icon08500VgWaitingTime01,
  Icon50100VgDisconnected01,
  Icon51100VgPressureLevel301,
} from '@vaillant-professional-ui/component-libs-web';
import { TableData } from '../../features/dashboard/presenter';

const {
  customer: {
    isActivationStatus,
    isSelectable,
    getBoilerSerialNumber,
    hasUnreadNotifications,
    isPendingConsent,
    getConnectivitySolution,
  },
} = selectors;

export interface CreateTableRows {
  customers: models.ICustomer[];
  t: (key: string) => string;
  formatDifference: ReturnType<typeof hooks.useLocalizedDifferenceInDays>;
  formatDate: IntlShape['formatDate'];
  formatTime: IntlShape['formatTime'];
  readNotifications: string[];
}

export const createTableRows = ({
  customers,
  t,
  formatDifference,
  formatDate,
  formatTime,
  readNotifications,
}: CreateTableRows): TableRow[] => {
  return customers.map((customer, index) => {
    const serial = getBoilerSerialNumber(customer);
    const hasUnread = hasUnreadNotifications(customer, readNotifications);
    const customerStatusProps = getCustomerStatusProps(customer, t, formatDifference, formatDate, formatTime);

    const hasMultipleRows = customerStatusProps.length > 1;

    return {
      _meta: {
        customer,
        customerStatusItems: customerStatusProps,
        hasMultipleRows,
        hasUnreadNotifications: hasUnread,
      },
      _disabled: !isSelectable(customer),
      id: serial || `generated-${index}`,
    };
  });
};

export const createTabs = (t: (key: string) => string, data: TableData): Tab[] => {
  return [
    {
      smallText: t('SG_DASHBOARD_FAILURES'),
      bigText: String(data.failures.length),
      disabled: !data.failures.length,
      icon: Icon03500VgWarning01,
      iconProps: { color: !data.failures.length ? 'text.disabled' : 'error.main' },
    },
    {
      smallText: t('SG_DASHBOARD_MAINTENANCE'),
      bigText: String(data.maintenance.length),
      disabled: !data.maintenance.length,
      icon: Icon07800VgMaintenance01,
      iconProps: { color: !data.maintenance.length ? 'text.disabled' : 'warning.main' },
    },
    {
      smallText: t('SG_DASHBOARD_PREDICTIONS'),
      bigText: String(data.prediction.length),
      disabled: !data.prediction.length,
      icon: Icon51100VgPressureLevel301,
      iconProps: { color: !data.prediction.length ? 'text.disabled' : 'primary.dark' },
    },
    {
      smallText: t('SG_DASHBOARD_OFFLINE'),
      bigText: String(data.offline.length),
      disabled: !data.offline.length,
      icon: Icon50100VgDisconnected01,
      iconProps: { color: !data.offline.length ? 'text.disabled' : 'gray' },
    },
    {
      smallText: t('SG_DASHBOARD_PENDING'),
      bigText: String(data.pending.length),
      disabled: !data.pending.length,
      icon: Icon08500VgWaitingTime01,
      iconProps: { color: !data.pending.length ? 'text.disabled' : 'primary.dark' },
    },
  ];
};

export const enableResendConsentMail = (row: TableRow) => {
  return (
    ['ERELAX', 'ERELAX_NETCOMV2', 'CAG'].indexOf(getConnectivitySolution(row._meta.customer)) !== -1 &&
    isPendingConsent(row._meta.customer)
  );
};

export const enableRetryAddCustomer = (row: TableRow) => isActivationStatus(row._meta.customer, ['USER_UNKNOWN']);

export const useGetCurrentTabIndexFromUrl = () => {
  const { tab } = useParams<{ tab: string }>();
  return tabMap.findIndex((item) => item === tab);
};

export const detectActiveTab = (tabIndexFromUrl: number, fallbackTabIndex: number | null): number | null =>
  tabIndexFromUrl >= 0 ? tabIndexFromUrl : fallbackTabIndex;

export const hasAnyIssue = (data: DashboardData): boolean => findFirstNonemptyTab(data) !== null;

export const useTabs = (firstNonemptyTab: number | null) => {
  const query = useQuery();
  const queryString = query.toString();
  const tabIndex = useGetCurrentTabIndexFromUrl();

  const [activeTab, setActiveTab] = React.useState<number | null>(detectActiveTab(tabIndex, firstNonemptyTab));
  const navigate = useNavigate();

  hooks.useDidUpdate(() => {
    if (activeTab !== null) {
      navigate(`/dashboard/${tabMap[activeTab]}${queryString ? `?${queryString}` : ''}`);
    }
  }, [activeTab]);

  hooks.useDidUpdate(() => {
    setActiveTab(detectActiveTab(tabIndex, firstNonemptyTab));
  }, [tabIndex, firstNonemptyTab]);

  return { activeTab, setActiveTab };
};

export const findFirstNonemptyTab = (data: DashboardData): number | null => {
  const firstNonemptyTab = tabMap.map((key) => data[key].length).findIndex((item) => item > 0);
  if (firstNonemptyTab !== -1) {
    return firstNonemptyTab;
  }
  return null;
};

export const tabToDtcType = (tab: types.OneOfArrayValues<typeof tabMap>): models.DtcTypeName | undefined => {
  const map: Partial<Record<keyof DashboardData, models.DtcTypeName>> = {
    failures: 'ERROR',
    maintenance: 'MAINTENANCE',
  };
  return map[tab];
};

export const hasContactNames = (user?: models.IUser) => user && user.contact.firstName && user.contact.lastName;
