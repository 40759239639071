import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQueryClient, UseQueryResult } from 'react-query';
import { DocumentsByType, UseDownloadDocuments, UseGetDocuments, UseTroubleCodeInsight } from './Product.types';
import { TroubleTypeMappingEnum, DtcDetails, TroubleCodes, Document } from '../../../typings/models';
import { useDocumentList, useTroubleCodeInsight } from '../../apis/product';
import { store } from '../../../store/store';
import { TroubleCodeInsight } from '../../apis/product';
import { getGroupedDocuments } from '../../../util/manualMapping';
import { useQueries } from '../../hooks';
import { productApi } from '../../apis/product/product.endpoints';

export const troubleCodeInsightToDtcDetails = (troubleCodeInsight: TroubleCodeInsight): DtcDetails => {
  return {
    ...troubleCodeInsight,
    type: TroubleTypeMappingEnum[troubleCodeInsight.type],
    name: `${TroubleTypeMappingEnum[troubleCodeInsight.type]}.${troubleCodeInsight.code}`,
  };
};

export const troubleCodesToDtcDetails = (troubleCodes: TroubleCodes): DtcDetails => {
  return {
    ...troubleCodes,
    type: TroubleTypeMappingEnum[troubleCodes.type],
    description: troubleCodes.description ?? undefined,
    title: troubleCodes.title ?? undefined,
    hint: troubleCodes.hint ?? undefined,
  };
};

export const useGetDtcDetails: UseTroubleCodeInsight = ({ articleNumber, dtc = null }) => {
  const country = useSelector(store.getCountry);
  const language = useSelector(store.getLanguage);

  const {
    data: dtcDetails,
    isFetching,
    error,
  } = useTroubleCodeInsight(
    // @ts-ignore ("enabled" flag ensures that code is not null)
    { language, code: dtc, country, articleNumber },
    { enabled: !!dtc && !!articleNumber },
  );

  return {
    dtcDetails: dtcDetails ? troubleCodeInsightToDtcDetails(dtcDetails) : null,
    isPending: isFetching,
    error,
  };
};

export const useGetDocuments: UseGetDocuments = ({ articleNumber }) => {
  const country = useSelector(store.getCountry);
  return useDocumentList(country, articleNumber, {
    enabled: !!articleNumber,
    select: (result: AxiosResponse) => getGroupedDocuments(result.data),
  }) as UseQueryResult<DocumentsByType, AxiosResponse>;
};

export const useDownloadDocuments: UseDownloadDocuments = (params) => {
  const queryClient = useQueryClient();
  const [fileIds, setFileIds] = useState<string[]>([]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['downloadDocument']);
    };
  }, [queryClient]);

  const addToQueue = (fileId: string) => {
    if (!fileIds.includes(fileId)) {
      setFileIds((state) => [...state, fileId]);
    }
  };
  const removeFromQueue = (fileId: string) => setFileIds((state) => state.filter((item) => item !== fileId));

  const queries = useQueries<Document[], AxiosRequestConfig<never>, AxiosResponse<string>>(
    fileIds.map((id) => {
      return {
        key: ['downloadDocument', id],
        requestConfig: productApi.downloadMediaFile(id),
        requestOptions: {
          meta: { fileId: id },
          onSuccess: (response) => {
            params?.onSuccess?.({ response, id });
            removeFromQueue(id);
            queryClient.removeQueries(['downloadDocument', id]);
            return response.data;
          },
          onError: () => {
            removeFromQueue(id);
          },
        },
      };
    }),
  );

  const errorFileIds = queryClient
    .getQueryCache()
    .findAll({ queryKey: ['downloadDocument'] })
    .filter((item) => !!item.state.error)
    .map((item) => item.meta!.fileId) as string[];

  return {
    queries,
    addToQueue,
    queue: fileIds,
    errors: errorFileIds,
  };
};
