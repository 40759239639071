import React from 'react';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { Box, Button } from '@mui/material';
import { IMessageDialogContentProps } from './MessageDialogContent.types';
import { Typography } from '@vaillant-professional-ui/component-libs-web';

const MessageDialogContent = ({
  messageId,
  onClose,
  testID,
  buttonI18nKey = 'SG_LOGIN_MOVEBTN',
}: IMessageDialogContentProps) => {
  const t = hooks.useTranslation();
  return (
    <div>
      <Typography data-testid={'addCustomerApiResponseText'} i18nKey={messageId} />
      <Box mt={5} display='flex' justifyContent='center'>
        <Button color='secondary' variant='contained' data-testid={testID} onClick={onClose}>
          {t(buttonI18nKey)}
        </Button>
      </Box>
    </div>
  );
};

export default MessageDialogContent;
