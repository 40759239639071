import { addDays, differenceInDays, endOfDay, startOfDay, subHours } from 'date-fns';
import sortBy from 'lodash/sortBy';
import { predictionTooFarInFuture } from '../../../../services/customers';
import { TranslationFnc } from '../../../../typings/models';
import { History, Prediction, PredictionData, PredictionResponse, TliCardProps, TliData } from './maintenance.types';

export const mapTliDataToTliCard = (tliData: TliData, t: TranslationFnc): TliCardProps | null => {
  const zone = tliData.zones[0];
  if (!zone) {
    return null;
  }

  return {
    deviceName: tliData.marketingName || t('SG_UNKNOWN_CONTROLLER'),
    isDhwEnabled: tliData.isDhwEnabled,
    values: {
      type: zone.calculatedMode.name,
      temperatureInsideCurrent: zone.roomTemperature,
      temperatureInsideCurrentUnit: zone.roomTemperatureUnit,
      temperatureInsideDesired: zone.roomTemperatureTarget,
      temperatureInsideDesiredUnit: zone.roomTemperatureTargetUnit,
      dateFrom: zone.calculatedMode.from ? new Date(zone.calculatedMode.from) : null,
      dateTo: zone.calculatedMode.to ? new Date(zone.calculatedMode.to) : null,
    },
  };
};

interface Coordinate {
  x: number;
  y: number;
}
type Coordinates = Coordinate[];

const toUnix = (timestamp: string | number) => {
  let date: Date;
  if (typeof timestamp === 'string') {
    date = new Date(timestamp.split(':').slice(0, 2).join(':'));
  } else {
    date = new Date(timestamp * 1000);
  }
  return Math.floor(date.getTime() / 1000);
};

const minMax = ([min, max]: [number, number], val: number): [number, number] => [
  Math.min(val, min),
  Math.max(val, max),
];

const splitPrediction = (acc: [Coordinates, Coordinates], [x, lowerBound, upperBound]: [number, number, number]) => {
  acc[0].push({ x, y: lowerBound });
  acc[1].push({ x, y: upperBound });
  return acc;
};

const calcDomain: (values: readonly Coordinate[]) => { x: [number, number]; y: [number, number] } = (
  values: readonly Coordinate[],
) => {
  const domainX: [number, number] = values
    .map((v) => v.x)
    .reduce(minMax, [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]);

  const domainY: [number, number] = values
    .map((v) => v.y)
    .reduce(minMax, [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]);

  return { x: domainX, y: domainY };
};

const calcDaysUntilCritical = (errorStartMin: string | null, now = new Date()) => {
  if (errorStartMin) {
    const criticalTimeStamp = toUnix(errorStartMin);

    return { daysUntilCritical: differenceInDays(criticalTimeStamp * 1000, now), criticalTimeStamp };
  }
  return { daysUntilCritical: null, criticalTimeStamp: null };
};

const splitAndCutPredictionCoordinates = (predictionValues: Prediction['values'], predictionCutOffDate: number) => {
  const greaterThenZero = (value: number) => {
    const tolerance = 0.03;
    return value + tolerance > 0;
  };
  const predictionLow: Coordinates = [];
  const predictionHigh: Coordinates = [];
  predictionValues
    .filter(([ts, ,]) => ts <= predictionCutOffDate)
    .filter(([, value]) => greaterThenZero(value))
    .reduce(splitPrediction, [predictionLow, predictionHigh]);
  const predictionDomain = calcDomain(predictionLow.concat(predictionHigh));
  return { predictionLow, predictionHigh, predictionDomain };
};

const enrichNonHealthyReasonsIfPredictionIsTooFarInFuture = (
  errorStartMin: string | null,
  nonHealthyReasons: string[],
  now: Date,
) => {
  if (errorStartMin !== null && predictionTooFarInFuture(toUnix(errorStartMin) * 1000, now)) {
    return [...nonHealthyReasons, 'too_far_in_future'];
  }
  return nonHealthyReasons;
};

export const filterRefills = (data: PredictionResponse, now: number, hoursToLookBack: number): History => {
  const from = startOfDay(subHours(now, hoursToLookBack)).getTime();
  const to = endOfDay(now).getTime();

  return {
    values: data.refillHistory.values.filter((value) => {
      const timestamp = value[0] * 1000;
      return timestamp > from && timestamp < to;
    }),
  };
};

const closeGapBetweenHistoryAndPrediction = (data: PredictionResponse): PredictionResponse => {
  const lastHistoryPoint = data.history.values.sort((lhs, rhs) => rhs[0] - lhs[0])[0];
  const firstPredictionPoint = [lastHistoryPoint[0], lastHistoryPoint[1], lastHistoryPoint[1]] as [
    number,
    number,
    number,
  ];
  if (!data.prediction) {
    return data;
  } else {
    return {
      ...data,
      prediction: {
        ...data.prediction,
        values: [firstPredictionPoint, ...data.prediction.values],
      },
    };
  }
};

export const prepareInput = (rawData: PredictionResponse, now: Date, maxPredictionScope = 14): PredictionData => {
  const data = closeGapBetweenHistoryAndPrediction(rawData);
  const predictionCutOffDate = addDays(now, maxPredictionScope).getTime() / 1000;

  const { daysUntilCritical, criticalTimeStamp } = calcDaysUntilCritical(data.prediction?.errorStartMin ?? null, now);

  const nonHealthyReasons = enrichNonHealthyReasonsIfPredictionIsTooFarInFuture(
    data.prediction?.errorStartMin ?? null,
    data?.healthiness?.nonHealthyReasons ?? [],
    now,
  );

  // Only, if nonHealthyReasons is empty, the prediction is valid.
  const isPredictable = !nonHealthyReasons?.length;

  // Only, if there is a predicted point in time when the error would happen, the system is unHealthy
  const isUnHealthy = isPredictable && !!data.prediction?.errorStartMin;

  const values = data.history.values.sort((lhs, rhs) => lhs[0] - rhs[0]).map(([ts, value]) => ({ x: ts, y: value }));
  const valueDomain = calcDomain(values);
  // always use the same vertical domain to make charts more comparable
  valueDomain.y = [0, Math.max(3, valueDomain.y[1])];

  const { predictionLow, predictionHigh, predictionDomain } = splitAndCutPredictionCoordinates(
    data.prediction?.values || [],
    predictionCutOffDate,
  );

  const refillHistorySorted: PredictionData['refillHistory'] = {
    values: sortBy(data?.refillHistory?.values ?? null, (data) => data[0]).reverse(),
  };

  return {
    predictionComputedAt: new Date(data.predictionComputedAt),
    measurements: {
      values,
      valueDomain,
    },
    prediction: {
      isPredictable,
      daysUntilCritical,
      criticalTimeStamp,
      predictionHigh,
      predictionLow,
      predictionDomain,
    },
    healthiness: {
      nonHealthyReasons,
      disconnectedCounter: data.healthiness.disconnectedCounter,
      errorCounter: data.healthiness.errorCounter,
      refillCounter: data.healthiness.refillCounter,
      isUnHealthy,
    },
    refillHistory: refillHistorySorted,
  };
};
