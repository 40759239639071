import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Icon43501VgCheckConfirm201 } from '../../atoms/Icon/Icon43501VgCheckConfirm201';
import { Icon46801VgInformation201 } from '../../atoms/Icon/Icon46801VgInformation201';
import { Typography } from '../../atoms/Typography';
import { StatusPanelProps } from './StatusPanel.types';
import { Container, IconContainer, TitleContainer } from './StatusPanel.styles';

const createIconMap = (theme: Theme) => ({
  success: { icon: Icon43501VgCheckConfirm201, color: theme.palette.primary.main },
  error: { icon: Icon46801VgInformation201, color: theme.palette.common.black },
  info: { icon: Icon46801VgInformation201, color: theme.palette.common.black },
});

export const StatusPanel: FC<PropsWithChildren<StatusPanelProps>> = ({
  type,
  title,
  text,
  children,
  variant = 'small',
  testIdKey,
}) => {
  const testId = useInstanceId('statuspanel', testIdKey);
  const theme = useTheme();
  const { icon: StatusIcon, color } = createIconMap(theme)[type];
  const isSmall = variant === 'small';

  return (
    <Container {...testId('root').testIdAttributes}>
      <IconContainer $isSmall={isSmall} {...testId('icon').testIdAttributes}>
        <StatusIcon color={color} width={70} height={70} />
      </IconContainer>
      {!!title && (
        <TitleContainer $isSmall={isSmall} {...testId('title').testIdAttributes}>
          <Typography bold={true} variant={isSmall ? 'body' : 'h2'}>
            {title}
          </Typography>
        </TitleContainer>
      )}
      {!!text && (
        <Typography variant={isSmall && !!title ? 'caption' : 'body'} {...testId('text').testIdAttributes}>
          {text}
        </Typography>
      )}
      {!!children && <Box mt={isSmall ? 6 : 12}>{children}</Box>}
    </Container>
  );
};
