import { styled, Chip } from '@vaillant-professional-ui/component-libs-web';

export const StyledTable = styled('table')`
  width: 100%;
`;

export const StyledStatusChip = styled(Chip)`
  width: fit-content;
`;

export const StyledRow = styled('tr')`
  padding-top: 0;
  padding-bottom: 0;
`;

export const StyledIconCell = styled('td')`
  padding-right: 16px;
`;

export const StatusCell = styled('td')`
  width: 62.5%;
  padding-left: ${({ theme }) => theme.spacing(4)};
`;

export const DateCell = styled('td')`
  width: 37.5%;
`;
