import React from 'react';
import { ControllerType, TliScheduleEditorDomesticHotWaterProps } from './ScheduleEditor.types';
import { createDefaultTimeWindow } from './ScheduleEditor.util';
import { useScheduleEditor } from './ScheduleEditor.hooks';
import { util } from '@vaillant-professional-ui/component-libs-common';
import { ScheduleEditor } from './ScheduleEditor';

export const TliScheduleEditorDomesticHotWater: React.FC<TliScheduleEditorDomesticHotWaterProps> = ({
  values,
  maxTimeSlotsPerDay: maxTimeWindows,
  timeStepWidthInMinutes,
  onChange,
}) => {
  const onCreateDefaultTimeWindow = createDefaultTimeWindow;
  const { managedValues, onAddTimeWindow, deleteTimespan, changeTimeWindow, maximumStartTime } =
    useScheduleEditor<util.TimeWindow>(values, timeStepWidthInMinutes, onChange, onCreateDefaultTimeWindow);

  return (
    <ScheduleEditor
      onChangeTimeWindow={changeTimeWindow}
      managedValues={managedValues}
      timeStepWidthInMinutes={timeStepWidthInMinutes}
      maximumStartTime={maximumStartTime}
      maxTimeWindows={maxTimeWindows}
      deleteTimespan={deleteTimespan}
      onAddTimeWindow={onAddTimeWindow}
      controllerType={ControllerType.TLI_DHW}
      testIdKey='tli-dhw'
    />
  );
};
