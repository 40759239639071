import * as React from 'react';
import { SwitcherButtonGroupProps } from './SwitcherButtonGroup.types';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { StyledTab, StyledTabs, StyledToggleButton, StyledToggleButtonGroup } from './SwitcherButtonGroup.styled';
import { useMediaQuery, useTheme } from '@mui/material';

export const SwitcherButtonGroup: React.FunctionComponent<React.PropsWithChildren<SwitcherButtonGroupProps>> = ({
  buttons,
  scrollableTabsOnMobile,
  selectedValue,
  testIdKey,
  ...rest
}) => {
  const testId = useInstanceId('switcher-button-group', testIdKey);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (scrollableTabsOnMobile && isMobile) {
    return (
      <StyledTabs
        onChange={rest.onChange}
        value={selectedValue}
        variant='scrollable'
        {...testId('root').testIdAttributes}
      >
        {buttons.map((button) => (
          <StyledTab
            key={button.value}
            value={button.value}
            {...testId(`button.${button.value}`).testIdAttributes}
            label={button.children}
          />
        ))}
      </StyledTabs>
    );
  }

  return (
    <StyledToggleButtonGroup {...testId('root').testIdAttributes} {...rest}>
      {buttons.map((buttonProps) => (
        <StyledToggleButton
          key={buttonProps.value}
          selected={buttonProps.value === selectedValue}
          {...testId(`button.${buttonProps.value}`).testIdAttributes}
          {...buttonProps}
        />
      ))}
    </StyledToggleButtonGroup>
  );
};
