import React from 'react';

import { Box } from '@mui/material';

import { Card, CardContent, CardHeader, Grid, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon03500VgWarning01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon08001VgBack01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon08201VgRight01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon08302VgPressure01 } from '@vaillant-professional-ui/component-libs-web';

import { api, PredictionChart } from '@vaillant-professional-ui/pui-frontend-common';

import { useResize } from '../../lib/hooks';
import Button from '../Button';
import Link from '../Link';

type Props = ReturnType<typeof api.useCases.useWaterPressurePredictionChart> & {
  buttonLabel: string;
  pathToPredictionDetails: string;
};

export const WaterPressurePredictionCard: React.FC<Props> = ({
  isError,
  data: predictionData,
  waterPressure,
  warning,
  arrowDirection,
  backgroundColor,
  textColor,
  title,
  computationDate,
  buttonLabel,
  pathToPredictionDetails,
}) => {
  const containerRef = React.useRef<HTMLDivElement>();
  const svgContainerRef = React.useRef<HTMLDivElement>(null);

  const resizeHandler = () => {
    const containerWidth = containerRef?.current?.offsetWidth ?? null;
    if (containerWidth !== null && svgContainerRef.current) {
      svgContainerRef.current.setAttribute('style', `width:${containerWidth}px`);
      svgContainerRef.current.setAttribute('style', `height:${containerWidth / 1.33}px`);
    }
  };

  useResize(resizeHandler);

  React.useEffect(() => {
    resizeHandler();
  }, [predictionData]);

  if (!predictionData) {
    return null;
  }

  const predictionProps = {
    valueDomain: predictionData.measurements.valueDomain,
    values: predictionData.measurements.values,
    isUnHealthy: predictionData.healthiness.isUnHealthy,
    isPredictable: predictionData.prediction.isPredictable,
    criticalTimeStamp: predictionData.prediction.criticalTimeStamp,
    predictionDomain: predictionData.prediction.predictionDomain,
    predictionLow: predictionData.prediction.predictionLow,
    predictionHigh: predictionData.prediction.predictionHigh,
  };

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={predictionProps.isUnHealthy && <Typography color='text.secondary'>{computationDate}</Typography>}
      />
      <CardContent>
        <Box ref={containerRef}>
          {isError && <Typography i18nKey={'SG_STATUS_ERROR_TITLE'} />}
          <>
            <Box mb={8}>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                    <Grid item>
                      <Icon08302VgPressure01 width={24} display='block' />
                    </Grid>
                    <Grid item>
                      <Typography>{waterPressure} bar</Typography>
                    </Grid>
                    <Grid item>
                      <Box ml={2}>
                        <Icon08001VgBack01
                          width={12}
                          height={12}
                          display='block'
                          color={textColor}
                          style={{
                            padding: 2,
                            backgroundColor: backgroundColor,
                            borderRadius: '50%',
                            transform: `rotate(${arrowDirection}deg)`,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {warning && (
                    <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                      <Grid item>
                        <Icon03500VgWarning01 width={24} color={textColor} display='block' />
                      </Grid>
                      <Grid item>
                        <Typography>{warning}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box mb={2}>
              <div ref={svgContainerRef} style={{ height: 0 }}>
                <PredictionChart {...predictionProps} padding={0} />
              </div>
            </Box>
            <Box mb={1} display='flex' justifyContent='center'>
              <Link style={{ textDecoration: 'none' }} to={pathToPredictionDetails}>
                <Button variant='text' color='primary' endIcon={<Icon08201VgRight01 color='primary' />}>
                  {buttonLabel}
                </Button>
              </Link>
            </Box>
          </>
        </Box>
      </CardContent>
    </Card>
  );
};
