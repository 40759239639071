import React, { ErrorInfo } from 'react';

export interface LoggingContextValues {
  reportEvent: (name: string, meta?: Record<string, string | number | boolean>) => void;
  reportError: (name: string, error: unknown, errorInfo?: ErrorInfo) => void;
}

export const LoggingContext = React.createContext<LoggingContextValues>({
  reportEvent: () => {
    console.log('logging not configured yed.');
  },
  reportError: () => {
    console.log('logging not configured yed.');
  },
});

export const LoggingContextProvider: React.FC<React.PropsWithChildren<LoggingContextValues>> = ({
  children,
  ...value
}) => {
  return <LoggingContext.Provider value={value}>{children}</LoggingContext.Provider>;
};

export const useLoggingContext = () => React.useContext(LoggingContext);
