import { Box, Skeleton } from '@mui/material';

export const ZoomChartSkeleton = () => {
  return (
    <Box p={4}>
      <Box mb={4}>
        <Skeleton variant='rectangular' height={340} />
      </Box>
      <Box>
        <Skeleton variant='rectangular' height={50} />
      </Box>
    </Box>
  );
};
