import rawCountries from '../assets/fixtures/countries.json';

const countries = rawCountries as RawCountry[];

export type CountryCode = (typeof countries)[number][0];

export interface Country {
  code: CountryCode;
  nativeName: string;
  name: string;
  languages: string[];
  phonePrefixes: string[];
  currencies: string[];
}

export type RawCountry = [
  /** code */
  string,
  /** nativeName */
  string,
  /** name */
  string,
  /** languages */
  string[],
  /** phonePrefixes */
  string[],
  /** currencies */
  string[]
];

export const getCountryInfo = (code: CountryCode): Country => {
  const rawCountry = countries.find((item) => item[0] === code.toUpperCase()) as RawCountry | undefined;
  if (!rawCountry) {
    throw new Error(`Country ${code} unknown`);
  }

  return {
    code: rawCountry[0],
    nativeName: rawCountry[1],
    name: rawCountry[2],
    languages: rawCountry[3],
    phonePrefixes: rawCountry[4],
    currencies: rawCountry[5]
  };
};

const sortCountries = (countries: Country[], sortBy: 'name' | 'nativeName'): Country[] => {
  return countries.sort((a, b) =>
    sortBy === 'name' ? a.name.localeCompare(b.name) : a.nativeName.localeCompare(b.nativeName)
  );
};

export const getCountriesInfo = (codes: CountryCode[], sortBy: 'name' | 'nativeName' = 'name'): Country[] => {
  return sortCountries(codes.map(getCountryInfo), sortBy);
};

export const getCountryCodes = (): CountryCode[] => countries.map((record) => record[0]);
