import { Country, IUserInfo } from '../../typings/models';
import {
  ActionTypes,
  SetCookiesAccepted,
  SetCountry,
  SetNotificationRead,
  SetTermsAccepted,
  SetUserInfo,
  SetUserLanguage,
  SetDismissMaxDatapointsWarning,
} from './settings.actions.types';

export const setUserLanguage = (language: string): SetUserLanguage => ({
  type: ActionTypes.SET_USER_LANGUAGE,
  payload: language,
});

export const setCountry = (country: Country): SetCountry => {
  return { type: ActionTypes.SET_COUNTRY, payload: country };
};

export const setTermsAccepted = (userId: string): SetTermsAccepted => ({
  type: ActionTypes.SET_TERMS_ACCEPTED,
  payload: userId,
});

export const setCookiesAccepted = (value: boolean): SetCookiesAccepted => ({
  type: ActionTypes.SET_COOKIES_ACCEPTED,
  payload: value,
});

export const setUserInfo = (userInfo: IUserInfo): SetUserInfo => ({
  type: ActionTypes.SET_USER_INFO,
  payload: userInfo,
});

export const setNotificationRead = (notificationId: string, contactId: string): SetNotificationRead => ({
  type: ActionTypes.SET_NOTIFICATION_READ,
  payload: { notificationId, contactId },
});

export const setHasDismissedMaxDatapointsWarning = (value: boolean): SetDismissMaxDatapointsWarning => ({
  type: ActionTypes.SET_DISMISS_MAX_DATAPOINTS_WARNING,
  payload: value,
});
