import { AxiosRequestConfig } from 'axios';
import { objectToGetParams } from '../../../util/url';
import { NotificationParams } from './notifications.types';

export const notificationsApi = {
  getNotifications: (
    gatewaySerial: string,
    { sort = 'occurrenceTimestamp', sortDirection = 'asc', from, to, ...rest }: NotificationParams = {},
  ): AxiosRequestConfig => {
    const fromTime = from ? Math.floor(from.getTime() / 1000) : null;
    const toTime = to ? Math.floor(to.getTime() / 1000) : null;
    const sortField = `${sortDirection === 'asc' ? '' : '-'}${sort}`;

    const params = objectToGetParams({
      sort: sortField,
      from: fromTime,
      to: toTime,
      ...rest,
    });

    return {
      method: 'get',
      url: `/pui/api-gateway/v1/systems/${gatewaySerial}/notifications?${params}`,
      timeout: 10000,
    };
  },
};
