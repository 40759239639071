import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { useTliCircuit, useTliCircuitPresentationData } from './TliHeatCurve.hooks';
import { HeatCurvePresenterData, UseHeatCurvePresenterParams } from './TliHeatCurve.types';
import { useCustomerName } from '../Customer/Customer.hooks';

export const useHeatCurvePresenter = ({
  systemId,
  deviceId,
  boilerSerialNumber,
  circuitIndex,
}: UseHeatCurvePresenterParams): HeatCurvePresenterData => {
  const t = useTranslation();

  const { circuit, isLoading, error, patchTliCircuitHeatCurve, refetch } = useTliCircuit({
    systemId,
    deviceId,
    boilerSerialNumber,
    circuitIndex,
  });

  const customerName = useCustomerName(boilerSerialNumber, { enabled: false });

  const { spinnerControlHeatCurve, spinnerControlMinFlowTargetTemperature, minFlowTargetTemperatureUnit, circuitName } =
    useTliCircuitPresentationData(t, circuit);

  const heatCurveValue = spinnerControlHeatCurve.value;
  const minFlowTargetTemperatureValue = spinnerControlMinFlowTargetTemperature.value;
  const isDirty =
    heatCurveValue !== circuit?.heat_curve?.value ||
    minFlowTargetTemperatureValue !== circuit?.min_flow_target_temperature?.value;

  const onSave = () => {
    patchTliCircuitHeatCurve({ heatCurve: heatCurveValue, minFlowTargetTemperature: minFlowTargetTemperatureValue });
  };

  const onCancel = () => {
    spinnerControlHeatCurve.resetValue();
    spinnerControlMinFlowTargetTemperature.resetValue();
  };

  const presentationData = {
    spinnerControlHeatCurve,
    spinnerControlMinFlowTargetTemperature,
    minFlowTargetTemperatureUnit,
    customerName,
    isDirty,
    circuitName,
    onSave,
    onCancel,
  };

  return {
    refetch,
    isLoading,
    error,
    presentationData,
  };
};
