import { buildSupportedDevicesList, useCustomerDetails, useSystemDevices } from '../../../api/useCases';

interface Props {
  boilerSerialNumber: string;
  systemId: string;
  deviceId?: string;
}

export const useSystemSettingsData = ({ boilerSerialNumber, systemId }: Props) => {
  const { customer, error: customerError, isLoading: isLoadingUser } = useCustomerDetails(boilerSerialNumber);
  const { devices, isLoading: isLoadingDevices, error } = useSystemDevices(systemId, buildSupportedDevicesList);

  return {
    devices,
    customer,
    error: error || customerError,
    isLoading: isLoadingUser || isLoadingDevices,
  };
};
