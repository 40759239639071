import { differenceInMilliseconds } from 'date-fns';
import { ICustomer } from '../../typings/models';
import * as selectors from '../../selectors/customer';

const hasWarning: (customer: ICustomer) => boolean = (customer) =>
  selectors.isOnline(customer) && selectors.hasConsentGiven(customer) && isCustomerWithUnrectifiedError(customer);

export const isCustomerWithUnrectifiedError: (customer: ICustomer) => boolean = (customer) => {
  const activeNotification = selectors.getActiveNotification(customer);
  if (activeNotification === undefined) {
    return false;
  }
  return !activeNotification?.rectificationTimeStamp;
};

const offsetBase = new Date('2000-01-01T00:00:00.000Z').getTime();
export const calcDateOffset = (timestamp: string | Date, now: number) => {
  const dte = new Date(timestamp);
  const inMilliseconds = differenceInMilliseconds(now, dte);
  return (offsetBase - Math.max(Math.min(offsetBase - 1, inMilliseconds), 1)) / offsetBase;
};

export const calculateStatusWeight: (customer: ICustomer, now?: number) => number = (customer, now = Date.now()) => {
  /* see PUI-989, PUI-5030 */

  const hasErrors = selectors.getActiveTroubleCodes(customer).length > 0;
  const isOk = !hasErrors;
  const isOnline = selectors.isOnline(customer);
  const isOffline = !selectors.isOnline(customer);
  const hasConsentGiven = selectors.hasConsentGiven(customer);
  const isPending = selectors.isPending(customer);
  const f22Prediction = selectors.getF22Prediction(customer);
  const lastHeartBeat = selectors.getLastHeartbeat(customer);

  // customers who are offline and have an active error (at least before he went offline)
  if (hasConsentGiven && isOffline && hasErrors) {
    const offset = lastHeartBeat ? calcDateOffset(lastHeartBeat, now) : 0;
    return 1 + offset;
  }

  // customers who are online and have an active error
  if (hasConsentGiven && isOnline && hasErrors) {
    return 2;
  }

  // customer with f22 prediction, weighted by prediction date
  if (hasConsentGiven && isOnline && f22Prediction) {
    return 2 + calcDateOffset(f22Prediction.predictedFrom, now);
  }

  // customers who are offline and has no active error
  if (hasConsentGiven && isOffline && !hasErrors) {
    const offset = lastHeartBeat ? calcDateOffset(lastHeartBeat, now) : 0;
    return 3 + offset;
  }

  // customers who are online and status ok
  if (hasConsentGiven && isOnline && isOk) {
    return 4;
  }

  // customers who are offline and deactivated
  // Not yet implemented return 5;

  // customers who are online and deactivated
  // Not yet implemented return 6;

  // customers who are pending and have a active error
  if (isPending && isOnline && hasErrors) {
    return 7;
  }

  // customers who are pending and have no active error
  if (isPending && isOnline && hasErrors) {
    return 8;
  }

  // fallback - we should never get here...
  return 100;
};

// case insensitive comparator - provide getter for attribute based comparison
type GetterFnc<T> = (obj: T) => string;
const caseInsensitiveStringCompare = (a: string, b: string) => a.localeCompare(b, undefined, { sensitivity: 'accent' });
const caseInsensitiveCompare =
  <T>(getter: GetterFnc<T>) =>
  (a: T, b: T) =>
    caseInsensitiveStringCompare(getter(a), getter(b));

const nameComparator = caseInsensitiveCompare((v: ICustomer) => selectors.getEndUserFullName(v));
const applianceComparator = caseInsensitiveCompare((v: ICustomer) => selectors.getBoilerMarketingName(v));

const getStatusWeight = (customer: ICustomer) => calculateStatusWeight(customer);
const statusComparator = (a: ICustomer, b: ICustomer) => {
  const s1 = getStatusWeight(a);
  const s2 = getStatusWeight(b);

  if (s1 > s2) {
    return 1;
  }
  if (s1 < s2) {
    return -1;
  }

  return nameComparator(a, b);
};

const customerListComparators = {
  name: nameComparator,
  appliance: applianceComparator,
  status: statusComparator,
};

export { hasWarning, customerListComparators };
