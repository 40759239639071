import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon03500VgWarning01 } from '@vaillant-professional-ui/component-libs-web';
import { AgreementRequired } from './AgreementRequired';
import { EnterEmail } from './EnterEmail';
import { ChooseEmail } from './ChooseEmail';

interface Props {
  isError: boolean;
  actionRequired: boolean;
  addCustomerResponse?: { responseId: string; code: string };
  agreementRequired: boolean;
  emailAddress: string;
  emailRequired: boolean;
  emailSuggestions: string[];
  isErrorCode: boolean;
  setEmailAddress: (emailAddress: string) => void;
  onResetState: () => void;
  onSubmitEmail: (email: string, agreementGiven?: boolean) => void;
}

export const getInnerComponent = ({
  actionRequired,
  addCustomerResponse,
  agreementRequired,
  emailAddress,
  emailRequired,
  emailSuggestions,
  isError,
  isErrorCode,
  setEmailAddress,
  onResetState,
  onSubmitEmail,
}: Props) => {
  if (isError) {
    return (
      <Box my={4} display='flex' flexDirection='row'>
        <Box mr={2}>
          <Icon03500VgWarning01 color='error.main' />
        </Box>
        <Typography color='error' i18nKey='SG_STATUS_ERROR_TEXT' />
      </Box>
    );
  }

  if (!actionRequired && !!addCustomerResponse?.responseId) {
    return (
      <Box p={8} display='flex' flexDirection='row' justifyContent='center'>
        {isErrorCode && (
          <Box mr={3}>
            <Icon03500VgWarning01 color='error.main' />
          </Box>
        )}
        <Typography
          style={{ textAlign: 'center' }}
          color={isErrorCode ? 'error' : 'text.primary'}
          i18nKey={addCustomerResponse.responseId}
        />
      </Box>
    );
  }

  if (agreementRequired) {
    return <AgreementRequired onCancel={onResetState} onConfirm={() => onSubmitEmail(emailAddress, true)} />;
  }

  if (emailRequired && emailSuggestions.length === 0) {
    return (
      <EnterEmail value={emailAddress} onChange={setEmailAddress} onSubmit={onSubmitEmail} onCancel={onResetState} />
    );
  }

  if (emailRequired && emailSuggestions.length > 0) {
    return (
      <ChooseEmail
        value={emailAddress}
        onChange={setEmailAddress}
        emailAddresses={emailSuggestions}
        onCancel={onResetState}
        onSubmit={onSubmitEmail}
      />
    );
  }

  return null;
};
