import * as React from 'react';
import { Avatar } from '@mui/material';
import { useInstanceId, util } from '@vaillant-professional-ui/component-libs-common';
import { ScheduleProfileIconProps } from './ScheduleProfileIcon.types';
import { IconProps } from '../Icon/Icon.types';
import { Icon01200VgAtHome01 } from '../Icon/Icon01200VgAtHome01';
import { Icon01500VgAway01 } from '../Icon/Icon01500VgAway01';
import { Icon04500VgNightMode01 } from '../Icon/Icon04500VgNightMode01';

const iconMap: Map<util.FixedProfileType['type'], React.FC<IconProps>> = new Map([
  ['DAY', Icon01200VgAtHome01],
  ['AWAY', Icon01500VgAway01],
  ['NIGHT', Icon04500VgNightMode01],
]);

export const ScheduleProfileIcon: React.FunctionComponent<ScheduleProfileIconProps> = ({ testIdKey, profile }) => {
  const testId = useInstanceId('scheduleprofileicon', testIdKey);

  const { fgColor, bgColor } = util.getErelaxProfileColor(profile);
  const Icon = profile.type === 'CUSTOM' ? null : iconMap.get(profile.type);
  return (
    <Avatar sx={{ bgcolor: bgColor, mr: 2, width: 32, height: 32 }} {...testId().testIdAttributes}>
      {Icon ? <Icon width={24} color={fgColor} /> : null}
    </Avatar>
  );
};
