import { Documents } from '../../typings/models';
import { GeneralAction } from '../generalActions';
import { DocumentTypes } from './DocumentationDownloadActionTypes';

export type DocumentsState = Documents;

const initialState = {
  documentsList: [],
};

export const documentationDownloadReducer = (
  state: DocumentsState = initialState,
  action: DocumentTypes | GeneralAction,
): DocumentsState => {
  switch (action.type) {
    case 'STORE_DOCUMENT_INFO': {
      const documentsList = state.documentsList.filter(
        (storedDocument) => storedDocument.fileId !== action.payload.fileId,
      );

      return {
        ...state,
        documentsList: [...documentsList, action.payload],
      };
    }

    case 'APP/RESET': {
      return initialState;
    }

    default:
      return state;
  }
};
