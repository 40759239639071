import { ListItem as ListItemBase, ListItemIcon, ListItemProps } from '@mui/material';
import { styled } from '../../../util/styling';

type StyledListItemProps = ListItemProps & { $isActive: boolean };

export const ListItem: React.FC<StyledListItemProps> = styled(ListItemBase)<StyledListItemProps>`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(7)};
  border-left: ${({ theme, $isActive }) =>
    $isActive ? `4px ${theme.palette.primary.main} solid` : `4px transparent solid`};
  background-color: ${({ theme, $isActive }) => ($isActive ? theme.palette.primary.lightest : 'auto')};
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  min-width: fit-content;
`;
