import { useEffect } from 'react';

// eslint-disable-next-line
type ComplexKeyEvent = any;

export const useKeyPress = (key?: string | string[], handler?: (key: string) => void) => {
  useEffect(() => {
    if (!key || key.length === 0 || !handler) {
      return;
    }
    const keyupHandler = (event: ComplexKeyEvent) => {
      const e = event || window.event,
        target = e.target || e.srcElement;

      const tag = target.tagName.toUpperCase();
      const Type = target.type;
      if (['TEXTAREA', 'SELECT'].includes(tag)) {
        if (event.key === 'Escape') {
          e.target.blur();
        }
        return;
      }

      if (tag === 'INPUT' && (Type === 'text' || Type === 'password')) {
        if (event.key === 'Escape') {
          e.target.blur();
        }
        return;
      }

      let hasDetected = false;
      if (typeof key === 'string' && event.key === key) {
        hasDetected = true;
      } else if (Array.isArray(key)) {
        hasDetected = key.includes(event.key);
      }
      if (hasDetected) {
        handler && handler(event.key);
      }
    };

    window.addEventListener('keyup', keyupHandler);

    return () => {
      window.removeEventListener('keyup', keyupHandler);
    };
  }, [handler, key]);
};
