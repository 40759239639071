import { styled } from '@mui/material';

const arrowWidthMobile = 5;
const arrowWidth = 6;
const arrowTopPosition = 6;
const tooltipBorderWidth = 1;

export const ForeignObject = styled('foreignObject')<{ $height: number; $width: number }>`
  overflow: visible;
  border-width: 0;
  height: ${({ $height = 0 }) => $height}px;
  width: ${({ $width = 0 }) => $width}px;
`;

export const TooltipContent = styled('div')<{
  $width?: number;
  $x: number;
  $y: number;
  $position: 'left' | 'right';
  $isArrowVisible?: boolean;
}>`
  display: flex;
  width: fit-content;
  left: ${({ $x }) => $x - tooltipBorderWidth}px;
  top: ${({ $y }) => $y}px;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(2)}`};
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  border: ${tooltipBorderWidth}px solid ${({ theme }) => theme.palette.primary.main};
  overflow: visible;

  ${({ $isArrowVisible, $position, $width, theme }) =>
    $isArrowVisible
      ? `&:before {
    content: '';
    height: 0;
    position: absolute;
    width: 0;
    ${$position}: ${$position === 'left' && $width != null ? `${$width + tooltipBorderWidth}px` : '100%'};
    ${theme.breakpoints.up('sm')} {
      border: ${arrowWidthMobile}px solid transparent;
    };
    border: ${arrowWidth}px solid transparent;
    border-${$position}-color: ${theme.palette.primary.main} !important;
    top: ${arrowTopPosition}px;
  }`
      : ''};
`;

export const chartTooltipStyleConfig = { arrowWidthMobile, arrowWidth, arrowTopPosition, tooltipBorderWidth };
