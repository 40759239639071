/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon06600VgEcoMode01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon06600vgecomode01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M455.37 199.89a224 224 0 0 0-143.77-52.67c-64.31 0-121.63 28-146 71.31-17.26 30.63-16.35 66.18 2.4 100.76a151.84 151.84 0 0 0-8.1 103.32 11.34 11.34 0 0 0 10.89 8.39 11.51 11.51 0 0 0 3-.4 11.34 11.34 0 0 0 7.94-13.93 129.46 129.46 0 0 1 1.94-75.37 95.54 95.54 0 0 0 68.56 28.86 102.07 102.07 0 0 0 52.37-14.86c27.46-16.44 39.41-35.52 51-54 16.34-26.1 33.24-53.09 97.24-82.44a11.34 11.34 0 0 0 2.57-19zm-119 89.4C325.28 307 315.73 322.21 293 335.85a79.5 79.5 0 0 1-40.72 11.59c-23.36 0-45.22-11.07-58.34-29.2.12-.21.23-.42.35-.62a129.5 129.5 0 0 1 78.85-61.17 11.34 11.34 0 0 0-6-21.88 151.91 151.91 0 0 0-85.44 60.49c-8.81-23.07-7.63-45.39 3.65-65.4 20.14-35.75 70.89-59.76 126.28-59.76a200.91 200.91 0 0 1 115 36.49c-56.73 29.31-74.48 57.61-90.29 82.9z'
      />
    </svg>
  );
};
