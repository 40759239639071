import { BreakPoint } from '../../../Utils/breakpoint';
import { chartTooltipStyleConfig } from '../ChartTooltip/ChartTooltip.styles';

const { arrowWidth, arrowWidthMobile } = chartTooltipStyleConfig;

const arrowWidthOffset = arrowWidth / 2;
const arrowWidthOffsetMobile = arrowWidthMobile / 2;

export const tooltipBreakpointStyles: Record<BreakPoint, { fontSize: number; xOffset: number; yOffset: number }> = {
  sm: {
    fontSize: 14,
    xOffset: 5,
    yOffset: arrowWidthOffsetMobile,
  },
  md: {
    fontSize: 16,
    xOffset: 5,
    yOffset: arrowWidthOffset,
  },
  lg: {
    fontSize: 6,
    xOffset: 5,
    yOffset: arrowWidthOffset,
  },
  xl: {
    fontSize: 7,
    xOffset: 3,
    yOffset: arrowWidthOffset,
  },
  default: {
    fontSize: 6,
    xOffset: 5,
    yOffset: arrowWidthOffset,
  },
};
