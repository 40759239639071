import React from 'react';
import { LoggingContextProvider } from '@vaillant-professional-ui/pui-frontend-common';
import { reportEvent, reportError } from '../../services/Utility';

export const LoggingContextProviderWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <LoggingContextProvider reportEvent={reportEvent} reportError={reportError}>
      {children}
    </LoggingContextProvider>
  );
};
