import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { BigTabsLabelProps } from './BigTabsLabel.types';
import { SmallText, BigText } from './BigTabsLabel.styles';
import { Box } from '@mui/material';

export const BigTabsLabel: React.FunctionComponent<BigTabsLabelProps> = ({
  testIdKey,
  isActive = true,
  smallText,
  bigText,
  disabled = false,
}) => {
  const testId = useInstanceId('tabsmajorlabel', testIdKey);
  const textColor = isActive ? 'primary' : 'text.secondary';

  return (
    <Box display='flex' alignItems='center' {...testId('root').testIdAttributes}>
      <SmallText variant='h4' color={textColor} disabled={disabled} {...testId('small').testIdAttributes}>
        {smallText.toUpperCase()}
      </SmallText>
      {!!bigText && (
        <BigText variant='h2' color={textColor} disabled={disabled} {...testId('big').testIdAttributes}>
          {bigText}
        </BigText>
      )}
    </Box>
  );
};
