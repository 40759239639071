import React from 'react';
import { HintTextProps } from './HintText.types';
import { Box } from '@mui/system';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon46801VgInformation201 } from '@vaillant-professional-ui/component-libs-web';

export const HintText = ({ text }: HintTextProps) => {
  return (
    <Box display='flex' flexDirection='row'>
      <Box mr={2}>
        <Icon46801VgInformation201 color='text.secondary' />
      </Box>
      <Box mt={1}>
        <Typography variant='caption' color='text.secondary'>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
