/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon02000VgCooling01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon02000vgcooling01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M440.65 312.61 383.24 328l-77.1-44.51 77.13-44.54 57.42 15.38a11.11 11.11 0 0 0 2.94.39 11.34 11.34 0 0 0 2.93-22.29l-35.51-9.51 30.3-17.5A11.34 11.34 0 1 0 430 185.77l-30.3 17.5 9.51-35.52a11.34 11.34 0 0 0-21.9-5.87l-15.38 57.42-77.13 44.54v-89.06l42-42a11.33 11.33 0 1 0-16-16l-26 26v-35a11.34 11.34 0 0 0-22.68 0v35l-26-26a11.33 11.33 0 0 0-16 16l42 42v89L195 219.3l-15.39-57.42a11.34 11.34 0 0 0-21.9 5.87l9.52 35.52-30.3-17.5a11.34 11.34 0 1 0-11.34 19.64l30.3 17.49-35.52 9.52a11.34 11.34 0 0 0 2.93 22.29 11.46 11.46 0 0 0 2.94-.38l57.42-15.39 77.13 44.53L183.68 328l-57.42-15.38a11.34 11.34 0 1 0-5.86 21.9l35.51 9.48-30.3 17.49A11.34 11.34 0 1 0 137 381.16l30.3-17.49-9.52 35.51a11.34 11.34 0 0 0 8 13.89 11.48 11.48 0 0 0 2.94.39 11.36 11.36 0 0 0 10.95-8.41L195 347.63l77.1-44.51v89l-42 42a11.33 11.33 0 1 0 16 16l26-26v35a11.34 11.34 0 0 0 22.68 0v-35l26 26a11.34 11.34 0 1 0 16-16l-42-42v-89l77.1 44.52 15.38 57.42a11.35 11.35 0 0 0 11 8.4 11.06 11.06 0 0 0 2.94-.39 11.33 11.33 0 0 0 8-13.88l-9.52-35.52 30.3 17.5a11.34 11.34 0 0 0 11.34-19.64L411 344l35.51-9.51a11.34 11.34 0 1 0-5.87-21.9z'
      />
    </svg>
  );
};
