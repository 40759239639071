import React from 'react';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import get from 'lodash/get';
import { IconProps } from '@vaillant-professional-ui/component-libs-web';

const getColor = (theme: Theme, color = '', defaultColor = '#000'): string => {
  const themeColor = get(theme.palette, color);
  return themeColor || color || defaultColor;
};

/**
 * @deprecated Just a temporary solution until Icon is added to the icon lib in vg-components
 */
export const DashboardIcon: React.FC<IconProps> = ({ width = 24, height = 24, style = {}, color, ...rest }) => {
  const theme = useTheme();
  const convertedColor = getColor(theme, color);
  const iconProps = {
    width,
    height,
    style: {
      ...style,
      color: convertedColor,
    },
    ...rest,
  };
  return (
    <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...iconProps}>
      <path
        fill='currentColor'
        d='M9.95577 10.8447H8.00022C7.46688 10.8447 7.11133 10.4891 7.11133 9.95577V8.00022C7.11133 7.46688 7.46688 7.11133 8.00022 7.11133H9.95577C10.4891 7.11133 10.8447 7.46688 10.8447 8.00022V9.95577C10.8447 10.4891 10.4891 10.8447 9.95577 10.8447Z'
      />
      <path
        fill='currentColor'
        d='M17.156 10.8447H15.2004C14.6671 10.8447 14.3115 10.4891 14.3115 9.95577V8.00022C14.3115 7.46688 14.6671 7.11133 15.2004 7.11133H17.156C17.6893 7.11133 18.0449 7.46688 18.0449 8.00022V9.95577C18.0449 10.4891 17.6004 10.8447 17.156 10.8447Z'
      />
      <path
        fill='currentColor'
        d='M24.3562 10.8447H22.4006C21.8673 10.8447 21.5117 10.4891 21.5117 9.95577V8.00022C21.5117 7.46688 21.8673 7.11133 22.4006 7.11133H24.3562C24.8895 7.11133 25.2451 7.46688 25.2451 8.00022V9.95577C25.2451 10.4891 24.8006 10.8447 24.3562 10.8447Z'
      />
      <path
        fill='currentColor'
        d='M9.95577 17.7783H8.00022C7.46688 17.7783 7.11133 17.4227 7.11133 16.8894V14.9338C7.11133 14.4005 7.46688 14.0449 8.00022 14.0449H9.95577C10.4891 14.0449 10.8447 14.4005 10.8447 14.9338V16.8894C10.8447 17.3338 10.4891 17.7783 9.95577 17.7783Z'
      />
      <path
        fill='currentColor'
        d='M17.156 17.7783H15.2004C14.6671 17.7783 14.3115 17.4227 14.3115 16.8894V14.9338C14.3115 14.4005 14.6671 14.0449 15.2004 14.0449H17.156C17.6893 14.0449 18.0449 14.4005 18.0449 14.9338V16.8894C18.0449 17.3338 17.6004 17.7783 17.156 17.7783Z'
      />
      <path
        fill='currentColor'
        d='M17.156 24.6225H15.2004C14.6671 24.6225 14.3115 24.2669 14.3115 23.7336V21.778C14.3115 21.2447 14.6671 20.8892 15.2004 20.8892H17.156C17.6893 20.8892 18.0449 21.2447 18.0449 21.778V23.7336C18.0449 24.178 17.6004 24.6225 17.156 24.6225Z'
      />
      <path
        fill='currentColor'
        d='M24.3562 17.7783H22.4006C21.8673 17.7783 21.5117 17.4227 21.5117 16.8894V14.9338C21.5117 14.4005 21.8673 14.0449 22.4006 14.0449H24.3562C24.8895 14.0449 25.2451 14.4005 25.2451 14.9338V16.8894C25.2451 17.3338 24.8006 17.7783 24.3562 17.7783Z'
      />
      <path
        fill='currentColor'
        d='M24.3562 24.6225H22.4006C21.8673 24.6225 21.5117 24.2669 21.5117 23.7336V21.778C21.5117 21.2447 21.8673 20.8892 22.4006 20.8892H24.3562C24.8895 20.8892 25.2451 21.2447 25.2451 21.778V23.7336C25.2451 24.178 24.8006 24.6225 24.3562 24.6225Z'
      />
      <path
        fill='currentColor'
        d='M9.95577 24.6225H8.00022C7.46688 24.6225 7.11133 24.2669 7.11133 23.7336V21.778C7.11133 21.2447 7.46688 20.8892 8.00022 20.8892H9.95577C10.4891 20.8892 10.8447 21.2447 10.8447 21.778V23.7336C10.8447 24.2669 10.4891 24.6225 9.95577 24.6225Z'
      />
    </svg>
  );
};
