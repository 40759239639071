/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon43801VgQuickActions01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon43801vgquickactions01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M283.46 102.05c-100 0-181.41 81.38-181.41 181.41s81.38 181.42 181.41 181.42 181.42-81.38 181.42-181.42-81.38-181.41-181.42-181.41zm0 340.15c-87.53 0-158.74-71.21-158.74-158.74s71.21-158.73 158.74-158.73 158.75 71.21 158.75 158.73S371 442.2 283.46 442.2z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.46 257.59A25.52 25.52 0 1 0 309 283.11a25.52 25.52 0 0 0-25.54-25.52zm0-98.85A25.51 25.51 0 1 0 309 184.25a25.51 25.51 0 0 0-25.54-25.51zm0 198.43A25.51 25.51 0 1 0 309 382.68a25.51 25.51 0 0 0-25.54-25.51z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
