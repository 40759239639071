import { Box, Stack } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import {
  BigCheckbox,
  StyledMenu,
  StyledMenuItem,
  Title,
  StyledButton,
  Circle,
  ClickableOverlayWrapper,
  ClickableOverlay,
  OptionLabel,
} from './CheckboxSelect.styles';
import { Icon02501VgDown01 } from '@vaillant-professional-ui/component-libs-web';

interface Option {
  id: string;
  label: string;
  checked: boolean;
  color: string;
}

export interface CheckboxSelectProps {
  label: string;
  options: Option[];
  onChange: (optionIds: string[], isEnabled: boolean) => void;
  freeSlotsAllowed: number;
  onDisabledClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const CheckboxSelect: React.FC<CheckboxSelectProps> = ({
  options,
  label,
  onChange,
  freeSlotsAllowed,
  onDisabledClick = () => {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const amountChecked = options.filter((option) => option.checked).length;
  const totalOptions = options.length;

  const isIndeterminate = amountChecked !== 0 && amountChecked !== options.length;

  const isBigCheckboxChecked = amountChecked === options.length || isIndeterminate;
  const isBigCheckboxDisabled =
    !isBigCheckboxChecked && !isIndeterminate && totalOptions - amountChecked > freeSlotsAllowed;

  return (
    <>
      <StyledButton onClick={handleClick}>
        <Stack direction='row' alignItems='center' flex={1}>
          <Box flex={1} display='flex' alignItems='center'>
            <ClickableOverlayWrapper>
              {isBigCheckboxDisabled && (
                <ClickableOverlay
                  onClick={(event) => {
                    event.stopPropagation();
                    onDisabledClick(event);
                  }}
                />
              )}
              <BigCheckbox
                disabled={isBigCheckboxDisabled}
                checked={isBigCheckboxChecked}
                indeterminate={isIndeterminate}
                onClick={(event) => event.stopPropagation()}
                onChange={(event) =>
                  onChange(
                    options.map((option) => option.id),
                    isIndeterminate ? false : event.target.checked,
                  )
                }
              />
            </ClickableOverlayWrapper>
            <Title color='black' variant='h4'>
              {label}
            </Title>
            <Box ml={2} mr={4}>
              <Typography variant='h4' color='grey.500'>{`(${amountChecked}/${totalOptions})`}</Typography>
            </Box>
          </Box>
          <Icon02501VgDown01 color='primary.main' height={32} width={32} />
        </Stack>
      </StyledButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ variant: 'outlined' }}
      >
        {options.map((option) => {
          const isDisabled = freeSlotsAllowed <= 0 && !option.checked;

          return (
            <StyledMenuItem
              key={option.id}
              onClick={(event) => (isDisabled ? onDisabledClick(event) : onChange([option.id], !option.checked))}
              disabled={isDisabled}
            >
              <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                <BigCheckbox checked={option.checked} />
                <Circle color={option.color} />
                <OptionLabel>{option.label}</OptionLabel>
              </Box>
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};
