import { AxiosRequestConfig } from 'axios';
import { AddCustomerRequest, FeedbackRequest, RemoveCustomerRequest } from './management.types';

export const managementApi = {
  addCustomer: (data: AddCustomerRequest): AxiosRequestConfig<AddCustomerRequest> => ({
    method: 'post',
    url: '/pui/management-api/v1/systems',
    timeout: 12000,
    data,
  }),
  getLoyaltyRegistrations: (): AxiosRequestConfig => ({
    method: 'get',
    url: '/pui/management-api/v1/loyalty/registrations?connected=true',
  }),
  sendFeedback: (data: FeedbackRequest): AxiosRequestConfig => ({
    method: 'post',
    url: '/pui/management-api/v1/support-requests',
    data,
  }),
  removeCustomer: (data: RemoveCustomerRequest): AxiosRequestConfig => ({
    method: 'delete',
    url: `/pui/management-api/v1/systems/${data.boilerSerialNumber}`,
  }),
  resendConsent: (boilerSerialNumber: string): AxiosRequestConfig => ({
    method: 'post',
    url: `/pui/management-api/v1/systems/${boilerSerialNumber}/resend-consent-request`,
    data: {}, // empty payload
  }),
  getPaymentPlans: (): AxiosRequestConfig => ({
    method: 'get',
    url: '/pui/management-api/v1/payment-plans',
  }),

  getPaymentPlan: (paymentPlanId: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/management-api/v1/payment-plans/${paymentPlanId}`,
  }),

  acceptPaymentPlan: (paymentPlanId: string): AxiosRequestConfig => ({
    method: 'patch',
    url: `/pui/management-api/v1/payment-plans/${paymentPlanId}`,
    data: {
      paymentAccepted: true,
    },
  }),
};
