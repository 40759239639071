/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon04500VgNightMode01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon04500vgnightmode01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M391.39 385.51a136.06 136.06 0 0 1-90.11-238 136.06 136.06 0 1 0 95.17 237.9c-1.68.05-3.37.1-5.06.1z'
      />
    </svg>
  );
};
