import React from 'react';

import { DeviceSettings } from './DeviceSettings';
import { usePresenter } from './DeviceSettings.presenter';
import { features } from '@vaillant-professional-ui/pui-frontend-common';

export const DeviceSettingsTliContainer: React.FC = () => {
  const props = usePresenter(features.systemSettings.deviceSettingsTli.useTliPresenter);
  return <DeviceSettings {...props} />;
};
