import { AuthStatusDelegated, AuthStatusIdm, Observer, TokenData } from './auth.types';
import { AuthAdapter, AuthService, AuthStatus } from './auth.types';
import { getAuthenticationStatus } from './auth.util';

export const createAuth = async (adapter: AuthAdapter): Promise<AuthService> => {
  let authenticationSource: AuthStatus['authenticationSource'] = null;
  let token: TokenData | string | null = null;
  let observers: Observer[] = [];

  const getStatus = () => getAuthenticationStatus(authenticationSource, token);

  const notifyObservers = (prevStatus: AuthStatus, nextStatus: AuthStatus) => {
    observers.forEach((observer) => observer(prevStatus, nextStatus));
  };

  const emitStatusChange = (prevStatus: AuthStatus) => {
    notifyObservers(prevStatus, getStatus());
  };

  const setToken = (data: TokenData | null) => {
    const prevStatus = getStatus();
    token = data;

    if (!!(prevStatus as AuthStatusIdm | AuthStatusDelegated).token && !token) {
      authenticationSource = null;
    }
    emitStatusChange(prevStatus);
  };

  const logout = async (redirectUri?: string) => {
    const prevStatus = getStatus();
    if (authenticationSource === 'idm') {
      await adapter.logoutIdm(redirectUri);
    }
    reset();
    emitStatusChange(prevStatus);
  };

  const initialStatus = await adapter.initialize({
    onTokenRefreshed: setToken,
    onGetStatus: getStatus,
    onTokenRefreshError: logout,
    onAuthLogout: logout,
  });

  authenticationSource = initialStatus?.authenticationSource || null;

  if (initialStatus?.authenticationSource === 'idm' || initialStatus?.authenticationSource === 'delegated') {
    const is = initialStatus as AuthStatusIdm | AuthStatusDelegated; // ts hack, necessary for app compilation
    token = is.token;
  }

  const subscribe = (observer: Observer) => {
    observers.push(observer);
    const unsubscribe = () => {
      observers = observers.filter((item) => item !== observer);
    };
    return unsubscribe;
  };

  const reset = () => {
    authenticationSource = null;
    token = null;
  };

  const loginIdm = async <T extends undefined>(options: T) => {
    const prevStatus = getStatus();
    reset();
    token = await adapter.loginIdm(options);
    authenticationSource = 'idm';
    emitStatusChange(prevStatus);
  };

  const loginDemo = () => {
    const prevStatus = getStatus();
    reset();
    authenticationSource = 'demo';
    emitStatusChange(prevStatus);
  };

  const loginDelegated = (_delegatedToken: string) => {
    const prevStatus = getStatus();
    reset();
    authenticationSource = 'delegated';
    token = _delegatedToken;
    emitStatusChange(prevStatus);
  };

  return {
    getStatus,
    loginIdm,
    loginDemo,
    loginDelegated,
    logout,
    subscribe,
  };
};
