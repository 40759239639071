import * as React from 'react';
import { useInstanceId, useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { Button, ButtonProps } from '../../atoms/Button';
import { StatusPanel } from '../StatusPanel';
import { LoadingErrorPanelProps } from './LoadingErrorPanel.types';
import { getTranslationForStatusCode } from './LoadingErrorPanel.util';

export const LoadingErrorPanel: React.FC<LoadingErrorPanelProps> = ({
  statusCode,
  title,
  text,
  onRetry,
  testIdKey,
  ...statusPanelProps
}) => {
  const t = useTranslation();
  const testId = useInstanceId('loadingerrorpanel', testIdKey);
  const isNotFound = statusCode === 404;
  const canRetry = !isNotFound && !!onRetry;
  const { variant = 'small' } = statusPanelProps;
  const buttonProps: Partial<ButtonProps> =
    variant === 'small'
      ? {
          variant: 'outlined',
          color: 'primary',
        }
      : {
          variant: 'contained',
          color: 'secondary',
        };

  return (
    <StatusPanel
      title={title || t('VG_STATUS_ERROR_TITLE')}
      text={text || getTranslationForStatusCode(t, statusCode)}
      testIdKey={testId('root').key}
      {...statusPanelProps}
      type='error'
    >
      {canRetry ? (
        <Button
          {...testId('button').testIdAttributes}
          {...buttonProps}
          onClick={() => onRetry()}
          i18nKey='VG_LOADING_ERROR_PANEL_RETRY'
        />
      ) : null}
    </StatusPanel>
  );
};
