/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon07800VgMaintenance01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon07800vgmaintenance01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M261 237.41v-29.59a4.42 4.42 0 0 0-7.48-3.19 42.52 42.52 0 0 0 13.15 68.94v116.91a5.67 5.67 0 0 0 5.67 5.67H295a5.67 5.67 0 0 0 5.67-5.67V273.55a42.51 42.51 0 0 0 13.19-68.89 4.42 4.42 0 0 0-7.52 3.15v29.59l-22.68 11.34z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M503.25 431 293.49 67.64a11.34 11.34 0 0 0-19.64 0L64.08 431a11.34 11.34 0 0 0 9.82 17h419.53a11.34 11.34 0 0 0 9.82-17zm-409.71-5.71L283.67 96l190.12 329.3z'
        className='cls-1'
      />
    </svg>
  );
};
