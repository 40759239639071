import { I18nKeys } from '../../../../../i18n';

export const dataPointKeyToTranslationMap: Record<string, I18nKeys> = {
  activeCoolingFlowTemperatureMinimumSetpoint: 'SG_CONTROL_CIRCUIT_COOLING_FLOW_TEMPERATURE_MINIMUM_SETPOINT',
  adaptiveHeatCurveEnabled: 'SG_CONTROL_SYSTEM_ADAPTIVE_HEATING_CURVE',
  alternativePoint: 'SG_CONTROL_SYSTEM_ALTERNATIVE_POINT',
  automaticCooling: 'SG_CONTROL_SYSTEM_AUTOMATIC_COOLING_ON_OFF',
  backupHeaterAllowedFor: 'SG_CONTROL_SYSTEM_BACKUP_HEATER_ALLOWED_FOR',
  backupHeaterType: 'SG_CONTROL_SYSTEM_BACKUP_HEATER_TYPE',
  circuitCoolingAllowed: 'SG_CONTROL_CIRCUIT_HEATING_CIRCUIT_COOLING_ALLOWED',
  circuitMaxFlowTargetTemperature: 'SG_CONTROL_CIRCUIT_HEATING_FLOW_TEMPERATURE_MAXIMUM_SETPOINT',
  circuitMinFlowTargetTemperature: 'SG_CONTROL_CIRCUIT_MIN_FLOW_TEMPERATURE',
  circuitSetbackModeEnabled: 'SG_CONTROL_CIRCUIT_SET_BACK_MODE_ENABLED',
  continuousHeatingRoomSetpoint: 'SG_CONTROL_SYSTEM_CONTINUOUS_HEATING_ROOM_SETPOINT',
  continuousHeatingStartSetpoint: 'SG_CONTROL_SYSTEM_CONTINUOUS_HEATING_START_SETPOINT',
  coolingOperationMode: 'SG_CONTROL_ZONE_COOLING_HEATING_CIRCUIT_OPERATION_MODE_COOLING',
  currentCircuitFlowTemperature: 'SG_CONTROL_CIRCUIT_CURRENT_CIRCUIT_FLOW_TEMPERATURE',
  currentDhwTemperature: 'SG_CONTROL_DHW_DOMESTIC_HOT_WATER_TANK_TEMPERATURE',
  dhwHysteresis: 'SG_CONTROL_DHW_DOMESTIC_HOT_WATER_HYSTERESIS',
  dhwMaxTargetTemperature: 'SG_CONTROL_DHW_DHW_FLOW_TEMPERATURE_MAXIMUM_SETPOINT',
  dhwMaximumTemperature: 'SG_CONTROL_DHW_MAX_TEMPERATURE',
  dhwMinTargetTemperature: 'SG_CONTROL_DHW_DHW_FLOW_TEMPERATURE_MINIMUM_SETPOINT',
  dhwOperationMode: 'SG_OPERATION_MODE',
  dhwTargetTemperature: 'SG_MONITORING_VALVE_DOM_TEMPERATURE',
  dhwWeeklyPlanner: 'SG_DHW_WEEKLY_PLANNER',
  domesticHotWaterBivalencePoint: 'SG_CONTROL_SYSTEM_DWH_BIVALENCE_POINT',
  domesticHotWaterFlowSetpointOffset: 'SG_CONTROL_SYSTEM_DHW_FLOW_SETPOINT_OFFSET',
  domesticHotWaterMaximumLoadingTime: 'SG_CONTROL_SYSTEM_DHW_MAXIMUM_LOADING_TIME',
  domesticHotWaterSetParallelLoading: 'SG_CONTROL_DHW_DOMESTIC_HOT_WATER_SET_PARALLEL_LOADING',
  energyProvidePowerCutBehavior: 'SG_CONTROL_SYSTEM_ENERGY_PROVIDER_CUT_BEHAVIOUR',
  heatCurve: 'SG_HEAT_CURVE_SETTINGS',
  heatDemandLimitedByOutsideTemperature: 'SG_CONTROL_CIRCUIT_HEAT_DEMAND_LIMITED_BY_OUTSIDE_TEMPERATURE',
  heatingCircuitBivalencePoint: 'SG_CONTROL_SYSTEM_HEATING_CIRCUIT_BIVALENCE_POINT',
  heatingCircuitFlowSetpoint: 'SG_CONTROL_CIRCUIT_HEATING_CIRCUIT_FLOW_SETPOINT',
  heatingCircuitFlowSetpointExcessOffset: 'SG_CONTROL_CIRCUIT_HEATING_CIRCUIT_FLOW_SETPOINT_EXCESS_OFFSET',
  heatingCircuitRoomTemperatureControlMode: 'SG_CONTROL_CIRCUIT_HEATING_CIRCUIT_ROOM_TEMPERATURE_CONTROL_MODE',
  heatingCircuitType: 'SG_CONTROL_CIRCUIT_HEATING_CIRCUIT_TYPE',
  heatingManualModeSetpoint: 'SG_CONTROL_HEATING_MANUAL_SETPOINT',
  humidity: 'SG_CONTROL_ZONE_HUMIDITY_CURRENT',
  hybridStrategy: 'SG_CONTROL_SYSTEM_HYBRID_STRATEGY',
  maxFlowSetpointHpError: 'SG_CONTROL_SYSTEM_MAXIMUM_FLOW_SETPOINT_IF_HEAT_PUMP_ERROR',
  maximumPreheatingTime: 'SG_CONTROL_SYSTEM_MAXIMUM_PREHEATING_TIME',
  mixerCircuitTypeExternal: 'SG_CONTROL_CIRCUIT_MIXER_CIRCUIT_TYPE_EXTERNAL',
  operationMode: 'SG_OPERATION_MODE',
  outdoorTemperature: 'SG_CONTROL_SYSTEM_OUTDOOR_TEMPERATURE',
  roomTemperature: 'SG_CONTROL_ZONE_ROOM_TEMPERATURE_VALUE',
  roomTemperatureSetpointCoolingManual: 'SG_ROOM_TEMPERATURE_SETPOINT_COOLING_MANUAL',
  setbackTemperature: 'SG_SET_BACK_TEMPERATURE',
  systemFlowTemperature: 'SG_CONTROL_SYSTEM_COMMON_SYSTEM_FLOW_TEMPERATURE',
  systemScheme: 'SG_CONTROL_SYSTEM_SYSTEM_SCHEME',
  weeklyPlanner: 'SG_DHW_WEEKLY_PLANNER',
  zoneActive: 'SG_CONTROL_ZONE_IS_ENABLED',
};
