import * as React from 'react';
import { TranslatedProps } from './Translated.types';

export const getChildren = (
  component: TranslatedProps['component'],
  children?: React.ReactChild | React.ReactChildren | React.ReactNode
): React.ReactChild | React.ReactChildren | React.ReactNode => {
  const isElement = React.isValidElement(component);
  const isDomElement = typeof component === 'string';

  if (isDomElement || !isElement) {
    return children;
  }

  return (component as React.ReactElement).props.children;
};
