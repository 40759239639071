import * as React from 'react';
import { getChildren } from './Translated.util';
import { useContent } from './Translated.hooks';
import { TranslatedProps } from './Translated.types';

export const Translated: React.FC<React.PropsWithChildren<TranslatedProps>> = (props) => {
  const { children: childrenProps, component: Component, i18nKey, i18nRenderOptions, ...elementProps } = props;
  const children = getChildren(Component, childrenProps);
  const content = useContent(props, children);

  if (!!i18nKey && !!children && typeof children !== 'string') {
    throw new Error('Cannot translate children which are not of type string');
  }

  const translationProp = i18nRenderOptions?.translationProp || 'children';

  const newProps = {
    ...elementProps,
    children: undefined,
    [translationProp]: content,
  };

  // Something like `component={<SomeComponent />}` given
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, newProps);
  }

  // Something like `component={SomeComponent}` given
  return React.createElement(Component as React.ElementType, newProps);
};
