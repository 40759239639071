import { AxiosError, AxiosResponse } from 'axios';
import { MutateOptions } from 'react-query';
import { useToast, withToastMessages } from '../../../components/meta/ToastProvider';
import { SchedulePayload, useUpdateSchedule } from '../../apis/schedules/v2';
import { QueryKey, ReactQueryAxiosContext, useOptimisticQueryOptions } from '../../hooks';
import { updateSchedules } from '../TliScheduler/TliScheduler.write.util';
import { useTranslation } from '../../../libs/hooks';
import { DeviceDetailsResponseTli } from '../../apis/maintenance/v2';

interface ToastMessage {
  onError: {
    title: string;
    message: string;
  };
  onSuccess: {
    message: string;
  };
}

/**
 * Write data to the schedules API
 * Update cached response from maintenance API
 */
const useOptimisticPatchSchedule = (gatewaySerialNumber: string, tliStateKey: QueryKey, toastMessage: ToastMessage) => {
  const toastContext = useToast();
  const options = useOptimisticQueryOptions<DeviceDetailsResponseTli, SchedulePayload, unknown>(
    tliStateKey,
    updateSchedules,
  );
  const { mutate, ...rest } = useUpdateSchedule<DeviceDetailsResponseTli>(
    gatewaySerialNumber,
    withToastMessages(options, toastMessage, toastContext),
  );
  const mutateSchedules = (
    data: SchedulePayload,
    options?: MutateOptions<
      AxiosResponse<unknown>,
      AxiosError<unknown>,
      SchedulePayload,
      ReactQueryAxiosContext<DeviceDetailsResponseTli>
    >,
  ) => mutate(data, options);

  return { mutate: mutateSchedules, ...rest };
};

export const useOptimisticPatchHeatingSchedule = (gatewaySerialNumber: string, tliStateKey: QueryKey) => {
  const t = useTranslation();
  return useOptimisticPatchSchedule(gatewaySerialNumber, tliStateKey, {
    onError: { title: t('SG_TOAST_ERROR_MSG'), message: t('SG_TOAST_HEATING_SCHEDULE_FAILED') },
    onSuccess: { message: t('SG_TOAST_HEATING_SCHEDULE_SUCCESS') },
  });
};

export const useOptimisticPatchDhwSchedule = (gatewaySerialNumber: string, tliStateKey: QueryKey) => {
  const t = useTranslation();
  return useOptimisticPatchSchedule(gatewaySerialNumber, tliStateKey, {
    onError: { title: t('SG_TOAST_ERROR_MSG'), message: t('SG_TOAST_HEATING_SCHEDULE_FAILED') },
    onSuccess: { message: t('SG_TOAST_HEATING_SCHEDULE_SUCCESS') },
  });
};
