import { getDistributionByBrand, Distribution } from '../distributions';
import { assertNonEmptyString } from '../util/assert';

export type NodeEnvironment = 'production' | 'development' | 'test';
export type RuntimeContext = 'debug' | 'pui-dev' | 'pui-qa' | 'pui-prod';

export interface AppConfig {
  runtimeContext: RuntimeContext;
  environment: NodeEnvironment;
  distribution: Distribution;
  buildId: string;
  apiRootUrl: string;
  subscriptionKey: string;
  googleMapsApiKey: string;
}

/**
 * getAppConfig extracts 'external' configuration that is injected via environment variables or globals,
 * depending on the given runtime environment.
 *
 * @param context - either window (for PROD environments) or proc (for DEV environments)
 */
export const getAppConfig = (context: Record<string, string>): AppConfig => {
  const {
    NODE_ENV,
    REACT_APP_BRAND_KEY,
    REACT_APP_RUNTIME_CONTEXT,
    REACT_APP_BASE_API_URL,
    REACT_APP_SUB_KEY,
    REACT_APP_BUILD_ID = 'local-build',
    REACT_APP_GOOGLE_MAPS_API_KEY,
  } = context;

  assertNonEmptyString(REACT_APP_BRAND_KEY);
  assertNonEmptyString(REACT_APP_RUNTIME_CONTEXT);
  assertNonEmptyString(REACT_APP_BASE_API_URL);
  assertNonEmptyString(REACT_APP_SUB_KEY);
  assertNonEmptyString(REACT_APP_GOOGLE_MAPS_API_KEY);

  const distribution = getDistributionByBrand(REACT_APP_BRAND_KEY);
  if (!distribution) {
    throw new Error('Missing configuration for BRAND_KEY');
  }

  return {
    distribution,
    buildId: REACT_APP_BUILD_ID,
    runtimeContext: REACT_APP_RUNTIME_CONTEXT as RuntimeContext,
    environment: (NODE_ENV as NodeEnvironment) || 'production',
    apiRootUrl: REACT_APP_BASE_API_URL,
    subscriptionKey: REACT_APP_SUB_KEY,
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  };
};

export type ApiEnvironment = 'prod' | 'qa' | 'dev';

export const getApiEnvironment = (appConfig: AppConfig): ApiEnvironment => {
  if (appConfig.apiRootUrl.startsWith('https://api.vaillant-group.com')) {
    return 'prod';
  }
  if (appConfig.apiRootUrl.startsWith('https://api-stage.vaillant-group.com')) {
    return 'qa';
  }
  if (appConfig.apiRootUrl.startsWith('https://api-test.vaillant-group.com')) {
    return 'dev';
  }
  throw new Error('unknown API URL');
};
