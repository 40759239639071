/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon08302VgPressure01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon08302vgpressure01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='m440.24 192.13-29.38 17.15a147.52 147.52 0 0 1 .29 147.87l29.45 17a181.62 181.62 0 0 0-.36-182.06zm-338.19 91.33h34a147.32 147.32 0 0 1 19.72-73.69l-29.44-17a181.38 181.38 0 0 0-24.28 90.69zm2.73 31.54a180.26 180.26 0 0 0 21.55 59.21l29.45-17a145.74 145.74 0 0 1-17.49-48.06zM252 104.78a181.59 181.59 0 0 0-107.5 62.07l26.05 21.88a147.45 147.45 0 0 1 87.28-50.44zm31.46-2.73v34a147.19 147.19 0 0 1 112.92 52.67l26-21.88a181.1 181.1 0 0 0-138.92-64.79zM343 167.43a6.75 6.75 0 0 0-8.46 1.95l-76.65 101.93a28.36 28.36 0 0 0 9.73 42.26l.65.33a28.35 28.35 0 0 0 40-16.77l38.05-121.7a6.74 6.74 0 0 0-3.32-8z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
