import { Box } from '@mui/material';
import React from 'react';

import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Grid, PageHeader, SwitcherButtonGroup, Typography } from '@vaillant-professional-ui/component-libs-web';

import { FeatureToggle } from '@vaillant-professional-ui/pui-frontend-common';

import { LoadingErrorWrapper } from '../../../components/LoadingErrorWrapper';
import { CustomerDetailBreadcrumbs } from '../../../components/molecules/CustomerDetailBreadcrumbs';
import { ErelaxSchedulerPageContainer } from '../../../components/pages/ErelaxSchedulerPage';
import { DeviceInformation } from './DeviceInformation';
import { DeviceSettingsContainer, DeviceSettingsTliContainer } from '../deviceSettings';
import { SystemSettingsPageProps } from './SystemSettingsPage.types';

export const SystemSettingsPage: React.FC<SystemSettingsPageProps> = ({
  isLoading,
  error,
  selectedDevice,
  customer,
  shouldShowErelaxScheduler,
  shouldShowTliControlComponent,
  shouldShowDeviceSettingsComponent,
  pageTitle,
  setDevice,
  deviceSelectionOptions: toggleButtonItems,
}) => {
  const testId = useInstanceId('page', 'root');

  return (
    <LoadingErrorWrapper showLoadingSpinner={true} isPending={isLoading} error={error}>
      {selectedDevice && customer ? (
        <Box
          width='100%'
          display='flex'
          alignItems='stretch'
          flexDirection='column'
          pb={10}
          {...testId('container').testIdAttributes}
        >
          <CustomerDetailBreadcrumbs customer={customer} />
          <PageHeader
            testIdKey='page-header'
            title={pageTitle}
            rightComponent={
              <FeatureToggle featureName='system-settings'>
                <Typography variant='h3' i18nKey='SG_SYSTEM_SETTINGS_EXPERT_VIEW' />
              </FeatureToggle>
            }
            subtitle={
              <FeatureToggle featureName='system-settings'>
                <Grid alignItems='center' justifyContent='space-between' container>
                  <Box>
                    <SwitcherButtonGroup
                      testIdKey='topology'
                      buttons={toggleButtonItems.map((item) => ({
                        value: item.value,
                        children: <Typography>{item.label}</Typography>,
                      }))}
                      defaultValue='system-control'
                      selectedValue={selectedDevice.id}
                      onChange={(_, value: string) => !!value && setDevice(value)}
                    />
                  </Box>
                </Grid>
              </FeatureToggle>
            }
          />
          <DeviceInformation
            serialNumber={selectedDevice.serialNumber}
            nomenclature={selectedDevice.nomenclature}
            marketingName={selectedDevice.marketingName}
          />
          {shouldShowErelaxScheduler ? <ErelaxSchedulerPageContainer /> : null}
          {shouldShowTliControlComponent ? <DeviceSettingsTliContainer /> : null}
          {shouldShowDeviceSettingsComponent ? <DeviceSettingsContainer /> : null}
        </Box>
      ) : null}
    </LoadingErrorWrapper>
  );
};
