import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { PermissionGate } from '../PermissionGate';
import { getRedirectPathFromLocation } from './utils';
import { PermissionGateProps } from '../PermissionGate/PermissionGate.types';
import { AcceptTermsPageContainer as AcceptTermsGate } from '../pages/AcceptTermsPage';

export const RequirePermissions: React.FC<React.PropsWithChildren<PermissionGateProps>> = ({ ...rest }) => {
  const { isAuthenticated, prevStatus } = useAuthContext();

  const targetPath = prevStatus.isAuthenticated ? '/login' : getRedirectPathFromLocation('/login');

  if (!isAuthenticated) {
    return <Navigate to={targetPath} replace={true} />;
  }

  return (
    <AcceptTermsGate>
      <PermissionGate fallbackComponent={<Navigate to={targetPath} replace={true} />} {...rest} />
    </AcceptTermsGate>
  );
};
