import React from 'react';

import { ChartTooltip } from '../ChartTooltip';
import { ChartTooltipContainerProps, TooltipDataPoint } from '../ChartTooltip/ChartTooltip.types';
import { useTooltipMetaData } from './CursorChartTooltip.hooks';
import { DataPoints } from './CursorChartTooltip.types';

export const CursorChartTooltip: React.FC<
  Omit<ChartTooltipContainerProps<{ x: Date; y: number }>, 'titleRenderer'> & {
    getTooltipValues: (position: Date) => { timeRange: [Date, Date]; dataPoints: DataPoints } | null;
    titleRenderer: (data: { timeRange: [Date, Date]; dataPoints: DataPoints | null } | null) => string;
  }
> = ({ datum, center, width = 0, titleRenderer, getTooltipValues, ...chartData }) => {
  const tooltipValues = datum ? getTooltipValues?.(datum.x) : null;
  const title = titleRenderer?.(tooltipValues);

  const activePoints: TooltipDataPoint[] =
    (tooltipValues &&
      Object.entries(tooltipValues.dataPoints).map(([key, data]) => ({
        name: key,
        value: data.value[1],
        unit: data.unit,
        style: { data: { stroke: data.color, fill: data.color } },
      }))) ??
    [];

  const {
    isArrowVisible,
    fontSize,
    containerRef,
    position,
    xPosition,
    xPositionContent,
    yPosition,
    yPositionContent,
    containerWidth,
  } = useTooltipMetaData({
    width,
    centerY: center?.y ?? chartData.y ?? 0,
    centerX: center?.x ?? chartData.x ?? 0,
    dataPointsCount: activePoints?.length ?? 0,
  });

  return (
    <ChartTooltip<TooltipDataPoint>
      containerRef={containerRef}
      position={position}
      xPosition={xPosition}
      xPositionContent={xPositionContent}
      yPosition={yPosition}
      yPositionContent={yPositionContent}
      containerWidth={containerWidth}
      fontSize={fontSize}
      activePoints={activePoints}
      title={title}
      isArrowVisible={isArrowVisible}
      {...chartData}
    />
  );
};
