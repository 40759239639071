import * as fromSettings from './settings/settings.reducer';
import * as fromSession from './session/session.reducer';
import { State } from './rootReducer';

export const getRoles = (state: State) => fromSession.getRoles(state.session);
export const getCountry = (state: State) => fromSettings.getCountry(state.settings);
export const getBrowserLanguage = (state: State, primary?: boolean) =>
  fromSettings.getBrowserLanguage(state.settings, primary);
export const getLanguage = (state: State) => fromSettings.getLanguage(state.settings);
export const getIsTermsAccepted = (state: State, accountId?: string) =>
  fromSettings.getIsTermsAccepted(state.settings, accountId);
export const getUserLanguage = (state: State, primary?: boolean) =>
  fromSettings.getUserLanguage(state.settings, primary);
export const getReadNotifications = (state: State, contactId: string) =>
  fromSettings.getReadNotifications(state.settings, contactId);
export const getUserInfo = (state: State) => fromSettings.getUserInfo(state.settings);
export const getHasDismissedMaxDatapointsWarning = (state: State) =>
  fromSettings.getHasDismissedMaxDatapointsWarning(state.settings);
