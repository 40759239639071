import React from 'react';

import { Box } from '@mui/system';
import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { selectors } from '@vaillant-professional-ui/pui-frontend-common';
import { DeviceInformationProps } from './SystemSettingsPage.types';

export const DeviceInformation: React.FC<DeviceInformationProps> = ({ marketingName, nomenclature, serialNumber }) => {
  const t = useTranslation();

  return (
    <Box display='flex' flexDirection='column' gap='8px' mb={8}>
      <Typography bold variant='h3'>
        {marketingName}
      </Typography>
      <Box display='flex' flexWrap='wrap' gap={[0, 6]}>
        <Typography color='grey.500'>{nomenclature}</Typography>
        {serialNumber && (
          <Typography color='grey.500'>
            {t('SG_APPLIANCE_DETAILS_SERIALNUMBER_SHORT')}: {selectors.boiler.getHighlightedArticleNumber(serialNumber)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
