import deburr from 'lodash/deburr';
import memoize from 'lodash/memoize';

import { models } from '@vaillant-professional-ui/pui-frontend-common';
import { sortFncFactory } from '../../lib/sorting';
import { CustomerRow } from './CustomerTable.types';

const sortByName = sortFncFactory((item: CustomerRow) => [deburr(item.customer.fullName).toLowerCase()]);

const sortByDevice = sortFncFactory((item: CustomerRow) => item.customer.nomenclature?.toLowerCase());

const sortByAddress = sortFncFactory((item: CustomerRow) => [item.customer.addressLine2, item.customer.addressLine1]);

export const getColumns = memoize((t: models.TranslationFnc) => [
  {
    key: 'name',
    label: t('SG_JOBLIST_NAME'),
    sort: sortByName,
  },
  {
    key: 'device',
    label: t('SG_JOBLIST_APPLIANCE'),
    sort: sortByDevice,
  },
  {
    key: 'address',
    label: t('SG_JOBDETAILS_ADDRESS'),
    sort: sortByAddress,
  },
  {
    key: 'actions',
    align: 'right' as const,
  },
]);
