import { Toolbar } from '@mui/material';
import { Grid, styled } from '@vaillant-professional-ui/component-libs-web';
import TextLink from '../TextLink';

export const ContainerAnonymous: typeof Toolbar = styled(Toolbar)`
  flex-grow: 0;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);
  z-index: ${({ theme }) => theme.zIndex.appBar};
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ContainerAuthenticated: typeof Toolbar = styled(Toolbar)`
  flex-grow: 0;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);
  z-index: ${({ theme }) => theme.zIndex.appBar};
  flex-direction: column;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    flex-wrap: nowrap;
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const FooterLink = styled(TextLink)`
  font-size: 12px;
  line-height: 22px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.grey['700']};
`;

export const FooterLinkContainer = styled(Grid)`
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    justify-content: flex-start;
  }
`;

export const FooterLogoWrapper = styled(Grid)`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: flex-end;
  }
`;

export const FooterLogo = styled('img')`
  height: 32px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 24px;
  }
`;
