import { ChipProps } from '@vaillant-professional-ui/component-libs-web';
import { api, hooks, models, selectors, util } from '@vaillant-professional-ui/pui-frontend-common';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { IntlShape } from 'react-intl';

import {
  Icon50100VgDisconnected01,
  Icon50900VgPressureLevel101,
  Icon51000VgPressureLevel201,
  Icon51100VgPressureLevel301,
  Icon51200VgPressureLevel401,
  Icon51300VgPressureLevel501,
  IconProps,
} from '@vaillant-professional-ui/component-libs-web';

export type StatusType = models.DtcTypeName | models.ActivationStatus | 'PREDICTION' | 'OFFLINE' | 'PENDING' | 'OK';

export interface CustomerStatusProp {
  color: ChipProps['color'];
  title: string | null;
  type: StatusType;
  icon?: React.FC<IconProps>;
  variant?: ChipProps['variant'];
  subtitle?: string | null;
  tooltip?: React.ReactNode | null;
  occurrence?: {
    date: string;
    time: string;
  } | null;
  styles?: {
    typography?: React.CSSProperties;
    chip?: React.CSSProperties;
  };
  dtc?: models.TroubleCodes;
}

const {
  customer: { isOffline, getActiveTroubleCodes, getLastHeartbeat, hasF22Prediction },
} = selectors;

const PressureLevels = [
  Icon50900VgPressureLevel101,
  Icon51000VgPressureLevel201,
  Icon51100VgPressureLevel301,
  Icon51200VgPressureLevel401,
  Icon51300VgPressureLevel501,
];

export const getCustomerStatusProps = (
  customer: models.ICustomer | models.ICustomerDetail,
  t: (key: string) => string,
  formatDifference: ReturnType<typeof hooks.useLocalizedDifferenceInDays>,
  formatDate: IntlShape['formatDate'],
  formatTime: IntlShape['formatTime'],
): CustomerStatusProp[] => {
  const result = [];

  // Failures + Warnings
  const dtcs = getActiveTroubleCodes(customer).filter((dtc) => dtc.type !== 'STATUS') ?? [];
  const sortedDtcs = sortBy(dtcs, 'occurredAt').reverse();

  sortedDtcs.forEach((dtc) => {
    const occurrence = dtc.occurredAt
      ? {
          date: formatDate(dtc.occurredAt),
          time: formatTime(dtc.occurredAt),
          _raw: dtc.occurredAt,
        }
      : null;

    result.push({
      title: util.troubleCodes.troubleCodeInsightCodeToString(dtc),
      color: util.troubleCodes.troubleCodeInsightCodeToColorType(dtc) as ChipProps['color'],
      subtitle: dtc.title,
      type: dtc.type,
      dtc,
      occurrence,
    });
  });

  // Offline
  if (isOffline(customer)) {
    const lastHeartbeat = getLastHeartbeat(customer);
    result.push({
      icon: Icon50100VgDisconnected01,
      title: t('SG_DASHBOARD_OFFLINE'),
      color: 'default',
      type: 'OFFLINE',
      occurrence: lastHeartbeat
        ? {
            date: formatDate(lastHeartbeat),
            time: formatTime(lastHeartbeat),
            // @ts-ignore
            _raw: lastHeartbeat.toISOString(),
          }
        : null,
    });
  }

  // Pending or failed validation
  const incompleteStatusKeys = selectors.customer.incompleteStatusTextKeys(customer);
  if (incompleteStatusKeys) {
    result.push({
      title: t(incompleteStatusKeys.titleKey),
      color: 'default',
      type: selectors.customer.getActivationStatus(customer),
      subtitle: t(incompleteStatusKeys.subtitleKey),
    });
  }

  // F.22 prediction
  if (hasF22Prediction(customer)) {
    const { icon, f22Title } = api.useCases.selectF22IconAndTitle(customer, PressureLevels, t, formatDifference);
    result.push({
      icon,
      title: f22Title ?? null,
      color: 'warning',
      type: 'PREDICTION',
    });
  }

  if (result.length === 0) {
    result.push({
      title: t('SG_OK'),
      color: 'default',
      type: 'OK',
    });
  }

  return sortBy(result, (row) => {
    // @ts-ignore
    return row.occurrence?._raw || Infinity;
  }).reverse() as CustomerStatusProp[];
};
