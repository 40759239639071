import sortBy from 'lodash/sortBy';
import { api } from '../../../..';
import { extractArticleNumber } from '../../../selectors/boiler';
import { nonNullable } from '../../../util';
import { SystemOverviewDevice } from './System.types';
import orderBy from 'lodash/orderBy';
import { TranslateFnc } from '@vaillant-professional-ui/component-libs-common';

const deviceTypeMap: Partial<
  Record<api.maintenance.v2.DeviceResponse['device_type'], SystemOverviewDevice['genericType']>
> = {
  'HEAT_GENERATOR/HEAT_PUMP': 'HEATPUMP',
  'HEAT_GENERATOR/BOILER': 'BOILER',
  GATEWAY: 'GATEWAY',
  'GATEWAY/CAG': 'GATEWAY',
  'GATEWAY/ERELAX': 'GATEWAY',
  CONTROL: 'CONTROL',
  'CONTROL/TLI': 'CONTROL',
  'CONTROL/VRC700': 'CONTROL',
  ERELAX_THERMOSTAT: 'CONTROL',
} as const;

const sortedDeviceTypes = Object.keys(deviceTypeMap);

const isHeatGenerator = (device: api.maintenance.v2.DeviceResponse) => device.device_type.includes('HEAT_GENERATOR');

const defaultSortDevicesFunction = (
  unsortedDevices: api.maintenance.v2.DeviceResponse[],
): api.maintenance.v2.DeviceResponse[] =>
  sortBy(unsortedDevices, (device) => sortedDeviceTypes.indexOf(device.device_type)).filter(
    (device) => device.device_type !== 'ERELAX_THERMOSTAT',
  );

const mapDevicesResponseToSystemOverviewDevices = (
  devices: api.maintenance.v2.DeviceResponse[],
  sortDevicesFunction = defaultSortDevicesFunction,
  t: TranslateFnc,
): SystemOverviewDevice[] => {
  const shouldShowMainHeatSource = devices.filter(isHeatGenerator).length > 1;

  return sortDevicesFunction(devices)
    .map((device) => {
      const genericType = deviceTypeMap[device.device_type];

      if (!genericType) {
        return null;
      }

      const { bus_coupler_address, software_version, software_product_number, software_revision } =
        device.device_identification || {};

      const isMainHeatSource = shouldShowMainHeatSource && isHeatGenerator(device) && bus_coupler_address === 0;

      const softwareVersion = Number.isFinite(software_product_number)
        ? `${software_product_number}${
            Number.isFinite(software_version)
              ? `.${software_version}${Number.isFinite(software_revision) ? `.${software_revision}` : ''}`
              : ''
          }`
        : undefined;

      const articleNumber = device.serial_number && extractArticleNumber(device.serial_number);

      const label = t(`SG_DEVICE_TYPE_${genericType}`);

      return {
        id: device.id,
        label,
        type: device.device_type,
        genericType,
        isMainHeatSource,
        marketingName: device.marketing_name,
        nomenclature: device.nomenclature,
        serialNumber: device.serial_number,
        articleNumber,
        softwareVersion,
      };
    })
    .filter(nonNullable);
};

type SupportedValueKey = keyof typeof supportedDeviceTypes;

const supportedDeviceTypes = {
  CONTROL: 0,
  'CONTROL/TLI': 0,
  'CONTROL/VRC700': 0,
  ERELAX_THERMOSTAT: 0,
  'HEAT_GENERATOR/HEAT_PUMP': 1,
  'HEAT_GENERATOR/BOILER': 2,
} as const;

const isSupportedDeviceType = (
  device_type: api.maintenance.v2.DeviceResponse['device_type'],
): device_type is SupportedValueKey => Object.keys(supportedDeviceTypes).includes(device_type);

const filterBySupportedDeviceType = (devices: api.maintenance.v2.DeviceResponse[] = []) =>
  devices.filter((device) => isSupportedDeviceType(device.device_type));

const getOrderValueByDeviceType = (deviceResponse: api.maintenance.v2.DeviceResponse) =>
  supportedDeviceTypes[deviceResponse.device_type as SupportedValueKey];

const buildSupportedDevicesList = (devices: api.maintenance.v2.DeviceResponse[]) => {
  const supportedDevices = filterBySupportedDeviceType(devices);

  return orderBy(supportedDevices, getOrderValueByDeviceType, 'asc');
};

export { buildSupportedDevicesList, mapDevicesResponseToSystemOverviewDevices };
