import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useQueries, useQuery } from '../../hooks';
import { emfApi } from './emf.endpoints';
import {
  EmfBucketsRequestParams,
  EmfBucketsResponse,
  EmfBucketsResponseWithDeviceId,
  EmfDevicesResponse,
  EmfErrorResponse,
} from './emf.types';
import { filterSupportedDevices } from './emf.util';

export const useEmfDevices = (
  systemId: string,
  options?: Omit<
    UseQueryOptions<AxiosResponse<EmfDevicesResponse>, AxiosError<EmfErrorResponse>, EmfDevicesResponse>,
    'select'
  >,
) => {
  return useQuery<EmfDevicesResponse, AxiosRequestConfig<never>, EmfDevicesResponse, EmfErrorResponse>(
    ['emfDevices', systemId],
    emfApi.getEmfDevices(systemId),
    {
      ...options,
      select: (response) => filterSupportedDevices(response.data),
    },
  );
};

export const useEmfBuckets = (
  paramsList: EmfBucketsRequestParams[],
  options?: UseQueryOptions<
    AxiosResponse<EmfBucketsResponse>,
    AxiosError<EmfErrorResponse>,
    EmfBucketsResponseWithDeviceId
  >,
) => {
  return useQueries<EmfBucketsResponse, AxiosRequestConfig<never>, EmfBucketsResponseWithDeviceId, EmfErrorResponse>(
    paramsList.map((params) => {
      return {
        key: ['emfBuckets', params.systemId, params.deviceId, params.startDate, params.endDate, params.resolution],
        requestConfig: emfApi.getEmfBuckets(params),
        requestOptions: {
          select: (response) => ({ data: response.data, deviceId: params.deviceId }),
          ...options,
        },
      };
    }),
  );
};
