import React from 'react';
import { Button, Grid, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Box } from '@mui/material';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { ResendConsentProps } from './ResendConsent.types';

export const ResendConsent: React.FC<React.PropsWithChildren<ResendConsentProps>> = ({ resendConsent, onClose }) => {
  const t = hooks.useTranslation();

  return (
    <Box>
      <Box mb={6}>
        <Grid container direction='row'>
          <Grid item>
            <Typography data-testid={'resendConsentConfirmText'} i18nKey={'SG_RESEND_CONSENT_TEXT'} />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4} alignContent='center'>
        <Grid container direction='row' justifyContent='center'>
          <Box mr={2}>
            <Button testIdKey='cancel-remove' variant='outlined' color='primary' onClick={() => onClose(false)}>
              {t('SG_CANCEL')}
            </Button>
          </Box>
          <Box mr={2}>
            <Button testIdKey='perform-remove' variant='contained' color='primary' onClick={resendConsent}>
              {t('SG_OK')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default ResendConsent;
