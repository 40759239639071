import * as React from 'react';
import { Snackbar, SnackbarOrigin } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Alert } from '../Alert';
import { ToastProps } from './Toast.types';
import { StyledPaper } from './Toast.styled';

export const Toast: React.FC<React.PropsWithChildren<ToastProps>> = (props) => {
  const position: SnackbarOrigin = { vertical: 'top', horizontal: 'right' };
  const {
    severity,
    isOpen,
    onClose,
    title,
    body,
    id,
    duration,
    snackbarProps = { anchorOrigin: position },
    testIdKey,
  } = props;
  const testId = useInstanceId('toast', testIdKey);
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={duration}
      onClose={onClose}
      {...snackbarProps}
      {...testId().testIdAttributes}
    >
      <StyledPaper elevation={20} $anchorOrigin={snackbarProps.anchorOrigin}>
        <Alert title={title} body={body} id={id} severity={severity} onClose={onClose} />
      </StyledPaper>
    </Snackbar>
  );
};
