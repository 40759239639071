import * as React from 'react';
import csCz from '../../../assets/i18n/cs-cz.json';
import daDk from '../../../assets/i18n/da-dk.json';
import deAt from '../../../assets/i18n/de-at.json';
import deCh from '../../../assets/i18n/de-ch.json';
import deDe from '../../../assets/i18n/de-de.json';
import elGr from '../../../assets/i18n/el-gr.json';
import enGb from '../../../assets/i18n/en-gb.json';
import esEs from '../../../assets/i18n/es-es.json';
import etEe from '../../../assets/i18n/et-ee.json';
import fiFi from '../../../assets/i18n/fi-fi.json';
import frBe from '../../../assets/i18n/fr-be.json';
import frFr from '../../../assets/i18n/fr-fr.json';
import hrHr from '../../../assets/i18n/hr-hr.json';
import huHu from '../../../assets/i18n/hu-hu.json';
import itIt from '../../../assets/i18n/it-it.json';
import ltLt from '../../../assets/i18n/lt-lt.json';
import lvLv from '../../../assets/i18n/lv-lv.json';
import nlBe from '../../../assets/i18n/nl-be.json';
import nlNl from '../../../assets/i18n/nl-nl.json';
import noNo from '../../../assets/i18n/no-no.json';
import plPl from '../../../assets/i18n/pl-pl.json';
import ptPt from '../../../assets/i18n/pt-pt.json';
import roRo from '../../../assets/i18n/ro-ro.json';
import ruRu from '../../../assets/i18n/ru-ru.json';
import skSk from '../../../assets/i18n/sk-sk.json';
import slSi from '../../../assets/i18n/sl-si.json';
import srRs from '../../../assets/i18n/sr-rs.json';
import svSe from '../../../assets/i18n/sv-se.json';
import trTr from '../../../assets/i18n/tr-tr.json';
import ukUa from '../../../assets/i18n/uk-ua.json';
import zhCn from '../../../assets/i18n/zh-cn.json';
import { I18nContextProviderProps, I18nContextType } from './I18nProvider.types';

export const localeMap: Record<string, { translations: Record<string, string>; dateFns: object }> = {
  'cs-cz': { translations: csCz, dateFns: require(`date-fns/locale/cs`).default },
  'da-dk': { translations: daDk, dateFns: require(`date-fns/locale/da`).default },
  'de-at': { translations: deAt, dateFns: require(`date-fns/locale/de-AT`).default },
  'de-ch': { translations: deCh, dateFns: require(`date-fns/locale/de`).default },
  'de-de': { translations: deDe, dateFns: require(`date-fns/locale/de`).default },
  'el-gr': { translations: elGr, dateFns: require(`date-fns/locale/el`).default },
  'en-gb': { translations: enGb, dateFns: require(`date-fns/locale/en-GB`).default },
  'es-es': { translations: esEs, dateFns: require(`date-fns/locale/es`).default },
  'et-ee': { translations: etEe, dateFns: require(`date-fns/locale/et`).default },
  'fi-fi': { translations: fiFi, dateFns: require(`date-fns/locale/fi`).default },
  'fr-be': { translations: frBe, dateFns: require(`date-fns/locale/fr`).default },
  'fr-fr': { translations: frFr, dateFns: require(`date-fns/locale/fr`).default },
  'hr-hr': { translations: hrHr, dateFns: require(`date-fns/locale/hr`).default },
  'hu-hu': { translations: huHu, dateFns: require(`date-fns/locale/hu`).default },
  'it-it': { translations: itIt, dateFns: require(`date-fns/locale/it`).default },
  'lt-lt': { translations: ltLt, dateFns: require(`date-fns/locale/lt`).default },
  'lv-lv': { translations: lvLv, dateFns: require(`date-fns/locale/lv`).default },
  'nl-be': { translations: nlBe, dateFns: require(`date-fns/locale/nl-BE`).default },
  'nl-nl': { translations: nlNl, dateFns: require(`date-fns/locale/nl`).default },
  'no-no': { translations: noNo, dateFns: require(`date-fns/locale/nn`).default },
  'pl-pl': { translations: plPl, dateFns: require(`date-fns/locale/pl`).default },
  'pt-pt': { translations: ptPt, dateFns: require(`date-fns/locale/pt`).default },
  'ro-ro': { translations: roRo, dateFns: require(`date-fns/locale/ro`).default },
  'ru-ru': { translations: ruRu, dateFns: require(`date-fns/locale/ru`).default },
  'sk-sk': { translations: skSk, dateFns: require(`date-fns/locale/sk`).default },
  'sl-si': { translations: slSi, dateFns: require(`date-fns/locale/sl`).default },
  'sr-rs': { translations: srRs, dateFns: require(`date-fns/locale/sr`).default },
  'sv-se': { translations: svSe, dateFns: require(`date-fns/locale/sv`).default },
  'tr-tr': { translations: trTr, dateFns: require(`date-fns/locale/tr`).default },
  'uk-ua': { translations: ukUa, dateFns: require(`date-fns/locale/uk`).default },
  'zh-cn': { translations: zhCn, dateFns: require(`date-fns/locale/zh-CN`).default }
};

export const localeFallbackMap: Record<string, string> = {
  cs: 'cs-cz',
  da: 'da-dk',
  de: 'de-de',
  el: 'el-gr',
  en: 'en-gb',
  es: 'es-es',
  et: 'et-ee',
  fi: 'fi-fi',
  fr: 'fr-fr',
  hr: 'hr-hr',
  hu: 'hu-hu',
  it: 'it-it',
  lt: 'lt-lt',
  lv: 'lv-lv',
  nl: 'nl-nl',
  no: 'no-no',
  pl: 'pl-pl',
  pt: 'pt-pt',
  ro: 'ro-ro',
  ru: 'ru-ru',
  sk: 'sk-sk',
  sl: 'sl-si',
  sr: 'sr-rs',
  sv: 'sv-se',
  tr: 'tr-tr',
  uk: 'uk-ua',
  zh: 'zh-cn'
};

const defaultI18nContext: I18nContextType = {
  translate: (f) => f
};

export const I18nContext = React.createContext(defaultI18nContext);

export const I18nContextProvider: React.FC<React.PropsWithChildren<I18nContextProviderProps>> = ({
  children,
  ...rest
}) => {
  return <I18nContext.Provider value={rest}>{children}</I18nContext.Provider>;
};
