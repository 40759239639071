/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon07100VgSearch01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon07100vgsearch01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M420.2 283.63a96.38 96.38 0 1 0-156-28.44L131.57 387.86a22.67 22.67 0 0 0 0 32.07l16 16a22.69 22.69 0 0 0 32.07 0l132.7-132.63a96.53 96.53 0 0 0 107.86-19.67zm-120.26-16a73.7 73.7 0 1 1 104.23 0 73.78 73.78 0 0 1-104.23-.03z'
      />
    </svg>
  );
};
