/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon39501VgGroup01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon39501vggroup01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M362.84 226.77a78.78 78.78 0 0 1-.9 11.87A56.69 56.69 0 1 0 333.27 165a79.23 79.23 0 0 1 29.57 61.77zm-181.42 17.01a56.44 56.44 0 0 0 23.58-5.14 80.11 80.11 0 0 1-.89-11.87A79.27 79.27 0 0 1 233.66 165a56.7 56.7 0 1 0-52.24 78.75zm272.12 11.34h-96a80.06 80.06 0 0 1-9 17h2.95a45.4 45.4 0 0 1 45.35 45.35v66.59h79.37V277.79a22.67 22.67 0 0 0-22.67-22.67zm-235.15 17.01a79.61 79.61 0 0 1-9-17h-96a22.67 22.67 0 0 0-22.68 22.67v106.27h79.37v-66.59a45.4 45.4 0 0 1 45.36-45.35zm65.08 11.33a56.69 56.69 0 1 0-56.7-56.69 56.69 56.69 0 0 0 56.7 56.69z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M351.5 294.8H215.44a22.67 22.67 0 0 0-22.68 22.68v106.27h181.42V317.48a22.68 22.68 0 0 0-22.68-22.68z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
