interface HandleComponentProps {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const HandleComponent = ({ x, y, width, height }: HandleComponentProps) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      x={x}
      y={y}
      viewBox='0 0 309.000000 482.000000'
      preserveAspectRatio='xMidYMid meet'
      style={{ cursor: 'col-resize' }}
    >
      <g transform='translate(0.000000,482.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='#fff'>
        <path
          d='M1125 4374 c-11 -2 -45 -9 -75 -15 -125 -25 -281 -122 -365 -227 -55
-69 -109 -175 -132 -262 -17 -61 -18 -165 -18 -1580 0 -1419 1 -1519 18 -1584
49 -191 175 -349 350 -437 145 -73 149 -74 722 -74 503 0 511 0 590 23 249 72
429 264 481 514 12 58 14 299 12 1586 l-3 1517 -23 70 c-61 183 -190 331 -352
407 -135 64 -118 62 -675 64 -280 1 -519 0 -530 -2z m1086 -192 c162 -65 285
-205 318 -360 8 -37 11 -491 11 -1546 0 -1457 0 -1495 -20 -1557 -46 -149
-156 -266 -306 -326 l-69 -28 -480 -3 c-330 -2 -501 1 -548 8 -144 25 -292
131 -355 256 -66 130 -62 31 -62 1661 0 1615 -3 1528 57 1653 33 69 141 179
211 214 108 55 114 55 672 53 510 -2 516 -2 571 -25z'
        />
        <path
          d='M1874 3365 c-2 -5 -3 -532 -1 -1170 l2 -1160 83 -3 82 -3 0 1170 0
1169 -81 4 c-49 2 -83 -1 -85 -7z'
        />
        <path d='M1290 2200 l0 -1171 83 3 82 3 2 1168 2 1167 -84 0 -85 0 0 -1170z' />
      </g>
    </svg>
  );
};
