import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import { Select } from '@vaillant-professional-ui/component-libs-web';
import { LanguageSelectionProps, isLanguage } from './LanguageSelection.types';
import { languageOptions } from './LanguageSelection.util';

export const LanguageSelection: React.FC<LanguageSelectionProps> = ({ setLanguage, currentLanguage }) => {
  const handleSetLanguage = (event: SelectChangeEvent<unknown>) => {
    if (isLanguage(event.target.value)) {
      setLanguage(event.target.value);
    }
  };

  return (
    <Select
      testIdKey='language-select'
      width={76}
      initialValue={currentLanguage}
      value={currentLanguage}
      onChange={handleSetLanguage}
      options={languageOptions}
      size='big'
      variant='filled'
    />
  );
};
