import React from 'react';

import { Box } from '@mui/system';
import { ButtonContentProps } from './EmfFilterCard.types';
import { Typography } from '@vaillant-professional-ui/component-libs-web';

export const ButtonContent = ({ isActive, title, iconComponent: IconComponent }: ButtonContentProps) => {
  return (
    <Box
      display='flex'
      flexDirection={['column', 'row']}
      alignItems={['center']}
      justifyContent={['center', 'flex-start']}
    >
      <Box mr={[0, 2]} display='flex' alignItems='center' mb={[1, 0]}>
        <IconComponent color={isActive ? 'primary.main' : 'grey.500'} width={24} height={24} />
      </Box>
      <Typography>{title}</Typography>
    </Box>
  );
};
