import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import fromUnixTime from 'date-fns/fromUnixTime';
import { api, hooks, State, store } from '@vaillant-professional-ui/pui-frontend-common';
import { selectUnit } from '@formatjs/intl-utils';
import {
  getUnreadNotifications,
  customersToNotifications,
  NotificationBlock,
  notificationsToTimeGroups,
} from '../../Utils/notificationsUtils';
import { NotificationsMenu } from './NotificationsMenu';

export const NotificationsMenuContainer: React.FC<React.PropsWithChildren> = () => {
  const intl = useIntl();
  const t = hooks.useTranslation();
  const dispatch = useDispatch();
  const { userContactId } = api.useCases.useGetUserInfo();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const readNotifications = useSelector<State, string[]>((state) =>
    store.getReadNotifications(state, userContactId ?? ''),
  );

  // { enabled: false } here because we don't want to fetch the whole customer list in the customer detail page
  const { data: customers } = api.maintenance.v1.useCustomerList({ enabled: false });

  const notifications = customersToNotifications(customers || [], readNotifications);
  const timeGroups = notificationsToTimeGroups(
    notifications,
    (date) => intl.formatDate(date, { dateStyle: 'medium', timeStyle: 'medium' }),
    (unixTs: number) => {
      const date = fromUnixTime(unixTs);
      const { value, unit } = selectUnit(date);
      return intl.formatRelativeTime(value, unit);
    },
  );
  const groupedNotifications: NotificationBlock[] = [{ title: t('SG_SYSTEM_NOTIFICATIONS'), timeGroups }];

  const handleNotificationSelect = (notificationId: string) => {
    const notification = notifications.find((notification) => notification.id === notificationId);
    if (notification?.heatGeneratorSerialNumber) {
      dispatch(store.setNotificationRead(notificationId, userContactId ?? ''));
      navigate(`/customers/${notification.heatGeneratorSerialNumber}`);
    }
    setIsDropdownOpen(false);
  };

  return customers ? (
    <NotificationsMenu
      countUnreadNotifications={getUnreadNotifications(notifications, readNotifications).length}
      notifications={notifications}
      readNotifications={readNotifications}
      groupedNotifications={groupedNotifications}
      isDropdownOpen={isDropdownOpen}
      onSetIsDropdownOpen={setIsDropdownOpen}
      onSelectNotification={handleNotificationSelect}
    />
  ) : null;
};
