import { AxiosResponse, AxiosError } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useQuery } from '../../hooks';
import { productIdentificationApi } from './productIdentification.endpoints';
import { ProductIdentificationResponse } from './productIdentification.types';

export const useProductIdentification = <Data = ProductIdentificationResponse>(
  articleNumber: string,
  options?: UseQueryOptions<AxiosResponse<ProductIdentificationResponse>, AxiosError, Data>,
) => {
  return useQuery<ProductIdentificationResponse, AxiosError, Data>(
    ['productIdentification', articleNumber],
    productIdentificationApi.getProductIdentification(articleNumber),
    { select: (select) => select.data as unknown as Data, ...options },
  );
};
