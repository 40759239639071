import { IconButton } from '@mui/material';
import { styled } from '../../../util/styling';
import { Button } from '../../atoms/Button';
import { Card } from '../Card';
import { CalendarContainer as CalendarContainerBase } from 'react-datepicker';

export const DatePickerInputButton = styled(Button)`
  > span {
    color: ${({ theme }) => theme.palette.common.black};
  }
  text-transform: none;
  border-radius: 0;
  height: auto;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
    min-width: 300px;
  }
`;

export const DatePickerContainer = styled('div')`
  display: flex;
  flex-direction: row;

  > div {
    border: 1px ${({ theme }) => theme.palette.grey['500']} solid;
    border-right: none;
  }

  > div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  > div:nth-child(2) {
    flex-grow: 1;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      flex-grow: 0;
    }
  }

  > div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px ${({ theme }) => theme.palette.grey['500']} solid;
  }
`;

export const DatePickerInputField = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const DatePickerInputNavigationButton = styled(IconButton)`
  min-width: auto;
  height: auto;
  border-radius: 0;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 500px;
    max-width: 500px;
  }

  .react-datepicker {
    border: none;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__current-month {
    margin: 0 0 12px calc(100% / 7 / 2.65);
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: normal;
  }

  .react-datepicker__day-name {
    text-transform: uppercase;
    margin: 0;
  }

  .react-datepicker__week,
  .react-datepicker__header,
  .react-datepicker__day-names {
    display: flex;
    justify-content: stretch;
    flex-grow: 1;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 0;
    margin-top: 8px;
  }

  .react-datepicker__day-name {
    flex-grow: 1;
    color: ${({ theme }) => theme.palette.grey['600']};
    font-size: 0.8em;
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__year-text {
    padding: 4px;
    margin: 0;

    flex-grow: 1;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 0.9em;
  }

  .react-datepicker__month-text,
  .react-datepicker__year-text {
    margin: 0 0 16px 0;
  }

  .react-datepicker__day {
    width: calc(100% / 7);
  }

  .react-datepicker__month-text {
    padding: 8px 4px;
  }

  .react-datepicker__year-text {
    flex-basis: 30%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected.react-datepicker__day--selected:hover,
  .react-datepicker__month--selected,
  .react-datepicker__month-text.react-datepicker__month--selected:hover,
  .react-datepicker__year-text--selected {
    color: ${({ theme }) => theme.palette.common.black};
    background-color: ${({ theme }) => theme.palette.primary.lightest};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${({ theme }) => theme.palette.primary.lightest};
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    border-radius: 0;
    background-color: ${({ theme }) => theme.palette.primary.lightest};
    color: ${({ theme }) => theme.palette.common.black};
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .react-datepicker__day--in-range:hover {
    background-color: ${({ theme }) => theme.palette.grey['200']};
  }

  .react-datepicker__day:hover :not(.react-datepicker__day--in-range) {
    border-radius: 16px;
    background-color: ${({ theme }) => theme.palette.primary.lightest};
    color: ${({ theme }) => theme.palette.common.black};
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__month-wrapper,
  .react-datepicker__year-wrapper {
    display: flex;
    justify-content: stretch;
    min-width: auto;
    max-width: none;
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.palette.grey['400']};
  }

  .react-datepicker__day--today {
    background-color: inherit;

    &:hover {
      background-color: ${({ theme }) => theme.palette.grey['200']};
    }
  }
`;

export const StyledCalendarContainer = styled(CalendarContainerBase)`
  border: 0;
`;
