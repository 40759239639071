/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon01800VgTemperatureOutside01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon01800vgtemperatureoutside01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M481.62 375.77V244.19a23.62 23.62 0 0 0-47.24 0v131.58a42.52 42.52 0 1 0 47.24 0zm-53.5 33.77a29.81 29.81 0 0 1 13.78-23.63l5.08-3.26v-14h12.59V356H447v-40.94h12.59v-12.6H447v-40.95h12.59v-12.6H447v-4.72a11 11 0 1 1 22 0v138.46l5.08 3.26a29.81 29.81 0 0 1 13.78 23.63zM240.66 77.12a11.33 11.33 0 0 0-16 0L82.89 218.85a11.37 11.37 0 0 0-3.32 8V442.3a11.34 11.34 0 0 0 11.34 11.34h283.47a11.34 11.34 0 0 0 11.34-11.34V226.87a11.34 11.34 0 0 0-3.33-8zM363 431H102.25V231.56l130.39-130.39L363 231.56z'
      />
    </svg>
  );
};
