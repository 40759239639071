import React, { useMemo } from 'react';

import { AxiosError } from 'axios';

import { baseTheme, BaseTheme } from '@vaillant-professional-ui/component-libs-common';

import {
  findNearestDataPoints,
  getFilteredByDate,
  getMinMaxDates,
  getMinMaxValues,
  groupDate,
  groupedByHour,
} from '../historyChartsCard/presenter/timeSeries';

import {
  DateTimeRange,
  NumberCoordinate,
  NumberTimeSeries,
  StringCoordinate,
  StringTimeSeries,
} from '../historyChartsCard/presenter/timeSeries.types';

import { useAppConfig } from '../../../config/AppConfigProvider';
import { useTranslation } from '../../../libs/hooks';
import { TranslationFnc } from '../../../typings/models';
import { COLORS, DTC_COLORS } from '../historyChartsCard/presenter/presenter';
import { NearestDataPoints } from '../historyChartsCard/presenter/timeSeries.types';
import { useSystemMonitoring } from './useCase';

type UseCaseData = ReturnType<typeof useSystemMonitoring>;

export interface DataProps {
  dateRange: DateTimeRange | null;
  dtcs: StringTimeSeries[];
  getTooltipValuesFactory: (
    visibleLines: string[],
    visibleDtcs: string[],
  ) => (position: Date) => {
    timeRange: [Date, Date];
    dataPoints: NearestDataPoints;
  } | null;
  lines: NumberTimeSeries[];
  title: string;
  zoomDomain: { x: DateTimeRange | undefined; y: [number, number] | undefined };
  yDomain: [number, number] | null;
}

export interface ContainerProps {
  error?: boolean | AxiosError<unknown, null>;
  setZoomRange: (range: DateTimeRange) => void;
  width: string | number;
  xDomain: DateTimeRange | null;
}

export interface ViewData {
  containerProps: ContainerProps;
  dataProps: DataProps[];
  isLoading: boolean;
}

const useThemePalette = () => {
  const appConfig = useAppConfig();
  const theme = baseTheme[appConfig.distribution.theme === 'puiGreen' ? 'green' : 'red'];
  return theme.colors;
};

const calcYDomain = (lineTimeSeries: NumberTimeSeries[]) => {
  const yDomain = getMinMaxValues(lineTimeSeries);
  if (yDomain && yDomain[0] - yDomain[1] === 0) {
    const variation = yDomain[0] * 0.1;
    yDomain[0] -= variation;
    yDomain[1] += variation;
  }
  return yDomain;
};

const mapChartData = (
  data: UseCaseData['data'],
  zoomRange: DateTimeRange | null,
  palette: BaseTheme['colors'],
  t: TranslationFnc,
) => {
  return data.map((chartData) => {
    const { lines, dtcs } = chartData;

    // @ts-ignore legacy file will be deleted
    const dtcTimeSeries = dtcs.map<StringTimeSeries>((dtc) => {
      const coordinates = dtc.values.map((v) => {
        return [v.timestamp, v.value];
      }) as StringCoordinate[];
      return {
        name: dtc.name ? t(dtc.name) : '',
        // TODO: Clean this up when removing the legacy charts
        color: dtc.label === 'refill' ? '#588BC6' : palette[DTC_COLORS[dtc.label]],
        coordinates,
        filteredByZoomDomain: getFilteredByDate<StringCoordinate>(coordinates, zoomRange),
      };
    });

    // @ts-ignore legacy file will be deleted
    const lineTimeSeries = lines.map<NumberTimeSeries>((line, index) => {
      const coordinates = line.values.map((v) => {
        return [v.timestamp, v.value];
      }) as NumberCoordinate[];

      return {
        name: line.name ? t(line.name) : '',
        color: COLORS[index], // previously line.color,
        unit: line.unit,
        coordinates,
        filteredByZoomDomain: groupDate(getFilteredByDate<NumberCoordinate>(coordinates, zoomRange), zoomRange).sort(
          (a, b) => a[0].getTime() - b[0].getTime(),
        ),
      };
    });

    const getTooltipValuesFactory: DataProps['getTooltipValuesFactory'] =
      (visibleLines, visibleDtcs) => (position: Date) =>
        findNearestDataPoints(
          position,
          lineTimeSeries.filter((item) => visibleLines.includes(item.name)),
          dtcTimeSeries.filter((item) => visibleDtcs.includes(item.name)),
          zoomRange,
        );

    const yDomain = calcYDomain(lineTimeSeries);

    return {
      dateRange: getMinMaxDates([...lineTimeSeries, ...dtcTimeSeries]),
      dtcs: dtcTimeSeries,
      getTooltipValuesFactory,
      lines: lineTimeSeries.map((line) => ({ ...line, coordinates: groupedByHour(line.coordinates) })),
      title: chartData.title,
      yDomain,
      zoomDomain: { x: zoomRange ?? undefined, y: yDomain ?? undefined },
    };
  });
};

const calcXDomain = (zoomChartData: { dateRange: DateTimeRange | null }[]) => {
  if (zoomChartData.length === 0) {
    return null;
  }

  return zoomChartData
    .filter((item) => !!item.dateRange)
    .reduce(
      (acc, chartData) => {
        const minX = Math.min(acc[0], chartData.dateRange![0].getTime());
        const maxX = Math.max(acc[1], chartData.dateRange![1].getTime());
        return [minX, maxX];
      },
      [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
    )
    .map((d) => new Date(d)) as DateTimeRange;
};

export type UsePresenter = (boilerSerialNumber: string, maxDate: number, hoursToLookBack: number) => ViewData;

export const useLegacyPresenter: UsePresenter = (boilerSerialNumber, maxDate, hoursToLookBack) => {
  const [zoomRange, setZoomRange] = React.useState<DateTimeRange | null>(null);

  const { error, isLoading, data } = useSystemMonitoring(boilerSerialNumber, maxDate, hoursToLookBack);

  const palette = useThemePalette();
  const t = useTranslation();

  const zoomChartData = useMemo(() => {
    if (!data) {
      return [];
    }
    return mapChartData(data, zoomRange, palette, t);
  }, [data, zoomRange, palette, t]);

  const xDomain = useMemo(() => {
    const newDomain = calcXDomain(zoomChartData);
    if (!newDomain) {
      return null;
    }
    if (!zoomRange) {
      setZoomRange(newDomain);
    }
    return newDomain;
  }, [setZoomRange, zoomChartData, zoomRange]);

  return {
    isLoading,
    containerProps: {
      error,
      setZoomRange,
      xDomain,
      width: '100%',
    },
    dataProps: zoomChartData,
  };
};
