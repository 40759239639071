import { UseQueryResult } from 'react-query';

export const getCombinedQueryStatus = (...queries: Partial<UseQueryResult>[]) => {
  return {
    isFetching: queries.some((query) => query.isFetching),
    isLoading: queries.some((query) => query.isLoading),
    isError: queries.some((query) => query.isError),
    isSuccess: queries.every((query) => query.isSuccess),
    errors: queries.map((query) => query.error),
  };
};
