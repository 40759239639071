import React, { useRef } from 'react';

import { features } from '@vaillant-professional-ui/pui-frontend-common';

import { useBoilerSerialNumberFromPath } from '../../../../components/Routes';
import { SystemMonitoringCharts } from './SystemMonitoringCharts';

export const SystemMonitoringChartsContainer: React.FC = () => {
  const boilerSerialNumber = useBoilerSerialNumberFromPath();
  const now = useRef(Date.now());
  const props = features.historyCharts.legacyCharts.useLegacyPresenter(boilerSerialNumber, now.current, 24 * 7);
  return <SystemMonitoringCharts {...props} />;
};
