import * as React from 'react';
import { Box } from '@mui/system';
import { ConditionalWrapper, useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';

import { BannerProps } from './Banner.types';
import { BannerStyled } from './Banner.styled';
import { Icon08201VgRight01 } from '../../atoms/Icon/Icon08201VgRight01';
import { Icon04701VgInformation01 } from '../../atoms/Icon/Icon04701VgInformation01';
import { Icon47001VgExclamationMark201 } from '../../atoms/Icon/Icon47001VgExclamationMark201';
import { Icon06501VgCheckConfirm01 } from '../../atoms/Icon/Icon06501VgCheckConfirm01';
import { Icon03500VgWarning01 } from '../../atoms/Icon/Icon03500VgWarning01';

const severityIconMap = {
  info: Icon04701VgInformation01,
  error: Icon47001VgExclamationMark201,
  success: Icon06501VgCheckConfirm01,
  warning: Icon03500VgWarning01,
};

export const Banner: React.FunctionComponent<BannerProps> = ({
  testIdKey,
  icon,
  body,
  title,
  severity,
  showMoreLinkText,
  boxProps = {},
}) => {
  const testId = useInstanceId('banner', testIdKey);
  const Icon = icon || severityIconMap[severity];

  return (
    <BannerStyled display='flex' flexDirection='column' $severity={severity} py={5} px={6} {...boxProps}>
      <Box display='flex' flexGrow={1} flexDirection='row'>
        {!!Icon && (
          <Box mr={1} display='flex' justifyContent='center' alignItems={'center'} flexGrow={0}>
            <Icon color='inherit' width={32} height={32} />
          </Box>
        )}
        <Box mr={1} display='flex' alignItems='center' flexGrow={1}>
          <ConditionalWrapper
            condition={typeof title === 'string'}
            wrapper={(children) => (
              <Typography {...testId('title').testIdAttributes} bold color='inherit'>
                {children}
              </Typography>
            )}
          >
            {title}
          </ConditionalWrapper>
          {!!showMoreLinkText && (
            <Box ml={6} display='flex' flexDirection='row' alignItems='center'>
              <Typography {...testId('title').testIdAttributes} color='inherit'>
                {showMoreLinkText.toUpperCase()}
              </Typography>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Icon08201VgRight01 width={32} height={32} color='inherit' />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {!!body && (
        <Box ml={9} mt={1}>
          <Typography {...testId('title').testIdAttributes} color='inherit'>
            {body}
          </Typography>
        </Box>
      )}
    </BannerStyled>
  );
};
