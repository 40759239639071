import { TranslationFnc } from '../../../../../typings/models';
import { nonNullable } from '../../../../../util';
import { DeviceDetailsResponseTli } from '../../../../../api/apis/maintenance/v2';
import { createCategory } from '../../../deviceSettings/useCase/model/category';
import { mapBooleanMeasuredValue, mapEnumMeasuredValue, mapNumericMeasuredValue } from './mapMeasuredValue';

const mapSystem = (response: DeviceDetailsResponseTli, t: TranslationFnc) => {
  const system = response.system;
  return [
    mapNumericMeasuredValue({
      id: 'outdoorTemperature',
      measuredValue: system?.outdoor_temperature,
      t,
    }),
    mapBooleanMeasuredValue({
      id: 'adaptiveHeatCurveEnabled',
      measuredValue: system?.heating?.adaptive_heat_curve,
      mutationId: 'adaptiveHeatCurveEnabled',
      action: system?._actions?.update_adaptive_heat_curve,
      actionPropertyKey: 'adaptiveHeatingCurve',
      t,
    }),
    mapBooleanMeasuredValue({ id: 'automaticCooling', measuredValue: system?.automatic_cooling, t }),
    mapEnumMeasuredValue({
      id: 'hybridStrategy',
      measuredValue: system?.hybrid_strategy,
      mutationId: 'hybridStrategy',
      action: system?._actions?.update_hybrid_control_strategy,
      actionPropertyKey: 'hybridControlStrategy',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'heatingCircuitBivalencePoint',
      measuredValue: system?.heating?.bivalence_point,
      mutationId: 'heatingCircuitBivalencePoint',
      action: system?._actions?.update_heating_bivalence_point,
      actionPropertyKey: 'heatingCircuitBivalencePoint',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'domesticHotWaterBivalencePoint',
      measuredValue: system?.domestic_hot_water?.bivalence_point,
      mutationId: 'domesticHotWaterBivalencePoint',
      action: system?._actions?.update_domestic_hot_water_bivalence_point,
      actionPropertyKey: 'dhwBivalencePoint',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'dhwHysteresis',
      action: system?._actions?.update_domestic_hot_water_hysteresis,
      actionPropertyKey: 'dhwHysteresis',
      measuredValue: response.system?.domestic_hot_water?.hysteresis,
      mutationId: 'dhwHysteresis',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'alternativePoint',
      measuredValue: system?.alternative_point,
      mutationId: 'alternativePoint',
      action: system?._actions?.update_alternative_point,
      actionPropertyKey: 'alternativePoint',
      t,
    }),
    mapEnumMeasuredValue({
      id: 'energyProvidePowerCutBehavior',
      measuredValue: system?.energy_provide_power_cut_behavior,
      mutationId: 'energyProvidePowerCutBehavior',
      action: system?._actions?.update_energy_provide_power_cut_behavior,
      actionPropertyKey: 'energyProvidePowerCutBehavior',
      t,
    }),
    mapEnumMeasuredValue({
      id: 'backupHeaterType',
      measuredValue: system?.backup_heater?.type,
      mutationId: 'backupHeaterType',
      action: system?._actions?.update_backup_heater_type,
      actionPropertyKey: 'backupHeaterType',
      t,
    }),
    mapEnumMeasuredValue({
      id: 'backupHeaterAllowedFor',
      measuredValue: system?.backup_heater?.allowed_for,
      mutationId: 'backupHeaterAllowedFor',
      action: system?._actions?.update_backup_heater_allowed_for,
      actionPropertyKey: 'backupHeaterAllowedFor',
      t,
    }),
    mapNumericMeasuredValue({ id: 'systemFlowTemperature', measuredValue: system?.flow_temperature, t }),
    mapNumericMeasuredValue({
      id: 'systemScheme',
      measuredValue: system?.hydraulic_map,
      mutationId: 'systemScheme',
      action: system?._actions?.update_hydraulic_map,
      actionPropertyKey: 'systemScheme',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'domesticHotWaterMaximumLoadingTime',
      measuredValue: system?.domestic_hot_water?.maximum_loading_time,
      mutationId: 'dhwMaximumLoadingTime',
      action: system?._actions?.update_domestic_hot_water_maximum_loading_time,
      actionPropertyKey: 'dhwMaximumLoadingTime',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'continuousHeatingRoomSetpoint',
      action: system?._actions?.update_continuous_heating_room_setpoint,
      actionPropertyKey: 'continuousHeatingRoomSetpoint',
      measuredValue: system?.heating?.continuous_room_setpoint,
      mutationId: 'continuousHeatingRoomSetpoint',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'maximumPreheatingTime',
      actionPropertyKey: 'maximumPreheatingTime',
      action: system?._actions?.update_maximum_preheating_time,
      mutationId: 'maximumPreheatingTime',
      measuredValue: system?.maximum_preheating_time,
      t,
    }),
    mapNumericMeasuredValue({
      id: 'domesticHotWaterFlowSetpointOffset',
      measuredValue: system?.domestic_hot_water?.flow_setpoint_offset,
      mutationId: 'domesticHotWaterFlowSetpointOffset',
      action: system?._actions?.update_domestic_hot_water_flow_setpoint_offset,
      actionPropertyKey: 'setPointOffset',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'continuousHeatingStartSetpoint',
      action: system?._actions?.update_continuous_heating_start_setpoint,
      actionPropertyKey: 'continuousHeatingStartSetpoint',
      measuredValue: system?.heating?.continuous_start_setpoint,
      mutationId: 'continuousHeatingStartSetpoint',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'maxFlowSetpointHpError',
      measuredValue: system?.max_flow_setpoint_hp_error,
      action: system?._actions?.update_max_flow_setpoint_hp_error,
      mutationId: 'maxFlowSetpointHpError',
      actionPropertyKey: 'maxFlowSetpointHpError',
      t,
    }),
    mapBooleanMeasuredValue({
      id: 'domesticHotWaterSetParallelLoading',
      measuredValue: response.system?.domestic_hot_water?.parallel_loading,
      mutationId: 'domesticHotWaterSetParallelLoading',
      action: response.system?._actions?.update_domestic_hot_water_parallel_tank_loading,
      actionPropertyKey: 'paralellTankLoadingAllowed',
      t,
    }),
  ].filter(nonNullable);
};

export const createSystem = (response: DeviceDetailsResponseTli, t: TranslationFnc) => {
  return createCategory(t('SG_APPLIANCE'), mapSystem(response, t));
};
