/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon08201VgRight01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon08201vgright01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M226.54 431.22a11.34 11.34 0 0 1-8-19.36L346.8 283.58 218.52 155.3a11.33 11.33 0 0 1 16-16l136.3 136.29a11.34 11.34 0 0 1 0 16L234.55 427.9a11.3 11.3 0 0 1-8.01 3.32z'
      />
    </svg>
  );
};
