import { Device, Dtc, DtcTranslation, SystemInformation } from './qrCodeDiagnostics.types';
import { Country, Language, LiveMonitorMode, TranslationFnc, TroubleCodes } from '../../../typings/models';
import { DiagnosticsData } from '../diagnostics';
import { createDataPoints } from '../diagnostics/diagnostics.util';
import { getModeFromThreeWayValve } from '../../useCases/LiveMonitor/LiveMonitor.utils';
import { DateFormatter } from '../../../libs/hooks';

export const getBoilerDevice = (systemInformation: SystemInformation): Device | null => {
  return systemInformation.system.devices.find((device) => device.type === 'BOILER') || null;
};

export const isValidQrCode = (systemInformation: SystemInformation) =>
  Boolean(systemInformation?.system?.connectivity_solution === 'QR_CODE');

export const getProduct = (device: Device) => {
  return {
    articleNumber: device.product.article_number,
    nomenclatures: [device.product.nomenclature],
    marketingName: device.product.marketing_name,
    serialNumber: device.serial_number,
  };
};

export const getThreeWayValveValue = (device: Device): LiveMonitorMode | null =>
  getModeFromThreeWayValve(device.diagnostics?.three_way_valve?.value);

/**
 * @private
 * Implementation detail - exported only for testing :-(
 */
export const _getDtcDescriptionForLocale = (language: Language, country: Country, dtc: Dtc): DtcTranslation => {
  const keys = Object.keys(dtc.translations);
  type TranslationKey = keyof typeof dtc.translations;

  const localeMatch = keys.find(
    (k) => k.replace('-', '_').toLocaleLowerCase() === `${language}_${country}`.toLocaleLowerCase(),
  );

  if (localeMatch) {
    return dtc.translations[localeMatch as TranslationKey];
  }
  const languageMatch = keys.find((k) => k.toLowerCase().indexOf(language.toLowerCase()) !== -1);
  if (languageMatch) {
    return dtc.translations[languageMatch as TranslationKey];
  }
  const countryMatch = keys.find((k) => k.toLowerCase().indexOf(country.toLowerCase()) !== -1);
  if (countryMatch) {
    return dtc.translations[countryMatch as TranslationKey];
  }
  if (language.toLowerCase() !== 'en') {
    return _getDtcDescriptionForLocale('en', 'GB', dtc);
  }
  return Object.values(dtc.translations)[0] ?? { title: 'no data', description: 'no data', hint: 'no data' };
};

const _getLegacyFormattedDtc = (language: Language, country: Country, dtc: Dtc): TroubleCodes => {
  const { id, code, name, occurred_at: occurredAt, rectified_at: rectifiedAt } = dtc;
  const type: TroubleCodes['type'] = dtc.type as TroubleCodes['type'];
  const dtcDescription = _getDtcDescriptionForLocale(language, country, dtc);
  return {
    id,
    type,
    code,
    occurredAt,
    rectifiedAt,
    name,
    rectified: Boolean(rectifiedAt),
    title: dtcDescription?.title ?? '',
    hint: dtcDescription?.hint ?? '',
    description: dtcDescription?.description ?? '',
  };
};

export const getLegacyFormattedDtcs = (language: Language, country: Country, dtcs: Dtc[]): TroubleCodes[] => {
  return dtcs.map((dtc) => _getLegacyFormattedDtc(language, country, dtc));
};

const snakeToCamel = (val: string) =>
  val
    .split('_')
    .map((sub) => sub.replace(/^\w/, (c) => c.toUpperCase()))
    .join('')
    .replace(/^\w/, (c) => c.toLowerCase());

type NestedObject = Record<string, unknown>;

export const camelize = (obj: NestedObject = {}) => {
  const mapped: Array<[string, unknown]> = Object.keys(obj).map((key) => {
    const val = obj[key];
    const newKey = snakeToCamel(key);

    if (typeof val === 'object' && val !== null) {
      return [newKey, camelize(val as NestedObject)];
    }
    return [newKey, val];
  });

  return Object.fromEntries(mapped);
};

export const getLegacyDiagnosticsData: (device: Device, t: TranslationFnc, df: DateFormatter) => DiagnosticsData = (
  device,
  t,
  df,
) => {
  const camelized = camelize(device.diagnostics);
  const data = { boiler: { type: 'BOILER', serialNumber: device.serial_number, ...camelized } };
  const dataPoints = createDataPoints(data, t, df);
  const result: DiagnosticsData = {
    data,
    dataPoints,
  };
  return result;
};
