import React from 'react';
import { Box } from '@mui/system';
import { Divider } from '@mui/material';

export const Footer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Box mt={2}>
        <Divider />
      </Box>
      <Box
        display='flex'
        alignItems={{ xs: 'stretch', sm: 'center' }}
        justifyContent='center'
        gap={2}
        flexDirection={{ xs: 'column', sm: 'row' }}
        p={3}
      >
        {children}
      </Box>
    </>
  );
};
