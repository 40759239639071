import * as React from 'react';
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button';
import { Icon09801VgClose01 } from '../../atoms/Icon/Icon09801VgClose01';
import { DialogProps } from './Dialog.types';

export const Dialog: React.FunctionComponent<React.PropsWithChildren<DialogProps>> = ({
  buttons,
  text,
  children = '',
  open,
  onClose,
  title = '',
  testIdKey,
  ...rest
}) => {
  const testId = useInstanceId('dialog', testIdKey);

  const content = text ? <Typography variant='body'>{text}</Typography> : children;

  return (
    <MuiDialog open={open} onClose={onClose} {...rest} {...testId('root').testIdAttributes}>
      <DialogTitle {...testId('title').testIdAttributes}>
        <Typography variant='h3'>{title}</Typography>
        {!!onClose && (
          <IconButton onClick={() => onClose()} {...testId('icon-button').testIdAttributes} size='large'>
            <Icon09801VgClose01 color='common.black' />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent {...testId('content').testIdAttributes}>{content}</DialogContent>
      {!!buttons && (
        <DialogActions>
          {buttons.map((button, index) => {
            const { disabled, label, onClick, variant = 'contained', color = 'secondary' } = button;
            return (
              <Button
                testIdKey={testId(`button-${index}`).key}
                disabled={disabled}
                key={index}
                color={color}
                onClick={onClick}
                variant={variant}
              >
                {label}
              </Button>
            );
          })}
        </DialogActions>
      )}
    </MuiDialog>
  );
};
