import { AxiosError } from 'axios';
import { useEmfDevices as useEmfDevicesBase } from '../../apis/emf';
import {
  EmfBucketResolution,
  EmfBucketsResponseWithDeviceId,
  EmfDeviceTypeResponse,
  EmfErrorResponse,
  EmfOperationMode,
} from '../../apis/emf/emf.types';
import { ConsumptionData, ConsumptionFilters, EmfDevice } from './emf.types';

export type UseEmfForAllDevicesFunction = (
  boilerSerialNumber: string,
  startDate: Date,
  endDate: Date,
  resolution: EmfBucketResolution,
) => {
  data?: Array<EmfBucketsResponseWithDeviceId> | undefined;
  isError: boolean;
  isLoading: boolean;
  errors: AxiosError<EmfErrorResponse>[];
};

export type UseConsumptionDataFunction = (param: UseConsumptionDataParams) => {
  data?: ConsumptionData;
  isLoading: boolean;
  errors: AxiosError<EmfErrorResponse>[];
};

export type UseEmfDevicesFunction = (
  boilerSerialNumber: string,
  startDate: Date,
) => {
  data?: EmfDevice[];
  defaultFilters: ConsumptionFilters;
  isHybridSystem: boolean;
  deviceTypes?: EmfDeviceTypeResponse[];
} & Omit<ReturnType<typeof useEmfDevicesBase>, 'data'>;

export interface UseConsumptionDataParams {
  boilerSerialNumber: string;
  startDate: Date;
  endDate: Date;
  dateRangePeriod: DateRangePeriod;
  selectedDevices?: 'all' | string[];
  selectedOperationModes: EmfOperationMode[];
  supportedOperationModes: EmfOperationMode[];
}

export enum DateRangeMapping {
  DAY = 'HOUR',
  WEEK = 'DAY',
  MONTH = 'DAY',
  YEAR = 'MONTH',
}

export type DateRangePeriod = keyof typeof DateRangeMapping;

export type IconName =
  | 'Icon00100VgHeating01'
  | 'Icon00300VgDomesticHotWater01'
  | 'Icon00500VgBurner01'
  | 'Icon02000VgCooling01'
  | 'Icon06600VgEcoMode01'
  | 'Icon13000VgElectric01'
  | 'Icon16100VgSolarCollector01';

export interface Item {
  label: string;
  value: string;
  icon: IconName;
  unit: string;
}
