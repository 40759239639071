import { useUrlState } from '../../lib/hooks';
import { features as commonFeatures, hooks, models, util } from '@vaillant-professional-ui/pui-frontend-common';
import { DashboardData, TableRow, ViewMode } from '../../components/Dashboard/Dashboard.types';
import { useEnforceListViewOnMobile } from '../../components/Dashboard/Dashboard.hooks';
import React, { useEffect, useMemo, useState } from 'react';
import {
  createTableRows,
  createTabs,
  enableResendConsentMail,
  enableRetryAddCustomer,
  findFirstNonemptyTab,
  hasAnyIssue,
  tabToDtcType,
  useTabs,
} from '../../components/Dashboard/Dashboard.util';
import { useIntl } from 'react-intl';
import { useFirebase } from '../../providers/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { getEndUserFullName } from '@vaillant-professional-ui/pui-frontend-common/src/selectors/customer';

export interface ItemAction {
  label: string;
  action: (row: TableRow) => void;
  disabled: (record: TableRow) => boolean;
}

export interface TableData {
  failures: TableRow[];
  maintenance: TableRow[];
  prediction: TableRow[];
  offline: TableRow[];
  pending: TableRow[];
}

export const useWebPresenter = ({
  groupedCustomers,
  setCustomerToResendConsent,
  shouldShowNoDevices,
  readNotifications,
}: {
  groupedCustomers: Partial<Record<models.CustomerDashboardFilter, models.ICustomer[]>>;
  setCustomerToResendConsent: (customer: models.ICustomer | null) => void;
  shouldShowNoDevices: boolean;
  readNotifications: string[];
}) => {
  const [selectedCustomer, setSelectedCustomer] = useUrlState('serial');
  const [_viewMode, setViewMode] = useUrlState('view', 'list');
  const viewMode = util.typings.ensureOneOf(_viewMode, ['list', 'map'], 'list') as ViewMode;
  useEnforceListViewOnMobile();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const t = hooks.useTranslation();
  const formatDifference = hooks.useLocalizedDifferenceInDays(new Date());
  const { formatDate, formatTime } = useIntl();

  const tableData: TableData = React.useMemo(() => {
    const makeRows = (customers: models.ICustomer[]) =>
      createTableRows({
        customers,
        t,
        formatDifference,
        formatDate,
        formatTime,
        readNotifications,
      });
    return {
      failures: makeRows(groupedCustomers.ERROR || []),
      maintenance: makeRows(groupedCustomers.MAINTENANCE || []),
      prediction: makeRows(groupedCustomers.PREDICTION || []),
      offline: makeRows(groupedCustomers.OFFLINE || []),
      pending: makeRows(groupedCustomers.PENDING || []),
    };
  }, [
    formatDate,
    formatDifference,
    formatTime,
    groupedCustomers.ERROR,
    groupedCustomers.MAINTENANCE,
    groupedCustomers.OFFLINE,
    groupedCustomers.PENDING,
    groupedCustomers.PREDICTION,
    readNotifications,
    t,
  ]);

  const firstNonemptyTab = findFirstNonemptyTab(tableData);
  const { activeTab, setActiveTab } = useTabs(!isMobile ? firstNonemptyTab : null);

  useEffect(() => {
    if (!isMobile && activeTab === null) {
      return setActiveTab(firstNonemptyTab);
    }
  }, [activeTab, firstNonemptyTab, isMobile, setActiveTab]);

  const firebaseService = useFirebase();
  const [addCustomerPreset, setAddCustomerPreset] = useState<TableRow | null | undefined>(undefined);

  const { tab } = useParams() as { tab: keyof DashboardData };

  const onRetryValidation = React.useCallback((row: TableRow) => {
    setAddCustomerPreset(row);
  }, []);

  const openAddCustomer = React.useCallback(() => {
    setAddCustomerPreset(null);
    firebaseService.logUserAction('addCustomer');
  }, [firebaseService]);

  const closeAddCustomer = () => {
    setAddCustomerPreset(undefined);
  };

  const closeResendConsent = () => {
    setCustomerToResendConsent(null);
  };

  const abortAddCustomer = () => {
    setAddCustomerPreset(undefined);
  };

  const openResendConsentModal = React.useCallback(
    (row: TableRow) => {
      firebaseService.logUserAction('openResendConsent');
      setCustomerToResendConsent(row._meta.customer);
    },
    [firebaseService, setCustomerToResendConsent],
  );

  const tabs = React.useMemo(() => createTabs(t, tableData) ?? [], [tableData, t]);

  const dtcType = React.useMemo(() => {
    const dtcTypes = tabToDtcType(tab);
    return dtcTypes ? [dtcTypes] : undefined;
  }, [tab]);

  const isAllOk = !hasAnyIssue(tableData);
  const navigate = useNavigate();
  const shouldShowAllOk = isAllOk;
  const shouldShowTabs = !isAllOk;
  const shouldShowNotificationBars = !isAllOk;
  const shouldShowMapToggle = !isAllOk;
  const shouldShowAddDeviceButton = !shouldShowNoDevices;

  type KeysType = keyof TableData;

  const selectedCustomerData = useMemo(() => {
    return (Object.keys(tableData) as KeysType[]).map((key) => {
      return tableData[key].find((item) => item._meta.customer.system.boiler.serialNumber === selectedCustomer);
    })[0];
  }, [selectedCustomer, tableData]);

  const customerFullName = selectedCustomerData ? getEndUserFullName(selectedCustomerData._meta.customer) : null;

  const {
    dialogTitle,
    isDialogOpen,
    isRemovingCustomer,
    closeDialog,
    removeCustomer,
    testIdKey,
    cancelButtonText,
    removeButtonText,
    removeCustomerHeaderLabel,
    openDialog,
  } = commonFeatures.removeCustomer.usePresenter({
    customerFullName,
    // INFO: Do we need escape here?? Why gateway serial number is optional?
    gatewaySerialNumber: selectedCustomerData?._meta.customer.system.gateway?.serialNumber ?? selectedCustomer,
    opts: {
      onSuccess: () => {
        closeDialog();
        navigate('/dashboard');
      },
    },
  });

  const openRemoveCustomer = React.useCallback(
    (row: TableRow) => {
      firebaseService.logUserAction('removeCustomer');
      setSelectedCustomer(row._meta.customer.system.boiler.serialNumber);
      openDialog();
    },
    [openDialog, setSelectedCustomer, firebaseService],
  );

  const actions: ItemAction[] = [
    {
      label: t('SG_REMOVE_CUSTOMER_CONFIRMATION'),
      action: openRemoveCustomer,
      disabled: () => false,
    },
    {
      label: t('SG_RESEND_CONSENT_TITLE'),
      action: openResendConsentModal,
      disabled: (record: TableRow) => !enableResendConsentMail(record),
    },
    {
      label: t('SG_RETRY_VALIDATION'),
      action: onRetryValidation,
      disabled: (record: TableRow) => !enableRetryAddCustomer(record),
    },
  ];

  return {
    setViewMode,
    viewMode,
    addCustomerPreset,
    closeAddCustomer,
    closeResendConsent,
    abortAddCustomer,
    shouldShowNotificationBars,
    shouldShowMapToggle,
    shouldShowAddDeviceButton,
    selectedCustomer,
    setSelectedCustomer,
    onRetryValidation,
    activeTab,
    openAddCustomer,
    openResendConsentModal,
    tableData,
    dtcType,
    tabs,
    navigate,
    t,
    setActiveTab,
    shouldShowAllOk,
    shouldShowTabs,
    isMobile,
    dialogTitle,
    isDialogOpen,
    isRemovingCustomer,
    closeDialog,
    removeCustomer,
    testIdKey,
    cancelButtonText,
    removeButtonText,
    removeCustomerHeaderLabel,
    openRemoveCustomer,
    actions,
  };
};
