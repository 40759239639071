import * as qrCodeDiagnostics from '../../apis/qrCodeDiagnostics';
import { Country, DtcTypeName, Language } from '../../../typings/models';
import { TickerItem } from './LiveMonitor.types';
import { Notification } from '../../apis/notifications';
import { statusCodeIcons } from './LiveMonitor.constants';

type DateTimeFormatter = (dt: Date | number | string) => string;

/**
 * New mapper, currently used by QR code screen. Will replace `getTicker`, as soon as
 * the maintenance api system endpoint uses the new systemInfo format.
 */
export const dtcsToMonitorTickerItems = (
  dtcs: qrCodeDiagnostics.Dtc[],
  dateFormatter: DateTimeFormatter,
  timeFormatter: DateTimeFormatter,
  language: Language,
  country: Country,
): TickerItem[] => {
  return dtcs
    .filter((dtc) => dtc.type === 'STATUS')
    .map((dtc) => {
      return {
        icon: statusCodeIcons[dtc.code] || '18801-vg-start01',
        color: dtc.rectified_at ? 'text.secondary' : 'primary',
        date: dateFormatter(dtc.occurred_at),
        time: timeFormatter(dtc.occurred_at),
        description: qrCodeDiagnostics.selectors._getDtcDescriptionForLocale(language, country, dtc)?.title ?? '',
      };
    });
};

export const dtcsToNotifications = (
  dtcs: qrCodeDiagnostics.Dtc[],
  gatewaySerialNumber: string,
  gatewayArticleNumber: string,
  deviceSerialNumber: string,
  deviceArticleNumber: string,
): Notification[] => {
  return dtcs.map((dtc) => {
    return {
      id: dtc.id,
      type: dtc.type as DtcTypeName,
      code: dtc.code,
      rectified: !!dtc.rectified_at,
      occurrenceTimestamp: Math.floor(new Date(dtc.occurred_at).getTime() / 1000),
      rectificationTimestamp: dtc.rectified_at ? Math.floor(new Date(dtc.rectified_at).getTime() / 1000) : undefined,
      gateway: {
        serialNumber: gatewaySerialNumber,
        articleNumber: gatewayArticleNumber,
      },
      device: {
        serialNumber: deviceSerialNumber,
        articleNumber: deviceArticleNumber,
      },
    };
  });
};
