import { Box } from '@mui/material';
import { styled } from '../../../util/styling';

export const LabelContainer = styled(Box)``;

export const ResponsiveBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > span {
    display: none;
  }
  & > span:nth-of-type(1) {
    display: inline-block;
  }
  & > span:nth-of-type(3n) {
    display: inline-block;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    & > span:nth-of-type(2n) {
      display: inline-block;
    }
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    & > span {
      display: inline-block;
    }
  }
`;

export const ScaleContainer = styled(Box)`
  position: relative;
`;

export const ScaleInner = styled('div')<{ shouldHideBorder: boolean }>`
  position: absolute;
  left: 10px;
  border-bottom: ${({ shouldHideBorder }) => (shouldHideBorder ? undefined : '3px grey solid')};
  width: ${(100 / 24) * 23}%;
  height: 20px;
`;

export const ActionsContainer = styled('div')`
  position: absolute;
  right: 10px;
`;

// Keep comment, for the case that the temperature should not be dynamic
// background-color rgb(0, 220, 90);

export const Bar = styled('button')<{
  start: number;
  end: number;
  isClickable: boolean;
  isConsecutive: boolean;
  backgroundColor: string;
}>`
  overflow: hidden;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  position: absolute;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-width: 0;
  border-top-left-radius: ${({ isConsecutive }) => (isConsecutive ? '0px' : '8px')};
  border-top-right-radius: ${({ isConsecutive }) => (isConsecutive ? '0px' : '8px')};
  height: ${({ isConsecutive }) => (isConsecutive ? '16px' : '24px')};
  width: ${({ start, end }) => (100 / 24) * (end / 60) - (100 / 24) * (start / 60)}%;
  left: ${({ start }) => (100 / 24) * (start / 60)}%;
  bottom: ${({ isConsecutive }) => (isConsecutive ? undefined : '-3px')};
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    border-top-left-radius: ${({ isConsecutive }) => (isConsecutive ? '4px' : undefined)};
    border-bottom-left-radius: ${({ isConsecutive }) => (isConsecutive ? '4px' : undefined)};
  }
  &:last-child {
    border-top-right-radius: ${({ isConsecutive }) => (isConsecutive ? '4px' : undefined)};
    border-bottom-right-radius: ${({ isConsecutive }) => (isConsecutive ? '4px' : undefined)};
  }
`;
