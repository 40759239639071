import ChipBase from '@mui/material/Chip';
import { styled } from '../../../util/styling';
import { ChipProps } from './Chip.types';

export const Chip = styled(ChipBase)<ChipProps>`
  opacity: 1 !important;
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.body}
    ${({ disabled, theme }) =>
      disabled
        ? `background-color: ${theme.palette.text.disabled}; >span { color: ${theme.palette.common.white} !important; }`
        : ''};
`;
