import { EnumDataPoint } from '../../useCase/model/dataPoint';
import { OptionViewModel, UseMutation } from './writeDatapointPresentationData';

export const writeEnumDatapointPresenter = ({
  dataPoint,
  useMutation,
}: {
  dataPoint: EnumDataPoint;
  useMutation: UseMutation;
}) => {
  if (!dataPoint.writeParams) {
    return null;
  }

  const { title, writeParams } = dataPoint;
  const selectableOptions: OptionViewModel[] = writeParams.possibleValues.map((option) => ({
    ...option,
    testIdKey: option.value,
  }));

  return {
    type: 'enum' as const,
    initialValue: writeParams.initialValue as string,
    selectableOptions,
    title,
    useMutation,
  };
};
