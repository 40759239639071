import React from 'react';
import { createMigrate, persistStore, persistReducer, Storage } from 'redux-persist';
import { Provider } from 'react-redux';
import { createStore, StoreEnhancer } from 'redux';
import { PersistGate, PersistGateProps } from 'redux-persist/integration/react';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { rootReducer, State } from '../../../store/rootReducer';
import { migrations } from '../../../store/migrations';
import { Country, Language } from '../../../typings/models';
import { initialState as settingsInitialState } from '../../../store/settings/settings.reducer';

interface Props {
  systemLanguage: Language;
  fallbackCountry: Country;
  middlewares: StoreEnhancer;
  storage: Storage;
  onBeforeLift?: PersistGateProps['onBeforeLift'];
}

export const StoreProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  systemLanguage,
  fallbackCountry,
  middlewares,
  onBeforeLift,
  storage,
}) => {
  const { store, persistor } = React.useMemo(() => {
    const persistConfig = {
      key: 'root',
      storage,
      version: 3,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore see https://github.com/rt2zz/redux-persist/issues/1065
      migrate: createMigrate(migrations),
      stateReconciler: autoMergeLevel2,
      whitelist: ['settings', 'tutorial'],
    };

    const persistedReducer = persistReducer<State>(persistConfig, rootReducer);

    const initialState: Partial<State> = {
      settings: { ...settingsInitialState, systemLanguage, country: fallbackCountry },
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore see https://github.com/rt2zz/redux-persist/issues/1169
    const store = createStore(persistedReducer, initialState, middlewares);

    const persistor = persistStore(store);

    return { store, persistor };
  }, [systemLanguage, fallbackCountry, middlewares, storage]);

  return (
    <Provider store={store}>
      <PersistGate onBeforeLift={onBeforeLift} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};
