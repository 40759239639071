import * as React from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Option } from './Option';
import { DeviceAutocompleteOption, DeviceAutocompleteProps } from './DeviceAutocomplete.types';
import { Autocomplete } from '../Autocomplete';
import { useInstanceId, useTranslation } from '@vaillant-professional-ui/component-libs-common';

const filterOptions = createFilterOptions<DeviceAutocompleteOption>({
  stringify: (option: DeviceAutocompleteOption) => `${option.serialNumber} ${option.address} ${option.productName}`,
});

export const DeviceAutocomplete: React.FunctionComponent<DeviceAutocompleteProps> = ({
  options,
  onChange,
  initialValue,
  testIdKey,
  textFieldProps = {},
  ...rest
}) => {
  const [query, setQuery] = React.useState('');
  const t = useTranslation();
  const testId = useInstanceId('deviceautocomplete', testIdKey);

  const handleInputChange = (event: React.ChangeEvent<{}>, serialNumber: string) => {
    setQuery(serialNumber);
    onChange(event, serialNumber);
  };

  const initialOption: DeviceAutocompleteOption | undefined = initialValue
    ? options?.find((option) => option.serialNumber === initialValue)
    : undefined;

  return (
    <Autocomplete
      getOptionLabel={(option) => `${option.serialNumber || ''}`}
      textFieldProps={{
        label: t('VG_DEVICE_AUTOCOMPLETE_PLACEHOLDER'),
        variant: 'standard',
        ...textFieldProps,
      }}
      options={options}
      testIdKey={testId('root').key}
      renderOption={(props, option, { selected }) => (
        <Option
          props={props}
          key={option.serialNumber}
          option={option}
          isSelected={selected}
          query={query}
          testIdKey={testId(option.serialNumber).key}
        />
      )}
      filterOptions={filterOptions}
      value={initialOption}
      onInputChange={handleInputChange}
      selectOnFocus
      clearOnBlur={false}
      {...rest}
    />
  );
};
