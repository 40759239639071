export const colors = {
  primary: '#00917E',
  primaryLight: '#2BA394',
  primaryLighter: '#9DD8D0',
  primaryLightest: '#DBF0ED',
  primaryDark: '#00826F',
  primaryDarker: '#006D5C',
  primaryDarkest: '#005949',

  secondary: '#FF8500',
  secondaryLight: '#FFD19C',
  secondaryLightest: '#FFE7CC',

  success: '#00917E',
  successLight: '#9DD8D0',
  successLightest: '#DBF0ED',

  warning: '#E67E22',
  warningLight: '#FFDD86',
  warningLightest: '#FFEAD4',

  error: '#CC0033',
  errorLight: '#EB99AD',
  errorLightest: '#F9DEE5'
};
