/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon13300VgSystem01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon13300vgsystem01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M442.41 130.49H329a11.34 11.34 0 0 0-11.34 11.34v28.34H295v-28.34a11.34 11.34 0 0 0-11.34-11.34H124.93a11.34 11.34 0 0 0-11.34 11.34v79.37a11.34 11.34 0 0 0 11.34 11.34h68v22.67h-68a11.34 11.34 0 0 0-11.34 11.34v158.74a11.34 11.34 0 0 0 11.34 11.34h158.74A11.34 11.34 0 0 0 295 425.29v-68h22.67v68A11.34 11.34 0 0 0 329 436.63h113.41a11.34 11.34 0 0 0 11.34-11.34V141.83a11.34 11.34 0 0 0-11.34-11.34zm-306.14 22.68h136.06v56.69H136.27zM272.33 414H136.27V277.89h136.06zM295 334.58v-68a11.34 11.34 0 0 0-11.34-11.34h-68v-22.7h68A11.34 11.34 0 0 0 295 221.2v-28.35h22.67v141.73zM431.07 414h-90.71V153.17h90.71z'
      />
    </svg>
  );
};
