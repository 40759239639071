import { AppBar, Toolbar } from '@mui/material';
import { styled } from '@vaillant-professional-ui/component-libs-web';

export const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.common.white};
  font-size: 14px;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

export const StyledToolbar = styled(Toolbar)<{ $isLogoRightAligned: boolean }>`
  padding-left: 4px;
  padding-right: 21px;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  ${({ theme }) => theme.breakpoints.up('lg')} {
    justify-content: ${({ $isLogoRightAligned }) => ($isLogoRightAligned ? 'flex-end' : 'flex-start')};
  }
`;

export const LogoWrapper = styled('div')`
    min-height: 87px;
    padding-left: 0;
    align-items: center;
    display: flex;
    ${({ theme }) => theme.breakpoints.up('lg')} {
      padding-left: 26px;
    },
`;
