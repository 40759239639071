import { DtcType, DtcTypeName, TroubleTypeMappingEnum } from '../typings/models';

interface TroubleCodeLike {
  code: number;
  type: DtcTypeName;
}

type GetTroubleCodeForNotification = (notification: TroubleCodeLike) => string;

export const getTroubleCodeForNotification: GetTroubleCodeForNotification = (notification) => {
  return mergeCode(TroubleTypeMappingEnum[notification.type], notification.code);
};

export const mergeCode: (letter: DtcType, code: number) => string = (letter, code) => `${letter}.${code}`;
