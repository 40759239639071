import React from 'react';
import { Grid as GridBase } from '@mui/material';
import { spacing, palette } from '@mui/system';
import { styled } from '../../../util/styling';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { GridProps } from './Grid.types';

const StyledGrid = styled(GridBase)`
  ${spacing}
  ${palette}
`;

export const Grid: React.FC<React.PropsWithChildren<GridProps>> = ({ children, testIdKey, ...rest }) => {
  const testId = useInstanceId('grid', testIdKey);
  return (
    <StyledGrid {...testId().testIdAttributes} {...rest}>
      {children}
    </StyledGrid>
  );
};
