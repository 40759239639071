import * as React from 'react';
import { Box } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';
import { Icon02301VgPlus01 } from '../../atoms/Icon/Icon02301VgPlus01';
import { Icon02401VgMinus01 } from '../../atoms/Icon/Icon02401VgMinus01';
import { SpinnerControlProps } from './SpinnerControl.types';
import { StyledIconButton } from './SpinnerControl.styles';

export const SpinnerControl: React.FunctionComponent<SpinnerControlProps> = ({
  displayValue,
  testIdKey,
  disablePlus,
  disableMinus,
  onPressMinus,
  onPressPlus,
}) => {
  const testId = useInstanceId('spinnercontrol', testIdKey);

  return (
    <Box {...testId('root').testIdAttributes} display='flex' flexDirection='row' alignItems='center' gap={3}>
      <StyledIconButton
        aria-label='decrement'
        color='primary'
        disabled={disableMinus}
        onClick={() => onPressMinus && onPressMinus()}
        size='large'
        {...testId('decrement').testIdAttributes}
      >
        <Icon02401VgMinus01 color='inherit' />
      </StyledIconButton>
      <Typography whiteSpace='nowrap' variant='h3' {...testId('value').testIdAttributes}>
        {displayValue}
      </Typography>
      <StyledIconButton
        aria-label='increment'
        color='primary'
        disabled={disablePlus}
        onClick={() => onPressPlus()}
        size='large'
        {...testId('increment').testIdAttributes}
      >
        <Icon02301VgPlus01 color='inherit' />
      </StyledIconButton>
    </Box>
  );
};
