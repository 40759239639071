import L, { latLngBounds, LatLngLiteral } from 'leaflet';
import { models } from '@vaillant-professional-ui/pui-frontend-common';

export const markerDefault = new L.Icon({
  iconUrl: require('../assets/images/map-markers/address location_filled.svg'),
  iconRetinaUrl: require('../assets/images/map-markers/address location_filled.svg'),
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(81, 117),
  iconAnchor: [40, 81],
  popupAnchor: [2, -58],
});

export const markerActive = new L.Icon({
  iconUrl: require('../assets/images/map-markers/address location_filled.svg'),
  iconRetinaUrl: require('../assets/images/map-markers/address location_filled.svg'),
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(121.5, 175.5),
  iconAnchor: [60.75, 124],
  popupAnchor: [1, -80],
});

type CreateClusterCustomIcon = (themeName: string) => (cluster: { getChildCount: () => void }) => L.DivIcon;

export const createClusterCustomIcon: CreateClusterCustomIcon = (themeName: string) => (cluster) => {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: `marker-cluster-custom marker-cluster-custom-${themeName}`,
    iconSize: L.point(40, 40, true),
  });
};

export const geolocationToLeafletLocation = (location: models.Geolocation<number>): LatLngLiteral => ({
  lat: location.latitude,
  lng: location.longitude,
});

export const getBounds = (geolocations: models.Geolocation<number>[]): models.MapBounds | null => {
  if (geolocations.length === 0) {
    return null;
  }

  const firstLocation = geolocationToLeafletLocation(geolocations[0]);

  const bounds = latLngBounds([firstLocation, firstLocation]);
  geolocations.forEach((g) => bounds.extend(geolocationToLeafletLocation(g)));
  return {
    northEast: bounds.getNorthEast(),
    southWest: bounds.getSouthWest(),
  };
};

export const getCustomerBounds = (customers: models.ICustomer[]): models.MapBounds | null => {
  const customersWithLocation = customers.filter(
    (customer) =>
      Number.isFinite(customer.customer?.address?.geoLocation?.longitude) &&
      Number.isFinite(customer.customer?.address?.geoLocation?.latitude),
  );
  const locations: models.Geolocation<number>[] = (customersWithLocation.map(
    (customer) => customer.customer?.address?.geoLocation,
  ) ?? []) as models.Geolocation<number>[];
  return getBounds(locations);
};
