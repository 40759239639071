import { features } from '@vaillant-professional-ui/pui-frontend-common';
import { ZoomChart } from './ZoomChart';
import { useBoilerSerialNumberFromPath } from '../../../components/Routes';
import { useDateRangePersistor } from '../dateRangePersistor';

interface ZoomChartContainerProps {
  url: string;
  zoomRange: features.historyCharts.historyChartsCard.DateTimeRange;
  setZoomRange: (zoomRange: features.historyCharts.historyChartsCard.DateTimeRange) => void;
}

export const ZoomChartContainer: React.FC<ZoomChartContainerProps> = ({ url, zoomRange, setZoomRange }) => {
  const boilerSerialNumber = useBoilerSerialNumberFromPath();
  const { start, end } = useDateRangePersistor();

  const props = features.historyCharts.historyChartsCard.usePresenter({
    url,
    start,
    end,
    boilerSerialNumber,
    zoomRange,
    setZoomRange,
  });

  return <ZoomChart {...props} />;
};
