import * as React from 'react';
import { format } from 'date-fns';
import { getDateFormat } from './DateRangePicker.util';
import { ButtonProps, DatePickerInputButton } from '@vaillant-professional-ui/component-libs-web';

interface InputProps {
  startDate: Date;
  endDate?: Date | null;
  locale: Locale;
  isOpen: () => boolean;
  onClick?: () => void;
  testIdKey?: string;
}

export const Input = React.forwardRef<React.FC<ButtonProps>, InputProps>(function Input(
  { onClick, startDate, endDate, locale, isOpen, testIdKey },
  ref,
) {
  const formatDate = (date: Date) => format(date, getDateFormat(), { locale });

  const formatted = `${formatDate(startDate)}${endDate ? ` – ${formatDate(endDate)}` : ''}`;

  return (
    <DatePickerInputButton ref={ref} variant='text' onClick={isOpen() ? undefined : onClick} testIdKey={testIdKey}>
      {formatted}
    </DatePickerInputButton>
  );
});
