import { config, selectors } from '@vaillant-professional-ui/pui-frontend-common';
import React from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { createClusterCustomIcon } from '../../lib/maps';
import { useFirebase } from '../../providers/firebase';

import { useMapContext } from './MapContext';
import { Marker } from './Marker';

export const Markers: React.FC<React.PropsWithChildren<unknown>> = React.memo<{ children?: React.ReactNode }>(
  function Markers() {
    const { customers, dtcTypes, highlightedCustomer, setHighlightedCustomer, selectedCustomer, setSelectedCustomer } =
      useMapContext();
    const { distribution } = config.useAppConfig();
    const firebaseService = useFirebase();

    return (
      <MarkerClusterGroup
        showCoverageOnHover={false}
        disableClusteringAtZoom={14}
        iconCreateFunction={createClusterCustomIcon(distribution.theme)}
        onClick={(cluster) =>
          firebaseService.logUserAction('dashboardMapClusterClick', { latlng: { ...cluster.latlng } })
        }
      >
        {customers.map((customer) => {
          const position = selectors.customer.getEndUserPosition(customer);
          const boilerSerialNumber = selectors.customer.getBoilerSerialNumber(customer);
          return position ? (
            <Marker
              key={`marker-${boilerSerialNumber}`}
              dtcTypes={dtcTypes}
              customers={customers}
              boilerSerialNumber={boilerSerialNumber}
              position={position}
              isSelected={selectedCustomer === boilerSerialNumber}
              isHighlighted={highlightedCustomer === boilerSerialNumber}
              setHighlightedCustomer={setHighlightedCustomer}
              setSelectedCustomer={setSelectedCustomer}
            />
          ) : null;
        })}
      </MarkerClusterGroup>
    );
  },
);
