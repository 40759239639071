import { useCallback } from 'react';
import { DemoServerParams } from './server.types';

type HookParams = Omit<DemoServerParams, 'callback'>;

let server: unknown = null;

export const useDemoServer = (params: HookParams) => {
  const setup = useCallback(() => {
    const startServer = () => {
      import('./server').then((mod) => {
        // @ts-ignore
        server = mod.startServer(params);
      });
    };
    if (!server) {
      void startServer();
    }
  }, [params]);

  const shutDown = useCallback(() => {
    if (server) {
      // @ts-ignore
      server.shutdown();
      server = null;
    }
  }, []);

  return { setup, shutDown, isActive: !!server };
};
