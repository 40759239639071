import React from 'react';
import { RemoveCustomerDialogContainerProps } from './RemoveCustomer.types';
import { Dialog } from '@vaillant-professional-ui/component-libs-web';
import RemoveCustomerDialogContent from './RemoveCustomer';

const RemoveCustomerDialogContainer: React.FC<React.PropsWithChildren<RemoveCustomerDialogContainerProps>> = (
  props,
) => {
  const {
    dialogTitle,
    isOpen,
    testIdKey,
    removeCustomerHeaderLabel,
    cancelButtonText,
    removeButtonText,
    removeCustomer,
    isRemovingCustomer,
    closeDialog,
  } = props;

  return (
    <Dialog testIdKey={testIdKey} title={dialogTitle} open={isOpen} onClose={closeDialog}>
      <RemoveCustomerDialogContent
        removeCustomerHeaderLabel={removeCustomerHeaderLabel}
        cancelButtonText={cancelButtonText}
        removeButtonText={removeButtonText}
        closeDialog={closeDialog}
        removeCustomer={removeCustomer}
        isRemovingCustomer={isRemovingCustomer}
      />
    </Dialog>
  );
};

export default RemoveCustomerDialogContainer;
