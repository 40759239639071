import { IconButton } from '@mui/material';
import { Badge, styled } from '@vaillant-professional-ui/component-libs-web';

export const BadgeStyled = styled(Badge)`
  .MuiBadge-badge {
    ${({ theme }) => `
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-size: ${theme.typography.smallCopy.fontSize}px;
      line-height: ${theme.typography.smallCopy.lineHeight};
    `}
  }
`;

export const IconButtonStyled = styled(IconButton)`
  width: ${({ theme }) => theme.spacing(12)};
  height: ${({ theme }) => theme.spacing(12)};
  padding: 0;
`;
