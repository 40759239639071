import { styled } from '../../../util/styling';

const RadioIcon = styled('span')`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-sizing: border-box;
`;

export const RadioIconChecked = styled(RadioIcon)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const RadioIconUnChecked = styled(RadioIcon)`
  border: 1px solid ${({ theme }) => theme.palette.grey['400']};
`;
