import { AxiosResponse } from 'axios';
import { DeviceDetailsResponseTli, TliCircuit } from '../../../apis/maintenance/v2';
import {
  HeatCurveVariables,
  MinFlowTargetTemperatureVariables,
  WithCircuitIndex,
} from '../../../apis/settings/settings.types';
import { UpdateFnc } from '../../../hooks';
import { patchTliResponseV2, updateIndexedObjectArray } from './generalUtil';
import { MeasuredValue } from '../../../apis/maintenance/v2/maintenance.types';

const getTliCircuits = (tliResponse: AxiosResponse<DeviceDetailsResponseTli>): TliCircuit[] | undefined => {
  return tliResponse.data?.circuits;
};

export const updateTliCircuits = <T extends WithCircuitIndex>(
  updateData: T,
  previousValue: AxiosResponse<DeviceDetailsResponseTli>,
  patchFnc: (previousCircuits: TliCircuit) => TliCircuit,
) => {
  const circuits = getTliCircuits(previousValue);
  if (!circuits) {
    return previousValue;
  }

  const newCircuits = updateIndexedObjectArray<TliCircuit>(circuits, updateData.circuitIndex, patchFnc);
  return patchTliResponseV2(previousValue, { circuits: newCircuits });
};

export const patchCircuitFunctionFactory =
  (newValue: number | string | boolean, key: string) =>
  (circuit: TliCircuit): TliCircuit => {
    const value = circuit[key as keyof TliCircuit] as MeasuredValue;

    if (!value) {
      return circuit;
    }

    return {
      ...circuit,
      [key]: { ...value, value: newValue },
    };
  };

export const updateMinFlowTemperature: UpdateFnc<DeviceDetailsResponseTli, MinFlowTargetTemperatureVariables> = (
  updateData,
  previousValue,
) => {
  const patchFunction = patchCircuitFunctionFactory(updateData.minFlowTargetTemperature, 'min_flow_target_temperature');
  return updateTliCircuits<MinFlowTargetTemperatureVariables>(updateData, previousValue, patchFunction);
};

export const updateHeatCurve: UpdateFnc<DeviceDetailsResponseTli, HeatCurveVariables> = (updateData, previousValue) => {
  const patchFunction = patchCircuitFunctionFactory(updateData.heatCurve, 'heat_curve');
  return updateTliCircuits<HeatCurveVariables>(updateData, previousValue, patchFunction);
};
