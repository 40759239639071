/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon43501VgCheckConfirm201: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon43501vgcheckconfirm201', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M354.36 198.36A11.34 11.34 0 0 0 339 203l-74.51 138.35-52.05-61.81a11.34 11.34 0 0 0-17.35 14.61l62.8 74.57a11.33 11.33 0 0 0 18.66-1.93L359 213.72a11.34 11.34 0 0 0-4.64-15.36z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.46 102.05c-100 0-181.41 81.38-181.41 181.41s81.38 181.42 181.41 181.42 181.42-81.38 181.42-181.42-81.38-181.41-181.42-181.41zm0 340.15c-87.53 0-158.74-71.21-158.74-158.74s71.21-158.74 158.74-158.74 158.75 71.21 158.75 158.74S371 442.2 283.46 442.2z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
