import { TranslationFnc } from '../../../../../typings/models';
import { getFractionDigitsCount, printableUnit } from '../../../../../api/apis/maintenance/v2';
import { DataPoint, NumericDataPoint, EnumDataPoint, BooleanDataPoint } from '../../useCase/model/dataPoint';
import { writeEnumDatapointPresenter } from './writeEnumDatapointPresenter';
import { UseMutation, WriteDatapointPresentationData } from './writeDatapointPresentationData';

export const writeDatapointPresenter = (
  dataPoint: DataPoint | null,
  activeMutation: string | null,
  createUseMutation: (dataPoint: NumericDataPoint | EnumDataPoint | BooleanDataPoint) => UseMutation,
  t: TranslationFnc,
): WriteDatapointPresentationData | null => {
  if (!activeMutation || !dataPoint) {
    return null;
  }

  if (dataPoint.type === 'numeric') {
    const writeParams = dataPoint.writeParams;
    if (!writeParams) {
      return null;
    }
    return {
      type: 'numeric',
      initialValue: writeParams.initialValue,
      minimum: writeParams.minimum,
      maximum: writeParams.maximum,
      unit: printableUnit(dataPoint.measuredValue.unit),
      stepSize: writeParams?.stepSize,
      title: dataPoint.title,
      digitsAfterPeriod: getFractionDigitsCount(writeParams.initialValue, writeParams),
      testIdKey: `dialog-data.${dataPoint.id}`,
      buttonLabelCancel: t('SG_CANCEL'),
      buttonLabelSave: t('SG_SAVE'),
      useMutation: createUseMutation(dataPoint),
    };
  }

  if (dataPoint.type === 'boolean') {
    const writeParams = dataPoint.writeParams;
    if (!writeParams) {
      return null;
    }
    return {
      type: 'boolean',
      initialValue: writeParams.initialValue,
      title: dataPoint.title,
      useMutation: createUseMutation(dataPoint),
    };
  }

  if (dataPoint.type === 'enum') {
    return writeEnumDatapointPresenter({ dataPoint, useMutation: createUseMutation(dataPoint) });
  }

  return null;
};
