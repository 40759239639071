import isNil from 'lodash/isNil';
import { QueryKey } from '../../hooks';
import {
  useOptimisticPatchDhwSchedule,
  useOptimisticPatchHeatingSchedule,
} from '../TliReadWrite/useOptimisticUpdateAgainstSchedulesApi';
import {
  TliDhwTimeWindow,
  TliHeatingTimeWindow,
  TliScheduleDay,
  TliSchedulerPresentationData,
  TliSchedulerZonePresentationData,
} from './TliScheduler.types';
import { tliScheduleDayToDHWSchedulePayload, tliScheduleDayToHeatingSchedulePayload } from './TliScheduler.write.util';

export const useMutationHandlers = ({
  gatewaySerialNumber,
  controlSerialNumber,
  queryKey,
  zoneIndex,
  presentationData,
}: {
  gatewaySerialNumber: string;
  controlSerialNumber: string;
  queryKey: QueryKey;
  zoneIndex?: number;
  presentationData: TliSchedulerPresentationData<TliSchedulerZonePresentationData> | null;
}) => {
  const { mutate: mutateHeating } = useOptimisticPatchHeatingSchedule(gatewaySerialNumber ?? '', queryKey);
  const { mutate: mutateDhw } = useOptimisticPatchDhwSchedule(gatewaySerialNumber, queryKey);

  return {
    patchHeatingSchedule: (patchData: TliScheduleDay<TliHeatingTimeWindow>[]) => {
      if (isNil(presentationData) || isNil(zoneIndex)) {
        throw new Error('Can not patch heating schedule without presentation data and zone index');
      }
      const payload = tliScheduleDayToHeatingSchedulePayload(
        controlSerialNumber,
        patchData,
        presentationData,
        zoneIndex,
      );
      mutateHeating(payload);
    },
    patchDhwSchedule: (patchData: TliScheduleDay<TliDhwTimeWindow>[]) => {
      const payload = tliScheduleDayToDHWSchedulePayload(controlSerialNumber, patchData);
      mutateDhw(payload);
    },
  };
};
