import React from 'react';
import { Vrc700SchedulerPage } from './Vrc700SchedulerPage';
import {
  Vrc700SchedulerPageProps,
  Vrc700SchedulerPagePropsParamsType,
  Vrc700SchedulerPageContainerProps,
} from './Vrc700SchedulerPage.types';
import { useValidatedParams } from '../../../lib/hooks';
import { features } from '@vaillant-professional-ui/pui-frontend-common';

import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { urlParamsSchema } from './Vrc700SchedulerPage.util';

export const Vrc700SchedulerPageContainer: React.FC<Vrc700SchedulerPageContainerProps> = () => {
  const { deviceId, systemId, boilerSerialNumber, index, scheduleType } =
    useValidatedParams<Vrc700SchedulerPagePropsParamsType>(urlParamsSchema);

  const { error, isLoading, schedule, fullName, backlinkPath, testId, maxSlotsPerDay } =
    features.schedules.vrc700.useVrc700Presenter({
      systemId,
      deviceId,
      boilerSerialNumber,
      index,
      scheduleType,
    });

  const schedulePageProps: Vrc700SchedulerPageProps = {
    schedule,
    maxSlotsPerDay,
    fullName: fullName ?? undefined,
    scheduleType,
    backlinkPath,
    testId,
  };

  return (
    <LoadingErrorWrapper error={error} isPending={isLoading} showLoadingSpinner={true}>
      <Vrc700SchedulerPage {...schedulePageProps}></Vrc700SchedulerPage>
    </LoadingErrorWrapper>
  );
};
