import { FormControl, MenuItem, SelectProps as SelectPropsBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import React from 'react';
import { Typography } from '../Typography';
import {
  Icon02501VgDown01Styled,
  InputLabelStyled,
  OptionIconStyled,
  OptionLabelStyled,
  SelectOptionContentStyled,
  SelectStyled,
} from './Select.styles';
import { SelectProps } from './Select.types';
import { sizeToFontMap, useLabelId } from './Select.util';

export const Select: React.FC<React.PropsWithChildren<SelectProps>> = (props) => {
  const { formControlProps = {}, options, label, variant, errors = [], inputProps, value } = props;
  const { initialValue, onChange, testIdKey, size = 'normal', ...rest } = props;

  const theme = useTheme();
  const labelId = useLabelId(label);
  const testId = useInstanceId('select', testIdKey);
  const [cachedValue, setCachedValue] = React.useState<string | number | undefined | unknown>(initialValue ?? '');

  const handleChange: SelectPropsBase['onChange'] = (e, child) => {
    setCachedValue(e.target?.value);
    !!onChange && onChange(e, child);
  };

  const actualValue = value !== undefined ? value : cachedValue;
  const iconColor = variant === 'outlined' ? theme.palette.primary.main : theme.palette.common.white;
  const labelVariant = sizeToFontMap[size] as 'h3' | 'body';

  const Icon = React.memo(({ ...iconProps }) => (
    <Icon02501VgDown01Styled {...iconProps} $size={size} color={iconColor} />
  ));

  return (
    <FormControl {...formControlProps}>
      {!!label && !actualValue && (
        <InputLabelStyled
          id={labelId}
          variant={variant}
          $labelVariant={labelVariant}
          {...testId('label').testIdAttributes}
        >
          {label}
        </InputLabelStyled>
      )}
      <SelectStyled
        labelId={label ? labelId : undefined}
        IconComponent={Icon}
        $labelVariant={labelVariant}
        inputProps={{ ...(inputProps || {}), ...testId('root').testIdAttributes }}
        MenuProps={{
          marginThreshold: 0,
        }}
        {...rest}
        value={actualValue}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            {...testId(`menu-item-${option.value}`).testIdAttributes}
          >
            <SelectOptionContentStyled>
              {option.icon && <OptionIconStyled>{option.icon}</OptionIconStyled>}
              <OptionLabelStyled $labelVariant={labelVariant} $hasIcon={!!option.icon}>
                {option.label}
              </OptionLabelStyled>
            </SelectOptionContentStyled>
          </MenuItem>
        ))}
      </SelectStyled>
      {errors.length > 0 && (
        <Typography variant='caption' color='error' style={{ marginTop: 4 }} testIdKey={testId('error').key}>
          {errors[0]}
        </Typography>
      )}
    </FormControl>
  );
};
