/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon13000VgElectric01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon13000vgelectric01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M300.68 425.29c-28 0-45.36-23.54-45.36-45.35v-45.36a68 68 0 0 0 68-68v-56.72a22.67 22.67 0 0 0-22.67-22.68H295v-56.69a11.34 11.34 0 1 0-22.68 0v56.69h-56.68v-56.69a11.34 11.34 0 1 0-22.68 0v56.69h-5.67a22.68 22.68 0 0 0-22.68 22.68v56.69a68 68 0 0 0 68 68v45.36c0 32.72 26 68 68 68h96.37v-22.62zM187.29 266.55v-56.69h113.39v56.69a45.41 45.41 0 0 1-45.36 45.36h-22.67a45.41 45.41 0 0 1-45.36-45.36z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
