import React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { Button, ModalDrawer, Radio, Typography } from '@vaillant-professional-ui/component-libs-web';

interface WriteEnumDatapointDrawerContentProps {
  title: string;
  subtitle: string;
  selectCaption: string;
  formControlLabels: Omit<FormControlLabelProps, 'control'>[];
  selectedMode: string;
  onChangeSelectedMode: RadioGroupProps['onChange'];
  onClose: () => void;
  isSaveButtonDisabled: boolean;
  handleSubmit: () => void;
}

export const WriteEnumDatapointDrawerContent: React.FC<WriteEnumDatapointDrawerContentProps> = ({
  title,
  subtitle,
  selectCaption,
  formControlLabels,
  selectedMode,
  onChangeSelectedMode,
  onClose,
  isSaveButtonDisabled,
  handleSubmit,
}) => {
  return (
    <>
      <ModalDrawer.Title>{title}</ModalDrawer.Title>
      <ModalDrawer.Content>
        <Box display='flex' flexDirection='column' gap={8}>
          <Typography>{subtitle}</Typography>
          <FormControl>
            <FormLabel focused={false}>
              <Typography variant='caption'>{selectCaption}</Typography>
            </FormLabel>
            <RadioGroup value={selectedMode} onChange={onChangeSelectedMode}>
              {formControlLabels.map((formControlLabel) => (
                <FormControlLabel key={formControlLabel.value as string} {...formControlLabel} control={<Radio />} />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </ModalDrawer.Content>
      <ModalDrawer.Footer>
        <Button variant='outlined' onClick={onClose} testIdKey='close' i18nKey='SG_CLOSE' />
        <Button
          variant='contained'
          onClick={handleSubmit}
          testIdKey='save'
          disabled={isSaveButtonDisabled}
          i18nKey='SG_SAVE'
        />
      </ModalDrawer.Footer>
    </>
  );
};
