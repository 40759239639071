import React from 'react';

import { Box, Stack } from '@mui/material';

import { Card, CardContent, CardHeader, LoadingErrorPanel } from '@vaillant-professional-ui/component-libs-web';

import { features } from '@vaillant-professional-ui/pui-frontend-common';

import { ZoomChartSkeleton } from '../ZoomChart/ZoomChart.skeleton';
import { ZoomChartContainer } from '../ZoomChart/ZoomChart.container';

export type HistoryChartsProps = features.historyCharts.historyChartsList.CardsViewData;

export const HistoryCharts: React.FC<HistoryChartsProps> = ({
  cards,
  isLoading,
  error,
  refetch,
  zoomRange,
  setZoomRange,
  hasNoData,
  noDataTitle,
  noDataDescription,
}) => {
  if (isLoading) {
    return (
      <Stack direction='column'>
        {[...Array(2).keys()].map((_, index) => {
          return (
            <Box mb={4} key={index}>
              <Card>
                <CardContent>
                  <ZoomChartSkeleton />
                </CardContent>
              </Card>
            </Box>
          );
        })}
      </Stack>
    );
  }

  if (error) {
    return (
      <Box mb={4} display='flex' alignItems='center' justifyContent='center' flexGrow={1}>
        <LoadingErrorPanel onRetry={void refetch} />
      </Box>
    );
  }

  if (hasNoData) {
    return (
      <Box mb={4} display='flex' alignItems='center' justifyContent='center' flexGrow={1}>
        <LoadingErrorPanel title={noDataTitle} text={noDataDescription} />
      </Box>
    );
  }

  return (
    <Stack direction='column'>
      {cards?.map(({ id, title, url }) => {
        return (
          <Box mb={4} key={id}>
            <Card>
              <CardHeader title={title} testIdKey={`zoom-chart-${id}`} />
              <CardContent>
                <ZoomChartContainer url={url} zoomRange={zoomRange} setZoomRange={setZoomRange} />
              </CardContent>
            </Card>
          </Box>
        );
      })}
    </Stack>
  );
};
