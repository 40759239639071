import { api, selectors } from '@vaillant-professional-ui/pui-frontend-common';
import React from 'react';
import { useFirebase } from '../../providers/firebase';

import { ResendConsent } from './ResendConsent';
import { ResendConsentContainerProps } from './ResendConsent.types';

export const ResendConsentContainer: React.FC<React.PropsWithChildren<ResendConsentContainerProps>> = (props) => {
  const firebaseService = useFirebase();
  const boilerSerialNumber = selectors.customer.getBoilerSerialNumber(props.customer);
  const { mutate: resendConsent } = api.management.useResendConsent();

  const handleResendConsent = () => {
    resendConsent(boilerSerialNumber);
    firebaseService.logUserAction('resendMail');
    props.onClose(true);
  };

  return <ResendConsent {...props} resendConsent={handleResendConsent} />;
};
