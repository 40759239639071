/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon00800VgVentilation01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon00800vgventilation01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M312 227.39V85.89a11.34 11.34 0 0 0-5.51-9.73l-34-20.41a11.34 11.34 0 0 0-17.17 9.73v161.91a68 68 0 0 1 56.7 0zm179.62 149.25-140.23-81a68 68 0 0 1-28.39 49.1l122.6 70.79a11.35 11.35 0 0 0 11.17.1l34.68-19.26a11.33 11.33 0 0 0 .17-19.73zM216 295.66 93.36 366.43a11.36 11.36 0 0 0-5.67 9.63L87 415.73a11.34 11.34 0 0 0 17 10l140.31-81A68 68 0 0 1 216 295.66zm67.67-51.78A45.35 45.35 0 1 0 329 289.23a45.35 45.35 0 0 0-45.33-45.35z'
      />
    </svg>
  );
};
