import React from 'react';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { NotificationList, ModalDrawer, Button } from '@vaillant-professional-ui/component-libs-web';
import { useFirebase } from '../../providers/firebase';
import { NotificationsMenuProps } from './NotificationsMenu.types';
import NotificationsButton from './NotificationsMenuButton';
import { Box } from '@mui/system';

export const NotificationsMenu: React.FC<React.PropsWithChildren<NotificationsMenuProps>> = ({
  countUnreadNotifications,
  groupedNotifications,
  isDropdownOpen,
  notifications,
  onSetIsDropdownOpen,
  onSelectNotification,
}) => {
  const firebaseService = useFirebase();
  const t = hooks.useTranslation();

  return (
    <>
      <NotificationsButton
        numberOfUnreadNotifications={countUnreadNotifications}
        onClick={() => {
          !isDropdownOpen && firebaseService.logUserAction('notification');
          if (notifications.length > 0) {
            onSetIsDropdownOpen(true);
          }
        }}
      />
      <ModalDrawer
        open={isDropdownOpen}
        onClose={() => onSetIsDropdownOpen(false)}
        anchor='right'
        testIdKey='notifications'
      >
        <ModalDrawer.Title>{t('SG_SETTINGS_NOTIFICATION')}</ModalDrawer.Title>
        <ModalDrawer.Content>
          <Box mt={6}>
            <NotificationList
              testIdKey='default'
              data={groupedNotifications}
              onClickNotification={onSelectNotification}
            />
          </Box>
        </ModalDrawer.Content>
        <ModalDrawer.Footer>
          <Button variant={'outlined'} onClick={() => onSetIsDropdownOpen(false)} testIdKey='close'>
            {t('VG_AUTOCOMPLETE_CLOSE')}
          </Button>
        </ModalDrawer.Footer>
      </ModalDrawer>
    </>
  );
};
