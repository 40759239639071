/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon00300VgDomesticHotWater01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon00300vgdomestichotwater01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='m407.79 208.54 20.09-5.85a10.43 10.43 0 0 0 6.85-13.69 152.67 152.67 0 0 0-50.08-67.34 149.76 149.76 0 0 0-91.53-30.86c-83.37 0-151.18 67.83-151.18 151.19v211.65h41.7V242a109.49 109.49 0 0 1 211.53-39.68 10.42 10.42 0 0 0 12.62 6.22zm35.08 72.76-23.14-47.66-23.13 47.66a25.51 25.51 0 1 0 46.27 0z'
      />
    </svg>
  );
};
