import React from 'react';

import { Box } from '@mui/material';
import { Card, CardContent } from '@vaillant-professional-ui/component-libs-web';
import sortBy from 'lodash/sortBy';
import { useIntl } from 'react-intl';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryStack, VictoryVoronoiContainer } from 'victory';
import { useBreakPoint } from '../../../Utils/breakpoint';
import { ChartLegend } from '../../ChartLegend';
import { EmfChartTitleCard } from '../../EmfChartTitleCard';
import { BarChartTooltip } from '../BarChartTooltip/BarChartTooltip';
import { useCalculateCornerRadius, useChartComponentsProps, useTickFormat } from './EmfChart.hooks';
import { useStyles } from './EmfChart.styles';
import { EmfChartProps, EmfDataPoint } from './EmfChart.types';
import { BarDisplayOrder, getDateRangePeriodConfig } from './EmfChart.util';

export const EmfChart: React.FC<EmfChartProps> = ({
  buckets,
  dateRangePeriod,
  emfCategory,
  totalEnergyValue,
  maxDomainY = 0,
  isLoading,
}) => {
  const styles = useStyles();
  const breakPoint = useBreakPoint();
  const { formatNumber } = useIntl();
  const [isTooltipShown, setIsTooltipShown] = React.useState<boolean>(false);

  const { barsProps, legendProps, dayCount, formatTitle } = useChartComponentsProps(buckets, styles, dateRangePeriod);
  const { domain, domainPadding, dateFormat, tickFrequency, victoryLabelY } = getDateRangePeriodConfig(
    dateRangePeriod,
    breakPoint,
    dayCount,
  );

  const tickFormat = useTickFormat({ dateFormat, tickFrequency });
  const calculateCornerRadius = useCalculateCornerRadius(barsProps);

  const showTooltip = () => setIsTooltipShown(true);
  const hideTooltip = () => setIsTooltipShown(false);

  const [boundingRect, setBoundingRect] = React.useState({ width: 0, height: 0 });

  const graphRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <Card>
      <CardContent style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Box display='flex' gap={4} flexDirection='column' flex={1} minHeight={600} ref={graphRef}>
          <Box pb={3} px={6}>
            <EmfChartTitleCard emfCategory={emfCategory} totalValue={totalEnergyValue} />
          </Box>
          <VictoryChart
            width={boundingRect.width}
            height={boundingRect.height}
            style={{ parent: { overflow: 'visible' } }}
            animate={false}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiDimension='x'
                voronoiPadding={0}
                style={{ padding: 0 }}
                mouseFollowTooltips
                labels={() => (isTooltipShown ? ' ' : '')}
                labelComponent={
                  <BarChartTooltip<EmfDataPoint>
                    domain={{ y: [domain.y[0], Math.max(domain.y[1], maxDomainY)] }}
                    titleRenderer={formatTitle}
                  />
                }
              />
            }
            domain={{ y: [domain.y[0], Math.max(domain.y[1], maxDomainY)], x: domain.x }}
            domainPadding={domainPadding}
            singleQuadrantDomainPadding={{ x: false }}
          >
            <VictoryAxis
              fixLabelOverlap
              tickFormat={tickFormat}
              style={{
                axis: styles.grid,
                tickLabels: styles.tickLabelsX,
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(...args) => formatNumber(args[0], { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
              tickLabelComponent={<VictoryLabel textAnchor='start' dx={victoryLabelY.dx} dy={victoryLabelY.dy} />}
              style={{
                axis: styles.axis,
                grid: styles.grid,
                tickLabels: styles.tickLabelsY,
              }}
            />
            <VictoryStack>
              {sortBy(barsProps, (barProps) => BarDisplayOrder[barProps.name]).map(({ name, ...rest }) => {
                return (
                  <VictoryBar
                    key={`${name}-${rest.x}-${rest.y}`}
                    {...rest}
                    events={[
                      {
                        target: 'data',
                        eventHandlers: {
                          onMouseOver: showTooltip,
                          onMouseOut: hideTooltip,
                          onTouchMove: showTooltip,
                        },
                      },
                    ]}
                    cornerRadius={{
                      top: calculateCornerRadius,
                    }}
                  />
                );
              })}
            </VictoryStack>
          </VictoryChart>
          <Box display='flex' flex={1} justifyContent='flex-end' px={[6, 8]}>
            <ChartLegend data={legendProps} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
