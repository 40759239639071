import React from 'react';

import {
  createContainer,
  VictoryArea,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryCursorContainerProps,
  VictoryZoomContainerProps,
} from 'victory';

import { Box, useMediaQuery } from '@mui/material';

import { useTheme } from '@vaillant-professional-ui/component-libs-web';
import { features, hooks } from '@vaillant-professional-ui/pui-frontend-common';

import { CursorChartTooltip } from '../../../components/organisms/CursorChartTooltip';
import { useElementSize } from '../../../lib/hooks';
import { HandleComponent } from './HandleComponent';
import { TimeSeriesGraphs } from './TimeSeriesGraphs';
import { Container, getThemeForVictory } from './ZoomChart.styles';
import { ZoomChartProps } from './ZoomChart.types';
import { ZoomChartSkeleton } from './ZoomChart.skeleton';
import { Legend } from '../Legend';
import { Categories } from './Categories';

const VictoryZoomCursorContainer = createContainer<VictoryZoomContainerProps, VictoryCursorContainerProps>(
  'zoom',
  'cursor',
);

export const ZoomChart: React.FC<ZoomChartProps> = ({ dataProps, containerProps, isLoading, isFetching }) => {
  const {
    setZoomRange,
    xDomain,
    onToggleTimeSeries,
    isTimeSeriesEnabled,
    maxTimeSeries,
    selectedTimeSeries,
    freeSlotsAllowed,
  } = containerProps;

  const { ref: wrapperRef, width: wrapperWidth } = useElementSize();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const df = hooks.useDateFormatter();

  const handleZoom: VictoryZoomContainerProps['onZoomDomainChange'] = (newDomain) => {
    if (newDomain?.x) {
      setZoomRange(newDomain.x as features.historyCharts.historyChartsCard.DateTimeRange);
    }
  };

  const tickLabelsConfig = { fill: '#919191', fontFamily: theme.typography.fontFamily, fontSize: '12px' };

  if (isLoading || !dataProps) {
    return <ZoomChartSkeleton />;
  }

  const { categories, lines: timeSeriesData, yDomain, zoomDomain, getTooltipValues } = dataProps;
  const domain = { x: xDomain ?? undefined, y: yDomain ?? undefined };

  return (
    <>
      <Box display='flex' flexDirection='column' gap={4}>
        <Categories
          categories={categories}
          freeSlotsAllowed={freeSlotsAllowed}
          isTimeSeriesEnabled={isTimeSeriesEnabled}
          onToggleTimeSeries={onToggleTimeSeries}
          maxTimeSeries={maxTimeSeries}
        />
      </Box>
      <Container $isFetching={isFetching} mx={-2} p={0} ref={wrapperRef}>
        <VictoryChart
          theme={getThemeForVictory(theme)}
          width={wrapperWidth}
          scale={{ x: 'time' }}
          domain={domain}
          padding={{ left: 32, right: 26, top: 24, bottom: 24 }}
          containerComponent={
            <VictoryZoomCursorContainer
              name='zoom-cursor'
              allowPan={false}
              cursorDimension='x'
              responsive={false}
              zoomDimension='x'
              zoomDomain={zoomDomain}
              onZoomDomainChange={handleZoom}
              cursorLabel={() => ' '}
              cursorLabelComponent={
                <CursorChartTooltip
                  width={wrapperWidth}
                  titleRenderer={(titleData) =>
                    (titleData && `${df(titleData.timeRange[0])} - ${df(titleData.timeRange[1])}`) ?? ''
                  }
                  getTooltipValues={getTooltipValues}
                />
              }
            />
          }
        >
          <VictoryAxis
            offsetY={24}
            orientation='bottom'
            style={{
              axis: { stroke: 'lightgray' },
              grid: {
                stroke: 'lightgray',
              },
              tickLabels: tickLabelsConfig,
            }}
          />
          <VictoryAxis
            dependentAxis={true}
            style={{
              axis: { stroke: 'lightgray' },
              grid: {
                stroke: 'lightgray',
              },
              tickLabels: tickLabelsConfig,
            }}
          />
          {timeSeriesData.map((line) => (
            <TimeSeriesGraphs key={line.id} line={line} />
          ))}
        </VictoryChart>

        {!isMobile && (
          <VictoryChart
            height={150}
            width={wrapperWidth}
            scale={{ x: 'time' }}
            domain={domain}
            padding={{ left: 32, right: 26, top: 48, bottom: 48 }}
            containerComponent={
              <VictoryBrushContainer
                brushStyle={{ stroke: 'transparent', fill: '#9BC2A5', fillOpacity: 0.5 }}
                // @ts-ignore this is fine. Victory injects the missing props.
                handleComponent={<HandleComponent />}
                handleWidth={13}
                name='brush-container'
                responsive={false}
                brushDimension='x'
                brushDomain={zoomDomain}
                onBrushDomainChangeEnd={handleZoom}
              />
            }
          >
            {timeSeriesData.map((line) => (
              <VictoryArea
                groupComponent={<g />}
                interpolation='natural'
                key={line.name}
                style={{
                  data: { stroke: '#ddd', fill: '#ddd', strokeWidth: 1, opacity: 0.5 },
                  parent: { border: '1px solid #ccc' },
                }}
                data={line.coordinates}
                x={0}
                y={1}
              />
            ))}
            <VictoryAxis
              offsetY={50}
              orientation='bottom'
              style={{
                axis: { stroke: '#ddd' },
                grid: {
                  stroke: 'none',
                },
                tickLabels: tickLabelsConfig,
              }}
            />
          </VictoryChart>
        )}
        <Legend timeSeries={selectedTimeSeries} maxTimeSeries={maxTimeSeries} />
      </Container>
    </>
  );
};
