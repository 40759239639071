import { AxiosResponse, AxiosError } from 'axios';
import { UseMutationOptions } from 'react-query';
import { useToast, withToastMessages } from '../../../../components/meta/ToastProvider';
import { ReactQueryAxiosContext } from '../../../hooks';
import { TliResponse, TliControl } from '../../../apis/maintenance/v1/maintenance.types';
import { DeviceDetailsResponseTli } from '../../../apis/maintenance/v2';

export const patchTliResponse = (
  response: AxiosResponse<TliResponse>,
  controlUpdate: Partial<TliControl>,
): AxiosResponse<TliResponse> => {
  const result: AxiosResponse<TliResponse> = {
    ...response,
    data: {
      ...response.data,
      control: {
        ...response.data.control,
        ...controlUpdate,
      },
    },
  };
  return result;
};

export const patchTliResponseV2 = (
  response: AxiosResponse<DeviceDetailsResponseTli>,
  updates: Partial<DeviceDetailsResponseTli>,
): AxiosResponse<DeviceDetailsResponseTli> => {
  const result: AxiosResponse<DeviceDetailsResponseTli> = {
    ...response,
    data: {
      ...response.data,
      ...updates,
    },
  };
  return result;
};

export const updateIndexedObjectArray = <T extends { index: number }>(
  objectsToUpdate: T[],
  index: number,
  patchFnc: (objectToUpdate: T) => T,
) => {
  const updatedObjects: T[] = objectsToUpdate.map((objectToUpdate) =>
    objectToUpdate.index === index ? { ...patchFnc(objectToUpdate) } : { ...objectToUpdate },
  );
  return updatedObjects;
};

export const useWithToastMessage = <TMutated, TVariables, TResponseData = TMutated>(
  options: UseMutationOptions<
    AxiosResponse<TMutated>,
    AxiosError<TMutated>,
    TVariables,
    ReactQueryAxiosContext<TResponseData>
  >,
  successMessage: string,
  errorTitle: string,
  errorMessage: string,
) => {
  const toastContext = useToast();
  const toastMessage = {
    onError: { title: errorTitle, message: errorMessage },
    onSuccess: { message: successMessage },
  };
  return withToastMessages(options, toastMessage, toastContext);
};
