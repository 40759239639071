/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon02401VgMinus01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon02401vgminus01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path fill='currentColor' d='M419.53 294.8H147.4a11.34 11.34 0 1 1 0-22.67h272.13a11.34 11.34 0 1 1 0 22.67z' />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
