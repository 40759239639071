import React, { useState } from 'react';

import { useParams } from 'react-router';

import { useIntl } from 'react-intl';

import { Box, Paper, Tab, Tabs } from '@mui/material';

import { TabContent, Typography, useTheme } from '@vaillant-professional-ui/component-libs-web';
import { Icon00100VgHeating01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon00300VgDomesticHotWater01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon13300VgSystem01 } from '@vaillant-professional-ui/component-libs-web';

import { api, firebase, hooks, selectors, util } from '@vaillant-professional-ui/pui-frontend-common';

import { useFirebase } from '../../../providers/firebase';
import Loader from '../../../components/ActivitySpinner/ActivitySpinner';
import { Item } from './Item';
import { ItemContainer, ItemLabel, ItemValue, Row, TabContainer } from './SystemDataOverview.styles';

type TabNames = 'boiler' | 'heating' | 'dhw';
type UserActionMap = Record<TabNames, firebase.UserActionIdentifier>;
const userActionMap: UserActionMap = {
  boiler: 'monitoringTab_heatingDevice',
  heating: 'monitoringTab_heating',
  dhw: 'monitoringTab_dhw',
};

interface SystemDataOverviewProps {
  dataPoints: api.diagnostics.DataPoint[];
  diagnosticsError: unknown;
  isFetchingDiagnostics: boolean;
  predictionData: api.maintenance.v1.PredictionData | null;
  lastRefill: string;
}

const SystemDataOverview: React.FC<SystemDataOverviewProps> = ({
  dataPoints,
  diagnosticsError,
  isFetchingDiagnostics,
  predictionData,
  lastRefill,
}) => {
  const firebaseService = useFirebase();
  const t = hooks.useTranslation();
  const [currentTab, setCurrentTab] = useState<TabNames>('boiler');
  const setTab = (_: unknown, value: TabNames) => {
    firebaseService.logUserAction(userActionMap[value]);
    setCurrentTab(value);
  };

  const { tabBoilerDataPoints, tabHeatingDataPoints, tabDhwDataPoints } =
    util.monitoring.getMonitoringTabData(dataPoints);

  const theme = useTheme();
  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' flexBasis={'100%'} pt={4} mb={6}>
        <Typography variant='h2'>{t('SG_MONITORING_DETAILS_TABS_TITLE')}</Typography>
      </Box>
      <Box pr={[0, 0, 0, 0, 4]} width={['100%', '100%', '100%', '100%', `calc(50% - ${theme.spacing(2)})`]}>
        {isFetchingDiagnostics ? (
          <Loader showLoadingText={true} onScreen={false} loading={isFetchingDiagnostics} />
        ) : diagnosticsError ? (
          <Typography i18nKey='SG_MONITORINGWIDGET_NOSERVICE' />
        ) : (
          <Paper>
            <Tabs onChange={setTab} value={currentTab} data-testid='monitoringTabs'>
              <Tab
                data-testid='monitoringTabDataBoiler'
                value='boiler'
                label={
                  <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
                    <Icon13300VgSystem01 />
                    <Typography ml={2} variant='h3'>
                      {t('SG_BOILER')}
                    </Typography>
                  </div>
                }
              />

              <Tab
                data-testid='monitoringTabDataHeating'
                value='heating'
                label={
                  <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
                    <Icon00100VgHeating01 />
                    <Typography ml={2} variant='h3'>
                      {t('SG_LIVE_MONITOR_MODE_HEATING')}
                    </Typography>
                  </div>
                }
              />

              <Tab
                data-testid='monitoringTabWaterDhw'
                value='dhw'
                label={
                  <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
                    <Icon00300VgDomesticHotWater01 />
                    <Typography ml={2} variant='h3'>
                      {t('SG_MONITORING_VALVE_DOM')}
                    </Typography>
                  </div>
                }
              />
            </Tabs>
            <TabContainer>
              <TabContent value={currentTab} index='boiler'>
                {tabBoilerDataPoints.length ? (
                  tabBoilerDataPoints.map(([key, value]) => <Item key={key} value={value} />)
                ) : (
                  <Typography i18nKey='SG_MONITORING_NO_DATA' />
                )}
              </TabContent>
              <TabContent value={currentTab} index='heating'>
                {tabHeatingDataPoints.map(([key, value]) => (
                  <Item key={key} value={value} />
                ))}

                <Row>
                  <ItemContainer>
                    <ItemLabel>
                      <Typography i18nKey={'SG_PREDICTION_POSSIBLE'} />
                    </ItemLabel>
                    <ItemValue>
                      <Typography component='span' i18nKey={predictionData ? 'SG_YES' : 'SG_NO'} />
                    </ItemValue>
                  </ItemContainer>
                </Row>
                {predictionData?.healthiness?.errorCounter !== undefined && (
                  <Row>
                    <ItemContainer>
                      <ItemLabel>
                        <Typography i18nKey={'SG_NO_OF_ERRORS'} />
                        <Typography i18nKey={'SG_LAST_MONTHS'} i18nOptions={{ amount: 3 }} />
                      </ItemLabel>
                      <ItemValue>
                        <Typography component='span'>{predictionData.healthiness.errorCounter} </Typography>
                      </ItemValue>
                    </ItemContainer>
                  </Row>
                )}

                {predictionData?.healthiness?.refillCounter !== undefined && (
                  <Row>
                    <ItemContainer>
                      <ItemLabel>
                        <Typography i18nKey={'SG_NO_OF_REFILLS'} />
                        <Typography i18nKey={'SG_LAST_MONTHS'} i18nOptions={{ amount: 3 }} />
                      </ItemLabel>
                      <ItemValue>
                        <Typography component='span'>{predictionData.healthiness.refillCounter} </Typography>
                      </ItemValue>
                    </ItemContainer>
                  </Row>
                )}

                {!!lastRefill && (
                  <Row>
                    <ItemContainer>
                      <ItemLabel>
                        <Typography i18nKey={'SG_LAST_REFILL'} />
                        <Typography i18nKey={'SG_LAST_MONTHS'} i18nOptions={{ amount: 3 }} />
                      </ItemLabel>
                      <ItemValue>
                        <Typography component='span'>{lastRefill} </Typography>
                      </ItemValue>
                    </ItemContainer>
                  </Row>
                )}
              </TabContent>
              <TabContent value={currentTab} index='dhw'>
                {tabDhwDataPoints.length ? (
                  tabDhwDataPoints.map(([key, value]) => <Item key={key} value={value} />)
                ) : (
                  <Typography i18nKey='SG_MONITORING_NO_DATA' />
                )}
              </TabContent>
            </TabContainer>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

interface ParamsType {
  boilerSerialNumber: string;
}

export const SystemDataOverviewContainer: React.FC = () => {
  const { boilerSerialNumber } = useParams() as unknown as ParamsType;
  const { customer } = api.useCases.useCustomerDetails(boilerSerialNumber);

  const t = hooks.useTranslation();
  const intl = useIntl();
  const df = hooks.useDateFormatter();
  const gatewaySerialNumber = (customer && selectors.customer.getGatewaySerialNumber(customer)) ?? undefined;

  // @ts-ignore protected by "enabled"
  const diagnosticsQuery = api.diagnostics.useDiagnostics(gatewaySerialNumber, t, df, {
    enabled: !!gatewaySerialNumber,
  });

  const waterPressurePredictionQuery = api.maintenance.v1.useWaterPressurePrediction({ boilerSerialNumber });

  const dataPoints = diagnosticsQuery.data?.dataPoints ?? [];
  const predictionData = waterPressurePredictionQuery?.data ?? null;

  const lastRefillTs = predictionData?.refillHistory?.values?.[0]?.[0];
  const lastRefill = lastRefillTs ? intl.formatDate(new Date((lastRefillTs as number) * 1000)) : '';

  return (
    <SystemDataOverview
      diagnosticsError={diagnosticsQuery?.error}
      predictionData={predictionData}
      isFetchingDiagnostics={diagnosticsQuery.isFetching}
      dataPoints={dataPoints}
      lastRefill={lastRefill}
    />
  );
};
