import { hooks, util } from '@vaillant-professional-ui/pui-frontend-common';
import {
  Icon03500VgWarning01,
  Icon07800VgMaintenance01,
  Icon08500VgWaitingTime01,
  Icon50100VgDisconnected01,
  Icon51100VgPressureLevel301,
  PageHeader,
  Typography,
} from '@vaillant-professional-ui/component-libs-web';
import React, { SVGProps } from 'react';
import { useGetUserDetails } from '../../lib/hooks';
import { useAppConfig } from '@vaillant-professional-ui/pui-frontend-common/src/config';
import { Tab } from '@mui/material';
import type { VgBaseProps } from '@vaillant-professional-ui/component-libs-web/src/components/components.types';
import { MobileDashboardTabs } from './MobileDashboardTabs';
import { MobileDashboardCards } from './MobileDashboardCards';
import { MobileDashboardContainer } from './MobileDashboard.styles';
import { MobileDashboardList } from './MobileDashboardList';
import { TableRow } from './Dashboard.types';
import { Device, ICustomer, ICustomerDetail } from '@vaillant-professional-ui/pui-frontend-common/src/typings/models';
import { ItemAction } from '../../features/dashboard/presenter';

export interface Tab {
  smallText: string;
  bigText: string;
  disabled: boolean;
  icon:
    | typeof Icon03500VgWarning01
    | typeof Icon07800VgMaintenance01
    | typeof Icon51100VgPressureLevel301
    | typeof Icon50100VgDisconnected01
    | typeof Icon08500VgWaitingTime01;
  iconProps: VgBaseProps & SVGProps<SVGSVGElement>;
}

interface MobileDashboardProps {
  tabs: Tab[];
  setActiveTab: (value: number) => void;
  openAddCustomer: () => void;
  activeTab: number | null;
  systemsWaitingForActionCount: number;
  listData: TableRow[];
  formatAddress: (customer: ICustomer | ICustomerDetail) => { firstLine: string; secondLine: string };
  formatCustomerName: (customer?: ICustomer | ICustomerDetail | undefined, reversed?: boolean) => string;
  getBoilerNomenclature: (boiler: Device) => string;
  getBoilerMarketingName: (boiler: Device) => string;
  itemActions: ItemAction[];
}

export const MobileDashboard = ({
  tabs,
  setActiveTab,
  openAddCustomer,
  activeTab,
  systemsWaitingForActionCount,
  listData,
  formatAddress,
  formatCustomerName,
  getBoilerNomenclature,
  getBoilerMarketingName,
  itemActions,
}: MobileDashboardProps) => {
  const t = hooks.useTranslation();
  const { user } = useGetUserDetails();
  const {
    distribution: { platformName },
  } = useAppConfig();

  return (
    <MobileDashboardContainer activeTab={activeTab}>
      {activeTab === null ? (
        <>
          <PageHeader
            testIdKey='mobile-dashboard'
            title={util.user.getGreetingByTime(t, platformName, user)}
            subtitle={
              <Typography
                variant='h3'
                i18nKey='SG_DASHBOARD_WAITING_SYSTEMS'
                i18nOptions={{ count: <strong>{systemsWaitingForActionCount}</strong> }}
              />
            }
          />

          <MobileDashboardCards tabs={tabs} setActiveTab={setActiveTab} openAddCustomer={openAddCustomer} />
        </>
      ) : (
        <>
          <MobileDashboardTabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
          <MobileDashboardList
            listData={listData}
            itemActions={itemActions}
            formatAddress={formatAddress}
            formatCustomerName={formatCustomerName}
            getBoilerNomenclature={getBoilerNomenclature}
            getBoilerMarketingName={getBoilerMarketingName}
          />
        </>
      )}
    </MobileDashboardContainer>
  );
};
