import { useTli } from '../../apis/maintenance/v2/maintenance.hooks';
import { useLocalizedWeekdays, useTranslation } from '../../../libs/hooks';
import { useMutationHandlers } from './TliScheduler.hooks';
import { UseTliSchedulerPresenter } from './TliScheduler.types';
import { getPresentationData, hasMetaDataForZones } from './TliScheduler.read.util';

export const useTliScheduler: UseTliSchedulerPresenter = ({
  gatewaySerialNumber,
  controlSerialNumber,
  systemId,
  deviceId,
  zoneIndex,
  options = {},
}) => {
  const t = useTranslation();
  const weekdays = useLocalizedWeekdays();
  const { data: tliData, ...query } = useTli({ systemId, deviceId }, { staleTime: 1000 * 60 * 15, ...options });

  const presentationData = tliData ? getPresentationData(tliData, weekdays, t) : null;

  if (!hasMetaDataForZones(presentationData)) throw new Error('Metadata for zones are missing');

  const mutationHandlers = useMutationHandlers({
    gatewaySerialNumber,
    controlSerialNumber,
    queryKey: query.queryKey,
    zoneIndex,
    presentationData,
  });

  return {
    ...query,
    ...mutationHandlers,
    presentationData,
  };
};
