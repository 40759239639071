import { AxiosRequestConfig } from 'axios';
import { Country } from '../../../typings/models';
import { ProductReportRequest } from './product.types';

export const productApi = {
  getProductReports: (data: ProductReportRequest[]): AxiosRequestConfig => ({
    method: 'post',
    url: `/pui/product-api/v1/product-reports`,
    data,
  }),

  downloadMediaFile: (fileId: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/product-api/v1/media/${fileId}`,
    timeout: 1000 * 60 * 2, // 2m,
    responseType: 'blob',
  }),

  getMediaFile: (country: Country, articleNumber: string, fileId: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/product-api/v1/countries/${country.toUpperCase()}/products/${articleNumber}/media-files/${fileId}`,
  }),

  getMediaFiles: (country: Country, articleNumber: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/product-api/v1/countries/${country.toUpperCase()}/products/${articleNumber}/media-files`,
  }),

  getAllProducts: (country: Country, brand: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/product-api/v1/countries/${country.toUpperCase()}/products?brand=${brand.toUpperCase()}`,
  }),

  getTroubleCodeInsights: (country: Country, articleNumber: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/product-api/v1/countries/${country.toUpperCase()}/products/${articleNumber}/trouble-code-insights`,
  }),

  getTroubleCodeInsight: (
    country: Country,
    articleNumber: string,
    code: string,
    language: string,
  ): AxiosRequestConfig => {
    return {
      method: 'get',
      url: `/pui/product-api/v1/countries/${country?.toUpperCase()}/products/${articleNumber}/trouble-code-insights/${code}?language=${language}`,
    };
  },
};
