import { isNil } from 'lodash';

import { NumberMeasuredValue } from '../../../../../api/apis/maintenance/v2';
import {
  Action,
  BooleanMeasuredValue,
  StringMeasuredValue,
} from '../../../../../api/apis/maintenance/v2/maintenance.types';
import { TranslationFnc } from '../../../../../typings/models';
import { BooleanDataPoint, DataPoint, EnumDataPoint } from '../../../deviceSettings/useCase/model/dataPoint';
import { dataPointKeyToTranslationMap } from './translationMap';
import { formatEnumValue, getBooleanWriteParams, getEnumWriteParams, getNumericWriteParams } from './writeParams';

interface Params<MeasuredValue> {
  id: string;
  measuredValue?: MeasuredValue;
  mutationId?: string;
  action?: Action;
  actionPropertyKey?: string;
  t: TranslationFnc;
  payloadMetadata?: Record<string, number | string>;
}

export const mapNumericMeasuredValue = ({
  id,
  measuredValue,
  mutationId,
  action,
  actionPropertyKey,
  t,
  payloadMetadata,
}: Params<NumberMeasuredValue>): DataPoint | null => {
  if (!measuredValue || isNil(measuredValue?.value)) {
    return null;
  }

  return {
    id,
    title: t(dataPointKeyToTranslationMap[id]),
    type: 'numeric',
    measuredValue,
    mutationId,
    writeParams:
      action && actionPropertyKey
        ? getNumericWriteParams({ initialValue: measuredValue.value, action, actionPropertyKey, payloadMetadata })
        : null,
  };
};

export const mapBooleanMeasuredValue = ({
  id,
  measuredValue,
  mutationId,
  action,
  actionPropertyKey,
  payloadMetadata,
  t,
}: Params<BooleanMeasuredValue>): BooleanDataPoint | null => {
  if (isNil(measuredValue?.value)) {
    return null;
  }
  return {
    type: 'boolean' as const,
    id,
    title: t(dataPointKeyToTranslationMap[id] ?? ''),
    measuredValue,
    mutationId,
    writeParams:
      action && actionPropertyKey
        ? getBooleanWriteParams({ initialValue: measuredValue.value, action, actionPropertyKey, payloadMetadata })
        : null,
  };
};

export const mapEnumMeasuredValue = ({
  id,
  measuredValue,
  mutationId,
  action,
  actionPropertyKey,
  payloadMetadata,
  t,
}: Params<StringMeasuredValue>): EnumDataPoint | null => {
  if (isNil(measuredValue?.value)) {
    return null;
  }
  return {
    type: 'enum' as const,
    id,
    title: t(dataPointKeyToTranslationMap[id]),
    measuredValue: {
      ...measuredValue,
      title: measuredValue.title ?? formatEnumValue(measuredValue.value),
    },
    mutationId,
    writeParams:
      action && actionPropertyKey
        ? getEnumWriteParams({ initialValue: measuredValue.value, action, actionPropertyKey, payloadMetadata })
        : null,
  };
};
