import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { AlertProps } from './Alert.types';
import { NotificationItem } from '../../molecules/NotificationItem/NotificationItem';
import { Box } from '@mui/material';
import { AlertStyled } from './Alert.styles';
import { Icon04701VgInformation01 } from '../../atoms/Icon/Icon04701VgInformation01';
import { Icon47001VgExclamationMark201 } from '../../atoms/Icon/Icon47001VgExclamationMark201';
import { Icon06501VgCheckConfirm01 } from '../../atoms/Icon/Icon06501VgCheckConfirm01';
import { Icon03500VgWarning01 } from '../../atoms/Icon/Icon03500VgWarning01';

const severityIconMap = {
  info: <Icon04701VgInformation01 color='inherit' />,
  error: <Icon47001VgExclamationMark201 color='inherit' />,
  success: <Icon06501VgCheckConfirm01 color='success' />,
  warning: <Icon03500VgWarning01 color='inherit' />,
};

export const Alert: React.FunctionComponent<AlertProps> = (props) => {
  const { severity, testIdKey } = props;
  const testId = useInstanceId('alert', testIdKey);
  const icon = props.icon || severityIconMap[severity];
  return (
    <AlertStyled severity={severity} {...testId('root').testIdAttributes}>
      <Box pt={3} pl={5} pr={3} display='flex' justifyContent='center'>
        {icon}
      </Box>
      <Box flexGrow={1}>
        <NotificationItem {...props} testIdKey={testId('item').key} />
      </Box>
    </AlertStyled>
  );
};
