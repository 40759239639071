/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon54900VgFieldIntensityGsm01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon54900vgfieldintensitygsm01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M164.41 416.69a14.16 14.16 0 0 1-14.17-14.17v-22.68a14.17 14.17 0 0 1 28.34 0v22.68a14.16 14.16 0 0 1-14.17 14.17zM258 402.52v-102a14.17 14.17 0 0 0-28.34 0v102a14.17 14.17 0 0 0 28.34 0zm79.37 0V221.1a14.17 14.17 0 0 0-28.34 0v181.42a14.17 14.17 0 0 0 28.34 0zm79.37 0V141.73a14.17 14.17 0 0 0-28.34 0v260.79a14.17 14.17 0 0 0 28.34 0z'
      />
    </svg>
  );
};
