import { Paper } from '@mui/material';
import { Button, styled } from '@vaillant-professional-ui/component-libs-web';

export const Container = styled(Paper)`
  align-self: flex-start;
  margin: 20px auto;
  padding: ${({ theme }) => theme.spacing(8)};
  max-width: 600px;
  text-align: center;
`;

export const CancelButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3)};
`;

export const SubmitButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3)};
`;
