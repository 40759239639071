import { IColors } from './theme.types';

const colors: IColors = {
  ACTION: '#4CADD4',
  ACTION_HOVER: '#0085A4',
  ACTIVE_COLOR: '#F5CCD6',
  ACTIVE_COLOR_LIGHT: '#fcf2f5',
  ANTHRAZIT_BLACK: '#333d40',
  BACKGROUND_COLOR_GRAY: '#f1f1f1',
  BLACK: '#000000',
  BLACK_SOFT: '#0C0033',
  BOX_SHADOW_3: '#6c6c6c',
  CONTAINER_BORDER: 'rgba(0, 0, 0, 0.3)',
  CONTAINER_BORDER_LIGHT: 'rgba(128, 128, 128, 0.3)',
  CUSTOMER_BACKGROUND_COLOR: '#f4cbd5',
  CUSTOMER_DETAILS_BACKGROUND_COLOR: '#f4cbd5',
  CUSTOMER_DETAILS_BOX_SHADOW: 'inset 0px 20px 18px -10px #c995a1, inset 0px -20px 18px -10px #c995a1',
  CUSTOMER_LIST_BACKGROUND_COLOR: '#fff6cc',
  DOCUMENT_DOWNLOAD_BOX_SHADOW: '#d1d1d1',
  ERROR: '#ffd100',
  ERROR_LIGHT: '#FFF6CC',
  FROSTY_BLUE: '#8cd0e7',
  GRAY_DARK: '#7f7f7f',
  GRAY_LIGHT: '#9B9B9B',
  GRAY_LIGHTER: '#E6E6E6',
  GRAY_WHITE: '#F1F1F1',
  GRID_ROW_BACKGROUND: '#F5F5F6',
  HEAT_ORANGE: '#e27246',
  INACTIVE: '#CCCCCC',
  INPUT_BORDER: '#C2BFCC',
  LIGHT_GREEN: '#F0F6F6',
  LOGIN_TEXT_BACKGROUND_COLOR: '#4CADD4',
  PLACEHOLDER_TEXT: 'rgba(12,0,51,0.5)',
  POPPER_BORDER_GRAY: '#D6D3DD',
  PRIMARY: '#cc0033',
  PRIMARY_HOVER: '#971B2F',
  SECONDARY: '#971B2F',
  SIGNAL: '#ffd100',
  SUCCESS_NOTIFICATION: '#ddeeec',
  TRANSPARENT: 'transparent',
  UNSEEN_NOTIFICATIONS_BG: '#fff6cc',
  WATER_PRESSURE_ERROR: '#ffd100',
  WATER_PRESSURE_OK: '#4CADD4',
  WHITE: '#FFFFFF',
  WIDGET_FOOTER: '#404041',
};

export default colors;
