import { useLocation } from 'react-router-dom';

const CONSUMPTION_PATHS_WITH_FILTER = [
  'consumption',
  'electrical-consumption',
  'gas-consumption',
  'environmental-yield',
];

export const useShouldShowOperationModeFilter = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')?.pop();
  return !!currentPath && CONSUMPTION_PATHS_WITH_FILTER.includes(currentPath);
};
