import styled from 'styled-components';
import { Box, Link as LinkBase } from '@mui/material';

export const Link = styled(LinkBase)`
  text-decoration: none !important;
  color: ${({ theme }) => theme.palette.common.black};
  cursor: pointer;
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px ${({ theme }) => theme.palette.grey['500']} solid;
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;
