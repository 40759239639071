/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon09801VgClose01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon09801vgclose01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M365.18 201.75a11.33 11.33 0 0 0-16 0l-65.69 65.68-62.95-63a11.34 11.34 0 0 0-16 16l63 62.95-65.68 65.69a11.33 11.33 0 1 0 16 16l65.68-65.68 63 63a11.34 11.34 0 1 0 16-16l-62.95-63 65.68-65.68a11.33 11.33 0 0 0-.09-15.96z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.46 102.05c-100 0-181.41 81.38-181.41 181.41s81.38 181.42 181.41 181.42 181.42-81.38 181.42-181.42-81.38-181.41-181.42-181.41zm0 340.16c-87.53 0-158.74-71.22-158.74-158.75s71.21-158.73 158.74-158.73 158.75 71.21 158.75 158.73S371 442.21 283.46 442.21z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
