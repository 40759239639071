import * as models from '../../../typings/models';

import { IconName } from './LiveMonitor.types';

export const NOTIFICATIONS_TIMEFRAME = 1000 * 60 * 60 * 12; // 12h
export const NOTIFICATIONS_REFRESH_INTERVAL = 1000 * 30; // 30s
export const NOTIFICATIONS_LIMIT = 10;

export const modes: Record<models.LiveMonitorMode, number[]> = {
  heating: [
    0, // Heating demand
    1, // Heating mode fan start up
    2, // Heating mode Pump flow
    3, // Heating mode Ignition
    4, // Heating mode Burner on
    5, // Heating mode Pumping fan slow down
    6, // Heating mode fan slow down
    7, // Heating mode Pump wake
    8, // Heating mode Burner off time
  ],
  dhw: [
    10, // Domestic hot water demand
    11, // DHW mode fan start up
    13, // DHW mode Ignition
    14, // DHW mode Burner on
    15, // DHW mode Pumping fan slow down
    16, // DHW mode fan slow down
    17, // DHW mode Pump slow-down
  ],
  storageTank: [
    20, // Domestic hot water demand
    21, // DHW mode fan start up
    22, // DHW mode Pump running
    23, // DHW mode Ignition
    24, // DHW mode Burner on
    25, // DHW mode Pumping fan slow down
    26, // DHW mode fan slow down
    27, // DHW mode Pump slow-down
    28, // Domestic hot water Burner off time
  ],
};
export const otherModes = [
  41, // Water pressure too high
  42, // Flue gas damper closed
  46, // Comfort protection mode: Minimum load, flame out
  53, // Waiting period Water shortage
  56, // Waiting period CO limit value excess
  59, // Waiting period water circulation quantity
  100, // Standby
  105, // Unit not purged
];

export const statusCodeIcons: Record<number, IconName> = {
  // heating
  0: '00100-vg-heating01',
  1: '00800-vg-ventilation01',
  2: '02900-vg-heat-pump01',
  3: '11200-vg-preheating01',
  4: '00500-vg-burner01',
  5: '02900-vg-heat-pump01',
  6: '00800-vg-ventilation01',
  7: '02900-vg-heat-pump01',
  8: '00500-vg-burner01',

  // dhw
  10: '00300-vg-domestic-hot-water01',
  11: '00800-vg-ventilation01',
  13: '11200-vg-preheating01',
  14: '00500-vg-burner01',
  15: '02900-vg-heat-pump01',
  16: '00800-vg-ventilation01',
  17: '02900-vg-heat-pump01',

  // storageTank
  20: '00300-vg-domestic-hot-water01',
  21: '00800-vg-ventilation01',
  22: '02900-vg-heat-pump01',
  23: '11200-vg-preheating01',
  24: '00500-vg-burner01',
  25: '02900-vg-heat-pump01',
  26: '00800-vg-ventilation01',
  27: '02900-vg-heat-pump01',
  28: '01000-vg-timer01',
};
export const invertedStatusCodes = ['S.0', 'S.00', 'S.10', 'S.20'];
