import { Box, IconButton, TableCell, Typography } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Icon08201VgRight01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon43801VgQuickActions01 } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { HighlightText } from '../HighlightText';
import { CustomerRow } from './CustomerTable.types';

interface CustomerTableRowProps {
  item: CustomerRow;
  markText?: string;
  onClickRowActions?: (event: React.MouseEvent<HTMLElement>, data: CustomerRow) => void;
}

export const CustomerTableRow: React.FC<CustomerTableRowProps> = ({ item, onClickRowActions, markText }) => {
  const testId = useInstanceId('enhancedtable', 'table');
  const { id, customer } = item;

  return (
    <>
      <TableCell>
        <HighlightText markText={markText}>{customer.fullName}</HighlightText>
      </TableCell>
      <TableCell>
        {customer.nomenclature ? <HighlightText markText={markText}>{customer.nomenclature}</HighlightText> : null}
        {customer.marketingName ? (
          <Typography color='text.secondary'>
            <HighlightText markText={markText}>{customer.marketingName}</HighlightText>
          </Typography>
        ) : null}
      </TableCell>
      <TableCell>
        <HighlightText markText={markText}>{customer.addressLine1}</HighlightText>
        <Typography color='text.secondary'>
          <HighlightText markText={markText}>{customer.addressLine2}</HighlightText>
        </Typography>
      </TableCell>
      <TableCell>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
          {onClickRowActions ? (
            <IconButton onClick={(event) => onClickRowActions(event, item)}>
              <Icon43801VgQuickActions01
                width={32}
                height={32}
                color='primary.main'
                {...testId(`row-${id}-actions`).testIdAttributes}
              />
            </IconButton>
          ) : null}
          <Icon08201VgRight01
            color='primary.main'
            width={32}
            height={32}
            {...testId(`row-${id}-arrowRight`).testIdAttributes}
          />
        </Box>
      </TableCell>
    </>
  );
};
