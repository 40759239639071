import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { DataCardGroupProps } from './DataCardGroup.types';
import { Container } from './DataCardGroup.styles';

export const DataCardGroup: React.FC<DataCardGroupProps> = ({ testIdKey, children, disabled, isLoading, ...rest }) => {
  const testId = useInstanceId('datacardgroup', testIdKey);
  return (
    <Container {...rest} {...testId('root').testIdAttributes}>
      {React.Children.map(
        children,
        (thisArg, index) =>
          thisArg &&
          React.cloneElement(thisArg as React.ReactElement, {
            key: index,
            testIdKey: testId(`datacard-${index}`).key,
            disabled,
            isLoading,
          }),
      )}
    </Container>
  );
};
