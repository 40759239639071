import { customers, models, selectors } from '@vaillant-professional-ui/pui-frontend-common';
import { deburr, memoize } from 'lodash';
import sortBy from 'lodash/sortBy';
import { TableRow } from './Dashboard.types';
import { sortFncFactory } from '../../lib/sorting';
import { SortDirection } from '../organisms/VirtualizedTable';

const sortByName = sortFncFactory((item: TableRow) => [
  deburr(selectors.customer.getEndUserLastName(item._meta.customer)).toLowerCase(),
  deburr(selectors.customer.getEndUserFirstName(item._meta.customer)).toLowerCase(),
]);

const sortByDevice = sortFncFactory((item: TableRow) =>
  selectors.customer.getBoilerNomenclature(item._meta.customer).toLowerCase(),
);

const sortByAddress = sortFncFactory((item: TableRow) => [
  item._meta.customer.customer?.address?.postalCode,
  item._meta.customer.customer?.address?.street,
]);

export const getColumns = memoize((t: models.TranslationFnc) => [
  {
    key: 'name',
    label: t('SG_JOBLIST_NAME'),
    width: '18%',
    sort: sortByName,
  },
  {
    key: 'status',
    label: t('SG_JOBLIST_CODE'),
    width: '25%',
  },
  {
    key: 'date',
    label: t('SG_JOBLIST_DATE'),
    width: '15%',
    sort: (data: TableRow[], direction: SortDirection) => {
      const sorted = sortBy(data, (row) => customers.calculateStatusWeight(row._meta.customer));
      return direction === 'asc' ? sorted.reverse() : sorted;
    },
  },
  {
    key: 'device',
    label: t('SG_JOBLIST_APPLIANCE'),
    width: '15%',
    sort: sortByDevice,
  },
  {
    key: 'location',
    label: t('SG_JOBLIST_LOCATION'),
    width: '15%',
    sort: sortByAddress,
  },
  {
    key: 'actions',
    label: t('SG_ACTIONS'),
    width: '10%',
    align: 'right' as const,
  },
]);
