import * as React from 'react';
import { useSelector } from 'react-redux';
import { store, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { Routes } from './Routes';

export const RoutesContainer = () => {
  const { isAuthenticated, logout } = useAuthContext();
  const country = useSelector(store.getCountry);

  // If for some weird reason, the user is logged in without a country, reset app
  if (isAuthenticated && !country) {
    void logout();
  }

  return <Routes isAuthenticated={isAuthenticated} />;
};
