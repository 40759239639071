import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { BackLink, PageHeader, Typography, ScheduleTableEditor } from '@vaillant-professional-ui/component-libs-web';
import { api, hooks } from '@vaillant-professional-ui/pui-frontend-common';
import Button from '../../Button';
import { TliSchedulerPageProps } from './TliSchedulerPage.types';

export const TliSchedulerPage: React.FC<React.PropsWithChildren<TliSchedulerPageProps>> = ({
  customerName,
  zoneData,
  onSave,
}) => {
  const t = hooks.useTranslation();
  const [managedSchedules, setManagedSchedules] = useState<
    api.useCases.tliScheduler.TliScheduleDay<api.useCases.tliScheduler.TliHeatingTimeWindow>[]
  >(zoneData.schedule);

  return (
    <Box width={'100%'}>
      <PageHeader title={customerName || undefined} />
      <Box mb={8}>
        <BackLink linkProps={{ component: Link, to: '..' }} i18nKey='SG_BACK_TO_TLI_CONTROL' testIdKey='tli' />
      </Box>
      <Box mb={8}>
        <Typography variant='h2'>{t('SG_WEEKLY_HEATING_PLANNER')}</Typography>
        <Typography variant='h4'>{zoneData.name}</Typography>
      </Box>
      <Box width={{ lg: '50%', xs: '100%' }} mb={{ xs: 5, lg: 0 }} mr='15px'>
        <Typography>{t('SG_WEEKLY_HEATING_PLANER_DESCRIPTION')}</Typography>
      </Box>
      <ScheduleTableEditor
        managedSchedules={managedSchedules}
        // @ts-ignore This works, because while ScheduleTableEditor is not explicitly
        // returning a "key" property, it just spreads in all the existing properties
        // in "setManagedSchedules", so when we pass "key" in managedSchedules,
        // ScheduleTableEditor will just add it to the existing properties.
        setManagedSchedules={setManagedSchedules}
        maxTimeSlotsPerDay={zoneData.maxSlotsPerDay || 10}
        timeStepWidthInMinutes={zoneData.stepSize || 10}
        temperatureConfig={{
          minimum: zoneData.minTargetTemperature || 10,
          maximum: zoneData.maxTargetTemperature || 40,
          stepSize: zoneData.targetTemperatureStepSize || 1,
        }}
        testIdKey='tli-scheduler'
      />
      <Box display='flex' mt='32px' justifyContent='flex-end'>
        <Button
          disabled={isEqual(zoneData, managedSchedules)}
          color='primary'
          variant='contained'
          onClick={() => onSave(managedSchedules)}
          testId='scheduler-submit'
        >
          {t('SG_SAVE_CHANGES')}
        </Button>
      </Box>
    </Box>
  );
};
