import { styled } from '../../../util/styling';
import { util } from '@vaillant-professional-ui/component-libs-common';
import { SeverityLevel } from './DataPointCardItem.types';
import { Typography } from '../../atoms/Typography';
import { Card as CardBase } from '../Card';
import { Box } from '@mui/material';

const getColors = ({ theme, $severityLevel }: { $severityLevel: SeverityLevel; theme: any }) => {
  if ($severityLevel === 'info') {
    return {
      color: theme.palette.common.black,
      subtitleColor: theme.palette.grey['500'],
      background: theme.palette.common.white,
    };
  }

  const background = theme.palette[$severityLevel].lightest;

  const subtitleColor = theme.palette.grey['800'];

  const color = util.pickTextColorBasedOnBgColorSimple(
    background,
    theme.palette.common.white,
    theme.palette.common.black,
  );

  return {
    color,
    subtitleColor,
    background,
  };
};

export const Card = styled(CardBase)<{ $severityLevel: SeverityLevel }>`
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(2)}`};
  color: ${(props) => getColors(props).color};
  background-color: ${(props) => getColors(props).background};
`;

export const Label = styled(Typography)`
  color: ${(props) => getColors(props).subtitleColor};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconContainer = styled(Box)<{ $severityLevel: SeverityLevel }>`
  color: ${({ theme, $severityLevel }) => {
    if ($severityLevel === 'info') {
      return 'inherit';
    }
    return `${theme.palette[$severityLevel].main};`;
  }};
`;

export const BoxContainer = styled(Box)`
  overflow: hidden;
`;

export const TypographyContainer = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
