import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Layout from './Layout';
import { usePermissions } from '../PermissionGate';
import { store, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';

const indentBlocklist = ['/login'];

export const LayoutContainer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isAuthenticated, isDemo } = useAuthContext();
  const location = useLocation();
  const country = useSelector(store.getCountry);
  const { hasPermissions } = usePermissions(['VIEW_SIDEBAR']);
  const canViewSidebar = hasPermissions && !indentBlocklist.includes(location.pathname);

  return (
    <Layout country={country} isAuthenticated={isAuthenticated} isDemo={isDemo} canViewSidebar={canViewSidebar}>
      {children}
    </Layout>
  );
};
