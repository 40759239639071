import React from 'react';
import { Grid, Typography } from '@vaillant-professional-ui/component-libs-web';
import { util } from '@vaillant-professional-ui/pui-frontend-common';
import Button from '../Button';
import Loader from '../ActivitySpinner/ActivitySpinner';
import { RequestConsentProps } from './RequestConsent.types';
import { getInnerComponent } from './RequestConsent.util';

export const RequestConsent: React.FC<React.PropsWithChildren<RequestConsentProps>> = React.memo(
  function RequestConsent({
    addCustomerResponse,
    actionRequired,
    agreementRequired,
    emailRequired,
    loyaltyRegistration,
    formatAddressUK,
    formatDate,
    isError,
    isErrorCode,
    isPending,
    isInitial,
    onSubmitEmail,
    onResetState,
    onRequestConsent,
  }: RequestConsentProps) {
    const [emailAddress, setEmailAddress] = React.useState('');
    const sublines = React.useMemo(
      () =>
        [
          loyaltyRegistration.address
            ? (util.address.formatAddress(loyaltyRegistration.address, formatAddressUK, true) as string[]).join(', ')
            : null,
          loyaltyRegistration.product.nomenclature,
          loyaltyRegistration.product.articleNumber,
          loyaltyRegistration.registeredAt ? formatDate(new Date(loyaltyRegistration.registeredAt)) : null,
        ].filter(Boolean),
      [],
    );
    const emailSuggestions = loyaltyRegistration.users.map((user) => user.email);

    if (isPending) {
      return <Loader loading={true} />;
    }

    const Component = getInnerComponent({
      actionRequired,
      addCustomerResponse,
      agreementRequired,
      emailAddress,
      emailRequired,
      emailSuggestions,
      isError,
      isErrorCode,
      setEmailAddress,
      onResetState,
      onSubmitEmail,
    });

    return (
      <Grid container direction='column'>
        <Grid container direction='row'>
          <Grid item xs={12} sm={6}>
            <Typography variant='h4'>{loyaltyRegistration.serialNumber}</Typography>
            {sublines.map((subline, index) => (
              <Typography key={index} variant='caption' color='text.secondary'>
                {subline}
              </Typography>
            ))}
          </Grid>
          <Grid item container xs={12} sm={6} justifyContent='flex-end' alignItems='center' pl={4}>
            {isInitial && (
              <Button
                onClick={onRequestConsent}
                testId='addDeviceSuggestedCustomerCta'
                color='primary'
                variant='outlined'
                i18nKey='SG_REQUEST_CONSENT'
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {Component}
        </Grid>
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.actionRequired === nextProps.actionRequired &&
      prevProps.agreementRequired === nextProps.agreementRequired &&
      prevProps.emailRequired === nextProps.emailRequired &&
      prevProps.isError === nextProps.isError &&
      prevProps.isInitial === nextProps.isInitial &&
      prevProps.isPending === nextProps.isPending
    );
  },
);
