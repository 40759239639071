import * as React from 'react';
import { Box, IconButton, Grid } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { NotificationItemProps } from './NotificationItem.types';
import { Icon45101VgClose201 } from '../../atoms/Icon/Icon45101VgClose201';
import { Typography } from '../../atoms/Typography';
import { NotificationItemStyled } from './NotificationItem.styles';

export const NotificationItem: React.FunctionComponent<NotificationItemProps> = ({
  body,
  dateTime,
  title,
  severity = 'info',
  onClick,
  onClose,
  testIdKey,
}) => {
  const testId = useInstanceId('notificationitem', testIdKey);

  return (
    <NotificationItemStyled severity={severity} elevation={0} {...testId('root').testIdAttributes}>
      <Grid container direction='row'>
        <Grid item xs>
          <Box p={3} onClick={onClick}>
            {!!title && (
              <Typography {...testId('title').testIdAttributes} bold>
                {title}
              </Typography>
            )}
            {!!body && (
              <Box mb={2}>
                <Typography {...testId('body').testIdAttributes} variant='body'>
                  {body}
                </Typography>
              </Box>
            )}
            {!!dateTime && (
              <Typography {...testId('dateTime').testIdAttributes} variant='smallCopy' color='text.secondary'>
                {dateTime}
              </Typography>
            )}
          </Box>
        </Grid>
        {!!onClose && (
          <Grid item>
            <Box p={1}>
              <IconButton {...testId('iconButton').testIdAttributes} onClick={onClose} size='large'>
                <Icon45101VgClose201 width={24} height={24} color='common.black' />
              </IconButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </NotificationItemStyled>
  );
};
