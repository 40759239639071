import { selectors } from '../../../../..';
import { useCustomer } from '../../../../api/apis/maintenance/v1';
import { useChartList } from '../../../../api/apis/maintenance/v2/maintenance.hooks';
import { Language } from '../../../../typings/models';

interface UseCaseParams {
  boilerSerialNumber: string;
  language: Language;
}

export const useCase = ({ boilerSerialNumber, language }: UseCaseParams) => {
  const { data: customer, isLoading: isLoadingCustomer, error: errorCustomer } = useCustomer({ boilerSerialNumber });
  const systemId = customer && selectors.customer.getSystemId(customer);

  const { isLoading: isLoadingChart, error: errorChart, data, refetch } = useChartList({ systemId, language });
  const isLoading = isLoadingChart || isLoadingCustomer;
  const error = errorChart || errorCustomer;
  return { data, isLoading, error, refetch };
};
