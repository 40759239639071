import { styled } from '../../../util/styling';

export const TimeGroupStyled = styled('div')`
  & .notificationitem {
    border-bottom: ${({ theme }) => `1px ${theme.palette.grey['500']} solid`};
    & .notificationitem:last-of-type {
      border-bottom: 'none';
    }
  }
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  & .timeGroup:last-of-type {
    margin-bottom: 0;
  }
  & > .hover > *:hover {
    background-color: ${({ theme }) => theme.palette.grey['300']};
  }
`;
