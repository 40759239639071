import React from 'react';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SettingsMenuProps } from './SettingsPage.types';
import { Link } from './SettingsPage.styled';

export const SettingsMenu: React.FC<React.PropsWithChildren<SettingsMenuProps>> = ({ currentSection, sections }) => {
  return (
    <List>
      {Object.entries(sections).map(([menuSectionKey, menuSection]) => {
        const isExternal = !!menuSection.externalLink;
        const linkProps = isExternal
          ? {
              forwardedAs: 'a',
              to: menuSection.externalLink,
              target: '_blank',
              rel: 'noopener noreferrer',
            }
          : {
              to: `/settings/${menuSectionKey}`,
            };

        return (
          <ListItem key={menuSectionKey}>
            <Link {...linkProps}>
              <Typography
                color={menuSectionKey === currentSection.key ? 'text.primary' : 'text.secondary'}
                i18nKey={menuSection.i18nKey}
              />
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
};
