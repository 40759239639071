import React from 'react';
import { config, hooks, permissions, store, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ConfigurationProvider } from '@vaillant-professional-ui/component-libs-web';
import { ConfigurationProviderProps } from '@vaillant-professional-ui/component-libs-common';

export const VgComponentsWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { status } = useAuthContext();
  const appConfig = config.useAppConfig();
  const themeName = appConfig.distribution.theme === 'puiGreen' ? 'green' : 'red';
  const t = hooks.useTranslation() as unknown as ConfigurationProviderProps['translate'];
  const intl = useIntl();
  const userRoles = useSelector(store.getRoles);

  const permissionConfig = {
    rules: permissions.rules,
    userRoles: [...userRoles, ...status.roles],
  };

  return (
    <ConfigurationProvider
      locale={intl.locale}
      themeName={themeName}
      translate={t}
      permissions={permissionConfig}
      testing={{ testIdAttributes: ['data-testid'] }}
    >
      {children}
    </ConfigurationProvider>
  );
};
