/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon45200VgDelete01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon45200vgdelete01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M181.62 396.71a34.05 34.05 0 0 0 34 34H351.7a34.05 34.05 0 0 0 34-34V204H181.62zm22.68-170.08H363v170.08a11.36 11.36 0 0 1-11.34 11.34H215.64a11.35 11.35 0 0 1-11.34-11.34zm177.88-56.49-55.68-10.67 4.27-22.27a11.35 11.35 0 0 0-9-13.27l-55.69-10.67a11.33 11.33 0 0 0-13.26 9l-4.27 22.27-55.68-10.67a11.34 11.34 0 0 0-4.27 22.28l189.31 36.27a11.34 11.34 0 1 0 4.27-22.27zM273 137.66l33.41 6.4-2.13 11.14-33.41-6.4z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.67 385.47A11.34 11.34 0 0 0 295 374.13V260.55a11.34 11.34 0 1 0-22.68 0v113.58a11.34 11.34 0 0 0 11.35 11.34zm45.33.09a11.34 11.34 0 0 0 11.34-11.34V260.64a11.34 11.34 0 0 0-22.68 0v113.58A11.34 11.34 0 0 0 329 385.56zm-90.69-.19A11.34 11.34 0 0 0 249.65 374V260.45a11.34 11.34 0 1 0-22.67 0V374a11.34 11.34 0 0 0 11.33 11.37z'
        className='cls-1'
      />
    </svg>
  );
};
