import React from 'react';
import { Box } from '@mui/material';
import { BackLink, PageHeader, ScheduleTableEditor, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Vrc700SchedulerPageProps } from './Vrc700SchedulerPage.types';
import { Link } from 'react-router-dom';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { AxiosError } from 'axios';
import { schedulePageSubtitleTextMapper } from '@vaillant-professional-ui/pui-frontend-common/src/features/schedules/Vrc700/presenter';

export const Vrc700SchedulerPage: React.FC<Vrc700SchedulerPageProps> = ({
  schedule,
  maxSlotsPerDay,
  fullName,
  scheduleType,
  backlinkPath,
  testId,
}) => {
  return (
    <Box width={'100%'}>
      <PageHeader title={fullName} />
      <Box>
        <BackLink
          linkProps={{ component: Link, to: backlinkPath }}
          i18nKey='SG_BACK_TO_SETTINGS_AND_PARAMETERS'
          testIdKey='settings'
        />
      </Box>
      <Box mb={8} mt={4}>
        <Typography variant={'h2'} i18nKey={schedulePageSubtitleTextMapper[scheduleType]} />
      </Box>
      {schedule ? (
        <ScheduleTableEditor
          readOnly={true}
          managedSchedules={schedule}
          setManagedSchedules={() => {}}
          maxTimeSlotsPerDay={maxSlotsPerDay}
          timeStepWidthInMinutes={10}
          testIdKey={testId}
        />
      ) : (
        <LoadingErrorWrapper error={new AxiosError('No schedule found', '404')} />
      )}
    </Box>
  );
};
