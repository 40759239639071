import { ReactQueryAxiosContext, UseAxiosMutationOptions, useMutation } from '../../hooks';
import { DeviceDetailsResponseTli } from '../maintenance/v2';
import { settingsApi } from './settings.endpoints';
import {
  HeatCurveVariables,
  HeatCurvePayload,
  MinFlowTargetTemperatureVariables,
  MinFlowTargetTemperaturePayload,
} from './settings.types';

export const usePatchHeatCurve = (
  gatewaySerialNumber: string,
  options: UseAxiosMutationOptions<DeviceDetailsResponseTli, HeatCurveVariables> = {},
) => {
  return useMutation<
    DeviceDetailsResponseTli,
    HeatCurvePayload,
    HeatCurveVariables,
    ReactQueryAxiosContext<DeviceDetailsResponseTli>
  >(
    (data) =>
      settingsApi.patchHeatCurve(gatewaySerialNumber, {
        circuitIndex: data.circuitIndex,
        heatCurve: Number((Math.round(data.heatCurve / 0.05) * 0.05).toFixed(2)),
      }),
    options,
  );
};

export const usePatchMinFlowTemperature = (
  gatewaySerialNumber: string,
  options: UseAxiosMutationOptions<DeviceDetailsResponseTli, MinFlowTargetTemperatureVariables> = {},
) => {
  return useMutation<
    DeviceDetailsResponseTli,
    MinFlowTargetTemperaturePayload,
    MinFlowTargetTemperatureVariables,
    ReactQueryAxiosContext<DeviceDetailsResponseTli>
  >((data) => settingsApi.patchMinFlowTemperature(gatewaySerialNumber, data), options);
};
