import { models } from '@vaillant-professional-ui/pui-frontend-common';
import memoize from 'lodash/memoize';
import { sortFncFactory } from '../../lib/sorting';

const sortBySerialNumber = sortFncFactory(
  (loyaltyRegistration: models.LoyaltyRegistration) => loyaltyRegistration.serialNumber,
);

const sortByDevice = sortFncFactory((loyaltyRegistration: models.LoyaltyRegistration) =>
  loyaltyRegistration.product?.nomenclature?.toLowerCase(),
);

const sortByAddress = sortFncFactory((loyaltyRegistration: models.LoyaltyRegistration) => [
  loyaltyRegistration.address?.postalCode,
  loyaltyRegistration.address?.street,
]);

const sortByRegistrationDate = sortFncFactory(
  (loyaltyRegistration: models.LoyaltyRegistration) => loyaltyRegistration.registeredAt || '',
);

export const getColumns = memoize((t: models.TranslationFnc) => [
  {
    key: 'serialNumber',
    label: t('SG_SERIALNUMBER'),
    sort: sortBySerialNumber,
  },
  {
    key: 'device',
    label: t('SG_JOBLIST_APPLIANCE'),
    sort: sortByDevice,
  },
  {
    key: 'address',
    label: t('SG_JOBDETAILS_ADDRESS'),
    sort: sortByAddress,
  },
  {
    key: 'registeredAt',
    label: t('SG_LOYALTY_DEVICES_REGISTERED_AT'),
    sort: sortByRegistrationDate,
  },
  {
    key: 'action',
    label: t('SG_ADD_CUSTOMER'),
  },
]);
