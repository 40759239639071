import React from 'react';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { ExternalLink, InternalLink } from './TextLink.styles';
import { ITextLinkProps } from './TextLink.types';

const TextLink: React.FC<React.PropsWithChildren<ITextLinkProps>> = ({
  children,
  to,
  id,
  style = {},
  testID,
  values,
  ...rest
}) => {
  const t = hooks.useTranslation();
  const content = (
    <span>
      {id && t(id, values)}
      {!!children && children}
    </span>
  );

  const link = to.toString();
  return /^https?:\/\//.test(link) ? (
    <ExternalLink href={link} target='_blank' rel='noopener noreferrer'>
      {content}
    </ExternalLink>
  ) : (
    <InternalLink data-testid={testID} style={style} to={to} {...rest}>
      {content}
    </InternalLink>
  );
};

export default TextLink;
