import { Tab, Tabs, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '../../../util/styling';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: inline;
`;

export const StyledToggleButton = styled(ToggleButton)`
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(5)};
  color: ${({ theme }) => theme.palette.grey[500]};
  border-color: ${({ theme }) => theme.palette.grey[400]};

  &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-color: ${({ theme }) => theme.palette.grey[400]};
    &:hover {
      border-color: ${({ theme }) => theme.palette.grey[500]};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.palette.grey[800]};
    background-color: ${({ theme }) => theme.palette.common.white};
    border-color: ${({ theme }) => theme.palette.grey[500]};
    z-index: 1;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.common.black};
    padding: ${({ theme }) => theme.spacing(5)} calc(${({ theme }) => theme.spacing(5)} - 1px);
    border-radius: 4px !important;
    z-index: 2;

    &.Mui-selected:hover {
      background-color: ${({ theme }) => theme.palette.primary.lightest};
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledTab = styled(Tab)`
  flex-direction: row;
`;
