import React from 'react';

import { VictoryTooltip } from 'victory';
import { ForeignObject, TooltipContent } from './ChartTooltip.styles';
import { ChartTooltipProps, TooltipDataPoint } from './ChartTooltip.types';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useTranslation } from '@vaillant-professional-ui/component-libs-common';

export const ChartTooltip = <T extends TooltipDataPoint>(
  props: ChartTooltipProps<T>,
): React.ReactElement<ChartTooltipProps<T>> => {
  const {
    position,
    xPosition,
    yPosition,
    yPositionContent,
    xPositionContent,
    containerWidth,
    activePoints,
    containerRef,
    title = '',
    fontSize,
    isArrowVisible,
    ...rest
  } = props;
  const { formatNumber } = useIntl();
  const t = useTranslation();

  return (
    <VictoryTooltip
      flyoutComponent={
        activePoints?.length ? (
          <g style={{ pointerEvents: 'none' }}>
            <ForeignObject height={1} width={1} $height={1} $width={1} x={xPosition} y={yPosition - 9}>
              <TooltipContent
                $isArrowVisible={isArrowVisible ?? true}
                ref={containerRef}
                $position={position}
                $width={containerWidth}
                $x={xPositionContent}
                $y={yPositionContent}
              >
                <Typography paddingBottom={1} whiteSpace='nowrap' fontSize={fontSize}>
                  {title}
                </Typography>
                {activePoints?.map((activeDatum, index) => (
                  <Box
                    key={index}
                    ml={1}
                    display='flex'
                    whiteSpace='nowrap'
                    gap={2}
                    alignItems='center'
                    width='100%'
                    padding={0}
                  >
                    <Box
                      borderRadius='50%'
                      width={9}
                      height={9}
                      minHeight={9}
                      minWidth={9}
                      bgcolor={activeDatum.style?.data.stroke ?? activeDatum.style?.data.fill}
                      mb={0.5}
                    />
                    <Typography
                      display='flex'
                      width='100%'
                      whiteSpace='nowrap'
                      gap={2}
                      justifyContent='space-between'
                      alignItems='center'
                      fontSize={fontSize}
                    >
                      <Typography fontSize={fontSize}>{`${activeDatum.name && t(activeDatum.name)}`}</Typography>
                      <Typography bold fontSize={fontSize}>
                        {typeof activeDatum.value === 'number'
                          ? `${formatNumber(activeDatum.value, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} ${activeDatum.unit}`
                          : activeDatum.value}
                      </Typography>
                    </Typography>
                  </Box>
                ))}
              </TooltipContent>
            </ForeignObject>
          </g>
        ) : (
          <></>
        )
      }
      {...rest}
    />
  );
};
