import { Paper, SnackbarOrigin } from '@mui/material';
import { styled } from '../../../util/styling';

export const StyledPaper = styled(Paper)<{ $anchorOrigin?: SnackbarOrigin }>`
  padding: 0;
  width: 100%;
  overflow: hidden;
  position: absolute;
  ${(props) => props.$anchorOrigin?.vertical === 'top' && { top: 0 }}
  ${(props) => props.$anchorOrigin?.vertical === 'bottom' && { bottom: 0 }}
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 500px;
  }
`;
