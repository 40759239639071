import React from 'react';

import { ChartTooltip } from '../ChartTooltip';
import { TooltipDataPoint } from '../ChartTooltip/ChartTooltip.types';
import { useEmfTooltipMetaData } from './BarChartTooltip.hooks';
import { BarChartTooltipProps } from './BarChartTooltip.types';

export const BarChartTooltip = <T extends TooltipDataPoint>(
  props: BarChartTooltipProps<T>,
): React.ReactElement<BarChartTooltipProps<T>> => {
  const { activePoints: _activePoints, datum, titleRenderer, ...rest } = props;
  const activePoints: TooltipDataPoint[] = React.useMemo(
    () => _activePoints?.filter((point) => !!point.value && point.value >= 0.01) ?? [],
    [_activePoints],
  );

  const { containerRef, position, xPosition, xPositionContent, yPosition, yPositionContent, containerWidth, fontSize } =
    useEmfTooltipMetaData(props);

  return (
    <ChartTooltip
      containerWidth={containerWidth}
      position={position}
      xPosition={xPosition}
      xPositionContent={xPositionContent}
      yPosition={yPosition}
      yPositionContent={yPositionContent}
      fontSize={fontSize}
      datum={datum}
      title={titleRenderer(datum)}
      activePoints={activePoints}
      containerRef={containerRef}
      {...rest}
    />
  );
};
