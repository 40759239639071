import { models } from '@vaillant-professional-ui/pui-frontend-common';

export interface LanguageSelectionProps {
  setLanguage: (language: models.Language) => void;
  currentLanguage: string;
}

export interface LanguageSelectionMocks {
  default: {
    props: LanguageSelectionProps;
  };
  [key: string]: {
    props: LanguageSelectionProps;
  };
}

export const isLanguage = (value: unknown): value is models.Language => {
  if (!value || typeof value !== 'string') {
    return false;
  }

  return models.supportedLanguages.includes(value as models.Language);
};
