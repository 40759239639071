import React from 'react';

import { Box, Stack } from '@mui/material';

import { Button, ModalDrawer, Switch, Typography } from '@vaillant-professional-ui/component-libs-web';

interface WriteBooleanDatapointDrawerContentProps {
  title: string;
  subtitle: string;
  labelSwitchOn: string;
  labelSwitchOff: string;
  value: boolean;
  onChange: (value: boolean) => void;
  onClose: () => void;
  isSaveButtonDisabled: boolean;
  handleSubmit: () => void;
}

export const WriteBooleanDatapointDrawerContent: React.FC<WriteBooleanDatapointDrawerContentProps> = ({
  title,
  subtitle,
  labelSwitchOn,
  labelSwitchOff,
  value,
  onChange,
  onClose,
  isSaveButtonDisabled,
  handleSubmit,
}) => {
  return (
    <>
      <ModalDrawer.Title>{title}</ModalDrawer.Title>
      <ModalDrawer.Content>
        <Box display='flex' flexDirection='column' gap={8}>
          <Typography>{subtitle}</Typography>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography bold={!value}>{labelSwitchOff}</Typography>
            <Switch testIdKey='booleanDrawerInput' checked={value} onChange={(_, checked) => onChange(checked)} />
            <Typography bold={value}>{labelSwitchOn}</Typography>
          </Stack>
        </Box>
      </ModalDrawer.Content>
      <ModalDrawer.Footer>
        <Button variant='outlined' onClick={onClose} testIdKey='close' i18nKey='SG_CLOSE' />
        <Button
          variant='contained'
          onClick={handleSubmit}
          testIdKey='save'
          disabled={isSaveButtonDisabled}
          i18nKey='SG_SAVE'
        />
      </ModalDrawer.Footer>
    </>
  );
};
