import { Button, styled } from '@vaillant-professional-ui/component-libs-web';

export const StyledLegendButton = styled(Button)<{ $active?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-color: ${({ $active, theme }) => ($active ? theme.palette.primary.main : theme.palette.common.white)};
  background-color: ${({ $active, theme }) => ($active ? theme.palette.primary.lightest : 'transparent')};
`;
