import React from 'react';

import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { AppConfig, useAppConfig } from '../../config';
import { useLoggingContext } from '../../contexts/loggingContext';
import { getCountry, getLanguage } from '../../store/selectors';
import { Country, Language } from '../../typings/models';
import { I18nKeys } from '../messages';

interface Props {
  defaultView?: JSX.Element;
}

type Messages = Record<I18nKeys, string>;

const overrides = (appConfig: AppConfig, country: Country, language: Language, messages: Messages) => {
  return {
    VG_LOGIN_FAILED:
      appConfig?.distribution?.brand?.group === 'vaillant' && country === 'GB' && language === 'en'
        ? messages?.SG_LOGIN_FAIL_VAILLANT_UK
        : messages?.SG_LOGIN_FAIL,
  };
};

const loadI18nResources = async (
  language: Language,
  country: Country,
  appCfg: AppConfig,
  setMessages: (messages: Messages) => void,
) => {
  try {
    const { default: loadedMessages } = await import('../messages');
    const messages = loadedMessages[language];
    const vgOverrides = overrides(appCfg, country, language, messages);
    setMessages({ ...messages, ...vgOverrides });
  } catch (e) {
    console.error('ERROR **** ', e);
  }
};

const ignoreMissingTranslationKeyErrors = () => undefined;

export const I18nProvider: React.FC<React.PropsWithChildren<Props>> = ({ defaultView, children }) => {
  const [messages, setMessages] = React.useState<Messages | null>(null);
  const appCfg: AppConfig = useAppConfig();
  const language = useSelector(getLanguage);

  const country = useSelector(getCountry);
  const { reportError } = useLoggingContext();

  React.useEffect(() => {
    if (language) {
      loadI18nResources(language, country, appCfg, setMessages).catch((error) => {
        reportError('i18n-load-resources', error);
      });
    }
  }, [reportError, language, appCfg, country]);

  if (!messages && defaultView) {
    return defaultView;
  }

  const locale = `${language}-${country}`;

  return (
    <IntlProvider
      onError={ignoreMissingTranslationKeyErrors}
      locale={locale}
      messages={messages as Record<string, string>}
      defaultLocale='en-GB'
    >
      {children}
    </IntlProvider>
  );
};
