import { IsoTimestampRecord } from '../../../typings/models';
import { getTroubleCodeForNotification } from '../../../util';
import { Notification } from './notifications.types';

export const notificationResponseToTimestampString = (
  notifications: Notification[] = [],
): IsoTimestampRecord<string>[] =>
  notifications.map((v) => ({
    value: getTroubleCodeForNotification(v),
    timestamp: new Date(v.occurrenceTimestamp * 1000).toISOString(),
  }));
