import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { addDays, startOfDay } from 'date-fns';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

import { HeatingDeviceType } from '../../../typings/models';
import { useNumberFormatWithFallback } from '../../../util/l10n';
import { useEmfBuckets, useEmfDevices as useEmfDevicesBase } from '../../apis/emf';
import { useTranslation } from '../../../libs/hooks';
import { EmfBucketsRequestParams, EmfErrorResponse } from '../../apis/emf/emf.types';
import {
  Item,
  UseConsumptionDataFunction,
  UseEmfDevicesFunction,
  UseEmfForAllDevicesFunction,
} from './emf.hooks.types';
import { ConsumptionData, EmfDevice } from './emf.types';
import {
  dateRangePeriodToResolution,
  getCalculatedConsumption,
  getDefaultConsumptionFilters,
  getEmfBucketsRequestParamsFactory,
  getSelectedEmfData,
  isHybrid,
  transformValuesToKwh,
} from './emf.util';

const useEmfBucketsForAllDevices: UseEmfForAllDevicesFunction = (boilerSerialNumber, ...queryParams) => {
  const convertToEmfBucketsRequestParams = getEmfBucketsRequestParamsFactory(boilerSerialNumber, ...queryParams);

  const { data: devices } = useEmfDevicesBase(boilerSerialNumber);
  const paramsList = (
    devices ? devices.map(convertToEmfBucketsRequestParams).filter(Boolean) : []
  ) as EmfBucketsRequestParams[];

  const result = useEmfBuckets(paramsList ?? [], { enabled: !!paramsList });
  const data = compact(result.map((q) => (q.data ? transformValuesToKwh(q.data) : q.data)));

  const errors: AxiosError<EmfErrorResponse>[] = compact(result.map((q) => q.error));
  const isError = result.findIndex((q) => q.isError) !== -1;
  const isLoading = result.some((q) => q.isLoading);

  return { data, isError, isLoading, errors };
};

export const useEmfDevices: UseEmfDevicesFunction = (boilerSerialNumber, startDate) => {
  const { data: emfDevicesData, ...rest } = useEmfDevicesBase(boilerSerialNumber);

  const data = emfDevicesData?.map(
    (device): EmfDevice => ({
      id: device.device_uuid,
      articleNumber: device.article_number,
      serialNumber: device.device_serial_number,
      type: device.device_type,
      productName: device.product_name,
      supportedEnergyInformation:
        device.data?.map(({ operation_mode: operationMode, value_type: energyType }) => ({
          operationMode,
          energyType,
        })) ?? [],
    }),
  );

  const supportedOperationModes = uniq(
    data?.flatMap((entry) => entry.supportedEnergyInformation.map((v) => v.operationMode)) ?? [],
  );

  const defaultFilters = useMemo(
    () => getDefaultConsumptionFilters(startDate, supportedOperationModes),
    [startDate, supportedOperationModes],
  );

  const isHybridSystem = data ? isHybrid(data) : false;

  return { data, defaultFilters, isHybridSystem, ...rest };
};

export const useConsumptionData: UseConsumptionDataFunction = ({
  boilerSerialNumber,
  startDate,
  endDate: rawEndDate,
  dateRangePeriod,
  selectedDevices = 'all',
  selectedOperationModes,
}) => {
  const endDate = startOfDay(addDays(rawEndDate, 1)); // Send begin of next day to API

  const resolution = dateRangePeriodToResolution(dateRangePeriod);
  const {
    isLoading,
    errors,
    data: emfData,
  } = useEmfBucketsForAllDevices(boilerSerialNumber, startDate, endDate, resolution);

  if (!emfData) {
    return { isLoading, errors };
  }

  const { categories, efficiency } = getCalculatedConsumption(
    getSelectedEmfData(selectedDevices, emfData),
    selectedOperationModes,
  );

  return {
    data: { categories, efficiency },
    isLoading,
    errors,
  };
};

export const useDataPoints = (
  mainHeatingDeviceType: HeatingDeviceType,
  consumptionData: ConsumptionData | undefined,
  isHybridSystem: boolean,
): Item[] => {
  const t = useTranslation();
  const formatNumber = useNumberFormatWithFallback();

  const formatValue = (value: number) => formatNumber(value);

  if (!consumptionData) {
    return [];
  }

  const map: Record<'HYBRID' | 'HEAT_PUMP' | 'BOILER', Item[]> = {
    HEAT_PUMP: [
      {
        label: t('SG_ELECTRICAL_ENERGY_CONSUMPTION'),
        value: formatValue(consumptionData.categories.electricalConsumed.total),
        icon: 'Icon13000VgElectric01',
        unit: 'kWh',
      },
      {
        label: t('SG_EMF_YIELD'),
        value: formatValue(consumptionData.categories.environmentalYield.total),
        icon: 'Icon06600VgEcoMode01',
        unit: 'kWh',
      },
      {
        label: t('SG_SOLAR_THERMAL'),
        value: formatValue(consumptionData.categories.solarYield.total),
        icon: 'Icon16100VgSolarCollector01',
        unit: 'kWh',
      },
      {
        label: t('SG_HEAT_GENERATED'),
        value: formatValue(consumptionData.categories.heatingGenerated.total),
        icon: 'Icon00100VgHeating01',
        unit: 'kWh',
      },
      {
        label: t('SG_HOT_WATER_GENERATED'),
        value: formatValue(consumptionData.categories.dhwGenerated.total),
        icon: 'Icon00300VgDomesticHotWater01',
        unit: 'kWh',
      },
      {
        label: t('SG_COOLING_GENERATED'),
        value: formatValue(consumptionData.categories.coolingGenerated.total),
        icon: 'Icon02000VgCooling01',
        unit: 'kWh',
      },
    ],
    BOILER: [
      {
        label: t('SG_GAS_CONSUMPTION'),
        value: formatValue(consumptionData.categories.primaryConsumed.total),
        icon: 'Icon00500VgBurner01',
        unit: 'kWh',
      },
      {
        label: t('SG_ELECTRICAL_ENERGY_CONSUMPTION'),
        value: formatValue(consumptionData.categories.electricalConsumed.total),
        icon: 'Icon13000VgElectric01',
        unit: 'kWh',
      },
      {
        label: t('SG_HEAT_GENERATED'),
        value: formatValue(consumptionData.categories.heatingGenerated.total),
        icon: 'Icon00100VgHeating01',
        unit: 'kWh',
      },
      {
        label: t('SG_HOT_WATER_GENERATED'),
        value: formatValue(consumptionData.categories.dhwGenerated.total),
        icon: 'Icon00300VgDomesticHotWater01',
        unit: 'kWh',
      },
    ],
    HYBRID: [
      {
        label: t('SG_ELECTRICAL_ENERGY_CONSUMPTION'),
        value: formatValue(consumptionData.categories.electricalConsumed.total),
        icon: 'Icon13000VgElectric01',
        unit: 'kWh',
      },
      {
        label: t('SG_GAS_CONSUMPTION'),
        value: formatValue(consumptionData.categories.primaryConsumed.total),
        icon: 'Icon00500VgBurner01',
        unit: 'kWh',
      },
      {
        label: t('SG_EMF_YIELD'),
        value: formatValue(consumptionData.categories.environmentalYield.total),
        icon: 'Icon06600VgEcoMode01',
        unit: 'kWh',
      },
      {
        label: t('SG_SOLAR_THERMAL'),
        value: formatValue(consumptionData.categories.solarYield.total),
        icon: 'Icon16100VgSolarCollector01',
        unit: 'kWh',
      },
    ],
  };

  const key = isHybridSystem ? 'HYBRID' : mainHeatingDeviceType === 'HEAT_PUMP' ? 'HEAT_PUMP' : 'BOILER';
  return map[key];
};
