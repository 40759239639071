import { styled } from '@mui/material';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const ToggleButtonGroupStyled = styled(MuiToggleButtonGroup)`
  display: flex;
  min-height: 48px;
  background-color: transparent !important;
  border-radius: 4px !important;
  gap: ${({ theme }) => theme.spacing(2)} !important;
  padding-bottom: 0 !important;
  background: none;
  .Mui-disabled {
    border-color: ${({ theme }) => theme.palette.divider} !important;
  }
`;
