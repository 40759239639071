import Link from '@mui/material/Link';
import { styled } from '@vaillant-professional-ui/component-libs-web';

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    text-decoration: underline !important;
  }
`;
