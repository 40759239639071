import { Button, Typography, styled, Checkbox } from '@vaillant-professional-ui/component-libs-web';
import { Menu, MenuItem } from '@mui/material';

export const BigCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    font-size: 24px;
  }

  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
`;

export const Title = styled(Typography)`
  text-transform: none;
  text-align: start;
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 0px ${({ theme }) => theme.spacing(6)} 0px ${({ theme }) => theme.spacing(3)};
  min-height: 28px;

  pointer-events: auto !important;
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-outlined {
    border-width: 1px;
    border-color: ${({ theme }) => theme.palette.common.black};
    border-radius: 0;
  }
`;

export const StyledButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
`;

export const Circle = styled('span')<{ color: string }>`
  align-self: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${({ color }) => color};
`;

export const ClickableOverlayWrapper = styled('div')`
  display: inline-block;
  position: relative;
`;

export const ClickableOverlay = styled('div')`
  position: absolute;
  inset: 0;
  z-index: 999;
`;

export const OptionLabel = styled(Typography)`
  flex: 1;
  white-space: normal;
`;
