import { useDeviceSettings } from '../../../../api/apis/maintenance/v2';
import { mutationFactory } from './mutation/mutationFactory';
import { mapResponseCategoriesToDataModel } from './mapping/responseToDataModelMapper';
import { useTranslation } from '../../../../..';

type Props = Omit<Parameters<typeof useDeviceSettings>, 'options'>[0];

export const useDeviceSettingsAndMutations = (props: Props) => {
  const t = useTranslation();
  const { data, refetch, ...query } = useDeviceSettings(props, {
    staleTime: 1000 * 60 * 15,
    enabled: !!props.deviceId,
  });
  const createUseMutation = mutationFactory(query.queryKey);
  const categorisedDataPoints = mapResponseCategoriesToDataModel(data?.categories ?? [], t);
  return { categories: categorisedDataPoints, createUseMutation, refetch, ...query };
};
