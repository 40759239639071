import { Theme } from '@mui/material';
import { VictoryThemeDefinition } from 'victory';

export const getThemeForVictory = (theme: Theme): VictoryThemeDefinition => {
  // Typography
  const letterSpacing = 'normal';
  const fontSize = 22;

  // Layout
  const padding = 28;
  const baseProps = {
    padding: 20,
  };

  // * Labels
  const baseLabelStyles = {
    fontFamily: 'Vaillant_12',
    fontSize,
    letterSpacing,
    padding,
    stroke: 'transparent',
    strokeWidth: 0,
  };

  const centeredLabelStyles = { textAnchor: 'middle', ...baseLabelStyles };

  // Strokes
  const strokeLinecap = 'round';
  const strokeLinejoin = 'round';

  return {
    axis: {
      ...{
        style: {
          axis: {
            strokeWidth: 1,
            strokeLinecap,
            strokeLinejoin,
            stroke: 'lightgray',
          },
          axisLabel: {
            ...centeredLabelStyles,
            ...{
              padding,
              stroke: 'transparent',
            },
          },
          grid: {
            stroke: theme.palette.grey[400],
          },
        },
      },
      ...baseProps,
    },
    line: {
      ...{
        style: {
          data: {
            fill: 'transparent',
            opacity: 1,
            strokeWidth: 2,
          },
          labels: centeredLabelStyles,
        },
      },
      ...baseProps,
    },
    bar: {
      style: { data: { width: 10 } },
    },
  };
};
