import { isNil } from 'lodash';
import { FormatNumberOptions, useIntl } from 'react-intl';

export const useNumberFormatWithFallback = () => {
  const { formatNumber } = useIntl();
  const handleNumberFormat = (
    value: number | null,
    options: FormatNumberOptions = { minimumFractionDigits: 1, maximumFractionDigits: 1 },
  ) => {
    if (isNil(value)) {
      return formatNumber(0, options).replaceAll('0', '-');
    }
    return formatNumber(value, options);
  };
  return handleNumberFormat;
};

export type NumberFormatter = ReturnType<typeof useNumberFormatWithFallback>;
