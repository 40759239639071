import { Outlet } from 'react-router';

import { ChartTabsContainer } from '../ChartTabs';
import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { Card, CardContent, Typography } from '@vaillant-professional-ui/component-libs-web';
import { DateRangePickerContainer } from '../DateRangePicker';

const usePresenter = () => {
  const t = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const viewSettingsCardTitle = t('SG_TIME_SERIES_VIEW_SETTINGS').toUpperCase();
  const viewSettingsCardGraphsSubtitle = t('SG_TIME_SERIES_SHOW_GRAPHS_FOR');

  return { isMobile, viewSettingsCardTitle, viewSettingsCardGraphsSubtitle };
};

export const HistoryChartsLayout: React.FC = () => {
  const { isMobile, viewSettingsCardTitle, viewSettingsCardGraphsSubtitle } = usePresenter();

  return (
    <>
      <Box mb={{ xs: 6, sm: 8 }}>
        {isMobile ? (
          <Card>
            <CardContentStyled>
              <Typography variant='h4'>{viewSettingsCardTitle}</Typography>
              <Box mt={2} mb={4}>
                <DateRangePickerContainer />
              </Box>
              <Typography variant='h4'>{viewSettingsCardGraphsSubtitle}</Typography>
              <ChartTabsContainer />
            </CardContentStyled>
          </Card>
        ) : (
          <ChartTabsContainer />
        )}
      </Box>
      <Outlet />
    </>
  );
};

const CardContentStyled = styled(CardContent)`
  padding-bottom: 16px !important;
`;
