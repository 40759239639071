import * as React from 'react';

import { features } from '@vaillant-professional-ui/pui-frontend-common';
import { DeviceSettings } from './DeviceSettings';
import { usePresenter } from './DeviceSettings.presenter';

export const DeviceSettingsContainer: React.FC = () => {
  const props = usePresenter(features.systemSettings.deviceSettings.usePresenter);
  return <DeviceSettings {...props} />;
};
