import * as React from 'react';

import { ListItemIcon, ListItemText } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { ListItem, ListItemIconStyled } from './SideMenuItem.styles';
import { SideMenuItemProps } from './SideMenuItem.types';

export const SideMenuItem: React.FunctionComponent<React.PropsWithChildren<SideMenuItemProps>> = ({
  isActive = false,
  contentComponent,
  rightComponent,
  testIdKey,
  leftComponent,
  style,
}) => {
  const testId = useInstanceId('sidemenuitem', testIdKey);

  return (
    <ListItem $isActive={isActive} {...testId('root').testIdAttributes} style={style}>
      {!!leftComponent && (
        <ListItemIconStyled {...testId('left-icon').testIdAttributes}>{leftComponent}</ListItemIconStyled>
      )}
      <ListItemText {...testId('text').testIdAttributes}>{contentComponent}</ListItemText>
      {!!rightComponent && <ListItemIcon {...testId('right-icon').testIdAttributes}>{rightComponent}</ListItemIcon>}
    </ListItem>
  );
};
