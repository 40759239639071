import React from 'react';
import { ModalDrawerProps } from './ModalDrawer.types';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';

interface ModalDrawerContextValue {
  onClose: ModalDrawerProps['onClose'];
  testId: ReturnType<typeof useInstanceId>;
}

export const ModalDrawerContext = React.createContext<ModalDrawerContextValue>({
  onClose: () => {},
  testId: () => ({ testIdAttributes: {} }),
});
