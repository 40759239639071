/* eslint-disable @typescript-eslint/no-var-requires */
export const awbLogo = require('./logo/awb/generic.png');
export const bulexLogo = require('./logo/bulex/generic.svg');
export const glowWormLogo = require('./logo/glow-worm/generic.png');
export const hermannSaunierDuvalLogo = require('./logo/hermannsaunierduval/generic.png');
export const prothermLogo = require('./logo/protherm/generic.png');
export const saunierduvalLogo = require('./logo/saunierduval/generic.svg');
export const vaillantLogo = require('./logo/vaillant/generic.svg');

export const awbLogoNL = require('./logo/awb/AWB_2D_Claim_NL.png');
export const bulexLogoBE = require('./logo/bulex/Bulex_2D_horiz_Claim_BE.png');
export const glowWormLogoGB = require('./logo/glow-worm/GW_2D_Claim.png');
export const saunierduvalLogoES = require('./logo/saunierduval/SD_2D_horiz_Claim_ES.png');
export const saunierduvalLogoFR = require('./logo/saunierduval/SD_2D_horiz_Claim_FR.png');
export const saunierduvalLogoIT = require('./logo/saunierduval/SD_2D_horiz_Claim_IT.png');
export const saunierduvalLogoRO = require('./logo/saunierduval/SD_2D_horiz_Claim_RO.png');

export const vaillantLogoAT = require('./logo/vaillant/de_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoBE = require('./logo/vaillant/be-fr_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoCH = require('./logo/vaillant/ch_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoCZ = require('./logo/vaillant/cz_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoDE = require('./logo/vaillant/de_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoDK = require('./logo/vaillant/generic.png');
export const vaillantLogoEE = require('./logo/vaillant/generic.png');
export const vaillantLogoES = require('./logo/vaillant/es_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoFI = require('./logo/vaillant/fi_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoFR = require('./logo/vaillant/fr_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoGB = require('./logo/vaillant/uk_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoHR = require('./logo/vaillant/hr_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoHU = require('./logo/vaillant/hu_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoIT = require('./logo/vaillant/it_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoNL = require('./logo/vaillant/be-fl_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoNO = require('./logo/vaillant/no_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoPL = require('./logo/vaillant/pl_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoRO = require('./logo/vaillant/ro_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoSE = require('./logo/vaillant/se_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoSK = require('./logo/vaillant/sk_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoTR = require('./logo/vaillant/tr_Vaillant_Logo_claim_1_RGB.png');
export const vaillantLogoUA = require('./logo/vaillant/ua_Vaillant_Logo_claim_1_RGB.png');

export const awbLoginSM = require('./login/Screen_MyV_SD_SM.png');
export const awbLoginMD = require('./login/Screen_MyV_SD_MD.png');
export const awbLoginLG = require('./login/Screen_MyV_SD_LG.png');
export const awbLoginXL = require('./login/Screen_MyV_SD_XL.png');

export const bulexLoginSM = require('./login/Screen_MyV_SD_SM.png');
export const bulexLoginMD = require('./login/Screen_MyV_SD_MD.png');
export const bulexLoginLG = require('./login/Screen_MyV_SD_LG.png');
export const bulexLoginXL = require('./login/Screen_MyV_SD_XL.png');

export const glowWormLoginSM = require('./login/Screen_MyV_SD_SM.png');
export const glowWormLoginMD = require('./login/Screen_MyV_SD_MD.png');
export const glowWormLoginLG = require('./login/Screen_MyV_SD_LG.png');
export const glowWormLoginXL = require('./login/Screen_MyV_SD_XL.png');

export const hermannSaunierDuvalLoginSM = require('./login/Screen_MyV_SD_SM.png');
export const hermannSaunierDuvalLoginMD = require('./login/Screen_MyV_SD_MD.png');
export const hermannSaunierDuvalLoginLG = require('./login/Screen_MyV_SD_LG.png');
export const hermannSaunierDuvalLoginXL = require('./login/Screen_MyV_SD_XL.png');

export const prothermDuvalLoginSM = require('./login/Screen_MyV_SD_SM.png');
export const prothermDuvalLoginMD = require('./login/Screen_MyV_SD_MD.png');
export const prothermDuvalLoginLG = require('./login/Screen_MyV_SD_LG.png');
export const prothermDuvalLoginXL = require('./login/Screen_MyV_SD_XL.png');

export const saunierduvalLoginSM = require('./login/Screen_MyV_SD_SM.png');
export const saunierduvalLoginMD = require('./login/Screen_MyV_SD_MD.png');
export const saunierduvalLoginLG = require('./login/Screen_MyV_SD_LG.png');
export const saunierduvalLoginXL = require('./login/Screen_MyV_SD_XL.png');

export const vaillantLoginSM = require('./login/Screen_MyV_VAI_SM.png');
export const vaillantLoginMD = require('./login/Screen_MyV_VAI_MD.png');
export const vaillantLoginLG = require('./login/Screen_MyV_VAI_LG.png');
export const vaillantLoginXL = require('./login/Screen_MyV_VAI_XL.png');
