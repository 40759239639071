import { Accordion, AccordionDetails, AccordionSummary, Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { CheckboxSelect } from '../../../components/molecules/CheckboxSelect';
import { features, useTranslation } from '@vaillant-professional-ui/pui-frontend-common';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon02501VgDown01 } from '@vaillant-professional-ui/component-libs-web';
import { MaxDatapointsTooltip } from '../MaxDatapointsTooltip';

interface CategoriesProps {
  categories: NonNullable<features.historyCharts.historyChartsCard.ViewData['dataProps']>['categories'];
  freeSlotsAllowed: number;
  onToggleTimeSeries: (timeSeries: string[], isEnabled: boolean) => void;
  isTimeSeriesEnabled: (id: string) => boolean;
  maxTimeSeries: number;
}

const usePresenter = ({
  categories,
  isTimeSeriesEnabled,
}: Pick<CategoriesProps, 'categories' | 'isTimeSeriesEnabled'>) => {
  const t = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const shouldCollapseCategories = isMobile && categories.length > 2;

  const accordionTitle = t('SG_TIME_SERIES_FILTER_BY_DATAPOINTS');

  const totalPossibleTimeSeries = categories.reduce(
    (accumulated, category) => accumulated + category.timeSeries.length,
    0,
  );
  const selectedTimeSeriesLength = categories.reduce(
    (accumulated, category) => accumulated + category.timeSeries.filter((ts) => isTimeSeriesEnabled(ts.id)).length,
    0,
  );

  const { hasDismissedMaxDatapointsWarning, setHasDismissedMaxDatapointsWarning } =
    features.historyCharts.historyChartsCard.useCaseMaxDatapointsTooltip();

  const [tooltipAnchor, setTooltipAnchor] = React.useState<HTMLElement | null>(null);

  const openTooltip = (event: React.MouseEvent<HTMLElement>) =>
    !hasDismissedMaxDatapointsWarning && setTooltipAnchor(event.currentTarget);

  const closeTooltip = () => setTooltipAnchor(null);

  const dismissWarning = () => {
    setHasDismissedMaxDatapointsWarning(true);
    closeTooltip();
  };

  return {
    shouldCollapseCategories,
    accordionTitle,
    totalPossibleTimeSeries,
    selectedTimeSeriesLength,
    openTooltip,
    closeTooltip,
    tooltipAnchor,
    dismissWarning,
  };
};

export const Categories: React.FC<CategoriesProps> = ({
  categories,
  freeSlotsAllowed,
  onToggleTimeSeries,
  isTimeSeriesEnabled,
  maxTimeSeries,
}) => {
  const {
    shouldCollapseCategories,
    accordionTitle,
    totalPossibleTimeSeries,
    selectedTimeSeriesLength,
    openTooltip,
    closeTooltip,
    tooltipAnchor,
    dismissWarning,
  } = usePresenter({
    categories,
    isTimeSeriesEnabled,
  });

  const checkboxes = categories.map((category, index) => {
    return (
      <CheckboxSelect
        key={index}
        label={category.title}
        freeSlotsAllowed={freeSlotsAllowed}
        onChange={onToggleTimeSeries}
        onDisabledClick={openTooltip}
        options={category.timeSeries.map((ts) => ({
          id: ts.id,
          label: ts.title,
          checked: isTimeSeriesEnabled(ts.id),
          color: ts.color,
        }))}
      />
    );
  });

  return (
    <>
      <MaxDatapointsTooltip
        onClose={closeTooltip}
        anchor={tooltipAnchor}
        maxTimeSeries={maxTimeSeries}
        onDontShowAgain={dismissWarning}
      />
      {shouldCollapseCategories ? (
        <Accordion>
          <AccordionSummary expandIcon={<Icon02501VgDown01 color='primary.main' height={32} width={32} />}>
            <Typography variant='h3'>{accordionTitle}</Typography>
            <Box ml={2}>
              <Typography
                variant='h3'
                color='grey.500'
              >{`(${selectedTimeSeriesLength}/${totalPossibleTimeSeries})`}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexDirection='column'>
              {checkboxes}
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box
          display='flex'
          flexWrap={{ xs: 'nowrap', sm: 'wrap' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={{ xs: 0, sm: 4 }}
        >
          {checkboxes}
        </Box>
      )}
    </>
  );
};
