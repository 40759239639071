import isNil from 'lodash/isNil';
import { zoneNameV2 } from '../../../selectors/control';
import { TranslationFnc } from '../../../typings/models';
import {
  DeviceDetailsResponseTli,
  TliDhwTimeWindow as TliDhwTimeWindowResponse,
  TliHeatingTimeWindow as TliHeatingTimeWindowResponse,
  TliHeatingTimeWindowSchedule,
  TliZone as TliZoneResponse,
  printableUnit,
} from '../../apis/maintenance/v2';
import {
  TliDhwTimeWindow,
  TliHeatingTimeWindow,
  TliScheduleDay,
  TliSchedulerPresentationData,
  TliSchedulerZonePresentationData,
  TliZone,
} from './TliScheduler.types';
import { NumberValueUnit } from '../../apis/maintenance/v2/maintenance.types';

const allWeekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

const tliHeatingTimeWindowMapper = (heatingTimeWindows: TliHeatingTimeWindowResponse[]): TliHeatingTimeWindow[] =>
  heatingTimeWindows.map((timeWindow) => {
    if (
      !timeWindow ||
      isNil(timeWindow.start?.value) ||
      isNil(timeWindow.end?.value) ||
      isNil(timeWindow.set_point?.value)
    ) {
      throw new Error('Time window has missing required values');
    }
    return {
      start: timeWindow.start.value,
      end: timeWindow.end.value,
      temperatureTarget: timeWindow.set_point.value,
    };
  });

type TimeWindowScheduleKey = Exclude<keyof TliHeatingTimeWindowSchedule, 'config'>;
type TimeWindowScheduleValue = Required<TliHeatingTimeWindowSchedule>[TimeWindowScheduleKey];
type TimeWindowEntry = [TimeWindowScheduleKey, TimeWindowScheduleValue];

const getTemperatureUnit = (zone: TliZoneResponse) => {
  if (!zone.heating?.schedule) return '';

  const value = Object.entries(zone.heating.schedule)
    .filter<TimeWindowEntry>((entry): entry is TimeWindowEntry => entry[0] !== 'config')
    .flatMap((v) => v[1])
    .flatMap<NumberValueUnit | undefined>((v) => v?.set_point?.unit)[0];

  return printableUnit(value);
};

const tliDhwTimeWindowMapper = (dhwTimeWindows: TliDhwTimeWindowResponse[]): TliDhwTimeWindow[] =>
  dhwTimeWindows.map((timeWindow) => {
    if (!timeWindow || isNil(timeWindow.start.value) || isNil(timeWindow.end.value)) {
      throw new Error('Time window has missing required values');
    }
    return {
      start: timeWindow.start.value,
      end: timeWindow.end.value,
    };
  });

const hasMetaDataForZones = (
  presentationData?: TliSchedulerPresentationData<TliZone> | null,
): presentationData is TliSchedulerPresentationData<TliSchedulerZonePresentationData> | null =>
  !presentationData ||
  presentationData.zoneSchedules.every(
    (zone) =>
      !isNil(zone.maxSlotsPerDay) &&
      !isNil(zone.stepSize) &&
      !isNil(zone.minTargetTemperature) &&
      !isNil(zone.maxTargetTemperature) &&
      !isNil(zone.targetTemperatureStepSize),
  );

const getPresentationData = (
  tliData: DeviceDetailsResponseTli,
  weekdays: string[],
  t: TranslationFnc,
): TliSchedulerPresentationData<TliZone> | null => {
  if (!tliData.zones) {
    return null;
  }

  const createTimeslotsForHeatingDay = (
    weekday: keyof TliHeatingTimeWindowSchedule,
    zone: TliZoneResponse,
  ): TliScheduleDay<TliHeatingTimeWindow> => {
    const schedulesResponse = zone.heating?.schedule?.[weekday];

    return {
      key: weekday,
      label: weekdays[allWeekDays.indexOf(weekday)],
      values: schedulesResponse ? tliHeatingTimeWindowMapper(schedulesResponse) : [],
    };
  };

  const zoneSchedules = tliData.zones
    .filter((zone) => !!zone?.heating?.schedule)
    .map((zone) => {
      const dayProps =
        zone._actions?.replace_schedule?.schema?.properties?.centralHeating?.properties?.heatingSchedules?.properties
          ?.zones?.items?.properties?.mon?.items?.properties;

      const schedule = allWeekDays.map((weekDay) =>
        createTimeslotsForHeatingDay(weekDay as keyof TliHeatingTimeWindowSchedule, zone),
      );

      return {
        index: zone.index,
        name: zoneNameV2(zone, t),
        targetTemperatureUnit: getTemperatureUnit(zone),
        minTargetTemperature: dayProps?.targetRoomTemperature?.minimum,
        maxTargetTemperature: dayProps?.targetRoomTemperature?.maximum,
        targetTemperatureStepSize: dayProps?.targetRoomTemperature?.multipleOf,
        minSlotsPerDay: zone.heating?.schedule?.config?.min_slots_per_day?.value,
        maxSlotsPerDay: zone.heating?.schedule?.config?.max_slots_per_day?.value,
        stepSize: dayProps?.start?.multipleOf,
        schedule,
      };
    });

  const dhwSchedule = {
    maxSlotsPerDay: tliData.domestic_hot_water?.[0]?.schedule?.config?.max_time_slots_per_day?.value ?? 3,
    stepSize:
      tliData.domestic_hot_water?.[0]?._actions?.replace_schedule?.schema?.properties?.domesticHotWater?.properties
        ?.hotWaterSchedule?.properties?.mon?.items?.properties?.start?.multipleOf ?? 10,
    schedule: allWeekDays.map((weekDay, index) => {
      const schedulesResponse = tliData.domestic_hot_water?.[0]?.schedule?.[weekDay] ?? [];

      return {
        key: weekDay,
        label: weekdays[index],
        values: tliDhwTimeWindowMapper(schedulesResponse),
      };
    }),
  };

  return { zoneSchedules, dhwSchedule };
};

export { getPresentationData, hasMetaDataForZones };
