import * as React from 'react';
import { Translated, useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { ChipProps } from './Chip.types';
import { Chip as ChipBase } from './Chip.styles';

export const Chip: React.FunctionComponent<ChipProps> = ({ disabled, testIdKey, size = 'medium', ...rest }) => {
  const testId = useInstanceId('chip', testIdKey);

  return (
    <Translated
      {...rest}
      {...testId().testIdAttributes}
      component={<ChipBase disabled={disabled} size={size} />}
      i18nRenderOptions={{
        translationProp: 'label',
      }}
    />
  );
};
