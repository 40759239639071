import { Button, ModalDrawer } from '@vaillant-professional-ui/component-libs-web';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { EmfDeviceSelection } from './EmfDeviceSelection';
import { EmfDeviceSelectionDialogProps } from './EmfDeviceSelection.types';

export const EmfDeviceSelectionDrawer = ({ isOpen, onClose, ...rest }: EmfDeviceSelectionDialogProps) => {
  const t = hooks.useTranslation();

  return (
    <ModalDrawer anchor='right' open={isOpen} onClose={onClose} testIdKey='emfDeviceSelection'>
      <ModalDrawer.Title>{t('SG_INSPECTOR_PRODGROUP_TITLE')}</ModalDrawer.Title>
      <ModalDrawer.Content>
        <EmfDeviceSelection {...rest} />
      </ModalDrawer.Content>
      <ModalDrawer.Footer>
        <Button variant='outlined' onClick={onClose} i18nKey='SG_CLOSE' testIdKey='close' />
      </ModalDrawer.Footer>
    </ModalDrawer>
  );
};
