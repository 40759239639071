import React, { useRef } from 'react';

import { Box, Container } from '@mui/material';
import { PageHeader, StatusCard } from '@vaillant-professional-ui/component-libs-web';
import { hooks, models } from '@vaillant-professional-ui/pui-frontend-common';
import { useFirebase } from '../../../providers/firebase';
import { AddCustomerButton, AddCustomerButtonActions } from '../../AddCustomer/AddCustomerButton';
import { CustomerCards } from '../../CustomerCard/CustomerCards';
import { CustomerTable } from '../../CustomerTable';
import { LoyaltyRegistrationTable } from '../../LoyaltyRegistrationTable';
import { PermissionGate } from '../../PermissionGate';
import { SearchTextField } from '../../SearchTextField';
import { CustomerTableContainer } from './CustomerListPage.styles';
import { CustomerListPageProps } from './CustomerListPage.types';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';

export const CustomerListPage: React.FC<React.PropsWithChildren<CustomerListPageProps>> = ({
  customers,
  hasNoCustomers,
  loyaltyRegistrations,
  query,
  setQuery,
  shouldShowLoyaltyRegistrations,
  shouldShowAddCustomerInHeader,
  shouldShowAsCards,
  shouldShowAsTable,
  hasNoSearchResult,
  shouldShowPageHeader,
  sorting,
}) => {
  const t = hooks.useTranslation();

  const [boilerSerialNumber, setBoilerSerialNumber] = React.useState<string | null>(null);
  const addCustomerRef = useRef<AddCustomerButtonActions>();
  const firebaseService = useFirebase();

  const testId = useInstanceId('list', 'customerList');

  const openAddCustomerWithBoilerSerialNumber = (loyaltyRegistration: models.LoyaltyRegistration) => {
    setBoilerSerialNumber(loyaltyRegistration.serialNumber);
    if (addCustomerRef.current) {
      addCustomerRef.current.openAddCustomer();
    }
    firebaseService.logUserAction('openRequestConsent');
  };

  return (
    <Box width={'100%'} {...testId('root').testIdAttributes}>
      {shouldShowPageHeader && (
        <PageHeader
          testIdKey={'customerList'}
          i18nKey='SG_JOBLIST_TITLE'
          rightComponent={
            shouldShowAddCustomerInHeader && (
              <Box mx={5} my='auto' sx={{ display: { xs: 'none', sm: 'block' } }}>
                <AddCustomerButton
                  testId='addDevice_customerListDesktop'
                  ref={addCustomerRef}
                  boilerSerialNumber={boilerSerialNumber}
                />
              </Box>
            )
          }
        />
      )}
      <Box display='flex' flexDirection='column'>
        {hasNoCustomers ? (
          <Container maxWidth='md'>
            <StatusCard type='info' title={t('SG_NO_CUSTOMERS_TITLE')} text={t('SG_NO_CUSTOMERS_TEXT')} variant='big'>
              <AddCustomerButton
                testId='addDevice_noCustomers'
                ref={addCustomerRef}
                boilerSerialNumber={boilerSerialNumber}
              />
            </StatusCard>
          </Container>
        ) : (
          <>
            <SearchTextField query={query} setQuery={setQuery} placeholder={t('SG_SEARCH_CUSTOMER')} />
            {shouldShowAsCards && (
              <CustomerCards hasNoSearchResult={hasNoSearchResult} markText={query} customers={customers} />
            )}
            {shouldShowAsTable && (
              <CustomerTableContainer>
                <CustomerTable
                  hasNoSearchResult={hasNoSearchResult}
                  customers={customers}
                  sorting={sorting}
                  query={query}
                  height={`calc(${shouldShowLoyaltyRegistrations ? 80 : 100}vh - 400px)`}
                />
              </CustomerTableContainer>
            )}
          </>
        )}
      </Box>
      <Box display='flex' flexDirection='column'>
        <PermissionGate requiredPermissions={['ADD_CUSTOMER']}>
          {shouldShowLoyaltyRegistrations && (
            <Box flex={1} mt={24}>
              <LoyaltyRegistrationTable
                loyaltyRegistrations={loyaltyRegistrations}
                openAddCustomer={openAddCustomerWithBoilerSerialNumber}
              />
            </Box>
          )}
        </PermissionGate>
      </Box>
    </Box>
  );
};
