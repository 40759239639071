import React from 'react';
import { Box } from '@mui/material';
import { FooterLink } from './Footer.styles';

interface TextLinksProps {
  country: string;
}

export const TextLinks: React.FC<React.PropsWithChildren<TextLinksProps>> = ({ country }) => {
  return (
    <>
      <Box mr={[0, 8]} mb={[3, 0]}>
        <FooterLink
          to={`/imprint/${country}`}
          firebaseProps={{ identifier: 'imprint', eventTrigger: 'menu' }}
          testID={'linkToImprint'}
          id='SG_IMPRINT_TITLE'
        />
      </Box>
      <Box mr={[0, 8]} mb={[3, 0]}>
        <FooterLink
          to={`/terms/${country}`}
          firebaseProps={{ identifier: 'termsAndConditions', eventTrigger: 'menu' }}
          testID={'linkToTerms'}
          id='SG_TERMSANDCONDITIONS_TITLE'
        />
      </Box>
      <Box mr={[0, 0, 8]} mb={[3, 0]}>
        <FooterLink
          to={`/privacy-policy/${country}`}
          firebaseProps={{ identifier: 'privacyPolicy', eventTrigger: 'menu' }}
          testID={'linkToPrivacyPolicy'}
          id='SG_TERMS_TEXT_ITEM1'
        />
      </Box>
    </>
  );
};
