import { Box, BoxProps, styled } from '@mui/material';
import { ThemeVariation } from '@vaillant-professional-ui/component-libs-common';
import { Grid, GridProps } from '../../atoms/Grid';
import { Button } from '../../atoms/Button';
import { assets } from './IdmLogin.assets';
import { Typography } from '../../atoms/Typography';

export const ContentContainer = styled(Grid)<GridProps & { $themeName: ThemeVariation }>`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 0;
  padding: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-wrap: nowrap;
    flex-grow: 1;
  }

  background-color: ${({ theme, $themeName }) =>
    $themeName === 'green' ? theme.palette.common.white : theme.palette.grey['300']};
`;

export const ContainerLeft = styled(Grid)<GridProps & { $themeName: ThemeVariation }>`
  background-image: ${({ $themeName }) => `url(${$themeName === 'green' ? assets.bgGreen : assets.bgRed})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  object-fit: contain;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
`;

export const ContainerRight = styled(Grid)`
  max-width: 530px;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: nowrap;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: none;
  }
`;

export const HeroImage = styled('div')<{
  heroImageSm: string;
  heroImageMd: string;
  heroImageLg: string;
  heroImageXl: string;
}>`
  width: 90%;
  height: 100%;
  background-image: ${({ heroImageSm }) => `url(${heroImageSm})`};
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  margin-bottom: ${({ theme }) => theme.spacing(12)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-height: 300px;
    background-image: ${({ heroImageMd }) => `url(${heroImageMd})`};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    min-height: 500px;
    background-image: ${({ heroImageMd }) => `url(${heroImageMd})`};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    background-position: center 20%;
    min-height: auto;
    margin-bottom: 0;
    background-image: ${({ heroImageLg }) => `url(${heroImageLg})`};
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    background-image: ${({ heroImageXl }) => `url(${heroImageXl})`};
  }
`;

export const TitleWrapper = styled(Box)<BoxProps & { $themeName: ThemeVariation }>`
  display: flex;
  flex-grow: 0;
  height: ${({ theme }) => theme.spacing(24)};
  min-height: ${({ theme }) => theme.spacing(24)};
  color: black;
  justify-content: flex-end;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    color: ${({ $themeName }) => ($themeName === 'green' ? 'white' : 'black')};
    height: ${({ theme }) => theme.spacing(40)};
    min-height: ${({ theme }) => theme.spacing(40)};
  }
`;

export const LeftTitle = styled(Typography)`
  max-width: 80%;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: 60%;
  }
`;

export const AlignedTypography = styled(Typography)`
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    text-align: left;
  }
`;

export const AppStoreBadge = styled('img')`
  height: 40px;
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

export const PlayStoreBadge = styled('img')`
  height: 40px;
`;

export const LoginButton = styled(Button)`
  min-width: 200px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 255px;
  }
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacing(6)};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacing(10)};
  }
`;

export const DemoButton = styled(Button)`
  min-width: 200px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 255px;
  }
`;
