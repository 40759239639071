import { IconProps } from '@vaillant-professional-ui/component-libs-web';
import { IIconLabelProps } from '../IconLabel/IconLabel.types';
import { EmfCategory } from './EmfChartTitleCard.types';
import { Icon00100VgHeating01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon00300VgDomesticHotWater01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon02000VgCooling01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon13000VgElectric01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon06600VgEcoMode01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon00500VgBurner01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon16100VgSolarCollector01 } from '@vaillant-professional-ui/component-libs-web';

export const emfCategoryIconMap: Record<EmfCategory, React.FunctionComponent<IconProps>> = {
  heatingGenerated: Icon00100VgHeating01,
  dhwGenerated: Icon00300VgDomesticHotWater01,
  coolingGenerated: Icon02000VgCooling01,
  electricalConsumed: Icon13000VgElectric01,
  environmentalYield: Icon06600VgEcoMode01,
  primaryConsumed: Icon00500VgBurner01,
  solarYield: Icon16100VgSolarCollector01,
};

export const iconLabelIconMap: Record<string, IIconLabelProps['icon']> = {
  COOLING: '02000-vg-cooling01',
  HEATING: '00100-vg-heating01',
  DOMESTIC_HOT_WATER: '00300-vg-domestic-hot-water01',
};
