import { Box, Hidden, Tooltip } from '@mui/material';
import { useInstanceId, util } from '@vaillant-professional-ui/component-libs-common';
import React from 'react';
import { Typography } from '../../atoms/Typography';
import { WeekdayRowProps } from './ScheduleTable.types';
import { ActionsContainer, Bar, LabelContainer, ScaleContainer, ScaleInner } from './WeekdayRow.styles';
import { Card, CardContent } from '../../molecules/Card';

export const WeekdayRow = <T extends util.TimeWindow>({
  values,
  label,
  onClick,
  getTimeWindowDisplayData,
  onRowClick,
  testIdKey,
}: WeekdayRowProps<T>) => {
  const testId = useInstanceId('weekday-row', testIdKey);

  const isConsecutive = util.areTimeWindowsWithProfile(values);
  return (
    <div
      onClick={() => {
        onRowClick && onRowClick(values, label);
      }}
      style={{ cursor: onRowClick && 'pointer' }}
      {...testId('root').testIdAttributes}
    >
      <Card>
        <CardContent>
          <Box display='flex' flexDirection='row'>
            <LabelContainer width={['64px', '88px']}>
              <Typography variant='caption'>{label}</Typography>
            </LabelContainer>
            <ScaleContainer width={'100%'}>
              <ScaleInner shouldHideBorder={isConsecutive}>
                {values.map((timeWindow) => {
                  const { tooltipText, colors, label } = getTimeWindowDisplayData(timeWindow);
                  return (
                    <Tooltip title={tooltipText} arrow key={timeWindow.start}>
                      <Bar
                        start={timeWindow.start}
                        end={timeWindow.end}
                        isClickable={!!onClick}
                        backgroundColor={colors.color}
                        onClick={() => !!onClick && onClick(timeWindow)}
                        isConsecutive={isConsecutive}
                      >
                        <Box color={colors.textColor}>
                          <Hidden smDown>
                            <Typography
                              variant='caption'
                              color='inherit'
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {label}
                            </Typography>
                          </Hidden>
                        </Box>
                      </Bar>
                    </Tooltip>
                  );
                })}
              </ScaleInner>
              <ActionsContainer>&nbsp;</ActionsContainer>
            </ScaleContainer>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
