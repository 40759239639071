import React from 'react';
import { RuntimeContext, useAppConfig } from '../../config';

export type FeatureName = 'system-settings' | 'time-series' | 'guided-services-available' | 'guided-services-paid';

// Maybe useful gates in future:
// * particular user ids
// * certain roles
// * a subset of countries
// * check for a certain value in LocalStorage/AsyncStorage
// * ···
export type FeatureConfig = Partial<Record<FeatureName, { runtimeContexts: RuntimeContext[] }>>;

export interface FeatureContextProps {
  featureConfig: FeatureConfig;
}

export interface FeatureContextValues {
  features: FeatureName[];
  setFeatures: (features: FeatureName[]) => void;
}

const FeatureContext = React.createContext<FeatureContextValues>({ features: [], setFeatures: () => {} });

export const FeatureContextProvider: React.FC<React.PropsWithChildren<FeatureContextProps>> = ({
  children = null,
  featureConfig,
}) => {
  const appConfig = useAppConfig();

  const [features, setFeatures] = React.useState<FeatureName[]>(
    Object.entries(featureConfig)
      .map(([featureName, value]) => (value?.runtimeContexts?.includes(appConfig.runtimeContext) ? featureName : null))
      .filter(Boolean) as FeatureName[],
  );

  return <FeatureContext.Provider value={{ setFeatures, features }}>{children}</FeatureContext.Provider>;
};

export interface UseFeatureToggle {
  setFeature: (key: FeatureName, shouldBeEnabled: boolean) => void;
  hasFeature: (key: FeatureName) => boolean;
}

export const useFeatureToggle: () => UseFeatureToggle = () => {
  const { features, setFeatures } = React.useContext(FeatureContext);
  const setFeature = React.useCallback(
    (key: FeatureName, shouldBeEnabled: boolean) => {
      const index = features.findIndex((featureName) => key === featureName);
      if (shouldBeEnabled) {
        if (index === -1) {
          setFeatures([...features, key]);
        }
      } else {
        if (index !== -1) {
          setFeatures(features.slice(0, index).concat(features.slice(index + 1)));
        }
      }
    },
    [features, setFeatures],
  );

  const hasFeature = React.useCallback((key: FeatureName) => features.includes(key), [features]);
  return { hasFeature, setFeature };
};
