import * as React from 'react';
import {
  ConfigurationProvider as ConfigurationProviderBase,
  ConfigurationProviderProps,
} from '@vaillant-professional-ui/component-libs-common';

import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '../themes';

export const ConfigurationProvider: React.FC<React.PropsWithChildren<ConfigurationProviderProps>> = ({
  children,
  ...props
}) => {
  const theme = createTheme(props.themeName);

  return (
    <ConfigurationProviderBase {...props}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ConfigurationProviderBase>
  );
};
