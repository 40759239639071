import { styled, css } from '../../../util/styling';
import { Severity } from '../../molecules/NotificationItem/NotificationItem.types';

export const AlertStyled = styled('div')<{ severity: Severity }>`
  display: flex;
  flex-direction: row;
  ${({ severity, theme }) => {
    switch (severity) {
      case 'success':
        return css`
          background-color: ${theme.palette.primary.lightest};
          '& svg': {
            color: ${theme.palette.primary.main};
          }
        `;
      case 'warning':
        return css`
          background-color: ${theme.palette.warning.lightest};
          '& svg': {
            color: ${theme.palette.common.black};
          }
        `;
      case 'error':
        return css`
          background-color: ${theme.palette.error.main};
          color: ${theme.palette.common.white};
          '& svg': {
            color: 'inherit';
          }
        `;
      case 'info':
        return css`
          background-color: ${theme.palette.common.white};
        `;
    }
  }}
`;
