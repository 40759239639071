import { useSelector } from 'react-redux';
import { useCase } from '../useCase';
import { store } from '../../../../store/store';
import React from 'react';
import { DateTimeRange } from '../../historyChartsCard';
import { useTranslation } from '../../../../libs/hooks';

interface PresenterParams {
  boilerSerialNumber: string;
  chartTabId: string;
  start: Date;
  end: Date;
}

export const useCardsPresenter = ({ boilerSerialNumber, chartTabId, start, end }: PresenterParams) => {
  const t = useTranslation();
  const language = useSelector(store.getLanguage);

  const [zoomRange, setZoomRange] = React.useState<DateTimeRange>([start, end]);
  React.useEffect(() => setZoomRange([start, end]), [start, end]);

  const { data, isLoading, error, refetch } = useCase({ boilerSerialNumber, language });

  const cards = data?.tabs
    ?.find((tab) => tab.id === chartTabId)
    ?.data?.charts?.map((chart) => ({ id: chart.id, title: chart.title, url: chart._links?.self.href }));

  const hasNoData = cards && cards.length === 0;
  const noDataTitle = t('SG_NO_DATA');
  const noDataDescription = t('SG_TIME_SERIES_NO_DATA');

  return { cards, isLoading, error, refetch, zoomRange, setZoomRange, hasNoData, noDataTitle, noDataDescription };
};

export type CardsViewData = ReturnType<typeof useCardsPresenter>;
