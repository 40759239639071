import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { BackLink, PageHeader, ScheduleTableEditor } from '@vaillant-professional-ui/component-libs-web';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { Link } from 'react-router-dom';
import { TliDHWSchedulerPageProps } from './TliDHWSchedulerPage.types';
import Button from '../../Button';
import isEqual from 'lodash/isEqual';

export const TliDHWSchedulerPage: React.FC<React.PropsWithChildren<TliDHWSchedulerPageProps>> = ({
  customerName,
  schedules,
  maxTimeSlotsPerDay,
  timeStepWidthInMinutes,
  onSave,
}) => {
  const t = hooks.useTranslation();
  const [managedSchedules, setManagedSchedules] = useState(schedules);
  const onPressSave = () => {
    onSave(managedSchedules);
  };
  return (
    <Box width={'100%'}>
      {customerName && <PageHeader title={customerName} />}
      <Box mb={8}>
        <BackLink linkProps={{ component: Link, to: '..' }} i18nKey='SG_BACK_TO_TLI_CONTROL' />
      </Box>
      <Box mb={5}>
        <Typography variant='h2'>{t('SG_DHW_TITLE')}</Typography>
      </Box>
      <ScheduleTableEditor
        managedSchedules={managedSchedules}
        setManagedSchedules={setManagedSchedules}
        maxTimeSlotsPerDay={maxTimeSlotsPerDay}
        timeStepWidthInMinutes={timeStepWidthInMinutes}
        testIdKey='dhw-scheduler'
      />
      <Box display='flex' mt='32px' justifyContent='flex-end'>
        <Button
          disabled={isEqual(schedules, managedSchedules)}
          color='primary'
          variant='contained'
          onClick={onPressSave}
        >
          {t('SG_SAVE_CHANGES')}
        </Button>
      </Box>
    </Box>
  );
};
