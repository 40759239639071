import { TranslateFnc } from '@vaillant-professional-ui/component-libs-common';
import { models } from '../..';
import inRange from 'lodash/inRange';

export const getGreetingByTime = (t: TranslateFnc, platformName: string, user?: models.IUser) => {
  if (!user || !user.contact.firstName || !user.contact.lastName) {
    return t('SG_WELCOME_TITLE', { brandname: `${platformName}!` });
  }

  const time = new Date().getHours();
  const templateValues = { customerName: `${user.contact.firstName} ${user.contact.lastName}` };

  if (inRange(time, 12, 17)) {
    return t('SG_DASHBOARD_GREETING_AFTERNOON', templateValues);
  }

  if (inRange(time, 6, 12)) {
    return t('SG_DASHBOARD_GREETING_MORNING', templateValues);
  }

  return t('SG_DASHBOARD_GREETING_EVENING', templateValues);
};
