import { useCallback, useEffect, useState } from 'react';
import { useConfiguration } from '../../meta/ConfigurationProvider';

export const useSpinnerControl = ({
  initialValue,
  unit,
  minimum,
  maximum,
  stepSize,
  digitsAfterPeriod
}: {
  initialValue: number;
  unit: string;
  minimum?: number | null;
  maximum?: number | null;
  stepSize?: number | null;
  digitsAfterPeriod: number;
}): {
  value: number;
  displayValue: string;
  onPressPlus: () => void;
  isPlusDisabled: boolean;
  onPressMinus: () => void;
  isMinusDisabled: boolean;
  resetValue: () => void;
} => {
  const isWriteDisabled = !(minimum && maximum && stepSize);

  const { locale } = useConfiguration();
  const cutOff = useCallback((v: number) => Math.max(Math.min(maximum || 0, v), minimum || 0), [maximum, minimum]);
  const [value, setRawValue] = useState(cutOff(initialValue));

  const setValue = useCallback((v: number) => setRawValue(cutOff(v)), [cutOff]);

  const increaseValue = useCallback(
    (direction: 1 | -1) => {
      const delta = direction * (stepSize || 0);
      const newValue = cutOff(value + delta);
      setValue(newValue);
    },
    [stepSize, cutOff, value, setValue]
  );

  const displayValue = useCallback(
    (v: number) => {
      const numberFormatOptions: Intl.NumberFormatOptions = {
        minimumFractionDigits: digitsAfterPeriod,
        maximumFractionDigits: digitsAfterPeriod
      };
      const formattedNumber = Number(v).toLocaleString(locale, numberFormatOptions);

      return `${formattedNumber} ${unit}`.trim();
    },
    [digitsAfterPeriod, locale, unit]
  );

  const onPressPlus = useCallback(() => increaseValue(1), [increaseValue]);
  const onPressMinus = useCallback(() => increaseValue(-1), [increaseValue]);
  const resetValue = useCallback(() => setValue(initialValue), [initialValue, setValue]);

  useEffect(() => {
    resetValue();
  }, [resetValue]);

  return {
    value,
    displayValue: displayValue(value),
    onPressPlus,
    isPlusDisabled: isWriteDisabled || value >= maximum,
    onPressMinus,
    isMinusDisabled: isWriteDisabled || value <= minimum,
    resetValue
  };
};
