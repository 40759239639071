import React from 'react';

import { useBreakPoint } from '../../../Utils/breakpoint';
import { ChartTooltipMetaProps } from '../ChartTooltip/ChartTooltip.types';
import { breakPointDependentStyles } from './CursorChartTooltip.styles';
import { UseCursorChartTooltipMetaDataConfig } from './CursorChartTooltip.types';

export const useTooltipMetaData = ({
  width,
  centerY,
  centerX,
}: UseCursorChartTooltipMetaDataConfig): ChartTooltipMetaProps => {
  const breakpoint = useBreakPoint();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerWidth = containerRef.current?.clientWidth ?? 150;

  const { fontSize, xOffset, yOffset } = breakPointDependentStyles[breakpoint];

  const position = centerX + containerWidth + xOffset > width ? 'left' : 'right';
  const xPosition = position === 'right' ? centerX : centerX - containerWidth - xOffset;
  const yPosition = centerY + yOffset;
  const yPositionContent = centerY + yOffset;
  const xPositionContent = position === 'right' ? centerX : centerX - containerWidth - xOffset;

  return {
    position,
    xPosition: xPosition < 0 ? 0 : xPosition,
    yPosition,
    yPositionContent,
    xPositionContent: xPositionContent < 0 ? 0 : xPositionContent,
    isArrowVisible: xPosition >= 0,
    fontSize,
    containerWidth,
    containerRef,
  };
};
