export const isTokenExpired = (token: string): boolean => {
  const body = atob(token.split('.')[1]);
  const expiry = JSON.parse(body).exp;

  if (!expiry) {
    throw new Error('Invalid token');
  }

  const currentTime = new Date().getTime() / 1000;
  return currentTime > expiry;
};
