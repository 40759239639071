import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { DataPointCardItemProps } from './DataPointCardItem.types';
import { TypographyContainer, BoxContainer, Card, IconContainer, Label } from './DataPointCardItem.styles';
import { variantMap } from './DataPointCardItem.variants';
import { DataPointCardItemSkeleton } from './DataPointCardItem.skeleton';

export const DataPointCardItem: React.FunctionComponent<DataPointCardItemProps> = ({
  label,
  value,
  unit,
  iconComponent: IconComponent,
  severityLevel = 'info',
  testIdKey,
  variant = 'default',
  isLoading = false,
  ...rest
}) => {
  const testId = useInstanceId('datapointcarditem', testIdKey);
  const variantConfig = variantMap[variant];

  if (isLoading) {
    return <DataPointCardItemSkeleton variant={variant} testIdKey={testIdKey} {...rest} />;
  }

  return (
    <Card $severityLevel={severityLevel} elevation={0} testIdKey={testId('root').key} {...rest}>
      <BoxContainer display='flex' flexDirection='row' alignItems='flex-start'>
        <IconContainer display='flex' mr={1} $severityLevel={severityLevel}>
          <IconComponent
            width={variantConfig.iconSize}
            height={variantConfig.iconSize}
            color='inherit'
            {...testId('icon').testIdAttributes}
          />
        </IconContainer>
        <BoxContainer
          display='flex'
          flexDirection='column'
          alignItems='left'
          mr={1}
          gap={variantConfig.title.gap}
          flexGrow={1}
        >
          <TypographyContainer
            variant={variantConfig.title.variant}
            bold
            color='inherit'
            {...testId('value').testIdAttributes}
          >
            {`${value} ${unit ?? ''}`.trim()}
          </TypographyContainer>
          <Label
            $severityLevel={severityLevel}
            variant={variantConfig.subtitle.variant}
            {...testId('label').testIdAttributes}
          >
            {label}
          </Label>
        </BoxContainer>
      </BoxContainer>
    </Card>
  );
};
