import { TranslationFnc } from '../../../../../typings/models';
import { nonNullable } from '../../../../../util';
import { DeviceDetailsResponseTli } from '../../../../../api/apis/maintenance/v2';
import { createCategory } from '../../../deviceSettings/useCase/model/category';
import { mapNumericMeasuredValue } from './mapMeasuredValue';

export const createBufferCylinder = (response: DeviceDetailsResponseTli, t: TranslationFnc) => {
  return createCategory(
    t('SG_BUFFER_CYLINDER'),
    [
      mapNumericMeasuredValue({
        id: 'dhwMaximumTemperature',
        measuredValue: response.system?.domestic_hot_water?.maximum_temperature,
        mutationId: 'dhwMaximumTemperature',
        action: response.system?._actions?.update_domestic_hot_water_maximum_temperature,
        actionPropertyKey: 'dhwMaximumTemperature',
        t,
      }),
    ].filter(nonNullable),
  );
};
