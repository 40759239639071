import * as React from 'react';
import { useConfiguration } from '../components/meta/ConfigurationProvider';
import { UseInstanceId } from './types';

export const expectToThrow = (func: Function, compareError?: string) => {
  // Even though the error is caught, it still gets printed to the console
  // so we mock that out to avoid the wall of red text.
  jest.spyOn(console, 'error');
  // @ts-ignore
  console.error.mockImplementation(() => {});

  if (compareError) {
    expect(func).toThrowError(compareError);
  } else {
    expect(func).toThrow();
  }

  // @ts-ignore
  console.error.mockRestore();
};

const createTestingProps = (testIdAttributes: string[], value: string) =>
  testIdAttributes.reduce((acc, prop) => ({ ...acc, [prop]: value }), {});

export const useInstanceId: UseInstanceId = (componentKey, parentKey) => {
  const {
    testing: { testIdAttributes },
  } = useConfiguration();
  const fullKey = parentKey ? `${parentKey}-${componentKey}` : null;

  return React.useCallback(
    (componentKey?: string) => {
      const key = `${fullKey}${componentKey ? `-${componentKey}` : ''}`;
      return {
        key: fullKey ? key : undefined,
        testIdAttributes: fullKey ? createTestingProps(testIdAttributes, key) : {},
      };
    },
    [fullKey, testIdAttributes],
  );
};

/** Move from web to here */
export interface VgBaseProps {
  testIdKey?: string;
  isLoading?: boolean;
}

export const withTestAttributes =
  <T extends object>(Component: React.ComponentType<T>, testIdRootKey: string): React.FC<T & VgBaseProps> =>
  ({ testIdKey, ...rest }: T & VgBaseProps) => {
    const testId = useInstanceId(testIdRootKey, testIdKey);
    // @ts-ignore FIXME
    return <Component {...testId('root').testIdAttributes} {...rest} />;
  };
