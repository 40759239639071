import { styled } from '@vaillant-professional-ui/component-libs-web';
import Link from '../Link';

export const ExternalLink = styled('a')`
  font-size: 10px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.grey['400']};
`;

export const InternalLink = styled(Link)`
  font-size: 10px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.grey['400']};
`;
