import React from 'react';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon06501VgCheckConfirm01 } from '@vaillant-professional-ui/component-libs-web';
import { Box, List, ListItem } from '@mui/material';
import { i18nConfig } from '@vaillant-professional-ui/pui-frontend-common';
import Switch from '../Switch';
import { LocaleSelectorProps } from './LocaleSelector.types';

export const LocaleSelector: React.FC<React.PropsWithChildren<LocaleSelectorProps>> = ({
  setLanguage,
  userLanguage,
  browserLanguage,
}) => {
  const isLanguageSetManually = !!userLanguage;

  return (
    <div>
      <Typography
        component='span'
        variant='caption'
        i18nKey='SG_SETTINGS_LGMANUAL'
        color={isLanguageSetManually ? 'text.primary' : 'text.secondary'}
      />
      <Switch
        onChange={(event) => setLanguage(event.target.checked ? browserLanguage : undefined)}
        checked={isLanguageSetManually}
        testIdKey='locale'
      />

      {!!userLanguage && (
        <List>
          {i18nConfig.map((record) => (
            <ListItem
              button
              component='a'
              onClick={() => setLanguage(record.language)}
              key={record.language}
              data-testid={`${record.full}-list-item`}
            >
              {record.language === userLanguage && (
                <Icon06501VgCheckConfirm01 width={16} color='primary.main' testIdKey='selected-language' />
              )}
              <Box pl={record.language === userLanguage ? 2 : 6}>
                <Typography color={record.language === userLanguage ? 'text.primary' : 'text.secondary'}>
                  {record.full}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};
