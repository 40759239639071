import { DatePickerMode } from './DateRangePicker.types';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSameMonth,
  isSameYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

/**
 * Create some timezone aware date-fn functions
 * @see https://stackoverflow.com/a/72372517/146283
 */

// eslint-disable-next-line @typescript-eslint/ban-types
const calcZonedDate = (date: Date, tz: string, fn: Function, options: unknown = null) => {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
};

const zonedFns = {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
};
const zoned = Object.keys(zonedFns).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (date: Date, timezone: string, options: unknown = null) =>
      calcZonedDate(date, timezone, zonedFns[key as keyof typeof zonedFns], options),
  }),
  {},
  // eslint-disable-next-line @typescript-eslint/ban-types
) as Record<keyof typeof zonedFns, Function>;

/**
 * Date format which will be used to display the currently selected date between the arrow buttons
 */
export const getDateFormat = (mode: DatePickerMode) => {
  if (mode === 'DAY' || mode === 'WEEK') {
    return 'PP';
  }
  if (mode === 'MONTH') {
    return 'MMMM yyyy';
  }
  return 'yyyy';
};

/**
 * Date format which will be used to display the currently selected date within the date sheet
 */ export const getHeaderTitleFormat = (mode: DatePickerMode) => {
  if (mode === 'YEAR') {
    return '';
  }
  if (mode === 'MONTH') {
    return 'yyyy';
  }
  return 'MMMM yyyy';
};

const isYearInterval = (startDate: Date, endDate: Date) => {
  return (
    isSameYear(startDate, endDate) &&
    startDate.getDate() === 1 &&
    startDate.getMonth() === 0 &&
    endDate.getDate() === 31 &&
    endDate.getMonth() === 11
  );
};

const isMonthInterval = (startDate: Date, endDate: Date) => {
  return isSameMonth(startDate, endDate) && isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate);
};

const isWeekInterval = (startDate: Date, endDate: Date, locale: Locale) => {
  return isSameDay(startOfWeek(startDate, { locale }), startDate) && isSameDay(endOfWeek(endDate, { locale }), endDate);
};

/**
 * Detects mode for start- and end date
 */
export const detectMode = (
  startDate: Date,
  endDate: Date = startDate,
  locale: Locale,
  timezone: string,
): DatePickerMode | null => {
  const localStartDate = utcToZonedTime(new Date(startDate.getTime()), timezone);
  const localEndDate = utcToZonedTime(new Date(endDate.getTime()), timezone);

  if (isSameDay(localStartDate, localEndDate)) {
    return 'DAY';
  }

  if (isWeekInterval(localStartDate, localEndDate, locale)) {
    return 'WEEK';
  }

  if (isMonthInterval(localStartDate, localEndDate)) {
    return 'MONTH';
  }

  if (isYearInterval(localStartDate, localEndDate)) {
    return 'YEAR';
  }

  return null;
};

/**
 * Returns sanitized date range for given start- and end date.
 * If the mode cannot be derived, return the current week.
 */
export const getValidatedDateRange = (
  startDate: Date,
  endDate: Date = startDate,
  locale: Locale,
  timezone: string,
): [Date, Date] => {
  if (!detectMode(startDate, endDate, locale, timezone)) {
    return [zoned.startOfWeek(startDate, timezone, { locale }), zoned.endOfWeek(startDate, timezone, { locale })];
  }
  return [zoned.startOfDay(startDate, timezone), zoned.endOfDay(endDate, timezone)];
};

const PREVIOUS = -1;
const NEXT = 1;
const CURRENT = 0;
type DIRECTION = 0 | 1 | -1;

/**
 * Returns either the current, previous or next date interval for the given mode.
 */
const getDateInterval = (
  mode: DatePickerMode,
  date: Date,
  locale: Locale,
  timezone: string,
  direction: DIRECTION,
): [startDate: Date, endDate: Date] => {
  if (mode === 'DAY') {
    const newDate = addDays(date, 1 * direction);
    return [zoned.startOfDay(newDate, timezone), zoned.endOfDay(newDate, timezone)];
  }

  if (mode === 'WEEK') {
    const weekStart = zoned.startOfWeek(date, timezone, { locale });
    const newDate = addWeeks(weekStart, 1 * direction);
    return [newDate, zoned.endOfWeek(newDate, timezone, { locale })];
  }

  if (mode === 'MONTH') {
    const newMonth = addMonths(date, 1 * direction);
    return [zoned.startOfMonth(newMonth, timezone), zoned.endOfMonth(newMonth, timezone)];
  }

  const newYear = addYears(date, 1 * direction);
  return [zoned.startOfYear(newYear, timezone), zoned.endOfYear(newYear, timezone)];
};

/**
 * Get previous date interval for given date and mode
 */
export const getPrevInterval = (mode: DatePickerMode, startDate: Date, locale: Locale, timezone: string) =>
  getDateInterval(mode, startDate, locale, timezone, PREVIOUS);

/**
 * Get next date interval for given date and mode
 */
export const getNextInterval = (mode: DatePickerMode, startDate: Date, locale: Locale, timezone: string) =>
  getDateInterval(mode, startDate, locale, timezone, NEXT);

/**
 * Get current date interval for given date and mode
 */
export const getCurrentInterval = (
  mode: DatePickerMode,
  startDate: Date,
  locale: Locale,
  timezone: string,
): [startDate: Date, endDate: Date] => getDateInterval(mode, startDate, locale, timezone, CURRENT);

/**
 * Date sheet header configuration
 */
export const getHeaderConfig = (
  mode: DatePickerMode,
  locale: Locale,
  headerProps: ReactDatePickerCustomHeaderProps,
) => {
  if (mode === 'DAY' || mode === 'WEEK') {
    return {
      title: format(headerProps.date, getHeaderTitleFormat(mode), { locale }),
      decreaseFn: headerProps.decreaseMonth,
      increaseFn: headerProps.increaseMonth,
      decreaseDisabled: headerProps.prevMonthButtonDisabled,
      increaseDisabled: headerProps.nextMonthButtonDisabled,
    };
  }

  return {
    title: mode === 'MONTH' ? format(headerProps.date, getHeaderTitleFormat(mode), { locale }) : '',
    decreaseFn: headerProps.decreaseYear,
    increaseFn: headerProps.increaseYear,
    decreaseDisabled: headerProps.prevYearButtonDisabled,
    increaseDisabled: headerProps.nextYearButtonDisabled,
  };
};
