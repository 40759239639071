import * as React from 'react';
import { TranslatedProps } from './Translated.types';
import { useTranslation } from '../I18nProvider';
import { getChildren } from './Translated.util';

export const useContent = (
  props: Partial<React.PropsWithChildren<TranslatedProps>>,
  children: ReturnType<typeof getChildren>
) => {
  const translate = useTranslation();

  const { i18nKey, i18nOptions, i18nRenderOptions } = props;
  const hasTranslationProp = !!i18nRenderOptions?.translationProp;
  const translationProp = i18nRenderOptions?.translationProp || 'children';

  if (i18nKey) {
    return translate(i18nKey, i18nOptions);
  }

  if (hasTranslationProp) {
    // @ts-ignore not worth the complexity
    return props[translationProp];
  }

  return children;
};
