import React from 'react';
import { Button, Grid, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Box } from '@mui/material';
import Loader from '../ActivitySpinner';
import { RemoveCustomerDialogContentProps } from './RemoveCustomer.types';

const RemoveCustomerDialogContent: React.FC<React.PropsWithChildren<RemoveCustomerDialogContentProps>> = ({
  removeCustomerHeaderLabel,
  cancelButtonText,
  removeButtonText,
  closeDialog,
  removeCustomer,
  isRemovingCustomer,
}) => {
  if (isRemovingCustomer) {
    return <Loader loading={true} />;
  }

  return (
    <Box>
      <Box mb={6}>
        <Grid container direction='row'>
          <Grid item>
            <Typography data-testid={'removeCustomerConfirmText'}>{removeCustomerHeaderLabel}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mb={4} alignContent='center'>
        <Grid container direction='row' justifyContent='center'>
          <Box mr={2}>
            <Button testIdKey='cancel-remove' variant='outlined' color='primary' onClick={closeDialog}>
              {cancelButtonText}
            </Button>
          </Box>
          <Box mr={2}>
            <Button testIdKey='perform-remove' variant='contained' color='primary' onClick={removeCustomer}>
              {removeButtonText}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default RemoveCustomerDialogContent;
