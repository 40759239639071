/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon06501VgCheckConfirm01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon06501vgcheckconfirm01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M266.46 425.2a11.34 11.34 0 0 1-8.74-4.12L150 290.69a11.34 11.34 0 1 1 17.48-14.45l96.83 117.22 128.16-245.63a11.33 11.33 0 1 1 20.1 10.48L276.51 419.1a11.36 11.36 0 0 1-8.87 6 11.66 11.66 0 0 1-1.18.1z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
