import { isUndefined } from 'lodash';
import { DataPoint } from '../api/apis/diagnostics';

export const filterSubset = (dataPoints: DataPoint[]) => (keys: string[]) => {
  return keys
    .map((key) => dataPoints.find((entry) => entry[0] === key))
    .filter((entry) => !isUndefined(entry)) as DataPoint[]; // "as" should not be necessary
};

export const getMonitoringTabData = (dataPoints: DataPoint[]) => {
  const filterDataPoints = filterSubset(dataPoints);

  const tabBoilerDataPoints = filterDataPoints([
    'generatorStatus',
    'modulationLevelPercent',
    'threeWayValve',
    'heatingDemand',
    'averageIgnitionTime',
    'outdoorTemperature',
    'hoursTillService',
  ]);

  const tabHeatingDataPoints = filterDataPoints([
    'centralHeatingFlowTemperature',
    'centralHeatingReturnTemperature',
    'centralHeatingWaterPressure',
    'centralHeatingBlocked',
    'centralHeatingOperatingHours',
    'centralHeatingBurnerStarts',
    'centralHeatingPowerOutput',
  ]);

  const tabDhwDataPoints = filterDataPoints([
    'domesticHotWaterDemand',
    'domesticHotWaterOperatingHours',
    'domesticHotWaterBurnerStarts',
    'domesticHotWaterPowerOutput',
  ]);
  return { tabBoilerDataPoints, tabHeatingDataPoints, tabDhwDataPoints };
};
