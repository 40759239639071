import * as React from 'react';
import { Box } from '@mui/material';
import { useInstanceId, util } from '@vaillant-professional-ui/component-libs-common';
import { TimePickerProps } from './TimePicker.types';
import { HourSelect, MinuteSelect } from './Select';

const getNearestLowerStep = (value: number, step: number) => {
  return Math.floor(value / step) * step + step;
};

const getNearestUpperStep = (value: number, step: number) => {
  return Math.ceil(value / step) * step;
};
const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
export const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  initialValue,
  setTime,
  minimum = '00:00',
  maximum = '24:00',
  testIdKey,
  stepWidthInMinutes,
}) => {
  const testId = useInstanceId('timepicker', testIdKey);

  const [hourInitial, minuteInitial] = util.getHourAndMinute(initialValue);

  const [hour, setHour] = React.useState(hourInitial);
  const [minute, setMinute] = React.useState(minuteInitial);

  const [hourMinimum, minuteMinimum] = util.getHourAndMinute(minimum);
  const [hourMaximum, minuteMaximum] = util.getHourAndMinute(maximum);

  const [minuteSelectMinimum, minuteSelectMaximum] = React.useState(
    hour <= hourMinimum ? getNearestUpperStep(minuteMinimum, stepWidthInMinutes) : 0,
  );

  const [maximumMinute, setMaximumMinute] = React.useState(
    hour >= hourMaximum ? getNearestLowerStep(minuteMaximum, stepWidthInMinutes) : 59,
  );

  React.useEffect(() => {
    minuteSelectMaximum(hour <= hourMinimum ? getNearestUpperStep(minuteMinimum, stepWidthInMinutes) : 0);
    setMaximumMinute(hour >= hourMaximum ? getNearestLowerStep(minuteMaximum, stepWidthInMinutes) : 59);
  }, [hour, hourMaximum, hourMinimum, minuteMaximum, minuteMinimum, stepWidthInMinutes]);

  React.useEffect(() => {
    const timestamp = hour * 60 + minute;
    const maxTimestamp = util.formatTimeToMinutesSinceMidnight(maximum);
    if (timestamp > maxTimestamp) {
      const [h, m] = util.getHourAndMinute(maximum);
      setHour(h);
      setMinute(m);
      return;
    }
    if (hourInitial !== hour || minuteInitial !== minute) {
      setTime(util.formatTimeFromMinutesSinceMidnight(hour * 60 + minute) as util.TimeString);
    }
  }, [hour, hourInitial, maximum, minute, minuteInitial, setTime]);

  return (
    <Box
      {...testId('root').testIdAttributes}
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
    >
      <HourSelect
        selectedValue={clamp(hour, hourMinimum, hourMaximum)}
        setSelectedValue={setHour}
        minimum={hourMinimum}
        maximum={hourMaximum}
        testIdKey={testId('hour').key}
      />
      :
      <MinuteSelect
        selectedValue={
          hour >= hourMaximum
            ? Math.min(minute, minuteMaximum)
            : hour <= hourMinimum
            ? Math.max(minuteMinimum, minute)
            : minute
        }
        setSelectedValue={setMinute}
        stepWidthInMinutes={stepWidthInMinutes}
        minimum={minuteSelectMinimum}
        maximum={maximumMinute}
        testIdKey={testId('minute').key}
      />
    </Box>
  );
};
