import ActionTypes from './session.actions.types';
import { ActionTypes as SettingsActionTypes } from '../settings/settings.actions.types';

import { ISessionState } from './session.reducer.types';
import { keepCountry, removeCountries } from './session.util';
import { Role } from '../../libs/permissions';

export const initialState: ISessionState = {
  roles: process.env.NODE_ENV === 'development' ? ['DEVELOPER'] : [],
};

const sessionReducer: (
  state: ISessionState,
  action: { type: string; payload?: Array<Role> | string },
) => ISessionState = (state = initialState, action) => {
  switch (action.type) {
    case SettingsActionTypes.SET_COUNTRY: {
      const countryCode = typeof action.payload === 'string' && action.payload.toUpperCase();

      return {
        ...state,
        roles: countryCode
          ? [...state.roles.filter(removeCountries), `COUNTRY_${countryCode}`]
          : [...state.roles.filter(removeCountries)],
      };
    }

    case ActionTypes.SET_ROLES: {
      const roles = [...(action.payload as Array<Role>)];
      return { ...state, roles };
    }

    case 'APP/RESET': {
      return { ...initialState, roles: [...initialState.roles, ...state.roles.filter(keepCountry)] };
    }

    default:
      return state;
  }
};

export const getRoles = (state: ISessionState) => state.roles;
export default sessionReducer;
