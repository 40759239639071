import React from 'react';

import { Box } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { ChartLegendProps } from './ChartLegend.types';

export const ChartLegend: React.FC<ChartLegendProps> = ({ data }) => {
  return (
    <Box display='flex' gap={[6, 12]}>
      {data.map(({ color, label }, index) => (
        <Box display='flex' alignItems='center' gap={2} key={`${label}-${index}`}>
          <Box borderRadius='50px' width={[16, 16, 16, 24]} height={[6, 6, 6, 10]} bgcolor={color} />
          <Typography variant='caption'>{label}</Typography>
        </Box>
      ))}
    </Box>
  );
};
