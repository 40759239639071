import * as React from 'react';
import { Box } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';
import { NotificationListProps } from './NotificationList.types';
import { NotificationItem } from '../../molecules/NotificationItem';
import { TimeGroupStyled } from './NotificationList.styles';

export const NotificationList: React.FunctionComponent<NotificationListProps> = ({
  onCloseNotification,
  onClickNotification,
  data,
  testIdKey,
}) => {
  const testId = useInstanceId('notificationlist', testIdKey);
  return (
    <div {...testId('root').testIdAttributes}>
      {data.map((block, blockIndex) => {
        return (
          <div key={blockIndex} {...testId(`block-${blockIndex}`).testIdAttributes}>
            <Box pl={2} mb={4}>
              <Typography variant='h4' bold={true}>
                {block.title.toUpperCase()}
              </Typography>
            </Box>
            {block.timeGroups.map((timeGroup, index) => {
              return (
                <TimeGroupStyled
                  key={index}
                  className={`timeGroup`}
                  {...testId(`block-${blockIndex}-timegroup-${index}`).testIdAttributes}
                >
                  <Box pl={2} mb={1}>
                    <Typography
                      variant='smallCopy'
                      color='text.secondary'
                      testIdKey={testId(`block-${blockIndex}-timegroup-${index}-title`).key}
                    >
                      {timeGroup.title.toUpperCase()}
                    </Typography>
                  </Box>
                  {timeGroup.notifications.map((notification, notificationIndex) => {
                    const clickFunction = onClickNotification ? () => onClickNotification(notification.id) : undefined;
                    const closeFunction = onCloseNotification ? () => onCloseNotification(notification.id) : undefined;

                    return (
                      <div key={notification.id} className={`${notification.isRead ? 'hover' : ''}`}>
                        <NotificationItem
                          testIdKey={testId(`item-${notificationIndex}`).key}
                          onClick={clickFunction}
                          onClose={closeFunction}
                          severity={notification.isRead ? 'info' : 'success'}
                          {...notification}
                        />
                      </div>
                    );
                  })}
                </TimeGroupStyled>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
