import React from 'react';
import { useDispatch } from 'react-redux';
import { api, models, store, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { reportError as _reportError, reportEvent as _reportEvent } from '../../services/Utility';
import { ErrorCode, errorCodes } from '../pages/PermissionDeniedPage';
import { isTokenExpired } from './TokenLogin.util';

/**
 * Login an unauthenticated user via a given authorization token - no authorization server involved
 *
 * Sets the given token as Bearer token for further API requests and retrieves the systems boiler serial number.
 * Also performs all necessary state handling to let the user interact with the web page:
 *  - set country state
 *  - set login state
 *  - set terms to accepted
 *  - set roles according to given parameter
 *  - set user info state (by calling userinfo endpoint)
 *
 * @param country - country to set in global state
 * @param token - authentication token for further API calls
 * @param gatewaySerialNumber - identifier for the requested system
 */
export const useLoginWithToken = (country: models.Country, token: string, gatewaySerialNumber: string) => {
  const { logout, isDelegated, loginDelegated, isAuthenticated } = useAuthContext();
  const dispatch = useDispatch();
  const [errorCode, setErrorCode] = React.useState<ErrorCode | null>(null);
  const [boilerSerialNumber, setBoilerSerialNumber] = React.useState<string>();

  const { refetch: fetchCustomerList } = api.maintenance.v1.useCustomerList({ enabled: false });
  const { refetch: fetchUserInfo } = api.useCases.useGetUserInfo({ enabled: false });

  const reportEvent: typeof _reportEvent = (name, ...rest) => _reportEvent(`delegated-token-${name}`, ...rest);
  const reportError: typeof _reportError = (name, ...rest) => _reportError(`delegated-token-${name}`, ...rest);

  React.useEffect(() => {
    void (async () => {
      try {
        // Step 0: Verify input params
        if (!gatewaySerialNumber) {
          setErrorCode(errorCodes.noGatewaySerialNumber);
          reportEvent('missing_gw_serial_number');
          return;
        }
        try {
          if (isTokenExpired(token)) {
            reportEvent('expired_token');
            setErrorCode(errorCodes.expiredToken);
            return;
          }
        } catch (error) {
          reportEvent('invalid_token', { error: error as string });
          setErrorCode(errorCodes.invalidToken);
          return;
        }

        // Step 1: Logout, if needed
        if (isAuthenticated && !isDelegated) {
          reportEvent('logout');
          await logout(window.location.href);
        }

        // Step 2: Prepare redux state
        reportEvent('prepare-store');
        dispatch(store.setCountry(country.toUpperCase() as models.Country));
        dispatch(store.setCookiesAccepted(true));

        // Step 3: Login using delegation token
        if (!isDelegated) {
          reportEvent('login-delegated');
          await loginDelegated(token);
        }

        // Step 4: Fetch user info, and mark terms as accepted
        reportEvent('fetch-user-info');
        const { data } = await fetchUserInfo();
        const userAccountId = data?.user?.account?.id;
        if (userAccountId) {
          reportEvent('set-terms-accepted');
          dispatch(store.setTermsAccepted(userAccountId));
        }
        reportEvent('fetch-customer-list');
        const { data: customerList } = await fetchCustomerList();
        if (!customerList) {
          throw new Error('Failed to load customer list');
        }

        // Step 5: Find boiler serial number
        reportEvent('find-customer-entry');
        const customerEntry = (customerList || []).find((entry) => {
          return entry.system?.gateway?.serialNumber === gatewaySerialNumber;
        });

        if (customerEntry) {
          reportEvent('set-boiler-serialnumber', { boilerSerialNumber: customerEntry.system.boiler.serialNumber });
          setBoilerSerialNumber(customerEntry.system.boiler.serialNumber);
        } else {
          reportError('no-customer-entry-found', `No customer entry found for gateway ${gatewaySerialNumber}`);
          setErrorCode(errorCodes.customerNotFound);
        }
      } catch (e) {
        setErrorCode(errorCodes.fallback);
        reportError('unknown-error', e as Error);
        console.log(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { boilerSerialNumber, errorCode };
};
