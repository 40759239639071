import { AppBar, Drawer, Toolbar } from '@mui/material';
import { styled } from '@vaillant-professional-ui/component-libs-web';
import Link from '../Link';

export const Container = styled('div')`
  width: 100%;
`;

export const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  position: fixed;

  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 72px;
`;

export const StyledToolbar = styled(Toolbar)`
  width: 100vw;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HomeLink = styled(Link)`
  text-decoration: none;
`;

export const StyledDrawer = styled(Drawer)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    .MuiPaper-root {
      z-index: ${({ theme }) => theme.zIndex.appBar - 1};
    }
  }
`;
