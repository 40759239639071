import { Box, BoxProps } from '@mui/system';
import { styled } from '@vaillant-professional-ui/component-libs-web';

export const ContentContainer = styled(Box)<
  BoxProps & {
    $hasCop: boolean;
  }
>`
  display: grid;
  column-gap: ${({ theme }) => theme.spacing(4)};
  row-gap: ${({ theme }) => theme.spacing(4)};
  grid-template-columns: 1fr;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    grid-template-columns: repeat(${({ $hasCop }) => ($hasCop ? 4 : 3)}, 1fr);
  }
`;

export const CopContainer = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-column: span 2;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-row: span 2;
  }
`;
