import React from 'react';

import { Box } from '@mui/material';

import { SystemDataOverviewContainer } from './SystemDataOverview';
import { SystemMonitoringChartsContainer } from './SystemMonitoringCharts';

export const LegacyChartsTab: React.FC = () => {
  return (
    <>
      <Box display='flex' flexDirection='column' mb={4}>
        <SystemMonitoringChartsContainer />
      </Box>
      {/* Remove this component after the system settings view is available */}
      <SystemDataOverviewContainer />
    </>
  );
};
