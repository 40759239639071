import { TranslateFnc, useSpinnerControl } from '@vaillant-professional-ui/component-libs-common';
import { circuitNameV2 } from '../../../selectors/control';
import { useCustomer } from '../../apis/maintenance/v1';
import { useTli } from '../../apis/maintenance/v2/maintenance.hooks';
import { getWriteParams } from './TliHeatCurve.util';
import {
  useOptimisticPatchHeatCurve,
  useOptimisticPatchMinFlowTemperature,
} from '../TliReadWrite/useOptimisticUpdateAgainstSettingsApi';
import { TliCircuit, printableUnit } from '../../apis/maintenance/v2';
import { getGatewaySerialNumber } from '../../../selectors/customer';
import { CircuitPresentationData } from './TliHeatCurve.types';

const useTliHeatCurveData = ({
  boilerSerialNumber,
  systemId,
  deviceId,
}: {
  boilerSerialNumber: string;
  systemId: string;
  deviceId: string;
}) => {
  const customerQuery = useCustomer({ boilerSerialNumber });

  const tliQuery = useTli(
    { systemId, deviceId },
    {
      staleTime: 1000 * 60 * 15,
      enabled: !!systemId,
    },
  );
  const gatewaySerialNumber = customerQuery.data ? getGatewaySerialNumber(customerQuery.data) : '';

  return { customerQuery, tliQuery, gatewaySerialNumber };
};

const useTliCircuit = ({
  circuitIndex,
  ...useTliHeatCurveDataProps
}: {
  boilerSerialNumber: string;
  systemId: string;
  deviceId: string;
  circuitIndex: number;
}) => {
  const {
    customerQuery: { isLoading: isLoadingCustomer, error: customerError, refetch: refetchCustomer },
    tliQuery: { data: tliData, error: tliError, isLoading: isLoadingTliData, refetch: refetchTli, queryKey },
    gatewaySerialNumber,
  } = useTliHeatCurveData(useTliHeatCurveDataProps);

  const { mutate: patchHeatCurve, error: patchHeatCurveError } = useOptimisticPatchHeatCurve(
    gatewaySerialNumber,
    queryKey,
  );
  const { mutate: patchMinFlowTemperature, error: patchMinFlowTemperatureError } = useOptimisticPatchMinFlowTemperature(
    gatewaySerialNumber,
    queryKey,
  );

  const isLoading = isLoadingCustomer || isLoadingTliData;
  const circuit = tliData?.circuits?.find((circuit) => circuit.index === circuitIndex);
  const error = customerError || tliError || patchMinFlowTemperatureError || patchHeatCurveError;

  const refetch: () => Promise<unknown> = () => Promise.all([refetchCustomer, refetchTli]);

  const patchTliCircuitHeatCurve = (data: { heatCurve: number; minFlowTargetTemperature: number }) => {
    patchHeatCurve({ heatCurve: data.heatCurve, circuitIndex });
    patchMinFlowTemperature({ minFlowTargetTemperature: data.minFlowTargetTemperature, circuitIndex });
  };
  return { patchTliCircuitHeatCurve, refetch, isLoading, circuit, error };
};

const useTliCircuitPresentationData = (t: TranslateFnc, circuit?: TliCircuit): CircuitPresentationData => {
  const spinnerControlHeatCurve = useSpinnerControl({
    initialValue: circuit?.heat_curve?.value ?? 0,
    unit: '',
    digitsAfterPeriod: 2,
    ...getWriteParams(circuit?._actions?.update_heating_curve?.schema?.properties?.heatCurve),
  });

  const spinnerControlMinFlowTargetTemperature = useSpinnerControl({
    initialValue: circuit?.min_flow_target_temperature?.value ?? 0,
    unit: '',
    digitsAfterPeriod: 0,
    ...getWriteParams(
      circuit?._actions?.update_min_flow_target_temperature?.schema?.properties?.minFlowTargetTemperature,
    ),
  });

  return {
    circuitName: circuit ? circuitNameV2(t, circuit) : '',
    minFlowTargetTemperatureUnit: printableUnit(circuit?.min_flow_target_temperature?.unit) ?? '',
    spinnerControlMinFlowTargetTemperature,
    spinnerControlHeatCurve,
  };
};

export { useTliCircuitPresentationData, useTliCircuit };
