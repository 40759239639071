/**
 * Masks a string with provided mask.
 * @constructor
 * @param {number} len - Needed length of a resulting string.
 * @param {string} input - String that needs to be masked.
 * @param {string} mask - Provided mask to be used.
 * @example (6, '12', '867564') => '127564'
 */
export const maskString = (len: number, input: string, mask: string): string => {
  const diff = len - input.length;
  if (diff < 1) {
    return input;
  }
  const neededMask = mask.slice(-diff);
  return input + neededMask;
};

export const stripTrailingBackslashes = (url: string) => url.replace(/\/+$/, '');

export const kebabCase = (str: string): string => {
  const result = str
    .replace(/[\W_]+/g, '-') // Replace all non alphanumeric chars with dash
    .replace(/[A-Z]/g, (match) => '-' + match.toLowerCase()) // Prefix all uppercase letters with '-' and add letter in lowercase
    .replace(/-$/, '') // Remove trailing dash
    .replace(/^(-*)/, ''); // Remove leading dash

  return result;
};
const toTitleCase = (str: string): string => {
  const words = str.trim().toLowerCase().split(' ');
  return words.map((word) => word.charAt(0).toUpperCase() + word.substr(1)).join(' ');
};
const generateRandomString = (stringLength = 5, withLowerCase = false) => {
  let randomString = '';
  // Removed I,L,O, 0 (zero) to avoid ambiguous voucher
  const allCharacters = 'ABCDEFGHJKMNPQRSTUVWXYZ123456789' + (withLowerCase ? 'abcdefghijklmnopqrstuvwxyz' : '');
  while (stringLength--) {
    randomString += allCharacters.substr(Math.floor(Math.random() * allCharacters.length + 1), 1);
  }

  return randomString;
};
export { generateRandomString };
export { toTitleCase };
