import React from 'react';
import { Dashboard } from './Dashboard';
import { features as webFeatures } from '../../index';
import { features as commonFeatures } from '@vaillant-professional-ui/pui-frontend-common';
import { tabMap } from '../../features/dashboard/model';
import { DeviceMap } from '../DeviceMap';
import { DashboardTableContainer, TabsContainer } from './Dashboard.styles';
import { DashboardTable } from './DashboardTable';
import { Box, Container } from '@mui/material';
import {
  BigTabs,
  BigTabsPanel,
  Button,
  Dialog,
  StatusCard,
  Typography,
} from '@vaillant-professional-ui/component-libs-web';
import ActivitySpinner from '../ActivitySpinner';
import { AddButton } from './AddButton';
import { MobileDashboard } from './MobileDashboard';
import { AddCustomerDialog } from '../AddCustomer';
import { ResendConsentContainer as ResendConsent } from '../ResendConsent';
import RemoveCustomerDialogContainer from '../RemoveCustomer/RemoveCustomer.container';

export const DashboardContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    customerToResendConsent,
    groupedCustomers,
    setCustomerToResendConsent,
    shouldShowActivitySpinner,
    shouldShowNoDevices,
    systemsWaitingForActionCount,
    readNotifications,
    formatAddress,
    formatCustomerName,
    getBoilerNomenclature,
    getBoilerMarketingName,
    getBoiler,
  } = commonFeatures.dashboard.usePresenter();

  const {
    setViewMode,
    viewMode,
    addCustomerPreset,
    closeAddCustomer,
    closeResendConsent,
    abortAddCustomer,
    shouldShowNotificationBars,
    shouldShowMapToggle,
    shouldShowAddDeviceButton,
    selectedCustomer,
    setSelectedCustomer,
    onRetryValidation,
    activeTab,
    openAddCustomer,
    openResendConsentModal,
    tableData,
    dtcType,
    tabs,
    navigate,
    t,
    setActiveTab,
    shouldShowAllOk,
    shouldShowTabs,
    isMobile,
    dialogTitle,
    isDialogOpen,
    isRemovingCustomer,
    closeDialog,
    removeCustomer,
    testIdKey,
    cancelButtonText,
    removeButtonText,
    removeCustomerHeaderLabel,
    openRemoveCustomer,
    actions,
  } = webFeatures.dashboard.useWebPresenter({
    groupedCustomers,
    setCustomerToResendConsent,
    shouldShowNoDevices,
    readNotifications,
  });

  const tabsContent = React.useMemo(() => {
    if (activeTab === null) {
      return null;
    }

    if (viewMode === 'map') {
      const customers = tableData[tabMap[activeTab]].map((item) => item._meta.customer);

      return (
        <DeviceMap
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={(v) => setSelectedCustomer(v || '')}
          customers={customers}
          dtcTypes={dtcType}
        />
      );
    }

    if (viewMode === 'list') {
      return (
        <>
          <DashboardTableContainer>
            <DashboardTable
              data={tableData[tabMap[activeTab]]}
              retryValidation={onRetryValidation}
              openRemoveCustomer={openRemoveCustomer}
              openResendConsent={openResendConsentModal}
              getBoilerMarketingName={getBoilerMarketingName}
              getBoiler={getBoiler}
              formatCustomerName={formatCustomerName}
              formatAddress={formatAddress}
            />
          </DashboardTableContainer>
          {tableData[tabMap[activeTab]].length === 0 && (
            <Box display='flex' justifyContent='center' alignItems='center' p={20}>
              <Typography color='text.secondary' i18nKey='SG_NOT_FOUND' />
            </Box>
          )}
        </>
      );
    }

    return null;
  }, [
    activeTab,
    viewMode,
    tableData,
    selectedCustomer,
    dtcType,
    setSelectedCustomer,
    onRetryValidation,
    openRemoveCustomer,
    openResendConsentModal,
    getBoilerMarketingName,
    getBoiler,
    formatCustomerName,
    formatAddress,
  ]);

  const content = React.useMemo(() => {
    return (
      <>
        {shouldShowActivitySpinner ? (
          <ActivitySpinner loading={true} onScreen={true} />
        ) : shouldShowNoDevices ? (
          <Container maxWidth='md'>
            <StatusCard testIdKey='status-no-customers' type='success' title={t('SG_SPLASH_TITLE')} variant='big'>
              <div>
                <AddButton testIdKey='add-customer-panel' onClick={() => openAddCustomer()} />
              </div>
            </StatusCard>
          </Container>
        ) : shouldShowAllOk ? (
          <Container maxWidth='md'>
            <StatusCard
              testIdKey='status-all-ok'
              type='success'
              title={t('SG_ALL_APPLIANCES_OK')}
              text={t('SG_ALL_APPLIANCES_OK_TEXT')}
              variant='big'
            >
              <Container style={{ maxWidth: 640 }}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => navigate('/customers')}
                  i18nKey='SG_NAVIGATE_TO_CUSTOMERS'
                />
              </Container>
            </StatusCard>
          </Container>
        ) : shouldShowTabs ? (
          <TabsContainer display='flex' flexGrow={1}>
            <BigTabs tabs={tabs} value={activeTab} onChange={(_, value) => setActiveTab(value)} testIdKey='tabs'>
              {() => (
                <BigTabsPanel style={{ height: '100%' }} hasPadding={false}>
                  {tabsContent}
                </BigTabsPanel>
              )}
            </BigTabs>
          </TabsContainer>
        ) : null}
      </>
    );
  }, [
    activeTab,
    navigate,
    openAddCustomer,
    setActiveTab,
    shouldShowActivitySpinner,
    shouldShowAllOk,
    shouldShowNoDevices,
    shouldShowTabs,
    t,
    tabs,
    tabsContent,
  ]);

  return (
    <>
      {addCustomerPreset !== undefined && (
        <AddCustomerDialog
          boilerSerialNumber={undefined}
          dialogProps={{
            onClose: () => {
              abortAddCustomer();
              closeAddCustomer();
            },
          }}
        />
      )}
      <RemoveCustomerDialogContainer
        dialogTitle={dialogTitle}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        testIdKey={testIdKey}
        removeCustomerHeaderLabel={removeCustomerHeaderLabel}
        cancelButtonText={cancelButtonText}
        removeButtonText={removeButtonText}
        removeCustomer={removeCustomer}
        isRemovingCustomer={isRemovingCustomer}
        closeDialog={closeDialog}
      />
      {!!customerToResendConsent && (
        <Dialog
          testIdKey='resend-consent'
          title={t('SG_RESEND_CONSENT_TITLE')}
          open={true}
          onClose={closeResendConsent}
        >
          <ResendConsent customer={customerToResendConsent} onClose={closeResendConsent} />
        </Dialog>
      )}
      {isMobile ? (
        <MobileDashboard
          tabs={tabs}
          listData={activeTab !== null ? tableData[tabMap[activeTab]] : []}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          systemsWaitingForActionCount={systemsWaitingForActionCount}
          itemActions={actions}
          openAddCustomer={openAddCustomer}
          formatAddress={formatAddress}
          formatCustomerName={formatCustomerName}
          getBoilerNomenclature={getBoilerNomenclature}
          getBoilerMarketingName={getBoilerMarketingName}
        />
      ) : (
        <Dashboard
          viewMode={viewMode}
          setViewMode={setViewMode}
          content={content}
          shouldShowNotificationBars={shouldShowNotificationBars}
          shouldShowMapToggle={shouldShowMapToggle}
          shouldShowAddDeviceButton={shouldShowAddDeviceButton}
          systemsWaitingForActionCount={systemsWaitingForActionCount}
        />
      )}
    </>
  );
};
