import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import subHours from 'date-fns/subHours';

import { DataPointName } from '../../../typings/models';
import { QueryKey, useQueries } from '../../hooks';
import { NotificationParams, notificationResponseToTimestampString, useNotifications } from '../notifications';
import { historyApi } from './history.endpoints';
import { HistoryApiResponse, UseHistory } from './history.types';

const cachingOptions = {
  refetchOnMount: true,
  staleTime: 20000,
};

export const useHistory: UseHistory = ({
  boilerSerialNumber,
  gatewaySerialNumber = '',
  hoursToLookBack = 24 * 7,
  now = Date.now(),
}) => {
  const start = startOfDay(subHours(now, hoursToLookBack)).toISOString();
  const end = endOfDay(now).toISOString();
  const query = [
    `from=${encodeURIComponent(start)}`,
    `to=${encodeURIComponent(end)}`,
    'sort=DESC',
    'page=1',
    'limit=10000',
  ].join('&');

  const getQuery = (dataPoint: DataPointName) => ({
    key: ['history', boilerSerialNumber, gatewaySerialNumber, dataPoint] as QueryKey,
    requestConfig: historyApi.getData(gatewaySerialNumber, boilerSerialNumber, dataPoint, query),
    requestOptions: {
      enabled: !!boilerSerialNumber && !!gatewaySerialNumber,
      ...cachingOptions,
    },
  });

  const notificationParams: Omit<NotificationParams, 'type'> = {
    from: new Date(start),
    to: new Date(end),
    sort: 'occurrenceTimestamp',
    sortDirection: 'desc',
    limit: 1000,
  };

  const { data: dtcStatusValues } = useNotifications(
    {
      gatewaySerialNumber,
      notificationParams: { ...notificationParams, type: 'STATUS' },
    },
    {
      ...cachingOptions,
    },
  );

  const { data: dtcMaintenanceValues } = useNotifications(
    {
      gatewaySerialNumber,
      notificationParams: { ...notificationParams, type: 'MAINTENANCE' },
    },
    {
      ...cachingOptions,
    },
  );

  const { data: dtcErrorValues } = useNotifications(
    {
      gatewaySerialNumber,
      notificationParams: { ...notificationParams, type: 'ERROR' },
    },
    {
      ...cachingOptions,
    },
  );

  const [
    waterPressureQuery,
    returnTemperatureQuery,
    flowTemperatureQuery,
    dhwTemperatureQuery,
    buildPumpPWMQuery,
    evaporationTemperatureQuery,
    flowTemperatureSensorQuery,
    heatExchangerOutletTemperatureQuery,
    highPressureSensorQuery,
    currentSuperheatingQuery,
    currentSubcoolingQuery,
    heatExchangerInletTemperatureQuery,
    volumeFlowQuery,
  ] = useQueries<HistoryApiResponse>(
    (
      [
        'boiler:waterPressure',
        'boiler:heatingCircuit:returnTemperature',
        'boiler:heatingCircuit:flowTemperature',
        'boiler:domesticHotWater:temperature',
        'BuildPumpPWM',
        'RefrigerantCircuitEvaporationTemperature',
        'FlowTemperatureSensor',
        'BuildingCircuitHeatExchangerOutletTemperature',
        'RefrigerantCircuitHighPressureSensor',
        'RefrigerantCircuitCurrentSuperheating',
        'RefrigerantCircuitCurrentSubcooling',
        'EnvironmentCircuitHeatExchangerInletTemperature',
        'HP_BuildFlow_AI',
      ] as const
    ).map(getQuery),
  );

  const historyValues = {
    waterPressure: waterPressureQuery.data?.data,
    returnTemperature: returnTemperatureQuery.data?.data,
    flowTemperature: flowTemperatureQuery.data?.data,
    dhwTemperature: dhwTemperatureQuery.data?.data,
    buildPumpPWM: buildPumpPWMQuery?.data?.data,
    evaporationTemperature: evaporationTemperatureQuery?.data?.data,
    flowTemperatureSensor: flowTemperatureSensorQuery?.data?.data,
    heatExchangerOutletTemperature: heatExchangerOutletTemperatureQuery?.data?.data,
    highPressureSensor: highPressureSensorQuery?.data?.data,
    currentSuperheating: currentSuperheatingQuery?.data?.data,
    currentSubcooling: currentSubcoolingQuery?.data?.data,
    heatExchangerInletTemperature: heatExchangerInletTemperatureQuery?.data?.data,
    volumeFlow: volumeFlowQuery?.data?.data,
    dtcStatus: notificationResponseToTimestampString(dtcStatusValues),
    dtcMaintenance: notificationResponseToTimestampString(dtcMaintenanceValues),
    dtcError: notificationResponseToTimestampString(dtcErrorValues),
  };

  const queries = [
    waterPressureQuery,
    returnTemperatureQuery,
    flowTemperatureQuery,
    dhwTemperatureQuery,
    buildPumpPWMQuery,
    evaporationTemperatureQuery,
    flowTemperatureSensorQuery,
    heatExchangerOutletTemperatureQuery,
    highPressureSensorQuery,
    currentSuperheatingQuery,
    currentSubcoolingQuery,
    heatExchangerInletTemperatureQuery,
    volumeFlowQuery,
  ].filter(Boolean);

  return {
    data: historyValues,
    isLoading: queries.some((query) => query.isLoading),
    isFetching: queries.some((query) => query.isFetching),
    isError: queries.some((query) => query.isError),
  };
};
