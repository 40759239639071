import React from 'react';

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  MutationFunction,
  useMutation as useMutationBase,
  UseMutationOptions,
  useQueries as useQueriesBase,
  useQuery as useQueryBase,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useRuntimeContext } from '../../contexts/runtimeContext';
import { QueryKey, UpdateFnc, UseOptimisticQueryOptionsReturnType, UseQuery } from './hooks.types';
import { useAuthContext } from '../../contexts/authContext';
import { useLoggingContext } from '../../contexts/loggingContext';

export const useQuery: UseQuery = <T, D, TData = AxiosResponse<T, D>, ErrorResponse = unknown>(
  key: QueryKey,
  requestConfig: AxiosRequestConfig<D>,
  requestOptions: UseQueryOptions<AxiosResponse<T, D>, AxiosError<ErrorResponse, D>, TData> = {},
) => {
  const { isAuthenticated } = useAuthContext();
  const { apiClient } = useRuntimeContext();
  const { reportEvent } = useLoggingContext();

  const queryFn = React.useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!apiClient) {
        const error = 'client not configured';
        reportEvent('api-error-unconfigured', { error });
        return Promise.reject({ failureCount: 5, error });
      }

      try {
        const response: AxiosResponse<T, D> = await apiClient({ ...requestConfig, signal });
        return response;
      } catch (e) {
        const error: AxiosError<T, D> = e as AxiosError<T, D>;
        const eventName = `api-error-${error.request?.status ?? 'unknown'}`;
        reportEvent(eventName, { error: JSON.stringify(error) });
        return Promise.reject(e as AxiosError<T, D>);
      }
    },
    [reportEvent, requestConfig, apiClient],
  );

  const { enabled = true, ...restOptions } = requestOptions;
  const result = useQueryBase<AxiosResponse<T, D>, AxiosError<ErrorResponse, D>, TData>(key, queryFn, {
    ...restOptions,
    enabled: enabled && isAuthenticated,
  });

  if (result?.error?.response?.status === 401) {
    reportEvent('api-error-401', { error: 'unauthorized', result: JSON.stringify(result) });
    // @TODO logout
  }
  return { ...result, queryKey: key };
};

// T = response.data; D = request.data
export const useMutation = <T, D, TVariables, TContext = unknown>(
  dataFnc: (data: TVariables) => AxiosRequestConfig<D>,
  requestOptions?: UseMutationOptions<AxiosResponse<T, D>, AxiosError<T, D>, TVariables, TContext>,
) => {
  const { apiClient } = useRuntimeContext();

  const mutate: MutationFunction<AxiosResponse<T, D>, TVariables> = async (data) => {
    try {
      if (!apiClient) {
        return Promise.reject({ failureCount: 5, error: 'client not configured' });
      }
      const response: AxiosResponse<T, D> = await apiClient({ ...dataFnc(data) });
      return response;
    } catch (e) {
      const error = e as AxiosError<T, D>;
      throw error?.response?.data ?? error.response;
    }
  };

  return useMutationBase<AxiosResponse<T, D>, AxiosError<T, D>, TVariables, TContext>(mutate, requestOptions);
};

export const useQueries = <T, D = unknown, TData = AxiosResponse<T, D>, ErrorResponse = T>(
  queries: Array<{
    key: QueryKey;
    requestConfig: AxiosRequestConfig<D>;
    requestOptions?: UseQueryOptions<AxiosResponse<T, D>, AxiosError<ErrorResponse, D>, TData>;
  }>,
) => {
  const { isAuthenticated } = useAuthContext();
  const { apiClient } = useRuntimeContext();

  return useQueriesBase(
    queries.map((query) => {
      const { enabled: isEnabled = true, ...restOptions } = query.requestOptions || {};
      return {
        queryKey: query.key,
        queryFn: async () => {
          if (!apiClient) {
            return Promise.reject({ failureCount: 5, error: 'client not configured' });
          }
          return apiClient({ ...query.requestConfig });
        },
        ...(query.requestConfig || {}),
        ...restOptions,
        enabled: isEnabled && isAuthenticated,
      };
    }),
  );
};

/**
 * @template TMutated - Type of the response.data whose cached version will be mutated
 * @template TVariables - Variables that are used by mutation function
 * @template TTResponseData - Type of response.data of the endpoint where the payload is send to
 * @template {{ serious(): string }} Seriousalizable - must have a serious method
 * @param QueryKey - Key where the cached response data is found by
 * @param update - Function that updates the cached response
 */
export const useOptimisticQueryOptions = <TMutated, TVariables, TResponseData = TMutated>(
  queryKey: QueryKey,
  update: UpdateFnc<TMutated, TVariables>,
): UseOptimisticQueryOptionsReturnType<TMutated, TVariables, TResponseData> => {
  const queryClient = useQueryClient();
  return {
    onMutate: async (payload) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousValue = queryClient.getQueryData<AxiosResponse<TMutated>>(queryKey) ?? null;
      if (!previousValue) {
        return { previousValue: null, newValue: null };
      }

      // Optimistically update to the new value
      const newValue = update(payload, previousValue);
      queryClient.setQueryData(queryKey, newValue);
      // Return a context with the previous and new value
      return { previousValue, newValue };
    },

    // If the mutation fails, use the context we returned above
    onError: (_err, _newValue, context) => {
      queryClient.setQueryData(queryKey, context?.previousValue);
    },
  };
};
