import React from 'react';
import { MapContextProps } from './DeviceMap.types';

const warn = () => console.warn('MapContext provider not found');

const Context = React.createContext<MapContextProps>({
  customers: [],
  highlightedCustomer: null,
  setHighlightedCustomer: warn,
  selectedCustomer: null,
  setSelectedCustomer: warn,
  dtcTypes: [],
});

export const MapContext: React.FC<React.PropsWithChildren<MapContextProps>> = ({ children, ...value }) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useMapContext = () => React.useContext(Context);
