import React from 'react';
import { useSelector } from 'react-redux';
import { useMap, useMapEvents } from 'react-leaflet';
import { useMediaQuery } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { useTheme } from '@vaillant-professional-ui/component-libs-web';
import { selectors, countryBoundaries, store } from '@vaillant-professional-ui/pui-frontend-common';
import { useWindowSize } from '../../lib/hooks';
import { floatingWidth } from './DeviceMap.util';
import { useMapContext } from './MapContext';
import { getCustomerBounds } from '../../lib/maps';

export const SetBounds: React.FC<React.PropsWithChildren<unknown>> = () => {
  const map = useMap();
  const country = useSelector(store.getCountry);

  const { customers, selectedCustomer, setSelectedCustomer, setHighlightedCustomer } = useMapContext();
  const { width, height } = useWindowSize();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useMapEvents({
    click(e) {
      // @ts-ignore
      const hasClickedOnCustomerList = (e.originalEvent?.target?.className ?? '').includes('customer-summary');
      if (!hasClickedOnCustomerList) {
        setSelectedCustomer(null);
      }
    },
  });

  React.useEffect(() => {
    const mapOptions = {
      paddingTopLeft: [isMobile ? 40 : floatingWidth, 40],
      paddingBottomRight: [40, 40],
    };

    if (selectedCustomer) {
      const customer = customers.find((c) => selectors.customer.getBoilerSerialNumber(c) === selectedCustomer);
      if (customer) {
        const geolocation = selectors.customer.getEndUserPosition(customer);
        if (geolocation) {
          setHighlightedCustomer(null);
          if (geolocation.latitude && geolocation.longitude) {
            map.setView({ lat: geolocation.latitude, lng: geolocation.longitude }, 14, {
              ...mapOptions,
              animate: false,
            });
          }
        }
      }
      return;
    }

    const bounds = getCustomerBounds(customers) || countryBoundaries[country];
    if (!bounds) {
      return;
    }

    if (isEqual(bounds.northEast, bounds.southWest)) {
      // @ts-ignore
      map.setView(bounds.northEast, 11, mapOptions);
      return;
    }

    const tuple = [
      [bounds.southWest.lat, bounds.southWest.lng],
      [bounds.northEast.lat, bounds.northEast.lng],
    ];

    // @ts-ignore
    map.fitBounds(tuple, mapOptions);
  }, [map, customers, width, height, isMobile, selectedCustomer, country, setHighlightedCustomer]);

  return null;
};
