/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon46801VgInformation201: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon46801vginformation201', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M265 252v144.85h37.11V239.5h-24.6c-7.97 0-12.51 4.59-12.51 12.5zm18.67-81.92c-12.51 0-23.34 10.84-23.34 23.35s10.83 22.93 23.34 22.93c12.09 0 22.93-10.42 22.93-22.93s-10.84-23.35-22.93-23.35z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.46 102.05c-100 0-181.41 81.38-181.41 181.41s81.38 181.42 181.41 181.42 181.42-81.38 181.42-181.42-81.38-181.41-181.42-181.41zm0 340.15c-87.53 0-158.74-71.21-158.74-158.74s71.21-158.74 158.74-158.74S442.2 195.94 442.2 283.46 371 442.2 283.46 442.2z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
