import { firebase } from '@vaillant-professional-ui/pui-frontend-common';

export const urlMapping: [firebase.NavigationIdentifier, RegExp][] = [
  ['login', /^\/login(\/)?/],
  ['acceptTerms', /^\/accept-terms(\/)?/],
  ['demo', /^\/demo\/(.*)\/?/],
  ['feedback', /^\/feedback(\/)?/],
  ['emfOverview', /^\/customers\/(\w+)\/consumption\/?$/],
  ['emfElectricalConsumption', /^\/customers\/(\w+)\/consumption\/electrical-consumption\/?/],
  ['emfEnvironmentalYield', /^\/customers\/(\w+)\/consumption\/environmental-yield\/?/],
  ['emfHeatGenerated', /^\/customers\/(\w+)\/consumption\/heat-generated\/?/],
  ['emfDhwGenerated', /^\/customers\/(\w+)\/consumption\/dhw-generated\/?/],
  ['emfCoolingGenerated', /^\/customers\/(\w+)\/consumption\/cooling-generated\/?/],
  ['emfSolarThermal', /^\/customers\/(\w+)\/consumption\/solar-thermal\/?/],
  ['erelax-scheduler', /^\/customers\/(\w+)\/erelax-scheduler\/?/],
  ['tliDhwSchedule', /^\/customers\/(\w+)\/tli-dhw-schedule\/?/],
  ['tliHeatCurve', /^\/customers\/(\w+)\/system-settings\/(\w+)\/(\w+)\/heat-curve\/(\w+)\/?/],
  ['tliSettings', /^\/customers\/(\w+)\/tli-settings\/?/],
  ['tliScheduler', /^\/customers\/(\w+)\/system-settings\/(\w+)\/(\w+)\/scheduler\/(\w+)\/?/],
  ['tliSetBackTemperature', /^\/customers\/(\w+)\/tli-set-back-temperature\/?/],
  ['customerDetail', /^\/customers\/(\w+)\/?/],
  ['customerList', /^\/customers(\/)?$/],
  ['codeFinder', /^\/code-assistant(\/)?/],
  ['dashboardFailures', /^\/dashboard\/failures(\/)?/],
  ['dashboardMaintenance', /^\/dashboard\/maintenance(\/)?/],
  ['dashboardPredictions', /^\/dashboard\/prediction(\/)?/],
  ['dashboardOffline', /^\/dashboard\/offline(\/)?/],
  ['dashboardValidationFailed', /^\/dashboard\/validationFailed(\/)?/],
  ['dashboardPending', /^\/dashboard\/pending(\/)?/],
  ['dashboard', /^\/dashboard(\/)?$/],
  ['dashboard', /^\/(\/)?$/],
  ['delegationLink', /^\/jobs\/(\w+)\/?/],
  ['settings', /^\/settings(\/)?/],
];

export const getNavigationIdForUrl = (url: string): firebase.NavigationIdentifier => {
  const defaultIdentifier = 'unknown' as firebase.NavigationIdentifier;

  const id = urlMapping.reduce((result, [currentId, regex]) => {
    if (result !== defaultIdentifier) {
      return result;
    }
    return url.match(regex) ? currentId : result;
  }, defaultIdentifier);

  if (id === defaultIdentifier && url !== '') {
    console.error(`Please add an analytics identifier for ${url}`);
  }

  return id;
};
