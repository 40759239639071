import { Theme } from '@mui/material/styles';
import { BaseTheme } from '@vaillant-professional-ui/component-libs-common';

/**
 * Define own set of variants
 *
 * see https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
const typographyPreset: Record<string, object | undefined> = {
  h1: {
    fontSize: 24,
    lineHeight: '30px',
  },
  h2: {
    fontSize: 20,
    lineHeight: '25px',
  },
  h3: {
    fontSize: 18,
    lineHeight: '22px',
  },
  h4: {
    fontSize: 16,
    lineHeight: '22px',
  },
  h5: undefined,
  h6: undefined,

  smallCopy: {
    fontSize: 12,
    lineHeight: '15px',
  },

  body: {
    fontSize: 14,
    lineHeight: '20px',
  },
  caption: {
    fontSize: 12,
    lineHeight: '15px',
  },
  large: {
    fontSize: 32,
    lineHeight: '40px',
  },
  big: {
    fontSize: 48,
    lineHeight: '58px',
  },
  hero: {
    fontWeight: 600,
  },
};

// Styles that could be used in mui internally
typographyPreset['body1'] = typographyPreset.body;
typographyPreset['body2'] = typographyPreset.smallCopy;
typographyPreset['subtitle1'] = typographyPreset.caption;
typographyPreset['subtitle2'] = typographyPreset.caption;

/**
 * Map variant to semantic element
 *
 * see https://mui.com/material-ui/react-typography/#changing-the-semantic-element
 */
export const typographyVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body: 'p',
  smallCopy: 'p',
  caption: 'span',
  large: 'span',
  big: 'span',
  hero: 'span',
};

/**
 * extend the theme with responsive font behavior
 *
 * see https://material-ui.com/customization/typography/#responsive-font-sizes
 */
export const addResponsiveFonts = (muiTheme: Theme) => {
  muiTheme.typography.h1 = {
    ...muiTheme.typography.h1,
    [muiTheme.breakpoints.up('sm')]: {
      fontSize: 32,
      lineHeight: '40px',
    },
  };

  muiTheme.typography.h2 = {
    ...muiTheme.typography.h2,
    [muiTheme.breakpoints.up('sm')]: {
      fontSize: 24,
      lineHeight: '30px',
    },
  };

  muiTheme.typography.h3 = {
    ...muiTheme.typography.h3,
    [muiTheme.breakpoints.up('sm')]: {
      fontSize: 20,
      lineHeight: '25px',
    },
  };

  muiTheme.typography.h4 = {
    ...muiTheme.typography.h4,
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 18,
      lineHeight: '24px',
    },
  };

  muiTheme.typography.body = {
    ...muiTheme.typography.body,
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  };

  muiTheme.typography.caption = {
    ...muiTheme.typography.caption,
    [muiTheme.breakpoints.up('sm')]: {
      fontSize: 14,
      lineHeight: '18px',
    },
    [muiTheme.breakpoints.up('xl')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  };

  muiTheme.typography.hero = {
    ...muiTheme.typography.hero,
    [muiTheme.breakpoints.up('xs')]: {
      fontSize: 64,
      lineHeight: '77px',
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 72,
      lineHeight: '86px',
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 90,
      lineHeight: '108px',
    },
    [muiTheme.breakpoints.up('xl')]: {
      fontSize: 110,
      lineHeight: '132px',
    },
  };

  if (muiTheme?.components?.MuiButton?.styleOverrides?.root) {
    muiTheme.components.MuiButton.styleOverrides.root = {
      ...(muiTheme.components.MuiButton.styleOverrides.root as object),
      [muiTheme.breakpoints.down('md')]: {
        minHeight: 50,
      },
    };
  }
};

export const createTypography = (
  typography: BaseTheme['platforms']['web']['typography'],
): Partial<Theme['typography']> => {
  return {
    ...typography,
    ...Object.fromEntries(
      Object.entries(typographyPreset).map(([k, v]) => {
        return [k, v ? { ...typography, ...v } : undefined];
      }),
    ),
  };
};
