import * as React from 'react';
import { Box, Link } from '@mui/material';
import { Translated, useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { BackLinkProps } from './BackLink.types';
import { Icon, Text } from './BackLink.styles';

export const BackLink = <C extends React.ElementType>({
  children,
  i18nKey,
  i18nOptions,
  linkProps,
  testIdKey,
  ...rest
}: React.PropsWithChildren<BackLinkProps<C>>) => {
  const testId = useInstanceId('backlink', testIdKey);

  return (
    <Link {...linkProps} {...testId().testIdAttributes} {...rest}>
      <Box display='flex' flexDirection='row' alignItems='center' component='span'>
        <Icon color='primary.main' />
        <Translated
          i18nKey={i18nKey}
          i18nOptions={i18nOptions}
          component={
            <Text variant='caption' color='primary.main'>
              {children}
            </Text>
          }
        />
      </Box>
    </Link>
  );
};
