import React from 'react';

import {
  Table as TableBase,
  TableBody as TableBodyBase,
  TableContainer,
  TableHead as TableHeadBase,
  TableHeadProps,
  TableRow as TableRowBase,
  TableRowProps,
  styled,
} from '@mui/material';
import { useTheme } from '@vaillant-professional-ui/component-libs-web';
import { TableBodyProps, TableProps } from 'react-virtuoso';
import { BaseTableRowData, BaseTableRowDataWithId, CustomTableRowProps } from './VirtualizedTable.types';

const Table = (props: TableProps) => (
  <TableBase {...props} stickyHeader style={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
);

const CustomTableBody = (props: TableBodyProps, ref: TableBodyProps['ref']) => {
  return <TableBodyBase {...props} ref={ref} />;
};

const CustomTableHead = (props: TableHeadProps, ref: TableHeadProps['ref']) => {
  const theme = useTheme();
  return <TableHeadBase {...props} style={{ backgroundColor: theme.palette.common.white }} ref={ref} />;
};

const TableRowBaseStyled = styled(TableRowBase)<{ $disabled?: boolean; $hasUnreadNotifications?: boolean }>`
  &:hover {
    cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  }
  background-color: ${({ $hasUnreadNotifications, theme }) =>
    $hasUnreadNotifications ? `${theme.palette.primary.lighter} !important` : 'inherit'};
`;

const CustomTableRow = <Data extends BaseTableRowData | BaseTableRowDataWithId>(
  { context, ...rowProps }: CustomTableRowProps<Data>,
  ref: TableRowProps['ref'],
) => {
  const {
    item: { _disabled: isDisabled },
  } = rowProps;

  // @ts-ignore: ts doesn't understand the generic typing for id
  const rowId: string | undefined = rowProps.item.id;

  return (
    <TableRowBaseStyled
      $disabled={isDisabled}
      $hasUnreadNotifications={rowProps.item._meta?.hasUnreadNotifications}
      {...rowProps}
      ref={ref}
      onClick={(event) => !isDisabled && rowId && context?.onClickRow?.(event, rowId)}
    />
  );
};

const TableBody = React.forwardRef(CustomTableBody);

const TableRow = React.forwardRef(CustomTableRow);

const TableHead = React.forwardRef(CustomTableHead);

export const TableComponents = {
  Scroller: TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
};
