import { Country, IUserInfo } from '../../typings/models';

export enum ActionTypes {
  SET_USER_LANGUAGE = 'SETTINGS/SET_USER_LANGUAGE',
  SET_COUNTRY = 'SETTINGS/SET_COUNTRY',
  SET_TERMS_ACCEPTED = 'SETTINGS/SET_TERMS_ACCEPTED',
  SET_COOKIES_ACCEPTED = 'SETTINGS/SET_COOKIES_ACCEPTED',
  SET_NOTIFICATION_READ = 'SETTINGS/SET_NOTIFICATION_READ',
  SET_USER_INFO = 'SETTINGS/SET_USER_INFO',
  SET_DISMISS_MAX_DATAPOINTS_WARNING = 'SETTINGS/SET_DISMISS_MAX_DATAPOINTS_WARNING',
}

export interface SetUserLanguage {
  type: ActionTypes.SET_USER_LANGUAGE;
  payload: string;
}

export interface SetCountry {
  type: ActionTypes.SET_COUNTRY;
  payload: Country;
}

export interface SetTermsAccepted {
  type: ActionTypes.SET_TERMS_ACCEPTED;
  payload: string;
}

export interface SetCookiesAccepted {
  type: ActionTypes.SET_COOKIES_ACCEPTED;
  payload: boolean;
}

export interface SetUserInfo {
  type: ActionTypes.SET_USER_INFO;
  payload: IUserInfo;
}

export interface SetNotificationRead {
  type: ActionTypes.SET_NOTIFICATION_READ;
  payload: { notificationId: string; contactId: string };
}

export interface SetDismissMaxDatapointsWarning {
  type: ActionTypes.SET_DISMISS_MAX_DATAPOINTS_WARNING;
  payload: boolean;
}

export type Actions =
  | SetUserLanguage
  | SetCountry
  | SetTermsAccepted
  | SetCookiesAccepted
  | SetUserInfo
  | SetNotificationRead
  | SetDismissMaxDatapointsWarning;
