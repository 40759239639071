import startOfMonth from 'date-fns/startOfMonth';
import { useIntl } from 'react-intl';

import { useTranslation } from '../../../..';
import { HeatingDeviceType } from '../../../typings/models';
import { useNumberFormatWithFallback } from '../../../util/l10n';
import { useConsumptionData, useDataPoints, useEmfDevices } from './emf.hooks';

export interface ConsumptionCardPresenterProps {
  boilerSerialNumber: string;
  mainHeatingDeviceType: HeatingDeviceType;
  now: Date;
}

export const useConsumptionCardPresenter = ({
  boilerSerialNumber,
  mainHeatingDeviceType,
  now,
}: ConsumptionCardPresenterProps) => {
  const { formatDate } = useIntl();
  const formatNumber = useNumberFormatWithFallback();

  const t = useTranslation();

  const startDate = startOfMonth(now);

  const {
    isLoading: isLoadingDevices,
    isHybridSystem,
    defaultFilters: filters,
  } = useEmfDevices(boilerSerialNumber, startDate);

  const {
    data: consumptionData,
    isLoading: isLoadingConsumption,
    errors,
  } = useConsumptionData({ ...filters, boilerSerialNumber });

  const items = useDataPoints(mainHeatingDeviceType, consumptionData, isHybridSystem);

  const title = t('SG_ENERGY_INFORMATION');
  const subtitle = `${formatDate(filters.startDate)} – ${formatDate(filters.endDate)}`;
  const titleText = t('SG_CONSUMPTION_CARD_EFFICIENCY');
  const totalEfficiency = formatNumber(consumptionData?.efficiency?.total ?? null);
  const shouldShowEfficiency = mainHeatingDeviceType === 'HEAT_PUMP' && !isHybridSystem;
  const initialFilterParams = new URLSearchParams({ filters: JSON.stringify(filters) }).toString();
  const detailsLinkPath = `/customers/${boilerSerialNumber}/consumption?${initialFilterParams}`;
  const detailsLinkLabel = t('SG_MONITORING_DETAILS_LINK');

  return {
    isLoadingDevices,
    isLoadingConsumption,
    error: errors[0],
    title,
    subtitle,
    items,
    titleText,
    totalEfficiency,
    shouldShowEfficiency,
    detailsLinkPath,
    detailsLinkLabel,
  };
};
