/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon13201VgControl01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon13201vgcontrol01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M306.16 124.73a22.68 22.68 0 1 1-22.68-22.68 22.68 22.68 0 0 1 22.68 22.68zm85.69.36a22.67 22.67 0 1 0 11.5 29.91 22.68 22.68 0 0 0-11.5-29.91zm89.64 65.13a22.68 22.68 0 1 0-1.68 32 22.68 22.68 0 0 0 1.68-32zM175.1 125.08a22.67 22.67 0 1 0 29.9 11.49 22.67 22.67 0 0 0-29.9-11.49zM85.46 190.2a22.68 22.68 0 1 0 32-1.68 22.68 22.68 0 0 0-32 1.68zm294.36 178.3a96.38 96.38 0 1 1-188.95-26.84l-46.12-98.91 103 36.23a96.38 96.38 0 0 1 132.07 89.52zm-22.68 0a73.7 73.7 0 1 0-73.7 73.71 73.79 73.79 0 0 0 73.7-73.71z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
