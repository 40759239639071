import { AxiosResponse } from 'axios';

import { DeviceDetailsResponseTli } from '../../../apis/maintenance/v2';
import { TliDhw } from '../../../apis/maintenance/v2/maintenance.types';
import { patchTliResponseV2 } from './generalUtil';

/**
 * Domestic hot water is handled as an array and could potentially
 * have more than one entry, but by now the settings API would not handle
 * multiple dhw 'zones'.
 */
const getDomesticHotWater = (response: AxiosResponse<DeviceDetailsResponseTli>) => {
  return response.data.domestic_hot_water?.[0];
};

export const updateTliDhw = (
  previousValue: AxiosResponse<DeviceDetailsResponseTli>,
  patchFnc: (previousDhw: TliDhw) => TliDhw,
) => {
  const previousDhw = getDomesticHotWater(previousValue);
  if (!previousDhw) {
    return { ...previousValue };
  }
  return patchTliResponseV2(previousValue, { domestic_hot_water: [patchFnc(previousDhw)] });
};
