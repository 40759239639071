import * as React from 'react';
import { useInstanceId, useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { CountrySelectProps } from './CountrySelect.types';
import { Select } from '../Select';
import { getCountriesInfo, getCountryCodes } from '../../../util/i18n';

export const CountrySelect: React.FunctionComponent<React.PropsWithChildren<CountrySelectProps>> = ({
  countryList = getCountryCodes(),
  asNativeName = true,
  testIdKey,
  ...props
}) => {
  const t = useTranslation();
  const testId = useInstanceId('countryselect', testIdKey);

  const availableCountries = React.useMemo(
    () => getCountriesInfo(countryList, asNativeName ? 'nativeName' : 'name'),
    [asNativeName, countryList],
  );

  const countries = React.useMemo(
    () => (countryList ? availableCountries.filter((record) => countryList.includes(record.code)) : availableCountries),
    [availableCountries, countryList],
  );

  const options = React.useMemo(
    () =>
      countries.map((country) => ({
        value: country.code,
        label: asNativeName ? country.nativeName : country.name,
      })),
    [asNativeName, countries],
  );

  return (
    <Select label={t('VG_COUNTRY_SELECT_PLACEHOLDER')} options={options} testIdKey={testId('root').key} {...props} />
  );
};
