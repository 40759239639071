import React from 'react';
import { StyledLegendButton } from './LegendButton.styles';
import { LegendButtonProps, Shapes } from './LegendButton.types';

export const LegendButton = ({ type, color, dashArray, children, handleClick, active }: LegendButtonProps) => {
  const renderShape = function (type: Shapes) {
    switch (type) {
      case 'circle':
        return (
          <svg width='16' height='16' viewBox='0 0 10 10' style={{ marginRight: 4, transform: 'translate(-5px,3px)' }}>
            <circle stroke={color} fill={color} cx='5' cy='5' r='4' />
          </svg>
        );
      case 'bar':
        return (
          <svg width='16' height='16' viewBox='0 0 1 8' style={{ marginRight: 4, transform: 'translateY(3px)' }}>
            <path stroke={color} d='M0 0 L0 8' strokeWidth={8} strokeDasharray={dashArray} />
          </svg>
        );
      default:
        return (
          <svg width='32' height='16' viewBox='0 0 32 16' style={{ marginRight: 6, transform: 'translateY(3px)' }}>
            <path stroke={color} d='M0 8 L32 8' strokeWidth={4} strokeDasharray={dashArray || '0'} />
          </svg>
        );
    }
  };
  return (
    <StyledLegendButton $active={active} onClick={() => handleClick()} variant='outlined'>
      {renderShape(type)}
      {children}
    </StyledLegendButton>
  );
};

export default LegendButton;
