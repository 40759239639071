import { useMediaQuery } from '@mui/material';
import { useTheme } from '@vaillant-professional-ui/component-libs-web';

export type BreakPoint = 'sm' | 'md' | 'lg' | 'xl' | 'default';

export const useBreakPoint = (): BreakPoint => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm')) && 'sm';
  const md = useMediaQuery(theme.breakpoints.down('md')) && 'md';
  const lg = useMediaQuery(theme.breakpoints.down('lg')) && 'lg';
  const xl = useMediaQuery(theme.breakpoints.down('xl')) && 'xl';
  return sm || md || lg || xl || 'default';
};
