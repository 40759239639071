import { useMediaQuery } from '@mui/material';
import { useTheme } from '@vaillant-professional-ui/component-libs-web';
import { api, features, hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { getColumns } from '../../CustomerTable/CustomerTable.utils';
import { useSorting } from '../../organisms/VirtualizedTable';

export const useCustomerListPresenter = () => {
  const { customers, error, hasNoCustomers, isLoading, query, refetch, setQuery, hasNoSearchResult } =
    features.customerList.usePresenter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const t = hooks.useTranslation();
  const columns = getColumns(t);

  const { loyaltyRegistrations } = api.useCases.useLoyaltyRegistrationsWithPermissions();
  const shouldShowLoyaltyRegistrations = loyaltyRegistrations.length > 0 && !isMobile;
  const shouldShowTable = !hasNoCustomers || shouldShowLoyaltyRegistrations;

  const shouldShowPageHeader = !isMobile;
  const shouldShowAsTable = !isMobile && shouldShowTable;

  const shouldShowAddCustomerInHeader = !hasNoCustomers;
  const tableData = customers.map((customer) => ({ id: customer.boilerSerialNumber, customer }));

  const sorting = useSorting({
    data: tableData,
    columns,
    initialSorting: { columnIndex: 0, direction: 'asc' },
  });

  return {
    query,
    setQuery,
    shouldShowAsCards: isMobile && shouldShowTable,
    shouldShowAsTable,
    hasNoSearchResult,
    customers,
    shouldShowLoyaltyRegistrations,
    loyaltyRegistrations,
    shouldShowTable,
    refetch,
    isLoading,
    error,
    hasNoCustomers,
    shouldShowPageHeader,
    shouldShowAddCustomerInHeader,
    sorting,
  };
};
