import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Menu, MenuItem, MenuProps } from '@mui/material';
import range from 'lodash/range';
import { Button } from '../../atoms/Button';
import { VgBaseProps } from '../../components.types';

interface MenuOption {
  value: number;
  label: string;
  disabled?: boolean;
}

interface TimePickerMenuProps extends VgBaseProps {
  selectedValue: number;
  minimum?: number;
  maximum?: number;
  stepWidthInMinutes?: number;
  options: MenuOption[];
  anchorEl: null | HTMLElement;
  setSelectedValue: (value: number) => void;
  onClose: MenuProps['onClose'];
  testIdKey?: string;
}

const TimePickerMenu: React.FC<TimePickerMenuProps> = ({
  anchorEl,
  setSelectedValue,
  selectedValue,
  onClose,
  minimum,
  maximum,
  options,
  testIdKey,
}) => {
  const testId = useInstanceId('timepicker-select', testIdKey);
  const minValue = minimum ?? Math.min(...options.map((v) => v.value));
  const maxValue = maximum ?? Math.max(...options.map((v) => v.value));

  const handleMenuItemClick = (value: number) => {
    setSelectedValue(value);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      {...testId('menu').testIdAttributes}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          disabled={option.disabled || option.value > maxValue || option.value < minValue}
          selected={option.value === selectedValue}
          onClick={() => handleMenuItemClick(option.value)}
          {...testId(`menu-item-${option.value}`).testIdAttributes}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

type TimePickerSelectProps = Omit<TimePickerMenuProps, 'anchorEl' | 'onClose'>;
const padHour = (v: number): string => {
  return v.toString().padStart(2, '0');
};
const TimePickerSelect: React.FC<TimePickerSelectProps> = (props) => {
  const testId = useInstanceId('timepicker-select', props.testIdKey);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const setSelectedValue = (value: number) => {
    handleClose();
    props.setSelectedValue(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} {...testId('button').testIdAttributes}>
        {padHour(props.selectedValue)}
      </Button>
      <TimePickerMenu {...props} anchorEl={anchorEl} setSelectedValue={setSelectedValue} onClose={handleClose} />
    </>
  );
};

export const HourSelect: React.FC<Omit<TimePickerSelectProps, 'options' | 'stepWidthInMinutes'>> = (props) => {
  const { minimum = 0, maximum = 25 } = props;
  const optionsHour = range(minimum, maximum + 1, 1).map((v) => {
    return {
      value: v,
      label: padHour(v),
    };
  });

  return <TimePickerSelect {...props} options={optionsHour} />;
};

type MinuteSelectProps = Omit<TimePickerSelectProps, 'options'> & {
  stepWidthInMinutes: number;
};

export const MinuteSelect: React.FC<MinuteSelectProps> = (props) => {
  const { minimum = 0, maximum = 59, stepWidthInMinutes = 10 } = props;
  const optionsMinute = range(minimum, maximum, stepWidthInMinutes).map((v) => {
    return {
      value: v,
      label: padHour(v),
      disabled: false,
    };
  });

  return <TimePickerSelect {...props} options={optionsMinute} />;
};
