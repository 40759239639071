import React from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsCallOptions, getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { deleteApp, FirebaseOptions, getApps, initializeApp } from 'firebase/app';
import { config, firebase, store, useLoggingContext } from '@vaillant-professional-ui/pui-frontend-common';
import { useGetUserDetails } from '../../lib/hooks';
import { getNavigationIdForUrl } from './getNavigationIdForUrl';

interface FirebaseService {
  logUserAction: (
    eventName: firebase.UserActionIdentifier,
    eventParams?: Record<string, string | number>,
    options?: AnalyticsCallOptions,
  ) => void;
  logEvent: (eventName: string, eventParams?: Record<string, string | number>, options?: AnalyticsCallOptions) => void;
  setUserId: (userId: string) => void;
}

type EventParamsSupplier = () => firebase.FirebaseEventParams;

const mockService: FirebaseService = {
  logEvent: (eventName: string, eventParams?: Record<string, string | number>, options?: AnalyticsCallOptions) => {
    process.env.NODE_ENV === 'development' && console.log('logEvent', { eventName, eventParams, options });
  },
  logUserAction: (
    eventName: firebase.UserActionIdentifier,
    eventParams?: Record<string, string | number>,
    options?: AnalyticsCallOptions,
  ) => {
    process.env.NODE_ENV === 'development' &&
      console.log('logUserAction', {
        eventName,
        eventParams,
        options,
      });
  },
  setUserId: (userId: string) => {
    process.env.NODE_ENV === 'development' && console.log('setUserId', { userId });
  },
};

const initializeFirebaseAnalytics: (
  firebaseConfig: FirebaseOptions,
  getAdditionalParams: EventParamsSupplier,
) => FirebaseService = (firebaseConfig, getAdditionalParams) => {
  initializeApp(firebaseConfig);
  const analytics = getAnalytics();
  const loggingFunction = (name: string, params?: Record<string, string | number>, options?: AnalyticsCallOptions) => {
    logEvent(analytics, name, { ...getAdditionalParams(), ...params }, options);
  };
  const setUserFunction = (id: string, options?: AnalyticsCallOptions) => {
    setUserId(analytics, id, options);
  };

  const logUserAction = (
    eventName: firebase.UserActionIdentifier,
    eventParams: Record<string, string | number> = {},
    options?: AnalyticsCallOptions,
  ) => loggingFunction(eventName, { eventCategory: 'userAction', ...eventParams }, options);

  return { logEvent: loggingFunction, logUserAction, setUserId: setUserFunction };
};

export const clearTracking = async () => {
  for (const app of getApps()) {
    try {
      await deleteApp(app);
    } catch (reason) {
      console.error(reason);
    }
  }
};

const clearAndInitializeFirebaseAnalytics: (
  firebaseConfig: FirebaseOptions,
  getAdditionalParams: EventParamsSupplier,
) => Promise<FirebaseService> = async (firebaseConfig, getAdditionalParams) => {
  await clearTracking();
  return initializeFirebaseAnalytics(firebaseConfig, getAdditionalParams);
};

const useFirebaseService = (
  config: FirebaseOptions,
  hasCookiesAccepted: boolean,
  getAdditionalParams: EventParamsSupplier,
) => {
  const [service, setService] = React.useState<FirebaseService>(mockService);
  const { reportError } = useLoggingContext();

  React.useEffect(() => {
    if (config && hasCookiesAccepted) {
      setService(mockService);
      clearAndInitializeFirebaseAnalytics(config, getAdditionalParams)
        .then((instance) => {
          setService(instance);
        })
        .catch((error) => reportError('firebase_init_error', error));
    }
  }, [config, hasCookiesAccepted, getAdditionalParams, reportError]);

  return service;
};

const chooseFirebaseConfig = (appConfig: config.AppConfig) => {
  // special case: If we are running in development mode (that is: non compiled react app), use the dev configuration
  if (appConfig.environment !== 'production') {
    return appConfig.distribution.firebaseConfig.dev;
  }
  const apiEnvironment = config.getApiEnvironment(appConfig);
  return appConfig.distribution.firebaseConfig[apiEnvironment];
};

const useFirebaseConfig = () => {
  const { isTermsAccepted, userAccountHash, userContactHash } = useGetUserDetails();

  const appConfig = config.useAppConfig();
  const firebaseConfig = chooseFirebaseConfig(appConfig);

  const country = useSelector(store.getCountry);

  const getFirebaseEventParams = React.useCallback<EventParamsSupplier>(() => {
    return {
      eventView: getNavigationIdForUrl(window.location?.pathname),
      companyName: appConfig.distribution.brand.key,
      country,
      account_id: userAccountHash ?? 'unset',
      contact_id: userContactHash ?? 'unset',
      source: appConfig.distribution.platformName.replace(' ', ''),
    };
  }, [
    userAccountHash,
    userContactHash,
    country,
    appConfig.distribution.platformName,
    appConfig.distribution.brand.key,
  ]);

  return { isTermsAccepted, firebaseConfig, getFirebaseEventParams };
};

const FirebaseServiceContext = React.createContext<FirebaseService>(mockService);

export const FirebaseProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isTermsAccepted, firebaseConfig, getFirebaseEventParams } = useFirebaseConfig();
  const service = useFirebaseService(firebaseConfig, isTermsAccepted, getFirebaseEventParams);
  service.setUserId(getFirebaseEventParams().account_id);
  return <FirebaseServiceContext.Provider value={service}>{children}</FirebaseServiceContext.Provider>;
};

export const useFirebase = () => React.useContext(FirebaseServiceContext);
