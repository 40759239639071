interface HeatTemperatureParameters {
  outsideTemperature: number;
  setPointTemperature: number;
  heatCurve: number;
  minTemp: number;
}

export const calculateFlowTemperature = ({
  outsideTemperature,
  setPointTemperature,
  heatCurve,
  minTemp,
}: HeatTemperatureParameters) => {
  if (heatCurve < 0) {
    console.error('heat curve parameter must be a positive value');
    return null;
  }
  let heatTemp = Math.round(
    60 * Math.pow((setPointTemperature - outsideTemperature) / (70 / heatCurve), 0.75) +
      10 * ((setPointTemperature - outsideTemperature) / (70 / heatCurve)) +
      setPointTemperature,
  );
  heatTemp = isNaN(heatTemp) ? 0 : heatTemp;
  return Math.max(minTemp, heatTemp);
};
