import React from 'react';
import {
  List as ListBase,
  ListItem as ListItemBase,
  ListItemIcon as ListItemIconBase,
  ListItemText as ListItemTextBase,
  ListItemSecondaryAction as ListItemSecondaryActionBase
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled } from '../../../util/styling';
import { NotificationBarVariant, VariantColors } from './NotificationBar.types';

export const getVariantColors = (variant: NotificationBarVariant, theme: Theme): VariantColors => {
  const colors = {
    primary: {
      text: theme.palette.common.white,
      background: theme.palette.primary.main,
      icon: theme.palette.common.white
    },
    primaryLight: {
      text: theme.palette.common.black,
      background: theme.palette.primary.lightest,
      icon: theme.palette.primary.main
    },
    error: {
      text: theme.palette.common.white,
      background: theme.palette.error.main,
      icon: theme.palette.common.white
    }
  };
  return colors[variant];
};

export const List = styled(ListBase)`
  display: inline-flex;
`;

export const ListItem: React.FC<React.PropsWithChildren<unknown>> = styled(ListItemBase)<{
  $colors: VariantColors;
}>`
  color: ${(props) => props.$colors.text};
  background-color: ${(props) => props.$colors.background};
  border-radius: 4px;
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const ListItemIcon = styled(ListItemIconBase)`
  min-width: 0;
`;

export const ListItemText = styled(ListItemTextBase)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const ListItemSecondaryAction = styled(ListItemSecondaryActionBase)`
  top: 54%;
  right: 4px;
`;
