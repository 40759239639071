import { chartTooltipStyleConfig } from '../ChartTooltip/ChartTooltip.styles';

const { arrowWidth, arrowWidthMobile } = chartTooltipStyleConfig;
const arrowWidthOffset = arrowWidth;
const arrowWidthOffsetMobile = arrowWidthMobile;

export const breakPointDependentStyles = {
  sm: {
    fontSize: 16,
    xOffset: 12,
    yOffset: arrowWidthOffsetMobile,
  },
  md: {
    fontSize: 16,
    xOffset: 12,
    yOffset: arrowWidthOffset,
  },
  lg: {
    fontSize: 6,
    xOffset: 12,
    yOffset: arrowWidthOffset,
  },
  xl: {
    fontSize: 12,
    xOffset: 12,
    yOffset: arrowWidthOffset,
  },
  default: {
    fontSize: 6,
    xOffset: 12,
    yOffset: arrowWidthOffset,
  },
};
