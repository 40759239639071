import { Box } from '@mui/system';
import { css, styled } from '../../../util/styling';
import { Severity } from './Banner.types';

export const BannerStyled = styled(Box)<{ $severity: Severity }>`
  border-radius: 10px;
  ${({ $severity, theme }) => {
    switch ($severity) {
      case 'success':
        return css`
          background-color: ${theme.palette.primary.lightest};
          '& svg': {
            color: ${theme.palette.primary.main};
          }
        `;
      case 'warning':
        return css`
          background-color: ${theme.palette.warning.main};
          color: ${theme.palette.common.white};
          '& svg': {
            color: 'inherit';
          }
        `;
      case 'error':
        return css`
          background-color: ${theme.palette.error.main};
          color: ${theme.palette.common.white};
          '& svg': {
            color: 'inherit';
          }
        `;
      case 'info':
        return css`
          background-color: ${theme.palette.grey['400']};
        `;
    }
  }}
`;
