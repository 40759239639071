import { Typography, useTheme } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';

interface HighlightTextProps {
  children: string;
  markText?: string;
}

export const HighlightText: React.FC<HighlightTextProps> = ({ markText, children, ...textProps }) => {
  const theme = useTheme();
  if (!markText) {
    return (
      <Typography component='span' {...textProps}>
        {children}
      </Typography>
    );
  }

  const regex = new RegExp(`(${markText})`, 'gi');
  const textSplits = children?.split(regex);

  const result = textSplits?.map((title, index) => {
    const key = title + index;
    if (index % 2 !== 0 && markText) {
      return (
        <Typography component='span' key={key} style={{ color: theme.palette.primary.main }}>
          {title}
        </Typography>
      );
    } else {
      return (
        <Typography component='span' key={key}>
          {title}
        </Typography>
      );
    }
  });

  return (
    <Typography component='span' {...textProps}>
      {result}
    </Typography>
  );
};
