import { combineReducers } from 'redux';
import { ISessionState } from './session/session.reducer.types';
import { ISettingsState } from './settings/settings.reducer';
import settingsReducer from './settings/settings.reducer';
import sessionReducer from './session/session.reducer';
import { documentationDownloadReducer, DocumentsState } from './Documentation/DocumentationDownloadReducer';
import { tutorialReducer, TutorialState } from './Tutorial/TutorialReducer';

export interface State {
  session: ISessionState;
  settings: ISettingsState;
  documents: DocumentsState;
  tutorial: TutorialState;
}

export const rootReducer = combineReducers({
  session: sessionReducer,
  settings: settingsReducer,
  documents: documentationDownloadReducer,
  tutorial: tutorialReducer,
});
