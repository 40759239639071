import { PermissionRules } from './types';

/* eslint-disable no-unused-vars */
type Fn = (userRoles: string[]) => boolean;
type Roles = string[] | Array<Fn | string>;
/* eslint-disable no-unused-vars */
type Predicate = (roles: Roles) => Fn;

const filter = (roles: Roles, fn: 'every' | 'some') => (userRoles: string[]) => {
  return roles[fn]((value: typeof userRoles[number] | Fn) => {
    return typeof value === 'string' ? userRoles.includes(value) : value(userRoles);
  });
};

const identity = (f: any) => f;

export const every: Predicate = (roles: Roles) => filter(roles, 'every');
export const some: Predicate = (roles: Roles) => filter(roles, 'some');

export const hasPermission = (
  permissionRules: PermissionRules,
  requiredPermission: keyof typeof permissionRules,
  userRoles: string[],
  superAdminRole?: string
): boolean => {
  if (!!superAdminRole && userRoles.includes(superAdminRole)) {
    return true;
  }
  return permissionRules[requiredPermission]?.(userRoles);
};

export const hasPermissions = (
  permissionRules: PermissionRules,
  requiredPermissions: Array<keyof typeof permissionRules>,
  userRoles: string[],
  superAdminRole?: string
): boolean => {
  const allPermissions = requiredPermissions.map((permission) =>
    hasPermission(permissionRules, permission, userRoles, superAdminRole)
  );
  return allPermissions.every(identity);
};
