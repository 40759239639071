/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon01200VgAtHome01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon01200vgathome01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <circle
        cx={283.67}
        cy={240.73}
        r={25.2}
        fill='currentColor'
        className='cls-1'
        transform='rotate(-13.14 283.63 240.69)'
      />
      <path
        fill='currentColor'
        d='M433.42 218.85 291.68 77.12a11.33 11.33 0 0 0-16 0L133.92 218.85a11.33 11.33 0 0 0-3.32 8V442.3a11.34 11.34 0 0 0 11.34 11.34H425.4a11.34 11.34 0 0 0 11.34-11.34V226.87a11.37 11.37 0 0 0-3.32-8.02zM414.06 431h-105.2v-79.41h5A10.08 10.08 0 0 0 324 341.51V281a10.08 10.08 0 0 0-10.1-10h-60.47a10.08 10.08 0 0 0-10.08 10v60.47a10.08 10.08 0 0 0 10.08 10.08h5V431H153.27V231.56l130.4-130.39 130.39 130.39z'
        className='cls-1'
      />
    </svg>
  );
};
