import * as React from 'react';

import { Box, useTheme } from '@mui/material';

import { useInstanceId, useTranslation, util } from '@vaillant-professional-ui/component-libs-common';
import { ScheduleEditDrawer } from '../ScheduleEditDrawer';
import { ScheduleTable } from '../ScheduleTable';
import { Schedule, ScheduleTableEditorProps } from './ScheduleTableEditor.types';
import { ScheduleProfileItem } from '../../molecules/ScheduleProfileItem';
import { Card, CardContent } from '../../molecules/Card';
import { getTimeWindowDisplayDataFactory } from '../ScheduleTable/ScheduleTable.util';
import { Typography } from '../../atoms/Typography';

export const ScheduleTableEditor = <T extends util.TimeWindow>({
  testIdKey,
  // Editor Props
  timeStepWidthInMinutes,
  maxTimeSlotsPerDay,
  temperatureConfig,
  profiles,
  managedSchedules,
  setManagedSchedules,
  readOnly = false,
}: ScheduleTableEditorProps<T>) => {
  const theme = useTheme();
  const testId = useInstanceId('scheduletableditor', testIdKey);
  const t = useTranslation();

  const [timeWindows, setTimeWindows] = React.useState<T[]>([]);
  const [timeWindowsIndex, setTimeWindowsIndex] = React.useState(-1);
  const [label, setLabel] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);

  const profilesWithIndex = profiles ? util.applyCustomProfileCount(profiles) : undefined;

  const onDialogClose = () => {
    setIsOpen(false);
  };

  const onDialogSubmit = (timeWindows: T[]) => {
    const newSchedules: Schedule<T>[] = managedSchedules.map<Schedule<T>>((schedule, idx) => {
      if (idx === timeWindowsIndex) {
        return { ...schedule, values: [...timeWindows] };
      }
      return { ...schedule };
    });
    setManagedSchedules(newSchedules);
  };

  const onSelectRow = (index: number, values: T[], label: string) => {
    setTimeWindows(values);
    setTimeWindowsIndex(index);
    setLabel(label);
    setIsOpen(true);
  };

  const getTimeWindowDisplayData = (timeWindow: util.TimeWindow) =>
    getTimeWindowDisplayDataFactory(t, timeWindow, theme, '°C', profilesWithIndex, temperatureConfig?.stepSize);

  return (
    <div {...testId('root').testIdAttributes}>
      {isOpen && (
        <ScheduleEditDrawer<T>
          label={label}
          initialTimeWindows={timeWindows}
          profiles={profilesWithIndex}
          isOpen={isOpen}
          onSubmit={onDialogSubmit}
          onClose={onDialogClose}
          timeStepWidthInMinutes={timeStepWidthInMinutes}
          maxTimeSlotsPerDay={maxTimeSlotsPerDay}
          temperatureConfig={temperatureConfig}
          testIdKey={testId('drawer').key}
        />
      )}
      {profilesWithIndex?.length ? (
        <>
          <Box mb={8}>
            <Box mb={4}>
              <Typography i18nKey={'VG_SCHEDULE_EDITOR_PROFILES'} variant='h3' />
            </Box>
            <Card>
              <CardContent>
                <Box display='flex' flexDirection='row' gap={12}>
                  {profilesWithIndex?.map((profile) => {
                    return <ScheduleProfileItem key={profile.id} profile={profile} />;
                  })}
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box mb={4}>
            <Typography i18nKey={'VG_SCHEDULE_EDITOR_SCHEDULE'} variant='h3' />
          </Box>
        </>
      ) : null}
      <ScheduleTable onSelectRow={!readOnly ? onSelectRow : undefined} testIdKey={testId('table').key}>
        {managedSchedules.map((weekdaySchedule, index) => (
          <ScheduleTable.Row
            key={index}
            getTimeWindowDisplayData={getTimeWindowDisplayData}
            label={weekdaySchedule.label}
            values={weekdaySchedule.values}
            testIdKey={testId(`${index}`).key}
          />
        ))}
      </ScheduleTable>
    </div>
  );
};
