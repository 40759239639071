import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

import { features } from '@vaillant-professional-ui/pui-frontend-common';

interface Params extends Record<string, string> {
  boilerSerialNumber: string;
  systemId: string;
  deviceId: string;
}

const getRessourceIdFromUrl = (pathname: string) => {
  return pathname.split('/').filter(Boolean).pop();
};

const navigateToFirstDeviceIfNoneIsSelected = (
  pathname: string,
  systemId: string,
  deviceSelectionOptions: features.systemSettings.systemSettingsOverview.SelectionOption[],
  setDevice: (deviceId: string) => void,
) => {
  if (getRessourceIdFromUrl(pathname) === systemId && deviceSelectionOptions[0]) {
    setDevice(deviceSelectionOptions[0].value);
  }
};

const useRouteToDevice = (
  deviceSelectionOptions: features.systemSettings.systemSettingsOverview.SelectionOption[],
  boilerSerialNumber: string,
  systemId: string,
) => {
  const navigate = useNavigate();
  const setDevice = (deviceId: string) =>
    navigate(`/customers/${boilerSerialNumber}/system-settings/${systemId}/${deviceId}`);

  navigateToFirstDeviceIfNoneIsSelected(useLocation().pathname, systemId, deviceSelectionOptions, setDevice);
  return setDevice;
};

export const usePresenter = () => {
  const { boilerSerialNumber, systemId, deviceId } = useParams<Params>() as Params;

  if (!systemId) {
    throw new Error('No system ID');
  }

  const { deviceSelectionOptions, ...presentationData } = features.systemSettings.systemSettingsOverview.usePresenter({
    boilerSerialNumber,
    systemId,
    deviceId,
  });

  const setDevice = useRouteToDevice(deviceSelectionOptions, boilerSerialNumber, systemId);

  return { ...presentationData, deviceSelectionOptions, setDevice };
};
