import React from 'react';
import Switch from '@mui/material/Switch';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';

interface Props {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked?: boolean;
  testIdKey: string;
}

const SwitchIOS: React.FC<React.PropsWithChildren<Props>> = ({ checked, onChange, testIdKey }) => {
  const testId = useInstanceId('switch', testIdKey);

  return (
    <Switch disableRipple checked={checked} onChange={onChange} value='checkedB' {...testId('root').testIdAttributes} />
  );
};

export default SwitchIOS;
