import { styled } from '@vaillant-professional-ui/component-libs-web';
import { MapContainer as MapContainerBase } from 'react-leaflet';
import { floatingWidth } from './DeviceMap.util';
import { boxShadow } from '../../lib/sharedStyles';

export const MapContainer = styled(MapContainerBase)`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`;

export const FloatingContainer = styled('div')`
  z-index: 1000;
  position: absolute;
  top: ${({ theme }) => theme.spacing(6)};
  left: ${({ theme }) => theme.spacing(6)};
  bottom: ${({ theme }) => theme.spacing(6)};
  background-color: ${({ theme }) => theme.palette.common.white};
  width: ${floatingWidth}px;
  border-radius: 8px;
  overflow-y: auto;
  ${boxShadow}
`;

export const SummaryContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(4)};
  &:hover,
  &.highlighted {
    background-color: ${({ theme }) => theme.palette.grey['300']};
  }
  &.selected {
    background-color: ${({ theme }) => theme.palette.primary.lighter};
  }
`;

export const Link = styled('a')`
  color: ${({ theme }) => theme.palette.primary.main} !important;
`;
