/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon03300VgReadManual01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon03300vgreadmanual01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M244 181.51H142a11.34 11.34 0 1 0 0 22.68h102a11.34 11.34 0 0 0 0-22.68zm0 34.02H142a11.34 11.34 0 0 0 0 22.68h102a11.34 11.34 0 0 0 0-22.68zm0 34.01H142a11.34 11.34 0 1 0 0 22.68h102a11.34 11.34 0 1 0 0-22.68zm-34 34.02h-68a11.34 11.34 0 1 0 0 22.68h68a11.34 11.34 0 1 0 0-22.68z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M465.09 124.82H340.36a84.8 84.8 0 0 0-56.69 21.51A84.82 84.82 0 0 0 227 124.82H102.25a11.34 11.34 0 0 0-11.34 11.34v283.46A11.34 11.34 0 0 0 102.25 431H227a68.38 68.38 0 0 1 48.36 20l8.31 8.3L292 451a68.42 68.42 0 0 1 48.36-20h124.73a11.33 11.33 0 0 0 11.33-11.34v-283.5a11.34 11.34 0 0 0-11.33-11.34zm-11.34 283.47H340.36a90.85 90.85 0 0 0-56.69 19.77A90.87 90.87 0 0 0 227 408.29H113.59V147.5H227a62.38 62.38 0 0 1 44.38 18.38l12.31 12.31L296 165.88a62.37 62.37 0 0 1 44.38-18.38h113.37z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M356.19 245.91v139.7h36.37v-152h-24.11c-7.76.04-12.26 4.54-12.26 12.3zm18.39-36.05c11.85 0 22.47-10.21 22.47-22.47s-10.62-22.88-22.47-22.88c-12.26 0-22.88 10.62-22.88 22.88s10.62 22.47 22.88 22.47z'
        className='cls-1'
      />
    </svg>
  );
};
