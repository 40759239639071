import { OneOfArrayValues } from '../typings/generic';

export const ensureOneOf = (
  value: string | null,
  allowedValues: string[],
  fallback?: OneOfArrayValues<typeof allowedValues>,
): OneOfArrayValues<typeof allowedValues> | null => (value && allowedValues.includes(value) ? value : fallback ?? null);

/**
 * Typeguard that checks if given value is a number.
 * Also considers Infinity, -Infinity, and NaN and returns false for these values.
 * @param {?unknown} [value]
 * @returns {value is Number} true if value is Number
 */
export const isNumber = (value?: unknown): value is number => {
  return Number.isFinite(value);
};

export type GetTypeOfArray<T extends unknown[] | undefined> = T extends (infer U)[] ? U : never;
