import { permissions } from '@vaillant-professional-ui/component-libs-common';
import { Country } from '../typings/models';
import { localisedCountries } from '../i18n';

const { some, every } = permissions;

export type CountryRules = Record<string, Country[]>;

export type Role =
  | 'ANONYMOUS' // No login at all
  | 'AUTHENTICATED' // Owns a token
  | 'LOGIN_DEMO' // Logged in as demo user
  | 'LOGIN_REGULAR' // Logged in using personal credentials
  | 'LOGIN_DELEGATED' // Logged in via external delegation
  | 'COMPANY_EMPLOYEE' // Currently not used
  | 'COMPANY_OWNER' // Currently not used
  | 'SUPERADMIN' // Allowed to do everything;
  | 'TRAINING' // Training mode
  | `COUNTRY_${string}`
  | 'DEVELOPER';

export const rules: permissions.PermissionRules = {
  ADD_CUSTOMER: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  ADD_NOTE: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  EDIT_NOTE: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  CHECK_ARBITRARY_DTC: some(['LOGIN_REGULAR', 'LOGIN_DEMO', 'LOGIN_DELEGATED']),
  DELETE_NOTE: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  LOAD_CUSTOMER_LIST_CONTINUOUSLY: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  LOAD_LOYALTY_REGISTRATIONS: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  REMOVE_CUSTOMER: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_CODE_ASSISTANT: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_NOTES: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_CUSTOMER_LIST: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_CUSTOMER_DETAIL: some(['AUTHENTICATED', 'LOGIN_DELEGATED']),
  VIEW_DASHBOARD: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_FEEDBACK: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_NAVBAR_LOGIN: some(['LOGIN_DELEGATED']),
  VIEW_NOTIFICATIONS: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_QR_CODE_SCAN_SCREEN: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_PAYMENT_PAGE: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_SETTINGS: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_SIDEBAR: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_USERNAME: some(['LOGIN_REGULAR', 'LOGIN_DEMO']),
  VIEW_WATERPRESSURE_PREDICTION: every([some(['LOGIN_REGULAR', 'LOGIN_DEMO', 'LOGIN_DELEGATED'])]),
  COUNTRY_UK: every([some(['LOGIN_REGULAR', 'LOGIN_DEMO', 'LOGIN_DELEGATED']), some(['COUNTRY_GB'])]),
};

export const allCountriesExcept = (countriesToExclude: Country[]) => {
  const allCountries = Object.keys(localisedCountries) as Array<Country>;
  return allCountries.filter((country: Country) => !countriesToExclude.includes(country));
};

export const countryAvailabililty: CountryRules = {
  ADD_CUSTOMER: allCountriesExcept(['IT']),
  LOAD_LOYALTY_REGISTRATIONS: allCountriesExcept(['CH']),
  VIEW_QR_CODE_SCAN_SCREEN: allCountriesExcept(['DE']),
  VIEW_WATERPRESSURE_PREDICTION: allCountriesExcept(['ES']),
};

export type Permission = keyof typeof rules;
