import { styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;

  > div {
    border: 1px ${({ theme }) => theme.palette.grey['500']} solid;
    border-right: none;
  }

  > div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  > div:nth-child(2) {
    flex-grow: 1;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      flex-grow: 0;
    }
  }

  > div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px ${({ theme }) => theme.palette.grey['500']} solid;
  }
`;
