import React from 'react';
import { useConsumptionDetailsContext } from '../../pages/ConsumptionPage';
import { EmfChart } from '../EmfChart';

export const GasConsumptionDetails: React.FC = () => {
  const { filters, data, isLoading } = useConsumptionDetailsContext();
  return (
    <EmfChart
      buckets={data.categories.primaryConsumed.buckets}
      dateRangePeriod={filters.dateRangePeriod}
      emfCategory='primaryConsumed'
      maxDomainY={data.categories.primaryConsumed.highestValueByResolution}
      totalEnergyValue={data.categories.primaryConsumed.total}
      isLoading={isLoading}
    />
  );
};
