import React from 'react';

import { SortDirection } from '../../organisms/VirtualizedTable';
import { Sorting } from './VirtualizedTable.types';

export interface SortingProps<Data, Column> {
  data: Data[];
  columns: Column[];
  initialSorting: Sorting;
}
export const useSorting = <Data, Column extends { sort?: (data: Data[], sortDirection: SortDirection) => Data[] }>({
  data,
  columns,
  initialSorting,
}: SortingProps<Data, Column>) => {
  const [sorting, setSorting] = React.useState(initialSorting);

  const isSortingActive = (index: number) => index === sorting.columnIndex;
  const direction = (index: number) => (isSortingActive(index) ? sorting.direction : 'asc');
  const onClickHandler = (index: number) => () =>
    setSorting({
      columnIndex: index,
      direction: !isSortingActive(index) ? sorting.direction : sorting.direction === 'asc' ? 'desc' : 'asc',
    });

  const sortedData = columns[sorting.columnIndex]?.sort?.(data, sorting.direction) || data;

  return {
    isSortingActive,
    direction,
    onClickHandler,
    sortedData,
  };
};
