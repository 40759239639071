import { IconButton } from '@mui/material';
import { styled } from '../../../util/styling';
import { CardActionArea } from '../Card';

export const ExpandMore = styled(IconButton)<{ $isExpanded: boolean }>`
  transform: ${({ $isExpanded }) => (!$isExpanded ? 'rotate(0deg)' : 'rotate(180deg)')};
  margin-left: auto;
  transition: ${({ theme }) =>
    theme.transitions.create('transform', {
      duration: theme.transitions.duration.short
    })};
`;

export const ActionArea = styled(CardActionArea)`
  + .MuiCollapse-root .MuiCardContent-root:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(-4)};

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-top: ${({ theme }) => theme.spacing(-6)};
    }
  }
`;
