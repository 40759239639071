import React from 'react';

import { Outlet, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { api, hooks, selectors, useFeatureToggle } from '@vaillant-professional-ui/pui-frontend-common';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  Chip,
  PageHeader,
  SwitcherButtonGroup,
  SwitcherButtonGroupProps,
  Typography,
} from '@vaillant-professional-ui/component-libs-web';

import { usePermissions } from '@vaillant-professional-ui/component-libs-common';
import { nonNullable } from '@vaillant-professional-ui/pui-frontend-common/src/util';

import { CustomerDetailBreadcrumbsContainer } from '../../../components/molecules/CustomerDetailBreadcrumbs';
import { useBoilerSerialNumberFromPath } from '../../../components/Routes';
import { DateRangePickerContainer } from '../../historyCharts/DateRangePicker';

const useIsHeatpump = () => {
  const boilerSerialNumber = useBoilerSerialNumberFromPath();
  const { customer } = api.useCases.useCustomerDetails(boilerSerialNumber);
  const heatingDeviceType = customer && selectors.customer.getHeatingDeviceType(customer);
  const isHeatPump = heatingDeviceType === 'HEAT_PUMP';
  return isHeatPump;
};

const useShouldDisablePredictions = () => {
  const { hasPermissions: hasPermissionForWaterpressure } = usePermissions(['VIEW_WATERPRESSURE_PREDICTION']);
  return useIsHeatpump() || !hasPermissionForWaterpressure;
};

const useNavigationButtonList = () => {
  const { hasFeature } = useFeatureToggle();
  const hasTimeSeriesPermission = hasFeature('time-series');

  const t = hooks.useTranslation();
  const shouldDisablePredictionButton = useShouldDisablePredictions();
  const buttons = [
    { value: 'time-series', children: <Typography>{t('SG_MONITORING_DETAILS_TIME_SERIES')}</Typography> },
    hasTimeSeriesPermission
      ? {
          value: 'expert-mode-time-series',
          children: (
            <>
              <Typography>{t('SG_MONITORING_DETAILS_TIME_SERIES')}</Typography>
              <Box ml={2}>
                <Chip color='primary' label='BETA' size='small' testIdKey='mock' variant='filled' />
              </Box>
            </>
          ),
        }
      : undefined,
    {
      disabled: shouldDisablePredictionButton,
      value: 'f22-predictions',
      children: <Typography>{t('SG_MONITORING_DETAILS_PREDICTIONS')}</Typography>,
    },
  ].filter(nonNullable);

  return buttons;
};

const useHandleNavigation = (buttons: SwitcherButtonGroupProps['buttons']) => {
  const navigate = useNavigate();

  const setNavigationTab = (value: string) => {
    navigate(`./${value}`);
  };

  const location = useLocation();
  const navigationTab =
    buttons.map((b) => b.value).find((value) => location.pathname.includes(`/${value}`)) ?? 'time-series';

  return { navigationTab, setNavigationTab };
};

const usePresenter = () => {
  const buttons = useNavigationButtonList();
  const { navigationTab, setNavigationTab } = useHandleNavigation(buttons);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const shouldShowDateRangePicker = !isMobile && navigationTab === 'expert-mode-time-series';

  return { buttons, navigationTab, setNavigationTab, shouldShowDateRangePicker };
};

interface Props {
  buttons: SwitcherButtonGroupProps['buttons'];
  navigationTab: string;
  setNavigationTab: (val: string) => void;
  shouldShowDateRangePicker: boolean;
}

const MonitoringDetailsLayout: React.FC<Props> = ({
  buttons,
  navigationTab,
  setNavigationTab,
  shouldShowDateRangePicker,
}) => {
  return (
    <Box width={'100%'}>
      <CustomerDetailBreadcrumbsContainer />
      <PageHeader i18nKey='SG_MONITORING_DETAILS_TITLE' />
      <Box mb={8} display='flex' justifyContent='space-between' alignItems='center' flexDirection='row' gap={2}>
        <SwitcherButtonGroup
          scrollableTabsOnMobile
          exclusive
          buttons={buttons}
          selectedValue={navigationTab}
          onChange={(_, value) => setNavigationTab(value)}
          testIdKey='monitoring-tabs'
        />
        {shouldShowDateRangePicker ? <DateRangePickerContainer /> : null}
      </Box>
      <Outlet />
    </Box>
  );
};

export const MonitoringDetailsLayoutContainer: React.FC = () => {
  const props = usePresenter();
  return <MonitoringDetailsLayout {...props} />;
};
