import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from '../../hooks';
import { productApi } from './product.endpoints';
import { Country, Document } from '../../../typings/models';
import {
  DocumentListResponse,
  ProductListResponse,
  ProductListResponseEntry,
  ProductReportRequest,
  ProductReportResponse,
  TroubleCodeInsightResponse,
  TroubleCodeInsightsResponse,
  UseProductReportParams,
  UseTroubleCodeInsightParams,
  UseTroubleCodeInsightsParams,
} from './product.types';
import { UseQueryOptions } from 'react-query';
import { useLoggingContext } from '../../../contexts/loggingContext';

export const useTroubleCodeInsights = ({ country, articleNumber }: UseTroubleCodeInsightsParams, options = {}) => {
  return useQuery<TroubleCodeInsightsResponse, AxiosError, TroubleCodeInsightsResponse>(
    ['troubleCodeInsights', country as string, articleNumber],
    productApi.getTroubleCodeInsights(country, articleNumber),
    {
      select: (result) => result.data,
      ...options,
    },
  );
};

export const useTroubleCodeInsight = (
  { language, country, articleNumber, code }: UseTroubleCodeInsightParams,
  options: UseQueryOptions<AxiosResponse<TroubleCodeInsightResponse>, AxiosError, TroubleCodeInsightResponse> = {},
) => {
  const { reportEvent } = useLoggingContext();
  const troubleCodeInsightConfig = productApi.getTroubleCodeInsight(country, articleNumber, code, language);
  return useQuery<TroubleCodeInsightResponse, AxiosError, TroubleCodeInsightResponse>(
    ['troubleCodeInsight', language as string, country as string, articleNumber, code],
    troubleCodeInsightConfig,
    {
      select: (result) => result.data,
      onError: (err) => reportEvent('dtc-missing', { articleNumber, country, code, err: JSON.stringify(err) }),
      ...options,
    },
  );
};

export const useProductReport = (
  { country, language, serialNumber, troubleCodes }: UseProductReportParams,
  options = {},
) => {
  const productReportRequests: ProductReportRequest[] = [{ country, language, serialNumber, troubleCodes }];
  return useQuery<ProductReportResponse, AxiosError, ProductReportResponse>(
    ['troubleCodeInsights', 'from-report', country as string, language as string, serialNumber, ...troubleCodes.sort()],
    productApi.getProductReports(productReportRequests),
    {
      select: (result) => {
        return result.data;
      },
      ...options,
    },
  );
};

const selectSanitiseAndFilterProductArticleNumbers = (products: ProductListResponse) =>
  products
    .map((product) => {
      // sanitize articleNumber
      const articleNumber = product.articleNumber.toString().trim();
      return {
        ...product,
        articleNumber,
      };
    })
    .filter((product) => product.articleNumber.match(/^\d*$/));

export const useProductList = (country: Country, brand: string, options = {}) => {
  const byMarketingName = (lhs: ProductListResponseEntry, rhs: ProductListResponseEntry) => {
    /*
      HACK: Android will crash when comparing empty strings with localeCompare
      see https://github.com/facebook/react-native/issues/32174
      TODO: Remove this hack after upgrading to RN 0.69 or higher
    */
    return (lhs.marketingName ?? '0').localeCompare(rhs.marketingName ?? '0');
  };

  return useQuery<ProductListResponse, AxiosError, ProductListResponse>(
    ['allProducts', country as string, brand],
    productApi.getAllProducts(country, brand),
    {
      select: (result) => {
        // TODO: sanitizing and filtering should be done in the backend (PUI-10192, PUI-9833)
        // Remove this selector after those issues are fixed
        return selectSanitiseAndFilterProductArticleNumbers(result.data ?? []).sort(byMarketingName);
      },
      ...options,
    },
  );
};

export const useDocumentList = (country: Country, articleNumber: string, options = {}) => {
  return useQuery<DocumentListResponse, AxiosError, Document[]>(
    ['documentList', country as string, articleNumber],
    productApi.getMediaFiles(country, articleNumber),
    {
      select: (result) => {
        return result.data;
      },
      ...options,
    },
  );
};
