/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon03500VgWarning01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon03500vgwarning01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M503.05 431 293.29 67.64a11.34 11.34 0 0 0-19.64 0L63.88 431a11.34 11.34 0 0 0 9.82 17h419.53a11.34 11.34 0 0 0 9.82-17zm-409.71-5.71L283.47 96l190.12 329.3z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.47 358.05c-12.37 0-22.68 10.72-22.68 23.09a22.68 22.68 0 0 0 45.36 0c0-12.37-10.31-23.09-22.68-23.09zm-18.55-187.43v100.22l7.41 63.74h22.27l7.84-63.74V158.25h-25.15c-7.84 0-12.37 4.12-12.37 12.37z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
