import { deburr } from 'lodash';
import React from 'react';
import { selectors, util } from '../../..';
import { hasConsentGiven } from '../../selectors/customer';
import { useCase } from './useCase';

export interface CustomerViewModel {
  addressLine1: string;
  addressLine2: string;
  nomenclature?: string | null;
  marketingName: string | null;
  fullName: string;
  singlePageHref: string;
  boilerSerialNumber: string;
  id: string;
  customerRemoveKey: string;
}

const tokenize = (input?: string | null): string => {
  return deburr(input || '').toLowerCase();
};

export const searchCustomer = (customers: CustomerViewModel[], searchTerm: string): CustomerViewModel[] => {
  return customers.filter((customer) => {
    return [
      customer.addressLine1,
      customer.addressLine2,
      customer.boilerSerialNumber,
      customer.nomenclature,
      customer.marketingName,
      customer.fullName,
    ]
      .map(tokenize)
      .join('-')
      .includes(tokenize(searchTerm));
  });
};

export const usePresenter = () => {
  const { data, error, isLoading, refetch, hasPermissions } = useCase();
  const [query, setQuery] = React.useState('');
  const customersWithConsent = data?.filter(hasConsentGiven);
  const customers = customersWithConsent;
  const hasNoCustomers = customers?.length === 0 && !isLoading && !error;

  const customersViewModel: CustomerViewModel[] =
    customers?.map((customer) => {
      const address = customer.endUser?.address;
      const boiler = selectors.customer.getBoiler(customer);

      const content: CustomerViewModel = {
        addressLine1: address ? (util.address.formatAddress(address, hasPermissions, false) as string) : '',
        addressLine2: address ? [address?.postalCode ?? '', address?.city ?? ''].join(' ').trim() : '',
        boilerSerialNumber: selectors.customer.getBoilerSerialNumber(customer),
        fullName: selectors.customer.getEndUserFullName(customer, true) || '',
        id: customer?.system?.systemId,
        singlePageHref: '/customers/' + customer.system.boiler?.serialNumber,
        marketingName: boiler ? selectors.boiler.getMarketingName(boiler) : '',
        nomenclature: boiler ? selectors.boiler.getNomenclature(boiler) : '',
        customerRemoveKey: customer?.system?.gateway?.serialNumber || customer?.system?.boiler.serialNumber || '',
      };

      return content;
    }) || [];

  const filteredItems = query ? searchCustomer(customersViewModel, query) : customersViewModel;

  // this means there are customers already, but none of them match the search result
  const hasNoSearchResult = customersViewModel.length && !!query && filteredItems.length === 0 ? true : false;

  return {
    customers: filteredItems,
    hasNoSearchResult,
    isLoading,
    refetch,
    error,
    query,
    setQuery,
    hasNoCustomers,
  };
};
