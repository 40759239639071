import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';

interface LegendProps {
  timeSeries: { id: string; color: string; title: string }[];
  maxTimeSeries: number;
}

const usePresenter = ({ timeSeries, maxTimeSeries }: LegendProps) => {
  const t = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const shouldShowTimeSeries = !isMobile;

  const amountAvailableText = t('SG_TIME_SERIES_DATA_POINTS_AVAILABLE', {
    datapoints: timeSeries.length,
    max: maxTimeSeries,
  });

  const isMaxReached = timeSeries.length >= maxTimeSeries;

  return {
    description: isMaxReached ? t('SG_TIME_SERIES_MAX_DATA_POINTS_REACHED') : amountAvailableText,
    shouldShowTimeSeries,
  };
};

export const Legend: React.FC<LegendProps> = (props) => {
  const { description, shouldShowTimeSeries } = usePresenter(props);

  return (
    <Box display='flex' alignItems='center' gap={2}>
      {shouldShowTimeSeries ? (
        <Box display='flex' flex={1} flexWrap='wrap' gap={2}>
          {props.timeSeries.map((ts) => (
            <Box key={ts.id} display='flex' alignItems='center' gap={2} px={3} py={2}>
              <Pill $color={ts.color} />
              <Typography variant='caption'>{ts.title}</Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box flex={1} />
      )}
      <Typography variant='caption'>{description}</Typography>
    </Box>
  );
};

const Pill = styled('div')<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 16px;
  height: 3px;
`;
