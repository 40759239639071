/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon37902VgAccount01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon37902vgaccount01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M235.5 235.05a48 48 0 1 1 48 48 48 48 0 0 1-48-48zm229.38 48.42c0 100-81.38 181.41-181.42 181.41S102.05 383.5 102.05 283.47s81.38-181.42 181.41-181.42 181.42 81.38 181.42 181.42zm-119 71a5.67 5.67 0 1 0-11.34 0v79.28q5.77-2 11.34-4.36zm-113.39 0a5.67 5.67 0 1 0-11.34 0v74.93q5.57 2.38 11.34 4.35zm209.76-71c0-87.53-71.21-158.74-158.74-158.74s-158.79 71.21-158.79 158.74a158.56 158.56 0 0 0 62.37 126v-80.65a34 34 0 0 1 34-34h124.74a34 34 0 0 1 34 34v80.69a158.55 158.55 0 0 0 62.37-126.04z'
      />
    </svg>
  );
};
