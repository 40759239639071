import { CollapsibleCardProps } from '@vaillant-professional-ui/component-libs-web';
import Link from '../../Link';

export interface DataPointRepresentation {
  title: string;
  key: string;
  measuredAt?: Date;
  subtitle?: string;
  subline?: string;
  mutationId?: string;
  testIdKey?: string;
  badgeText?: string;
  disabled?: boolean;
  metadata?: { index?: number };
  linkProps?: {
    component?: typeof Link;
    to: string;
  };
}

export interface TitleEntry {
  title: string;
}

export type Entry = DataPointRepresentation[] | TitleEntry;

export interface DataPointsCardProps {
  title: string;
  dataPoints: Entry[];
  cardProps?: Partial<CollapsibleCardProps>;
}

export const isDataPointRepresentationArray = (entry: Entry): entry is DataPointRepresentation[] =>
  Array.isArray(entry);
