import * as React from 'react';

import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';
import { DataCardSkeleton } from './DataCard.skeleton';
import { Card, Chip, Container, IconSpacer, LinkIcon, TitleContainer } from './DataCard.styles';
import { DataCardProps } from './DataCard.types';

export const DataCard = <C extends React.ElementType>(props: React.PropsWithChildren<DataCardProps<C>>) => {
  const {
    icon: Icon,
    variant = 'elevation',
    title,
    badgeText,
    linkProps,
    disabled,
    subtitle,
    testIdKey,
    isLoading,
    isHighlighted,
    subline,
    ...rest
  } = props;

  const testId = useInstanceId('datacard', testIdKey);

  if (isLoading) {
    return (
      <DataCardSkeleton
        variant={variant}
        title={title}
        subtitle={subtitle}
        icon={Icon}
        hasBadge={!!badgeText}
        isHighlighted={isHighlighted}
        isLink={!!linkProps}
        hasSubline={!!subline}
      />
    );
  }

  const titleMarginLeft = !!Icon || variant === 'plain' ? 0 : { xs: 2, lg: 5 };

  return (
    <Container
      as={!linkProps || linkProps.disabled ? 'div' : undefined}
      {...linkProps}
      {...rest}
      $disabled={disabled || linkProps?.disabled}
      $isLink={!!linkProps}
      {...testId('root').testIdAttributes}
    >
      <Card variant={variant} $isHighlighted={isHighlighted} className={variant}>
        <Stack display='flex' flex={1}>
          {!!subline && (
            <Stack direction='row'>
              {!!Icon && <IconSpacer />}
              <Box ml={titleMarginLeft}>
                <Typography noWrap variant='smallCopy' color={disabled ? 'text.disabled' : 'text.secondary'}>
                  {subline}
                </Typography>
              </Box>
            </Stack>
          )}
          <Stack direction='row' alignItems='center' display='flex' flex={1}>
            {!!Icon && (
              <IconSpacer {...testId('icon').testIdAttributes}>
                <Icon color={disabled ? 'text.disabled' : 'primary.main'} width={32} height={32} />
              </IconSpacer>
            )}
            <TitleContainer
              $disabled={disabled}
              {...testId('text').testIdAttributes}
              display='flex'
              flexDirection='row'
              alignItems='center'
              flexWrap='wrap'
              ml={titleMarginLeft}
              flex={1}
            >
              <Box mr={badgeText ? 4 : 0}>
                <Typography variant='h4' color='inherit' disabled={disabled} title={title}>
                  {title}
                </Typography>
              </Box>
              {!!badgeText && <Chip color='primary' label={badgeText} disabled={disabled} />}
            </TitleContainer>
            <Box
              display='flex'
              flex={1}
              justifyContent='flex-end'
              flexDirection='row'
              alignItems='center'
              flexWrap='wrap'
              ml={!!Icon ? 0 : 5}
            >
              {(!!subtitle || !!linkProps) && (
                <Box mx={{ xs: 0, lg: 2 }} display='flex' alignItems='center' justifyContent='center' flexWrap='nowrap'>
                  {!!subtitle && (
                    <Typography
                      variant='h4'
                      color={isHighlighted ? 'text.primary' : 'text.secondary'}
                      disabled={disabled}
                      textAlign='right'
                      minWidth={70}
                    >
                      {subtitle}
                    </Typography>
                  )}
                  {!!linkProps && (
                    <LinkIcon
                      color={disabled || linkProps.disabled ? 'text.disabled' : 'primary.main'}
                      width={32}
                      height={32}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
};
