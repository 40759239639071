import { styled, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Box, TableCell } from '@mui/material';

export const TabsContainer = styled(Box)`
  > div {
    display: flex;
    flex-grow: 1;
    justify-content: stretch;
    align-items: stretch;
  }

  > div > :nth-child(2) {
    position: relative;
    height: 100%;
  }
`;

export const DashboardTableContainer = styled(Box)`
  border-radius: 0px 10px 10px 10px;
  overflow: hidden;
  height: 100%;
`;

export const TableCellWithoutPadding = styled(TableCell)`
  padding-left: 0px;
  padding-right: 0px;
`;

export const CustomerNameStyled = styled(Typography)`
  overflow-wrap: break-word;
`;
