import { useLocation } from 'react-router-dom';

export const fullyQualify = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  return link.href;
};

export const useKeyValueFromHash = (validKeys: string[]) => {
  const { hash } = useLocation();
  if (!hash.startsWith('#')) {
    return null;
  }

  const key = hash.split('#')[1].split('=')[0];
  if (!validKeys.includes(key)) {
    return null;
  }
  const value = hash.split('=')[1];
  return { key, value };
};
