import React, { useCallback, useEffect, useRef } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppConfig } from '../config';
import { useDemoServer as useDemoServerBase } from '../server/demo/hooks';
import { DemoServerParams } from '../server/demo/server.types';
import { store } from '../store/store';
import { TranslationFnc } from '../typings/models';
import { getLocalizedWeekdays } from '../util/date';
import { localeMap, useConfiguration } from '@vaillant-professional-ui/component-libs-common';
import format from 'date-fns/format';

// @see https://usehooks.com/usePrevious/
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useDidUpdate = (func: () => void, deps: unknown[]) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export const useTranslation = (): TranslationFnc => {
  const intl = useIntl();
  return React.useCallback(
    (messageId, values) => {
      return intl.formatMessage({ id: messageId as string }, values) as string;
    },
    [intl],
  );
};

export const useLocalizedWeekdays = (variant: 'long' | 'short' = 'long') => {
  const intl = useIntl();
  return getLocalizedWeekdays(intl.locale, variant).map(capitalize);
};

interface UseDifferencInDays {
  differenceInDays: number;
  differenceInDaysLocalized: string;
}

export const useLocalizedDifferenceInDays: (now: Date) => (ts?: Date | string | number) => UseDifferencInDays | null = (
  now,
) => {
  const intl = useIntl();
  return (ts?) => {
    if (ts === null || ts === undefined) {
      return null;
    }
    const then = new Date(ts);
    const difference = differenceInDays(then, now);
    const localized = intl.formatRelativeTime(difference, 'days');
    return { differenceInDays: difference, differenceInDaysLocalized: localized };
  };
};

export type DateFormatter = (ts: Date, time?: boolean) => string | null;

export const useConfigLocalizedDateFormat = () => {
  const { locale } = useConfiguration();
  return (date: Date, dateFormat: string) =>
    format(date, dateFormat, {
      locale: localeMap[locale as keyof typeof localeMap].dateFns,
    });
};

export const useDateFormatter: () => DateFormatter = () => {
  const intl = useIntl();
  return (ts, time = true) => {
    if (!ts) {
      return null;
    }
    const datePart = `${intl.formatDate(ts)}`;
    const timePart = time ? `, ${intl.formatTime(ts)}` : '';
    return `${datePart}${timePart}`;
  };
};

export const useFormattedDate: DateFormatter = (ts, time = true) => {
  const formatter = useDateFormatter();
  return formatter(ts, time);
};

type VoidFn = () => void;
export const useDialogState = (initial = false): [boolean, VoidFn, VoidFn] => {
  const [isOpen, setIsOpen] = React.useState(initial);
  const open = React.useCallback(() => setIsOpen(true), []);
  const close = React.useCallback(() => setIsOpen(false), []);
  return [isOpen, open, close];
};

export const useDemoServer = (
  alertFnc: DemoServerParams['alert'] = (msg: string) => {
    console.warn('Demo: ', msg);
  },
) => {
  const { apiRootUrl, distribution } = useAppConfig();
  const t = useTranslation();
  const country = useSelector(store.getCountry);

  const { setup, shutDown } = useDemoServerBase({
    alert: alertFnc,
    country,
    brand: distribution.brand.key,
    translate: t,
    urlPrefix: apiRootUrl,
  });

  return { setupDemo: setup, shutdownDemo: shutDown };
};

interface SwitchHandlers {
  on: () => void;
  off: () => void;
  toggle: () => void;
  reset: () => void;
}

/**
 * Hook that can be used to toggle, and switch on/off arbitrary states.
 *
 * Example:
 *  const [isOpen, { on, off, toggle, reset }] = useSwitch(false);
 *
 * @param initialState: boolean
 */
export const useSwitch = (initialState = false): [boolean, SwitchHandlers] => {
  const [state, setState] = React.useState(initialState);

  const handlers = React.useMemo(
    () => ({
      on: () => {
        setState(true);
      },
      off: () => {
        setState(false);
      },
      toggle: () => {
        setState((s) => !s);
      },
      reset: () => {
        setState(initialState);
      },
    }),
    [initialState],
  );

  return [state, handlers];
};

export const useToggleListItem = <T>(initialList: T[]) => {
  const [list, setList] = React.useState<T[]>(initialList);

  const toggle = useCallback(
    (listItem: T) => {
      if (list.includes(listItem)) {
        setList((state) => state.filter((item) => item !== listItem));
      } else {
        setList((state) => [...state, listItem]);
      }
    },
    [list],
  );

  return [list, toggle, setList] as const;
};
