import { Box } from '@mui/material';
import { styled } from '../../../util/styling';

export const Container = styled(Box)`
  & :first-child:not(:only-child) {
    .MuiPaper-root {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px ${({ theme }) => theme.palette.grey['350']} solid;
    }
  }

  & :last-child:not(:only-child) {
    .MuiPaper-root {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  & :not(:first-child):not(:last-child):not(:only-child) {
    .MuiPaper-root {
      border-radius: 0;
      border-bottom: 1px ${({ theme }) => theme.palette.grey['350']} solid;
    }
  }
`;
