import { Country, MapBounds } from '../typings/models';

export const countryBoundariesOnLandMass: Record<Country, MapBounds> = {
  AT: {
    northEast: {
      lat: 48.106691253914796,
      lng: 16.029078018678486,
    },
    southWest: {
      lat: 46.968856526144016,
      lng: 13.17998137358826,
    },
  },
  BE: {
    northEast: {
      lat: 51.09846866404042,
      lng: 5.57342051899341,
    },
    southWest: {
      lat: 50.36997801361956,
      lng: 3.7499665409857093,
    },
  },
  CH: {
    northEast: {
      lat: 46.95102322665632,
      lng: 9.740450197360081,
    },
    southWest: {
      lat: 46.645738068728114,
      lng: 6.9818528265541895,
    },
  },
  CZ: {
    northEast: {
      lat: 49.88923416300184,
      lng: 18.236838766998176,
    },
    southWest: {
      lat: 49.26675537365188,
      lng: 13.200653372486368,
    },
  },
  DE: {
    northEast: {
      lat: 53.78601585688311,
      lng: 11.625170628084138,
    },
    southWest: {
      lat: 49.0839939987875,
      lng: 7.9005471554292965,
    },
  },
  DK: {
    northEast: {
      lat: 56.3892794410325,
      lng: 9.3634609049856,
    },
    southWest: {
      lat: 55.31537746905485,
      lng: 8.718761739926936,
    },
  },

  ES: {
    northEast: {
      lat: 43.18692709766389,
      lng: -2.016361254717986,
    },
    southWest: {
      lat: 37.24915475462493,
      lng: -6.261962518009539,
    },
  },
  EE: {
    northEast: {
      lat: 59.178870433606335,
      lng: 25.88239593764635,
    },
    southWest: {
      lat: 57.401233529590485,
      lng: 24.611206639931993,
    },
  },
  FI: {
    northEast: {
      lat: 63.615678496432594,
      lng: 28.12343126926598,
    },
    southWest: {
      lat: 60.78744090567352,
      lng: 22.97056446984243,
    },
  },
  FR: {
    northEast: {
      lat: 49.078594951067934,
      lng: 5.468120000960506,
    },
    southWest: {
      lat: 43.82221078005492,
      lng: -0.4822712836085331,
    },
  },
  GB: {
    northEast: {
      lat: 53.525057471366196,
      lng: -0.1843419457606917,
    },
    southWest: {
      lat: 50.898726283567406,
      lng: -2.5143170819242076,
    },
  },
  HR: {
    northEast: {
      lat: 45.734846842558916,
      lng: 17.88562561208886,
    },
    southWest: {
      lat: 45.34244232224916,
      lng: 15.449679023211685,
    },
  },
  HU: {
    northEast: {
      lat: 47.67726128218061,
      lng: 21.09051394105103,
    },
    southWest: {
      lat: 46.375537340869386,
      lng: 17.743877895105868,
    },
  },
  IT: {
    northEast: {
      lat: 43.837452,
      lng: 14.766762,
    },
    southWest: {
      lat: 42.166409,
      lng: 9.872353,
    },
  },
  NL: {
    northEast: {
      lat: 52.42665933985901,
      lng: 5.650546953615959,
    },
    southWest: {
      lat: 51.553050433824964,
      lng: 4.730869154181426,
    },
  },
  NO: {
    northEast: {
      lat: 61.08094737284978,
      lng: 10.845338408803116,
    },
    southWest: {
      lat: 59.925477836906936,
      lng: 8.78749645022566,
    },
  },
  PL: {
    northEast: {
      lat: 53.71668761858048,
      lng: 22.76509271277167,
    },
    southWest: {
      lat: 50.5907136554062,
      lng: 16.5356546413507,
    },
  },
  RO: {
    northEast: {
      lat: 47.27549896331202,
      lng: 27.26025703714483,
    },
    southWest: {
      lat: 44.82950826394285,
      lng: 21.71882270313684,
    },
  },
  SE: {
    northEast: {
      lat: 62.0409919222451,
      lng: 16.943153976364954,
    },
    southWest: {
      lat: 59.736566490518214,
      lng: 11.946719603402158,
    },
  },
  SK: {
    northEast: {
      lat: 48.71727798214309,
      lng: 20.603361613577277,
    },
    southWest: {
      lat: 48.131607989870645,
      lng: 17.059820900169058,
    },
  },
  TR: {
    northEast: {
      lat: 39.78560602829046,
      lng: 37.7371032091732,
    },
    southWest: {
      lat: 37.51982506551427,
      lng: 27.290844421817866,
    },
  },
  UA: {
    northEast: {
      lat: 51.041731633620635,
      lng: 33.18991830413026,
    },
    southWest: {
      lat: 48.60771202991601,
      lng: 24.893686991333528,
    },
  },
};

// https://gist.github.com/graydon/11198540 (lng-west, lat-south, lng-east, lat-north)
export const countryBoundaries: Record<Country, MapBounds> = {
  AT: {
    northEast: {
      lat: 49.0390742051,
      lng: 16.9796667823,
    },
    southWest: {
      lat: 46.4318173285,
      lng: 9.47996951665,
    },
  },
  BE: {
    northEast: {
      lat: 51.4750237087,
      lng: 6.15665815596,
    },
    southWest: {
      lat: 49.5294835476,
      lng: 2.51357303225,
    },
  },
  CH: {
    northEast: {
      lat: 47.8308275417,
      lng: 10.4427014502,
    },
    southWest: {
      lat: 45.7769477403,
      lng: 6.02260949059,
    },
  },
  CZ: {
    northEast: {
      lat: 51.1172677679,
      lng: 18.8531441586,
    },
    southWest: {
      lat: 48.5553052842,
      lng: 12.2401111182,
    },
  },
  DE: {
    northEast: {
      lat: 54.983104153,
      lng: 15.0169958839,
    },
    southWest: {
      lat: 47.3024876979,
      lng: 5.98865807458,
    },
  },
  DK: {
    northEast: {
      lat: 57.730016588,
      lng: 12.6900061378,
    },
    southWest: {
      lat: 54.8000145534,
      lng: 8.08997684086,
    },
  },
  EE: {
    northEast: {
      lat: 59.9383754,
      lng: 28.2100175,
    },
    southWest: {
      lat: 57.5092997,
      lng: 21.3826069,
    },
  },
  ES: {
    northEast: {
      lat: 43.7483377142,
      lng: 3.03948408368,
    },
    southWest: {
      lat: 35.946850084,
      lng: -9.39288367353,
    },
  },
  FI: {
    northEast: {
      lat: 70.0922939,
      lng: 31.5867071,
    },
    southWest: {
      lat: 59.4541578,
      lng: 19.0832098,
    },
  },
  // Exception, due to oversea countries
  FR: {
    northEast: {
      lat: 49.078594951067934,
      lng: 5.468120000960506,
    },
    southWest: {
      lat: 43.82221078005492,
      lng: -0.4822712836085331,
    },
  },
  GB: {
    northEast: {
      lat: 58.6350001085,
      lng: 1.68153079591,
    },
    southWest: {
      lat: 49.959999905,
      lng: -7.57216793459,
    },
  },
  HR: {
    northEast: {
      lat: 46.5037509222,
      lng: 19.3904757016,
    },
    southWest: {
      lat: 42.47999136,
      lng: 13.6569755388,
    },
  },
  HU: {
    northEast: {
      lat: 48.585257,
      lng: 22.8977094,
    },
    southWest: {
      lat: 45.737128,
      lng: 16.1138867,
    },
  },
  IT: {
    northEast: {
      lat: 47.1153931748,
      lng: 18.4802470232,
    },
    southWest: {
      lat: 36.619987291,
      lng: 6.7499552751,
    },
  },
  NL: {
    northEast: {
      lat: 53.5104033474,
      lng: 7.09205325687,
    },
    southWest: {
      lat: 50.803721015,
      lng: 3.31497114423,
    },
  },
  NO: {
    northEast: {
      lat: 71.3848787,
      lng: 31.7614911,
    },
    southWest: {
      lat: 57.7590052,
      lng: 4.0875274,
    },
  },
  PL: {
    northEast: {
      lat: 54.8515359564,
      lng: 24.0299857927,
    },
    southWest: {
      lat: 49.0273953314,
      lng: 14.0745211117,
    },
  },
  RO: {
    northEast: {
      lat: 48.2208812526,
      lng: 29.62654341,
    },
    southWest: {
      lat: 43.6884447292,
      lng: 20.2201924985,
    },
  },
  SE: {
    northEast: {
      lat: 69.0599699,
      lng: 24.1776819,
    },
    southWest: {
      lat: 55.1331192,
      lng: 10.5930952,
    },
  },
  SK: {
    northEast: {
      lat: 49.6138162,
      lng: 22.56571,
    },
    southWest: {
      lat: 47.7314286,
      lng: 16.8331891,
    },
  },
  TR: {
    northEast: {
      lat: 42.297,
      lng: 44.8176638,
    },
    southWest: {
      lat: 35.8076804,
      lng: 25.6212891,
    },
  },
  UA: {
    northEast: {
      lat: 52.3350745713,
      lng: 40.0807890155,
    },
    southWest: {
      lat: 44.3614785833,
      lng: 22.0856083513,
    },
  },
};
