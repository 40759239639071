import { useQuery } from '../../hooks';
import { authenticationApi } from './authentication.endpoints';
import { IUserInfo } from '../../../typings/models';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { PermissionsResponse } from './authentication.types';

export const useGetUserInfo = (options: UseQueryOptions<AxiosResponse<IUserInfo>, AxiosError, IUserInfo> = {}) => {
  return useQuery<IUserInfo, AxiosError, IUserInfo>('userInfo', authenticationApi.getUserInfo(), {
    select: (response) => response.data,
    ...options,
  });
};

export const useGetActivePermissions = (
  userContactId: string | null,
  options: UseQueryOptions<AxiosResponse<PermissionsResponse>, AxiosError, PermissionsResponse> = {},
) => {
  return useQuery<PermissionsResponse, AxiosRequestConfig<never>, PermissionsResponse>(
    ['activePermissions', userContactId ?? ''],
    authenticationApi.getActivePermissions(),
    {
      select: (response) => response.data,
      enabled: !!userContactId,
      ...options,
    },
  );
};
