/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon10700VgAnalysis01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon10700vganalysis01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M459.42 368.6H130.6v-22.68h51a11.34 11.34 0 0 0 11.4-11.33v-90.71h39.69v45.35A11.34 11.34 0 0 0 244 300.57h62.37a11.34 11.34 0 0 0 11.33-11.34v-85h39.69v34a11.34 11.34 0 0 0 11.34 11.34h51a11.34 11.34 0 0 0 0-22.68h-39.68v-34a11.34 11.34 0 0 0-11.34-11.34h-62.36a11.34 11.34 0 0 0-11.35 11.3v85h-39.68v-45.31A11.33 11.33 0 0 0 244 221.2h-62.38a11.34 11.34 0 0 0-11.34 11.34v90.71H130.6V153.17a11.34 11.34 0 1 0-22.68 0v226.77a11.34 11.34 0 0 0 11.34 11.34h340.16a11.34 11.34 0 0 0 0-22.68z'
      />
    </svg>
  );
};
