import { config, hooks, store, useAuthContext, useLoggingContext } from '@vaillant-professional-ui/pui-frontend-common';
import { IdmLogin, SrcSet } from '@vaillant-professional-ui/component-libs-web';
import camelCase from 'lodash/camelCase';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as _images from '../../assets/images';
import { buildRedirectUriWithCorrelationParameter } from '../../lib/loginCorrelation';
import { CountryCode } from '@vaillant-professional-ui/component-libs-web/util/i18n';

// alias for correct typing
const images: Record<string, string> = _images;

export const LoginContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const country = useSelector(store.getCountry);
  const t = hooks.useTranslation();
  const appConfig = config.useAppConfig();
  const { reportEvent, reportError } = useLoggingContext();
  const { loginIdm, pendingState } = useAuthContext();

  const enterDemo = () => {
    if (country) {
      navigate(`/demo/${country}`, { replace: true });
    }
  };

  const setCountryCode = (countryCode: CountryCode) => {
    // @ts-ignore: Lots of casting between string, Coutry, CountryCode and other structures inside. Needs further cleanup.
    dispatch(store.setCountry(countryCode));
  };

  const loginViaIdm = React.useCallback(() => {
    const { redirectUri, correlationId } = buildRedirectUriWithCorrelationParameter('dashboard');
    reportEvent('login-redirect', { redirectUri, correlationId });
    loginIdm({ redirectUri }).catch((e) => reportError('keycloak-login', e));
  }, [loginIdm, reportError, reportEvent]);

  const getSourceSets = React.useCallback(() => {
    const brandKey = camelCase(appConfig.distribution.brand.key);
    const srcImages: [SrcSet, SrcSet, SrcSet, SrcSet] = [
      { imageSrc: images[`${brandKey}LoginSM`], width: 398 },
      { imageSrc: images[`${brandKey}LoginMD`], width: 467 },
      { imageSrc: images[`${brandKey}LoginLG`], width: 622 },
      { imageSrc: images[`${brandKey}LoginXL`], width: 924 },
    ];
    return srcImages;
  }, [appConfig.distribution.brand.key]);

  const countries = Object.keys(appConfig.distribution.countries);

  return (
    <IdmLogin
      isPending={pendingState === 'pending'}
      testIdKey='default'
      leftTitle={t('SG_SPLASH_SUBTITLE')}
      onLogin={loginViaIdm}
      onSelectCountry={setCountryCode}
      appStoreLink={appConfig.distribution.login.appStoreLink}
      playStoreLink={appConfig.distribution.login.playStoreLink}
      leftImageSrcSet={getSourceSets()}
      productName={appConfig.distribution.platformName}
      onSelectDemoMode={enterDemo}
      initialCountryCode={country}
      countries={countries}
    />
  );
};
