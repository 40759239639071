import * as api from './src/api';
import * as features from './src/features';
import { RuntimeContextProvider, useRuntimeContext } from './src/contexts/runtimeContext';
import { FeatureConfig, FeatureContextProvider, useFeatureToggle } from './src/contexts/featureContext';
import { LoggingContextProvider, useLoggingContext } from './src/contexts/loggingContext';
import { AuthContextProvider, useAuthContext } from './src/contexts/authContext';
import * as models from './src/typings/models';
import * as types from './src/typings/generic';
import * as testing from './src/libs/testing';
import * as liveMonitor from './src/api/useCases/LiveMonitor';
import * as selectors from './src/selectors';
import * as permissions from './src/libs/permissions';
import * as emf from './src/api/useCases/Emf';

import {
  i18nConfig,
  I18nProvider,
  getConfigForLanguage,
  getD3Locale,
  getLanguageShort,
  isLanguageSupported,
  languageToCountry,
  countryToLanguage,
  messages,
  localisedCountries,
  countryBoundaries,
  countryBoundariesOnLandMass,
} from './src/i18n';

import * as firebase from './src/services/firebase';
import * as keycloak from './src/services/keycloak';
import * as auth from './src/services/auth';
import * as customers from './src/services/customers';
import * as hooks from './src/libs/hooks';
import * as distributions from './src/distributions';
import * as config from './src/config';
import * as themes from './src/theme';
import * as util from './src/util';
import * as demoserver from './src/server/demo';

import { StoreProvider } from './src/components/meta/StoreProvider';
import { ToastProvider, ToastProps } from './src/components/meta/ToastProvider';
import { withToastMessages } from './src/components/meta/ToastProvider';
import { store } from './src/store/store';

export { useFeedbackForm, PredictionChart, HeatCurveChart } from './src/components/organisms';

export { ErrorBoundary } from './src/components/meta/ErrorBoundary';
export { FeatureToggle } from './src/components/meta/FeatureToggle';
export { PermissionGate, usePermissions } from './src/components/meta/PermissionGate';

export type { I18nKeys, I18nConfig } from './src/i18n';
export type { State } from './src/store/rootReducer';

const useTranslation = hooks.useTranslation;

export {
  api,
  features,
  models,
  i18nConfig,
  I18nProvider,
  getConfigForLanguage,
  getD3Locale,
  getLanguageShort,
  isLanguageSupported,
  languageToCountry,
  countryToLanguage,
  messages,
  localisedCountries,
  countryBoundaries,
  countryBoundariesOnLandMass,
  permissions,
  selectors,
  testing,
  liveMonitor,
  emf,
  auth,
  firebase,
  keycloak,
  config,
  themes,
  hooks,
  distributions,
  // Selected components
  customers,
  util,
  // Fixtures
  // server
  demoserver,
  // Contexts
  RuntimeContextProvider,
  FeatureContextProvider,
  useFeatureToggle,
  ToastProvider,
  withToastMessages,
  useRuntimeContext,
  LoggingContextProvider,
  useTranslation,
  useLoggingContext,
  AuthContextProvider,
  useAuthContext,
  types,
  StoreProvider,
  store,
};

export type { ToastProps, FeatureConfig };
