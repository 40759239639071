import { models } from '../../..';
import { getLanguageShort } from '../../i18n/config';
import { Country, IUserInfo, Language } from '../../typings/models';
import { GeneralAction } from '../generalActions';
import { Actions, ActionTypes } from './settings.actions.types';

export interface IReadNotification {
  [notificationId: string]: string[];
}

export interface ISettingsState {
  systemLanguage: string;
  readNotifications: IReadNotification;
  userLanguage?: string;
  country: Country;
  termsAccepted?: string[];
  cookiesAccepted?: boolean;
  userInfo: IUserInfo | null;
  hasDismissedMaxDatapointsWarning: boolean;
}

export const initialState: ISettingsState = {
  country: 'GB',
  systemLanguage: 'en',
  readNotifications: {},
  userInfo: null,
  hasDismissedMaxDatapointsWarning: false,
} as const;

const settingsReducer = (state = initialState, action: Actions | GeneralAction): ISettingsState => {
  switch (action.type) {
    case ActionTypes.SET_USER_LANGUAGE: {
      return { ...state, userLanguage: action.payload };
    }
    case ActionTypes.SET_COUNTRY:
      return { ...state, country: action.payload, cookiesAccepted: undefined };
    case ActionTypes.SET_TERMS_ACCEPTED:
      return { ...state, termsAccepted: [...(state.termsAccepted || []), action.payload] };
    case ActionTypes.SET_COOKIES_ACCEPTED:
      return { ...state, cookiesAccepted: action.payload };
    case ActionTypes.SET_NOTIFICATION_READ: {
      const { notificationId, contactId } = action.payload;
      return {
        ...state,
        readNotifications: {
          ...state.readNotifications,
          [notificationId]: Array.from(new Set([...(state.readNotifications[notificationId] || []), contactId])),
        },
      };
    }
    case ActionTypes.SET_USER_INFO:
      return { ...state, userInfo: action.payload };

    case ActionTypes.SET_DISMISS_MAX_DATAPOINTS_WARNING:
      return { ...state, hasDismissedMaxDatapointsWarning: action.payload };

    case 'APP/RESET': {
      return { ...state, userInfo: null };
    }

    default:
      return state;
  }
};

export const getBrowserLanguage = (state: ISettingsState, primary?: boolean): string =>
  primary ? getLanguageShort(state.systemLanguage) : state.systemLanguage;

export const getUserLanguage = (state: ISettingsState, primary?: boolean): string | undefined =>
  primary && state.userLanguage ? getLanguageShort(state.userLanguage) : state.userLanguage;

export const getLanguage = (state: ISettingsState, primary?: boolean): Language => {
  const comparisonLanguage = state.userLanguage || state.systemLanguage || 'en';
  const language = primary ? getLanguageShort(comparisonLanguage) : comparisonLanguage;
  return language as Language;
};

export const getCountry = (state: ISettingsState): Country => state.country;
export const getIsTermsAccepted = (state: ISettingsState, accountId?: string): boolean =>
  accountId ? (state.termsAccepted || []).includes(accountId) : false;

export const getIsCookiesAccepted = (state: ISettingsState): boolean | undefined => state.cookiesAccepted;

export const getReadNotifications: (state: ISettingsState, contactId: string) => string[] = (state, contactId) => {
  return Object.keys(state.readNotifications).filter((notificationId) =>
    state.readNotifications[notificationId].includes(contactId),
  );
};

export const getUserInfo = (state: ISettingsState): models.IUserInfo | null => state.userInfo;

export const isNotificationRead = (state: ISettingsState, notificationId: string, contactId: string): boolean =>
  getReadNotifications(state, contactId).includes(notificationId);

export const getHasDismissedMaxDatapointsWarning = (state: ISettingsState): boolean =>
  state.hasDismissedMaxDatapointsWarning;

export default settingsReducer;
