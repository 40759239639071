import React from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { api } from '@vaillant-professional-ui/pui-frontend-common';

import { useLocation } from 'react-router';
import { DetailsLink } from '../../atoms/DetailsLink';
import { SectionHeadline } from '../../atoms/SectionHeadline';
import { HintText } from '../../molecules/HintText';
import { useConsumptionDetailsContext } from '../../pages/ConsumptionPage';
import { SmallCard } from '../../pages/ConsumptionPage/ConsumptionPage.styled';
import { util } from '@vaillant-professional-ui/pui-frontend-common';
import { DataPointCardItem, HeroDataPointCardItem } from '@vaillant-professional-ui/component-libs-web';
import { Icon00100VgHeating01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon00300VgDomesticHotWater01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon02000VgCooling01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon13000VgElectric01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon00500VgBurner01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon06600VgEcoMode01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon16100VgSolarCollector01 } from '@vaillant-professional-ui/component-libs-web';
import { ContentContainer, CopContainer } from './ConsumptionOverview.styles';

export const ConsumptionOverview: React.FC = () => {
  const t = useTranslation();
  const {
    filters,
    hasCop,
    data: { efficiency, categories },
    isLoading,
    mainHeatingDeviceType,
    isHybridSystem,
  } = useConsumptionDetailsContext();

  const shouldShowGasConsumption = mainHeatingDeviceType === 'BOILER' || isHybridSystem;
  const isHintTextShown = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const hintText = t('SG_EMF_ENERGY_EFFICIENCY_EXPLANATION');
  const formatNumber = util.l10n.useNumberFormatWithFallback();
  const formatValue = (value: number) => formatNumber(value);
  const { search } = useLocation();

  return (
    <ContentContainer $hasCop={hasCop}>
      {hasCop && (
        <CopContainer>
          <SectionHeadline i18nKey='SG_EMF_ENERGY_EFFICIENCY' infoText={!isHintTextShown && hintText} />
          <HeroDataPointCardItem
            testIdKey='scop'
            isLoading={isLoading}
            label={t('SG_TOTAL_EFFICIENCY')}
            value={formatNumber(efficiency.total)}
            additionalValues={[
              {
                label: t('SG_EMF_FILTER_MODE_HEATING'),
                value: formatValue(efficiency.heating),
                unit: '',
                iconComponent: Icon00100VgHeating01,
              },
              {
                label: t('SG_EMF_FILTER_MODE_DHW'),
                value: formatValue(efficiency.dhw),
                unit: '',
                iconComponent: Icon00300VgDomesticHotWater01,
              },
              {
                label: t('SG_EMF_FILTER_MODE_COOLING'),
                value: formatValue(efficiency.cooling),
                unit: '',
                iconComponent: Icon02000VgCooling01,
              },
            ]}
          />

          <Box mt={8} mb={8} sx={{ display: { xs: 'none', md: 'block' } }}>
            <HintText text={hintText} />
          </Box>
        </CopContainer>
      )}
      <Box>
        <SectionHeadline i18nKey='SG_EMF_CONSUMPTION_OVERVIEW' />
        <SmallCard>
          <DataPointCardItem
            testIdKey='electricalConsumption'
            isLoading={isLoading}
            variant='big'
            iconComponent={Icon13000VgElectric01}
            label={t('SG_ELECTRICAL_ENERGY_CONSUMPTION')}
            value={formatValue(categories.electricalConsumed.total)}
            unit={'kWh'}
            severityLevel={'info'}
          />
          {api.useCases.emf.bucketsContainNonemptyValues(categories.electricalConsumed.buckets) && (
            <DetailsLink to={{ pathname: `electrical-consumption`, search }} label={t('SG_MONITORING_DETAILS_LINK')} />
          )}
        </SmallCard>
        {shouldShowGasConsumption && (
          <SmallCard>
            <DataPointCardItem
              testIdKey='gasConsumption'
              isLoading={isLoading}
              variant='big'
              iconComponent={Icon00500VgBurner01}
              label={t('SG_GAS_CONSUMPTION')}
              value={formatValue(categories.primaryConsumed.total)}
              unit={'kWh'}
              severityLevel={'info'}
            />
            {api.useCases.emf.bucketsContainNonemptyValues(categories.primaryConsumed.buckets) && (
              <DetailsLink to={{ pathname: `gas-consumption`, search }} label={t('SG_MONITORING_DETAILS_LINK')} />
            )}
          </SmallCard>
        )}
      </Box>
      <Box>
        <SectionHeadline i18nKey='SG_EMF_YIELD' />
        <SmallCard>
          <DataPointCardItem
            testIdKey='environmentalYield'
            isLoading={isLoading}
            variant='big'
            iconComponent={Icon06600VgEcoMode01}
            label={t('SG_ENVIRONMENTAL_YIELD')}
            value={formatValue(categories.environmentalYield.total)}
            unit={'kWh'}
            severityLevel={'info'}
          />
          {api.useCases.emf.bucketsContainNonemptyValues(categories.environmentalYield.buckets) && (
            <DetailsLink to={{ pathname: `environmental-yield`, search }} label={t('SG_MONITORING_DETAILS_LINK')} />
          )}
        </SmallCard>
        <SmallCard>
          <DataPointCardItem
            testIdKey='solarThermal'
            isLoading={isLoading}
            variant='big'
            iconComponent={Icon16100VgSolarCollector01}
            label={t('SG_SOLAR_THERMAL')}
            value={formatValue(categories.solarYield.total)}
            unit={'kWh'}
            severityLevel={'info'}
          />
          {api.useCases.emf.bucketsContainNonemptyValues(categories.solarYield.buckets) && (
            <DetailsLink to={{ pathname: 'solar-thermal', search }} label={t('SG_MONITORING_DETAILS_LINK')} />
          )}
        </SmallCard>
      </Box>

      {!!filters?.selectedOperationModes.length && (
        <Box>
          <SectionHeadline i18nKey='SG_EMF_GENERATED_ENERGY' />
          {filters.selectedOperationModes.includes('HEATING') && (
            <SmallCard>
              <DataPointCardItem
                testIdKey='heatGenerated'
                isLoading={isLoading}
                variant='big'
                iconComponent={Icon00100VgHeating01}
                label={t('SG_HEAT_GENERATED')}
                value={formatValue(categories.heatingGenerated.total)}
                unit={'kWh'}
                severityLevel={'info'}
              />
              {api.useCases.emf.bucketsContainNonemptyValues(categories.heatingGenerated.buckets) && (
                <DetailsLink to={{ pathname: 'heat-generated', search }} label={t('SG_MONITORING_DETAILS_LINK')} />
              )}
            </SmallCard>
          )}
          {filters.selectedOperationModes.includes('DOMESTIC_HOT_WATER') && (
            <SmallCard>
              <DataPointCardItem
                testIdKey='hotWaterGenerated'
                isLoading={isLoading}
                variant='big'
                iconComponent={Icon00300VgDomesticHotWater01}
                label={t('SG_HOT_WATER_GENERATED')}
                value={formatValue(categories.dhwGenerated.total)}
                unit={'kWh'}
                severityLevel={'info'}
              />
              {api.useCases.emf.bucketsContainNonemptyValues(categories.dhwGenerated.buckets) && (
                <DetailsLink to={{ pathname: 'dhw-generated', search }} label={t('SG_MONITORING_DETAILS_LINK')} />
              )}
            </SmallCard>
          )}
          {filters.selectedOperationModes.includes('COOLING') && (
            <SmallCard>
              <DataPointCardItem
                testIdKey='coolingGenerated'
                isLoading={isLoading}
                variant='big'
                iconComponent={Icon02000VgCooling01}
                label={t('SG_COOLING_GENERATED')}
                value={formatValue(categories.coolingGenerated.total)}
                unit={'kWh'}
                severityLevel={'info'}
              />
              {api.useCases.emf.bucketsContainNonemptyValues(categories.coolingGenerated.buckets) && (
                <DetailsLink to={{ pathname: 'cooling-generated', search }} label={t('SG_MONITORING_DETAILS_LINK')} />
              )}
            </SmallCard>
          )}
        </Box>
      )}
    </ContentContainer>
  );
};
