import { Stack, Tab } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { type Tab as TabType } from './MobileDashboard';
import { MobileDashboardTabsStickyWrapper, MobileDashboardTabsWrapper } from './MobileDashboard.styles';

export const MobileDashboardTabs = ({
  activeTab,
  setActiveTab,
  tabs,
}: {
  activeTab: number;
  setActiveTab: (value: number) => void;
  tabs: TabType[];
}) => {
  return (
    <MobileDashboardTabsStickyWrapper>
      <MobileDashboardTabsWrapper
        value={activeTab}
        variant='scrollable'
        scrollButtons
        onChange={(_, value) => setActiveTab(value)}
      >
        {tabs.map((tab) => {
          const Icon = tab.icon;
          return (
            <Tab
              disabled={tab.disabled}
              label={
                <Stack px={2} py={2} direction={'row'} gap={2} alignItems={'center'}>
                  <Icon width={'32px'} height={'32px'} {...tab.iconProps} />
                  <Typography disabled={tab.disabled}>{`${tab.smallText} (${tab.bigText})`}</Typography>
                </Stack>
              }
              key={tab.smallText}
            />
          );
        })}
      </MobileDashboardTabsWrapper>
    </MobileDashboardTabsStickyWrapper>
  );
};
