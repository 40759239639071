import React, { CSSProperties } from 'react';
import { Translated, useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { TypographyProps } from './Typography.types';
import { TypographyStyled, getTextTransform } from './Typography.styles';
import { useTheme } from '@mui/material/styles';

export const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = ({
  variant = 'body',
  i18nKey,
  i18nOptions,
  testIdKey,
  style = {},
  letterCase = 'default',
  bold,
  color,
  disabled,
  ...rest
}) => {
  const testId = useInstanceId('typography', testIdKey);
  const theme = useTheme();
  const mergedStyle: CSSProperties = {
    ...style,
    textTransform: getTextTransform(letterCase),
  };

  return (
    <Translated
      i18nKey={i18nKey}
      i18nOptions={i18nOptions}
      component={
        <TypographyStyled
          $bold={bold}
          {...rest}
          style={mergedStyle}
          variant={variant}
          sx={{
            // @see https://mui.com/material-ui/migration/v5-component-changes/#remove-color-and-style-override-keys
            color: disabled ? theme.palette.text.disabled : color ?? 'inherit',
          }}
          {...testId().testIdAttributes}
        />
      }
    />
  );
};
