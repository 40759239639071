import React from 'react';

import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { features, hooks } from '@vaillant-professional-ui/pui-frontend-common';

export interface WriteEnumDatapointDrawerContentContainerProps {
  onClose: () => void;
  title: string;
  initialValue: string;
  selectableOptions: features.systemSettings.deviceSettings.OptionViewModel[];
  useMutation: () => (selectedOperationMode: string) => void;
}

export const usePresenter = ({
  onClose,
  title,
  initialValue,
  selectableOptions,
  useMutation,
}: WriteEnumDatapointDrawerContentContainerProps) => {
  const t = hooks.useTranslation();
  const mutate = useMutation();
  const testId = useInstanceId('dialog', 'select-operation-mode');
  const [selectedMode, setSelectedMode] = React.useState<string>(initialValue);

  const handleSubmit = () => {
    mutate(selectedMode);
    onClose();
  };

  const subtitle = t('SYSTEM_SETTINGS_SELECT_DATAPOINT_VALUE', { datapoint: title });
  const selectCaption = t('SYSTEM_SETTINGS_PLEASE_SELECT');

  const formControlLabels = selectableOptions.map((option) => ({
    value: option.value,
    label: option.title,
    ...testId(`radio_button_${option.testIdKey}`).testIdAttributes,
  }));

  const onChangeSelectedMode = (_: unknown, value: string) => setSelectedMode(value);
  const isSaveButtonDisabled = selectedMode === initialValue;

  return {
    title,
    subtitle,
    selectCaption,
    formControlLabels,
    selectedMode,
    onChangeSelectedMode,
    onClose,
    isSaveButtonDisabled,
    handleSubmit,
  };
};
