import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useAuthContext } from './authContext';

import { registerAuthInterceptor } from '../api/tokenInterceptor';
import { store } from '../store/store';

export interface RuntimeContextProps {
  apiClient: AxiosInstance;
}

export interface RuntimeContextValues {
  apiClient: AxiosInstance;
}

export const RuntimeContext = React.createContext<RuntimeContextValues>({
  apiClient: axios.create(),
});

export const RuntimeContextProvider: React.FC<React.PropsWithChildren<RuntimeContextProps>> = ({
  apiClient,
  children,
}) => {
  const { accessToken } = useAuthContext();
  const language = useSelector(store.getLanguage);
  const country = useSelector(store.getCountry);
  const tokenRef = useRef(accessToken);

  const [isInterceptorRegistered, setIsInterceptorRegistered] = useState(false);

  tokenRef.current = accessToken;

  React.useEffect(() => {
    const eject = registerAuthInterceptor(apiClient, country, language, () => tokenRef.current);
    setIsInterceptorRegistered(true);
    return () => {
      setIsInterceptorRegistered(false);
      eject();
    };
  }, [apiClient, country, language]);

  if (!isInterceptorRegistered) {
    return null;
  }

  return <RuntimeContext.Provider value={{ apiClient }}>{children}</RuntimeContext.Provider>;
};

export const useRuntimeContext = () => React.useContext(RuntimeContext);

RuntimeContext.displayName = 'RuntimeContext';
