export const colors = {
  primary: '#CC0033',
  primaryLight: '#E31045',
  primaryLighter: '#F1557C',
  primaryLightest: '#FFDEDE',
  primaryDark: '#950025',
  primaryDarker: '#74001D',
  primaryDarkest: '#5B0017',

  secondary: '#00AFD7',
  secondaryLight: '#84DCF0',
  secondaryLightest: '#CEF2FA',

  success: '#82BA00',
  successLight: '#B1D958',
  successLightest: '#E6F4C6',

  warning: '#F39C12',
  warningLight: '#F8C576',
  warningLightest: '#FFF6CE',

  error: '#971B2F',
  errorLight: '#D46072',
  errorLightest: '#F9DEE5'
};
