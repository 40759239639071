import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import React, { useEffect } from 'react';
import { RouteProps, useLocation } from 'react-router-dom';
import { getNavigationIdForUrl, useFirebase } from '../../providers/firebase';

export const RouteListener: React.FC<React.PropsWithChildren<RouteProps>> = ({ children }) => {
  const firebaseService = useFirebase();
  const location = useLocation();
  const prevPathname = hooks.usePrevious<string>(location?.pathname ?? '');

  useEffect(() => {
    const navigationId = getNavigationIdForUrl(location?.pathname ?? '');
    const prevNavigationId = getNavigationIdForUrl(prevPathname ?? '');

    if (navigationId === prevNavigationId) {
      return;
    }

    !!navigationId &&
      firebaseService.logEvent(navigationId.toString(), {
        eventCategory: prevNavigationId ? 'navigation' : 'pageView',
        eventView: prevNavigationId ?? '',
      });

    window.ineum?.('page', navigationId?.toString() ?? location?.pathname ?? '');
  }, [firebaseService, location?.pathname, prevPathname]);

  return <>{children}</>;
};
