import { endOfDay, format, startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

/**
 * Create some timezone aware date-fn functions
 * @see https://stackoverflow.com/a/72372517/146283
 */

// eslint-disable-next-line @typescript-eslint/ban-types
const calcZonedDate = (date: Date, tz: string, fn: Function, options: unknown = null) => {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
};

const zonedFns = {
  startOfDay,
  endOfDay,
};
export const zoned = Object.keys(zonedFns).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (date: Date, timezone: string, options: unknown = null) =>
      calcZonedDate(date, timezone, zonedFns[key as keyof typeof zonedFns], options),
  }),
  {},
  // eslint-disable-next-line @typescript-eslint/ban-types
) as Record<keyof typeof zonedFns, Function>;

/**
 * Date format which will be used to display the currently selected date between the arrow buttons
 */
export const getDateFormat = () => {
  return 'PP';
};

/**
 * Date format which will be used to display the currently selected date within the date sheet
 */ export const getHeaderTitleFormat = () => {
  return 'MMMM yyyy';
};

/**
 * Date sheet header configuration
 */
export const getHeaderConfig = (locale: Locale, headerProps: ReactDatePickerCustomHeaderProps) => {
  return {
    title: format(headerProps.date, getHeaderTitleFormat(), { locale }),
    decreaseFn: headerProps.decreaseMonth,
    increaseFn: headerProps.increaseMonth,
    decreaseDisabled: headerProps.prevMonthButtonDisabled,
    increaseDisabled: headerProps.nextMonthButtonDisabled,
  };
};
