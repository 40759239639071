/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon66700VgNotification: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon66700vgnotification', unthemedProps);
  return (
    <svg viewBox='0 0 382.675 510.237' {...props}>
      <path
        fill='currentColor'
        d='M134.646 453.545c0 31.31 25.383 56.692 56.693 56.692 31.311 0 56.694-25.382 56.694-56.692zm-90.212-42.52h293.81l-2.92-3.505a70.995 70.995 0 0 1-16.425-45.368V200.197c0-53.018-33.373-101.07-83.043-119.572a14.173 14.173 0 0 1-9.19-14.285c.073-1.02.106-1.833.106-2.56 0-19.538-15.895-35.433-35.433-35.433s-35.433 15.895-35.433 35.433c0 .734.033 1.547.105 2.56a14.175 14.175 0 0 1-9.19 14.286c-49.67 18.5-83.04 66.553-83.041 119.571v161.955a71.001 71.001 0 0 1-16.426 45.368zm324.071 28.346H14.173a14.174 14.174 0 0 1-10.887-23.247l22.292-26.751a42.597 42.597 0 0 0 9.855-27.22l.001-161.956c0-32.428 9.865-63.52 28.528-89.912 16.339-23.105 38.285-41.113 63.878-52.511C130.879 25.412 158.198 0 191.339 0c33.142 0 60.461 25.41 63.5 57.771 25.592 11.4 47.539 29.408 63.877 52.512 18.664 26.393 28.529 57.485 28.529 89.913l-.001 161.955a42.605 42.605 0 0 0 9.856 27.222l22.292 26.75a14.172 14.172 0 0 1-10.887 23.247'
      />
    </svg>
  );
};
