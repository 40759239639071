import { AxiosError, AxiosResponse } from 'axios';
import { AddCustomerRequest, FeedbackRequest, RemoveCustomerRequest } from './management.types';
import { UseMutationOptions, useQueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from '../../hooks';
import { managementApi } from './management.endpoints';
import { AddCustomerResponse, IPaymentPlan, LoyaltyRegistration } from '../../../typings/models';
import { transformData } from '../transforms';

export const useSendFeedback = (options = {}) => {
  return useMutation<unknown, unknown, FeedbackRequest>(
    (feedbackData) => managementApi.sendFeedback(feedbackData),
    options,
  );
};

export const useAddCustomer = (options = {}) => {
  return useMutation<AddCustomerResponse, unknown, AddCustomerRequest>((data) => managementApi.addCustomer(data), {
    ...options,
  });
};

export const useRemoveCustomer = (options = {}) => {
  return useMutation<unknown, unknown, RemoveCustomerRequest>((data) => managementApi.removeCustomer(data), {
    ...options,
  });
};

export const useResendConsent = () => {
  return useMutation<unknown, unknown, string>((boilerSerialNumber) => managementApi.resendConsent(boilerSerialNumber));
};

export const useGetLoyaltyRegistrations = (
  options: UseQueryOptions<AxiosResponse<LoyaltyRegistration[]>, AxiosError, LoyaltyRegistration[]> = {},
) => {
  return useQuery<LoyaltyRegistration[], AxiosError, LoyaltyRegistration[]>(
    'loyaltyRegistrations',
    managementApi.getLoyaltyRegistrations(),
    {
      select: transformData,
      ...options,
    },
  );
};

export const useGetPaymentPlanList = (
  options: UseQueryOptions<AxiosResponse<IPaymentPlan[]>, AxiosError, IPaymentPlan[]> = {},
) => {
  return useQuery<IPaymentPlan[], AxiosError, IPaymentPlan[]>(['paymentPlans'], managementApi.getPaymentPlans(), {
    select: transformData,
    ...options,
  });
};

export const useGetPaymentPlan = (
  planId: string,
  options: UseQueryOptions<AxiosResponse<IPaymentPlan>, AxiosError, IPaymentPlan> = {},
) => {
  return useQuery<IPaymentPlan, AxiosError, IPaymentPlan>(
    ['paymentPlans', planId],
    managementApi.getPaymentPlan(planId),
    {
      select: transformData,
      ...options,
    },
  );
};

interface AcceptPaymentPlanMutationVars {
  planId: string;
}
export const useAcceptPaymentPlan = (
  options: UseMutationOptions<AxiosResponse<void>, AxiosError, AcceptPaymentPlanMutationVars> = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(({ planId }: AcceptPaymentPlanMutationVars) => managementApi.acceptPaymentPlan(planId), {
    onSuccess: () => {
      queryClient.invalidateQueries('paymentPlans');
    },
    ...options,
  });
};
