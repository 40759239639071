import React from 'react';
import { MenuItem } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon02501VgDown01 } from '@vaillant-professional-ui/component-libs-web';
import { ISelectProps } from './Select.types';
import { Label, SelectStyled } from './Select.styled';

import { makeLabelId } from './Select.util';

const SortIcon = ({ ...iconProps }) => <Icon02501VgDown01 width={32} {...iconProps} />;

const Select: React.FC<React.PropsWithChildren<ISelectProps>> = (props) => {
  const { options, label, value, variant, errors = [], inputProps } = props;
  const labelId = makeLabelId(label);

  return (
    <>
      {!!label && !value && (
        <Label id={labelId} variant={variant}>
          {label}
        </Label>
      )}
      <SelectStyled
        labelId={label ? labelId : undefined}
        IconComponent={SortIcon}
        inputProps={{
          padding: 0,
          ...inputProps,
        }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </SelectStyled>
      {errors.length > 0 && <Typography variant='caption'>{errors[0]}</Typography>}
    </>
  );
};

export default Select;
