import { AxiosRequestConfig } from 'axios';

export const diagnosticsApi = {
  getDiagnostics: (gatewaySerialNumber: string): AxiosRequestConfig => {
    return {
      method: 'get',
      url: `/pui/api-gateway/v1/systems/${gatewaySerialNumber}/diagnostics-v2`,
    };
  },
};
