import * as React from 'react';

import { AxiosError } from 'axios';

import { useQueryClient } from 'react-query';

import { getEndUserFullName, isOffline } from '../../../selectors/customer';
import { ActivationStatus, AddCustomerResponse, ICustomerDetail } from '../../../typings/models';
import { useCustomer } from '../../apis/maintenance/v1';
import { useAddCustomer as useAddCustomerApi } from '../../apis/management';
import { I18nKeys } from '../../../i18n/messages';
import { UseAddCustomer } from './Customer.types';
import { useTranslation } from '../../../libs/hooks';
import { isSerialNumberUUID } from '../../../selectors/boiler';
import { useLoggingContext } from '../../../contexts/loggingContext';

type PossibleStatusCodes = Exclude<ActivationStatus, 'COMPLETED' | 'EMAIL_REQUIRED' | 'PENDING_CONSENT'>;

const responseCodeToI18n: Record<PossibleStatusCodes, I18nKeys> = {
  SYSTEM_ACTIVATED: 'SG_CONFIRMATION_CLOUD_NOTIFICATION',
  // the serial number is not associated with a known system,
  // waiting for netatmo synchronization
  // -> we act, as if a new customer with pending consent was added:
  PENDING_SYSTEM: 'SG_CONFIRMATION_CLOUD_NOTIFICATION',
  // provided email is unknown.
  // Either wrong input or not yet synchronized with netatmo, waiting for netatmo synchronization
  // -> we act, as if a new customer with pending consent was added:
  PENDING_CUSTOMER: 'SG_CONFIRMATION_CLOUD_NOTIFICATION',
  // customer already existing, consent pending
  OPT_IN_PENDING: 'SG_ADDCUSTOMER_PENDING',

  AGREEMENT_REQUIRED: 'SG_AGREEMENT_OWN_DEVICE',

  PAYMENT_REQUIRED: 'SG_ADD_CUSTOMER_PAYMENT_REQUIRED',
  SYSTEM_ALREADY_EXISTING: 'SG_ADDCUSTOMER_EXISTS',
  SYSTEM_UNKNOWN: 'SG_ADDCUSTOMER_UNREGISTERED',
  USER_UNKNOWN: 'SG_ADDCUSTOMER_USER_UNKNOWN',
  USER_IS_INSTALLER: 'SG_ADDCUSTOMER_IS_INSTALLER',
  OTHER_INSTALLER: 'SG_ADDCUSTOMER_OTHER_INSTALLER',
  OTHER_CUSTOMER: 'SG_ADDCUSTOMER_OTHER_CUSTOMER',
  INVALID_STATE: 'SG_ADDCUSTOMER_INVALID_STATE',
} as const;

const getTranslationKey = (response: AddCustomerResponse, emailRequired: boolean) => {
  const responseMessage = responseCodeToI18n[response.code as PossibleStatusCodes];

  if (responseMessage === 'SG_CONFIRMATION_CLOUD_NOTIFICATION' && !emailRequired) {
    return 'SG_CONFIRMATION_CLOUD_NOTIFICATION_NO_EMAIL_REQUIRED';
  }

  return responseMessage;
};

export type UseCustomerDetails = (boilerSerialNumber: string) => {
  isFetching: boolean;
  isLoading: boolean;
  customer: ICustomerDetail | null;
  fullName: string | null;
  error: AxiosError<unknown, null> | null;
  errorDescription: string | null;
  refetch: () => Promise<unknown>;
};

export const useCustomerDetails: UseCustomerDetails = (boilerSerialNumber) => {
  const t = useTranslation();
  const isBoilerSerialNumberUUID = boilerSerialNumber ? isSerialNumberUUID(boilerSerialNumber) : false;
  const errorDescription = isBoilerSerialNumberUUID ? t('SG_UUID_SERIALNUMBER_ERROR_MSG') : null;

  const {
    refetch,
    error,
    data: detailsData,
    isFetching,
    isLoading,
  } = useCustomer({
    boilerSerialNumber,
    options: {
      enabled: !isBoilerSerialNumberUUID,
      refetchInterval: (customer) => (customer && isOffline(customer) ? 60 * 1000 : false),
    },
  });

  return {
    customer: detailsData || null,
    fullName: detailsData ? getEndUserFullName(detailsData) : null,
    error: isBoilerSerialNumberUUID ? new AxiosError('Serial number is not correct', '404') : error,
    errorDescription,
    isFetching,
    isLoading,
    refetch,
  };
};

export const useAddCustomer: UseAddCustomer = () => {
  const { reportError } = useLoggingContext();
  const queryClient = useQueryClient();
  const [isEmailRequired, setIsEmailRequired] = React.useState(false);
  const [isAgreementRequired, setIsAgreementRequired] = React.useState(false);
  const {
    mutateAsync,
    data,
    isSuccess,
    isLoading: isPending,
    isError,
    error,
    reset,
  } = useAddCustomerApi({
    onSuccess: () => {
      queryClient.refetchQueries(['customerList']).catch((e) => {
        reportError('customerlist-refetch', e);
      });
    },
  });

  const activationCode = data?.data?.code ?? '';

  React.useEffect(() => {
    activationCode === 'EMAIL_REQUIRED' && setIsEmailRequired(true);
    setIsAgreementRequired(activationCode === 'AGREEMENT_REQUIRED');

    if (isSuccess && !isActionRequired(activationCode) && !resultIsError(activationCode)) {
      queryClient.refetchQueries('customerList').catch((e) => {
        reportError('customerlist-refetch', e);
      });
      queryClient.refetchQueries('loyaltyRegistrations').catch((e) => {
        reportError('loyaltyRegistrations-refetch', e);
      });
    }
  }, [isSuccess, activationCode, queryClient, reportError]);

  const handleReset = () => {
    setIsEmailRequired(false);
    setIsAgreementRequired(false);
    reset();
  };

  const resultIsError = (code: string) => {
    return [
      'PAYMENT_REQUIRED',
      'SYSTEM_ALREADY_EXISTING',
      'SYSTEM_UNKNOWN',
      'USER_UNKNOWN',
      'USER_IS_INSTALLER',
      'OTHER_INSTALLER',
      'OTHER_CUSTOMER',
      'INVALID_STATE',
    ].includes(code);
  };

  const isActionRequired = (code: string) => ['EMAIL_REQUIRED', 'AGREEMENT_REQUIRED'].includes(code);

  const localizedAddCustomerResponse = data
    ? {
        ...data,
        responseId: getTranslationKey(data?.data, isEmailRequired),
      }
    : null;

  const addCustomer = async (serialNumber: string, email: string, agreementGiven?: boolean) => {
    await mutateAsync({
      serialNumber,
      email,
      selfRegistrationAgreementGiven: agreementGiven,
    });
  };

  return {
    addCustomer,
    actionRequired: isActionRequired(activationCode),
    isErrorCode: resultIsError(activationCode),
    agreementRequired: isAgreementRequired,
    emailRequired: isEmailRequired,
    data: data?.data,
    error,
    isPending,
    isSuccess,
    isError,
    localizedAddCustomerResponse,
    reset: handleReset,
  };
};

export const useCustomerName = (
  boilerSerialNumber: string,
  options: Parameters<typeof useCustomer>[0]['options'],
): string => {
  const { data: customer } = useCustomer({ boilerSerialNumber, options });
  return customer ? getEndUserFullName(customer) : '';
};
