import React from 'react';

import { TableCell, TableRow, TableSortLabel } from '@mui/material';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { useNavigateWithCommandClick } from '../../lib/hooks';
import { SortIcon } from '../atoms/SortIcon';
import { useSorting, VirtualizedTable } from '../organisms/VirtualizedTable';
import { DashboardTableProps } from './Dashboard.types';
import { getColumns } from './DashboardTable.utils';
import { DashboardTableRow } from './DashboardTableRow';
import { RowActionsMenu, useRowActionsMenuState } from './RowActionsMenu';

export const DashboardTable: React.FC<DashboardTableProps> = ({
  data,
  openRemoveCustomer,
  openResendConsent,
  retryValidation,
  getBoilerMarketingName,
  getBoiler,
  formatCustomerName,
  formatAddress,
}) => {
  const t = hooks.useTranslation();
  const navigate = useNavigateWithCommandClick();
  const handleRowClick = (event: React.MouseEvent, id: string) => {
    navigate(event, `/customers/${id}`);
  };

  const columns = getColumns(t);

  const { sortedData, isSortingActive, direction, onClickHandler } = useSorting({
    data,
    columns,
    initialSorting: { columnIndex: 2, direction: 'desc' },
  });

  const { anchor, onCloseMenu, selectedRowData, onClickRowActions } = useRowActionsMenuState();

  return (
    <>
      <RowActionsMenu
        anchor={anchor}
        onClose={onCloseMenu}
        selectedRowData={selectedRowData}
        retryValidation={retryValidation}
        openRemoveCustomer={openRemoveCustomer}
        openResendConsent={openResendConsent}
      />
      <VirtualizedTable
        onClickRow={handleRowClick}
        data={sortedData}
        style={{ minHeight: 200 }}
        fixedHeaderContent={() => (
          <TableRow>
            {columns.map((column, index) => (
              <TableCell style={{ width: column.width }} key={column.key} align={column.align}>
                <TableSortLabel
                  disabled={!column.sort}
                  hideSortIcon={!column.sort}
                  active={isSortingActive(index)}
                  direction={direction(index)}
                  onClick={onClickHandler(index)}
                  IconComponent={SortIcon}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(_, data) => (
          <DashboardTableRow
            item={data}
            key={data.id}
            onClickRowActions={onClickRowActions}
            getBoilerMarketingName={getBoilerMarketingName}
            getBoiler={getBoiler}
            formatCustomerName={formatCustomerName}
            formatAddress={formatAddress}
          />
        )}
      />
    </>
  );
};
