import { readableColorIsBlack } from 'color2k';

export interface ColorRecord {
  temperature: number;
  color: string;
  textColor: string;
}

export const temperatureColors: ColorRecord[] = [
  { temperature: 10, color: '#002D46', textColor: '#FFF' },
  { temperature: 14, color: '#005787', textColor: '#FFF' },
  { temperature: 18, color: '#00C7E0', textColor: '#000' },
  { temperature: 20, color: '#A4E400', /* '#3ADF5F', */ textColor: '#000' },
  { temperature: 22, color: '#DFDD00', textColor: '#000' },
  { temperature: 24, color: '#FFA700', textColor: '#FFF' },
  { temperature: 26, color: '#F26B38', textColor: '#FFF' },
  { temperature: 27, color: '#FF0000', textColor: '#FFF' }
];

// @Todo Should consider other metrics than celcius as well
export const getColorsForTemperature = (givenTemperature: number): Pick<ColorRecord, 'color' | 'textColor'> => {
  const closestColor =
    [...temperatureColors].reverse().find((record) => record.temperature <= givenTemperature) || temperatureColors[0];

  return {
    color: closestColor.color,
    textColor: closestColor.textColor
  };
};

export const pickTextColorBasedOnBgColorSimple = (
  bgColor: string,
  lightTextColor: string,
  darkTextColor: string
): string => {
  return readableColorIsBlack(bgColor) ? darkTextColor : lightTextColor;
};
