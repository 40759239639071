/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon08001VgBack01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon08001vgback01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M436.54 272.13H157.65L274.48 155.3a11.34 11.34 0 1 0-16-16l-136.3 136.3a11.33 11.33 0 0 0 0 16l136.3 136.3a11.34 11.34 0 1 0 16-16L157.41 294.8h279.13a11.34 11.34 0 0 0 0-22.67z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
