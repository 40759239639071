import invert from 'lodash/invert';
import { TroubleCodes, TroubleCodeInsight, TroubleCodeInsightType } from '../typings/models';

const map: Partial<Record<TroubleCodeInsightType, string>> = {
  ERROR: 'F',
  MAINTENANCE: 'M',
  LIMP_HOME_MODE: 'N',
  STATUS: 'S',
  DIAGNOSIS: 'D',
  LIMP_HOME_MODE_REVERSIBLE: 'L',
};

const colorMap: Partial<Record<TroubleCodeInsightType, string>> = {
  ERROR: 'error',
  MAINTENANCE: 'warning',
  LIMP_HOME_MODE: 'default',
  STATUS: 'default',
  DIAGNOSIS: 'default',
  LIMP_HOME_MODE_REVERSIBLE: 'default',
};

export const troubleCodeInsightCodeToColorType = (
  troubleCodeInsight: Partial<TroubleCodeInsight> | Partial<TroubleCodes>,
): string => {
  return (troubleCodeInsight.type && colorMap[troubleCodeInsight.type]) ?? 'default';
};

export const troubleCodeInsightCodeToString = (
  troubleCodeInsight: Partial<TroubleCodeInsight> | Partial<TroubleCodes>,
): string | null => {
  if (!troubleCodeInsight.type) {
    return null;
  }
  if (map[troubleCodeInsight.type] && troubleCodeInsight.code !== undefined) {
    return `${map[troubleCodeInsight.type]}.${troubleCodeInsight.code}`;
  }
  return null;
};

export const troubleCodeInsightCodeFromString = (code: string): Partial<TroubleCodeInsight> | null => {
  const bits = code.split('.');
  const type = invert(map)[bits[0].toUpperCase()] as TroubleCodeInsight['type'];
  const codeNumber = parseInt(bits[1], 10);
  if (!!type && !isNaN(codeNumber)) {
    return { type, code: codeNumber };
  }
  return null;
};
