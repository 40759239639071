/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon00100VgHeating01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon00100vgheating01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <rect
        width={45.35}
        height={283.46}
        x={136.27}
        y={141.73}
        fill='currentColor'
        className='cls-1'
        rx={11.34}
        ry={11.34}
      />
      <rect
        width={45.35}
        height={283.46}
        x={209.97}
        y={141.73}
        fill='currentColor'
        className='cls-1'
        rx={11.34}
        ry={11.34}
      />
      <rect
        width={45.35}
        height={283.46}
        x={283.67}
        y={141.73}
        fill='currentColor'
        className='cls-1'
        rx={11.34}
        ry={11.34}
      />
      <rect
        width={45.35}
        height={283.46}
        x={357.37}
        y={141.73}
        fill='currentColor'
        className='cls-1'
        rx={11.34}
        ry={11.34}
      />
      <path
        fill='currentColor'
        d='M442.41 141.73a22.68 22.68 0 1 0 22.67 22.68 22.68 22.68 0 0 0-22.67-22.68z'
        className='cls-1'
      />
    </svg>
  );
};
