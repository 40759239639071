import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavigateOptions, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMapEvents as useMapEventsBase } from 'react-leaflet';
import L from 'leaflet';
import { ObjectSchema } from 'yup';
import saveAs from 'file-saver';
import { Dialog } from '@vaillant-professional-ui/component-libs-web';
import { api, hooks, State, store } from '@vaillant-professional-ui/pui-frontend-common';
import { debounce } from 'lodash';

interface UseRequestFeedbackDialogParams {
  isSuccess: boolean;
  isError: boolean;
  successMessage: string;
  errorMessage: string;
}

type UseRequestFeedbackDialog = (params: UseRequestFeedbackDialogParams) => React.ReactNode;
export const useRequestFeedbackDialog: UseRequestFeedbackDialog = ({
  errorMessage,
  isError,
  isSuccess,
  successMessage,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const t = hooks.useTranslation();

  React.useEffect(() => {
    if (isSuccess || isError) {
      setIsOpen(true);
    }
  }, [isSuccess, isError]);
  return (
    <Dialog
      open={isOpen}
      text={isSuccess ? successMessage : errorMessage}
      buttons={[{ label: t('SG_OK'), variant: 'contained', color: 'primary', onClick: () => setIsOpen(false) }]}
      testIdKey='feedback-success'
    />
  );
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export const useMapEvents = () => {
  const [isZooming, setIsZooming] = React.useState(false);
  useMapEventsBase({
    zoomstart: () => {
      setIsZooming(true);
    },
    zoomend: () => {
      setIsZooming(false);
    },
  });
  return { isZooming };
};

export const useDisableScrollPropagationOnMap = (domId: string) => {
  React.useEffect(() => {
    const elem = L.DomUtil.get(domId);
    if (elem) {
      L.DomEvent.disableScrollPropagation(elem);
    }
  }, [domId]);
};

export const useUrlState = (key: string, initialValue?: string): [string, (value: string) => void] => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const isInitialized = React.useRef(false);
  const params = React.useMemo(() => new URLSearchParams(search), [search]);

  React.useEffect(() => {
    if (initialValue && !params.get(key) && !isInitialized.current) {
      params.set(key, initialValue);
      navigate(`?${params.toString()}`);
    }
    isInitialized.current = true;
  }, [navigate, initialValue, key, params]);

  const setOrAppend = (value: string) => {
    if (params.has(key)) {
      params.set(key, value);
    } else {
      params.append(key, value);
    }
  };

  const setValue = (value: string) => {
    if (value === params.get(key)) {
      return;
    }

    if (!value && !!params.has(key)) {
      params.delete(key);
    } else if (value) {
      setOrAppend(value);
    }
    navigate(`?${params.toString()}`);
  };

  return [params.get(key) ?? '', setValue];
};

export const useGetUserDetails = () => {
  const { user, userAccountId, userAccountHash, userContactHash, isLoading, isError, isSuccess } =
    api.useCases.useGetUserInfo();
  const isTermsAccepted = useSelector<State, boolean>((state) => store.getIsTermsAccepted(state, userAccountId));
  return { isTermsAccepted, user, isLoading, isError, isSuccess, userAccountHash, userContactHash };
};

export const useResize = (handler: EventListenerOrEventListenerObject) => {
  React.useEffect(() => {
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
    // it's only set once, so it's safe to ignore the exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

interface Dimensions {
  width: number;
  height: number;
}

type UseElementSize = (params?: {
  debounceMs?: number;
  initialWidth?: number;
  initialHeight?: number;
}) => { ref: (node: HTMLElement | null) => void } & Dimensions;

const getSize = (element: HTMLElement): Dimensions => {
  const width = element.offsetWidth;
  const height = element.offsetHeight;
  return { width, height };
};

export const useElementSize: UseElementSize = ({ debounceMs = 100, initialWidth = 0, initialHeight = 0 } = {}) => {
  const [size, setSize] = useState<Dimensions>({ width: initialWidth, height: initialHeight });
  const debouncedSetSize = useMemo(() => debounce(setSize, debounceMs), [debounceMs]);

  const ref = useCallback(
    (node: HTMLElement | null) => {
      const onResize = () => {
        if (node) {
          debouncedSetSize(getSize(node));
        }
      };

      if (node === null) {
        // React will call the ref callback with the DOM element when the component mounts, and call it with null when it unmounts.
        // So let's remove event listener when it unmounts
        window?.removeEventListener('resize', onResize);
        return;
      }

      if (!node.offsetWidth || !node.offsetHeight) {
        return;
      }

      setSize(getSize(node));
      window?.addEventListener('resize', onResize);
    },
    [debouncedSetSize],
  );

  return { ref, ...size };
};

export const useNavigateWithCommandClick = () => {
  const navigate = useNavigate();

  return (event: React.MouseEvent, to: string, options?: NavigateOptions) => {
    event.preventDefault();

    if (event.ctrlKey || event.metaKey || event.button === 1) {
      window.open(`${window.origin}${to}`);
    } else {
      navigate(to, options);
    }
  };
};

export const useDownloadDocuments: api.useCases.UseDownloadDocuments = () => {
  const downloadDocument: api.useCases.UseDownloadDocumentsParams['onSuccess'] = useCallback(({ response }) => {
    const saveAsFilename =
      (response.headers['content-disposition'] ?? '')?.split('filename=')[1]?.split(';')?.[0] ?? 'document.pdf';
    const blobPart = response.data ?? '';
    const blob = new Blob([blobPart], { type: 'application/octet-stream' });
    saveAs(blob, saveAsFilename);
  }, []);

  return api.useCases.useDownloadDocuments({ onSuccess: downloadDocument });
};

type BoundingRect = Omit<DOMRectReadOnly, 'toJSON'>;

export const useResizeObserver = <T extends HTMLElement>(target: RefObject<T>) => {
  const [state, setState] = useState<BoundingRect>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (target.current) {
      const observer = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
        setState(entry.contentRect);
      });
      observer.observe(target.current);
      return () => observer.disconnect();
    }
  }, [target]);

  return state;
};

// Returns the largest height is receives for given element over time.
export const useLargestHeight = <T extends HTMLElement>(target: RefObject<T>) => {
  const [largestHeight, setLargestHeight] = useState(0);
  const { height: currentHeight } = useResizeObserver(target);
  useEffect(() => {
    if (currentHeight > largestHeight) {
      setLargestHeight(currentHeight);
    }
  }, [currentHeight, largestHeight]);
  return largestHeight || 'auto';
};

export const useValidatedParams = <T extends Record<string, string>>(schema: ObjectSchema<T>) => {
  const params = useParams<T>();
  schema.validateSync(params);
  return params as T;
};
