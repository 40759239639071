import { groupBy, pick } from 'lodash';
import { CustomerDashboardFilter, CustomerIssueFilter, ICustomer, IGroupedIssues } from '../../typings/models';
import { filterConfiguration } from './filterConfiguration';

const prioritizedFilters: CustomerDashboardFilter[] = ['PENDING', 'OFFLINE', 'ERROR', 'MAINTENANCE', 'PREDICTION'];

const issueGroupToLanguageKey: Record<CustomerIssueFilter, string> = {
  ANY_ISSUE: 'SG_DASHBOARD_ALL_ISSUES',
  ERROR: 'SG_DASHBOARD_FAILURES',
  MAINTENANCE: 'SG_DASHBOARD_MAINTENANCE',
  OFFLINE: 'SG_DASHBOARD_OFFLINE',
  PENDING: 'SG_DASHBOARD_PENDING',
  PREDICTION: 'SG_DASHBOARD_PREDICTIONS',
  ALL_APPLIANCES: 'SG_JOBLIST_TITLE',
};

const groupCustomers = (customers: ICustomer[]): Record<CustomerDashboardFilter, ICustomer[]> => {
  return pick(
    groupBy(customers, (customer) =>
      prioritizedFilters.find((filter) => filterConfiguration[filter].predicate(customer)),
    ),
    prioritizedFilters,
  );
};

const makeCustomerGroup = (customers: ICustomer[], filter: CustomerDashboardFilter): IGroupedIssues => {
  const config = filterConfiguration[filter];
  return {
    customers: config.sort(customers),
    count: config.count(customers),
    group: issueGroupToLanguageKey[filter],
    filter,
  };
};

export { groupCustomers, makeCustomerGroup };
