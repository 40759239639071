import React from 'react';

export const Defs: React.FC<React.PropsWithChildren<React.SVGProps<SVGDefsElement>>> = (props) => <defs {...props} />;
export const LinearGradient: React.FC<React.PropsWithChildren<React.SVGProps<SVGLinearGradientElement>>> = (props) => (
  <linearGradient {...props} />
);
export const Stop: React.FC<React.PropsWithChildren<React.SVGProps<SVGStopElement>>> = (props) => <stop {...props} />;
export const G: React.FC<React.PropsWithChildren<React.SVGProps<SVGGElement>>> = (props) => <g {...props} />;
export const Text: React.FC<React.PropsWithChildren<React.SVGProps<SVGTextElement>>> = (props) => <text {...props} />;
export const Svg: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> = (props) => <svg {...props} />;
export const Rect: React.FC<React.PropsWithChildren<React.SVGProps<SVGRectElement>>> = (props) => <rect {...props} />;
