import { AxiosResponse } from 'axios';

import { DeviceSettingsResponse } from '../../../../../api/apis/maintenance/v2';
import { DeviceSettingsSettingValue } from '../../../../../api/apis/maintenance/v2/maintenance.types';
import { QueryKey, useMutation, useOptimisticQueryOptions } from '../../../../../api/hooks';
import { useWithToastMessage } from '../../../../../api/useCases/TliReadWrite/OptimisticUpdateAgainstSettingsApiUtil';
import { useTranslation } from '../../../../../libs/hooks';
import { TranslationFnc } from '../../../../../typings/models';
import { UseMutation } from '../../presenter/writeDatapointPresenter/writeDatapointPresentationData';
import { translateStringMeasuredValueToTitle } from '../i18n/stringMeasuredValueTitle';
import { BooleanDataPoint, EnumDataPoint, NumericDataPoint } from '../model/dataPoint';

export const patchResponse = (
  updateData: Record<string, number | string | boolean>,
  deviceSettingsResponse: DeviceSettingsResponse,
  itemId: string,
  t: TranslationFnc,
): DeviceSettingsResponse => {
  //@ts-ignore this is due to problems with types from Vrc700Device
  const newCategories: DeviceSettingsResponse['categories'] = deviceSettingsResponse.categories?.map((category) => {
    const newValueAttribute = Object.values(updateData)[0];

    const mapValue = (value: DeviceSettingsSettingValue) => {
      if (value.id === itemId) {
        const title =
          value.measured_value?.data_type === 'string' && typeof newValueAttribute === 'string'
            ? { title: translateStringMeasuredValueToTitle(value, newValueAttribute, t) }
            : value.measured_value?.data_type === 'boolean' && typeof newValueAttribute === 'boolean'
            ? { title: newValueAttribute ? 'TRUE' : 'FALSE' }
            : undefined;

        return {
          ...value,
          measured_value: {
            ...value.measured_value,
            ...title,
            value: newValueAttribute,
          },
        } as DeviceSettingsSettingValue;
      }
      return value;
    };

    return {
      ...category,
      values: category.values?.map(mapValue),
      subcategories: category.subcategories?.map((subcategory) => ({
        ...subcategory,
        values: subcategory.values?.map(mapValue),
      })),
    };
  });
  return { ...deviceSettingsResponse, categories: newCategories };
};

const updater =
  (mutationId: string, t: TranslationFnc) =>
  (updateData: Record<string, number | string | boolean>, previousValue: AxiosResponse<DeviceSettingsResponse>) => {
    const patchedResponse = patchResponse(updateData, previousValue.data, mutationId, t);
    return {
      ...previousValue,
      data: patchedResponse,
    };
  };

export const mutationFactory =
  (queryKey: QueryKey) => (dataPoint: NumericDataPoint | EnumDataPoint | BooleanDataPoint) => {
    const useControlSettingsMutation: UseMutation = () => {
      const t = useTranslation();

      const mutationOptions = useOptimisticQueryOptions(queryKey, updater(dataPoint.mutationId ?? '', t));

      const mutationOptionsWithToast = useWithToastMessage(
        mutationOptions,
        t('SG_TOAST_DATAPOINT_WRITE_SUCCESS', { datapoint: dataPoint.title }),
        t('SG_TOAST_ERROR_MSG'),
        t('SG_TOAST_DATAPOINT_WRITE_FAILED', { datapoint: dataPoint.title }),
      );

      const { mutate } = useMutation((payload) => {
        return {
          url: dataPoint.writeParams?.url,
          method: dataPoint.writeParams?.method,
          data: payload,
        };
      }, mutationOptionsWithToast);

      const { writeParams } = dataPoint;
      if (!writeParams) {
        return () => {};
      }
      return (value) => mutate({ [writeParams.actionPropertyKey]: value, ...writeParams.payloadMetadata });
    };
    return useControlSettingsMutation;
  };
