import { useCase } from './useCase';
import { selectors } from '../../../index';
import React from 'react';
import uniqBy from 'lodash/uniqBy';

export const usePresenter = () => {
  const {
    isLoading,
    customerToResendConsent,
    data,
    setCustomerToResendConsent,
    shouldShowActivitySpinner,
    shouldShowNoDevices,
    readNotifications,
  } = useCase();

  const formatAddress = selectors.customer.useCustomerAddressFormatter();
  const formatCustomerName = selectors.customer.getEndUserFullName;
  const getBoiler = selectors.customer.getBoiler;
  const getBoilerNomenclature = selectors.boiler.getNomenclature;
  const getBoilerMarketingName = selectors.boiler.getMarketingName;

  const groupedCustomers = selectors.groupCustomers(data);

  const systemsWaitingForActionCount = React.useMemo(
    () =>
      uniqBy(
        [
          ...(groupedCustomers.ERROR ?? []),
          ...(groupedCustomers.MAINTENANCE ?? []),
          ...(groupedCustomers.PENDING ?? []),
          ...(groupedCustomers.PREDICTION ?? []),
          ...(groupedCustomers.OFFLINE ?? []),
        ],
        (row) => row.system.boiler.serialNumber,
      ).length,
    [groupedCustomers],
  );

  return {
    isLoading,
    customerToResendConsent,
    groupedCustomers,
    setCustomerToResendConsent,
    shouldShowActivitySpinner,
    shouldShowNoDevices,
    systemsWaitingForActionCount,
    readNotifications,
    formatAddress,
    formatCustomerName,
    getBoilerNomenclature,
    getBoilerMarketingName,
    getBoiler,
  };
};
