import parseISO from 'date-fns/parseISO';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import {
  ExpiryStatus,
  ICustomer,
  ICustomerDetail,
  INotification,
  TroubleCodes,
  ActivationStatus,
  IBoiler,
  IAddressLocation,
  ErrorPredictions,
  ConnectivitySolution,
  ControlType,
  Geolocation,
  ConsentNotificationType,
  HeatingDeviceType,
} from '../typings/models';
import * as boiler from './boiler';
import * as gateway from './gateway';
import { I18nKeys } from '../i18n';
import { usePermissions } from '../components/meta/PermissionGate';

export const isOnline = (customer: ICustomer | ICustomerDetail): boolean => customer.status.online === true;
export const isOffline = (customer: ICustomer | ICustomerDetail): boolean => customer.status.online === false;

export const getLastHeartbeat = (customer: ICustomer | ICustomerDetail): Date | null =>
  customer.status.lastHeartbeatAt ? new Date(customer.status.lastHeartbeatAt) : null;

export const isSelectable = (customer: ICustomer | ICustomerDetail) =>
  getExpiryStatus(customer, new Date()) !== 'expired' && hasConsentGiven(customer) && !!getBoilerSerialNumber(customer);

export const hasConsentGiven = (customer: ICustomer | ICustomerDetail): boolean =>
  isActivationStatus(customer, 'COMPLETED');

export const isPendingConsent = (customer: ICustomer): boolean => isActivationStatus(customer, ['PENDING_CONSENT']);

export const isPending = (customer: ICustomer): boolean =>
  isActivationStatus(customer, ['PENDING_CUSTOMER', 'PENDING_SYSTEM', 'PENDING_CONSENT']);

export const requestedConsentTextKey = (customer: ICustomer | ICustomerDetail): I18nKeys => {
  const {
    status: { consentNotificationType },
  } = customer;
  const mapping: Record<ConsentNotificationType, I18nKeys> = {
    EMAIL: 'SG_CONSENT_REQUEST_MAIL',
    PUSH_NOTIFICATION: 'SG_CONSENT_REQUEST_PUSH_NOTIFICATION',
  };
  return consentNotificationType ? mapping[consentNotificationType] : 'SG_RESEND_MAIL_EXPLANATION';
};

export const incompleteStatusTextKeys = (
  customer: ICustomer | ICustomerDetail,
): { titleKey: I18nKeys; subtitleKey: I18nKeys } | undefined => {
  const keys: Partial<Record<ActivationStatus, { titleKey: I18nKeys; subtitleKey: I18nKeys }>> = {
    PENDING_CONSENT: {
      titleKey: 'SG_CONSENT_PENDING',
      subtitleKey: requestedConsentTextKey(customer),
    },
    PENDING_CUSTOMER: {
      titleKey: 'SG_CONSENT_VALIDATION',
      subtitleKey: 'SG_VALIDATING_NEW_CUSTOMER',
    },
    PENDING_SYSTEM: {
      titleKey: 'SG_CONSENT_VALIDATION',
      subtitleKey: 'SG_VALIDATING_NEW_CUSTOMER',
    },
    SYSTEM_UNKNOWN: {
      titleKey: 'SG_FAILED_VALIDATION',
      subtitleKey: 'SG_VALIDATING_FAILED_SYSTEM_UNKNOWN',
    },
    USER_UNKNOWN: {
      titleKey: 'SG_FAILED_VALIDATION',
      subtitleKey: 'SG_INVALID_CUSTOMER_INFORMATION',
    },
    USER_IS_INSTALLER: {
      titleKey: 'SG_FAILED_VALIDATION',
      subtitleKey: 'SG_ADDCUSTOMER_IS_INSTALLER',
    },
    OTHER_INSTALLER: {
      titleKey: 'SG_FAILED_VALIDATION',
      subtitleKey: 'SG_ADDCUSTOMER_OTHER_INSTALLER',
    },
  };
  return keys[customer.status.activation];
};

export const hasF22Prediction = (customer: ICustomer | ICustomerDetail): boolean => {
  return Boolean(getF22Prediction(customer));
};

export const hasFailedValidation = (customer: ICustomer): boolean =>
  isActivationStatus(customer, ['SYSTEM_UNKNOWN', 'USER_UNKNOWN', 'USER_IS_INSTALLER', 'OTHER_INSTALLER']);

export const getF22Prediction: (customer: ICustomer | ICustomerDetail) => ErrorPredictions | undefined = (customer) =>
  customer.status.errorPredictions?.filter((prediction) => prediction.type === 'F.22')?.[0];

export const getActivationStatus = (customer: ICustomer | ICustomerDetail) => {
  return customer.status.activation;
};

export const isActivationStatus = (
  customer: ICustomer | ICustomerDetail,
  activationStatus: ActivationStatus | ActivationStatus[],
) =>
  Array.isArray(activationStatus)
    ? activationStatus.includes(customer.status.activation)
    : customer.status.activation === activationStatus;

export const getLastActivation = (customer: ICustomer): Date | null =>
  customer?.status?.lastActivation ? new Date(customer.status.lastActivation) : null;

export const hasWarning = (customer: ICustomer) =>
  isOnline(customer) && hasConsentGiven(customer) && hasUnrectifiedDtc(customer);

export const hasUnrectifiedDtc = (customer: ICustomer): boolean => {
  const activeDtcs = getActiveTroubleCodes(customer);
  return activeDtcs.filter((dtc) => !dtc.rectifiedAt).length > 0;
};

export const hasUnreadNotifications = (customer: ICustomer, readNotificationIds: string[]) => {
  const dtcs = getActiveTroubleCodes(customer).map((d) => d.id);
  return dtcs.filter((dtc) => !readNotificationIds.includes(dtc)).length > 0;
};

export const hasUnrectifiedError = (customer: ICustomer): boolean => {
  const activeDtcs = getActiveTroubleCodes(customer);
  return activeDtcs.filter((dtc) => !dtc.rectifiedAt && dtc.type === 'ERROR').length > 0;
};

export const hasUnrectifiedMaintenance = (customer: ICustomer): boolean => {
  const activeDtcs = getActiveTroubleCodes(customer);
  return activeDtcs.filter((dtc) => !dtc.rectifiedAt && dtc.type === 'MAINTENANCE').length > 0;
};

export const getGatewaySerialNumber = (customer: ICustomer | ICustomerDetail): string | null =>
  gateway.getSerialNumber(customer.system.gateway);

export const getControlSerialNumber = (customer: ICustomer | ICustomerDetail): string | null =>
  customer.system?.control?.serialNumber ?? null;

export const getBoilerNomenclature = (customer: ICustomer | ICustomerDetail): string =>
  getBoiler(customer) ? boiler.getNomenclature(customer.system.boiler) : '';

export const getFullStatusCode = (customer: ICustomer): string | undefined =>
  getActiveNotification(customer)?.title || '';

export const getBoilerSerialNumber = (customer: ICustomer | ICustomerDetail): string =>
  getBoiler(customer) ? boiler.getSerialNumber(customer.system.boiler) : '';

export const getBoilerMarketingName = (customer: ICustomer | ICustomerDetail): string =>
  getBoiler(customer) ? boiler.getMarketingName(customer.system.boiler) : '';

export const getActiveNotification = (customer: ICustomer): INotification | null =>
  getBoiler(customer)?.activeNotification || null;

export const getActiveTroubleCodes = (customer: ICustomer | ICustomerDetail): TroubleCodes[] =>
  getBoiler(customer)?.activeTroubleCodes ?? [];

export const getEndUserPhone = (customer: ICustomer | ICustomerDetail): string | null =>
  customer.customer?.phone ?? null;

export const getEndUserAddressStr = (customer: ICustomer | ICustomerDetail): string => {
  const address = customer.customer?.address;
  return address ? `${address.street}, ${address.city}`.trim() : '';
};

export const getHeatingDeviceType = (customer: ICustomer | ICustomerDetail): HeatingDeviceType =>
  getBoiler(customer) ? boiler.getHeatingDeviceType(customer.system.boiler) : 'BOILER';

interface PartialAddress {
  name: string;
  street: string;
  extension: string;
  city: string;
}
export const getEndUserAddressBits = (customer: ICustomer | ICustomerDetail): PartialAddress | null => {
  const endUser = customer.customer;
  const address = customer.customer?.address;
  if (!address) {
    return null;
  }

  const toTitleCase = (str: string): string => {
    const words = str.trim().toLowerCase().split(' ');
    return words.map((word) => word.charAt(0).toUpperCase() + word.substr(1)).join(' ');
  };

  const customerSalutation = `${endUser?.salutation ? endUser.salutation + ' ' : ''}`;
  const name = customerSalutation + toTitleCase(endUser?.firstName + ' ' + endUser?.lastName);

  const street =
    !!address.street || !!address.houseNumber ? `${address.street || ''} ${address.houseNumber || ''}` : '';
  const extension = address.extension ?? '';
  const city = !!address.postalCode || !!address.city ? `${address.postalCode || ''} ${address.city || ''}` : '';

  return { name, street, extension, city };
};

export const getEndUserPosition = (customer: ICustomer): Geolocation | null =>
  customer.customer?.address?.geoLocation ?? null;

export const getEndUserFullName = (customer?: ICustomerDetail | ICustomer, reversed = false) => {
  if (!customer?.customer) {
    return '';
  }

  if (reversed) {
    return [customer.customer.lastName, customer.customer.firstName].filter(Boolean).join(', ').trim();
  }

  return [customer.customer.firstName, customer.customer.lastName].filter(Boolean).join(' ').trim();
};

export const getEndUserLastName = (customer: ICustomer) => customer?.customer?.lastName ?? '';
export const getEndUserFirstName = (customer: ICustomer) => customer?.customer?.firstName ?? '';

export const getBoilerArticleNumber = (customer: ICustomer | ICustomerDetail): string =>
  getBoiler(customer) ? boiler.extractArticleNumber(customer.system.boiler.serialNumber) : '';

export const getBoiler = (customer: ICustomer | ICustomerDetail): IBoiler | null => customer.system?.boiler;
export const getSystemId = (customer: ICustomer | ICustomerDetail): string => customer.system.systemId;

export const getExpiryStatus = (customer: ICustomer | ICustomerDetail, date: Date): ExpiryStatus => {
  const { status: { accessExpiresAt = null } = {} } = customer;

  if (!accessExpiresAt) {
    return 'never';
  }

  const expiryDate = new Date(accessExpiresAt);
  if (expiryDate > date) {
    return 'soon';
  }
  return 'expired';
};

export const getDaysUntilExpiry = (customer: ICustomer, now = new Date()): number | null => {
  const { status: { accessExpiresAt = null } = {} } = customer;

  if (!accessExpiresAt) {
    return null;
  }

  const diffMilliseconds = differenceInMilliseconds(parseISO(accessExpiresAt), now);
  return Math.ceil(diffMilliseconds / 1000 / 60 / 60 / 24);
};

export const addressLocationToString = (address: IAddressLocation): string => {
  const bits = [
    `${address.street ?? ''} ${address.houseNumber ?? ''}`.trim(),
    address.extension !== 'null.null' && address.extension,
    `${address.postalCode ?? ''} ${address.city ?? ''}`.trim(),
    address.country,
  ];
  return bits.filter(Boolean).join(', ');
};

export const useCustomerAddressFormatter = () => {
  const { hasPermissions: shouldFormatAddressUK } = usePermissions(['COUNTRY_UK']);

  return (customer: ICustomer | ICustomerDetail) => {
    const { customer: endUser } = customer;
    const firstLine = shouldFormatAddressUK
      ? ` ${endUser?.address?.houseNumber || ''} ${endUser?.address?.street || ''}`.trim()
      : `${endUser?.address?.street || ''} ${endUser?.address?.houseNumber || ''}`.trim();
    const secondLine = `${endUser?.address?.postalCode || ''} ${endUser?.address?.city || ''}`.trim();

    return { firstLine, secondLine };
  };
};

export const getConnectivitySolution: (customer: ICustomer | ICustomerDetail) => ConnectivitySolution = (customer) => {
  return (customer as ICustomer).system.connectivitySolution ?? (customer as ICustomerDetail).connectivitySolution;
};

export const getControlType: (customer: ICustomerDetail) => ControlType | null = (customerDetails) => {
  return customerDetails.control?.type ?? null;
};

export const isTliAvailable: (customer: ICustomer) => boolean = (customer) => {
  return !isErelax(customer) && !isSpeedboat(customer);
};

export const isErelax = (customer: ICustomer | ICustomerDetail) => {
  const connectivitySolution = getConnectivitySolution(customer);
  return ['ERELAX', 'ERELAX_NETCOMV2'].includes(connectivitySolution);
};

export const isSpeedboat = (customer: ICustomer | ICustomerDetail) => {
  const connectivitySolution = getConnectivitySolution(customer);
  return ['SPEEDBOAT'].includes(connectivitySolution);
};

export const isMfh = (customer: ICustomer | ICustomerDetail) => {
  const connectivitySolution = getConnectivitySolution(customer);
  return ['MFH'].includes(connectivitySolution);
};

export const isCag = (customerDetails: ICustomerDetail) => {
  return ['CAG'].includes(customerDetails?.connectivitySolution);
};

export const isTli = (customerDetails: ICustomerDetail) => {
  if (isCag(customerDetails)) {
    const controlType = getControlType(customerDetails);
    return 'TLI' === controlType;
  }
  return null;
};
