import * as React from 'react';
import MuiTextField from '@mui/material/TextField';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { TextFieldProps } from './TextField.types';

export const TextField: React.FunctionComponent<React.PropsWithChildren<TextFieldProps>> = ({ testIdKey, ...rest }) => {
  // Setting MuiTextField default props doesn't do it's job, so set the default value for variant here
  if ('variant' in rest === false) {
    rest.variant = 'standard';
  }
  const testId = useInstanceId('textfield', testIdKey);

  return <MuiTextField {...testId().testIdAttributes} {...rest} />;
};
