import { zip } from 'lodash';
import { TranslationFnc } from '../../../../../typings/models';
import { nonNullable } from '../../../../../util';
import { DeviceDetailsResponseTli } from '../../../../../api/apis/maintenance/v2';
import { Category, filterEmptyCategories } from '../../../deviceSettings/useCase/model/category';
import { createZones } from './zones';
import { createDhw } from './dhw';
import { createCircuits } from './circuits';
import { createSystem } from './system';
import { createBufferCylinder } from './bufferCylinder';
import { DeviceSettings } from '../../../deviceSettings/useCase';

export const mapResponseToDataModel = (response: DeviceDetailsResponseTli, t: TranslationFnc): DeviceSettings => {
  const system = createSystem(response, t);
  const zones = createZones(response, t);
  const circuits = createCircuits(response, t);
  const dhw = createDhw(response, t);
  const bufferCylinder = createBufferCylinder(response, t);

  const zonesAndCircuits = zip(zones, circuits).flat().filter(nonNullable);

  return {
    categories: filterEmptyCategories([system, ...zonesAndCircuits, dhw, bufferCylinder]) as Category[],
  };
};
