import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Radio as BaseRadio } from '@mui/material';
import { RadioProps } from './Radio.types';
import { RadioIconChecked, RadioIconUnChecked } from './Radio.styles';

export const Radio: React.FunctionComponent<RadioProps> = ({ testIdKey, ...rest }) => {
  const testId = useInstanceId('radio', testIdKey);

  return (
    <BaseRadio
      {...testId('root').testIdAttributes}
      disableRipple
      icon={<RadioIconUnChecked />}
      checkedIcon={<RadioIconChecked />}
      {...rest}
    />
  );
};
