import { Card, styled } from '@vaillant-professional-ui/component-libs-web';

export const SmallCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  padding-right: ${({ theme }) => theme.spacing(4)};
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
