import { api, selectors } from '@vaillant-professional-ui/pui-frontend-common';
import React from 'react';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { TliDHWSchedulerPage } from './TliDHWSchedulerPage';
import { useValidatedParams } from '../../../lib/hooks';
import { RouteParamsSchema, routeParamsSchema } from './TliDHWSchedulerPage.schema';

export const TliDHWSchedulerPageContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { boilerSerialNumber, systemId, deviceId } = useValidatedParams<RouteParamsSchema>(routeParamsSchema);
  const {
    error: errorUser,
    customer,
    fullName,
    isLoading: isLoadingUser,
  } = api.useCases.useCustomerDetails(boilerSerialNumber);
  const gatewaySerialNumber = customer && selectors.customer.getGatewaySerialNumber(customer);
  const controlSerialNumber = customer && selectors.customer.getControlSerialNumber(customer);

  const {
    presentationData,
    isLoading: isLoadingPresenter,
    error: errorPresenter,
    patchDhwSchedule,
  } = api.useCases.tliScheduler.useTliScheduler({
    gatewaySerialNumber: gatewaySerialNumber ?? '',
    controlSerialNumber: controlSerialNumber ?? '',
    systemId,
    deviceId,
    options: {
      enabled: !!gatewaySerialNumber && !!controlSerialNumber,
    },
  });

  const isLoading = isLoadingUser || isLoadingPresenter;
  const error = errorPresenter || errorUser;

  return (
    <LoadingErrorWrapper error={error} isPending={isLoading} showLoadingSpinner={true}>
      {presentationData && (
        <TliDHWSchedulerPage
          customerName={fullName}
          schedules={presentationData.dhwSchedule.schedule}
          maxTimeSlotsPerDay={presentationData.dhwSchedule.maxSlotsPerDay}
          timeStepWidthInMinutes={presentationData.dhwSchedule.stepSize}
          onSave={patchDhwSchedule}
        />
      )}
    </LoadingErrorWrapper>
  );
};
