import { MobileDashboardListContainer, Spacer } from './MobileDashboard.styles';
import { TableRow } from './Dashboard.types';
import React from 'react';
import { MobileDashboardListItem } from './MobileDashboardListItem';
import { Device, ICustomer, ICustomerDetail } from '@vaillant-professional-ui/pui-frontend-common/src/typings/models';
import { ItemAction } from '../../features/dashboard/presenter';

interface MobileDashboardListProps {
  listData: TableRow[];
  formatAddress: (customer: ICustomer | ICustomerDetail) => { firstLine: string; secondLine: string };
  formatCustomerName: (customer?: ICustomer | ICustomerDetail | undefined, reversed?: boolean) => string;
  getBoilerNomenclature: (boiler: Device) => string;
  getBoilerMarketingName: (boiler: Device) => string;
  itemActions: ItemAction[];
}

export const MobileDashboardList = ({
  listData,
  itemActions,
  formatAddress,
  formatCustomerName,
  getBoilerNomenclature,
  getBoilerMarketingName,
}: MobileDashboardListProps) => {
  return (
    <MobileDashboardListContainer>
      {listData.map((item) => (
        <>
          <MobileDashboardListItem
            key={item.id}
            item={item}
            formatAddress={formatAddress}
            formatCustomerName={formatCustomerName}
            getBoilerNomenclature={getBoilerNomenclature}
            getBoilerMarketingName={getBoilerMarketingName}
            itemActions={itemActions}
          />
          <Spacer />
        </>
      ))}
    </MobileDashboardListContainer>
  );
};
