import { Link as LinkBase, Box } from '@mui/material';
import { ThemeVariation } from '@vaillant-professional-ui/component-libs-common';
import { styled } from '../../../util/styling';
import { Card as CardBase } from '../Card';
import { Chip as ChipBase } from '../../atoms/Chip';
import { Icon08201VgRight01 } from '../../atoms/Icon';

export const Card = styled(CardBase)`
  display: flex;
  flex-grow: 1;
  padding: 24px ${({ variant }) => (variant === 'plain' ? 0 : '8px')};
  ${({ variant }) => variant === 'plain' && 'border-radius: 0;box-shadow: none;'}
  ${({ theme, $isHighlighted }) => $isHighlighted && `background-color: ${theme.palette.grey[200]};`}
`;

interface Props {
  $themeName?: ThemeVariation;
  $disabled?: boolean;
  $isLink?: boolean;
}

export const Container = styled(LinkBase)<Props>`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  text-decoration: none !important;
  color: ${({ theme }) => theme.palette.common.black};
  width: 100%;
  ${({ $isLink, $disabled }) => $isLink && `cursor: ${$disabled ? 'auto' : 'pointer'};`};
  ${({ $isLink, $disabled }) => $isLink && `pointer-events: ${$disabled ? 'none' : 'auto'};`};
  padding: 0;
`;

export const IconSpacer = styled(Box)`
  width: 52px;
  display: flex;
  box-sizing: border-box;
  padding-right: ${({ theme }) => theme.spacing(1)};
  align-items: flex-start;
  justify-content: center;
`;

export const TitleContainer = styled(Box)<Props>`
  word-break: break-word;
  color: ${({ theme, $disabled }) => ($disabled ? theme.palette.text.disabled : theme.palette.common.black)};
`;

export const Chip = styled(ChipBase)`
  height: 24px;
`;

export const LinkIcon = styled(Icon08201VgRight01)`
  flex-shrink: 0;
`;
