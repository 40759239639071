import { styled } from '../../../util/styling';
import { Icon08001VgBack01 } from '../Icon/Icon08001VgBack01';
import { Typography } from '../Typography';

export const Icon = styled(Icon08001VgBack01)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const Text = styled(Typography)`
  font-weight: bold;
`;
