import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';

import { Language } from '../../../../typings/models';
import { useQuery } from '../../../hooks';
import { maintenanceApi } from './maintenance.endpoints';
import {
  ChartDetailsResponse,
  ChartListResponse,
  DeviceDetailsParams,
  DeviceDetailsPathParams,
  DeviceDetailsResponse,
  DeviceDetailsResponseTli,
  DeviceDetailsResponseVrc700,
  DeviceSettingsParams,
  DeviceSettingsResponse,
  SystemParams,
  SystemResponse,
} from './maintenance.types';

export const useSystem = (
  { systemId }: SystemParams,
  options: UseQueryOptions<AxiosResponse<SystemResponse>, AxiosError, SystemResponse> = {},
) => {
  return useQuery<SystemResponse, AxiosRequestConfig<never>, SystemResponse>(
    ['system', systemId],
    maintenanceApi.getSystem(systemId),
    {
      select: (response) => response.data,
      ...options,
    },
  );
};

const useDeviceDetails = <T extends DeviceDetailsResponse>(
  { systemId, deviceId, deviceType }: DeviceDetailsParams,
  options: UseQueryOptions<AxiosResponse<T>, AxiosError, T> = {},
) => {
  return useQuery<T, AxiosRequestConfig<never>, T>(
    ['deviceDetails', systemId, deviceId, deviceType],
    maintenanceApi.getDeviceDetails(systemId, deviceId, deviceType),
    {
      select: (response) => response.data,
      ...options,
    },
  );
};

export const useTli = (
  params: DeviceDetailsPathParams,
  options: UseQueryOptions<AxiosResponse<DeviceDetailsResponseTli>, AxiosError, DeviceDetailsResponseTli> = {},
) => {
  return useDeviceDetails<DeviceDetailsResponseTli>({ ...params, deviceType: 'CONTROL/TLI' }, options);
};

export const useVrc700 = (
  params: DeviceDetailsPathParams,
  options: UseQueryOptions<AxiosResponse<DeviceDetailsResponseVrc700>, AxiosError, DeviceDetailsResponseVrc700> = {},
) => {
  return useDeviceDetails<DeviceDetailsResponseVrc700>({ ...params, deviceType: 'CONTROL/VRC700' }, options);
};

export const useDeviceSettings = (
  { systemId, deviceId, language }: DeviceSettingsParams & { language: Language },
  options: UseQueryOptions<AxiosResponse<DeviceSettingsResponse>, AxiosError, DeviceSettingsResponse> = {},
) => {
  return useQuery<DeviceSettingsResponse, AxiosRequestConfig<never>, DeviceSettingsResponse>(
    ['deviceSettings', systemId, deviceId, language],
    maintenanceApi.getDeviceSettings(systemId, deviceId),
    {
      select: (response) => response.data,
      ...options,
    },
  );
};

interface UseChartListParams {
  systemId?: string;
  language: Language;
}

export const useChartList = (
  { systemId, language }: UseChartListParams,
  options: UseQueryOptions<AxiosResponse<ChartListResponse>, AxiosError, ChartListResponse> = {},
) => {
  return useQuery<ChartListResponse, AxiosRequestConfig<never>, ChartListResponse>(
    ['chartList', systemId ?? '', language],
    maintenanceApi.getChartList(systemId),
    {
      select: (response) => response.data,
      enabled: !!systemId,
      ...options,
    },
  );
};

interface UseChartDetailsParams {
  chartUrl: URL;
  start: Date;
  end: Date;
  /* empty array means 'no time series is selected', `null` means 'we want the default values from the backend' */
  selectedTimeSeries: string[] | null;
  language: Language;
}

export const useChartDetails = (
  { chartUrl, start, end, selectedTimeSeries, language }: UseChartDetailsParams,
  options: UseQueryOptions<AxiosResponse<ChartDetailsResponse>, AxiosError, ChartDetailsResponse> = {},
) => {
  const timeSeries = selectedTimeSeries ? [...new Set(selectedTimeSeries)].sort() : undefined;

  return useQuery(
    [
      'chartDetail',
      chartUrl.toString(),
      start.toISOString(),
      end.toISOString(),
      language,
      ...(timeSeries ?? ['initial']),
    ],
    maintenanceApi.getChartDetails(chartUrl, start, end, timeSeries),
    {
      select: (response: AxiosResponse<ChartDetailsResponse>) => response.data,
      // TODO: should match resolution coming from backend
      staleTime: 1 * 60 * 1000,
      enabled: !!chartUrl,
      ...options,
    },
  );
};
