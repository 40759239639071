import * as React from 'react';

interface Props {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

export const ConditionalWrapper: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ condition, wrapper, children }) => (
  <React.Fragment>{condition ? wrapper(children) : children}</React.Fragment>
);
