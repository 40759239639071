import * as React from 'react';
import { Tab as TabBase } from '@mui/material';
import { Translated, useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { TabProps } from './Tab.types';

export const Tab: React.FunctionComponent<React.PropsWithChildren<TabProps>> = ({ testIdKey, ...rest }) => {
  const testId = useInstanceId('tab', testIdKey);
  return (
    <Translated
      {...rest}
      {...testId().testIdAttributes}
      i18nRenderOptions={rest.i18nKey ? { translationProp: 'label' } : undefined}
      component={TabBase}
    />
  );
};
