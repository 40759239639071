import { object as yupObject, string as yupString } from 'yup';

export const urlParamsSchema = yupObject().shape({
  deviceId: yupString().required(),
  systemId: yupString().required(),
  boilerSerialNumber: yupString().required(),
  index: yupString().required(),
  scheduleType: yupString()
    .oneOf(['heating', 'cooling', 'domestic_hot_water', 'ventilation', 'circulation_pump'])
    .required(),
});
