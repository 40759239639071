import { Box } from '@mui/material';
import { Button, ScheduleTableEditor, Typography } from '@vaillant-professional-ui/component-libs-web';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { isEqual } from 'lodash';
import React from 'react';
import { Container } from './ErelaxSchedulerPage.styles';
import { ErelaxSchedulerPageProps } from './ErelaxSchedulerPage.types';

export const ErelaxSchedulerPage = ({ profiles, scheduleVgComponents, onSave }: ErelaxSchedulerPageProps) => {
  const [managedSchedules, setManagedSchedules] = React.useState(scheduleVgComponents);
  const t = hooks.useTranslation();

  const onPressSave = React.useCallback(() => {
    onSave(managedSchedules);
  }, [managedSchedules, onSave]);

  return (
    <Box width='100%'>
      <Box mb={8}>
        <Typography variant='h2' i18nKey='SG_HEATING_SCHEDULE_TITLE' />
      </Box>
      <Box mb={8}></Box>
      <Container>
        <>
          <ScheduleTableEditor
            profiles={profiles}
            managedSchedules={managedSchedules}
            setManagedSchedules={setManagedSchedules}
            maxTimeSlotsPerDay={1440}
            timeStepWidthInMinutes={1}
          />
          <Box display='flex' mt='32px' justifyContent='flex-end'>
            <Button
              disabled={isEqual(scheduleVgComponents, managedSchedules)}
              color='primary'
              variant='contained'
              onClick={onPressSave}
            >
              {t('SG_SAVE_CHANGES')}
            </Button>
          </Box>
        </>
      </Container>
    </Box>
  );
};
