import React, { ComponentType } from 'react';

import { AxiosError } from 'axios';
import { QueryClient, QueryClientProvider, QueryOptions } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { FirebaseProvider } from '../../providers/firebase';

import { Toast } from '@vaillant-professional-ui/component-libs-web';
import {
  config,
  FeatureContextProvider,
  RuntimeContextProvider,
  ToastProps,
  ToastProvider,
} from '@vaillant-professional-ui/pui-frontend-common';
import { ErrorBoundary } from '../ErrorBoundary';
import { LayoutContainer } from '../Layout';
import { RoutesContainer } from '../Routes';
import { RouteListener } from '../Routes/RouteListener';
import { ScrollToTop } from '../ScrollToTop';
import { AppProps } from './App.types';
import { AuthenticationProvider } from './AuthenticationProvider';
import { LoggingContextProviderWrapper } from './LoggingContextProviderWrapper';
import { StoreProviderWrapper } from './StoreProviderWrapper';
import { VgComponentsWrapper } from './VgComponentsWrapper';
import { I18nProvider } from './I18nProvider';

const { AppConfigProvider } = config;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: ((failureCount: number, error: AxiosError) =>
        error.response?.status !== 404 && failureCount < 3) as QueryOptions['retry'],
      refetchOnWindowFocus: false,
    },
  },
});

const ScrollToTopWithLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <>
    <ScrollToTop />
    <LayoutContainer>{children}</LayoutContainer>
  </>
);

const App = ({ appConfig, apiClient, initialFeatures }: AppProps) => {
  return (
    <LoggingContextProviderWrapper>
      <AppConfigProvider config={appConfig}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <StoreProviderWrapper>
            <Router>
              <I18nProvider>
                <AuthenticationProvider>
                  <RuntimeContextProvider apiClient={apiClient}>
                    <FeatureContextProvider featureConfig={initialFeatures}>
                      <VgComponentsWrapper>
                        <FirebaseProvider>
                          <ToastProvider
                            duration={3000}
                            Toast={Toast as ComponentType<React.PropsWithChildren<ToastProps>>}
                          >
                            <ScrollToTopWithLayout>
                              <ErrorBoundary>
                                <RouteListener>
                                  <RoutesContainer />
                                </RouteListener>
                              </ErrorBoundary>
                            </ScrollToTopWithLayout>
                          </ToastProvider>
                        </FirebaseProvider>
                      </VgComponentsWrapper>
                    </FeatureContextProvider>
                  </RuntimeContextProvider>
                </AuthenticationProvider>
              </I18nProvider>
            </Router>
          </StoreProviderWrapper>
        </QueryClientProvider>
      </AppConfigProvider>
    </LoggingContextProviderWrapper>
  );
};

export default App;
