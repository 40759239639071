import React from 'react';

import {
  Button,
  ModalDrawer,
  SpinnerControl,
  SpinnerControlProps,
  Typography,
} from '@vaillant-professional-ui/component-libs-web';
import { Box } from '@mui/material';

export interface WriteNumericDatapointDrawerContentProps {
  title: string;
  subtitle: string;
  buttonLabelCancel: string;
  buttonLabelSave: string;
  spinnerControlProps: SpinnerControlProps;
  handleSubmit: () => void;
  isSaveButtonDisabled: boolean;
  onClose: () => void;
}

export const WriteNumericDatapointDrawerContent: React.FC<WriteNumericDatapointDrawerContentProps> = ({
  title,
  subtitle,
  buttonLabelCancel,
  buttonLabelSave,
  spinnerControlProps,
  handleSubmit,
  isSaveButtonDisabled,
  onClose,
}) => {
  return (
    <>
      <ModalDrawer.Title>{title}</ModalDrawer.Title>
      <ModalDrawer.Content>
        <Box display='flex' flexDirection='column' gap={8}>
          <Typography>{subtitle}</Typography>
          <SpinnerControl {...spinnerControlProps} />
        </Box>
      </ModalDrawer.Content>
      <ModalDrawer.Footer>
        <Button variant='outlined' onClick={onClose} testIdKey='close'>
          {buttonLabelCancel}
        </Button>
        <Button variant='contained' onClick={handleSubmit} testIdKey='save' disabled={isSaveButtonDisabled}>
          {buttonLabelSave}
        </Button>
      </ModalDrawer.Footer>
    </>
  );
};
