import React from 'react';
import { Box } from '@mui/system';
import { Paper } from '@mui/material';
import { StatusPanel } from '@vaillant-professional-ui/component-libs-web';
import {
  hooks,
  ErrorBoundary as ErrorBoundaryBase,
  useLoggingContext,
  config,
} from '@vaillant-professional-ui/pui-frontend-common';
import { StackTrace } from './ErrorBoundary.styles';
import { ErrorBoundaryProps } from './ErrorBoundary.types';
import { getPrintableError } from './ErrorBoundary.util';

const ErrorBox: React.FC<React.PropsWithChildren<ErrorBoundaryProps>> = ({ error, errorInfo }) => {
  const t = hooks.useTranslation();
  const appConfig = config.useAppConfig();
  const isDebug = appConfig.runtimeContext === 'debug';
  const errorStr = getPrintableError(error);

  return (
    <Paper
      elevation={0}
      component={Box}
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'
      marginBottom={[12, 0]}
    >
      <Box p={[4, 8, 12]}>
        {!isDebug ? (
          <StatusPanel type='error' title={t('SG_GENERIC_ERROR_TITLE')} variant='big'></StatusPanel>
        ) : (
          <StatusPanel type='error' title={errorStr} variant='big'>
            <Box>{!!errorInfo && <StackTrace>{errorInfo.componentStack}</StackTrace>}</Box>
          </StatusPanel>
        )}
      </Box>
    </Paper>
  );
};

export const ErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { reportError } = useLoggingContext();

  return (
    <ErrorBoundaryBase errorComponent={ErrorBox} reportError={reportError}>
      {children}
    </ErrorBoundaryBase>
  );
};
