/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon50100VgDisconnected01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon50100vgdisconnected01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M385.51 283.47h-17a11.34 11.34 0 0 0 0 22.67h17a45.36 45.36 0 1 1 0 90.71h-85a45.36 45.36 0 0 1-27.2-81.65 11.34 11.34 0 0 0-13.62-18.13 68 68 0 0 0 40.82 122.46h85a68 68 0 1 0 0-136.06zm-187.09-22.68h-17a45.36 45.36 0 1 1 0-90.71h85a45.36 45.36 0 0 1 27.2 81.65 11.34 11.34 0 0 0 13.62 18.13 68 68 0 0 0-40.78-122.46h-85a68 68 0 0 0 0 136.07h17a11.34 11.34 0 0 0 0-22.68z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
