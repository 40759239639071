import React from 'react';
import { applyMiddleware } from 'redux';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { config, getLanguageShort, models, StoreProvider, util } from '@vaillant-professional-ui/pui-frontend-common';

const middlewares = composeWithDevTools(applyMiddleware(thunk));

export const getBrowserLanguage = (returnDefault = true): models.Language => {
  const locale = navigator?.language;
  return getLanguageShort(locale, returnDefault) ?? 'en';
};

const systemLanguage = getBrowserLanguage();

export const StoreProviderWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const appConfig = config.useAppConfig();
  const country = util.i18n.guessCountry(
    Object.keys(appConfig.distribution.countries) as models.Country[],
    navigator?.language,
  );

  return (
    <StoreProvider
      storage={storage}
      middlewares={middlewares}
      systemLanguage={systemLanguage}
      fallbackCountry={country}
    >
      {children}
    </StoreProvider>
  );
};
