import { nonNullable } from '../../../../../util';
import { DataPoint } from './dataPoint';

export interface Category {
  type: 'category';
  title: string;
  values: Array<DataPoint | Category>;
}

export const isCategory = (category: Category['values'][number]): category is Category => {
  return category.type === 'category';
};

export const filterEmptyCategories = (values: Category['values']): Category['values'] => {
  return values
    .map((value) => {
      if (isCategory(value)) {
        if (value.values.length === 0) {
          return null;
        }
        const values = filterEmptyCategories(value.values);
        if (values.length === 0) {
          return null;
        }

        return { ...value, values };
      }
      return value;
    })
    .filter(nonNullable);
};

export const createCategory: (title: string, values: Category['values']) => Category = (title, values) => {
  return {
    type: 'category',
    title,
    values: filterEmptyCategories(values),
  };
};

export const flattenMeasuredValues = (category: Category): Array<DataPoint> => {
  const result = category.values
    .map((value) => {
      if (isCategory(value)) {
        return flattenMeasuredValues(value);
      }
      return value;
    })
    .flat();
  return result;
};

export const findDataPointForMutation = (categories: Category[], activeMutation: string) => {
  const dataPoints = categories
    .map((category) => {
      return flattenMeasuredValues(category);
    })
    .flat();
  const dataPoint = dataPoints.find(
    (dataPoint) => dataPoint.type !== 'link' && dataPoint.mutationId === activeMutation,
  );
  return dataPoint ?? null;
};
