import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api, State, store, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { useFirebase } from '../../../providers/firebase';
import ActivitySpinner from '../../ActivitySpinner';
import { AcceptTermsPage } from './AcceptTermsPage';

export const AcceptTermsPageContainer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, isDemo, logout } = useAuthContext();
  const country = useSelector(store.getCountry);
  const userInfo = api.useCases.useGetUserInfo();
  const userAccountId = userInfo.userAccountId;

  const isTermsAccepted = useSelector<State>((state) => store.getIsTermsAccepted(state, userAccountId));
  const firebaseService = useFirebase();

  const onDecline = React.useCallback(() => {
    firebaseService.logUserAction('declineTerms');
    void logout();
  }, [logout, firebaseService]);

  const onAccept = React.useCallback(() => {
    if (userAccountId) {
      dispatch(store.setTermsAccepted(userAccountId));
      firebaseService.logUserAction('acceptTerms');
    }
  }, [userAccountId, dispatch, firebaseService]);

  if (userInfo.isLoading) {
    return <ActivitySpinner showLoadingText={true} onScreen={true} loading={true} />;
  }

  const termsLink = `/terms/${country}`;
  const privacyPolicyLink = `/privacy-policy/${country}`;

  if (isAuthenticated && !isDemo && !isTermsAccepted) {
    return (
      <AcceptTermsPage
        termsLink={termsLink}
        privacyPolicyLink={privacyPolicyLink}
        onAccept={onAccept}
        onDecline={onDecline}
      />
    );
  }

  return <>{children}</>;
};
