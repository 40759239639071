import * as React from 'react';
import { useInstanceId, util } from '@vaillant-professional-ui/component-libs-common';
import { ScheduleTableProps } from './ScheduleTable.types';
import { DataCardGroup } from '../DataCardGroup';
import { ensureArray } from '../../../util/misc';
import { TickRow } from './TickRow';
import { WeekdayRow } from './WeekdayRow';

export const ScheduleTable = <T extends util.TimeWindow>({
  testIdKey,
  children,
  onSelectTimeWindow,
  onSelectRow,
  ...rest
}: ScheduleTableProps<T>) => {
  const testId = useInstanceId('scheduletable', testIdKey);

  return (
    <DataCardGroup {...rest} testIdKey={testId().key}>
      <>
        <TickRow />
        {ensureArray(children).map((child, index) =>
          React.cloneElement(child as React.ReactElement, {
            key: index,
            onRowClick: onSelectRow ? (values: T[], label: string) => onSelectRow(index, values, label) : undefined,
            onClick: onSelectTimeWindow ? (timeWindow: T) => onSelectTimeWindow(index, timeWindow) : undefined,
          }),
        )}
      </>
    </DataCardGroup>
  );
};

ScheduleTable.Row = WeekdayRow;
