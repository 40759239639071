import { IntlShape } from 'react-intl';
import * as models from '../../../typings/models';
import {
  deriveMode,
  getAttributes,
  getModeFromThreeWayValve,
  getOtherAttributes,
  getTicker,
  mergeStatusCode,
} from './LiveMonitor.utils';
import { Country, Language } from '../../../typings/models';
import { getBoilerSerialNumber, getGatewaySerialNumber } from '../../../selectors/customer';
import { TickerItem } from './LiveMonitor.types';
import { NOTIFICATIONS_LIMIT, NOTIFICATIONS_REFRESH_INTERVAL, NOTIFICATIONS_TIMEFRAME } from './LiveMonitor.constants';
import { useDateFormatter, useTranslation } from '../../../libs/hooks';
import { useProductReport } from '../../apis/product';
import { useDiagnostics } from '../../apis/diagnostics';
import { NotificationParams, useNotifications } from '../../apis/notifications';

const allBoilerStatuses = () => Array.from(Array(999).keys()).map(mergeStatusCode);

export const useTroubleCodeInsights = (boilerSerialNumber: string, country: Country, language: Language) => {
  const { data: productReport, ...rest } = useProductReport({
    country,
    language,
    serialNumber: boilerSerialNumber,
    troubleCodes: allBoilerStatuses(),
  });

  const troubleCodeInformation = productReport?.report?.[0]?.product?.troubleCodeInsights;
  return { troubleCodeInformation, ...rest };
};

export const useModeFromDiagnostics = (gatewaySerialNumber: string, enabled: boolean) => {
  const t = useTranslation();
  const df = useDateFormatter();

  const { data: diagnosticsData } = useDiagnostics(gatewaySerialNumber, t, df, {
    enabled,
    keepPreviousData: false,
  });
  return getModeFromThreeWayValve(diagnosticsData?.data?.boiler?.threeWayValve?.value);
};

const usePollStatusCodes = (gatewaySerialNumber: string, now: Date) => {
  const liveMonitorParams: NotificationParams = {
    from: new Date(now.getTime() - NOTIFICATIONS_TIMEFRAME), // 5 minutes ago,
    to: now,
    type: 'STATUS',
    sort: 'occurrenceTimestamp',
    sortDirection: 'desc',
    limit: NOTIFICATIONS_LIMIT,
  };
  const { data: liveMonitorNotifications, ...rest } = useNotifications(
    {
      gatewaySerialNumber,
      notificationParams: liveMonitorParams,
    },
    { refetchInterval: NOTIFICATIONS_REFRESH_INTERVAL, keepPreviousData: true },
  );

  const mode = deriveMode(liveMonitorNotifications ?? []);
  return { mode, liveMonitorNotifications, ...rest };
};

export const useLiveMonitor = (
  customer: models.ICustomerDetail | models.ICustomer,
  t: models.TranslationFnc,
  formatDate: IntlShape['formatDate'],
  formatTime: IntlShape['formatTime'],
  country: Country,
  language: Language,
  now = new Date(),
) => {
  const gatewaySerialNumber = getGatewaySerialNumber(customer);
  const boilerSerialNumber = getBoilerSerialNumber(customer);

  const {
    troubleCodeInformation: troubleCodeInsightList,
    isFetching,
    isLoading,
    error,
  } = useTroubleCodeInsights(boilerSerialNumber, country, language);

  const {
    mode: modeFromStatusCode,
    liveMonitorNotifications,
    isFetching: isFetchingStatusCodes,
    isLoading: isLoadingStatusCodes,
    error: statusCodesError,
  } = usePollStatusCodes(gatewaySerialNumber, now);

  const modeFromDiagnostics = useModeFromDiagnostics(gatewaySerialNumber, !modeFromStatusCode);

  // If we can't calculate the mode by the active status code, fall back to value given by diagnostics api
  const mode = modeFromStatusCode ?? modeFromDiagnostics;

  // If we still can't identify the mode the system is in, show attributes for mode 'heating'
  const attributes = getAttributes(liveMonitorNotifications ?? [], troubleCodeInsightList ?? [], mode ?? 'heating', t);

  const otherAttributes = getOtherAttributes(liveMonitorNotifications ?? [], troubleCodeInsightList ?? []);
  const tickerItems: TickerItem[] = getTicker(
    liveMonitorNotifications ?? [],
    troubleCodeInsightList ?? [],
    formatDate,
    formatTime,
  );

  return {
    isFetching: isFetching || isFetchingStatusCodes,
    isLoading: isLoading || isLoadingStatusCodes,
    error: error || statusCodesError,
    mode,
    attributes,
    otherAttributes,
    tickerItems,
  };
};
