import React from 'react';
import { Defs, LinearGradient, Stop } from '../../lib/svg';

interface IStop {
  offset: number;
  color: string;
}
interface Props {
  stops: IStop[];
  gradientMeasurementsId: string;
  gradientPredictionsId: string;
}

export const GradientDef: React.FC<React.PropsWithChildren<Props>> = ({
  stops,
  gradientMeasurementsId,
  gradientPredictionsId,
}) => (
  <Defs>
    <LinearGradient key={gradientMeasurementsId} id={gradientMeasurementsId} x1='0' y1='0' x2='0' y2='100%'>
      {stops.map((s, index) => (
        <Stop key={index} offset={`${s.offset}%`} stopColor={s.color} />
      ))}
      <Stop offset='100%' stopColor='white' />
    </LinearGradient>
    <LinearGradient key={gradientPredictionsId} id={gradientPredictionsId} x1='0' y1='0' x2='0' y2='100%'>
      <Stop offset='0%' stopColor='#AAA' />
      <Stop offset='100%' stopColor='#DDD' />
    </LinearGradient>
  </Defs>
);
