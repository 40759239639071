import { FormattedDate, FormattedTime } from 'react-intl';

import { Typography } from '@vaillant-professional-ui/component-libs-web';

import { api } from '@vaillant-professional-ui/pui-frontend-common';

import { ItemContainer, ItemLabel, ItemValue, Row } from './SystemDataOverview.styles';

interface ItemProps {
  value: api.diagnostics.DataPoint[1];
}

export const Item = ({ value }: ItemProps) => {
  // @ts-ignore typescript cannot derive type
  const timestamp = value.value?.timestamp ?? null;

  return (
    <Row>
      {!!timestamp && (
        <Typography variant='smallCopy' color='text.secondary'>
          <FormattedDate value={timestamp} />
          {', '}
          <FormattedTime value={timestamp} />
        </Typography>
      )}
      <ItemContainer>
        <ItemLabel>
          <Typography variant='body'>{value.readable.label}</Typography>
        </ItemLabel>

        <ItemValue>
          <Typography variant='body' style={{ fontWeight: 600 }} component='span'>
            {value.readable.value || '- -'}
          </Typography>
        </ItemValue>
      </ItemContainer>
    </Row>
  );
};
