/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon54200VgList01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon54200vglist01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M419.53 294.8h-204.1a11.34 11.34 0 1 1 0-22.67h204.1a11.34 11.34 0 1 1 0 22.67zm-266.46-28.34a17 17 0 1 0 17 17 17 17 0 0 0-17-17zm277.8-73.7a11.34 11.34 0 0 0-11.34-11.34h-204.1a11.34 11.34 0 0 0 0 22.68h204.1a11.34 11.34 0 0 0 11.34-11.34zm-277.8-17a17 17 0 1 0 17 17 17 17 0 0 0-17-17.01zm277.8 198.42a11.34 11.34 0 0 0-11.34-11.33h-204.1a11.34 11.34 0 1 0 0 22.67h204.1a11.34 11.34 0 0 0 11.34-11.35zm-277.8-17a17 17 0 1 0 17 17 17 17 0 0 0-17-17.01z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
