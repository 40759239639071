import { AxiosResponse } from 'axios';
import { DeviceDetailsResponseTli, TliZone } from '../../../apis/maintenance/v2';
import { WithZoneIndex } from '../../../apis/settings/settings.types';

import { patchTliResponseV2, updateIndexedObjectArray } from './generalUtil';

const getTliZones = (tliResponse: AxiosResponse<DeviceDetailsResponseTli>) => {
  return tliResponse.data.zones;
};

export const updateTliZones = <T extends WithZoneIndex>(
  updateData: T,
  previousValue: AxiosResponse<DeviceDetailsResponseTli>,
  patchFnc: (previousZones: TliZone) => TliZone,
) => {
  const zones = getTliZones(previousValue);
  if (!zones) {
    return { ...previousValue };
  }

  const newZones = updateIndexedObjectArray(zones, updateData.zoneIndex, patchFnc);
  return patchTliResponseV2(previousValue, { zones: newZones });
};
