import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { LocaleSelector } from '../../LocaleSelector';
import { SettingsPageProps } from './SettingsPage.types';
import { i18nConfig } from '@vaillant-professional-ui/pui-frontend-common';

export const SettingsLanguage: React.FC<React.PropsWithChildren<SettingsPageProps>> = ({
  userLanguage,
  onSetLanguage,
  browserLanguage,
}) => {
  return (
    <>
      <Box mb={6}>
        <Typography
          i18nKey='SG_SETTINGS_LANGUAGE'
          i18nOptions={{ 0: i18nConfig.filter((lang) => lang.language === browserLanguage).pop().full }}
          color={!userLanguage ? 'text.primary' : 'text.secondary'}
        />
      </Box>
      <LocaleSelector setLanguage={onSetLanguage} userLanguage={userLanguage} browserLanguage={browserLanguage} />
    </>
  );
};
