import { AxiosRequestConfig } from 'axios';

export const authenticationApi = {
  getUserInfo: (): AxiosRequestConfig => ({
    method: 'get',
    url: '/pui/authentication/v1/oauth/userinfo',
  }),

  getActivePermissions: (): AxiosRequestConfig => ({
    method: 'get',
    url: '/pui/authentication/v1/permissions',
  }),
};
