/*
  Can be used as a predicate that also refines the type correctly:

  const foo: Array<string | null> = ['bar', null];
  const baz: Array<string> = foo.filter(nonNullable);
*/

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}
