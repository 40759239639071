import React from 'react';

import { Box } from '@mui/material';
import { CollapsibleCard, DataCard, Typography } from '@vaillant-professional-ui/component-libs-web';
import { DataPointsTable } from '../DataPointsTable';
import { CardContentStyled } from './DataPointsCard.styled';
import { DataPointsCardProps, isDataPointRepresentationArray } from './DataPointsCard.types';
import { getLinkProps } from './DataPointsCard.util';

export const DataPointsCard: React.FC<DataPointsCardProps> = ({ title, cardProps = {}, dataPoints = [] }) => {
  return (
    <CollapsibleCard headerProps={{ title }} {...cardProps}>
      <Box mb={4} mt={[-4, -4, -6]}>
        {dataPoints.map((entry, index) => {
          if (isDataPointRepresentationArray(entry)) {
            return (
              <DataPointsTable key={index}>
                {entry.map(({ linkProps: _linkProps, disabled, ...props }) => (
                  <DataCard {...props} linkProps={getLinkProps(_linkProps, disabled)} key={props.key} variant='plain' />
                ))}
              </DataPointsTable>
            );
          }
          return (
            <CardContentStyled key={entry.title}>
              <Typography variant='h3' letterCase='allCaps'>
                {entry.title}
              </Typography>
            </CardContentStyled>
          );
        })}
      </Box>
    </CollapsibleCard>
  );
};
