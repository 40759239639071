import { TimeLocaleDefinition } from 'd3-time-format';

import { Country, Language } from '../../typings/models';

const availableLocales = [
  ['ar-EG', async () => await import('./ar-EG.json')],
  ['ar-SY', async () => await import('./ar-SY.json')],
  ['ca-ES', async () => await import('./ca-ES.json')],
  ['cs-CZ', async () => await import('./cs-CZ.json')],
  ['da-DK', async () => await import('./da-DK.json')],
  ['de-CH', async () => await import('./de-CH.json')],
  ['de-DE', async () => await import('./de-DE.json')],
  ['en-CA', async () => await import('./en-CA.json')],
  ['en-GB', async () => await import('./en-GB.json')],
  ['en-US', async () => await import('./en-US.json')],
  ['es-ES', async () => await import('./es-ES.json')],
  ['es-MX', async () => await import('./es-MX.json')],
  ['et-EE', async () => await import('./et-EE.json')],
  ['fa-IR', async () => await import('./fa-IR.json')],
  ['fi-FI', async () => await import('./fi-FI.json')],
  ['fr-CA', async () => await import('./fr-CA.json')],
  ['fr-FR', async () => await import('./fr-FR.json')],
  ['he-IL', async () => await import('./he-IL.json')],
  ['hr-HR', async () => await import('./hr-HR.json')],
  ['hu-HU', async () => await import('./hu-HU.json')],
  ['it-IT', async () => await import('./it-IT.json')],
  ['ja-JP', async () => await import('./ja-JP.json')],
  ['ko-KR', async () => await import('./ko-KR.json')],
  ['mk-MK', async () => await import('./mk-MK.json')],
  ['nb-NO', async () => await import('./nb-NO.json')],
  ['nl-BE', async () => await import('./nl-BE.json')],
  ['nl-NL', async () => await import('./nl-NL.json')],
  ['pl-PL', async () => await import('./pl-PL.json')],
  ['pt-BR', async () => await import('./pt-BR.json')],
  ['ru-RU', async () => await import('./ru-RU.json')],
  ['sk-SK', async () => await import('./sk-SK.json')],
  ['sv-SE', async () => await import('./sv-SE.json')],
  ['tr-TR', async () => await import('./tr-TR.json')],
  ['uk-UA', async () => await import('./uk-UA.json')],
  ['zh-CN', async () => await import('./zh-CN.json')],
  ['zh-TW', async () => await import('./zh-TW.json')],
] as const;

const getCountryAndLanguageFromFilename = (filename: string) => {
  const [language, country] = filename.split('-');
  return { country, language };
};

const equalIgnoreCase = (a: string, b: string) => a.toLowerCase() === b.toLowerCase();

const findLocale = (comparator: (localeCountry: string, localeLanguage: string) => boolean) => {
  return availableLocales.find(([filename]) => {
    const { country: localeCountry, language: localeLanguage } = getCountryAndLanguageFromFilename(filename);
    return comparator(localeCountry, localeLanguage);
  })?.[1];
};

export const getD3Locale = async (language: Language, country: Country): Promise<TimeLocaleDefinition> => {
  const importLocale =
    findLocale(
      (localeCountry, localeLanguage) =>
        equalIgnoreCase(country, localeCountry) && equalIgnoreCase(language, localeLanguage),
    ) ??
    findLocale(
      (localeCountry, localeLanguage) =>
        equalIgnoreCase(country, localeCountry) && equalIgnoreCase(country, localeLanguage),
    ) ??
    findLocale((localeCountry) => equalIgnoreCase(country, localeCountry)) ??
    (async () => await import('./en-GB.json'));

  const locale = await importLocale();
  return locale.default as TimeLocaleDefinition;
};
