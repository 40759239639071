import { ApiEnvironment, getApiEnvironment, AppConfig } from '../../config';
import { BrandKey, Country } from '../../typings/models';
import { IdmConfig } from '../auth';

const clientIds = {
  web: {
    dev: 'myvaillantpro-qa',
    qa: 'myvaillantpro-qa',
    prod: 'myvaillantpro-prod',
  },
  app: {
    dev: 'myvaillantpro-app-qa',
    qa: 'myvaillantpro-app-qa',
    prod: 'myvaillantpro-app-prod',
  },
} as const;

const idmUrls: Partial<Record<BrandKey, Partial<Record<Country, Record<ApiEnvironment, string>>>>> = {
  vaillant: {
    AT: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-austria',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-austria',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-austria',
    },
    BE: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-belgium',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-belgium',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-belgium',
    },
    CZ: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-czechrepublic',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-czechrepublic',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-czechrepublic',
    },
    CH: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-switzerland',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-switzerland',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-switzerland',
    },
    EE: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-estonia',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-estonia',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-estonia',
    },
    DE: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-germany',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-germany',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-germany',
    },
    DK: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-denmark',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-denmark',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-denmark',
    },
    ES: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-spain',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-spain',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-spain',
    },
    FI: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-finland',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-finland',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-finland',
    },
    FR: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-france',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-france',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-france',
    },
    GB: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-unitedkingdom',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-unitedkingdom',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-unitedkingdom',
    },
    HR: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-croatia',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-croatia',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-croatia',
    },
    HU: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-hungary',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-hungary',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-hungary',
    },
    IT: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-italy',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-italy',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-italy',
    },
    NL: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-netherlands',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-netherlands',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-netherlands',
    },
    NO: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-norway',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-norway',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-norway',
    },
    PL: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-poland',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-poland',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-poland',
    },
    RO: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-romania',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-romania',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-romania',
    },
    SE: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-sweden',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-sweden',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-sweden',
    },
    SK: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-slovakia',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-slovakia',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-slovakia',
    },
    TR: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-turkiye',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-turkiye',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-turkiye',
    },
    UA: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-ukraine',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/vaillant-ukraine',
      prod: 'https://identity.vaillant-group.com/auth/realms/vaillant-ukraine',
    },
  },
  saunierduval: {
    RO: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/sdbg-romania',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/sdbg-romania',
      prod: 'https://identity.vaillant-group.com/auth/realms/sdbg-romania',
    },
    FR: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/sdbg-france',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/sdbg-france',
      prod: 'https://identity.vaillant-group.com/auth/realms/sdbg-france',
    },
    IT: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/sdbg-italy',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/sdbg-italy',
      prod: 'https://identity.vaillant-group.com/auth/realms/sdbg-italy',
    },
    ES: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/sdbg-spain',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/sdbg-spain',
      prod: 'https://identity.vaillant-group.com/auth/realms/sdbg-spain',
    },
  },
  bulex: {
    BE: {
      dev: 'https://identity-qa.vaillant-group.com/auth/realms/bulex',
      qa: 'https://identity-qa.vaillant-group.com/auth/realms/bulex',
      prod: 'https://identity.vaillant-group.com/auth/realms/bulex',
    },
  },
} as const;

export const getIdmConfiguration = (country: Country, appConfig: AppConfig, client: 'web' | 'app'): IdmConfig => {
  const environment = getApiEnvironment(appConfig);
  const brand = appConfig.distribution.brand.key;

  const idmUrl = idmUrls[brand]?.[country]?.[environment];
  const clientId = clientIds[client]?.[environment];

  if (!idmUrl || !clientId) {
    throw new Error(`Missing IDM configuration for ${country} ${brand} ${environment} ${client}`);
  }

  const [url, realmName] = idmUrl.split('realms/');
  return {
    issuer: idmUrl,
    url,
    realm: realmName.replace('/', ''),
    clientId,
  };
};
