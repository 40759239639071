import { hasPermissions } from '../../../lib/permissions';
import { useConfiguration } from '../ConfigurationProvider';

export const usePermissions = (requiredPermissions: string[]) => {
  const { permissions } = useConfiguration();
  if (!permissions) {
    throw new Error('no permission rules passed to runtime context');
  }

  return {
    hasPermissions: hasPermissions(
      permissions.rules,
      requiredPermissions,
      permissions.userRoles,
      permissions.superAdminRole
    ),
    permissionsConfig: permissions
  };
};
