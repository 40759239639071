import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '../../atoms/Typography';
import { ModalDrawerContext } from './ModalDrawer.context';
import { Back } from './ModalDrawer.styled';
import { Icon08001VgBack01 } from '../../atoms/Icon/Icon08001VgBack01';

export interface TitleProps extends React.PropsWithChildren {}

export const Title: React.FC<TitleProps> = ({ children }) => {
  const { onClose, testId } = React.useContext(ModalDrawerContext);
  return (
    <Box py={10} px={[6, 10]} display='flex' flexDirection='row' alignItems='center'>
      <Box width={40} ml={-2}>
        <Back onClick={onClose} {...testId('back').testIdAttributes}>
          <Icon08001VgBack01 width={32} height={32} />
        </Back>
      </Box>
      <Typography variant='h2' bold={true} {...testId('title').testIdAttributes}>
        {children}
      </Typography>
    </Box>
  );
};
