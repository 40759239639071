export const getPrintableError = (error: unknown) => {
  if (!error) {
    return '';
  }
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return `${error.name}${error.message ? `: ${error.message}` : ''}`;
  }

  return '';
};
