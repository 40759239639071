import { useFeatureToggle } from '../../../contexts/featureContext';
import { useGetActivePermissions, useGetUserInfo as useGetUserInfoBase } from '../../apis/authentication';
import { useAuthContext } from '../../../contexts/authContext';
import { getCombinedQueryStatus } from '../useCases.util';
import { useEffect } from 'react';

export const useExtendedPermissions = () => {
  const { isLoading, isError, permissions } = useGetUserInfo();
  const activePermissions = permissions?.activePermissions ?? [];
  const canReadExtendedDataPoints = activePermissions.includes('READ_EXTENDED_DATA_POINTS');
  const canWriteExtendedDataPoints = activePermissions.includes('WRITE_EXTENDED_DATA_POINTS');
  return {
    canWriteExtendedDataPoints,
    canReadExtendedDataPoints,
    activePermissions: permissions?.activePermissions,
    isLoading,
    isError,
  };
};

export const useIsExpertModeActive = () => {
  const { canReadExtendedDataPoints } = useExtendedPermissions();
  return { isExpertModeActive: canReadExtendedDataPoints };
};

export const useGetUserInfo = (options: Parameters<typeof useGetUserInfoBase>[0] = {}) => {
  const { isAuthenticated } = useAuthContext();
  const { setFeature } = useFeatureToggle();

  const isEnabled = options.enabled ?? isAuthenticated;
  const combinedOptions = {
    staleTime: 1000 * 60 * 10, // 10m Stale,
    enabled: isEnabled,
    ...options,
  };
  const { data: userInfo, ...restUserInfo } = useGetUserInfoBase(combinedOptions);
  const { data: permissions, ...restPermissions } = useGetActivePermissions(userInfo?.user?.contact?.id ?? null, {
    staleTime: combinedOptions.staleTime,
  });

  useEffect(() => {
    setFeature('system-settings', !!permissions?.activePermissions?.includes('READ_EXTENDED_DATA_POINTS'));
    setFeature('time-series', !!permissions?.activePermissions?.includes('ADVANCED_SYSTEM_MONITORING'));
    setFeature(
      'guided-services-available',
      !!permissions?.activePermissions?.includes('GUIDED_SERVICES_FEATURE_ACTIVE'),
    );
    setFeature(
      'guided-services-paid',
      !!permissions?.activePermissions?.includes('EXTENDED_GUIDED_SERVICES_AVAILABLE'),
    );
  }, [permissions?.activePermissions, setFeature]);

  // User selectors
  const user = userInfo?.user;
  const userContactId = user?.contact?.id;
  const userAccountId = user?.account?.id;
  const userContactHash = user?.contact?.hash;
  const userAccountHash = user?.account?.hash;

  return {
    userInfo,
    permissions,
    user,
    userContactId,
    userAccountId,
    userContactHash,
    userAccountHash,
    refetch: restUserInfo.refetch,
    ...getCombinedQueryStatus(restUserInfo, restPermissions),
  };
};
