import { UseAxiosMutationOptions, useMutation, useQuery } from '../../../hooks';
import { schedulesApi } from './schedules.endpoints';
import { SchedulePayload, ScheduleResponse } from './schedules.types';

export const useUpdateSchedule = <TMutated>(
  gatewaySerialNumber: string,
  options?: UseAxiosMutationOptions<unknown, SchedulePayload, TMutated>,
) => {
  return useMutation((params: SchedulePayload) => schedulesApi.updateSchedule(gatewaySerialNumber, params), options);
};

export const useSchedules = (gatewaySerialNumber: string, options = {}) => {
  return useQuery<ScheduleResponse, Error, ScheduleResponse>(
    ['schedules', gatewaySerialNumber],
    schedulesApi.getSchedule(gatewaySerialNumber),
    {
      select: (result) => result.data,
      staleTime: 1000 * 30,
      ...options,
    },
  );
};
