import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon54200VgList01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon30700VgAddressLocation01 } from '@vaillant-professional-ui/component-libs-web';
import { Stack } from '@mui/material';
import { SwitcherButtonGroup } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { useFirebase } from '../../providers/firebase';
import { ViewMode } from './Dashboard.types';

interface MapToggle {
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
}

export const MapToggle: React.FC<React.PropsWithChildren<MapToggle>> = ({ viewMode, setViewMode }) => {
  const t = hooks.useTranslation();
  const firebaseService = useFirebase();

  const handleClick = (viewMode: ViewMode) => {
    firebaseService.logUserAction('dashboardViewModeToggle', { viewMode });
    setViewMode(viewMode);
  };

  const buttons = [
    {
      value: 'list',
      children: (
        <Stack direction='row' spacing={1}>
          <Icon54200VgList01 />
          <Typography>{t('SG_DASHBOARD_LIST')}</Typography>
        </Stack>
      ),
    },
    {
      value: 'map',
      children: (
        <Stack direction='row' spacing={1}>
          <Icon30700VgAddressLocation01 />
          <Typography>{t('SG_DASHBOARD_MAP')}</Typography>
        </Stack>
      ),
    },
  ];

  return (
    <SwitcherButtonGroup
      testIdKey='map-toggle'
      onChange={(_, viewMode) => handleClick(viewMode as ViewMode)}
      buttons={buttons}
      selectedValue={viewMode}
    />
  );
};
