import { ThemeOptions } from '@mui/material';
import { createTheme as createMuiTheme, Theme as MuiTheme } from '@mui/material/styles';
import {
  baseTheme as baseThemes,
  BaseTheme,
  ConfigurationProviderProps,
} from '@vaillant-professional-ui/component-libs-common';

import { addResponsiveFonts, createTypography, typographyVariantMapping } from './typography';

const breakpoints: ThemeOptions['breakpoints'] = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

export const createThemeOptions = (muiTheme: MuiTheme, baseTheme: BaseTheme): ThemeOptions => {
  return {
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: typographyVariantMapping,
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: baseTheme.colors.greyDark,
              },
              '&.Mui-focused fieldset': {
                borderColor: baseTheme.colors.greyDark,
              },
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
        styleOverrides: {
          root: {
            paddingTop: muiTheme.spacing(2),
            paddingBottom: muiTheme.spacing(2),
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
        styleOverrides: {
          icon: { top: 'calc(20%)' },
          select: {
            padding: muiTheme.spacing(2),
            '& ~ label': {
              transform: 'none !important',
              top: 9,
              paddingLeft: muiTheme.spacing(2),
            },
            '& ~ fieldset legend': {
              display: 'none',
            },
          },
          outlined: {
            paddingRight: `${muiTheme.spacing(12)} !important`,
            borderWidth: 0,
            color: muiTheme.palette.primary.main,
            '& ~ fieldset': {
              borderColor: 'black',
            },
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
          filled: {
            paddingRight: `${muiTheme.spacing(12)} !important`,
            borderWidth: 0,
            borderRadius: 5,
            color: muiTheme.palette.common.white,
            backgroundColor: muiTheme.palette.primary.main,
            '&:focus': {
              borderRadius: 5,
              backgroundColor: muiTheme.palette.primary.main,
            },
            '&.Mui-disabled': {
              backgroundColor: `${muiTheme.palette.grey[400]} !important`,
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingTop: muiTheme.spacing(6),
            '& button': {
              position: 'absolute',
              right: muiTheme.spacing(1),
              top: muiTheme.spacing(1),
              color: muiTheme.palette.primary.main,
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: muiTheme.spacing(4),
            alignSelf: 'center',
            paddingBottom: muiTheme.spacing(6),
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: 38,
            fontSize: 16,
            borderRadius: baseTheme.components.button.borderRadius,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 32,
            paddingRight: 32,
          },
          startIcon: {
            display: 'inline-block',
            marginTop: 1,
            marginLeft: -20,
            lineHeight: 'normal',
            verticalAlign: 'middle',
          },
          endIcon: {
            display: 'inline-block',
            marginTop: 1,
            marginRight: -20,
            lineHeight: 'normal',
            verticalAlign: 'middle',
          },
          sizeLarge: {
            fontSize: 20,
          },
          text: {
            minWidth: 64,
            paddingLeft: 24,
            paddingRight: 24,
            '&:hover': {
              border: 'none',
              backgroundColor: 'transparent',
            },
          },
          containedPrimary: {
            color: 'white',
          },
          containedSecondary: {
            color: 'white',
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: baseTheme.components.button.borderRadius,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: 0,
            borderColor: muiTheme.palette.grey['400'],
            borderWidth: 1,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: muiTheme.spacing(4),
            [muiTheme.breakpoints.up('sm')]: {
              padding: muiTheme.spacing(6),
            },
            // To match the styleguide, pull the first content element after the header up
            '+ .MuiCardContent-root': {
              marginTop: muiTheme.spacing(-4),
              [muiTheme.breakpoints.up('sm')]: {
                marginTop: muiTheme.spacing(-6),
              },
            },
          },
          title: {
            ...muiTheme.typography.h2,
          },
          subheader: {
            marginTop: muiTheme.spacing(3),
            ...muiTheme.typography.h4,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: muiTheme.spacing(4),
            [muiTheme.breakpoints.up('sm')]: {
              padding: muiTheme.spacing(6),
            },
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            justifyContent: 'center',
            marginBottom: muiTheme.spacing(10),
          },
        },
      },
      MuiCardActionArea: {
        styleOverrides: {
          focusHighlight: {
            background: 'transparent',
          },
          root: {
            background: 'transparent',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: muiTheme.palette.grey[500],
            backgroundColor: muiTheme.palette.common.white,
            fontSize: 16,
            paddingRight: 8,
            '&.Mui-focused': {
              color: muiTheme.palette.common.white,
            },
            '&.MuiInputLabel-outlined': {
              color: muiTheme.palette.primary.main,
            },
            '&.Mui-error + div input': {
              color: muiTheme.palette.error.main,
            },
            '&.Mui-error': {
              color: `${muiTheme.palette.error.main} !important`,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'primary',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&$error': {
              fontSize: 14,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderRadius: 5,
              borderWidth: 1,
            },
            '&.Mui-focused fieldset': {
              borderWidth: '1px !important',
              borderColor: muiTheme.palette.common.black,
            },
            '&:before, &:after': {
              borderColor: `${muiTheme.palette.primary.main} !important`,
            },
          },
          input: {
            fontSize: 16,
          },
          multiline: {
            backgroundColor: 'white',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          filled: {
            color: muiTheme.palette.grey[100],
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          groupUl: {
            borderRadius: 'none',
          },
          paper: {
            padding: 0,
            margin: 0,
            borderRadius: 0,
          },
          listbox: {
            padding: 0,
          },
          popupIndicator: {
            display: 'none',
          },
          option: {
            '&[data-focus=true],&[aria-selected=true]': {
              backgroundColor: muiTheme.palette.primary.lightest,
            },
            width: '100%',
            paddingLeft: 16,
            paddingRight: 16,
            borderBottom: `1px ${muiTheme.palette.grey[500]} solid`,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: muiTheme.palette.secondary.dark,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: muiTheme.palette.primary.lightest,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            padding: 0,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: '8px',
            padding: muiTheme.spacing(4),
          },
          outlined: {
            borderColor: muiTheme.palette.primary.main,
            borderWidth: 2,
          },
          elevation1: {
            boxShadow: '0 2px 4px 0 rgba(51,51,51,0.1)',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderWidth: 1,
            borderColor: muiTheme.palette.grey[500],
            padding: muiTheme.spacing(3),
            textTransform: 'none',
            '&.Mui-selected': {
              backgroundColor: muiTheme.palette.primary.lightest,
              color: muiTheme.palette.common.black,
              borderWidth: 2,
              borderColor: muiTheme.palette.primary.main,
              padding: muiTheme.spacing(3),
              '&.Mui-selected:hover': {
                backgroundColor: muiTheme.palette.grey[300],
              },
            },
          },
        },
      },

      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            flexGrow: 1,
          },
          grouped: {
            flexGrow: 1,
            '&:not(:first-of-type)': {
              '&.Mui-selected': {
                borderWidth: 2,
                borderColor: muiTheme.palette.primary.main,
              },
            },
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: muiTheme.palette.primary.darker,
            color: muiTheme.palette.common.white,
            fontSize: 12,
            paddingBottom: 8,
            '& h5': {
              fontSize: 14,
              fontWeight: 'bold',
            },
            '&.bright': {
              backgroundColor: muiTheme.palette.primary.main,
            },
            '& a': {
              borderBottom: '1px #FF8500 solid',
            },
            '& a:hover': {
              textDecoration: 'none',
            },
          },
          arrow: {
            color: muiTheme.palette.primary.darker,
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          extended: {
            backgroundColor: muiTheme.palette.secondary.main,
            marginRight: 0,
            borderRadius: '24px 0px 0px 24px',
            marginBottom: muiTheme.spacing(2.5),
            paddingRight: muiTheme.spacing(2.5),
            paddingTop: muiTheme.spacing(1.25),
            '&:hover': {
              backgroundColor: muiTheme.palette.secondary.dark,
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            borderRadius: 8,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: muiTheme.palette.primary.lighter,
            },
            '&:hover, &.Mui-selected:hover': {
              backgroundColor: muiTheme.palette.grey[100],
            },
          },
          head: {
            '&:hover': {
              backgroundColor: 'inherit',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: 18,
          },
          head: {
            flexDirection: 'row-reverse',
          },
          body: {
            paddingLeft: 24,
          },
          sizeSmall: {
            paddingLeft: 0,
            borderBottom: 'none',
            verticalAlign: 'top',
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '.MuiTableSortLabel-icon': {
              opacity: '1 !important',
              fill: muiTheme.palette.secondary.main,
            },
            '&.Mui-active': {
              color: `${muiTheme.palette.primary.main} !important`,
              '.MuiTableSortLabel-icon path:not([fill="none"]),rect:not([fill="none"]),circle:not([fill="none"]),text:not([fill="none"]),ellipse:not([fill="none"])':
                {
                  fill: `${muiTheme.palette.primary.main} !important`,
                  opacity: '1 !important',
                },
              p: {
                color: muiTheme.palette.primary.main,
              },
            },
            fontSize: 16,
            textTransform: 'uppercase',
            '&:hover': {
              color: muiTheme.palette.primary.main,
              '&.MuiTableSortLabel-icon': {
                fill: muiTheme.palette.primary.main,
                opacity: '1 !important',
              },
            },
            '&:not(.Mui-active)': {
              '&& .MuiTableSortLabel-icon': {
                transform: 'rotate(0deg)',
              },
            },
          },
          icon: {
            marginLeft: muiTheme.spacing(0.5),
          },
          iconDirectionAsc: {
            transform: 'rotate(180deg)',
          },
          iconDirectionDesc: {
            transform: 'rotate(0deg)',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            paddingBottom: 8,
            paddingTop: 12,
          },
          standardWarning: {
            backgroundColor: muiTheme.palette.error.main,
            color: 'white',
            '&& $icon': {
              color: 'white',
            },
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontSize: 16,
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          allowScrollButtonsMobile: true,
        },
        styleOverrides: {
          indicator: {
            backgroundColor: muiTheme.palette.primary.main,
            height: 3,
          },
          scrollButtons: {
            color: muiTheme.palette.primary.main,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: 16,
            textTransform: 'none',
            color: muiTheme.palette.common.black,
            '&.Mui-selected': {
              color: muiTheme.palette.common.black,
            },
            '&:disabled': {
              color: muiTheme.palette.grey[400],
            },
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          colorSecondary: {
            color: muiTheme.palette.common.white,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: (styles) =>
            styles.ownerState.variant === 'outlined' && {
              borderWidth: '2px',
            },
          colorWarning: (styles) =>
            styles.ownerState.variant === 'filled' && {
              color: styles.theme.palette.common.white,
            },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            height: 2,
          },
        },
      },
      MuiAccordion: {
        defaultProps: {
          disableGutters: true,
          elevation: 0,
        },
        styleOverrides: {
          root: {
            padding: 0,
            '&.Mui-expanded:before': {
              opacity: 1,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
          },
          content: {
            margin: 0,
            marginTop: muiTheme.spacing(4),
            marginBottom: muiTheme.spacing(4),
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
            paddingBottom: muiTheme.spacing(2),
          },
        },
      },
    },
  };
};

export const createPalette = (baseTheme: BaseTheme): ThemeOptions['palette'] => {
  return {
    text: {
      primary: baseTheme.colors.black,
      disabled: baseTheme.colors.grey,
    },
    mode: 'light',
    primary: {
      main: baseTheme.colors.primary,
      dark: baseTheme.colors.primaryDark,
      darker: baseTheme.colors.primaryDarker,
      darkest: baseTheme.colors.primaryDarkest,
      light: baseTheme.colors.primaryLight,
      lighter: baseTheme.colors.primaryLighter,
      lightest: baseTheme.colors.primaryLightest,
    },
    secondary: {
      main: baseTheme.colors.secondary,
      light: baseTheme.colors.secondaryLightest,
      lightest: baseTheme.colors.secondaryLightest,
    },
    success: {
      main: baseTheme.colors.success,
      light: baseTheme.colors.successLight,
      lightest: baseTheme.colors.successLightest,
    },
    error: {
      main: baseTheme.colors.error,
      light: baseTheme.colors.errorLight,
      lightest: baseTheme.colors.errorLightest,
    },
    warning: {
      main: baseTheme.colors.warning,
      light: baseTheme.colors.warningLight,
      lightest: baseTheme.colors.warningLightest,
    },
    grey: {
      '100': baseTheme.colors.greyLightest,
      '300': baseTheme.colors.greyLightest,
      '350': baseTheme.colors.greyLight,
      '400': baseTheme.colors.grey,
      '500': baseTheme.colors.greyDark,
      '800': baseTheme.colors.greyDarkest,
    },
    common: {
      black: baseTheme.colors.black,
      white: baseTheme.colors.white,
    },
  };
};

const addResponsiveFontsAndButtons = (muiTheme: MuiTheme) => {
  addResponsiveFonts(muiTheme);

  if (muiTheme?.components?.MuiButton?.styleOverrides?.root) {
    muiTheme.components.MuiButton.styleOverrides.root = {
      ...(muiTheme.components.MuiButton.styleOverrides.root as object),
      [muiTheme.breakpoints.down('md')]: {
        minHeight: 50,
      },
    };
  }
};

export const createTheme = (themeName: ConfigurationProviderProps['themeName']): MuiTheme => {
  // Base theme options for all platforms
  const baseTheme = baseThemes[themeName];

  // MUI color palette using base colors, and web specific colors
  const palette = createPalette(baseTheme);

  // Create base options with empty components first.
  // @see MUI guide: https://mui.com/material-ui/customization/theming/#api => Theme composition: using theme options to define other options
  const baseThemeOptions: ThemeOptions = {
    breakpoints,
    components: {},
    palette,
    typography: createTypography(baseTheme.platforms.web.typography),
    mixins: {},
    spacing: 4,
  };

  // Create MUI theme using empty components
  const muiTheme = createMuiTheme(baseThemeOptions);
  addResponsiveFontsAndButtons(muiTheme);

  // Create components using MUI Theme and base theme
  const themeOptions = createThemeOptions(muiTheme, baseTheme);

  // Create final MUI theme
  return createMuiTheme(muiTheme, themeOptions);
};
