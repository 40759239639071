import * as React from 'react';
import { Box, Divider } from '@mui/material';
import { Translated, useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { PageHeaderProps } from './PageHeader.types';
import { Typography } from '../../atoms/Typography';
import { Grid } from '../../atoms/Grid';

export const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  testIdKey,
  i18nKey,
  i18nOptions,
  rightComponent,
  subtitle,
  title,
  icon,
  ...rest
}) => {
  const testId = useInstanceId('pageheader', testIdKey);

  const IconComponent = icon || (() => null);

  return (
    <Box display='flex' flexDirection='column' {...testId().testIdAttributes} {...rest}>
      <Box mb={6}>
        <Grid container direction={{ xs: 'column', sm: 'row' }} wrap='nowrap' alignItems='center'>
          <Grid container alignItems='center' item xs={6}>
            <Translated
              component={
                <Typography style={{ display: 'inline' }} variant='h3' bold={true } mb={-2}>
                  {title}
                </Typography>
              }
              i18nKey={i18nKey}
              i18nOptions={i18nOptions}
            />
            <Box ml={4} display='flex'>
              <IconComponent width={28} height={28} />
            </Box>
          </Grid>
          {!!rightComponent && (
            <Grid container item xs={6} justifyContent={{ xs: 'flex-start', sm: 'flex-end' }} mt={{ xs: 2, sm: 0 }}>
              {rightComponent}
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mb={8}>
        <Divider />
      </Box>
      {!!subtitle && (
        <Box mb={4}>
          {typeof subtitle === 'string' ? (
            <Typography color='primary' variant='h3'>
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </Box>
      )}
    </Box>
  );
};
