import React from 'react';

import { useSelector } from 'react-redux';

import { features, store } from '@vaillant-professional-ui/pui-frontend-common';

import { useBoilerSerialNumberFromPath } from '../../../components/Routes';
import { useNavigate } from 'react-router';
import { ChartTabs } from './ChartTabs';
import { Icon00500VgBurner01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon02900VgHeatPump01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon05601VgConnectivityCommuncation01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon13201VgControl01 } from '@vaillant-professional-ui/component-libs-web';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ChartTab } from '@vaillant-professional-ui/pui-frontend-common/src/api/apis/maintenance/v2/maintenance.types';
import { Icon00800VgVentilation01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon16100VgSolarCollector01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon03000VgStorage01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon16000VgAccessory01 } from '@vaillant-professional-ui/component-libs-web';

const iconTypeMap: Record<NonNullable<ChartTab['icon']>, ReactJSXElement | null> = {
  HEAT_PUMP: <Icon02900VgHeatPump01 />,
  BOILER: <Icon00500VgBurner01 />,
  GATEWAY: <Icon05601VgConnectivityCommuncation01 />,
  CONTROL: <Icon13201VgControl01 />,
  VENTILATION: <Icon00800VgVentilation01 />,
  THERMOSTAT: <Icon13201VgControl01 />,
  ERELAX_THERMOSTAT: <Icon13201VgControl01 />,
  BASIC_REMOTE: <Icon13201VgControl01 />,
  HP_ACCESSORY: <Icon02900VgHeatPump01 />,
  ACCESSORY: <Icon16000VgAccessory01 />,
  FRESH_WATER_STATION: null,
  STORAGE: <Icon03000VgStorage01 />,
  SOLAR_STATION: <Icon16100VgSolarCollector01 />,
  CAG: <Icon05601VgConnectivityCommuncation01 />,
  ERELAX: <Icon13201VgControl01 />,
  TLI: <Icon13201VgControl01 />,
  VRC700: <Icon13201VgControl01 />,
  DEFAULT: null,
};

const getIcon = (name: ChartTab['icon']) => {
  if (!name) {
    return null;
  }
  return iconTypeMap[name] ?? null;
};

const usePresenter = () => {
  const boilerSerialNumber = useBoilerSerialNumberFromPath();
  const language = useSelector(store.getLanguage);
  const { tabs, selectedTabId, setSelectedTabId, isLoading, error } =
    features.historyCharts.historyChartsList.useTabsPresenter({
      boilerSerialNumber,
      language,
    });

  const tabsWithIcons = tabs.map((tab) => {
    return { ...tab, icon: getIcon(tab.iconType) };
  });

  const navigate = useNavigate();
  React.useEffect(() => {
    if (selectedTabId) {
      navigate(`./${selectedTabId}`);
    }
  }, [navigate, selectedTabId]);

  return { tabs: tabsWithIcons, selectedTabId, setSelectedTabId, isLoading, error };
};

export const ChartTabsContainer: React.FC = () => {
  const { tabs, selectedTabId, setSelectedTabId, isLoading, error } = usePresenter();

  if (!selectedTabId) {
    return null;
  }

  return (
    <ChartTabs
      tabs={tabs}
      selectedTabId={selectedTabId}
      setSelectedTabId={setSelectedTabId}
      isLoading={isLoading}
      error={error}
    />
  );
};
