import { styled } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { CustomerCardProps } from './CustomerCards.types';

import { Box, Typography } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Icon08201VgRight01 } from '@vaillant-professional-ui/component-libs-web';
import { useNavigate } from 'react-router';
import { HighlightText } from '../HighlightText';

const CustomerItem = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomerCard: React.FC<CustomerCardProps> = ({ customer, markText }) => {
  const testId = useInstanceId('customer-cards', 'mobile');
  const navigate = useNavigate();

  return (
    <CustomerItem
      {...testId(`row-${customer.id}-card`).testIdAttributes}
      onClick={() => navigate(customer.singlePageHref)}
    >
      <div>
        <Box my={3}>
          <HighlightText markText={markText}>{customer.fullName}</HighlightText>
        </Box>
        <Box my={3}>
          {customer.nomenclature ? <HighlightText markText={markText}>{customer.nomenclature}</HighlightText> : null}
          {customer.marketingName ? (
            <Typography color='text.secondary'>
              <HighlightText markText={markText}>{customer.marketingName}</HighlightText>
            </Typography>
          ) : null}
        </Box>
        <Box my={3}>
          <HighlightText markText={markText}>{customer.addressLine1}</HighlightText>
          <Typography color='text.secondary'>
            <HighlightText markText={markText}>{customer.addressLine2}</HighlightText>
          </Typography>
        </Box>
      </div>
      <Icon08201VgRight01 color='primary.main' width={32} height={32} />
    </CustomerItem>
  );
};
