import { Switch } from '@mui/material';
import { styled } from '../../../util/styling';

const HEIGHT = 32;

export const IOSSwitch = styled(Switch)`
  width: 51px;
  height: ${HEIGHT}px;
  padding: 0px;

  & .MuiSwitch-switchBase {
    padding: 3px;
    margin: auto 0px;
    bottom: 0;
    transition-duration: 300ms;

    &.Mui-checked {
      transform: translateX(20px);
      color: ${({ theme }) => theme.palette.common.white};

      & + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.palette.primary.main};
        opacity: 1;
        border: 0px;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: ${({ theme }) => theme.palette.primary.darker};
      border: 6px solid ${({ theme }) => theme.palette.common.white};
    }

    &.Mui-disabled .MuiSwitch-thumb {
      color: ${({ theme }) => theme.palette.grey[100]};
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.7;
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    box-shadow:
      0px 3px 2px 0px #3333331a,
      0px 0px 2px 1px #3333331a,
      0px 1px 2px 0px #3333331a;
  }

  & .MuiSwitch-track {
    border-radius: ${HEIGHT / 2}px;
    background-color: ${({ theme }) => theme.palette.grey[400]};
    opacity: 1;
    transition: ${({ theme }) =>
      theme.transitions.create(['background-color'], {
        duration: 500
      })};
  }
`;
