import React from 'react';
import { AppConfig } from './config';

interface Props {
  config: AppConfig;
}

const AppConfigContext = React.createContext<AppConfig | null>(null);

export const AppConfigProvider: React.FC<React.PropsWithChildren<Props>> = ({ config, children }) => {
  return <AppConfigContext.Provider value={config}>{children}</AppConfigContext.Provider>;
};

export const useAppConfig = (): AppConfig => {
  const appCfg = React.useContext(AppConfigContext);
  if (!appCfg) {
    throw new Error('App config used before initialized');
  }
  return appCfg;
};
