import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { PopoverProps } from './Popover.types';
import { Box, Popover as MuiPopover, styled } from '@mui/material';

export const Popover: React.FunctionComponent<PopoverProps> = ({ testIdKey, children, ...rest }) => {
  const testId = useInstanceId('popover', testIdKey);

  return (
    <MuiPopover
      {...rest}
      {...testId('root').testIdAttributes}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          borderRadius: 0,
        },
      }}
    >
      <Container>{children}</Container>
    </MuiPopover>
  );
};

const Container = styled(Box)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary.lightest};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 3px;
  box-shadow: 0px 2px 4px 0px #0000001a;

  &:before {
    background-color: inherit;
    border-color: inherit;
    border-style: solid;
    border-width: 1px 0px 0px 1px;
    border-radius: 2px 0 0 0;
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    left: -7px;
    transform: rotate(-54deg) skew(-20deg);
    top: max(10px, 25%);
  }
`;
