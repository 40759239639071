import React from 'react';

import { addDays, startOfDay } from 'date-fns';

const START = 'history-charts:start';
const END = 'history-charts:end';
const DEFAULT_RANGE_IN_DAYS = 3;
const EVENT_NAME = 'date-range';
const EVENT_KEY = 'history-charts';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

const persistDateRange = ({ startDate, endDate }: DateRange) => {
  const start = startDate.toISOString();
  const end = endDate.toISOString();
  const { start: previousStart, end: previousEnd } = getPersistedValues();
  if (previousStart !== start || previousEnd !== end) {
    sessionStorage.setItem(START, start);
    sessionStorage.setItem(END, end);
    window.dispatchEvent(new StorageEvent(EVENT_NAME, { key: EVENT_KEY }));
  }
};

const getPersistedValues = () => {
  const start = sessionStorage.getItem(START);
  const end = sessionStorage.getItem(END);
  return { start, end };
};

const getDateRange = (): DateRange => {
  const { start, end } = getPersistedValues();
  if (!start || !end) {
    console.error('storage not initialized');
    initializeStorage();
    return getDateRange();
  }

  const startDate = new Date(start);
  const endDate = new Date(end);
  return { startDate, endDate };
};

const initializeStorage = () => {
  const { start, end } = getPersistedValues();
  if (!start || !end) {
    const endDate = startOfDay(new Date());
    const startDate = addDays(endDate, -DEFAULT_RANGE_IN_DAYS);
    persistDateRange({ startDate, endDate });
  }
  return {};
};

initializeStorage();

const useStorageEventListener = (save: (dateRange: DateRange) => void) => {
  // update state on every storage change
  const eventListener: EventListener = React.useCallback(
    (event) => {
      if ((event as StorageEvent).key && (event as StorageEvent).key !== EVENT_KEY) {
        return;
      }

      save(getDateRange());
    },
    [save],
  );

  // handle livetime of the event listener
  React.useEffect(() => {
    window.addEventListener(EVENT_NAME, eventListener);

    return () => {
      window.removeEventListener(EVENT_NAME, eventListener);
    };
  }, [eventListener]);
};

export const useDateRangePersistor = () => {
  const [dateRange, save] = React.useState<DateRange>(getDateRange());

  useStorageEventListener(save);

  // persist every time, the date range changes
  React.useEffect(() => {
    persistDateRange(dateRange);
  }, [dateRange]);

  return {
    start: dateRange.startDate,
    end: dateRange.endDate,
    save,
  };
};
