import React from 'react';
import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { useSystem } from '../../apis/maintenance/v2';
import { UseSystemDevices } from './System.types';
import { mapDevicesResponseToSystemOverviewDevices } from './System.util';

export const useSystemDevices: UseSystemDevices = (systemId, sortDevicesFunction) => {
  const t = useTranslation();

  const { data, isLoading, error } = useSystem({ systemId: systemId ?? '' }, { enabled: !!systemId });
  return React.useMemo(() => {
    const devices = data?.devices && mapDevicesResponseToSystemOverviewDevices(data.devices, sortDevicesFunction, t);

    return { devices, isLoading, isError: !!error, error };
  }, [data?.devices, sortDevicesFunction, isLoading, error, t]);
};
