import { api, store } from '../../../index';
import { getBoilerSerialNumber, getEndUserFullName } from '../../selectors/customer';
import { useSelector } from 'react-redux';
import { useNotes } from './useNotes';
import { useCustomerTroubleCodeNotificationStatus } from './useCustomerTroubleCodeNotificationStatus';
import { transformCustomerToCustomerDetail } from './transformCustomerToCustomerDetail';

interface UseCustomerDetailsCaseParams {
  boilerSerialNumber: string;
}

export const useCase = ({ boilerSerialNumber }: UseCustomerDetailsCaseParams) => {
  const {
    customer: customerDetail,
    isLoading: isLoadingCustomerData,
    error,
    errorDescription,
  } = api.useCases.useCustomerDetails(boilerSerialNumber);

  const { data: customerList = [] } = api.maintenance.v1.useCustomerList({ enabled: false });

  const itemFromList = customerList.find((item) => getBoilerSerialNumber(item) === boilerSerialNumber);

  const customer = customerDetail || (itemFromList && transformCustomerToCustomerDetail(itemFromList)) || null;

  const systemDevices = api.useCases.useSystemDevices(customer?.system.systemId);

  const customerFullName = customer ? getEndUserFullName(customer) : null;

  const dtcDeviceId = systemDevices.devices?.find((device) => device.serialNumber === boilerSerialNumber)?.id;

  const language = useSelector(store.getLanguage);
  const country = useSelector(store.getCountry);

  const notes = useNotes({ boilerSerialNumber });

  const { userContactId } = useCustomerTroubleCodeNotificationStatus({ customer });

  return {
    customer,
    isLoading: isLoadingCustomerData,
    error,
    errorDescription,
    customerFullName,
    dtcDeviceId,
    language,
    country,
    ...notes,
    userContactId,
  };
};
