import * as React from 'react';
import { useInstanceId, useTranslation, util } from '@vaillant-professional-ui/component-libs-common';
import { ScheduleProfileItemProps } from './ScheduleProfileItem.types';
import { Box } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { ScheduleProfileIcon } from '../../atoms/ScheduleProfileIcon';
import { Icon00100VgHeating01 } from '../../atoms/Icon/Icon00100VgHeating01';
import { Icon00300VgDomesticHotWater01 } from '../../atoms/Icon/Icon00300VgDomesticHotWater01';

export const ScheduleProfileItem: React.FunctionComponent<ScheduleProfileItemProps> = ({ profile, testIdKey }) => {
  const testId = useInstanceId('scheduleprofileitem', testIdKey);
  const t = useTranslation();
  const profileTitle = util.getErelaxProfileTitle(profile, t);

  return (
    <Box display='flex' flexDirection='column' gap={1} {...testId('root').testIdAttributes}>
      <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
        <ScheduleProfileIcon profile={profile} />
        <Typography variant='body'>{profileTitle}</Typography>
      </Box>
      <Box mt={2} ml={1} display='flex' flexDirection='row' gap={4}>
        <Icon00100VgHeating01 />
        <Typography variant='body'> {profile.targetTemperature} °C </Typography>
      </Box>
      <Box ml={1} display='flex' flexDirection='row' gap={4}>
        <Icon00300VgDomesticHotWater01 />
        <Typography
          variant='body'
          i18nKey={profile.domesticHotWater ? 'VG_ERELAX_SCHEDULE_DHW_ON' : 'VG_ERELAX_SCHEDULE_DHW_OFF'}
        />
      </Box>
    </Box>
  );
};
