import React from 'react';
import { EmfDeviceSelectionProps } from './EmfDeviceSelection.types';
import { Section } from './Section';

export const EmfDeviceSelection = ({ devices, filters, setFilters }: EmfDeviceSelectionProps) => {
  const handleChange = (deviceId: string, checked: boolean) => {
    const selectedDevices =
      filters.selectedDevices === 'all' ? devices.map((_device) => _device.id) : filters.selectedDevices ?? [];

    let deviceFilters = [...selectedDevices];
    if (checked && !selectedDevices.includes(deviceId)) {
      deviceFilters.push(deviceId);
    } else if (!checked && selectedDevices.includes(deviceId)) {
      deviceFilters = deviceFilters.filter((_system) => _system !== deviceId);
    }

    setFilters({ ...filters, selectedDevices: deviceFilters.length === devices.length ? 'all' : deviceFilters });
  };

  const heatingGeneratorTypes = ['BOILER', 'HEATPUMP', 'ELECTRIC_AUXILIARY_HEATER'];
  const heatingGenerators = devices.filter((device) => heatingGeneratorTypes.includes(device.type));
  const additionalComponents = devices.filter((device) => !heatingGeneratorTypes.includes(device.type));

  return (
    <>
      {heatingGenerators.length > 0 && (
        <Section
          i18nKey='SG_EMF_HEAT_GENERATORS'
          devices={heatingGenerators}
          onChange={handleChange}
          filters={filters}
        />
      )}

      {additionalComponents.length > 0 && (
        <Section
          i18nKey='SG_EMF_ADDITIONAL_COMPONENTS'
          devices={additionalComponents}
          onChange={handleChange}
          filters={filters}
        />
      )}
    </>
  );
};
