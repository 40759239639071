import { Card, CardContent, styled, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Stack, Tabs, Theme } from '@mui/material';
import { colors } from '@vaillant-professional-ui/component-libs-common/src/theme/colors/green';

export const MobileDashboardContainer = styled(Stack)`
    width: 100%;
    margin-top: ${(props: { activeTab: number | null }) => (props.activeTab !== null ? '-18px' : 0)}
    padding-bottom: ${({ theme }) => theme.spacing(3)};;
}
`;

export const TabsContainer = styled('div')`
  display: grid;
  gap: ${({ theme }) => theme.spacing(3)};
  row-gap: ${({ theme }) => theme.spacing(4)};
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  justify-items: stretch;
`;

export const TabCard = styled(Card)`
  width: 100%;
`;

export const NewDeviceTabCard = styled(Card)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const NewDeviceTabCardContent = styled(CardContent)`
  color: ${({ theme }) => theme.palette.common.white};
`;
export const MobileDashboardTabsStickyWrapper = styled('div')`
  position: fixed;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin: -44px -16px 0 -16px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: -44px -24px 0 -24px;
  }
`;

export const MobileDashboardTabsWrapper = styled(Tabs)`
    position: relative;
    background-color: white;


    &::before {
        content: "";
        position: absolute;
        height: 3px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${({ theme }) => theme.palette.divider};
        border-radius: 4px 4px 0 0;
        width: 100vw;
    }

,
`;

export const MobileDashboardListContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin: 26px -16px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 26px -24px;
  }
`;

export const MobileDashboardListItemWrapper = styled('div')<{
  theme: Theme;
  isDisabled: boolean;
  hasUnreadNotifications: boolean;
}>`
    padding: ${({ theme }: { theme: Theme }) => theme.spacing(4)};
    background-color: ${({ theme, hasUnreadNotifications }: { theme: Theme; hasUnreadNotifications: boolean }) =>
      hasUnreadNotifications ? colors.primaryLighter : theme.palette.background.paper};
    display: flex;
    align-items: center;
    cursor: ${({ isDisabled, hasUnreadNotifications }: { isDisabled: boolean; hasUnreadNotifications: boolean }) =>
      isDisabled && hasUnreadNotifications ? 'default' : 'pointer'};

    &:hover {
        background-color: ${(props: { theme: Theme; isDisabled: boolean }) =>
          props.isDisabled ? props.theme.palette.background.paper : 'transparent'}
`;

export const MobileDashboardListItemContentWrapper = styled('div')`
  flex-grow: 1;
`;

export const MobileDashboardListItemCustomerName = styled(Typography)`
  overflow-wrap: break-word;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const MobileDashboardStatusOccurrenceWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const MobileDashboardListItemDisabledButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(3)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(1)};
`;

export const MobileDashboardListItemCustomerDevice = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(2)} 0`};
`;

export const Spacer = styled('div')`
  border-bottom: 2px solid ${({ theme }) => theme.palette.divider};
`;
