import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { Button, StatusCard } from '@vaillant-professional-ui/component-libs-web';
import { hooks, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { ErrorCode } from './PermissionDeniedPage.types';
import { errorCodeTranslationKeys } from './PermissionDeniedPage.constants';

export const PermissionDeniedPage: React.FC = () => {
  const t = hooks.useTranslation();
  const { logout } = useAuthContext();
  const { errorCode } = useParams<{ errorCode: string }>();
  const text = t(errorCodeTranslationKeys[errorCode as unknown as ErrorCode]);

  return (
    <Box pt={16} width='100%'>
      <Container maxWidth='md'>
        <StatusCard type='error' title={t('SG_PERMISSION_DENIED_TITLE')} text={text} variant='big'>
          <Box pt={8}>
            <Button color='secondary' variant='contained' onClick={() => void logout()} i18nKey='SG_LOGIN_LOGINBTN' />
          </Box>
        </StatusCard>
      </Container>
    </Box>
  );
};
