import { TableRow } from './Dashboard.types';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import {
  MobileDashboardListItemContentWrapper,
  MobileDashboardListItemCustomerDevice,
  MobileDashboardListItemCustomerName,
  MobileDashboardListItemDisabledButtonsWrapper,
  MobileDashboardListItemWrapper,
} from './MobileDashboard.styles';
import { Icon08201VgRight01, Typography } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { MobileDashboardStatusOccurrence } from './MobileDashboardStatusOccurrence';
import { Device, ICustomer, ICustomerDetail } from '@vaillant-professional-ui/pui-frontend-common/src/typings/models';
import { ItemAction } from '../../features/dashboard/presenter';

interface MovieDashboardListItemWrapperProps {
  item: TableRow;

  formatAddress: (customer: ICustomer | ICustomerDetail) => { firstLine: string; secondLine: string };
  formatCustomerName: (customer?: ICustomer | ICustomerDetail | undefined, reversed?: boolean) => string;
  getBoilerNomenclature: (boiler: Device) => string;
  getBoilerMarketingName: (boiler: Device) => string;
  itemActions: ItemAction[];
}

export const MobileDashboardListItem = ({
  item,
  formatAddress,
  formatCustomerName,
  getBoilerNomenclature,
  getBoilerMarketingName,
  itemActions,
}: MovieDashboardListItemWrapperProps) => {
  const {
    id,
    _meta: { customer, customerStatusItems, hasUnreadNotifications },
    _disabled: isDisabled,
  } = item;

  const theme = useTheme();
  const navigate = useNavigate();

  const address = formatAddress(customer);
  const formattedCustomerName = formatCustomerName(customer, true);

  return (
    <MobileDashboardListItemWrapper
      isDisabled={!!isDisabled}
      theme={theme}
      onClick={() => !isDisabled && navigate(`/customers/${id}`)}
      hasUnreadNotifications={hasUnreadNotifications}
    >
      <MobileDashboardListItemContentWrapper>
        <MobileDashboardListItemCustomerName disabled={isDisabled} bold={hasUnreadNotifications} variant={'h4'}>
          {formattedCustomerName || id}
        </MobileDashboardListItemCustomerName>
        {customerStatusItems.map((status, index) => (
          <MobileDashboardStatusOccurrence key={index} item={status} />
        ))}
        {customer.system.boiler ? (
          <MobileDashboardListItemCustomerDevice>
            <Typography variant={'h4'} disabled={isDisabled}>
              {getBoilerNomenclature(customer.system.boiler)}
            </Typography>
            <Typography disabled={isDisabled} variant='caption' color={'text.secondary'}>
              {getBoilerMarketingName(customer.system.boiler)}
            </Typography>
          </MobileDashboardListItemCustomerDevice>
        ) : null}
        <>
          <Typography variant={'h4'} disabled={isDisabled}>
            {address.firstLine}
          </Typography>
          <Typography variant={'h4'} disabled={isDisabled}>
            {address.secondLine}
          </Typography>
        </>
        {isDisabled && (
          <MobileDashboardListItemDisabledButtonsWrapper>
            {itemActions.map((action) => {
              if (action.disabled?.(item)) {
                return null;
              }
              return (
                <Typography
                  variant={'h4'}
                  key={action.label}
                  color={'primary.main'}
                  disabled={action.disabled?.(item)}
                  onClick={() => {
                    action.action(item);
                  }}
                >
                  {action.label}
                </Typography>
              );
            })}
          </MobileDashboardListItemDisabledButtonsWrapper>
        )}
      </MobileDashboardListItemContentWrapper>
      {!isDisabled && <Icon08201VgRight01 color='primary.main' width={32} height={32} />}
    </MobileDashboardListItemWrapper>
  );
};
