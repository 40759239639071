import range from 'lodash/range';
import { util } from '@vaillant-professional-ui/component-libs-common';
import cloneDeep from 'lodash/cloneDeep';

// TODO: move code to util from '@vaillant-professional-ui/component-libs-common';

export const generateTemperatureSelectOptions = (minTemp: number, maxTemp: number, stepSize: number) => {
  return range(minTemp, maxTemp + stepSize, stepSize).map((entry) => {
    const temperature = Math.min(entry, maxTemp);
    return { value: temperature, label: `${temperature} °C` };
  });
};

export const sortTimeWindows: <T extends util.TimeWindow>(timeWindows?: T[]) => T[] = (timeWindows = []) =>
  cloneDeep(timeWindows).sort((a, b) => a.start - b.start);

export const createDefaultTimeWindow = (): util.TimeWindow => ({ start: 0 * 60, end: 24 * 60 });
export const createDefaultTimeWindowWithTargetTemperature = (): util.TimeWindowWithTargetTemperature => ({
  start: 0 * 60,
  end: 24 * 60,
  temperatureTarget: 21,
});

export const createDefaultTimeWindowWithProfileFactory =
  (profile: util.ErelaxProfile) => (): util.TimeWindowWithProfile => ({
    start: 0 * 60,
    end: 24 * 60,
    profile,
  });

export const calcFreeSlots = <T extends util.TimeWindow>(existingTimeWindows: T[]): T[] => {
  const sortedTimeWindows = sortTimeWindows(existingTimeWindows);

  const collect = (accumulator: T[], currentTimeWindow: T) => {
    const previousTimeWindow = accumulator[accumulator.length - 1];
    previousTimeWindow.end = currentTimeWindow.start;
    const newTimeWindow: T = { ...previousTimeWindow, start: currentTimeWindow.end, end: 24 * 60 };
    return accumulator.concat([newTimeWindow]);
  };

  const fullDayTimeWindow = {
    ...existingTimeWindows[0], // to have the payload (e.g. profile or temperatureTarget)
    start: 0,
    end: 24 * 60,
  };
  return sortedTimeWindows.reduce(collect, [fullDayTimeWindow]).filter((v) => v.start < v.end);
};

export const createTimeWindow = <T extends util.TimeWindow>(
  existingTimeWindows: T[],
  createDefaultTimeWindowFnc = createDefaultTimeWindow,
): util.TimeWindow => {
  const sortedTimeWindows = sortTimeWindows(existingTimeWindows);
  if (!sortedTimeWindows.length) {
    return createDefaultTimeWindowFnc();
  }
  const freeSlots = calcFreeSlots(sortedTimeWindows).reverse();
  return freeSlots[0] ?? createDefaultTimeWindowFnc();
};

export const addNewTimeWindowWith =
  <T extends util.TimeWindow>(onCreateDefaultTimeWindow: () => T) =>
  (existingTimeWindows: T[]): T[] => {
    const newTimeWindow = createTimeWindow(existingTimeWindows, onCreateDefaultTimeWindow);
    return [...existingTimeWindows, { ...existingTimeWindows[0], ...newTimeWindow }];
  };

const ensureAllTimeWindowsAreConsecutive = <T extends util.TimeWindow>(newTimeWindows: T[]) => {
  const result = newTimeWindows.map((tw) => ({ ...tw }));
  // First time window starts at 0h
  result[0].start = 0;
  // Last time window ends at 24h
  result[result.length - 1].end = 24 * 60;

  for (let i = 1; i < result.length; ++i) {
    result[i - 1].end = result[i].start;
  }
  return result;
};
export const deleteTimeWindow = <T extends util.TimeWindow>(existingTimeWindows: T[], index: number): T[] => {
  if (index < 0 || index > existingTimeWindows.length - 1) {
    console.error('out of bounds error');
    return existingTimeWindows;
  }
  const newTimeWindows = existingTimeWindows
    .slice(0, index)
    .concat(existingTimeWindows.slice(index + 1, existingTimeWindows.length));
  if (util.areTimeWindowsWithProfile(existingTimeWindows)) {
    return ensureAllTimeWindowsAreConsecutive(newTimeWindows);
  }
  return newTimeWindows;
};

/**
 * Change any property of a time window instance.
 *
 * Performs sanity checks depending on the type of timeWindow, especially for eRelax time windows
 *
 * @param values - full list af time windows
 * @param index - index of the item that will be changed
 * @param newValue - payload that overrides the attributes of the time window on position index
 */
export const manipulateEntry = <T extends util.TimeWindow>(values: T[], index: number, newValue: Partial<T>): T[] => {
  const newValues = values.map((entry, idx) => {
    if (index === idx) {
      return { ...entry, ...newValue };
    }
    return { ...entry };
  });

  if (util.areTimeWindowsWithProfile(newValues)) {
    const anyProfile = { ...newValues[0].profile };
    const origId = anyProfile.id;
    anyProfile.id = -1;
    const defaultTimeWindow = createDefaultTimeWindowWithProfileFactory(anyProfile)();
    const mergeResult = util.mergeTimeWindows([defaultTimeWindow], newValues);
    const result = mergeResult.map((tw) => {
      const profile = tw.profile;
      return {
        ...tw,
        profile: {
          ...profile,
          id: profile.id === -1 ? origId : profile.id,
        },
      } as util.TimeWindowWithProfile;
    });

    return result as unknown as T[];
  }

  return newValues;
};

export const changeStart = <T extends util.TimeWindow>(value: T, start: number): T => {
  return { ...value, start, end: Math.max(start, value.end) };
};

export const changeEnd = <T extends util.TimeWindow>(value: T, end: number): T => {
  return { ...value, end, start: Math.min(end, value.start) };
};
