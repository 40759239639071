import * as React from 'react';
import { Grid, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Box } from '@mui/material';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { IAddCustomerProps } from './AddCustomer.types';
import Button from '../Button';

interface AgreementRequiredProps extends Pick<IAddCustomerProps, 'closeModal' | 'loading' | 'addCustomer'> {
  serialNo: string;
  email: string;
}

const AgreementRequired: React.FC<React.PropsWithChildren<AgreementRequiredProps>> = ({
  closeModal,
  loading,
  addCustomer,
  serialNo,
  email,
}) => {
  const t = hooks.useTranslation();
  return (
    <Grid container direction='row'>
      <Box mb={4}>
        <Typography i18nKey='SG_AGREEMENT_OWN_DEVICE' />
      </Box>
      <Grid container item justifyContent='center' wrap='nowrap'>
        <Box mr={2}>
          <Button
            color='secondary'
            variant='outlined'
            onClick={closeModal}
            testId='cancelAddCustomer'
            disabled={loading}
          >
            {t('SG_CANCEL')}
          </Button>
        </Box>
        <Box mr={2}>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => addCustomer(serialNo, email, true)}
            disabled={loading}
            testId='addDeviceInstallerAgreementGiven'
          >
            {t('SG_EDIT_SUBMIT_BUTTON')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AgreementRequired;
