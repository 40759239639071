import { AxiosRequestConfig } from 'axios';
import { DeviceType } from './maintenance.types';

const acceptHeaderMapping: Map<DeviceType, string> = new Map([
  ['CONTROL/TLI', 'application/x.tli+json;version=1'],
  ['CONTROL/VRC700', 'application/x.vrc700+json;version=1'],
  ['HEAT_GENERATOR/HEAT_PUMP', 'application/x.heat_pump+json;version=1'],
  ['HEAT_GENERATOR/BOILER', 'application/x.boiler+json;version=1'],
]);

export const maintenanceApi = {
  getSystem: (systemId: string): AxiosRequestConfig => ({
    url: `pui/maintenance/v2/systems/${systemId}`,
  }),

  getDeviceDetails: (systemId: string, deviceId: string, deviceType: DeviceType): AxiosRequestConfig => {
    return {
      url: `pui/maintenance/v2/systems/${systemId}/devices/${deviceId}`,
      headers: {
        Accept: acceptHeaderMapping.get(deviceType) || 'application/x.device+json;version=1',
      },
    };
  },

  getDeviceSettings: (systemId: string, deviceId: string): AxiosRequestConfig => ({
    url: `pui/maintenance/v2/systems/${systemId}/devices/${deviceId}/settings`,
  }),

  getChartList: (systemId: string | undefined): AxiosRequestConfig => {
    return {
      url: `pui/maintenance/v2/systems/${systemId}/chart-tabs`,
    };
  },

  getChartDetails: (
    url: URL,
    startDate: Date,
    endDate: Date,
    selectedTimeSeries?: Array<string>,
  ): AxiosRequestConfig => {
    const searchParams = url.searchParams;
    searchParams.set('start', startDate.toISOString());
    searchParams.set('end', endDate.toISOString());
    searchParams.delete('time_series');
    const timeSeriesQuery = selectedTimeSeries ? `&time_series=${(selectedTimeSeries ?? []).join(',')}` : '';
    url.search = `${searchParams.toString()}` + timeSeriesQuery;

    return {
      url: url.href,
      headers: {
        Accept: 'application/x.charts+json;version=1',
      },
    };
  },
};
