import React from 'react';
import SvgIcons from '../../SVGIcons';
import { IIconProps } from './Icon.types';

const Icon = (props: IIconProps) => {
  const { width, height = width, name, testID, ...rest } = props;
  const svgData = SvgIcons[name];
  return (
    <svg viewBox={svgData.viewBox} width={width} height={height} data-testid={testID} {...rest}>
      {svgData.svg}
    </svg>
  );
};

export default Icon;
