import { TranslationFnc } from '../../../typings/models';

import { Vrc700TimeWindow, Vrc700ScheduleDay, Vrc700PresentationData, Schedule, Vrc700Schedule } from './index';
import {
  Vrc700TimeWindowSchedule,
  Vrc700TimeWindow as Vrc700HeatingTimeWindowResponse,
  DeviceDetailsResponseVrc700,
  Vrc700Zone,
  Vrc700Dhw,
} from '../../../api/apis/maintenance/v2/maintenance.types';
import { models } from '../../../../index';
import { Vrc700Schedules } from './types';

const vrc700ZoneNameV2 = (zone: Vrc700Zone, t: models.TranslationFnc): string => {
  return zone.zone_name?.value || t('SG_ZONE_NO', { 0: zone.zone_index + 1 });
};

const allWeekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

const vrc700HeatingTimeWindowMapper = (heatingTimeWindows: Vrc700HeatingTimeWindowResponse[]): Vrc700TimeWindow[] => {
  return heatingTimeWindows.map((timeWindow) => {
    return {
      start: timeWindow['start'].value!,
      end: timeWindow['end'].value!,
    };
  });
};

type Weekday = keyof Vrc700TimeWindowSchedule;

export const getPresentationData = (
  vrc700Data: DeviceDetailsResponseVrc700,
  weekdays: string[],
  t: TranslationFnc,
  index: string,
  scheduleType: Schedule,
): Vrc700PresentationData | null => {
  if (!vrc700Data.zones) {
    return null;
  }

  const createTimeslotsForHeatingDay = (
    weekday: Weekday,
    schedule?: Vrc700TimeWindowSchedule,
  ): Vrc700ScheduleDay<Vrc700TimeWindow> => {
    const schedulesResponse = schedule?.[weekday];

    return {
      key: weekday,
      label: weekdays[allWeekDays.indexOf(weekday)],
      values: schedulesResponse ? vrc700HeatingTimeWindowMapper(schedulesResponse) : [],
    };
  };

  const createTimeSlotsForDhwDay = (
    weekday: Weekday,
    zone: Vrc700Dhw['schedule'] | Vrc700Dhw['circulation_pump_schedule'],
  ): Vrc700ScheduleDay<Vrc700TimeWindow> => {
    const schedulesResponse = zone?.[weekday];
    return {
      key: weekday,
      label: weekdays[allWeekDays.indexOf(weekday)],
      values: schedulesResponse ? vrc700HeatingTimeWindowMapper(schedulesResponse) : [],
    };
  };

  const heatingSchedules = vrc700Data.zones
    .filter((zone) => !!zone?.heating?.schedule)
    .map((zone) => {
      const schedule = allWeekDays.map((weekDay) => createTimeslotsForHeatingDay(weekDay, zone.heating?.schedule));

      return {
        index: zone.zone_index,
        name: vrc700ZoneNameV2(zone, t),
        config: zone.heating?.schedule?.config,
        schedule,
      };
    });

  const coolingSchedules = vrc700Data.zones
    .filter((zone) => !!zone?.cooling?.schedule)
    .map((zone) => {
      const schedule = allWeekDays.map((weekDay) => createTimeslotsForHeatingDay(weekDay, zone.cooling?.schedule));

      return {
        index: zone.zone_index,
        name: vrc700ZoneNameV2(zone, t),
        config: zone.cooling?.schedule?.config,
        schedule,
      };
    });

  const dhwSchedules =
    vrc700Data.domestic_hot_water?.map((zone) => {
      const schedule = allWeekDays.map((weekDay) => createTimeSlotsForDhwDay(weekDay, zone.schedule));
      return {
        index: zone.index,
        name: `domestic_hot_water-${zone.index}`,
        config: zone.schedule?.config,
        schedule,
      };
    }) ?? [];

  const dhwCirculationPumpSchedules =
    vrc700Data.domestic_hot_water?.map((zone) => {
      const schedule = allWeekDays.map((weekDay) => createTimeSlotsForDhwDay(weekDay, zone.circulation_pump_schedule));
      return {
        index: zone.index,
        name: `domestic_hot_water-circulation-pump-${zone.index}`,
        config: zone.circulation_pump_schedule?.config,
        schedule,
      };
    }) ?? [];

  const ventilationSchedules =
    vrc700Data.ventilation?.map((zone) => {
      const schedule = allWeekDays.map((weekDay) => createTimeSlotsForDhwDay(weekDay, zone.schedule));
      return {
        index: zone.index,
        name: `ventilation-${zone.index}`,
        config: zone.schedule?.config,
        schedule,
      };
    }) ?? [];

  const presentationData = {
    heatingSchedules,
    coolingSchedules,
    dhwSchedules,
    dhwCirculationPumpSchedules,
    ventilationSchedules,
  };

  const zoneData = presentationData ? getZoneData(scheduleType, index, presentationData) : null;
  const schedule = zoneData?.schedule;
  const config = zoneData?.config;
  const backlinkPath = `../../`;
  const testId = `${scheduleType}-scheduler`;

  return { schedule, backlinkPath, testId, maxSlotsPerDay: config?.max_slots_per_day?.value ?? 3 };
};

const getZoneData = (
  scheduleType: Schedule,
  index: string,
  presentationalData: Vrc700Schedules,
): Vrc700Schedule | null => {
  switch (scheduleType) {
    case 'domestic_hot_water':
      return presentationalData.dhwSchedules.find((zone) => zone.index === parseInt(index)) ?? null;
    case 'circulation_pump':
      return presentationalData.dhwCirculationPumpSchedules.find((zone) => zone.index === parseInt(index)) ?? null;
    case 'heating':
      return presentationalData.heatingSchedules.find((zone) => zone.index === parseInt(index)) ?? null;
    case 'cooling':
      return presentationalData.coolingSchedules.find((zone) => zone.index === parseInt(index)) ?? null;
    case 'ventilation':
      return presentationalData.ventilationSchedules.find((zone) => zone.index === parseInt(index)) ?? null;
    default:
      return null;
  }
};
