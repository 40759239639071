import * as React from 'react';
import { Box } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { BigTabsLabel } from '../../molecules/BigTabsLabel';
import { ContentContainer, TabsMajor, TabStyled } from './BigTabs.styles';
import { BigTabsProps } from './BigTabs.types';

export const BigTabs: React.FC<BigTabsProps> = ({
  children,
  testIdKey,
  tabs,
  initialValue,
  onChange,
  value,
  ...rest
}) => {
  const isControlled = value !== undefined;
  const [internalValue, setInternalValue] = React.useState<number>(initialValue ?? 0);

  if (value !== undefined && initialValue !== undefined) {
    throw new Error('BigTabs: Cannot set both value and initialValue');
  }

  React.useEffect(() => {
    if (isControlled) {
      setInternalValue(value as number);
    }
  }, [isControlled, value]);

  const testId = useInstanceId('bigtabs', testIdKey);
  const childrenIsFn = typeof children === 'function';
  const activePanel = childrenIsFn ? null : children[internalValue];

  const change = (event: React.ChangeEvent<{}>, v: number) => {
    if (!isControlled) {
      setInternalValue(v);
    }
    onChange(event, v);
  };

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <TabsMajor
        {...testId('root').testIdAttributes}
        onChange={change}
        scrollButtons={false}
        variant='scrollable'
        value={internalValue}
        {...rest}
      >
        {tabs.map((tab, index) => {
          return (
            <TabStyled
              {...testId(`tab-${index}`).testIdAttributes}
              key={index}
              value={index}
              disableRipple={true}
              disableFocusRipple={true}
              disabled={tab.disabled}
              label={
                <BigTabsLabel
                  smallText={tab.smallText}
                  bigText={tab.bigText}
                  isActive={internalValue === index}
                  disabled={tab.disabled}
                />
              }
            />
          );
        })}
      </TabsMajor>
      <ContentContainer>
        {/* TS function derivation does not work here
        // @ts-ignore */}
        {childrenIsFn ? children(internalValue) : activePanel}
      </ContentContainer>
    </Box>
  );
};
