/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon01600VgTemperature01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon01600vgtemperature01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M286.5 482A76.53 76.53 0 0 1 244 341.83V105a42.52 42.52 0 1 1 85 0v236.83A76.53 76.53 0 0 1 286.5 482zm53.79-79.37a53.68 53.68 0 0 0-24.8-42.55l-9.14-5.85V105a19.85 19.85 0 0 0-39.69 0v8.5h22.68v22.68h-22.68v73.7h22.68v22.68h-22.68v73.7h22.68v22.68h-22.68v25.3l-9.14 5.85a53.65 53.65 0 0 0-24.8 42.55z'
      />
    </svg>
  );
};
