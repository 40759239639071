import { useLocalizedDifferenceInDays } from '../../../libs/hooks';
import { getF22Prediction } from '../../../selectors/customer';
import { ICustomer, ICustomerDetail, TranslationFnc } from '../../../typings/models';

export const selectF22IconAndTitle = <T>(
  customer: ICustomer | ICustomerDetail,
  PressureLevels: Array<T>,
  t: TranslationFnc,
  formatDifference: ReturnType<typeof useLocalizedDifferenceInDays>,
) => {
  const f22Prediction = getF22Prediction(customer);
  const f22PredictionInDays = formatDifference(f22Prediction?.predictedFrom);

  const f22Title =
    f22PredictionInDays?.differenceInDays === 0
      ? t('SG_LESS_THAN_ONE_DAY')
      : f22PredictionInDays?.differenceInDaysLocalized;

  const minLevelByDaysGreater = 10;
  const days = Math.min(minLevelByDaysGreater, Math.max(0, f22PredictionInDays?.differenceInDays ?? 0));
  const stepSize = minLevelByDaysGreater / PressureLevels.length;
  const idx = Math.trunc(days / stepSize);
  const icon = PressureLevels[idx];
  return { icon, f22Title };
};
