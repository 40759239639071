import { AxiosRequestConfig } from 'axios';
import { SchedulePayload } from './schedules.types';

export const schedulesApi = {
  getSchedule: (gatewaySerialNumber: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/api-gateway/v1/systems/${gatewaySerialNumber}/schedules-v2`,
  }),

  updateSchedule: (gatewaySerialNumber: string, params: SchedulePayload): AxiosRequestConfig<SchedulePayload> => ({
    method: 'put',
    url: `/pui/api-gateway/v1/systems/${gatewaySerialNumber}/schedules-v2`,
    data: params,
  }),
};
