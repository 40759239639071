/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon11200VgPreheating01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon11200vgpreheating01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M283.74 393a62.36 62.36 0 0 0 34.65-114.21v-193a34.65 34.65 0 0 0-69.3 0v193A62.36 62.36 0 0 0 283.74 393zm-23.62-99.34 7.45-4.77v-20.66h18.48v-18.48h-18.48V189.7h18.48v-18.48h-18.48v-60h18.48V92.69h-18.48v-6.93a16.17 16.17 0 0 1 32.34 0v203.09l7.45 4.77a43.73 43.73 0 0 1 20.2 34.66h-87.64a43.73 43.73 0 0 1 20.2-34.66z'
        className='cls-1'
      />
      <circle
        cx={146.28}
        cy={408.28}
        r={11.34}
        fill='currentColor'
        className='cls-1'
        transform='rotate(-13.35 146.248 408.239)'
      />
      <circle
        cx={171.39}
        cy={441.26}
        r={11.34}
        fill='currentColor'
        className='cls-1'
        transform='rotate(-13.35 171.365 441.192)'
      />
      <circle
        cx={130.6}
        cy={370.12}
        r={11.34}
        fill='currentColor'
        className='cls-1'
        transform='rotate(-43.44 130.61 370.138)'
      />
      <path
        fill='currentColor'
        d='m487.35 363.75-36.5-42.64a11.34 11.34 0 0 0-16-1.24l-42.63 36.51A11.34 11.34 0 1 0 407 373.6l21.84-18.7c-12.31 68.92-72.69 121.41-145.1 121.41A147.36 147.36 0 0 1 210 456.6a11.34 11.34 0 1 0-11.35 19.63 170 170 0 0 0 85 22.76c84.3 0 154.46-61.66 167.78-142.25l18.63 21.76a11.34 11.34 0 0 0 17.22-14.75z'
        className='cls-1'
      />
    </svg>
  );
};
