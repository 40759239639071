import React from 'react';

import { Box } from '@mui/material';
import { AxiosError } from 'axios';

import { LoadingErrorWrapper } from '../../../components/LoadingErrorWrapper';
import { DataPointsCard, DataPointsCardProps } from '../../../components/organisms/DataPointsCard';
import { ModalDrawer } from '@vaillant-professional-ui/component-libs-web';

export interface DeviceSettingsProps {
  dataPointsCardsProps: DataPointsCardProps[] | null;
  isLoading: boolean;
  error: AxiosError<unknown> | null;
  refetch: () => void;
  drawerContent: React.ReactElement | null;
  handleCloseDrawer: () => void;
}

export const DeviceSettings: React.FC<DeviceSettingsProps> = ({
  dataPointsCardsProps,
  drawerContent,
  handleCloseDrawer,
  error,
  isLoading,
  refetch,
}) => {
  return (
    <LoadingErrorWrapper
      error={error}
      isPending={isLoading}
      showOnScreen={false}
      showLoadingSpinner={true}
      onRetry={() => void refetch}
    >
      <ModalDrawer open={!!drawerContent} onClose={handleCloseDrawer} testIdKey='systemControlDrawer'>
        {drawerContent}
      </ModalDrawer>
      {dataPointsCardsProps ? (
        <>
          <Box width={'100%'}>
            {dataPointsCardsProps.map((dataPointsCardProps, index) => (
              <Box key={dataPointsCardProps.title} mt={index ? 4 : 0}>
                <DataPointsCard {...dataPointsCardProps} />
              </Box>
            ))}
          </Box>
        </>
      ) : null}
    </LoadingErrorWrapper>
  );
};
