import { GeneralAction } from '../generalActions';
import { TutorialTypes } from './TutorialActionTypes';

export interface TutorialState {
  screens: Record<string, string>;
}

const initialState = { screens: {} };

export const tutorialReducer = (
  state: TutorialState = initialState,
  action: TutorialTypes | GeneralAction,
): TutorialState => {
  switch (action.type) {
    case 'MARK_TUTORIAL_FINISHED': {
      return {
        ...state,
        screens: { ...state.screens, [action.payload]: action.payload },
      };
    }
    case 'RESET_TUTORIAL': {
      return {
        ...state,
        screens: {},
      };
    }

    default:
      return state;
  }
};
