import React from 'react';

import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { StatusChip } from './StatusChip';
import { DateCell, StatusCell, StyledRow, StyledTable } from './StatusOccurrenceList.styles';
import type { StatusOccurrenceListProps } from './StatusOccurrenceList.types';
import { Box } from '@mui/material';
import { Typography, useTheme } from '@vaillant-professional-ui/component-libs-web';

export const StatusOccurrenceList: React.FunctionComponent<StatusOccurrenceListProps> = ({ testIdKey, items }) => {
  const testId = useInstanceId('statusoccurrencelist', testIdKey);
  const theme = useTheme();

  return (
    <StyledTable {...testId('root').testIdAttributes}>
      <tbody>
        {items.map(({ title, icon: Icon, color, variant = 'filled', subtitle, tooltip, styles, occurrence }, index) => {
          return (
            <StyledRow key={index} {...testId(`row-${index}`).testIdAttributes}>
              <StatusCell>
                <Box display='flex' flexDirection='column' gap={2} justifyContent='flex-start'>
                  <StatusChip
                    color={color}
                    variant={variant}
                    label={
                      Icon ? (
                        <Box display='flex' alignItems='center' gap={1}>
                          <Icon color='inherit' />
                          {title}
                        </Box>
                      ) : (
                        title
                      )
                    }
                    style={styles?.chip}
                    tooltip={tooltip}
                  />
                  <Typography variant='body' style={styles?.typography}>
                    {subtitle}
                  </Typography>
                </Box>
              </StatusCell>
              <DateCell>
                {!!occurrence && (
                  <div style={{ marginLeft: theme.spacing(4) }}>
                    <Typography>{occurrence.date}</Typography>
                    <div style={{ marginTop: theme.spacing(1) }}>
                      <Typography variant='caption' color='text.secondary'>
                        {occurrence.time}
                      </Typography>
                    </div>
                  </div>
                )}
              </DateCell>
            </StyledRow>
          );
        })}
      </tbody>
    </StyledTable>
  );
};
