import * as React from 'react';
import { Drawer as DrawerBase } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { AttachedComponents, ModalDrawerProps } from './ModalDrawer.types';
import { ModalDrawerContext } from './ModalDrawer.context';
import { Content } from './ModalDrawer.styled';
import { Title } from './Title';
import { Footer } from './Footer';

export const ModalDrawer: React.FC<ModalDrawerProps> & AttachedComponents = ({
  testIdKey,
  onClose,
  fitWidth,
  ...rest
}) => {
  const testId = useInstanceId('modal-drawer', testIdKey);
  return (
    <ModalDrawerContext.Provider value={{ onClose, testId }}>
      <DrawerBase
        anchor='right'
        onClose={onClose}
        PaperProps={{
          sx: {
            minWidth: ['100%', 580],
            width: ['100%', '70%', fitWidth ? 'auto' : 620],
            maxHeight: '100vh',
            overflowY: 'hidden',
          },
        }}
        {...testId('root').testIdAttributes}
        {...rest}
      />
    </ModalDrawerContext.Provider>
  );
};

ModalDrawer.Title = Title;
ModalDrawer.Footer = Footer;
ModalDrawer.Content = Content;
