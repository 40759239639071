import formatISO from 'date-fns/formatISO';
import mapValues from 'lodash/mapValues';
import { EmfBucketsRequestQueryParams, EmfDeviceTypeResponse, EmfDevicesResponse } from './emf.types';

const formatDateWithOffset = (date: string) => {
  const d = new Date(date);
  return formatISO(d, { representation: 'complete', format: 'extended' });
};

export const sanitizeParams = (params: EmfBucketsRequestQueryParams) => {
  // all arrays as comma seperated values
  const sanitized = mapValues(params, (value) => (Array.isArray(value) ? value.join(',') : value));
  // convert timestamps to contain offset instead of timezone
  if ('startDate' in sanitized) {
    sanitized.startDate = formatDateWithOffset(sanitized.startDate);
  }
  if ('endDate' in sanitized) {
    sanitized.endDate = formatDateWithOffset(sanitized.endDate);
  }
  return sanitized;
};

export const filterSupportedDevices = (response: EmfDevicesResponse): EmfDevicesResponse => {
  const supportedDevices: Record<EmfDeviceTypeResponse, true> = {
    BOILER: true,
    HEATPUMP: true,
    SOLAR_STATION: true,
    ELECTRIC_AUXILIARY_HEATER: true,
    VENTILATION: true,
    UNKNOWN: true,
  };
  return response.filter((device) => supportedDevices[device.device_type]);
};
