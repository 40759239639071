import React from 'react';
import { useConsumptionDetailsContext } from '../../pages/ConsumptionPage';
import { EmfChart } from '../EmfChart';

export const ElectricalConsumptionDetails: React.FC = () => {
  const { filters, data, isLoading } = useConsumptionDetailsContext();
  return (
    <EmfChart
      buckets={data.categories.electricalConsumed.buckets}
      dateRangePeriod={filters.dateRangePeriod}
      emfCategory='electricalConsumed'
      maxDomainY={data.categories.electricalConsumed.highestValueByResolution}
      totalEnergyValue={data.categories.electricalConsumed.total}
      isLoading={isLoading}
    />
  );
};
