import React from 'react';

import { Tooltip } from '@mui/material';
import { StyledStatusChip } from './StatusOccurrenceList.styles';
import { StatusChipProps } from './StatusOccurrenceList.types';

export const StatusChip: React.FC<StatusChipProps> = ({ label, color, variant, tooltip, style }) => {
  if (!label) {
    return null;
  }

  const Component = (
    <StyledStatusChip
      color={color}
      variant={variant}
      label={label}
      size='small'
      style={{
        cursor: tooltip ? 'hand' : 'inherit',
        fontWeight: '600',
        ...style,
      }}
    />
  );

  return tooltip ? (
    <Tooltip title={tooltip} arrow leaveDelay={30}>
      <div>{Component}</div>
    </Tooltip>
  ) : (
    Component
  );
};
