import React from 'react';

import { VictoryLine, VictoryScatter } from 'victory';

import { features } from '@vaillant-professional-ui/pui-frontend-common';

export const TimeSeriesGraphs = ({
  line,
  ...props
}: {
  line: features.historyCharts.historyChartsCard.NumberTimeSeries;
}) => (
  <>
    <React.Fragment key={line.name}>
      <VictoryLine
        {...props}
        data={line.filteredByZoomDomain ?? line.coordinates}
        groupComponent={<g />}
        style={{
          data: { stroke: line.color, strokeWidth: 1.5 },
          parent: { border: '1px solid #ccc' },
        }}
        x={0}
        y={1}
      />
      <VictoryScatter
        {...props}
        data={line.filteredByZoomDomain ?? line.coordinates}
        size={1.5}
        style={{
          data: { fill: line.color },
        }}
        x={0}
        y={1}
      />
    </React.Fragment>
  </>
);
