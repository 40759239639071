import {
  firebaseConfigDev,
  firebaseConfigSaunierDuval,
  firebaseConfigVaillant,
  FirebaseOptions,
} from '../services/firebase';
import { ThemeVariation } from '../theme';
import { BrandKey, Country } from '../typings/models';

export type PlatformName = 'myVAILLANT Pro Service' | 'MiGo Xpert';

export type BrandGroup = 'vaillant' | 'sdbg';

export interface Brand {
  key: BrandKey;
  name: string;
  group: BrandGroup;
}

export interface LoginConfig {
  appStoreLink?: string;
  playStoreLink?: string;
  isLogoRightAligned: boolean;
}

export interface Distribution {
  brand: Brand;
  platformName: PlatformName;
  theme: ThemeVariation;
  firebaseConfig: Record<'dev' | 'qa' | 'prod', FirebaseOptions>;
  login: LoginConfig;
  sparePartWidgetUserAgent: string;
  appStoreAvailableCountries: Country[];
  countries: Partial<{
    [key in Country]: {
      gateway: string;
      sparePartWidgetApiKey: string;
    };
  }>;
}

export const saunierduval: Distribution = {
  brand: {
    name: 'Saunier Duval',
    key: 'saunierduval',
    group: 'sdbg',
  },
  platformName: 'MiGo Xpert',
  theme: 'puiRed',
  firebaseConfig: { prod: firebaseConfigSaunierDuval, dev: firebaseConfigDev, qa: firebaseConfigDev },
  login: {
    appStoreLink: 'https://apps.apple.com/app/id1441660273',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.vaillantgroup.profui.saunierduval',
    isLogoRightAligned: true,
  },
  sparePartWidgetUserAgent: 'MiGo Xpert',
  appStoreAvailableCountries: ['FR'],
  countries: {
    ES: {
      gateway: 'MiGo',
      sparePartWidgetApiKey: 'cf9155d4-fc41-40e6-8cb5-2043d13c31e0',
    },
    FR: {
      gateway: 'MiGo',
      sparePartWidgetApiKey: '9050f7be-6f6b-4c37-95e9-fb4460d5e1ae',
    },
    IT: {
      gateway: 'MiGo',
      sparePartWidgetApiKey: 'd6e2bc4e-f24f-4674-80ac-2c789243d28a',
    },
    RO: {
      gateway: 'MiGo',
      sparePartWidgetApiKey: '02d2f553-4f44-47cc-a4f6-88fa72df6370',
    },
  },
};

export const vaillant: Distribution = {
  brand: {
    name: 'Vaillant',
    key: 'vaillant',
    group: 'vaillant',
  },
  theme: 'puiGreen',
  platformName: 'myVAILLANT Pro Service',
  firebaseConfig: { prod: firebaseConfigVaillant, dev: firebaseConfigDev, qa: firebaseConfigDev },
  login: {
    appStoreLink: 'https://apps.apple.com/app/id1442871953',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.vaillantgroup.profui.vaillant',
    isLogoRightAligned: false,
  },
  sparePartWidgetUserAgent: 'myVAILLANT Pro',
  appStoreAvailableCountries: ['AT', 'BE', 'CZ', 'DE', 'FR', 'HR', 'GB', 'NL'],
  countries: {
    AT: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '472009b1-504d-4b0b-88c1-d1a17dd17098',
    },
    BE: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: 'b8963619-6867-47bb-9a06-53a62e27062c',
    },
    CH: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: 'f7b25ab6-3182-4cb3-8aef-eff026b6172b',
    },
    CZ: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '94b630eb-3ade-4c01-88b8-e688daaa5914',
    },
    DK: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '269d3709-c9cf-45a9-8d10-793336d8b20a',
    },
    DE: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '7f764d3b-de0a-4647-aabb-1cb99b99867e',
    },
    ES: {
      gateway: 'vSMART',
      sparePartWidgetApiKey: '31c58744-86e8-4691-bfef-3e9b114692dd',
    },
    EE: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '10d55b00-4f81-4f7f-ab33-bf1591dfe439', // International key
    },
    FI: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '10d55b00-4f81-4f7f-ab33-bf1591dfe439', // International key - Currently no specific key for Finland
    },
    FR: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '39afdcf0-6f6b-4b8f-b6ab-bb0e41d32462',
    },
    GB: {
      gateway: 'vSMART',
      sparePartWidgetApiKey: '5cb6d1fd-b67b-4111-9aef-5199c02612bf',
    },
    HR: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: 'ed8ae0e4-433e-49a9-a32a-3305b3a71327',
    },
    HU: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '10d55b00-4f81-4f7f-ab33-bf1591dfe439', // International key - Currently no specific key for Hungary
    },
    IT: {
      gateway: 'vSMART',
      sparePartWidgetApiKey: '96a7b3c9-e014-4d07-a4c9-db7fc33f3a35',
    },
    NL: {
      gateway: 'vSMART',
      sparePartWidgetApiKey: '61717b68-86f4-4e2e-9121-710f3dcbc21a',
    },
    NO: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '10d55b00-4f81-4f7f-ab33-bf1591dfe439', // International key - Currently no specific key for Norway
    },
    PL: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: 'db306ee6-ba66-4653-88f8-cc4baac0db91',
    },
    RO: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '09da11bf-109e-4ffe-b96b-08a6990ba574',
    },
    SE: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '29ef1396-28ed-47a3-bde2-540304a0d642',
    },
    SK: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '3ed41376-efd2-4571-a2ea-7ad40de0f3dd',
    },
    TR: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '46f5138c-a274-42a2-ae22-a42c4f10aacf',
    },
    UA: {
      gateway: 'eRELAX',
      sparePartWidgetApiKey: '1909506c-f082-4bb6-be0b-fc46fc0f9e21',
    },
  },
};

const bulex: Distribution = {
  brand: {
    name: 'Bulex',
    key: 'bulex',
    group: 'sdbg',
  },
  theme: 'puiRed',
  platformName: 'MiGo Xpert',
  firebaseConfig: { prod: firebaseConfigSaunierDuval, dev: firebaseConfigDev, qa: firebaseConfigDev },
  login: {
    appStoreLink: 'https://apps.apple.com/app/id1441660273',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.vaillantgroup.profui.saunierduval',
    isLogoRightAligned: true,
  },
  sparePartWidgetUserAgent: 'MiGo Xpert',
  appStoreAvailableCountries: ['BE'],
  countries: {
    BE: {
      gateway: 'MiGo',
      sparePartWidgetApiKey: 'ef7c68a0-061a-4c39-acdb-490bb07bf879',
    },
  },
};

const awb: Distribution = {
  brand: {
    name: 'Awb',
    key: 'awb',
    group: 'sdbg',
  },
  theme: 'puiRed',
  platformName: 'MiGo Xpert',
  firebaseConfig: { prod: firebaseConfigSaunierDuval, dev: firebaseConfigDev, qa: firebaseConfigDev },
  login: {
    appStoreLink: 'https://apps.apple.com/app/id1441660273',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.vaillantgroup.profui.saunierduval',
    isLogoRightAligned: true,
  },
  sparePartWidgetUserAgent: 'MiGo Xpert',
  appStoreAvailableCountries: ['NL'],
  countries: {
    NL: {
      gateway: 'MiGo',
      sparePartWidgetApiKey: '', // TODO
    },
  },
};

const glowWorm: Distribution = {
  brand: {
    name: 'Glow worm',
    key: 'glow-worm',
    group: 'sdbg',
  },
  theme: 'puiRed',
  platformName: 'MiGo Xpert',
  firebaseConfig: { prod: firebaseConfigSaunierDuval, dev: firebaseConfigDev, qa: firebaseConfigDev },
  login: {
    appStoreLink: 'https://apps.apple.com/app/id1441660273',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.vaillantgroup.profui.saunierduval',
    isLogoRightAligned: true,
  },
  sparePartWidgetUserAgent: 'MiGo Xpert',
  appStoreAvailableCountries: ['GB'],
  countries: {
    GB: {
      gateway: 'MiGo',
      sparePartWidgetApiKey: '', // TODO
    },
  },
};

export const distributions = { awb, bulex, 'glow-worm': glowWorm, saunierduval, vaillant };

export const getDistributionByBrand = (brand: string): Distribution | null => {
  return Object.values(distributions).find((distribution) => distribution.brand.key === brand.toLowerCase()) ?? null;
};

export const getDistributionCountries = (distribution: Distribution): Country[] =>
  Object.keys(distribution.countries) as Country[];
