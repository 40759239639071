import React from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { Typography, useTheme } from '@vaillant-professional-ui/component-libs-web';
import { util } from '@vaillant-professional-ui/pui-frontend-common';
import { EmfCategory, EmfChartTitleCardProps } from './EmfChartTitleCard.types';
import { emfCategoryIconMap } from './EmfChartTitleCard.util';

const ConsumptionIcon: React.FC<{ emfCategory: EmfCategory }> = ({ emfCategory }) => {
  const theme = useTheme();
  const widthHeight = useMediaQuery(theme.breakpoints.up('sm')) ? 56 : 32;

  const Icon = emfCategoryIconMap[emfCategory];

  return <Icon width={widthHeight} height={widthHeight} />;
};

export const EmfChartTitleCard: React.FC<EmfChartTitleCardProps> = ({ emfCategory, totalValue, unit = 'kWh' }) => {
  const formatNumber = util.l10n.useNumberFormatWithFallback();

  return (
    <Box m={0} display='flex' flexDirection='column' gap={1}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Box ml={-1.5} display='flex' alignItems='center' gap={1}>
          <ConsumptionIcon emfCategory={emfCategory} />
          <Typography fontWeight={600} variant='h1'>
            {`${formatNumber(totalValue)} ${unit}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
