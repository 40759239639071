/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon30700VgAddressLocation01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon30700vgaddresslocation01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M369.58 187.75A100.89 100.89 0 0 0 284.16 141h-1.39a101.35 101.35 0 0 0-91.95 144L263 440.55a22.59 22.59 0 0 0 41 0l72.15-155.62a100.87 100.87 0 0 0-6.57-97.18zm-14 87.65-72.11 155.44L211.4 275.4a78.67 78.67 0 0 1 71.37-111.77h1.39a78.68 78.68 0 0 1 71.38 111.77z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.47 203.31A39.69 39.69 0 1 0 323.15 243a39.69 39.69 0 0 0-39.68-39.69z'
        className='cls-1'
      />
    </svg>
  );
};
