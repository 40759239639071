import { LoyaltyRegistration } from '../typings/models';
import { addressLocationToString } from '../selectors/customer';

export const availableDevice = (
  registration: LoyaltyRegistration,
  query = '',
  ignoreBoilerSerialNumbers: string[] = [],
): boolean =>
  registration.state.connected &&
  registration.state.consentState === 'NONE' &&
  !ignoreBoilerSerialNumbers.includes(registration.serialNumber) &&
  (!query ||
    registration.serialNumber.toLowerCase().includes(query.toLowerCase()) ||
    (!!registration.address &&
      addressLocationToString(registration.address).toLowerCase().includes(query.toLowerCase())));

export const order = (state: LoyaltyRegistration[]) =>
  state.sort((a, b) => (a.registeredAt || '').localeCompare(b.registeredAt || ''));
