import { usePermissions as usePermissionBase } from '@vaillant-professional-ui/component-libs-common';
import { areFeaturesAvailableInCountry } from './PermissionGate.util';
import { countryAvailabililty } from '../../../libs/permissions';
import { useSelector } from 'react-redux';
import { getCountry } from '../../../store/selectors';

export const usePermissions = (requiredPermissions: string[]) => {
  const country = useSelector(getCountry);
  const isFeatureListAvailable = areFeaturesAvailableInCountry(requiredPermissions, country, countryAvailabililty);
  const baseResult = usePermissionBase(requiredPermissions);

  if (!isFeatureListAvailable) {
    return { ...baseResult, hasPermissions: false };
  }

  return baseResult;
};
