import { IAddressLocation } from '../typings/models';

export const formatAddress: (
  address: IAddressLocation,
  formatAddressUK: boolean,
  array?: boolean,
) => string | string[] = (address, formatAddressUK, asArray = false) => {
  const bits = formatAddressUK
    ? [address?.houseNumber ?? '', address?.street ?? '']
    : [address?.street ?? '', address?.houseNumber ?? ''];

  const filtered = bits.filter(Boolean);

  return asArray ? filtered : filtered.join(' ').trim();
};
