import React from 'react';

import { Alert, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useInstanceId, useTranslation, util } from '@vaillant-professional-ui/component-libs-common';
import { Button } from '../../atoms/Button';
import { Typography } from '../../atoms/Typography';
import { TimePicker } from '../../molecules/TimePicker';
import { ControllerType } from './ScheduleEditor.types';
import { changeEnd, changeStart } from './ScheduleEditor.util';
import { Icon45200VgDelete01 } from '../../atoms/Icon/Icon45200VgDelete01';
import { Icon47001VgExclamationMark201 } from '../../atoms/Icon/Icon47001VgExclamationMark201';

interface ScheduleEditorProps<T extends util.TimeWindow> {
  controllerType: ControllerType;
  managedValues: T[];
  maximumStartTime: util.TimeString;
  maxTimeWindows: number;
  timeStepWidthInMinutes: number;
  testIdKey?: string;

  deleteTimespan: (index: number) => void;
  onAddTimeWindow: () => void;
  onChangeTimeWindow: (index: number, values: T) => void;
  renderSelectComponent?: (index: number, timespan: T) => React.ReactElement;
}
export const ScheduleEditor = <T extends util.TimeWindow>({
  controllerType,
  managedValues,
  maximumStartTime,
  maxTimeWindows,
  timeStepWidthInMinutes,
  testIdKey,

  deleteTimespan,
  onAddTimeWindow,
  onChangeTimeWindow,
  renderSelectComponent,
}: ScheduleEditorProps<T>) => {
  const testId = useInstanceId('scheduleeditor', testIdKey);
  const t = useTranslation();

  const onChangeStart = (index: number) => (time: util.TimeString) => {
    onChangeTimeWindow(index, changeStart(managedValues[index], util.formatTimeToMinutesSinceMidnight(time)));
  };

  const onChangeEnd = (index: number) => (time: util.TimeString) => {
    onChangeTimeWindow(index, changeEnd(managedValues[index], util.formatTimeToMinutesSinceMidnight(time)));
  };

  return (
    <Box display='flex' flexDirection='column' alignContent='center' {...testId('root').testIdAttributes}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>{t('VG_SCHEDULE_EDITOR_START')}</TableCell>
              <TableCell align='center'>{t('VG_SCHEDULE_EDITOR_END')}</TableCell>
              {controllerType === ControllerType.TLI ? (
                <TableCell align='center'>{t('VG_SCHEDULE_EDITOR_TEMPERATURE')}</TableCell>
              ) : controllerType === ControllerType.ERELAX ? (
                <TableCell align='center'>{t('VG_SCHEDULE_EDITOR_PROFILES')}</TableCell>
              ) : null}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {managedValues?.map((timespan: T, index: number) => {
              const start = util.formatTimeFromMinutesSinceMidnight(timespan.start) as util.TimeString;
              const end = util.formatTimeFromMinutesSinceMidnight(timespan.end) as util.TimeString;
              return (
                <TableRow
                  {...testId(`row-${index}`).testIdAttributes}
                  hover={false}
                  selected={false}
                  key={`${Math.random() * 100000000}`} // note: this is necessary because otherwise changing start times will not be rendered
                >
                  <TableCell align='center'>
                    <TimePicker
                      maximum={maximumStartTime}
                      stepWidthInMinutes={timeStepWidthInMinutes}
                      setTime={onChangeStart(index)}
                      initialValue={start}
                      testIdKey={testId('start').key}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <TimePicker
                      stepWidthInMinutes={timeStepWidthInMinutes}
                      setTime={onChangeEnd(index)}
                      initialValue={end}
                      testIdKey={testId('end').key}
                    />
                  </TableCell>
                  {renderSelectComponent && (
                    <TableCell align='center'>{renderSelectComponent(index, timespan)}</TableCell>
                  )}
                  <TableCell align='center'>
                    <Button
                      testIdKey={testId(`btn-delete-${index}`).key}
                      variant='text'
                      color='primary'
                      onClick={() => {
                        deleteTimespan(index);
                      }}
                    >
                      <Icon45200VgDelete01 color='primary' />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt='40px' display='flex' justifyContent='center' flexDirection='column'>
        {managedValues?.length >= maxTimeWindows && (
          <Alert
            style={{ marginBottom: '20px' }}
            severity='warning'
            icon={<Icon47001VgExclamationMark201 color='white' />}
          >
            <Typography style={{ fontWeight: 'bold' }}>{t('VG_SCHEDULE_EDITOR_MAX_WINDOWS_ALERT_1')}</Typography>
            <Typography variant='caption'>{t('VG_SCHEDULE_EDITOR_MAX_WINDOWS_ALERT_2')}</Typography>
          </Alert>
        )}
        <Button
          testIdKey={testId('btn-add-schedule').key}
          variant='text'
          color='primary'
          disabled={managedValues?.length >= maxTimeWindows}
          onClick={onAddTimeWindow}
        >
          {t('VG_SCHEDULE_EDITOR_ADD_ROW')}
        </Button>
      </Box>
    </Box>
  );
};
