import { FirebaseOptions } from './firebaseService.types';

export const firebaseConfigDev: FirebaseOptions = {
  apiKey: 'AIzaSyAMQhDWCfBfzZI8U9ShDXl3vCwXpvonkQM',
  authDomain: 'vg-iot-profui-dev.firebaseapp.com',
  databaseURL: 'https://vg-iot-profui-dev.firebaseio.com',
  projectId: 'vg-iot-profui-dev',
  storageBucket: 'vg-iot-profui-dev.appspot.com',
  messagingSenderId: '630012913895',
  appId: '1:630012913895:web:2eee1beabe21434da05a42',
  measurementId: 'G-KEB5XX8Z70',
};

export const firebaseConfigVaillant: FirebaseOptions = {
  apiKey: 'AIzaSyDaZv-dcmdMsw6sJ_Il0niyrtuXmS3IIus',
  authDomain: 'vg-iot-profui-prod.firebaseapp.com',
  databaseURL: 'https://vg-iot-profui-prod.firebaseio.com',
  projectId: 'vg-iot-profui-prod',
  storageBucket: 'vg-iot-profui-prod.appspot.com',
  messagingSenderId: '1001552137117',
  appId: '1:1001552137117:web:312025ba8cb95f09303d74',
  measurementId: 'G-8Z8EQCRJHP',
};

export const firebaseConfigSaunierDuval: FirebaseOptions = {
  apiKey: 'AIzaSyDaZv-dcmdMsw6sJ_Il0niyrtuXmS3IIus',
  authDomain: 'vg-iot-profui-prod.firebaseapp.com',
  databaseURL: 'https://vg-iot-profui-prod.firebaseio.com',
  projectId: 'vg-iot-profui-prod',
  storageBucket: 'vg-iot-profui-prod.appspot.com',
  messagingSenderId: '1001552137117',
  appId: '1:1001552137117:web:648f043517a69fcf303d74',
  measurementId: 'G-518PR78728',
};
