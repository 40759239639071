import React from 'react';
import { Marker as MarkerBase, Popup } from 'react-leaflet';
import { models, selectors } from '@vaillant-professional-ui/pui-frontend-common';
import { useFirebase } from '../../providers/firebase';
import { MapContextProps } from './DeviceMap.types';
import { markerActive, markerDefault } from '../../lib/maps';
import { CustomerPopup } from './CustomerPopup';

interface MarkerWrapperProps {
  customers: models.ICustomer[];
  dtcTypes?: models.DtcTypeName[];
  boilerSerialNumber: string;
  position: models.Geolocation;
  isSelected: boolean;
  isHighlighted: boolean;
  setSelectedCustomer: MapContextProps['setSelectedCustomer'];
  setHighlightedCustomer: MapContextProps['setHighlightedCustomer'];
}

const isPropsEqual = (prevProps: MarkerWrapperProps, nextProps: MarkerWrapperProps): boolean => {
  if (prevProps.isSelected && nextProps.isSelected && nextProps.isHighlighted) {
    return true;
  }

  if (prevProps.isSelected !== nextProps.isSelected || prevProps.isHighlighted !== nextProps.isHighlighted) {
    return false;
  }

  return true;
};

export const Marker: React.FC<React.PropsWithChildren<MarkerWrapperProps>> = React.memo(function Marker({
  customers,
  dtcTypes,
  isHighlighted,
  isSelected,
  setSelectedCustomer,
  setHighlightedCustomer,
  boilerSerialNumber,
  position,
}: MarkerWrapperProps) {
  const markerRef = React.useRef();
  const firebaseService = useFirebase();
  const [isRefReady, setIsRefReady] = React.useState(false);
  React.useEffect(() => {
    if (markerRef?.current && isSelected) {
      // @ts-ignore
      markerRef?.current?.openPopup();
    }
  }, [isRefReady, isSelected]);

  const customer =
    isSelected || isHighlighted
      ? customers.find((c) => selectors.customer.getBoilerSerialNumber(c) === boilerSerialNumber) ?? null
      : null;

  return (
    <MarkerBase
      ref={(r) => {
        // @ts-ignore
        markerRef.current = r;
        setIsRefReady(true);
      }}
      key={`marker-${boilerSerialNumber}`}
      position={{ lat: position.latitude, lng: position.longitude }}
      icon={isHighlighted || isSelected ? markerActive : markerDefault}
      eventHandlers={{
        mouseover() {
          !isSelected && setHighlightedCustomer(boilerSerialNumber);
        },
        mouseout() {
          setHighlightedCustomer(null);
        },
        click() {
          firebaseService.logUserAction('dashboardMapSelectCustomer', { location: 'marker' });
          setSelectedCustomer(boilerSerialNumber);
        },
      }}
    >
      <Popup closeButton={false}>
        <CustomerPopup dtcTypes={dtcTypes} customer={customer} />
      </Popup>
    </MarkerBase>
  );
},
isPropsEqual);
