import React from 'react';
import { styled } from '../../../util/styling';
import { Typography, TypographyProps } from '../../atoms/Typography';

export const SmallText: React.FC<React.PropsWithChildren<TypographyProps>> = styled(Typography)`
  display: inline;
`;

export const BigText: React.FC<React.PropsWithChildren<TypographyProps>> = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(4)};
  display: inline;
  font-weight: 600;
`;
