import { useCase } from './useCase';

interface CustomerDetailPresenterProps {
  boilerSerialNumber: string;
}

export const usePresenter = ({ boilerSerialNumber }: CustomerDetailPresenterProps) => {
  const {
    customer,
    isLoading,
    error,
    errorDescription,
    customerFullName,
    dtcDeviceId,
    language,
    country,
    isAddTroublePending,
    isDeleteTroublePending,
    isUpdateTroublePending,
    addNote,
    deleteNote,
    updateNote,
    userContactId,
  } = useCase({ boilerSerialNumber });

  return {
    customer,
    isLoading,
    error,
    errorDescription,
    customerFullName,
    dtcDeviceId,
    language,
    country,
    isAddTroublePending,
    isDeleteTroublePending,
    isUpdateTroublePending,
    addNote,
    deleteNote,
    updateNote,
    userContactId,
  };
};
