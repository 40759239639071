import React from 'react';
import camelCase from 'lodash/camelCase';
import { config } from '@vaillant-professional-ui/pui-frontend-common';
import { NavigationBarAnonymousProps } from './NavigationBar.types';
import { LogoWrapper, StyledAppBar, StyledToolbar } from './NavigationBarAnonymous.styles';
import Link from '../Link/Link';
import * as images from '../../assets/images';

export const NavigationBarAnonymous: React.FC<React.PropsWithChildren<NavigationBarAnonymousProps>> = () => {
  const appConfig = config.useAppConfig();
  // @ts-ignore
  const topLogo = images[`${camelCase(appConfig.distribution.brand.key)}Logo`];

  return (
    <StyledAppBar position='static' elevation={0}>
      <StyledToolbar $isLogoRightAligned={appConfig.distribution.login.isLogoRightAligned}>
        <Link to='/'>
          <LogoWrapper>
            <img src={topLogo} height='45' alt='Logo' />
          </LogoWrapper>
        </Link>
      </StyledToolbar>
    </StyledAppBar>
  );
};
