import { useLoggingContext } from '../../../index';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useRemoveCustomer as useRemoveCustomerApi } from '../../api/apis/management';

interface RemoveUseCaseProps {
  opts: UseRemoveCustomerParams;
  boilerSerialNumber: string;
}

export type UseRemoveCustomerParams = {
  onSuccess?: () => void;
  onError?: () => void;
} | null;

export type UseRemoveCustomer = (params: UseRemoveCustomerParams) => {
  removeCustomer: (boilerSerialNumber: string) => Promise<void>;
  isSuccess: boolean;
  isLoading: boolean;
};

export const useRemoveCustomer: UseRemoveCustomer = (params) => {
  const queryClient = useQueryClient();
  const { reportError } = useLoggingContext();
  const { mutateAsync, isSuccess, isLoading } = useRemoveCustomerApi({
    onSuccess: () => {
      queryClient.resetQueries('customerList').catch((e) => {
        reportError('customerlist-refetch', e);
      });
      params?.onSuccess?.();
    },
    onError: () => {
      params?.onError?.();
    },
  });

  const removeCustomer = async (boilerSerialNumber: string) => {
    await mutateAsync({ boilerSerialNumber });
    try {
      await mutateAsync({ boilerSerialNumber });
    } catch (error) {
      reportError('remove-customer', error);
    }
  };

  return { removeCustomer, isSuccess, isLoading };
};

export const useCase = ({ opts, boilerSerialNumber }: RemoveUseCaseProps) => {
  const { removeCustomer, isLoading, isSuccess } = useRemoveCustomer({ ...opts });

  const onRemoveCustomer = useCallback(async () => {
    await removeCustomer(boilerSerialNumber);
  }, [removeCustomer, boilerSerialNumber]);

  return { removeCustomer: onRemoveCustomer, isLoading, isSuccess };
};
