import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { config, store, models, countryToLanguage } from '@vaillant-professional-ui/pui-frontend-common';
import { LegalContentPageProps } from './LegalContentPage.types';

export const LegalContentPage: React.FC<LegalContentPageProps> = ({ contentType }) => {
  const {
    distribution: { countries, brand },
  } = config.useAppConfig();
  const language = useSelector(store.getLanguage);
  const params = useParams<{ country: string }>();
  const country = (params.country ?? '').toLowerCase();

  if (!Object.keys(countries).includes(country.toUpperCase())) {
    throw new Error('Invalid country');
  }

  const Component = React.lazy(() => {
    const defaultLanguageForCountry = countryToLanguage(params.country as models.Country);
    return import(
      /* webpackChunkName: "Legal" */ `./content/${brand.key}/${country}/${contentType}/${language}.tsx`
    ).catch(() => {
      return import(
        /* webpackChunkName: "Legal" */ `./content/${brand.key}/${country}/${contentType}/${defaultLanguageForCountry}.tsx`
      );
    });
  });

  return (
    <React.Suspense>
      <Component />
    </React.Suspense>
  );
};
