/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon18801VgStart01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon18801vgstart01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M229.35 385.05a17 17 0 0 1-25.54-14.71V196.59a17 17 0 0 1 25.54-14.71l149.89 86.87a17 17 0 0 1 0 29.43z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
