import { useParams } from 'react-router-dom';

import { api, selectors } from '@vaillant-professional-ui/pui-frontend-common';

import { ErrorBoundary } from '../../ErrorBoundary';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { ErelaxSchedulerPage } from './ErelaxSchedulerPage';

type PathParams = Record<'boilerSerialNumber', string>;

export const ErelaxSchedulerPageContainer: React.FC = () => {
  const { boilerSerialNumber } = useParams<PathParams>() as PathParams;
  const {
    error: errorCustomer,
    customer,
    isLoading: isLoadingCustomer,
    refetch,
  } = api.useCases.useCustomerDetails(boilerSerialNumber);

  const gatewaySerialNumber = customer ? selectors.customer.getGatewaySerialNumber(customer) : null;

  const {
    viewData,
    isLoading: isLoadingSchedules,
    error: errorSchedules,
  } = api.useCases.erelaxSchedules.useSchedules(gatewaySerialNumber as string, {
    enabled: !!gatewaySerialNumber,
  });

  const save = api.useCases.usePatchErelaxSchedule(gatewaySerialNumber as string);

  return (
    <ErrorBoundary>
      <LoadingErrorWrapper
        error={(errorCustomer || errorSchedules) ?? null}
        isPending={isLoadingCustomer || isLoadingSchedules}
        showLoadingSpinner={true}
        onRetry={() => {
          void refetch();
        }}
      >
        <ErelaxSchedulerPage
          profiles={viewData?.profiles ?? []}
          scheduleVgComponents={
            (viewData?.scheduleVgComponents as api.useCases.erelaxSchedules.ScheduleVgComponents[]) ?? []
          }
          onSave={save}
        />
      </LoadingErrorWrapper>
    </ErrorBoundary>
  );
};
