import * as hooks from '../../../libs/hooks';
import { useDiagnostics } from '../../apis/diagnostics';
import { useWaterPressurePrediction } from '../../apis/maintenance/v1';
import {
  getDisplayParameters,
  getWaterPressureFromDiagnosticDataOrFallBackToPredictionData,
  overwritePredictionDataIfPresent,
} from './WaterPressurePrediction.hooks.utils';

export const useWaterPressurePredictionChart = (
  boilerSerialNumber: string,
  gatewaySerialNumber: string,
  now = new Date(),
) => {
  const t = hooks.useTranslation();
  const df = hooks.useDateFormatter();

  const { data: diagnosticsData } = useDiagnostics(gatewaySerialNumber, t, df);

  const {
    isError,
    isFetching: isPending,
    isSuccess,
    data: predictionData,
  } = useWaterPressurePrediction({ boilerSerialNumber, now });

  const waterPressure = getWaterPressureFromDiagnosticDataOrFallBackToPredictionData(diagnosticsData, predictionData);

  const { warning, isUnHealthy, arrowDirection, textColor, backgroundColor, title } = getDisplayParameters(
    predictionData ?? null,
    waterPressure,
    t,
  );

  const data = overwritePredictionDataIfPresent(predictionData, isUnHealthy);

  const computationDate = predictionData ? df(predictionData.predictionComputedAt, true) : null;

  return {
    isError,
    isPending,
    isSuccess,
    data,
    title,
    computationDate,
    warning,
    arrowDirection,
    textColor,
    backgroundColor,
    waterPressure,
  };
};
