import React from 'react';
import { useDateRangePersistor } from '../dateRangePersistor';
import { DateRangePicker } from './DateRangePicker';

const usePresenter = () => {
  const { start, end, save } = useDateRangePersistor();

  const onChange = React.useCallback(
    (startDate: Date, endDate: Date) => {
      save({ startDate, endDate });
    },
    [save],
  );

  return {
    onChange,
    start,
    end,
  };
};

export const DateRangePickerContainer: React.FC = () => {
  const { onChange, start, end } = usePresenter();

  return <DateRangePicker onChange={onChange} startDate={start} endDate={end} />;
};
