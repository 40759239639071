/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon41100VgStorageWithCoil01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon41100vgstoragewithcoil01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M379.84 96.38H187.09a22.67 22.67 0 0 0-22.68 22.68v330.23a21.26 21.26 0 0 0 21.26 21.26h195.59a21.26 21.26 0 0 0 21.26-21.26V119.06a22.67 22.67 0 0 0-22.68-22.68zm0 22.68V148c-6 1.14-11.48 3.85-16.42 6.32-4.39 2.2-8.94 4.47-11.93 4.47s-7.54-2.27-11.94-4.47c-6.44-3.22-13.73-6.87-22.08-6.87s-15.64 3.65-22.08 6.87c-4.4 2.2-8.94 4.47-11.93 4.47s-7.54-2.27-11.94-4.47c-6.44-3.22-13.73-6.87-22.08-6.87s-15.64 3.65-22.08 6.87c-4.4 2.2-8.94 4.47-11.94 4.47s-7.53-2.27-11.93-4.47c-4.94-2.47-10.38-5.18-16.41-6.32v-28.94zM187.09 447.88v-39.69h17v-28.35h-17v-51h17v-28.36h-17v-51h17V221.1h-17v-49.51c2 .83 4.15 1.9 6.27 3 6.43 3.22 13.73 6.87 22.08 6.87s15.64-3.65 22.07-6.87c4.4-2.2 9-4.47 11.94-4.47s7.54 2.27 11.94 4.47c6.43 3.22 13.73 6.87 22.08 6.87s15.64-3.65 22.07-6.87c4.4-2.2 8.95-4.47 11.94-4.47s7.54 2.27 11.94 4.47c6.44 3.22 13.73 6.87 22.08 6.87s15.64-3.65 22.08-6.87c2.12-1.06 4.27-2.13 6.27-3v276.29z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M232.45 255.6a8.5 8.5 0 1 0 5.66 16l95-33.53h24.05v-17h-27zm2.83 44.88a8.52 8.52 0 0 0 2.83-.49l96.38-34a8.5 8.5 0 1 0-5.66-16l-96.38 34a8.51 8.51 0 0 0 2.83 16.53zm0 28.34a8.5 8.5 0 0 0 2.83-.48l96.38-34a8.5 8.5 0 1 0-5.66-16l-96.38 34a8.5 8.5 0 0 0 2.83 16.52zm-2.83 11.82a8.5 8.5 0 1 0 5.66 16l95-33.53h24.05v-17h-27z'
        className='cls-1'
      />
    </svg>
  );
};
