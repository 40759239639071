import { useParams } from 'react-router-dom';

export const getRedirectPath = (
  basePath: string,
  redirectPath: string | null,
  currentPath: string,
  fallbackToCurrentPath: boolean,
) => {
  const searchParams = new URLSearchParams();
  if (redirectPath && basePath !== redirectPath) {
    searchParams.set('redirect', redirectPath);
  } else if (fallbackToCurrentPath && currentPath && basePath !== currentPath) {
    searchParams.set('redirect', currentPath);
  }
  const redirect = searchParams.toString() ? `?${searchParams.toString()}` : '';
  return `${basePath}${redirect}`;
};

export const getRedirectPathFromLocation = (basePath: string, fallbackToCurrentPath = true) => {
  const redirectPath = new URLSearchParams(window.location.search).get('redirect');
  const currentPath = window.location.href.split(window.location.origin).reverse()[0];
  return getRedirectPath(basePath, redirectPath, currentPath, fallbackToCurrentPath);
};

export const useBoilerSerialNumberFromPath = () => {
  const { boilerSerialNumber } = useParams<Record<'boilerSerialNumber', string>>();
  if (!boilerSerialNumber) {
    throw new Error('Unexpected path. Missing serial number in URL');
  }
  return boilerSerialNumber;
};

export const useDeviceIdFromPath = () => {
  const { deviceId } = useParams<Record<'deviceId', string>>();
  if (!deviceId) {
    throw new Error('Unexpected path. Missing device id in URL');
  }
  return deviceId;
};
