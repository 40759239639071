import { isNil, startCase } from 'lodash';
import { Action } from '../../../../../api/apis/maintenance/v2/maintenance.types';
import { NumericWriteParams } from '../../../deviceSettings/useCase/model/dataPoint';

interface Params<MeasuredValueType> {
  initialValue: MeasuredValueType;
  action: Action;
  actionPropertyKey: string;
  payloadMetadata?: Record<string, number | string>;
}

export const getNumericWriteParams = ({
  initialValue,
  action,
  actionPropertyKey,
  payloadMetadata,
}: Params<number>): NumericWriteParams | null => {
  const apiWriteParams = getApiWriteParams(action);
  const { minimum, maximum, multipleOf } = action.schema?.properties?.[actionPropertyKey] ?? {};
  if (!apiWriteParams || isNil(minimum) || isNil(maximum) || isNil(multipleOf)) {
    return null;
  } else {
    return {
      ...apiWriteParams,
      initialValue,
      minimum,
      maximum,
      stepSize: multipleOf,
      payloadMetadata,
      actionPropertyKey,
    };
  }
};

export const getEnumWriteParams = ({ initialValue, action, actionPropertyKey, payloadMetadata }: Params<string>) => {
  const apiWriteParams = getApiWriteParams(action);
  const enumValues = action.schema?.properties?.[actionPropertyKey]?.enum;

  return apiWriteParams && enumValues
    ? {
        ...apiWriteParams,
        actionPropertyKey,
        initialValue,
        possibleValues: enumValues.map((value) => ({
          value,
          title: formatEnumValue(value),
        })),
        payloadMetadata,
      }
    : null;
};

export const getBooleanWriteParams = ({
  initialValue,
  action,
  actionPropertyKey,
  payloadMetadata,
}: Params<boolean>) => {
  const apiWriteParams = getApiWriteParams(action);
  return apiWriteParams
    ? {
        ...apiWriteParams,
        initialValue,
        actionPropertyKey,
        payloadMetadata,
      }
    : null;
};

const getApiWriteParams = (action: Action) => {
  const { href: url, method } = action;
  return url && method ? { url, method } : null;
};

export const formatEnumValue = (enumValue: string) => startCase(enumValue.toLowerCase());
