import { LinkDataPoint } from '../useCase/model/dataPoint';

/**
 * Helper function for generating proper key for Links on Device Settings View
 * @param index - number
 * @param section - @enum {LinkDataPoint['metadata']['section']}
 */
export const generateKeyFromIndexAndSection = (index: number, section: LinkDataPoint['metadata']['section']) => {
  return `${index}-${section}`;
};
