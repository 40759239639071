import { useTranslation } from '../../../..';
import { DeviceDetailsResponseTli } from '../../apis/maintenance/v2';
import { usePatchHeatCurve, usePatchMinFlowTemperature } from '../../apis/settings/settings.hooks';
import { HeatCurveVariables, MinFlowTargetTemperatureVariables } from '../../apis/settings/settings.types';
import { QueryKey, useOptimisticQueryOptions } from '../../hooks';
import {
  updateHeatCurve,
  updateMinFlowTemperature,
  useWithToastMessage,
} from './OptimisticUpdateAgainstSettingsApiUtil';

export const useOptimisticPatchMinFlowTemperature = (gatewaySerialNumber: string, tliStateKey: QueryKey) => {
  const t = useTranslation();
  const options = useOptimisticQueryOptions<DeviceDetailsResponseTli, MinFlowTargetTemperatureVariables>(
    tliStateKey,
    updateMinFlowTemperature,
  );

  /*
   * Minimum Flow Temperature is configured along with other heat curve parameters
   * via the heat curve dialog.
   * For that reason we inform the user that 'heat curve settings' are successfully
   * written, not 'minimum flow temperature' (see commented i18n keys).
   */
  const optionsWithToastMessages = useWithToastMessage(
    options,
    t('SG_TOAST_HEAT_CURVE_SUCCESS'), //  'SG_TOAST_HEAT_MIN_FLOW_TEMPERATURE_SUCCESS',
    t('SG_TOAST_ERROR_MSG'), // 'SG_TOAST_ERROR_MSG',
    t('SG_TOAST_HEAT_CURVE_FAILED'), // 'SG_TOAST_HEAT_MIN_FLOW_TEMPERATURE_FAILED',
  );
  return usePatchMinFlowTemperature(gatewaySerialNumber, optionsWithToastMessages);
};

export const useOptimisticPatchHeatCurve = (gatewaySerialNumber: string, tliStateKey: QueryKey) => {
  const t = useTranslation();
  const options = useOptimisticQueryOptions<DeviceDetailsResponseTli, HeatCurveVariables>(tliStateKey, updateHeatCurve);
  const optionsWithToastMessages = useWithToastMessage(
    options,
    t('SG_TOAST_HEAT_CURVE_SUCCESS'),
    t('SG_TOAST_ERROR_MSG'),
    t('SG_TOAST_HEAT_CURVE_FAILED'),
  );

  return usePatchHeatCurve(gatewaySerialNumber, optionsWithToastMessages);
};
