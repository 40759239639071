import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Collapse } from '@mui/material';
import { Icon02501VgDown01 } from '../../atoms/Icon/Icon02501VgDown01';
import { Card, CardHeader } from '../Card';
import { ActionArea, ExpandMore } from './CollapsibleCard.styled';
import { CollapsibleCardProps } from './CollapsibleCard.types';

export const CollapsibleCard: React.FunctionComponent<React.PropsWithChildren<CollapsibleCardProps>> = ({
  children,
  testIdKey,
  headerProps = {},
  isInitiallyOpen = true,
  ...cardProps
}) => {
  const testId = useInstanceId('card', testIdKey);
  const [isContentVisible, setIsContentVisible] = React.useState(isInitiallyOpen);

  const toggle = () => setIsContentVisible((state) => !state);

  return (
    <Card {...cardProps} {...testId('root').testIdAttributes}>
      <ActionArea onClick={toggle} disableRipple={true}>
        <CardHeader
          action={
            <ExpandMore $isExpanded={isContentVisible} {...testId('collapse-button')}>
              <Icon02501VgDown01 color='primary.main' width={32} height={32} />
            </ExpandMore>
          }
          {...headerProps}
        />
      </ActionArea>
      <Collapse in={isContentVisible} timeout='auto' unmountOnExit={true}>
        {children}
      </Collapse>
    </Card>
  );
};
