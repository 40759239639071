import React, { useMemo } from 'react';

import { BaseTheme } from '@vaillant-professional-ui/component-libs-common';

import { difference, zipObject } from 'lodash';
import { nonNullable } from '../../../../util';
import { useCase } from '../useCase';
import { DataProps } from './presenter.types';
import { findNearestDataPoints, getFilteredByDate, getMinMaxDates, groupDate } from './timeSeries';
import { DateTimeRange, NumberCoordinate, NumberTimeSeries } from './timeSeries.types';

type HistoryUseCaseData = ReturnType<typeof useCase>;
type UseCaseData = HistoryUseCaseData;

export const DTC_COLORS: Record<string, keyof BaseTheme['colors']> = {
  dtcStatus: 'grey',
  dtcMaintenance: 'secondary',
  dtcError: 'error',
};

export const COLORS = [
  '#E45812',
  '#2A3F96',
  '#FF95A9',
  '#816D75',
  '#723086',
  '#46C2EF',
  '#3D7E79',
  '#2CD34D',
  '#B3F51E',
  '#588BC6',
  '#1B1B1A',
  '#E52D8A',
  '#FFB576',
  '#B8D1F1',
  '#919191',
  '#FFC73E',
  '#2F2967',
  '#6db083',
  '#ef9e66',
  '#f38f97',
  '#ec825c',
  '#b3cd91',
  '#e65e6f',
  '#110000',
  '#564243',
];

const getTimeSeriesFromCategories = (
  timeSeriesId: string,
  categories: NonNullable<UseCaseData['data']>['categories'],
) => {
  for (const category of categories) {
    for (const ts of category.timeSeries) {
      if (ts.id === timeSeriesId) {
        return ts;
      }
    }
  }
  return null;
};

const transformTimeSeries = (
  categories: NonNullable<UseCaseData['data']>['categories'],
  timeSeriesData: NonNullable<UseCaseData['data']>['timeSeriesData'],
  zoomRange: DateTimeRange | null,
  colorMap: Record<string, string>,
) => {
  return timeSeriesData.map<NumberTimeSeries | null>((line) => {
    const ts = getTimeSeriesFromCategories(line.id, categories);
    if (!ts) {
      return null;
    }

    const coordinates = line.values.map((v) => {
      return [v.timestamp, v.value] as NumberCoordinate;
    });

    return {
      id: ts.id,
      name: ts.title,
      color: colorMap[ts.id],
      unit: ts.unit,
      coordinates,
      filteredByZoomDomain: groupDate(getFilteredByDate<NumberCoordinate>(coordinates, zoomRange), zoomRange).sort(
        (a, b) => a[0].getTime() - b[0].getTime(),
      ),
    };
  });
};

const mapChartData = (data: NonNullable<UseCaseData['data']>, zoomRange: DateTimeRange | null) => {
  const { categories, timeSeriesData } = data;

  const colorMap = zipObject(
    categories.flatMap((category) => category.timeSeries.map((ts) => ts.id)),
    COLORS,
  );

  const lineTimeSeries: NumberTimeSeries[] = transformTimeSeries(
    categories,
    timeSeriesData,
    zoomRange,
    colorMap,
  ).filter(nonNullable);

  const categoriesWithColor = categories.map((category) => ({
    ...category,
    timeSeries: category.timeSeries.map((ts) => ({ ...ts, color: colorMap[ts.id] })),
  }));

  const getTooltipValues: DataProps['getTooltipValues'] = (position: Date) =>
    findNearestDataPoints(position, lineTimeSeries, [], zoomRange);

  const yDomain: [number, number] | null = data.ranges ? [data.ranges.min_value, data.ranges.max_value] : null;

  return {
    dateRange: getMinMaxDates(lineTimeSeries),
    getTooltipValues,
    categories: categoriesWithColor,
    lines: lineTimeSeries,
    title: data.title,
    yDomain,
    zoomDomain: { x: zoomRange ?? undefined, y: yDomain ?? undefined },
  };
};

interface PresenterParams {
  boilerSerialNumber: string;
  url: string;
  start: Date;
  end: Date;
  zoomRange: DateTimeRange;
  setZoomRange: (zoomRange: DateTimeRange) => void;
}

export const usePresenter = (props: PresenterParams) => {
  const xDomain: DateTimeRange = [props.start, props.end];

  const [selectedTimeSeriesIds, setSelectedTimeSeriesIds] = React.useState<string[] | null>(null);

  const onToggleTimeSeries = React.useCallback(
    (timeSeries: string[], isEnabled: boolean) => {
      const newSelectedTimeSeries = isEnabled
        ? (selectedTimeSeriesIds ?? []).concat(timeSeries)
        : difference(selectedTimeSeriesIds, timeSeries);

      setSelectedTimeSeriesIds(newSelectedTimeSeries);
    },
    [selectedTimeSeriesIds],
  );

  const { isError, isLoading, isFetching, data } = useCase({ ...props, selectedTimeSeries: selectedTimeSeriesIds });

  const zoomChartData = useMemo(() => {
    return data ? mapChartData(data, props.zoomRange) : null;
  }, [data, props.zoomRange]);

  // Update the selectedTimeSeries array from the default selections coming from the response
  React.useEffect(() => {
    const selectedTimeSeriesFromResponse = zoomChartData?.categories
      .flatMap((category) => category.timeSeries.map((ts) => (ts.isEnabled ? ts.id : null)))
      .filter(nonNullable)
      .sort();

    if (selectedTimeSeriesFromResponse?.length && selectedTimeSeriesIds === null) {
      setSelectedTimeSeriesIds(selectedTimeSeriesFromResponse);
    }
  }, [selectedTimeSeriesIds, zoomChartData?.categories]);

  const isTimeSeriesEnabled = React.useCallback(
    (id: string) => {
      return selectedTimeSeriesIds?.includes(id) ?? false;
    },
    [selectedTimeSeriesIds],
  );

  const maxTimeSeries = data?.maxTimeSeries ?? 10;
  const selectedTimeSeries =
    zoomChartData?.categories
      .flatMap((category) => category.timeSeries)
      .filter((ts) => selectedTimeSeriesIds?.includes(ts.id)) ?? [];

  return {
    isLoading,
    isFetching,
    containerProps: {
      error: isError,
      setZoomRange: props.setZoomRange,
      xDomain,
      width: '100%',
      onToggleTimeSeries,
      isTimeSeriesEnabled,
      maxTimeSeries: maxTimeSeries,
      selectedTimeSeries,
      freeSlotsAllowed: Math.max(0, maxTimeSeries - (selectedTimeSeriesIds?.length ?? 0)),
    },
    dataProps: zoomChartData,
  };
};

export type ViewData = ReturnType<typeof usePresenter>;
