import React from 'react';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { Box, Typography } from '@mui/material';
import { ThemeColors } from '../../Colors';
import { CancelButton, SubmitButton, Container } from './AcceptTerms.styles';
import { AcceptTermsProps } from './AcceptTerms.types';

export const AcceptTerms = ({ termsLink, privacyPolicyLink, onAccept, onDecline }: AcceptTermsProps) => {
  const t = hooks.useTranslation();
  return (
    <Container elevation={0}>
      <Typography align='center'>
        {t('SG_TERMS_TEXT_TEXT', {
          0: (
            <a href={termsLink} target='_blank' style={{ color: ThemeColors.ACTION }} rel='noopener noreferrer'>
              {t('SG_TERMS_TEXT_ITEM0')}
            </a>
          ),
          1: (
            <a href={privacyPolicyLink} target='_blank' style={{ color: ThemeColors.ACTION }} rel='noopener noreferrer'>
              {t('SG_TERMS_TEXT_ITEM1')}
            </a>
          ),
        })}
      </Typography>

      <Box m={4}>
        <CancelButton testIdKey='terms-reject' onClick={onDecline} color='primary' variant='outlined'>
          {t('SG_TERMS_REJECT')}
        </CancelButton>

        <SubmitButton testIdKey='terms-accept' onClick={onAccept} color='primary' variant='contained'>
          {t('SG_TERMS_ACCEPT')}
        </SubmitButton>
      </Box>
    </Container>
  );
};
