import { IColors } from './theme.types';

const colors: IColors = {
  ACTION: '#FF8500',
  ACTION_HOVER: '#FE7407',
  ACTIVE_COLOR: '#cce9e5',
  ACTIVE_COLOR_LIGHT: '#f5fbfa',
  ANTHRAZIT_BLACK: '#333d40',
  BACKGROUND_COLOR_GRAY: '#f1f1f1',
  BLACK: '#000000',
  BLACK_SOFT: '#0C0033',
  BOX_SHADOW_3: '#6c6c6c',
  CONTAINER_BORDER: 'rgba(0, 0, 0, 0.7)',
  CONTAINER_BORDER_LIGHT: 'rgba(128, 128, 128, 0.3)',
  CUSTOMER_BACKGROUND_COLOR: '#00D2A720',
  CUSTOMER_DETAILS_BACKGROUND_COLOR: '#E9E9E9',
  CUSTOMER_DETAILS_BOX_SHADOW: 'inset 0px 20px 18px -10px #bbb, inset 0px -20px 18px -10px #bbb',
  CUSTOMER_LIST_BACKGROUND_COLOR: 'rgba(204, 0, 51, 0.15)',
  DOCUMENT_DOWNLOAD_BOX_SHADOW: '#d1d1d1',
  ERROR: '#cc0033',
  ERROR_LIGHT: '#d85339',
  FROSTY_BLUE: '#8cd0e7',
  GRAY_DARK: '#919191',
  GRAY_LIGHT: '#9B9B9B',
  GRAY_LIGHTER: '#f8f8f8',
  GRAY_WHITE: '#F1F1F1',
  GRID_ROW_BACKGROUND: '#F5F5F6',
  HEAT_ORANGE: '#e27246',
  INACTIVE: '#CCCCCC',
  INPUT_BORDER: '#C2BFCC',
  LIGHT_GREEN: '#F0F6F6',
  LOGIN_TEXT_BACKGROUND_COLOR: '#FF8500',
  PLACEHOLDER_TEXT: 'rgba(12,0,51,0.5)',
  POPPER_BORDER_GRAY: '#D6D3DD',
  PRIMARY: '#00917E',
  PRIMARY_HOVER: '#007465',
  SECONDARY: '#294447',
  SIGNAL: '#00917E',
  SUCCESS_NOTIFICATION: '#ddeeec',
  TRANSPARENT: 'transparent',
  UNSEEN_NOTIFICATIONS_BG: '#fff6cc',
  WATER_PRESSURE_ERROR: '#FF0048',
  WATER_PRESSURE_OK: '#00917E',
  WHITE: '#FFFFFF',
  WIDGET_FOOTER: '#294447',
};

export default colors;
