import React from 'react';

import { api, hooks, selectors } from '@vaillant-professional-ui/pui-frontend-common';

import { WaterPressurePredictionCard } from './WaterPressurePredictionCard';
import { useBoilerSerialNumberFromPath } from '../Routes';

const usePresenter = () => {
  const boilerSerialNumber = useBoilerSerialNumberFromPath();
  const { customer } = api.useCases.useCustomerDetails(boilerSerialNumber);
  const gatewaySerialNumber = customer ? selectors.customer.getGatewaySerialNumber(customer) : '';

  const predictionQuery = api.useCases.useWaterPressurePredictionChart(boilerSerialNumber, gatewaySerialNumber);

  const t = hooks.useTranslation();
  const buttonLabel = t('SG_MONITORING_DETAILS_LINK').toUpperCase();
  const pathToPredictionDetails = `../time-series`;

  return {
    ...predictionQuery,
    buttonLabel,
    pathToPredictionDetails,
  };
};

export const WaterPressurePredictionCardContainer: React.FC = () => {
  const props = usePresenter();
  return <WaterPressurePredictionCard {...props} />;
};
