import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { SelectionLinkCard } from '@vaillant-professional-ui/component-libs-web';
import { useTranslation, hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { useFirebase } from '../../../providers/firebase';
import { EmfFilterCardProps } from './EmfFilterCard.types';
import { ButtonContent } from './ButtonContent';
import { Mode } from '../../pages/ConsumptionPage/ConsumptionPage.types';
import { SelectionContent } from './SelectionContent';
import { useShouldShowOperationModeFilter } from './EmfFilterCard.hooks';
import { Icon00100VgHeating01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon00300VgDomesticHotWater01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon02000VgCooling01 } from '@vaillant-professional-ui/component-libs-web';
import { EmfDeviceSelectionDrawer } from '../EmfDeviceSelection';

export const EmfFilterCard = ({ devices, filters, setFilters }: EmfFilterCardProps) => {
  const [isDialogOpen, { on: openDialog, off: closeDialog }] = hooks.useSwitch(false);
  const t = useTranslation();
  const shouldShowOperationModeFilter = useShouldShowOperationModeFilter();
  const firebaseService = useFirebase();

  const handleOpenDialog = () => {
    firebaseService.logUserAction('emf_openSystemView');
    openDialog();
  };

  return (
    <>
      <EmfDeviceSelectionDrawer
        filters={filters}
        setFilters={setFilters}
        devices={devices}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      />
      <Box display='flex' flexDirection={['column', 'column', 'column', 'row']}>
        <Box flexGrow={2} flexBasis='60%' mr={[0, 0, 0, 8]} mb={[4, 4, 4, 0]} bgcolor='common.white'>
          <SelectionLinkCard
            title={t('SG_EMF_DEVICE_FILTER_TITLE')}
            onClick={handleOpenDialog}
            testIdKey='deviceFilter'
          >
            <SelectionContent devices={devices} filters={filters} />
          </SelectionLinkCard>
        </Box>
        <Box
          flexGrow={1}
          flexBasis='40%'
          display='flex'
          justifyContent='stretch'
          visibility={shouldShowOperationModeFilter ? 'visible' : 'hidden'}
        >
          <ToggleButtonGroup
            value={filters.selectedOperationModes}
            onChange={(_, modes: Mode[]) => {
              if (modes.length > 0) {
                setFilters({ ...filters, selectedOperationModes: modes });
              }
            }}
          >
            <ToggleButton value='HEATING' data-testid='toggle-heating'>
              <ButtonContent
                title={t('SG_EMF_FILTER_MODE_HEATING')}
                iconComponent={Icon00100VgHeating01}
                isActive={filters.selectedOperationModes.includes('HEATING')}
              />
            </ToggleButton>
            <ToggleButton value='DOMESTIC_HOT_WATER' data-testid='toggle-dhw'>
              <ButtonContent
                title={t('SG_EMF_FILTER_MODE_DHW')}
                iconComponent={Icon00300VgDomesticHotWater01}
                isActive={filters.selectedOperationModes.includes('DOMESTIC_HOT_WATER')}
              />
            </ToggleButton>
            {filters.supportedOperationModes.includes('COOLING') ? (
              <ToggleButton value='COOLING' data-testid='toggle-cooling'>
                <ButtonContent
                  title={t('SG_EMF_FILTER_MODE_COOLING')}
                  iconComponent={Icon02000VgCooling01}
                  isActive={filters.selectedOperationModes.includes('COOLING')}
                />
              </ToggleButton>
            ) : null}
          </ToggleButtonGroup>
        </Box>
      </Box>
    </>
  );
};
