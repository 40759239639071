import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';
import { Card, CardContent } from '../../molecules/Card';
import { ResponsiveBox } from './WeekdayRow.styles';

export const TickRow: React.FC<React.PropsWithChildren<unknown>> = () => {
  const t = useTranslation();
  const children = [];
  for (let i = 0; i <= 24; i++) {
    children.push(
      <span key={i} style={{ width: `${100 / 24}%` }}>
        <Typography variant='caption'>{String(i).padStart(2, '0')}</Typography>
      </span>,
    );
  }

  return (
    <div>
      <Card>
        <CardContent>
          <Box display='flex' flexDirection='row'>
            <Box width={['64px', '88px']}>
              <Typography variant='caption'>{t('VG_SCHEDULE_TABLE_TIME')}</Typography>
            </Box>
            <Box width={'100%'} display='flex' flexDirection='row'>
              <ResponsiveBox>{children}</ResponsiveBox>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
