import React from 'react';
import { config, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { useLocation } from 'react-router-dom';
import { useGetUserDetails } from '../../lib/hooks';
import { NavigationBar } from './NavigationBar';
import { NavigationBarProps } from './NavigationBar.types';

export const NavigationBarContainer = (props: Partial<NavigationBarProps>) => {
  const appConfig = config.useAppConfig();
  const location = useLocation();
  const { isTermsAccepted, user } = useGetUserDetails();
  const { logout, isDemo } = useAuthContext();

  return (
    <NavigationBar
      user={user}
      onLogout={logout}
      pathName={location.pathname}
      platformName={appConfig.distribution.platformName}
      isDemo={isDemo}
      isTermsAccepted={isTermsAccepted}
      {...props}
    />
  );
};
