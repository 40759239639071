import React from 'react';
import { SelectionBadge, Typography } from '@vaillant-professional-ui/component-libs-web';
import { useTranslation } from '@vaillant-professional-ui/pui-frontend-common';
import { SelectionContentProps } from './EmfFilterCard.types';
import { deviceTypeIntlKey } from '../EmfDeviceSelection/EmfDeviceSelection.constants';

export const SelectionContent: React.FC<SelectionContentProps> = ({ filters, devices }) => {
  const t = useTranslation();

  if (filters.selectedDevices === 'all') {
    return <Typography variant='caption' color='text.secondary' i18nKey='SG_EMF_DEVICE_FILTER_ALL_SELECTED' />;
  }

  if (!filters.selectedDevices?.length) {
    return null;
  }

  const badges = filters.selectedDevices.map((deviceId: string) => {
    const device = devices.find((_device) => _device.id === deviceId);
    if (!device) {
      return null;
    }
    return <SelectionBadge key={deviceId} title={t(deviceTypeIntlKey[device.type])} />;
  });

  return <>{badges}</>;
};
