import { Select as SelectBase, styled } from '@mui/material';
import IconBase from '../Icon';
import { InputLabel } from '@mui/material';
import { ISelectProps } from './Select.types';

export const SelectStyled = styled(SelectBase)<ISelectProps>`
  min-width: ${({ width }) => width || 200}px;
  '&:before': {
    display: none;
  }
  ,
'&:after': {
    display: none;
  }
`;

export const Icon = styled(IconBase)`
  margin-top: 4px;
`;

export const Label = styled(InputLabel)`
  transform: none !important;
  top: 7px !important;
  margin-left: 8px !important;
  z-index: 10 !important;
  background-color: transparent !important;
`;
