import React from 'react';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Dialog, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon46801VgInformation201 } from '@vaillant-professional-ui/component-libs-web';
import { ConditionalWrapper } from '@vaillant-professional-ui/component-libs-common';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { SectionHeadlineProps } from './SectionHeadline.types';

export const SectionHeadline = ({ infoText, ...rest }: SectionHeadlineProps) => {
  const [isDialogOpen, { on: openDialog, off: closeDialog }] = hooks.useSwitch(false);

  return (
    <Box mb={4} display='flex' alignItems='center'>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <ConditionalWrapper
          condition={typeof infoText === 'string'}
          wrapper={(_children) => <Typography>{_children}</Typography>}
        >
          {infoText}
        </ConditionalWrapper>
      </Dialog>
      <Typography variant='h3' letterCase='allCaps' color='grey.500' display='inline' {...rest} />
      {!!infoText && (
        <Box display='inline' ml={1}>
          <IconButton onClick={openDialog}>
            <Icon46801VgInformation201 width='24' height='24' color='primary.main' />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
