import { useAddNote, useDeleteNote } from '../../apis/maintenance/v1';
import { useUpdateNote } from '../../apis/maintenance/v1/maintenance.hooks';
import { useQueryClient } from 'react-query';
import { UseDeleteTroubleCodeNote } from './TroubleCodeNotes.types';

const useRefetchCustomerDetail = (boilerSerialNumber: string) => {
  const queryClient = useQueryClient();
  const refetch = () => queryClient.refetchQueries(['customer', boilerSerialNumber]);
  return refetch;
};

export const useAddTroubleCodeNote = (boilerSerialNumber: string, callback = () => undefined) => {
  const refetch = useRefetchCustomerDetail(boilerSerialNumber);
  const { mutate, ...rest } = useAddNote({
    onSuccess: async () => {
      await refetch();
      callback();
    },
  });

  const addNote = (dtcId: string, text: string) =>
    mutate({ serialNumber: boilerSerialNumber, notificationId: dtcId, text });
  return { addNote, ...rest };
};

export const useUpdateTroubleCodeNote = (boilerSerialNumber: string, callback = () => undefined) => {
  const refetch = useRefetchCustomerDetail(boilerSerialNumber);
  const { mutate, ...rest } = useUpdateNote({
    onSuccess: async () => {
      await refetch();
      callback();
    },
  });
  const updateNote = (serialNumber: string, noteId: string, text: string) => mutate({ serialNumber, noteId, text });
  return { updateNote, ...rest };
};

export const useDeleteTroubleCodeNote: UseDeleteTroubleCodeNote = (boilerSerialNumber, callback = () => undefined) => {
  const refetch = useRefetchCustomerDetail(boilerSerialNumber);
  const { mutate, ...rest } = useDeleteNote({
    onSuccess: async () => {
      await refetch();
      callback();
    },
  });
  const deleteNote = (serialNumber: string, noteId: string) => mutate({ serialNumber, noteId });
  return { deleteNote, ...rest };
};
