import { CardContent, Icon02301VgPlus01, Typography } from '@vaillant-professional-ui/component-libs-web';
import { ButtonBase, Stack } from '@mui/material';
import React from 'react';
import { Tab } from './MobileDashboard';
import { NewDeviceTabCard, NewDeviceTabCardContent, TabCard, TabsContainer } from './MobileDashboard.styles';

export const MobileDashboardCards = ({
  tabs,
  setActiveTab,
  openAddCustomer,
}: {
  tabs: Tab[];
  setActiveTab: (value: number) => void;
  openAddCustomer: () => void;
}) => {
  return (
    <TabsContainer>
      {tabs.map((tab, idx) => {
        const Icon = tab.icon;
        return (
          <ButtonBase onClick={() => setActiveTab(idx)} key={tab.smallText} disabled={tab.disabled}>
            <TabCard>
              <CardContent>
                <Stack alignItems={'center'} justifyContent={'center'} gap={2} pt={3}>
                  <Icon width={40} height={40} {...tab.iconProps} />
                  <Typography disabled={tab.disabled}>{`${tab.smallText} (${tab.bigText})`}</Typography>
                </Stack>
              </CardContent>
            </TabCard>
          </ButtonBase>
        );
      })}
      <ButtonBase onClick={() => openAddCustomer()} key={'new_item'}>
        <NewDeviceTabCard>
          <NewDeviceTabCardContent>
            <Stack alignItems={'center'} justifyContent={'center'} gap={2} pt={3}>
              <Icon02301VgPlus01 width={40} height={40} />
              <Typography i18nKey={'SG_ADD_DEVICE'} />
            </Stack>
          </NewDeviceTabCardContent>
        </NewDeviceTabCard>
      </ButtonBase>
    </TabsContainer>
  );
};
