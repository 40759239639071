/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon16100VgSolarCollector01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon16100vgsolarcollector01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='m264.83 412.13-66 65.19v-45.79a11.34 11.34 0 1 0-22.67 0v73.13A11.34 11.34 0 0 0 187.53 516h73.93a11.34 11.34 0 1 0 0-22.68h-46.52l65.83-65.07a11.34 11.34 0 1 0-15.94-16.12zm-124.96-125.3-66 65.2v-45.79a11.34 11.34 0 1 0-22.67 0v73.13a11.34 11.34 0 0 0 11.34 11.34h73.96a11.34 11.34 0 1 0 0-22.68H90L155.81 303a11.34 11.34 0 1 0-15.94-16.13zM388.42 107.4a7.5 7.5 0 0 0 7.5-7.51V58.63a7.51 7.51 0 1 0-15 0v41.26a7.51 7.51 0 0 0 7.5 7.51zm55.75 22.98a7.46 7.46 0 0 0 5.3-2.2L478.65 99A7.51 7.51 0 1 0 468 88.38l-29.18 29.19a7.51 7.51 0 0 0 5.31 12.81zm64.44 40.67h-41.27a7.5 7.5 0 1 0 0 15h41.27a7.5 7.5 0 1 0 0-15zM449.66 229a7.5 7.5 0 0 0-10.61 10.61l29.18 29.19a7.51 7.51 0 0 0 10.61-10.62zm-53.47 69.73v-41.27a7.51 7.51 0 0 0-15 0v41.27a7.51 7.51 0 1 0 15 0zM298.45 269a7.5 7.5 0 0 0 10.61 0l29.18-29.18a7.51 7.51 0 0 0-10.61-10.62l-29.18 29.19a7.5 7.5 0 0 0 0 10.61zm-29.96-82.68h41.27a7.51 7.51 0 1 0 0-15h-41.27a7.51 7.51 0 0 0 0 15zm58.95-57.95a7.5 7.5 0 0 0 10.61-10.61l-29.18-29.18a7.5 7.5 0 1 0-10.61 10.61zm61.11-5.97a56.28 56.28 0 1 0 56.27 56.28 56.28 56.28 0 0 0-56.27-56.28zm-240.73 28.42a11.33 11.33 0 0 0-16 0l-16.56 16.55a11.34 11.34 0 0 0 0 16L383.82 452a11.34 11.34 0 0 0 16 0l16.55-16.56a11.33 11.33 0 0 0 0-16z'
      />
    </svg>
  );
};
