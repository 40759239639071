import { i18nConfig } from '@vaillant-professional-ui/pui-frontend-common';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export const languageOptions = sortBy(
  uniqBy(i18nConfig, (record) => record.language).map((record) => ({
    value: record.language,
    label: record.language.toUpperCase(),
  })),
  (language) => language.label,
);
