import { Divider } from '@mui/material';
import { models, selectors } from '@vaillant-professional-ui/pui-frontend-common';
import React from 'react';
import { useDisableScrollPropagationOnMap } from '../../lib/hooks';
import { useFirebase } from '../../providers/firebase';
import { CustomerSummary } from '../CustomerSummary';
import { FloatingContainer, SummaryContainer } from './DeviceMap.styles';
import { useMapContext } from './MapContext';

export const FloatingCustomers: React.FC<React.PropsWithChildren<unknown>> = () => {
  useDisableScrollPropagationOnMap('floating-container');
  const firebaseService = useFirebase();

  const { customers, dtcTypes, setHighlightedCustomer, selectedCustomer, setSelectedCustomer } = useMapContext();

  React.useEffect(() => {
    if (selectedCustomer) {
      document.getElementById(`floating-customer-${selectedCustomer}`)?.scrollIntoView({ block: 'nearest' });
    }
  }, [selectedCustomer]);

  const handleHover = (boilerSerialNumber: string, isHover: boolean) => {
    setHighlightedCustomer(isHover ? boilerSerialNumber : null);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, customer: models.ICustomer) => {
    if (selectors.customer.getEndUserPosition(customer)?.latitude) {
      firebaseService.logUserAction('dashboardMapSelectCustomer', { location: 'list' });
      setSelectedCustomer(selectors.customer.getBoilerSerialNumber(customer));
    }
    e.stopPropagation();
    e.preventDefault();
  };

  return React.useMemo(
    () => (
      <FloatingContainer id='floating-container'>
        {customers.map((customer) => {
          const boilerSerialNumber = selectors.customer.getBoilerSerialNumber(customer);
          return (
            <React.Fragment key={boilerSerialNumber}>
              <SummaryContainer
                id={`floating-customer-${boilerSerialNumber}`}
                onClick={(e) => handleClick(e, customer)}
                onMouseEnter={() => handleHover(boilerSerialNumber, true)}
                onMouseOut={() => handleHover(boilerSerialNumber, false)}
                className={`floating-customer ${
                  selectedCustomer === boilerSerialNumber ? 'selected' : ''
                } customer-summary`}
              >
                <CustomerSummary dtcTypes={dtcTypes ?? []} customer={customer} style={{ pointerEvents: 'none' }} />
              </SummaryContainer>
              <Divider />
            </React.Fragment>
          );
        })}
      </FloatingContainer>
    ),
    [customers, selectedCustomer],
  );
};
