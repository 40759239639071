/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon05601VgConnectivityCommuncation01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon05601vgconnectivitycommuncation01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <circle
        cx={175.95}
        cy={391.28}
        r={34.02}
        fill='currentColor'
        className='cls-1'
        transform='rotate(-.07 163.482 360.245)'
      />
      <path
        fill='currentColor'
        d='M176 300.57a91 91 0 0 0-26 3.77 11.34 11.34 0 0 0 6.47 21.74 68 68 0 0 1 84.64 84.64 11.34 11.34 0 0 0 21.73 6.47A90.71 90.71 0 0 0 176 300.57z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M176 243.87a148.7 148.7 0 0 0-24.55 2 11.34 11.34 0 0 0 3.75 22.37 124.77 124.77 0 0 1 143.74 143.84 11.33 11.33 0 0 0 9.31 13.05 11 11 0 0 0 1.89.16 11.35 11.35 0 0 0 11.17-9.46 149.78 149.78 0 0 0 2-24.55C323.35 310 257.23 243.87 176 243.87z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M176 187.18a208.61 208.61 0 0 0-24 1.41 11.34 11.34 0 0 0 2.65 22.53 182.88 182.88 0 0 1 21.35-1.26c100 0 181.42 81.38 181.42 181.42a182.88 182.88 0 0 1-1.26 21.35 11.34 11.34 0 0 0 9.94 12.58 10.14 10.14 0 0 0 1.34.08 11.35 11.35 0 0 0 11.25-10 208.31 208.31 0 0 0 1.41-24c-.05-112.55-91.61-204.11-204.1-204.11z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M360.36 206.87A259.12 259.12 0 0 0 176 130.49c-7.75 0-15.73.36-23.69 1.08a11.34 11.34 0 1 0 2 22.59c7.3-.66 14.58-1 21.66-1a238.11 238.11 0 0 1 238.09 238.12c0 7.07-.33 14.36-1 21.66a11.35 11.35 0 0 0 10.28 12.31h1A11.34 11.34 0 0 0 435.66 415c.72-8 1.08-15.94 1.08-23.69a259.12 259.12 0 0 0-76.38-184.44z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
