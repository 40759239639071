import { Box } from '@mui/system';
import { styled } from '../../../util/styling';

export const Back = styled('button')`
  display: inline;
  border: none;
  background: none;
  padding: 0;
  padding-top: 4px;
  cursor: pointer;
`;

export const Content = styled(Box)`
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.spacing(6)};
  padding-right: ${({ theme }) => theme.spacing(6)};
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: ${({ theme }) => theme.spacing(18.5)};
    padding-right: ${({ theme }) => theme.spacing(12)};
  }
`;
