import React from 'react';
import { Box } from '@mui/material';
import { Grid, PageHeader } from '@vaillant-professional-ui/component-libs-web';
import { SettingsMenu } from './SettingsMenu';
import { SettingsPageProps } from './SettingsPage.types';

export const SettingsPage: React.FC<React.PropsWithChildren<SettingsPageProps>> = (props) => {
  const { country, currentSection, sections } = props;
  const SettingsComponent = React.useMemo(() => currentSection.component, [currentSection]);

  return (
    <Box width={'100%'}>
      <PageHeader i18nKey='SG_SETTINGS_TITLE' />
      <Grid container direction='row'>
        <Grid item xs={12} sm={4} md={2}>
          <SettingsMenu sections={sections} currentSection={currentSection} country={country} />
        </Grid>
        <Grid item xs={12} sm={8} md={10}>
          <SettingsComponent {...props} />
        </Grid>
      </Grid>
    </Box>
  );
};
