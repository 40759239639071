import { ScheduleVgComponents, SystemSchedule } from './schedules.erelax.types';
import { useUpdateSchedule, useSchedules as useScheduleBase } from '../../apis/schedules/v2/schedules.hooks';
import { getScheduleViewData, transformWeekDayPeriodsToSchedule } from './schedules.erelax.util';
import { useTranslation, useLocalizedWeekdays } from '../../../libs/hooks';
import { SchedulePayload, ScheduleResponse } from '../../apis/schedules/v2/schedules.types';
import { UpdateFnc, QueryKey, useOptimisticQueryOptions } from '../../hooks';
import { useToast, withToastMessages } from '../../../components/meta/ToastProvider';
import { AxiosError, AxiosResponse } from 'axios';
import { MutateOptions } from 'react-query';
import React from 'react';

export const useSchedules = (gatewaySerialNumber: string, options = {}) => {
  const result = useScheduleBase(gatewaySerialNumber, options);
  const { data: schedules } = result;

  const weekdays = useLocalizedWeekdays();

  const activeSchedule = schedules?.[0] as SystemSchedule;
  const viewData = activeSchedule ? getScheduleViewData(activeSchedule, weekdays) : null;

  return { ...result, viewData };
};

const useOptimisticPatchErelaxSchedule = (gatewaySerialNumber: string) => {
  const toastContext = useToast();
  const t = useTranslation();

  const updateCache: UpdateFnc<ScheduleResponse, SchedulePayload> = (payload, previousValue) => {
    const data: ScheduleResponse = [...previousValue.data];
    // @ts-ignore: We decided, not to spend hours in teaching ts to validate this line
    data[0] = { ...payload[0], _metadata: data[0]._metadata };
    const result: AxiosResponse<ScheduleResponse> = {
      ...previousValue,
      data,
    };
    return result;
  };

  const queryKey: QueryKey = ['schedules', gatewaySerialNumber];
  const options = useOptimisticQueryOptions<ScheduleResponse, SchedulePayload>(queryKey, updateCache);

  const { mutate, ...rest } = useUpdateSchedule(
    gatewaySerialNumber,
    withToastMessages(
      options,
      {
        onError: {
          title: t('SG_TOAST_ERROR_MSG'),
          message: t('SG_TOAST_HEATING_SCHEDULE_FAILED'),
        },
        onSuccess: { message: t('SG_TOAST_HEATING_SCHEDULE_SUCCESS') },
      },
      toastContext,
    ),
  );

  const mutateErelax = (
    data: NonNullable<SchedulePayload>,
    options?: MutateOptions<AxiosResponse<ScheduleResponse>, AxiosError, SchedulePayload>,
  ) => {
    mutate(data, options);
  };

  return { mutate: mutateErelax, ...rest };
};

export const usePatchErelaxSchedule = (gatewaySerialNumber: string) => {
  const { mutate } = useOptimisticPatchErelaxSchedule(gatewaySerialNumber);
  const save = React.useCallback(
    (scheduleVgComponents: ScheduleVgComponents[]) => {
      const scheduleErelax = transformWeekDayPeriodsToSchedule(scheduleVgComponents, gatewaySerialNumber);
      mutate(scheduleErelax);
    },
    [mutate],
  );
  return save;
};
