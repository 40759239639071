/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon47001VgExclamationMark201: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon47001vgexclamationmark201', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='m293.9 334.49 7.35-60.27V168.66h-23.58c-7.35 0-11.6 3.87-11.6 11.6v94l7 60.27zm-10.44 20.87c-11.59 0-21.25 10.05-21.25 21.65a21.26 21.26 0 1 0 42.51 0c0-11.6-9.66-21.65-21.26-21.65z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.46 102.05c-100 0-181.41 81.38-181.41 181.41s81.38 181.42 181.41 181.42 181.42-81.38 181.42-181.42-81.38-181.41-181.42-181.41zm0 340.15c-87.53 0-158.74-71.21-158.74-158.74s71.21-158.74 158.74-158.74S442.2 195.94 442.2 283.46 371 442.2 283.46 442.2z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
