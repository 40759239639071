import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { SelectionLinkCardProps } from './SelectionLinkCard.types';
import { Container, Link } from './SelectionLinkCard.styles';
import { Box } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { Icon08201VgRight01 } from '../../atoms/Icon/Icon08201VgRight01';

export const SelectionLinkCard: React.FunctionComponent<SelectionLinkCardProps> = ({
  testIdKey,
  title,
  children,
  ...rest
}) => {
  const testId = useInstanceId('selectionlinkcard', testIdKey);

  return (
    <Link {...rest} {...testId('root').testIdAttributes}>
      <Container>
        <Box display='flex' flexGrow={1} flexWrap='wrap' alignItems='center'>
          <Box display='flex' flexGrow={1} my={1} mr={4}>
            <Typography variant='h3'>{title}</Typography>
          </Box>
          <Box display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
            {children}
          </Box>
        </Box>
        <Icon08201VgRight01 color='primary.main' width={32} height={32} />
      </Container>
    </Link>
  );
};
