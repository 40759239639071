import React from 'react';

const isTesting = process.env.NODE_ENV === 'test';

export const makeLabelId = (label: string = '') =>
  `label-${label.replace(/ /g, '').toLowerCase()}-${Math.floor(Math.random() * 1000000000)}
  )}`;

// Prevent snapshots to fail repeatedly
export const makeLabelIdTesting = (label: string = '') => `label-${label.replace(/ /g, '').toLowerCase()})}`;

export const useLabelId = (label: string = '') => {
  const generator = isTesting ? makeLabelIdTesting : makeLabelId;
  const [generatedLabel] = React.useState<string>(generator(label));
  return generatedLabel;
};

export const sizeToFontMap = {
  normal: 'body',
  big: 'h3',
};
