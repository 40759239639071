import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Dialog, DialogProps, useTheme } from '@vaillant-professional-ui/component-libs-web';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import AddCustomerContainer from './AddCustomer.container';
import { IAddCustomerContainerProps } from './AddCustomer.types';
import { BaseProps } from '../../lib/types';

interface AddCustomerDialogProps extends BaseProps, Omit<IAddCustomerContainerProps, 'closeModal'> {
  dialogProps?: Omit<DialogProps, 'open'>;
}

export const AddCustomerDialog: React.FC<React.PropsWithChildren<AddCustomerDialogProps>> = ({
  dialogProps = {},
  ...rest
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const t = hooks.useTranslation();

  return (
    <Dialog
      fullWidth={true}
      fullScreen={isMobile}
      maxWidth={isMobile ? undefined : 'md'}
      title={t('SG_ADD_DEVICE')}
      scroll='paper'
      open={true}
      testIdKey='add-customer'
      {...dialogProps}
    >
      <AddCustomerContainer closeModal={() => dialogProps.onClose?.()} {...rest} />
    </Dialog>
  );
};
