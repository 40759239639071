import React from 'react';
import { Chip, ChipProps, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon46801VgInformation201 } from '@vaillant-professional-ui/component-libs-web';
import { selectors, util } from '@vaillant-professional-ui/pui-frontend-common';
import { CustomerSummaryProps } from './CustomerSummary.types';
import { TitleBox, NoGeolocation } from './CustomerSummary.styles';
import { Box } from '@mui/system';

const {
  customer: {
    getEndUserPosition,
    getEndUserFullName,
    getBoilerNomenclature,
    getBoilerSerialNumber,
    getActiveTroubleCodes,
  },
} = selectors;

const isPropsEqual = (prevProps: CustomerSummaryProps, nextProps: CustomerSummaryProps): boolean => {
  return prevProps.customer === nextProps.customer && prevProps.dtcTypes === nextProps.dtcTypes;
};

export const CustomerSummary: React.FC<React.PropsWithChildren<CustomerSummaryProps>> = React.memo(
  function CustomerSummary({ customer, dtcTypes, ...rest }: CustomerSummaryProps) {
    const activeDtcs = getActiveTroubleCodes(customer).filter((dtc) => (dtcTypes ? dtcTypes.includes(dtc.type) : true));
    const dtc = activeDtcs.length ? activeDtcs[0] : null;
    return (
      <section {...rest}>
        <Typography variant='body' style={{ fontWeight: 'bold' }}>
          {getEndUserFullName(customer) || getBoilerSerialNumber(customer)}
        </Typography>
        <TitleBox>
          <Typography color='text.secondary'>{getBoilerNomenclature(customer)}</Typography>
        </TitleBox>
        {dtc ? (
          <>
            <Box mb={2} mt={4}>
              <Chip
                label={util.troubleCodes.troubleCodeInsightCodeToString(dtc)}
                color={util.troubleCodes.troubleCodeInsightCodeToColorType(dtc) as ChipProps['color']}
                size='small'
              />
            </Box>
            <Typography variant='caption'>{dtc.title}</Typography>
          </>
        ) : null}
        {!getEndUserPosition(customer)?.latitude && (
          <NoGeolocation>
            <Icon46801VgInformation201 width={24} height={24} />
            <Typography i18nKey='SG_NO_GEOLOCATION_AVAILABLE' variant='caption' />
          </NoGeolocation>
        )}
      </section>
    );
  },
  isPropsEqual,
);
