import { Stack } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon08201VgRight01 } from '@vaillant-professional-ui/component-libs-web';
import { selectors, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { LinkStyled } from './CustomerDetailBreadcrumbs.styled';
import { CustomerDetailBreadcrumbsProps } from './CustomerDetailBreadcrumbs.types';

/* @deprecated Use CustomerDetailBreadcrumbsContainer instead */
export const CustomerDetailBreadcrumbs: FC<CustomerDetailBreadcrumbsProps> = ({ customer }) => {
  const { isDelegated } = useAuthContext();
  const customerName = selectors.customer.getEndUserFullName(customer);
  const boilerSerialNumber = selectors.customer.getBoilerSerialNumber(customer);

  return (
    <Stack direction='row' spacing={1} alignItems='center' mb={2}>
      {!isDelegated && (
        <>
          <LinkStyled
            // @ts-ignore ?
            to='/'
            as={Link}
            data-testid='breadcrumb-home'
          >
            <Typography variant='caption' color='primary.main' i18nKey='SG_HOME' />
          </LinkStyled>
          <Icon08201VgRight01 width={24} height={24} />
        </>
      )}

      <LinkStyled
        // @ts-ignore ?
        to={`/customers/${boilerSerialNumber}`}
        as={Link}
        data-testid='breadcrumb-customer-detail'
      >
        <Typography variant='caption' color='primary.main'>
          {customerName}
        </Typography>
      </LinkStyled>
      <Icon08201VgRight01 width={24} height={24} />
    </Stack>
  );
};
