import * as React from 'react';
import { Box, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Icon08201VgRight01 } from '../../atoms/Icon/Icon08201VgRight01';
import { Typography } from '../../atoms/Typography';
import { Card, Chip, Container, IconSpacer } from './DataCard.styles';
import { DataCardSkeletonProps } from './DataCard.types';

export const DataCardSkeleton: React.FC<DataCardSkeletonProps> = ({
  variant,
  icon,
  subtitle,
  testIdKey,

  isHighlighted,
  isLink,
  hasSubline,
  hasBadge,
  ...rest
}) => {
  const testId = useInstanceId('datacard-skeleton', testIdKey);
  const titleMarginLeft = !!icon || variant === 'plain' ? 0 : { xs: 2, lg: 5 };

  return (
    <Container {...rest} $disabled={true} $isLink={false} {...testId('root').testIdAttributes}>
      <Card variant={variant} $isHighlighted={isHighlighted} {...testId('root').testIdAttributes} {...rest}>
        <Stack display='flex' flex={1}>
          {hasSubline && (
            <Stack direction='row'>
              {!!icon && <IconSpacer />}
              <Box ml={titleMarginLeft}>
                <Typography variant='smallCopy'>
                  <Skeleton variant='text' animation='wave' width={100}></Skeleton>
                </Typography>
              </Box>
            </Stack>
          )}
          <Stack direction='row' alignItems='center' display='flex' flex={1}>
            {!!icon && (
              <IconSpacer {...testId('icon').testIdAttributes}>
                <Skeleton variant='circular' animation='wave' width={32} height={32} />
              </IconSpacer>
            )}
            <Box display='flex' flexDirection='row' alignItems='center' flexWrap='wrap' ml={!!icon ? 0 : 5} flex={1}>
              <Stack mr={hasBadge ? 4 : 0}>
                <Skeleton variant='text' animation='wave'>
                  <Typography variant={'h4'}>This is some demo text</Typography>
                </Skeleton>
              </Stack>
              {!!hasBadge && (
                <Skeleton variant='rounded' width={80}>
                  <Chip color='primary' label='...' disabled={true} />
                </Skeleton>
              )}
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              flexWrap='wrap'
              ml={!!icon ? 0 : 5}
              flexGrow={0}
            >
              {(!!subtitle || isLink) && (
                <Box mx={2} display='flex' alignItems='center' justifyContent='center' flexWrap='nowrap'>
                  {!!subtitle && (
                    <Skeleton variant='text' animation='wave' width={60}>
                      <Typography variant='smallCopy'>...</Typography>
                    </Skeleton>
                  )}
                  {isLink && (
                    <Box width={32} minWidth={32} display='flex' alignItems='center' justifyContent='center'>
                      <Icon08201VgRight01 color='text.disabled' width={32} height={32} />
                    </Box>
                  )}
                </Box>
              )}
            </Box>{' '}
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
};
