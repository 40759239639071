import { AxiosResponse, AxiosError } from 'axios';
import { UseMutationOptions } from 'react-query';
import { ReactQueryAxiosContext } from '../../../api/hooks';
import { ToastContextInterface } from './ToastProvider.types';

interface ToastMessages {
  onError: { title: string; message: string };
  onSuccess: { message: string };
}
export const withToastMessages = <TMutated, TVariables, TResponseData = TMutated>(
  options: UseMutationOptions<
    AxiosResponse<TResponseData>,
    AxiosError<TResponseData>,
    TVariables,
    ReactQueryAxiosContext<TMutated>
  >,
  toastMessages: ToastMessages,
  toastContext: ToastContextInterface,
): UseMutationOptions<
  AxiosResponse<TResponseData>,
  AxiosError<TResponseData>,
  TVariables,
  ReactQueryAxiosContext<TMutated>
> => {
  return {
    ...options,
    onError: (...params) => {
      void options.onError?.(...params);
      if (toastMessages?.onError) {
        toastContext.showToast('warning', toastMessages.onError.message, toastMessages.onError.title);
      }
    },
    onSuccess: (...params) => {
      void options.onSuccess?.(...params);
      if (toastMessages?.onSuccess) {
        toastContext.showToast('success', toastMessages.onSuccess.message);
      }
    },
  };
};
