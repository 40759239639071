import React from 'react';

import { TableCell, TableRow, TableSortLabel, Typography, styled } from '@mui/material';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { useNavigateWithCommandClick } from '../../lib/hooks';
import { SortIcon } from '../atoms/SortIcon';
import { VirtualizedTable } from '../organisms/VirtualizedTable';
import { CustomerTableProps } from './CustomerTable.types';
import { getColumns } from './CustomerTable.utils';
import { CustomerTableRow } from './CustomerTableRow';

export const CustomerTable: React.FC<CustomerTableProps> = ({ height, sorting, hasNoSearchResult, query }) => {
  const navigate = useNavigateWithCommandClick();
  const handleRowClick = (event: React.MouseEvent, id: string) => {
    navigate(event, `/customers/${id}`);
  };
  const t = hooks.useTranslation();
  const columns = getColumns(t);

  return (
    <VirtualizedTable
      onClickRow={handleRowClick}
      data={sorting.sortedData}
      style={{ height, minHeight: 200 }}
      fixedHeaderContent={() => (
        <TableRow>
          {columns.map((column, index) => (
            <TableCell key={column.key} align={column.align}>
              <TableSortLabel
                disabled={!column.sort}
                hideSortIcon={!column.sort}
                active={sorting.isSortingActive(index)}
                direction={sorting.direction(index)}
                onClick={sorting.onClickHandler(index)}
                IconComponent={SortIcon}
                sx={{ textOverflow: 'ellipsis' }}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      )}
      itemContent={(_, data) => <CustomerTableRow markText={query} item={data} key={data.id} />}
      fixedFooterContent={() => (
        <>
          {hasNoSearchResult ? (
            <FooterTableCell colSpan={columns.length} align='center'>
              <Typography color='grey.500' paddingY={9}>
                {t('SG_CUSTOMER_LIST_NO_CUSTOMER_FOUND')}
              </Typography>
            </FooterTableCell>
          ) : null}
        </>
      )}
    />
  );
};

const FooterTableCell = styled(TableCell)`
  border: 0;
`;
