/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon00500VgBurner01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon00500vgburner01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M373.1 476.32H203a11.34 11.34 0 1 0 0 22.68h170.1a11.34 11.34 0 0 0 0-22.67zm-131.36-13.06a11.33 11.33 0 0 0 5.36-12.81C222 357.4 293.9 310.57 299.57 221c0 0 69.81 97.77 37.13 213.13a11.34 11.34 0 0 0 20.54 9c78-122.56-30.69-310-77.77-380.69a11.34 11.34 0 0 0-20.77 5.77c-1.91 42.57-8 114.49-27.12 153-25.4 51-83.58 157.31-3.58 240.06a11.35 11.35 0 0 0 13.74 1.99z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
