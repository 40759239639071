import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon08201VgRight01 } from '@vaillant-professional-ui/component-libs-web';
import Link from '../../Link';
import { DetailsLinkProps } from './DetailsLink.types';

export const DetailsLink: React.FC<DetailsLinkProps> = ({ label, ...rest }) => {
  return (
    <Link style={{ textDecoration: 'none' }} {...rest}>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Typography color='primary.main' component='span' mr={2} letterCase='allCaps'>
          {label}
        </Typography>
        <Icon08201VgRight01 color='primary.main' />
      </Box>
    </Link>
  );
};
