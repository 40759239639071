import { Typography, styled } from '@vaillant-professional-ui/component-libs-web';

export const styles = {
  virtualizedTable: { height: 500 },
  card: {
    paddingLeft: 0,
    paddingRight: 0,
  },
};

export const TypographyStyled = styled(Typography)`
  line-break: anywhere;
`;
