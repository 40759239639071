import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useRef } from 'react';
import { useKeyPress } from '../../hooks/useKeyPress';
import { Icon07100VgSearch01, Icon45101VgClose201 } from '@vaillant-professional-ui/component-libs-web';

export type SearchTextFieldProps = { query: string; setQuery: (newQuery: string) => void } & Omit<
  TextFieldProps,
  'value' | 'onChange'
>;

export const SearchTextField = ({ query, setQuery, ...restProps }: SearchTextFieldProps) => {
  const input = useRef<HTMLInputElement>();

  useKeyPress('s', () => {
    input.current?.focus();
  });

  return (
    <TextField
      variant='outlined'
      size='small'
      value={query}
      inputRef={input}
      onChange={(e) => setQuery(e.target.value)}
      InputProps={{
        sx: { bgcolor: 'common.white' },
        startAdornment: (
          <InputAdornment position='start'>
            <Icon07100VgSearch01 />
          </InputAdornment>
        ),
        endAdornment: query ? (
          <InputAdornment position='end'>
            <IconButton onClick={() => setQuery('')}>
              <Icon45101VgClose201 width={16} height={16} color='grey.800' />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
      {...restProps}
    />
  );
};
