import { ConnectivitySolution, ControlType, ICustomer, ICustomerDetail, TroubleCodes } from '../../typings/models';

export const transformCustomerToCustomerDetail = (customer: ICustomer): ICustomerDetail => {
  const troubleCodes: TroubleCodes[] = customer.system.boiler.activeTroubleCodes;
  const connectivitySolution: ConnectivitySolution = customer.system.connectivitySolution;
  const control: { type: ControlType } | undefined = customer.system.control ? { type: 'TLI' } : undefined;
  const customerAddress = customer.customer;
  const system = {
    systemId: customer.system.systemId,
    boiler: customer.system.boiler,
    gateway: customer.system.gateway,
    control: customer.system.control,
  };
  const status = customer.status;

  return {
    troubleCodes,
    connectivitySolution,
    control,
    customer: customerAddress,
    system,
    status,
  };
};
