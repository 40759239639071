import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { StatusPanel } from '../../molecules/StatusPanel';
import { Card, CardContent } from './StatusCard.styled';
import { StatusCardProps } from './StatusCard.types';

export const StatusCard: React.FunctionComponent<StatusCardProps> = ({ testIdKey, ...rest }) => {
  const testId = useInstanceId('statuscard', testIdKey);

  return (
    <Card>
      <CardContent>
        <StatusPanel {...rest} testIdKey={testId().key} />
      </CardContent>
    </Card>
  );
};
