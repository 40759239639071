import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { hooks, useAuthContext } from '@vaillant-professional-ui/pui-frontend-common';
import StatusPage from '../StatusPage';
import { Button, Typography } from '@vaillant-professional-ui/component-libs-web';

export const NotFoundPage = () => {
  const t = hooks.useTranslation();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  const goAway = () => {
    navigate(isAuthenticated ? '/dashboard' : '/');
  };

  return (
    <Box display='flex' width='100%' height={['30vh', '30vh', '60vh']} alignItems='center' justifyContent='center'>
      <StatusPage type='error' title={t('SG_PAGE_NOT_FOUND_TITLE')}>
        <Box mt={-12} mb={10}>
          <Typography i18nKey='SG_PAGE_NOT_FOUND_DESCRIPTION' />
        </Box>{' '}
        <Button
          variant='contained'
          color='secondary'
          i18nKey={isAuthenticated ? 'SG_GO_TO_DASHBOARD' : 'SG_COUNTRY_SELECTION_BUTTON'}
          onClick={goAway}
        />
      </StatusPage>
    </Box>
  );
};
