import React from 'react';

import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Grid, PageHeader, Typography } from '@vaillant-professional-ui/component-libs-web';
import { hooks, util } from '@vaillant-professional-ui/pui-frontend-common';
import { useAppConfig } from '@vaillant-professional-ui/pui-frontend-common/src/config';
import { useGetUserDetails } from '../../lib/hooks';
import { AddCustomerButton } from '../AddCustomer/AddCustomerButton';
import { PermissionGate } from '../PermissionGate';
import { DashboardProps } from './Dashboard.types';
import { MapToggle } from './MapToggle';
import { Box } from '@mui/material';

export const Dashboard: React.FC<React.PropsWithChildren<DashboardProps>> = ({
  viewMode,
  setViewMode,
  content,
  shouldShowNotificationBars,
  shouldShowMapToggle,
  shouldShowAddDeviceButton,
  systemsWaitingForActionCount,
}) => {
  const t = hooks.useTranslation();
  const { user } = useGetUserDetails();
  const {
    distribution: { platformName },
  } = useAppConfig();
  const testId = useInstanceId('page', 'root');

  return (
    <Box
      width={'100%'}
      display='flex'
      alignItems='stretch'
      flexDirection='column'
      pb={10}
      {...testId('container').testIdAttributes}
    >
      <PageHeader
        testIdKey='dashboard'
        title={util.user.getGreetingByTime(t, platformName, user)}
        subtitle={
          <Grid alignItems='center' justifyContent='space-between' container>
            <Box>
              {shouldShowNotificationBars ? (
                <Typography
                  variant='h3'
                  i18nKey='SG_DASHBOARD_WAITING_SYSTEMS'
                  i18nOptions={{ count: <strong>{systemsWaitingForActionCount}</strong> }}
                />
              ) : null}
            </Box>
            {shouldShowMapToggle && (
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <MapToggle viewMode={viewMode} setViewMode={setViewMode} />
              </Box>
            )}
          </Grid>
        }
        rightComponent={
          shouldShowAddDeviceButton ? (
            <PermissionGate requiredPermissions={['ADD_CUSTOMER']}>
              <Box mx={5} my='auto' display={{ xs: 'none', sm: 'block' }}>
                <AddCustomerButton testId='addDevice_dashboard' />
              </Box>
            </PermissionGate>
          ) : null
        }
      />
      {content}
    </Box>
  );
};
