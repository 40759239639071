import * as React from 'react';
import { Box } from '@mui/material';
import BaseDatePicker, { registerLocale } from 'react-datepicker';
import { useConfiguration, useInstanceId, localeMap } from '@vaillant-professional-ui/component-libs-common';

import { Wrapper, StyledCalendarContainer } from './DatePicker.styled';
import { DatePickerProps } from './DatePicker.types';

import { Button } from '../../atoms/Button';
import 'react-datepicker/dist/react-datepicker.css';

Object.keys(localeMap).forEach((locale) => {
  registerLocale(locale, localeMap[locale].dateFns);
});

export const DatePicker: React.FunctionComponent<DatePickerProps> = React.forwardRef<BaseDatePicker, DatePickerProps>(
  function NamedDatePicker({ testIdKey, onClickSubmit, onClickCancel, ...rest }, datePickerRef) {
    const { locale } = useConfiguration();

    const testId = useInstanceId('DatePicker', testIdKey);

    // Prevent outside click
    // https://github.com/Hacker0x01/react-datepicker/issues/3900
    React.useEffect(() => {
      // @ts-ignore not sure why it complains
      if (datePickerRef?.current) {
        // @ts-ignore It's just not documented
        datePickerRef.current.handleCalendarClickOutside = () => {};
      }
    }, [datePickerRef]);

    return (
      <BaseDatePicker
        ref={datePickerRef}
        calendarContainer={({ children }: React.PropsWithChildren) => (
          <StyledCalendarContainer>
            <Wrapper {...testId('container').testIdAttributes}>
              {children}
              <Box display='flex' justifyContent='center' mt={4}>
                <Button
                  variant='text'
                  i18nKey='VG_DATE_RANGE_PICKER_CANCEL'
                  onClick={onClickCancel}
                  {...testId('cancel').testIdAttributes}
                />
                <Button
                  variant='contained'
                  i18nKey='VG_DATE_RANGE_PICKER_SUBMIT'
                  onClick={onClickSubmit}
                  {...testId('submit').testIdAttributes}
                />
              </Box>
            </Wrapper>
          </StyledCalendarContainer>
        )}
        locale={locale}
        {...rest}
      />
    );
  },
);
