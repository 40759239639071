import { api, State, store } from '../../../index';
import React from 'react';
import { useSelector } from 'react-redux';
import { ICustomer } from '../../typings/models';

export const useCase = () => {
  const { userContactId } = api.useCases.useGetUserInfo();
  const { data = [], isFetching: isLoading } = api.maintenance.v1.useCustomerList();

  const [_customerToResendConsent, setCustomerToResendConsent] = React.useState<ICustomer | null>(null);

  const findCustomerByBoilerSerialNumber = (serialNumber: string) =>
    data.find((item) => item.system.boiler.serialNumber === serialNumber) || null;

  const customerToResendConsent = _customerToResendConsent
    ? findCustomerByBoilerSerialNumber(_customerToResendConsent.system.boiler.serialNumber)
    : null;

  const shouldShowActivitySpinner = isLoading && data.length === 0;
  const shouldShowNoDevices = data.length === 0;

  const readNotifications = useSelector<State, string[]>((state) =>
    store.getReadNotifications(state, userContactId || ''),
  );

  return {
    isLoading,
    customerToResendConsent,
    data,
    setCustomerToResendConsent,
    shouldShowActivitySpinner,
    shouldShowNoDevices,
    readNotifications,
  };
};
