import { api } from '../../../index';

/**
 * Hook for performing notes operations. Soon will be legacy (we are getting rid of this functionality).
 * @param boilerSerialNumber
 */
export const useNotes = ({ boilerSerialNumber }: { boilerSerialNumber: string }) => {
  const { isLoading: isAddTroublePending, addNote } = api.useCases.useAddTroubleCodeNote(boilerSerialNumber);
  const { isLoading: isDeleteTroublePending, deleteNote } = api.useCases.useDeleteTroubleCodeNote(boilerSerialNumber);
  const { isLoading: isUpdateTroublePending, updateNote } = api.useCases.useUpdateTroubleCodeNote(boilerSerialNumber);

  return {
    isAddTroublePending,
    isDeleteTroublePending,
    isUpdateTroublePending,
    addNote,
    deleteNote,
    updateNote,
  };
};
