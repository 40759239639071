import React from 'react';

import { TableCell } from '@mui/material';
import { Chip, Typography } from '@vaillant-professional-ui/component-libs-web';
import { hooks, models, store } from '@vaillant-professional-ui/pui-frontend-common';
import { useSelector } from 'react-redux';
import { AddressCell } from '../AddressCell';
import { DeviceCell } from '../DeviceCell';
import { TypographyStyled } from './LoyaltyRegistrationTable.styles';

interface LoyaltyRegistrationTableRowProps {
  loyaltyRegistration: models.LoyaltyRegistration;
  openAddCustomer: () => void;
}

export const LoyaltyRegistrationTableRow: React.FC<LoyaltyRegistrationTableRowProps> = ({
  loyaltyRegistration,
  openAddCustomer,
}) => {
  const df = hooks.useDateFormatter();

  const serialNumber = loyaltyRegistration.serialNumber;
  const boiler = loyaltyRegistration.product;
  const address = loyaltyRegistration.address;
  const country = useSelector(store.getCountry);
  const shouldFormatAddressUK = country === 'GB';
  const registeredAt = loyaltyRegistration.registeredAt ? df(new Date(loyaltyRegistration.registeredAt)) : '';

  return (
    <>
      <TableCell>
        <TypographyStyled>{serialNumber}</TypographyStyled>
      </TableCell>
      <TableCell>{boiler ? <DeviceCell device={boiler} /> : null}</TableCell>
      <TableCell>
        {address ? <AddressCell address={address} formatAddressUK={shouldFormatAddressUK} /> : null}
      </TableCell>
      <TableCell>
        <Typography>{registeredAt}</Typography>
      </TableCell>
      <TableCell>
        <Chip clickable={true} color='primary' i18nKey='SG_REQUEST_CONSENT' onClick={() => openAddCustomer()} />
      </TableCell>
    </>
  );
};
