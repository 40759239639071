import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { util } from '@vaillant-professional-ui/pui-frontend-common';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { useFirebase } from '../../providers/firebase';

import Button from '../Button';

interface EnterEmailProps {
  value: string;
  onCancel: () => void;
  onChange: (value: string) => void;
  onSubmit: (email: string) => void;
}

export const EnterEmail: React.FC<React.PropsWithChildren<EnterEmailProps>> = ({
  value,
  onChange,
  onCancel,
  onSubmit,
}) => {
  const firebaseService = useFirebase();
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (value === '' && event.target.value !== '') {
      firebaseService.logUserAction('customerEmailChange');
    }
    onChange(event.target.value);
  };

  const handleFocus = () => {
    firebaseService.logUserAction('addDeviceFocusEmailAddress');
  };

  return (
    <Box mb={4} mt={4}>
      <Box mb={2}>
        <Typography variant='caption' i18nKey={'SG_ADDCUSTOMER_NEW_EMAIL'} color='initial' />
      </Box>
      <Box mb={6}>
        <TextField
          variant='standard'
          onFocus={handleFocus}
          value={value}
          fullWidth={true}
          inputProps={{
            'data-testid': 'customerEmailInput',
          }}
          onChange={handleEmailChange}
        />
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <Box mr={2}>
          <Button
            color='primary'
            variant='outlined'
            i18nKey='SG_CANCEL'
            onClick={onCancel}
            testId='addDeviceOtherSerialNumberBtn'
          />
        </Box>
        <Button
          color='primary'
          variant='contained'
          i18nKey='SG_CONTINUE'
          disabled={!util.Validators.isEmailValid(value)}
          onClick={() => onSubmit(value)}
          testId='addCustomer'
        />
      </Box>
    </Box>
  );
};
