import { AxiosError } from 'axios';

import { UseQueryResult } from 'react-query';

import { DeviceDetailsResponseTli } from '../../../../api/apis/maintenance/v2';
import { useTli } from '../../../../api/apis/maintenance/v2/maintenance.hooks';
import { QueryKey } from '../../../../api/hooks';
import { useTranslation } from '../../../../libs/hooks';
import { mapResponseToDataModel } from './mapping';
import { controlSettingsMutationFactory } from './mutation';
import { BooleanDataPoint, EnumDataPoint, NumericDataPoint } from '../../deviceSettings/useCase/model/dataPoint';
import { UseMutation } from '../../deviceSettings/presenter/writeDatapointPresenter/writeDatapointPresentationData';
import { DeviceSettings } from '../../deviceSettings/useCase';

export const useTliDeviceSettingsAndMutations = (
  systemId: string,
  deviceId?: string,
): {
  controlSettings: DeviceSettings | null;
  createUseMutation: (dataPoint: NumericDataPoint | EnumDataPoint | BooleanDataPoint) => UseMutation;
  refetch: () => void;
} & Omit<UseQueryResult<DeviceDetailsResponseTli, AxiosError>, 'refetch' | 'data'> & { queryKey: QueryKey } => {
  const {
    data: tliData,
    refetch: refetchBase,
    ...query
  } = useTli(
    { systemId, deviceId: deviceId ?? '' /* caught by enabled */ },
    {
      staleTime: 1000 * 60 * 15,
      enabled: !!deviceId,
    },
  );
  const t = useTranslation();

  const controlSettings = tliData ? mapResponseToDataModel(tliData, t) : null;

  const refetch = () => {
    if (deviceId) {
      void refetchBase();
    }
  };

  const createUseMutation = controlSettingsMutationFactory(query.queryKey);

  return { controlSettings, createUseMutation, ...query, refetch };
};
