/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon03000VgStorage01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon03000vgstorage01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M380.05 96.47H187.29a22.69 22.69 0 0 0-22.68 22.68v330.24a21.26 21.26 0 0 0 21.27 21.26h195.58a21.26 21.26 0 0 0 21.26-21.26V119.15a22.68 22.68 0 0 0-22.67-22.68zm0 22.68v28.9c-6 1.13-11.48 3.85-16.41 6.31-4.4 2.2-8.95 4.48-11.94 4.48s-7.54-2.28-11.94-4.48c-6.43-3.21-13.73-6.86-22.08-6.86s-15.64 3.65-22.08 6.86c-4.39 2.2-8.94 4.48-11.93 4.48s-7.54-2.28-11.94-4.48c-6.44-3.21-13.73-6.86-22.08-6.86s-15.64 3.64-22.08 6.86c-4.39 2.2-8.94 4.48-11.93 4.48s-7.54-2.28-11.94-4.48c-4.94-2.46-10.38-5.18-16.41-6.31v-28.9zM187.29 448v-39.72h45.35v-28.34h-45.35v-51h45.35v-28.37h-45.35v-51h45.35V221.2h-45.35v-49.52c2 .84 4.15 1.91 6.27 3 6.43 3.22 13.73 6.86 22.08 6.86s15.64-3.65 22.08-6.86c4.39-2.2 8.94-4.48 11.93-4.48s7.54 2.28 11.94 4.48c6.44 3.22 13.73 6.86 22.08 6.86s15.64-3.65 22.08-6.86c4.39-2.2 8.94-4.48 11.93-4.48s7.54 2.28 11.94 4.48c6.44 3.22 13.73 6.86 22.08 6.86s15.64-3.65 22.08-6.86c2.12-1.06 4.27-2.13 6.27-3V448z'
      />
    </svg>
  );
};
