import { Theme } from '@mui/material';
import { TranslateFnc, util } from '@vaillant-professional-ui/component-libs-common';
import { isNil } from 'lodash';

export const getTimeWindowDisplayDataFactory = (
  t: TranslateFnc,
  timeWindow: util.TimeWindow,
  theme: Theme,
  temperatureUnit: string | undefined,
  profiles: util.ErelaxProfile[] | undefined,
  stepSize?: number,
) => {
  const temperatureTarget = util.isTimeWindowWithTargetTemperature(timeWindow)
    ? timeWindow.temperatureTarget
    : util.isTimeWindowWithProfile(timeWindow)
    ? timeWindow.profile.targetTemperature
    : undefined;

  let tooltipText = '';
  let label = '';
  let colors: Pick<util.ColorRecord, 'color' | 'textColor'>;
  if (util.isTimeWindowWithProfile(timeWindow) && profiles?.length) {
    const profile = profiles.find((p) => p.id === timeWindow.profile.id) as util.ErelaxProfile;
    const profileColors = util.getErelaxProfileColor(profile);
    tooltipText = util.getErelaxProfileTitle(profile, t);
    colors = {
      color: profileColors.bgColor,
      textColor: profileColors.fgColor,
    };
  } else {
    tooltipText = `${formatTimeFromMinutesSinceMidnight(timeWindow.start)} - ${formatTimeFromMinutesSinceMidnight(
      timeWindow.end,
    )} ${getTemperatureLabel(temperatureTarget, temperatureUnit, stepSize)}`;
    label = getTemperatureLabel(temperatureTarget, temperatureUnit, stepSize);
    colors = getColorsForWeekdayTemperature(temperatureTarget, theme);
  }

  return { tooltipText, colors, label };
};

export const formatTimeFromMinutesSinceMidnight = util.formatTimeFromMinutesSinceMidnight;

export const getColorsForWeekdayTemperature = (temperature: number | undefined, theme: Theme) => {
  return temperature !== undefined && temperature !== null
    ? util.getColorsForTemperature(temperature)
    : { color: theme.palette.grey[500], textColor: theme.palette.grey[500] };
};

export const roundToGivenStep = (num: number, stepSize: number) => {
  const decimalPlaces = (stepSize.toString().split('.')[1] || []).length;
  return parseFloat((Math.round(num / stepSize) * stepSize).toFixed(decimalPlaces));
};

export const getTemperatureLabel = (temperature: number | undefined, unit: string | undefined, stepSize?: number) => {
  if (unit && temperature !== undefined && !isNil(stepSize)) {
    return `${roundToGivenStep(temperature, stepSize)}${unit}`;
  }
  return '';
};
