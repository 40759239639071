import { ICustomerDetail } from '../../typings/models';
import { useDispatch, useSelector } from 'react-redux';
import { api, store } from '../../../index';
import { getActiveTroubleCodes } from '../../selectors/customer';
import { useEffect } from 'react';

export const useCustomerTroubleCodeNotificationStatus = ({ customer }: { customer: ICustomerDetail | null }) => {
  const dispatch = useDispatch();
  const { userContactId } = api.useCases.useGetUserInfo();

  const activeDtc = useSelector(() => (customer ? getActiveTroubleCodes(customer) : []));

  useEffect(() => {
    if (customer && userContactId) {
      activeDtc.map((dtc) => dispatch(store.setNotificationRead(dtc.id, userContactId)));
    }
  }, [activeDtc, customer, dispatch, userContactId]);

  return { userContactId };
};
