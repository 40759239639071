import { SettingsLanguage } from './SettingsLanguage';

export const createSettingsSections = () => {
  return {
    language: {
      key: 'language',
      i18nKey: 'SG_SETTINGS_LGBUTTON',
      component: SettingsLanguage,
    },
  };
};
