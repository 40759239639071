import { Box } from '@mui/material';
import { styled } from '../../../util/styling';

export const Container = styled(Box)`
  text-align: center;
`;

interface Props {
  $isSmall: boolean;
}

export const IconContainer = styled(Box)<Props>`
  margin-bottom: ${({ theme, $isSmall }) => ($isSmall ? 0 : theme.spacing(4))};
`;

export const TitleContainer = styled(Box)<Props>`
  margin-bottom: ${({ theme, $isSmall }) => theme.spacing($isSmall ? 2 : 6)};
`;
