import { NumberValueUnit } from './maintenance.types';

const printableUnit = (unit: NumberValueUnit = 'NONE'): string => {
  return (
    {
      NONE: '',
      UNKNOWN: '',
      HOURS: 'h',
      MINUTES: 'm',
      SECONDS: 's',
      MILLISECONDS: 'ms',
      DEGREE_CELSIUS: '°C',
      DEGREE_KELVIN: 'K',
      LITER_PER_MIN: 'l/m',
      KILOWATT: 'kW',
      BAR: 'bar',
      ROUNDS_PER_MINUTE: 'r/m',
      ROUNDS_PER_SECOND: 'r/s',
      PERCENT: '%',
    }[unit] ?? unit
  );
};

const getCappedFractionDigitsCount = (value: number, maxCount = 2) => {
  const count = `${value}`.split('.')[1]?.length ?? 0;
  return count > maxCount ? maxCount : count;
};

const isNumberMeasuredValueWriteParams = (
  writeParams: unknown | null,
): writeParams is { [key: string]: unknown; stepSize: number } => {
  return !!writeParams && typeof writeParams === 'object' && Object.hasOwn(writeParams, 'stepSize');
};

export const getFractionDigitsCount = <T>(measuredValue: number, writeParams?: T | null) => {
  if (!writeParams) {
    return getCappedFractionDigitsCount(measuredValue);
  }
  const stepSize = isNumberMeasuredValueWriteParams(writeParams) ? writeParams.stepSize : 1;
  return getCappedFractionDigitsCount(stepSize);
};

const getMeasuredAt = (measuredValue?: { measured_at?: string }) => {
  return measuredValue?.measured_at ? new Date(measuredValue.measured_at) : undefined;
};

export { getMeasuredAt, printableUnit };
