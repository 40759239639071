import React from 'react';
import { authServiceMock } from '../services/auth/auth.service.mocks';
import { getAccessToken, AuthService, AuthStatus } from '../services/auth';

export interface AuthContextProps {
  service: AuthService;
  isChangingCountry?: boolean;
}

export interface AuthContextValues extends AuthContextProps {
  status: AuthStatus;
  prevStatus: AuthStatus;
}

export const AuthContext = React.createContext<AuthContextValues>({
  service: authServiceMock,
  status: authServiceMock.getStatus(),
  prevStatus: authServiceMock.getStatus(),
});

export const AuthContextProvider: React.FC<React.PropsWithChildren<AuthContextProps>> = ({
  children,
  service,
  isChangingCountry,
}) => {
  const [status, setStatus] = React.useState<{ status: AuthStatus; prevStatus: AuthStatus }>({
    status: service.getStatus(),
    prevStatus: service.getStatus(),
  });

  React.useEffect(() => {
    const unsubscribe = service.subscribe((prevStatus, nextStatus) => {
      setStatus({ status: nextStatus, prevStatus: prevStatus });
    });
    return unsubscribe;
  }, [service]);

  const value = { service, isChangingCountry, ...status };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

type PendingState = 'initial' | 'pending' | 'success' | 'error';

export type AuthInfo = Omit<AuthService, 'getStatus' | 'subscribe'> & {
  accessToken: string | null;
  prevStatus: AuthStatus;
  status: AuthStatus;
  isAuthenticated: boolean;
  isDemo: boolean;
  isIdm: boolean;
  isDelegated: boolean;
  pendingState: PendingState;
  authError: Error | null;
};

export const useAuthContext = (): AuthInfo => {
  const { service, status, prevStatus, isChangingCountry } = React.useContext(AuthContext);
  const [pendingState, setPendingState] = React.useState<PendingState>('initial');
  const [authError, setAuthError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    setPendingState(isChangingCountry ? 'pending' : 'initial');
  }, [isChangingCountry]);

  const loginIdm = React.useMemo(
    (): typeof service.loginIdm =>
      async (...params) => {
        setPendingState('pending');
        setAuthError(null);
        try {
          const result = await service.loginIdm(...params);
          setPendingState('success');
          return result;
        } catch (e) {
          setPendingState('error');
          setAuthError(e as Error);
        }
      },
    [service],
  );

  const logout = React.useMemo(
    (): typeof service.logout =>
      async (...params) => {
        setPendingState('pending');
        setAuthError(null);
        try {
          const result = await service.logout(...params);
          setPendingState('success');
          return result;
        } catch (e) {
          setPendingState('error');
          setAuthError(e as Error);
        }
      },
    [service],
  );

  const loginDelegated = React.useMemo(
    (): typeof service.loginDelegated =>
      (...params) => {
        service.loginDelegated(...params);
        setPendingState('success');
      },
    [service],
  );

  const loginDemo = React.useMemo(
    (): typeof service.loginDemo =>
      (...params) => {
        service.loginDemo(...params);
        setPendingState('success');
      },
    [service],
  );

  const isAuthenticated = status.isAuthenticated;
  const isDemo = status.authenticationSource === 'demo';
  const isIdm = status.authenticationSource === 'idm';
  const isDelegated = status.authenticationSource === 'delegated';
  const accessToken = getAccessToken(status);

  return {
    loginIdm,
    loginDelegated,
    loginDemo,
    logout,
    prevStatus,
    status: status as AuthStatus,
    accessToken,
    isAuthenticated,
    isDemo,
    isDelegated,
    isIdm,
    pendingState,
    authError,
  };
};
