import { useSpinnerControl } from '@vaillant-professional-ui/component-libs-common';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';

export interface WriteNumericDatapointDrawerContentContainerProps {
  title: string;
  buttonLabelCancel: string;
  buttonLabelSave: string;
  initialValue: number;
  unit: string;
  minimum: number;
  maximum: number;
  stepSize: number;
  digitsAfterPeriod: number;
  useMutation: () => (temperature: number) => void;
  onClose: () => void;
  testIdKey: string;
}

export const usePresenter = ({
  useMutation,
  initialValue,
  unit,
  minimum,
  maximum,
  stepSize,
  digitsAfterPeriod,
  buttonLabelCancel,
  buttonLabelSave,
  testIdKey,
  onClose,
  title,
}: WriteNumericDatapointDrawerContentContainerProps) => {
  const t = hooks.useTranslation();
  const mutate = useMutation();
  const spinnerControl = useSpinnerControl({
    initialValue,
    unit,
    minimum,
    maximum,
    stepSize,
    digitsAfterPeriod,
  });

  const handleSubmit = () => {
    mutate(spinnerControl.value);
    onClose();
  };

  const subtitle = t('SYSTEM_SETTINGS_SELECT_DATAPOINT_VALUE', { datapoint: title });

  const isSaveButtonDisabled = spinnerControl.value === initialValue;

  return {
    spinnerControlProps: { ...spinnerControl, testIdKey },
    title,
    subtitle,
    buttonLabelCancel,
    buttonLabelSave,
    handleSubmit,
    isSaveButtonDisabled,
    onClose,
  };
};
