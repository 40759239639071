import { useCase, UseRemoveCustomerParams } from './useCase';
import { hooks } from '../../../index';
import { useMemo, useState } from 'react';

interface RemoveCustomerPresenterProps {
  opts: UseRemoveCustomerParams;
  /**
   * Make sure YOU are using the correct serial number here!
   */
  gatewaySerialNumber: string;
  customerFullName: string | null;
}

export const usePresenter = ({ opts, gatewaySerialNumber, customerFullName }: RemoveCustomerPresenterProps) => {
  const { removeCustomer, isLoading } = useCase({ opts, boilerSerialNumber: gatewaySerialNumber });

  const [isRemoveCustomerDialogOpen, setIsRemoveCustomerDialogOpen] = useState(false);

  const t = hooks.useTranslation();

  const openDialog = useMemo(() => () => setIsRemoveCustomerDialogOpen(true), []);
  const closeDialog = useMemo(() => () => setIsRemoveCustomerDialogOpen(false), []);

  const dialogTitle = t('SG_REMOVE_CUSTOMER');
  const testIdKey = 'remove-customer';
  const cancelRemoveButtonText = t('SG_CANCEL');
  const removeRemoveButtonText = t('SG_REMOVE_BUTTON');

  const removeCustomerHeaderLabel = t('SG_REMOVE_CUSTOMER_HEADER', { 0: customerFullName || gatewaySerialNumber });

  return {
    isDialogOpen: isRemoveCustomerDialogOpen,
    closeDialog,
    openDialog,
    dialogTitle,
    testIdKey,
    removeCustomerHeaderLabel,
    cancelButtonText: cancelRemoveButtonText,
    removeButtonText: removeRemoveButtonText,
    removeCustomer,
    isRemovingCustomer: isLoading,
  };
};
