import { Box, List, ListItem, Paper } from '@mui/material';
import {
  BackLink,
  Button,
  PageHeader,
  SpinnerControl,
  Typography,
  useTheme,
} from '@vaillant-professional-ui/component-libs-web';
import { HeatCurveChart, hooks } from '@vaillant-professional-ui/pui-frontend-common';
import React from 'react';
import { Link } from 'react-router-dom';
import { TliHeatCurvePageProps } from './TliHeatCurvePage.types';

export const TliHeatCurvePage: React.FC<React.PropsWithChildren<TliHeatCurvePageProps>> = ({
  circuitName,
  customerName,
  isDirty,
  minFlowTargetTemperatureUnit = '°C',
  onSave,
  spinnerControlHeatCurve,
  spinnerControlMinFlowTargetTemperature,
}) => {
  const t = hooks.useTranslation();
  const theme = useTheme();

  return (
    <Box width={'100%'}>
      {customerName && <PageHeader title={customerName} />}
      <Box mb={8}>
        <BackLink linkProps={{ component: Link, to: '..' }} i18nKey='SG_BACK_TO_TLI_CONTROL' testIdKey='heat-curve' />
      </Box>
      <Box mb={8}>
        <Typography variant='h2'>{circuitName}</Typography>
      </Box>
      <Box display={'flex'} flexDirection={{ xs: 'column', lg: 'row' }} mb={5}>
        <Box width={{ lg: '50%', xs: '100%' }} mb={{ xs: 5, lg: 0 }} mr={{ xs: 0, lg: 30 }}>
          <Typography>{t('SG_HEAT_CURVE_DESCRIPTION')}</Typography>
        </Box>
        <Box width={{ lg: '50%', xs: '100%' }}>
          <Typography>{t('SG_HEAT_CURVE_SETPOINT')}</Typography>
        </Box>
      </Box>
      <Paper>
        <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} alignContent='center' alignItems='center'>
          <Box flexGrow={{ xs: 1, lg: 2 }}>
            <HeatCurveChart
              slope={spinnerControlHeatCurve.value}
              minTemperature={spinnerControlMinFlowTargetTemperature.value}
              mainColor={theme?.palette?.primary?.main}
              fontFamily={theme?.typography?.fontFamily}
              axisLabelSize={10}
              scatterSize={15}
              padding={{
                top: 30,
                bottom: 90,
                right: 30,
                left: 48,
              }}
            />
          </Box>
          <Box
            flexGrow={{ xs: 1, lg: 3 }}
            padding={{ lg: '50px' }}
            paddingLeft={{ lg: '150px' }}
            width={{ xs: '100%', lg: 'auto' }}
          >
            <List>
              <ListItem style={{ justifyContent: 'space-between' }} divider alignItems='center'>
                <Typography> {t('SG_HEAT_CURVE_SLOPE')}</Typography>
                <SpinnerControl
                  testIdKey='heat-curve-input'
                  displayValue={spinnerControlHeatCurve.displayValue}
                  onPressMinus={spinnerControlHeatCurve.onPressMinus}
                  onPressPlus={spinnerControlHeatCurve.onPressPlus}
                  disableMinus={spinnerControlHeatCurve.isMinusDisabled}
                  disablePlus={spinnerControlHeatCurve.isPlusDisabled}
                />
              </ListItem>
              <ListItem style={{ justifyContent: 'space-between' }}>
                <Typography>{`${t('SG_HEAT_CURVE_MIN_FLOW_TEMP_SHORT')} (${minFlowTargetTemperatureUnit})`}</Typography>
                <SpinnerControl
                  testIdKey='min-flow-target-temp-input'
                  displayValue={spinnerControlMinFlowTargetTemperature.displayValue}
                  onPressMinus={spinnerControlMinFlowTargetTemperature.onPressMinus}
                  onPressPlus={spinnerControlMinFlowTargetTemperature.onPressPlus}
                  disableMinus={spinnerControlMinFlowTargetTemperature.isMinusDisabled}
                  disablePlus={spinnerControlMinFlowTargetTemperature.isPlusDisabled}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          {/* <Button color='primary' disabled={!isDataChanged()} onClick={() => restoreSettings()}>
            {t('SG_HEAT_CURVE_RESTORE_SETTINGS')}
          </Button> */}
          <Button color='primary' variant='contained' disabled={!isDirty} onClick={onSave} testIdKey='save-heat-curve'>
            {t('SG_SAVE_CHANGES')}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
