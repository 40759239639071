import { TranslationFnc } from '../../../../../typings/models';
import { nonNullable } from '../../../../../util';
import { DeviceDetailsResponseTli } from '../../../../../api/apis/maintenance/v2';
import { Category, createCategory } from '../../../deviceSettings/useCase/model/category';
import { mapEnumMeasuredValue, mapNumericMeasuredValue } from './mapMeasuredValue';
import { dataPointKeyToTranslationMap } from './translationMap';
import { DeviceSettingsSettingValue } from '../../../../../api/apis/maintenance/v2/maintenance.types';

const mapDhw = (response: DeviceDetailsResponseTli, t: TranslationFnc): Category['values'] => {
  const dhw = response.domestic_hot_water?.[0];
  if (!dhw) {
    return [];
  }

  return [
    mapEnumMeasuredValue({
      id: 'dhwOperationMode',
      measuredValue: dhw.operation_mode,
      mutationId: 'dhwOperationMode',
      action: dhw._actions?.update_dhw_operation_mode,
      actionPropertyKey: 'operationMode',
      t,
    }),
    mapNumericMeasuredValue({ id: 'currentDhwTemperature', measuredValue: dhw.tank_temperature, t }),
    dhw.schedule
      ? {
          title: t(dataPointKeyToTranslationMap['dhwWeeklyPlanner']),
          type: 'link' as const,
          id: 'dhwWeeklyPlanner' as const,
          metadata: { index: dhw.index, section: 'domestic_hot_water' } as DeviceSettingsSettingValue['metadata_value'],
        }
      : null,
    mapNumericMeasuredValue({
      id: 'dhwTargetTemperature',
      measuredValue: dhw.target_temperature,
      mutationId: 'dhwTargetTemperature',
      action: dhw._actions?.update_dhw_target_temperature,
      actionPropertyKey: 'targetTemperature',
      t,
    }),
    mapNumericMeasuredValue({
      id: 'dhwMinTargetTemperature',
      measuredValue: dhw.minimum_target_temperature,
      t,
    }),
    mapNumericMeasuredValue({ id: 'dhwMaxTargetTemperature', measuredValue: dhw.maximum_target_temperature, t }),
  ].filter(nonNullable);
};

export const createDhw = (response: DeviceDetailsResponseTli, t: TranslationFnc) =>
  createCategory(t('SG_MONITORING_VALVE_DOM'), mapDhw(response, t));
