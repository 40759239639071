import { Typography, useTheme } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { CustomerCard } from './CustomerCard';
import { CustomerCardsProps } from './CustomerCards.types';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { Box } from '@mui/material';

export const CustomerCards: React.FC<CustomerCardsProps> = ({ customers, hasNoSearchResult, markText }) => {
  const theme = useTheme();

  return (
    <Virtuoso
      style={{
        height: 'calc(100vh - 310px)',
        marginRight: theme.spacing(-4),
        marginLeft: theme.spacing(-4),
        marginTop: theme.spacing(4),
      }}
      data={customers}
      itemContent={(_, customer) => {
        return <CustomerCard customer={customer} markText={markText} key={customer?.id} />;
      }}
      components={{ Footer: hasNoSearchResult ? Footer : undefined }}
    />
  );
};

const Footer = () => {
  const t = hooks.useTranslation();

  return (
    <Box bgcolor='common.white' display='flex' justifyContent='center' py={10}>
      <Typography color='grey.500'>{t('SG_CUSTOMER_LIST_NO_CUSTOMER_FOUND')}</Typography>
    </Box>
  );
};
