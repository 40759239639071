import { useLocalizedWeekdays, useTranslation } from '../../../libs/hooks';
import { getPresentationData } from './getPresentationData';
import { useVrc700Case } from './useCase';
import { Schedule, UseVrc700Presenter } from './types';
import { useCustomerDetails } from '../../../api/useCases';
import { I18nKeys } from '../../../i18n';

export const useVrc700Presenter: UseVrc700Presenter = ({
  systemId,
  deviceId,
  boilerSerialNumber,
  index,
  scheduleType,
}) => {
  const t = useTranslation();
  const weekdays = useLocalizedWeekdays();

  const { error: errorUser, isLoading: isLoadingCustomer, fullName } = useCustomerDetails(boilerSerialNumber);
  const { vrc700Query } = useVrc700Case({ systemId, deviceId });
  const presentationalData = vrc700Query.data
    ? getPresentationData(vrc700Query.data, weekdays, t, index, scheduleType)
    : null;

  const isLoading = isLoadingCustomer || vrc700Query.isLoading;
  const error = errorUser || vrc700Query.error;

  return {
    isLoading,
    error,
    fullName,
    ...presentationalData!,
  };
};

export const schedulePageSubtitleTextMapper: Record<Schedule, I18nKeys> = {
  circulation_pump: 'SG_WEEKLY_CIRCULATION_PUMP_PLANNER',
  cooling: 'SG_WEEKLY_COOLING_PLANNER',
  ventilation: 'SG_WEEKLY_VENTILATION_PLANNER',
  heating: 'SG_WEEKLY_HEATING_PLANNER',
  domestic_hot_water: 'SG_DHW_TITLE',
};
