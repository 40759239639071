import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, UseQueryOptions } from 'react-query';
import { QueryKey, useMutation, useQuery } from '../../../hooks';
import { maintenanceApi } from './maintenance.endpoints';
import { prepareInput, filterRefills } from './maintenance.util';
import {
  CreateTroubleCodeNodeData,
  CustomerListResponse,
  DeleteTroubleCodeNoteRequestParams,
  PredictionData,
  PredictionResponse,
  TliData,
  TliResponse,
  UpdateTroubleCodeNoteData,
  UseTliOptions,
  UseTliReturnType,
} from './maintenance.types';
import { ICustomerDetail } from '../../../../typings/models';
import { History } from './maintenance.types';

interface Params {
  boilerSerialNumber: string;
  now?: Date;
  maxPredictionScope?: number;
}

interface WaterPressureRefillParams {
  boilerSerialNumber: string;
  now: number;
  hoursToLookBack: number;
}

export const useWaterPressureRefills = (
  { boilerSerialNumber, hoursToLookBack = 24 * 7, now = Date.now() }: WaterPressureRefillParams,
  options: UseQueryOptions<AxiosResponse<PredictionResponse>, AxiosError, History> = {},
) => {
  return useQuery<PredictionResponse, AxiosError, History>(
    ['waterPressurePredictionRefills', boilerSerialNumber],
    maintenanceApi.getPrediction(boilerSerialNumber),
    {
      select: (result) => filterRefills(result.data, now, hoursToLookBack),
      staleTime: 1000 * 60 * 60,
      ...options,
    },
  );
};

export const useWaterPressurePrediction = (
  { boilerSerialNumber, now = new Date(), maxPredictionScope = 14 }: Params,
  options: UseQueryOptions<AxiosResponse<PredictionResponse>, AxiosError, PredictionData> = {},
) => {
  return useQuery<PredictionResponse, AxiosError, PredictionData>(
    ['waterPressurePrediction', boilerSerialNumber],
    maintenanceApi.getPrediction(boilerSerialNumber),
    {
      select: (result) => {
        return { ...prepareInput(result.data, now, maxPredictionScope) };
      },
      staleTime: 1000 * 60 * 60,
      ...options,
    },
  );
};

export const useSparePartsLink = (options = {}) => {
  return useQuery<string, AxiosError, string>('sparePartsLink', maintenanceApi.getSparePartsLink(), {
    staleTime: 0,
    keepPreviousData: true,
    select: (response) => response.data,
    ...options,
  });
};

export const useCustomer = ({
  boilerSerialNumber,
  options = {},
}: {
  boilerSerialNumber: string;
  options?: UseQueryOptions<AxiosResponse<ICustomerDetail>, AxiosError, ICustomerDetail>;
}) => {
  return useQuery<ICustomerDetail, null, ICustomerDetail>(
    ['customer', boilerSerialNumber],
    maintenanceApi.getCustomer(boilerSerialNumber),
    {
      staleTime: 0,
      keepPreviousData: true,
      select: (response) => response.data,
      ...options,
    },
  );
};

export const useCustomerList = (
  options: UseQueryOptions<AxiosResponse<CustomerListResponse>, AxiosError, CustomerListResponse> = {},
) => {
  return useQuery<CustomerListResponse, unknown, CustomerListResponse>(
    'customerList',
    maintenanceApi.getCustomerList(),
    {
      staleTime: 0,
      keepPreviousData: true,
      select: (data) => data.data,
      ...options,
    },
  );
};

export const useTli = (boilerSerialNumber: string, options: UseTliOptions = {}): UseTliReturnType => {
  const queryKey: QueryKey = ['tliState', boilerSerialNumber];
  const useQ = useQuery<TliResponse, unknown, TliData | null>(
    queryKey,
    maintenanceApi.getTliState(boilerSerialNumber),
    options,
  );
  return useQ;
};

export const useAddNote = (
  options?: UseMutationOptions<AxiosResponse<unknown>, unknown, CreateTroubleCodeNodeData>,
) => {
  return useMutation<unknown, unknown, CreateTroubleCodeNodeData>((data) => maintenanceApi.addTroubleCodeNote(data), {
    ...options,
  });
};

export const useUpdateNote = (
  options?: UseMutationOptions<AxiosResponse<unknown>, unknown, UpdateTroubleCodeNoteData>,
) => {
  return useMutation<unknown, unknown, UpdateTroubleCodeNoteData>(
    (data) => maintenanceApi.updateTroubleCodeNote(data),
    { ...options },
  );
};

export const useDeleteNote = (
  options?: UseMutationOptions<AxiosResponse<unknown>, unknown, DeleteTroubleCodeNoteRequestParams>,
) => {
  return useMutation<unknown, unknown, DeleteTroubleCodeNoteRequestParams>(
    (data) => maintenanceApi.deleteTroubleCodeNote(data),
    { ...options },
  );
};
