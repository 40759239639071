import React from 'react';

import { Navigate, Outlet, Route, Routes as RoutesBase } from 'react-router-dom';
import { HistoryChartsContainer, HistoryChartsLayout } from '../../features/historyCharts/HistoryChartsContainer';

import {
  LegacyChartsTab,
  MonitoringDetailsLayoutContainer,
  WaterPressurePredictionTab,
} from '../../features/systemMonitoring';
import ActivitySpinner from '../ActivitySpinner';
import { DashboardContainer } from '../Dashboard';
import { LoginContainer } from '../Login';
import { ConsumptionOverview } from '../organisms/ConsumptionOverview/ConsumptionOverview';
import { CoolingGeneratedDetails } from '../organisms/CoolingGeneratedDetails/CoolingGeneratedDetails';
import { DhwGeneratedDetails } from '../organisms/DhwGeneratedDetails/DhwGeneratedDetails';
import { ElectricalConsumptionDetails } from '../organisms/ElectricalConsumptionDetails/ElectricalConsumptionDetails';
import { EnvironmentalYieldDetails } from '../organisms/EnvironmentalYieldDetails/EnvironmentalYieldDetails';
import { GasConsumptionDetails } from '../organisms/GasConsumptionDetails';
import { HeatGeneratedDetails } from '../organisms/HeatGeneratedDetails/HeatGeneratedDetails';
import { SolarThermalDetails } from '../organisms/SolarThermalDetails/SolarThermalDetails';
import { ConsumptionPageContainer } from '../pages/ConsumptionPage';
import { CustomerListPageContainer } from '../pages/CustomerListPage';
import { ErelaxSchedulerPageContainer } from '../pages/ErelaxSchedulerPage';
import { LegalContentPage } from '../pages/LegalContentPage';
import { NotFoundPage } from '../pages/NotFoundPage';
import { PermissionDeniedPage } from '../pages/PermissionDeniedPage';
import { SettingsPageContainer } from '../pages/SettingsPage';
import { TokenLogin } from '../TokenLogin';
import { RequirePermissions } from './RequirePermissions';
import { RoutesProps } from './Routes.types';
import { TliDHWSchedulerPageContainer } from '../pages/TliDHWSchedulerPage';
import { TliSchedulerPageContainer } from '../pages/TliSchedulerPage';
import { TliHeatCurvePageContainer } from '../pages/TLIHeatCurvePage';
import { SystemSettingsPageContainer } from '../../features/systemSettings/systemSettingsOverview/SystemSettingsPage.container';
import { FeatureToggle } from '@vaillant-professional-ui/pui-frontend-common';
import { Vrc700SchedulerPageContainer } from '../pages/Vrc700SchedulerPage';

const LazyEnterDemoContainer = React.lazy(() => import('../EnterDemo'));
const LazyCodeAssistantContainer = React.lazy(() => import('../CodeAssistant/CodeAssistant.container'));
const LazyFeedbackPageContainer = React.lazy(() => import('../pages/FeedbackPage/FeedbackPage.container'));
const LazyCustomerDetailContainer = React.lazy(() => import('../CustomerDetail/CustomerDetail.container'));

export const Routes: React.FC<RoutesProps> = ({ isAuthenticated }) => {
  const defaultPath = !isAuthenticated ? '/login' : '/dashboard';

  return (
    <RoutesBase>
      {/** Public routes */}
      <Route path='permission-denied/:errorCode' element={<PermissionDeniedPage />} />
      <Route path='jobs/:country/:token/:gatewaySerialNumber' element={<TokenLogin />} />
      <Route path='imprint/:country' element={<LegalContentPage contentType='legal' />} />
      <Route path='terms/:country' element={<LegalContentPage contentType='terms' />} />
      <Route path='privacy-policy/:country' element={<LegalContentPage contentType='privacy' />} />
      <Route
        path='demo/:country'
        element={
          <React.Suspense fallback={<ActivitySpinner onScreen={true} loading={true} />}>
            <LazyEnterDemoContainer />
          </React.Suspense>
        }
      />

      {/** Dashboard */}
      <Route
        path='dashboard'
        element={
          <RequirePermissions requiredPermissions={['VIEW_DASHBOARD']}>
            <Outlet />
          </RequirePermissions>
        }
      >
        <Route index element={<DashboardContainer />} />
        <Route path=':tab' element={<DashboardContainer />} />
      </Route>

      {/** Customer */}
      <Route path='customers'>
        <Route
          index
          element={
            <RequirePermissions requiredPermissions={['VIEW_CUSTOMER_LIST']}>
              <CustomerListPageContainer />
            </RequirePermissions>
          }
        />
        <Route
          path=':boilerSerialNumber'
          element={
            <RequirePermissions requiredPermissions={['VIEW_CUSTOMER_DETAIL']}>
              <Outlet />
            </RequirePermissions>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<ActivitySpinner onScreen={true} loading={true} />}>
                <LazyCustomerDetailContainer />
              </React.Suspense>
            }
          />

          <Route path='system-settings/:systemId/:deviceId?' element={<Outlet />}>
            <Route path='dhw-schedule' element={<TliDHWSchedulerPageContainer />} />
            <Route path='scheduler/:zone?' element={<TliSchedulerPageContainer />} />
            <Route path='heat-curve/:circuitIndex' element={<TliHeatCurvePageContainer />} />
            <Route path='' element={<SystemSettingsPageContainer />} />
            <Route path={'schedule'}>
              <Route path={'vrc700/:index/:scheduleType'} element={<Vrc700SchedulerPageContainer />} />
            </Route>
          </Route>

          {/*TODO: remove this?*/}
          <Route path='erelax-scheduler' element={<ErelaxSchedulerPageContainer />} />

          <Route path='monitoring-details' element={<MonitoringDetailsLayoutContainer />}>
            <Route path='time-series' element={<LegacyChartsTab />} />
            <Route
              path='expert-mode-time-series'
              element={
                <FeatureToggle featureName='time-series'>
                  <HistoryChartsLayout />
                </FeatureToggle>
              }
            >
              <Route path=':chartTabId' element={<HistoryChartsContainer />} />
            </Route>
            <Route path='f22-predictions' element={<WaterPressurePredictionTab />} />
            <Route path='' element={<Navigate to='./time-series' replace />} />
          </Route>

          <Route path='consumption' element={<ConsumptionPageContainer />}>
            <Route path='cooling-generated' element={<CoolingGeneratedDetails />} />
            <Route path='dhw-generated' element={<DhwGeneratedDetails />} />
            <Route path='electrical-consumption' element={<ElectricalConsumptionDetails />} />
            <Route path='environmental-yield' element={<EnvironmentalYieldDetails />} />
            <Route path='heat-generated' element={<HeatGeneratedDetails />} />
            <Route path='solar-thermal' element={<SolarThermalDetails />} />
            <Route path='gas-consumption' element={<GasConsumptionDetails />} />
            <Route path='' element={<ConsumptionOverview />} />
          </Route>
        </Route>
      </Route>

      <Route
        path='feedback'
        element={
          <React.Suspense fallback={<ActivitySpinner onScreen={true} loading={true} />}>
            <RequirePermissions requiredPermissions={['VIEW_FEEDBACK']}>
              <LazyFeedbackPageContainer />
            </RequirePermissions>
          </React.Suspense>
        }
      />

      {/** Code assistant */}
      <Route
        path='code-assistant'
        element={
          <RequirePermissions requiredPermissions={['VIEW_CODE_ASSISTANT']}>
            <Outlet />
          </RequirePermissions>
        }
      >
        <Route
          index
          element={
            <React.Suspense fallback={<ActivitySpinner onScreen={true} loading={true} />}>
              <LazyCodeAssistantContainer />
            </React.Suspense>
          }
        />
        <Route
          path=':articleNumber'
          element={
            <React.Suspense fallback={<ActivitySpinner onScreen={true} loading={true} />}>
              <LazyCodeAssistantContainer />
            </React.Suspense>
          }
        />
        <Route
          path=':articleNumber/:dtc'
          element={
            <React.Suspense fallback={<ActivitySpinner onScreen={true} loading={true} />}>
              <LazyCodeAssistantContainer />
            </React.Suspense>
          }
        />
      </Route>

      {/** Settings */}
      <Route
        path='settings'
        element={
          <RequirePermissions requiredPermissions={['VIEW_SETTINGS']}>
            <Outlet />
          </RequirePermissions>
        }
      >
        <Route index element={<SettingsPageContainer />} />
        <Route path=':section' element={<SettingsPageContainer />} />
      </Route>

      <Route path='login' element={isAuthenticated ? <Navigate to={defaultPath} /> : <LoginContainer />} />
      <Route path='/' element={<Navigate to={defaultPath} />} />
      <Route path='*' element={<NotFoundPage />} />
    </RoutesBase>
  );
};
