import { differenceInDays } from 'date-fns';
import { sortBy } from 'lodash';
import { CustomerDashboardFilter, ICustomer } from '../../typings/models';
import {
  getF22Prediction,
  hasFailedValidation,
  hasUnrectifiedError,
  hasUnrectifiedMaintenance,
  isOffline,
  isPending,
} from './../customer';
import { calculateStatusWeight } from '../../services/customers';
import { FilterConfiguration } from './groupCustomers.types';

const defaultSort = (customers: ICustomer[]) => customers;
const defaultCount = (customers: ICustomer[]) => customers.length;

export const filterConfiguration: Record<CustomerDashboardFilter, FilterConfiguration> = {
  ERROR: {
    predicate: hasUnrectifiedError,
    sort: defaultSort,
    count: (customers: ICustomer[]) =>
      customers.map((customer) => customer.system.boiler.activeTroubleCodes).flat().length,
  },
  MAINTENANCE: {
    predicate: hasUnrectifiedMaintenance,
    sort: defaultSort,
    count: defaultCount,
  },
  PREDICTION: {
    predicate: (customer) =>
      Boolean(
        getF22Prediction(customer) &&
          customer.status.errorPredictions?.find(
            (prediction) => differenceInDays(new Date(prediction.predictedFrom), Date.now()) < 8,
          ),
      ),
    sort: defaultSort,
    count: defaultCount,
  },
  OFFLINE: {
    predicate: isOffline,
    sort: (customers) => sortBy(customers, (customer) => calculateStatusWeight(customer, Date.now())),
    count: defaultCount,
  },
  PENDING: {
    predicate: (customer) => isPending(customer) || hasFailedValidation(customer),
    sort: defaultSort,
    count: defaultCount,
  },
};
