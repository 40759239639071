import { IInternalLinkProps } from '../../Link/Link.types';

export const getLinkProps = (
  linkProps?: {
    component?: React.FC<React.PropsWithChildren<IInternalLinkProps>> | undefined;
    to: string;
  },
  disabled?: boolean,
) => {
  if (linkProps) {
    return { ...linkProps, disabled };
  }
};
