import React from 'react';

import { Box, TableCell, TableRow, TableSortLabel } from '@mui/material';
import { Card, CardContent, Typography } from '@vaillant-professional-ui/component-libs-web';
import { hooks, models } from '@vaillant-professional-ui/pui-frontend-common';
import { SortIcon } from '../atoms/SortIcon';
import { VirtualizedTable, useSorting } from '../organisms/VirtualizedTable';
import { TypographyStyled, styles } from './LoyaltyRegistrationTable.styles';
import { LoyaltyRegistrationTableProps } from './LoyaltyRegistrationTable.types';
import { getColumns } from './LoyaltyRegistrationTable.utils';
import { LoyaltyRegistrationTableRow } from './LoyaltyRegistrationTableRow';
import { BaseTableRowData } from '../organisms/VirtualizedTable/VirtualizedTable.types';

interface Dummy extends models.LoyaltyRegistration, BaseTableRowData {} // ts hack

export const LoyaltyRegistrationTable: React.FC<LoyaltyRegistrationTableProps> = ({
  openAddCustomer,
  loyaltyRegistrations,
}) => {
  const t = hooks.useTranslation();
  const columns = getColumns(t);

  const { sortedData, isSortingActive, direction, onClickHandler } = useSorting({
    data: loyaltyRegistrations,
    columns,
    initialSorting: { columnIndex: 2, direction: 'desc' },
  });
  const _sortedData: Dummy[] = sortedData; // ts hack

  return (
    <>
      <Box mb={5}>
        <Typography variant='h2' i18nKey='SG_REGISTERED_AND_CONNECTED_LOYALTY_DEVICES_TITLE' />
      </Box>
      <Box mb={5}>
        <Typography variant='h4' i18nKey='SG_REGISTERED_AND_CONNECTED_LOYALTY_DEVICES_SUBTITLE' />
      </Box>
      <Card style={styles.card}>
        <CardContent>
          <VirtualizedTable
            onClickRow={undefined}
            data={_sortedData}
            style={styles.virtualizedTable}
            fixedHeaderContent={() => (
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={column.key}>
                    <TableSortLabel
                      disabled={!column.sort}
                      hideSortIcon={!column.sort}
                      active={isSortingActive(index)}
                      direction={direction(index)}
                      onClick={onClickHandler(index)}
                      IconComponent={SortIcon}
                    >
                      <TypographyStyled>{column.label}</TypographyStyled>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            )}
            itemContent={(_, data) => (
              <LoyaltyRegistrationTableRow
                loyaltyRegistration={data}
                openAddCustomer={() => openAddCustomer(data)}
                key={data.serialNumber}
              />
            )}
          />
        </CardContent>
      </Card>
    </>
  );
};
