import { models } from '@vaillant-professional-ui/pui-frontend-common';
import React from 'react';
import { ContainerAuthenticated, FooterLinkContainer, FooterLogo, FooterLogoWrapper } from './Footer.styles';
import { TextLinks } from './TextLinks';
import { Grid } from '@vaillant-professional-ui/component-libs-web';

interface FooterProps {
  imgSrcLogo: string;
  country: models.Country;
}

export const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({ country, imgSrcLogo }) => {
  return (
    <ContainerAuthenticated component={Grid} container={true} px={8} pt={[4, 4, 0]}>
      <FooterLinkContainer mb={[4, 4, 0]} item={true} container={true} alignItems='center'>
        <TextLinks country={country} />
      </FooterLinkContainer>
      {!!country && (
        <FooterLogoWrapper item container mb={[6, 6, 0]}>
          <FooterLogo src={imgSrcLogo} alt='Claim' />
        </FooterLogoWrapper>
      )}
    </ContainerAuthenticated>
  );
};
