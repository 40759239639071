import { CustomerStatusProp } from '../../Utils/customer';
import { MobileDashboardStatusOccurrenceWrapper } from './MobileDashboard.styles';
import { StatusChip } from '../organisms/StatusOccurrenceList/StatusChip';
import { Box } from '@mui/material';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';

interface MobileDashboardStatusOccurrenceProps {
  item: CustomerStatusProp;
}

export const MobileDashboardStatusOccurrence = ({ item }: MobileDashboardStatusOccurrenceProps) => {
  const { title, icon: Icon, color, variant = 'filled', tooltip, styles, subtitle, occurrence } = item;
  return (
    <MobileDashboardStatusOccurrenceWrapper>
      <StatusChip
        type={'STATUS'}
        color={color}
        variant={variant}
        label={
          Icon ? (
            <Box display='flex' alignItems='center' gap={1}>
              <Icon color='inherit' />
              {title}
            </Box>
          ) : (
            title
          )
        }
        style={styles?.chip}
        tooltip={tooltip}
      />
      <Typography variant='h4' style={styles?.typography}>
        {subtitle}
      </Typography>
      {!!occurrence && (
        <div>
          <Typography variant='smallCopy' color='text.secondary'>
            {occurrence.date} {occurrence.time}
          </Typography>
        </div>
      )}
    </MobileDashboardStatusOccurrenceWrapper>
  );
};
