import { Tab, Tabs } from '@mui/material';
import { styled } from '../../../util/styling';

export const TabsMajor = styled(Tabs)`
  & .MuiTabs-indicator {
    transition: none;
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  & [role='tab'] {
    padding-left: ${({ theme }) => theme.spacing(12)};
    padding-right: ${({ theme }) => theme.spacing(12)};
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-bottom: ${({ theme }) => theme.spacing(5)};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-color: ${({ theme }) => theme.palette.grey['200']};
    border-style: solid;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.common.white};

    ${({ theme }) => theme.breakpoints.down('lg')} {
      padding-left: ${({ theme }) => theme.spacing(4)};
      padding-right: ${({ theme }) => theme.spacing(4)};
      padding-top: ${({ theme }) => theme.spacing(4)};
      padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
  }

  & [role='tab']:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(8)};

    ${({ theme }) => theme.breakpoints.down('lg')} {
      margin-right: ${({ theme }) => theme.spacing(4)};
    }
  }

  & [role='tab']:not(.Mui-selected) {
    background-color: ${({ theme }) => theme.palette.grey['350']};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }

  & [role='tab']:not(.Mui-selected):not(.Mui-disabled) .MuiTypography-root {
    color: ${({ theme }) => theme.palette.grey['600']};
  }

  & [role='tab'].mui-selected:before,
  & [role='tab'].mui-selected:after {
    background-color: ${({ theme }) => theme.palette.primary};
    width: 20px;
    height: 20px;
  }

  & [role='tab']:disabled {
    background-color: ${({ theme }) => theme.palette.grey['100']};
  }

  & [role='tab']:disabled .muitypography-root {
    color: ${({ theme }) => theme.palette.grey['400']};
  }
`;

export const ContentContainer = styled('div')`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
`;

export const TabStyled = styled(Tab)`
  max-width: none;
`;
