import * as api from '../api';

export const minutesSinceMidnightToTime = (minutes: number): string =>
  `${String(Math.floor(minutes / 60)).padStart(2, '0')}:${String(minutes % 60).padStart(2, '0')}`;

type ArrayWithSevenElements = [string, string, string, string, string, string, string];

export const getLocalizedWeekdays = (locale: string, variant: 'short' | 'long' = 'short'): ArrayWithSevenElements =>
  [
    '2021-09-13', // Mon
    '2021-09-14', // Tue
    '2021-09-15', // Wed
    '2021-09-16', // Thu
    '2021-09-17', // Fri
    '2021-09-18', // Sat
    '2021-09-19', // Sun
  ].map((d) =>
    new Intl.DateTimeFormat(locale, { weekday: variant }).format(new Date(d)).toUpperCase(),
  ) as ArrayWithSevenElements;

interface GappedTimeWindow {
  start: string;
  end: string;
  startMinutes: number;
  endMinutes: number;
  temperatureTarget?: number;
}

const createGappedTimeWindow = (start: number, end: number, temperatureTarget?: number): GappedTimeWindow => ({
  start: minutesSinceMidnightToTime(start),
  end: minutesSinceMidnightToTime(end),
  startMinutes: start,
  endMinutes: end,
  temperatureTarget,
});

/**
 * Creates a gapped time window list, like in https://vaillant.invisionapp.com/console/share/W62O4TAKAG/570595161/play
 *
 * Example pseudo code:
 *
 * Given:
 * * 06:00 - 07:00, 20°
 * * 08:00 - 09:00, 21°
 *
 * Result:
 * * 00:00 - 06:00
 * * 06:00 - 07:00, 20°
 * * 07:00 - 08:00
 * * 08:00 - 09:00, 21°
 * * 09:00 - 24:00
 */
export const createGappedTimeWindows = (timeWindows: api.maintenance.v1.TliHeatingTimeWindow[]): GappedTimeWindow[] => {
  // If no entries given, return one gap from 0:00 - 24:00
  if (timeWindows.length === 0) {
    return [
      {
        start: '00:00',
        end: '24:00',
        startMinutes: 0,
        endMinutes: 24 * 60,
      },
    ];
  }

  const initial: GappedTimeWindow[] =
    timeWindows[0].start === 0 ? [] : [createGappedTimeWindow(0, timeWindows[0].start)];

  const result: GappedTimeWindow[] = timeWindows.reduce((acc, timeWindow, index) => {
    // Add current time window
    const newValue = [...acc, createGappedTimeWindow(timeWindow.start, timeWindow.end, timeWindow.temperatureTarget)];
    if (index < timeWindows.length - 1) {
      const nextTimeWindow = timeWindows[index + 1];
      // If there is a next time window, and it's subsequent, just continue
      if (nextTimeWindow.start === timeWindow.end) {
        return newValue;
      }

      // If there is another time window, but it's not subsequent, add gap
      newValue.push(createGappedTimeWindow(timeWindow.end, nextTimeWindow.start));
    } else {
      // If it's the last time window, and it does not end at 24:00, add a last gap, longing to 24:00
      if (timeWindow.end !== 1440) {
        newValue.push(createGappedTimeWindow(timeWindow.end, 1440));
      }
    }

    return newValue;
  }, initial);

  return result;
};
