import React from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog } from '@vaillant-professional-ui/component-libs-web';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';

interface Props {
  isDemo: boolean;
}

export const DemoDisclaimer: React.FC<React.PropsWithChildren<Props>> = ({ isDemo }) => {
  const [hasBeenRead, setHasBeenRead] = React.useState(false);
  const location = useLocation();
  const t = hooks.useTranslation();

  React.useEffect(() => {
    setHasBeenRead(false);
  }, [isDemo]);

  // TODO: Create better solution: It can be the case that the current user is logged in via demo mode when he reaches this point.
  // To properly avoid this, we need to think about route restructuring. Until then, we just don't show the demo disclaimer on token login.
  const isDialogOpen = isDemo && !location.pathname.startsWith('/jobs/') && !hasBeenRead;

  return (
    <Dialog
      testIdKey='demo-disclaimer'
      open={isDialogOpen}
      onClose={() => setHasBeenRead(true)}
      title={t('SG_DEMO_MODE_DISCLAIMER_HEADER')}
      text={t('SG_DEMO_MODE_DISCLAIMER_TEXT')}
      buttons={[{ label: t('SG_OK'), variant: 'contained', color: 'primary', onClick: () => setHasBeenRead(true) }]}
    />
  );
};
