import React from 'react';
import { Box } from '@mui/material';
import { Grid, Typography } from '@vaillant-professional-ui/component-libs-web';
import Button from '../Button';

interface AgreementRequiredProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const AgreementRequired: React.FC<React.PropsWithChildren<AgreementRequiredProps>> = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <Grid container direction='row' py={6}>
      <Box mb={4}>
        <Typography i18nKey='SG_AGREEMENT_OWN_DEVICE' />
      </Box>
      <Grid container item justifyContent='flex-end' wrap='nowrap'>
        <Box mr={2}>
          <Button
            color='primary'
            variant='outlined'
            onClick={onCancel}
            testId='cancelAddCustomer'
            i18nKey='SG_CANCEL'
          />
        </Box>
        <Button
          color='primary'
          variant='contained'
          onClick={onConfirm}
          testId='addDeviceInstallerAgreementGiven'
          i18nKey='SG_EDIT_SUBMIT_BUTTON'
        />
      </Grid>
    </Grid>
  );
};
