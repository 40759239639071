/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon51200VgPressureLevel401: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon51200vgpressurelevel401', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 651.97 566.93' {...props}>
      <path
        fill='currentColor'
        d='M164.41 481.89a5.67 5.67 0 0 1-5.67 5.67H79.37a5.67 5.67 0 0 1-5.67-5.67v-34a5.67 5.67 0 0 1 5.67-5.67h79.37a5.67 5.67 0 0 1 5.67 5.67zm0-124.72a5.67 5.67 0 0 0-5.67-5.67H79.37a5.67 5.67 0 0 0-5.67 5.66v34a5.67 5.67 0 0 0 5.67 5.67h79.37a5.67 5.67 0 0 0 5.67-5.67zm0-90.71a5.67 5.67 0 0 0-5.67-5.67H79.37a5.67 5.67 0 0 0-5.67 5.67v34a5.67 5.67 0 0 0 5.67 5.67h79.37a5.67 5.67 0 0 0 5.67-5.67zm0-90.71a5.67 5.67 0 0 0-5.67-5.67H79.37a5.67 5.67 0 0 0-5.67 5.67v34a5.67 5.67 0 0 0 5.67 5.67h79.37a5.67 5.67 0 0 0 5.67-5.67zm389.21 129.77-29.38 17.15a147.56 147.56 0 0 1 .3 147.87l29.44 17a181.56 181.56 0 0 0-.36-182.06zm-338.19 91.33h34a147.41 147.41 0 0 1 19.71-73.69l-29.44-17a181.36 181.36 0 0 0-24.27 90.69zm2.74 31.5a179.78 179.78 0 0 0 21.55 59.22l29.44-17a146.14 146.14 0 0 1-17.49-48zm39.71-148.11 26.05 21.88a147.52 147.52 0 0 1 87.29-50.45l-5.87-33.5a181.56 181.56 0 0 0-107.47 62.07zm138.97-64.81v34a147.17 147.17 0 0 1 112.92 52.67l26.05-21.88a181.11 181.11 0 0 0-138.97-64.79zm58.7 65.39a6.72 6.72 0 0 0-8.45 1.94L370.49 384.7a28.35 28.35 0 0 0 9.73 42.3l.64.33a28.34 28.34 0 0 0 40-16.76l38.06-121.7a6.74 6.74 0 0 0-3.37-8.05z'
      />
      <path fill='none' d='M0 0h651.97v566.93H0z' />
    </svg>
  );
};
