import { History } from '../../apis/maintenance/v1/maintenance.types';
import formatISO from 'date-fns/formatISO';
import { ChartData, HistoryDiagnosticModified } from './MonitoringDetails.hooks.types';
import { IHistory, IsoTimestampRecord } from '../../../typings/models';

type MapType = Record<string, string>;
type TimeDataType = Record<string, IHistory>;
type BarDataType = Record<string, IsoTimestampRecord<number>[]>;

const mapColorToData: MapType = {
  waterPressure: 'black',
  returnTemperature: '#2CD34D',
  flowTemperature: '#54A080',
  dhwTemperature: '#46C2EF',
  buildPumpPWM: '#816D75',
  evaporationTemperature: '#FF95A9',
  flowTemperatureSensor: '#2F2967',
  heatExchangerOutletTemperature: '#D062CC',
  highPressureSensor: '#B8D1F1',
  currentSuperheating: '#E52D8A',
  currentSubcooling: '#2A3F96',
  heatExchangerInletTemperature: '#FFB576',
  volumeFlow: '#D0A816',
  dtcStatus: 'grey',
  dtcMaintenance: 'secondary',
  dtcError: 'error',
};

const temperatureKeyTypeMap: MapType = {
  returnTemperature: 'timeseries',
  flowTemperature: 'timeseries',
  dhwTemperature: 'timeseries',
  buildPumpPWM: 'timeseries',
  evaporationTemperature: 'timeseries',
  flowTemperatureSensor: 'timeseries',
  heatExchangerOutletTemperature: 'timeseries',
  highPressureSensor: 'timeseries',
  currentSuperheating: 'timeseries',
  currentSubcooling: 'timeseries',
  heatExchangerInletTemperature: 'timeseries',
  dtcStatus: 'timebar',
  dtcMaintenance: 'timebar',
  dtcError: 'timebar',
};

export const translationIdMapping: Record<string, string> = {
  dtcStatus: 'SG_S_XX',
  dtcMaintenance: 'SG_M_XX',
  dtcError: 'SG_F_XX',
  flowTemperature: 'SG_FLOW_TEMP_SHORT',
  returnTemperature: 'SG_RETURN_TEMP_SHORT',
  waterPressure: 'SG_PRESSURE',
  dhwTemperature: 'SG_DHW_TEMPERATURE_SHORT',
  buildPumpPWM: 'SG_CIRCULATION_PUMP',
  evaporationTemperature: 'SG_EVAPORATION_TEMPERATURE',
  flowTemperatureSensor: 'SG_FLOW_TEMPERATURE_SENSOR',
  heatExchangerOutletTemperature: 'SG_EXCHANGER_OUTLET_TEMPERATURE',
  highPressureSensor: 'SG_HIGH_PRESSURE_SENSOR',
  currentSuperheating: 'SG_CURRENT_SUPERHEATING',
  currentSubcooling: 'SG_CURRENT_SUBCOOLING',
  heatExchangerInletTemperature: 'SG_EXCHANGER_INLET_TEMPERATURE',
  volumeFlow: 'SG_VOLUME_FLOW',
  refill: 'SG_REFILL',
};

const pressureKeyTypeMap: MapType = {
  waterPressure: 'timeseries',
  refill: 'timebar',
  dtcStatus: 'timebar',
  dtcMaintenance: 'timebar',
  dtcError: 'timebar',
};

const literPerHourTypeMap: MapType = {
  volumeFlow: 'timeseries',
  dtcStatus: 'timebar',
  dtcMaintenance: 'timebar',
  dtcError: 'timebar',
};

const transformTimeseries = (key: string, data: TimeDataType, map: Record<string, string>) => {
  return {
    label: key,
    name: translationIdMapping[key] ?? key,
    values: data[key]?.values,
    unit: data[key]?.unit || '',
    color: mapColorToData[key] || 'red',
    type: map[key],
    isSelected: true,
  };
};

const transformTimebars = (key: string, data: BarDataType) => {
  return {
    label: key,
    name: translationIdMapping[key] ?? key,
    values: data[key],
    unit: '',
    color: mapColorToData[key] || 'blue',
    type: 'timebar',
    isSelected: true,
  } as ChartData;
};

const transformToTemperatureChartData = (data: HistoryDiagnosticModified): ChartData[] => {
  const series = Object.keys(data)
    .filter(
      (key: string) =>
        temperatureKeyTypeMap[key] === 'timeseries' && data[key as keyof HistoryDiagnosticModified]?.values?.length,
    )
    .map((key: string) => {
      return transformTimeseries(key, data as TimeDataType, temperatureKeyTypeMap);
    });
  const bars = Object.keys(data)
    .filter((key) => temperatureKeyTypeMap[key] === 'timebar')
    .map((key: string) => {
      return transformTimebars(key, data as BarDataType);
    });

  return [...series, ...bars] as ChartData[];
};

const transformToLiterPerHourChartData = (data: HistoryDiagnosticModified): ChartData[] => {
  const series = Object.keys(data)
    .filter(
      (key: string) =>
        literPerHourTypeMap[key] === 'timeseries' && data[key as keyof HistoryDiagnosticModified]?.values?.length,
    )
    .map((key: string) => transformTimeseries(key, data as TimeDataType, literPerHourTypeMap));

  const bars = Object.keys(data)
    .filter((key) => literPerHourTypeMap[key] === 'timebar')
    .map((key: string) => {
      return transformTimebars(key, data as BarDataType);
    });

  return [...series, ...bars] as ChartData[];
};

const transformToPressureChartData = (
  formatDate: (date: string) => string,
  data: HistoryDiagnosticModified,
  refillHistory?: History,
): ChartData[] => {
  const series = Object.keys(data)
    .filter((key) => pressureKeyTypeMap[key] === 'timeseries' && data[key as keyof HistoryDiagnosticModified]?.values)
    .map((key: string) => {
      return transformTimeseries(key, data as TimeDataType, pressureKeyTypeMap);
    });
  const refills: ChartData[] =
    refillHistory?.values?.map((value) => {
      const timestamp = formatISO(value[0] * 1000);
      return {
        label: 'refill',
        values: [{ timestamp, value: formatDate(timestamp) }],
        name: translationIdMapping['refill'],
        type: 'timebar',
        isSelected: true,
        color: '#588BC6',
        unit: '',
      };
    }) ?? [];

  const bars = Object.keys(data)
    .filter((key) => pressureKeyTypeMap[key] === 'timebar')
    .map((key: string) => {
      return transformTimebars(key, data as BarDataType);
    });
  return [...series, ...bars, ...refills] as ChartData[];
};

export { transformToTemperatureChartData, transformToPressureChartData, transformToLiterPerHourChartData };
