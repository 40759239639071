import { groupBy } from 'lodash';
import * as models from '../typings/models';
import { ManualType } from '../typings/models';
import { DocumentsByType } from '../api/useCases';

export const documentTypeMap = {
  Installation: [
    'Montageanleitung',
    'Installationsanleitung',
    'ErP-Label pdf',
    'Umrüstanleitung',
    'Umbauanleitung',
    'Sicherheitshinweis',
    'Beiblatt/Hinweis',
    'Checklist',
    'Montageanleitung LAZ',
    'Montageschablone',
    'Kurz-Installationsanleitung',
    'Betriebs- und Installationsanleitung',
  ],
  Operation: ['Betriebsanleitung', 'Ersatzteilanleitung', 'Kurz-Betriebsanleitung'],
  Maintenance: ['Wartungsanleitung'],
  Others: [],
};

const getNormalizedName = (group: ManualType): string => {
  return (Object.keys(documentTypeMap).find((key) => documentTypeMap[key].includes(group)) as string) || 'Others';
};

export const getGroupedDocuments = (documents: models.Document[] = []): DocumentsByType => {
  const grouped = {};
  Object.keys(documentTypeMap).forEach((key) => {
    const filtered = documents.filter((document) => getNormalizedName(document.group) === key);
    grouped[key] = {
      total: filtered.length,
      languages: groupBy(filtered, 'language'),
    };
  });

  return grouped as unknown as DocumentsByType;
};

export const mapManualToLanguage: Record<models.ManualType, string> = {
  Installation: 'SG_DOCUMENTATION_INSTALL',
  Operation: 'SG_DOCUMENTATION_OPERATION',
  Maintenance: 'SG_DOCUMENTATION_MAINTENANCE',
  Others: 'SG_LIVE_MONITORING_OTHER',
};

export const mapGroupToI18nKey = {
  Montageanleitung: 'SG_DOCUMENT_ASSEMBLY_INSTRUCTIONS',
  Installationsanleitung: 'SG_DOCUMENT_INSTALLATION_MANUAL',
  'ErP-Label pdf': 'SG_DOCUMENT_ERP_LABEL_PDF',
  Umrüstanleitung: 'SG_DOCUMENT_CONVERSION_MANUAL',
  Umbauanleitung: 'SG_DOCUMENT_CONVERSION_GUIDE',
  Sicherheitshinweis: 'SG_DOCUMENT_SAFETY_NOTE',
  'Beiblatt/Hinweis': 'SG_DOCUMENT_SUPPLEMENT_NOTE',
  Checklist: 'SG_DOCUMENT_CHECKLIST',
  'Montageanleitung LAZ': 'SG_DOCUMENT_ASSEMBLY_INSTRUCTIONS_LAZ',
  Montageschablone: 'SG_DOCUMENT_MOUNTING_TEMPLATE',
  'Kurz-Installationsanleitung': 'SG_DOCUMENT_QUICK_INSTALLATION_GUIDE',
  'Betriebs- und Installationsanleitung': 'SG_DOCUMENT_OPERATING_INSTALLATION_INSTRUCTIONS',
  Betriebsanleitung: 'SG_DOCUMENT_OPERATING_INSTRUCTIONS',
  Ersatzteilanleitung: 'SG_DOCUMENT_SPARE_PARTS_MANUAL',
  'Kurz-Betriebsanleitung': 'SG_DOCUMENT_SHORT_OPERATING_INSTRUCTIONS',
  Wartungsanleitung: 'SG_DOCUMENT_MAINTENANCE_MANUAL',
};
