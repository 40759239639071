import { Card as CardBase, CardContent as CardContentBase } from '../../molecules/Card';
import { styled } from '../../../util/styling';

export const Card = styled(CardBase)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const CardContent = styled(CardContentBase)`
  padding-bottom: ${({ theme }) => theme.spacing(10)} !important;
`;
