import React from 'react';

import { Box } from '@mui/system';
import { BackLink, PageHeader } from '@vaillant-professional-ui/component-libs-web';
import { Link, Outlet } from 'react-router-dom';
import { SectionHeadline } from '../../atoms/SectionHeadline';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { CustomerDetailBreadcrumbs } from '../../molecules/CustomerDetailBreadcrumbs';
import { EmfFilterCard } from '../../organisms/EmfFilterCard';
import { ConsumptionPageProps } from './ConsumptionPage.types';
import { DateRangePicker } from '../../../features/energyMonitoring';

export const ConsumptionPage: React.FC<ConsumptionPageProps> = ({
  loadingDevicesError,
  loadingConsumptionDataError,
  isLoadingCustomerOrDevices,
  isLoadingConsumptionData,
  setFilters,
  mainHeatingDeviceType,
  devices,
  filters,
  handleDateChange,
  handleCalendarOpen,
  hasCop,
  now,
  pageTitle,
  isHybridSystem,
  customer,
  data,
  shouldShowBackLink,
  backLinkPath,
}) => {
  return (
    <LoadingErrorWrapper error={loadingDevicesError} isPending={isLoadingCustomerOrDevices}>
      {!isLoadingCustomerOrDevices && (
        <LoadingErrorWrapper error={loadingConsumptionDataError}>
          <Box width='100%' mb={8}>
            {customer ? <CustomerDetailBreadcrumbs customer={customer} /> : null}
            {!!pageTitle && <PageHeader title={pageTitle} />}
            {shouldShowBackLink ? (
              <Box mb={8}>
                <BackLink
                  linkProps={{ component: Link, to: backLinkPath }}
                  i18nKey='SG_EMF_BACK_TO_ENERGY_INFORMATION'
                  testIdKey='emf'
                />
              </Box>
            ) : null}
            <Box mb={8} display='flex' flexDirection={['column', 'row']} justifyContent='space-between'>
              <DateRangePicker
                datePickerProps={{ onCalendarOpen: handleCalendarOpen }}
                onChange={handleDateChange}
                startDate={filters.startDate}
                endDate={filters.endDate}
                maxDate={now}
              />
            </Box>
            <Box mb={8}>
              <SectionHeadline i18nKey='SG_EMF_SYSTEM_VIEW_SETTINGS' />
              <EmfFilterCard devices={devices} filters={filters} setFilters={setFilters} />
            </Box>
            <Outlet
              context={{
                filters,
                hasCop,
                isLoading: isLoadingConsumptionData,
                data,
                mainHeatingDeviceType,
                isHybridSystem,
              }}
            />
          </Box>
        </LoadingErrorWrapper>
      )}
    </LoadingErrorWrapper>
  );
};
