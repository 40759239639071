/* eslint-disable @typescript-eslint/no-explicit-any */
/* 
  because the State type will change, but we need to keep using the old type 
  here for the migration, it is useless to use the typing 
*/

export const migrations = {
  3: (state: any) => {
    try {
      return {
        settings: {
          userLanguage: state.settings.language.code,
          termsAccepted: state.login.session.userInfo?.user.account.id
            ? [state.login.session.userInfo.user.account.id]
            : [],
          userInfo: state.login.session.userInfo,
          country: state.login.session.country.countryCode,
        },
      };
    } catch {
      return undefined;
    }
  },
};
