import { forwardRef, useImperativeHandle } from 'react';

import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { useFirebase } from '../../providers/firebase';
import { AddCustomerDialog } from '../AddCustomer';
import { PermissionGate } from '../PermissionGate';
import { Button, Icon02301VgPlus01 } from '@vaillant-professional-ui/component-libs-web';

export interface AddCustomerButtonProps {
  boilerSerialNumber?: string | null;
  testId?: string;
  open?: () => void;
}

export interface AddCustomerButtonActions {
  openAddCustomer: () => void;
}

export const AddCustomerButton = forwardRef(function AddCustomerButton(props: AddCustomerButtonProps, ref) {
  const [isAddCustomerOpen, openAddCustomer, closeAddCustomer] = hooks.useDialogState(false);

  const firebaseService = useFirebase();

  useImperativeHandle<unknown, AddCustomerButtonActions>(ref, () => ({
    openAddCustomer,
  }));

  const handleAddClick = () => {
    openAddCustomer();
    firebaseService.logUserAction('addCustomer');
  };

  return (
    <>
      {isAddCustomerOpen && (
        <AddCustomerDialog dialogProps={{ onClose: closeAddCustomer }} boilerSerialNumber={props.boilerSerialNumber} />
      )}

      <PermissionGate requiredPermissions={['ADD_CUSTOMER']}>
        <Button
          testIdKey={props.testId || 'addDevice'}
          color='secondary'
          variant='contained'
          startIcon={<Icon02301VgPlus01 color='white' />}
          onClick={handleAddClick}
          i18nKey={'SG_ADD_DEVICE'}
        />
      </PermissionGate>
    </>
  );
});
