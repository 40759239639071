import React from 'react';

import { Box, IconButton } from '@mui/material';

import {
  Icon06201VgConfigurationSettings01,
  Icon37902VgAccount01,
  Icon39501VgGroup01,
  Icon45101VgClose201,
  Icon46801VgInformation201,
  Icon46901VgQuestion201,
} from '@vaillant-professional-ui/component-libs-web';
import { api } from '@vaillant-professional-ui/pui-frontend-common';
import { DashboardIcon } from '../../SVGIcons/temporaryIcons/DashboardIcon';
import { AddCustomerButton } from '../AddCustomer/AddCustomerButton';
import { PermissionGate } from '../PermissionGate';
import { Container, Content, DrawerHeader, MenuList } from './DrawerContent.styles';
import { DrawerProps } from './DrawerContent.types';
import { getUserName } from './DrawerContent.util';
import { createLinkComponent } from './Link';
import { LogoutButton } from './LogoutButton';

export const DrawerContent: React.FC<React.PropsWithChildren<DrawerProps>> = (props) => {
  const { close, isDemo, onLogout, pathName, user } = props;
  const Link = createLinkComponent(pathName, close);
  const { data } = api.maintenance.v1.useCustomerList({ enabled: false });

  return (
    <Container>
      <DrawerHeader>
        <Box pl={4}>
          <IconButton color='inherit' aria-label='open drawer' onClick={close} edge='start' size='large'>
            <Icon45101VgClose201 width={40} height={40} color='white' />
          </IconButton>
        </Box>
      </DrawerHeader>
      <Content pb={['35%', '50%']}>
        <MenuList>
          <Box>
            <PermissionGate requiredPermissions={['VIEW_DASHBOARD']}>
              <Link
                testIdKey='dashboard'
                firebaseProps={{ identifier: 'dashboard', eventTrigger: 'tab' }}
                to='/dashboard'
                i18nKey='SG_HOME'
                icon={DashboardIcon}
              />
            </PermissionGate>

            <PermissionGate requiredPermissions={['VIEW_CUSTOMER_LIST']}>
              <Link
                testIdKey='customerList'
                firebaseProps={{ identifier: 'customerList', eventTrigger: 'tab' }}
                to='/customers'
                i18nKey='SG_JOBLIST_TITLE'
                icon={Icon39501VgGroup01}
                suffix={data ? `(${data.filter((customer) => customer.status.consentGiven).length})` : null}
              />
            </PermissionGate>

            <PermissionGate requiredPermissions={['VIEW_CODE_ASSISTANT']}>
              <Link
                testIdKey='codeAssist'
                firebaseProps={{ identifier: 'codeFinder', eventTrigger: 'tab' }}
                to='/code-assistant'
                i18nKey='SG_DIAGNOSIS_TITLE'
                icon={Icon46801VgInformation201}
              />
            </PermissionGate>
            <Box mx={5} my={3} sx={{ display: { xs: 'block', md: 'none' } }}>
              <AddCustomerButton testId='addDevice_sidebar' />
            </Box>
          </Box>
          <Box style={{ bottom: 0 }}>
            <PermissionGate requiredPermissions={['VIEW_SETTINGS']}>
              <Link
                testIdKey='settings'
                firebaseProps={{
                  identifier: 'settings',
                  eventTrigger: 'menu',
                }}
                to='/settings'
                i18nKey='SG_SETTINGS_TITLE'
                icon={Icon06201VgConfigurationSettings01}
              />
            </PermissionGate>

            <PermissionGate requiredPermissions={['VIEW_FEEDBACK']}>
              <Link
                testIdKey='feedback'
                firebaseProps={{
                  identifier: 'feedback',
                  eventTrigger: 'tab',
                }}
                to='/feedback'
                i18nKey='SG_SETTINGS_FEEDBACK'
                icon={Icon46901VgQuestion201}
              />
            </PermissionGate>

            {user && (
              <Link
                testIdKey='linkToLogin'
                as={'div'}
                style={{ textDecoration: 'none', cursor: 'default', pointerEvents: 'none' }}
                to='#'
                label={getUserName(user)}
                icon={Icon37902VgAccount01}
              />
            )}

            <LogoutButton
              onLogout={onLogout}
              isDemo={isDemo}
              firebaseProps={{
                identifier: 'logout',
                eventTrigger: 'menu',
              }}
            />
          </Box>
        </MenuList>
      </Content>
    </Container>
  );
};
