import { useCases } from '@vaillant-professional-ui/pui-frontend-common/src/api';
import addHours from 'date-fns/addHours';
import { BreakPoint } from '../../../Utils/breakpoint';
import { DateRangePeriodConfig } from './EmfChart.types';

const getDomainPaddings = (
  isMobile: boolean,
  dayCount?: number,
): Record<useCases.emf.DateRangePeriod, { x: [number, number] }> => {
  const hasLessDays = dayCount && dayCount < 30;
  return isMobile
    ? {
        DAY: { x: [8, 8] },
        WEEK: { x: [24, 20] },
        MONTH: { x: [12, 12] },
        YEAR: { x: [72, 72] },
      }
    : {
        DAY: { x: [44, 8] },
        WEEK: { x: [-96, 32] },
        MONTH: { x: [hasLessDays ? 34 : 44, 24] },
        YEAR: { x: [72, 72] },
      };
};

export const getEndDateForDay = (startDateString: string, endDateString?: string) => {
  if (!endDateString) {
    return addHours(new Date(startDateString), 1);
  }

  return new Date(endDateString);
};

export const getDateRangePeriodConfig = (
  dateRangePeriod: useCases.emf.DateRangePeriod,
  breakPoint: BreakPoint,
  dayCount?: number,
): DateRangePeriodConfig[useCases.emf.DateRangePeriod] => {
  const isMobile = breakPoint === 'sm';
  const domainPaddings = getDomainPaddings(isMobile, dayCount);
  const config: DateRangePeriodConfig = {
    DAY: {
      domain: { y: [0, 5] },
      domainPadding: domainPaddings.DAY,
      victoryLabelY: {
        dx: isMobile ? -10 : -10,
        dy: isMobile ? 12 : 4,
      },
      dateFormat: 'H',
    },
    WEEK: {
      domain: { y: [0, 100], x: [0, 7] },
      domainPadding: domainPaddings.WEEK,
      victoryLabelY: {
        dx: isMobile ? -12 : 0,
        dy: isMobile ? 8 : 8,
      },
      dateFormat: 'eee',
    },
    MONTH: {
      domain: { y: [0, 100] },
      domainPadding: domainPaddings.MONTH,
      victoryLabelY: {
        dx: isMobile ? -24 : 0,
        dy: isMobile ? 12 : 4,
      },
      dateFormat: 'd',
      tickFrequency: isMobile ? 6 : undefined,
    },
    YEAR: {
      domain: { y: [0, 1200] },
      domainPadding: domainPaddings.YEAR,
      victoryLabelY: {
        dx: isMobile ? -32 : -10,
        dy: isMobile ? 12 : 8,
      },
      dateFormat: 'MMM',
    },
  };
  return config[dateRangePeriod];
};

export const translationMap: Record<string, string> = {
  HEATING: 'SG_EMF_FILTER_MODE_HEATING',
  DOMESTIC_HOT_WATER: 'SG_EMF_FILTER_MODE_DHW',
  COOLING: 'SG_EMF_FILTER_MODE_COOLING',
  SUM: 'SG_SOLAR_THERMAL',
};

export const barRatioMap: Record<useCases.emf.DateRangePeriod, number> = {
  DAY: 0.7,
  WEEK: 0.85,
  MONTH: 0.6,
  YEAR: 0.7,
};

export const BarDisplayOrder: Record<string, number> = {
  DOMESTIC_HOT_WATER: 1,
  COOLING: 2,
  HEATING: 3,
};
