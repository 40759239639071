import { styled } from '@vaillant-professional-ui/component-libs-web';

export const TitleBox = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const NoGeolocation = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;
