import React from 'react';

import maxBy from 'lodash/maxBy';
import { useBreakPoint } from '../../../Utils/breakpoint';
import { ChartTooltipMetaProps, TooltipDataPoint } from '../ChartTooltip/ChartTooltip.types';
import { tooltipBreakpointStyles } from './BarChartTooltip.styles';
import { BarChartTooltipProps } from './BarChartTooltip.types';

export const useEmfTooltipMetaData = <T extends TooltipDataPoint>(
  data: BarChartTooltipProps<T>,
): ChartTooltipMetaProps => {
  const { width = 0, height = 0, center, domain, activePoints } = data;
  const centerY = center?.y ?? 0;
  const centerX = center?.x ?? 0;
  const domainY = domain?.y ?? [0, 0];

  const highestValue = maxBy(activePoints, '_y1')?._y1 ?? 0;
  const barHeight = (height / (domainY[1] - domainY[0]) ?? 1) * highestValue;
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = React.useState<number>(0);
  const [containerHeight, setContainerHeight] = React.useState<number>(0);

  const breakpoint = useBreakPoint();
  const isMobile = ['sm', 'md'].includes(breakpoint);
  const { fontSize, xOffset, yOffset } = tooltipBreakpointStyles[breakpoint];

  React.useLayoutEffect(() => {
    setContainerWidth(containerRef?.current?.clientWidth || 0);
    setContainerHeight(containerRef?.current?.clientHeight || 0);
  }, [containerRef]);

  const position = centerX + containerWidth > width ? 'left' : 'right';
  const xLeftPosition = centerX - containerWidth - xOffset;
  const xRightPosition = centerX + xOffset;

  const desktopPositionY =
    centerY + containerHeight / 2 > height
      ? height - containerHeight
      : centerY - containerHeight < 0
      ? 0
      : centerY - yOffset;
  const mobilePositionY = height - barHeight / 2 - containerHeight;

  const xPosition = position === 'right' ? xRightPosition : xLeftPosition < 0 ? 0 : xLeftPosition;
  const yPosition = isMobile ? mobilePositionY : desktopPositionY;

  return {
    fontSize,
    containerRef,
    containerWidth,
    position,
    xPosition,
    xPositionContent: xPosition,
    yPosition,
    yPositionContent: yPosition,
  };
};
