import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import get from 'lodash/get';
import { UseGenerateSvgIconHook } from './Icon.types';

const getColor = (theme: Theme, color: string = '', defaultColor = 'inherit'): string => {
  const themeColor = get(theme.palette, color);
  return themeColor || color || defaultColor;
};

export const useThemedIconProps: UseGenerateSvgIconHook = (
  testIdName,
  { width = 24, height = 24, style = {}, color, testIdKey, ...rest },
) => {
  const theme = useTheme();
  const testId = useInstanceId(testIdName, testIdKey);
  const convertedColor = getColor(theme, color);
  return {
    width,
    height,
    ...testId().testIdAttributes,
    style: {
      ...style,
      color: convertedColor,
    },
    ...rest,
  };
};
