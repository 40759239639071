import { AxiosRequestConfig } from 'axios';
import {
  CreateTroubleCodeNodeData,
  CreateTroubleCodeNoteRequestBody,
  DeleteTroubleCodeNoteRequestParams,
  UpdateTroubleCodeNoteData,
  UpdateTroubleCodeNoteRequestBody,
} from './maintenance.types';

export const maintenanceApi = {
  getCustomer: (serialNumber: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/maintenance-api/v1/systems/${serialNumber}`,
  }),

  getTliState: (serialNumber: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/maintenance-api/v1/systems/${serialNumber}/tli`,
  }),

  getPrediction: (boilerSerialNumber: string): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/maintenance-api/v1/systems/${boilerSerialNumber}/predictions/F.22`,
  }),

  getSparePartsLink: (): AxiosRequestConfig => ({
    method: 'get',
    url: `/pui/maintenance-api/v1/spareparts/link`,
  }),

  getCustomerList: (): AxiosRequestConfig => ({
    timeout: 60000,
    method: 'get',
    url: `/pui/maintenance-api/v1/systems`,
  }),

  addTroubleCodeNote: (params: CreateTroubleCodeNodeData): AxiosRequestConfig<CreateTroubleCodeNoteRequestBody> => ({
    method: 'post',
    url: `/pui/maintenance-api/v1/devices/${params.serialNumber}/notes`,
    data: {
      text: params.text,
      notificationId: params.notificationId,
    },
  }),

  updateTroubleCodeNote: (params: UpdateTroubleCodeNoteData): AxiosRequestConfig<UpdateTroubleCodeNoteRequestBody> => ({
    method: 'put',
    url: `/pui/maintenance-api/v1/devices/${params.serialNumber}/notes/${params.noteId}`,
    data: { text: params.text },
  }),

  deleteTroubleCodeNote: (params: DeleteTroubleCodeNoteRequestParams): AxiosRequestConfig => ({
    method: 'delete',
    url: `/pui/maintenance-api/v1/devices/${params.serialNumber}/notes/${params.noteId}`,
  }),
};
