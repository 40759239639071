import * as React from 'react';
import { getHeatingDeviceType } from '../../../selectors/customer';
import { useCustomerDetails } from '../Customer';
import { useConsumptionData, useEmfDevices } from './emf.hooks';
import { DateRangePeriod } from './emf.hooks.types';
import { ConsumptionFilters } from './emf.types';

interface ConsumptionPagePresenterProps {
  boilerSerialNumber: string;
  logCalendarOpen: () => void;
  logCalendarDateRangeChanged: (mode: DateRangePeriod) => void;
  getPersistedFilters: (defaultFilters: ConsumptionFilters) => ConsumptionFilters;
  persistFilters: (defaultFilters: ConsumptionFilters) => void;
  startDate: Date;
}

export const useConsumptionPagePresenter = ({
  boilerSerialNumber,
  logCalendarOpen,
  logCalendarDateRangeChanged,
  getPersistedFilters,
  persistFilters,
  startDate = new Date(),
}: ConsumptionPagePresenterProps) => {
  const { customer } = useCustomerDetails(boilerSerialNumber);

  const mainHeatingDeviceType = customer ? getHeatingDeviceType(customer) : null;

  const {
    data: devices = [],
    isHybridSystem,
    isLoading: isLoadingDevices,
    defaultFilters,
    error: loadingDevicesError,
  } = useEmfDevices(boilerSerialNumber, startDate);

  const isLoadingCustomerOrDevices = isLoadingDevices || !customer;

  const [filters, _setFilters] = React.useState(getPersistedFilters(defaultFilters));

  const setFilters = (filters: ConsumptionFilters) => {
    persistFilters(filters);
    _setFilters(filters);
  };

  const {
    data,
    errors: loadingConsumptionDataErrors,
    isLoading: isLoadingConsumptionData,
  } = useConsumptionData({ ...filters, boilerSerialNumber });

  const loadingConsumptionDataError = loadingConsumptionDataErrors?.filter(Boolean)[0];

  const hasCop = mainHeatingDeviceType === 'HEAT_PUMP';

  const handleDateChange = (startDate: Date, endDate: Date, mode: DateRangePeriod) => {
    logCalendarDateRangeChanged(mode);
    setFilters({ ...filters, startDate, endDate, dateRangePeriod: mode });
  };

  const handleCalendarOpen = () => {
    logCalendarOpen();
  };

  return {
    loadingDevicesError,
    loadingConsumptionDataError,
    isLoadingCustomerOrDevices,
    isLoadingConsumptionData,
    setFilters,
    mainHeatingDeviceType,
    devices,
    filters,
    handleDateChange,
    handleCalendarOpen,
    hasCop,
    now: startDate,
    isHybridSystem,
    customer,
    data,
  };
};
