import * as React from 'react';
import { Box, IconButton, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { endOfDay, getMonth, getYear, startOfDay } from 'date-fns';
import {
  useConfiguration,
  useInstanceId,
  localeMap,
  useTranslation,
} from '@vaillant-professional-ui/component-libs-common';

import { Container } from './DateRangePicker.styled';
import { DatePickerMode, datePickerModes } from './DateRangePicker.types';

import {
  detectMode,
  getDateFormat,
  getNextInterval,
  getPrevInterval,
  getCurrentInterval,
  getValidatedDateRange,
  getHeaderConfig,
} from './DateRangePicker.util';
import { Input } from './Input';

import {
  DatePickerInputField,
  DatePickerInputNavigationButton,
  DatePicker,
} from '@vaillant-professional-ui/component-libs-web';
import { Icon08201VgRight01 } from '@vaillant-professional-ui/component-libs-web';
import { Icon08101VgLeft01 } from '@vaillant-professional-ui/component-libs-web';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

export interface DateRangePickerProps {
  onChange: (startDate: Date, endDate: Date, mode: DatePickerMode) => void;
  startDate: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  datePickerProps?: Partial<ReactDatePickerProps>;
}

export const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = ({
  startDate: _startDate,
  endDate: _endDate = _startDate,
  minDate,
  maxDate,
  onChange,
  datePickerProps = {},
}) => {
  const { locale, timezone } = useConfiguration();
  const dateFnsLocale = localeMap[locale].dateFns;
  const t = useTranslation();
  const theme = useTheme();
  const testId = useInstanceId('daterangepicker', 'emf');
  const datePickerRef = React.useRef<ReactDatePicker>(null);

  const getInitialDateRange = () =>
    getValidatedDateRange(startOfDay(_startDate), endOfDay(_endDate), dateFnsLocale, timezone);
  const getInitialMode = () => detectMode(_startDate, _endDate, dateFnsLocale, timezone) || 'WEEK';

  const [dateRange, setDateRange] = React.useState(getInitialDateRange());
  const [mode, setMode] = React.useState<DatePickerMode>(getInitialMode());
  const [startDate, endDate] = dateRange;

  const handleSetDateRange = (_dateRange: [Date, Date]) => {
    setDateRange([startOfDay(_dateRange[0]), endOfDay(_dateRange[1])]);
  };

  // If date was set from outside, update internal values
  React.useEffect(() => {
    setMode(getInitialMode());
    handleSetDateRange(getInitialDateRange());
  }, [_startDate, _endDate]);

  // If quick action was selected, preset internal values for current date
  React.useEffect(() => {
    handleSetDateRange(getCurrentInterval(mode, startDate, dateFnsLocale, timezone));
  }, [mode]);

  const handleDecreaseClick = () => {
    const interval = getPrevInterval(mode, startDate, dateFnsLocale, timezone);
    handleSetDateRange(interval);
    submit(...interval);
  };

  const handleIncreaseClick = () => {
    const interval = getNextInterval(mode, startDate, dateFnsLocale, timezone);
    handleSetDateRange(interval);
    submit(...interval);
  };

  const iconProps = {
    color: theme.palette.primary.main,
    width: 32,
    height: 32,
  };

  const cancel = () => {
    handleSetDateRange(getInitialDateRange());
    setMode(getInitialMode());
    datePickerRef.current?.setOpen(false);
  };

  const submit = (_startDate: Date, _endDate: Date) => {
    onChange(_startDate, _endDate, mode);
    datePickerRef.current?.setOpen(false);
  };

  // Either a day, week, month or year was selected
  const handleValueSelected = (update: Date) => {
    handleSetDateRange(getCurrentInterval(mode, update, dateFnsLocale, timezone));
  };

  const canDecrease = !minDate || getPrevInterval(mode, startDate, dateFnsLocale, timezone)[0] >= minDate;
  const canIncrease = !maxDate || getNextInterval(mode, startDate, dateFnsLocale, timezone)[0] <= maxDate;

  return (
    <Container>
      <DatePickerInputField>
        <DatePickerInputNavigationButton
          variant='text'
          onClick={handleDecreaseClick}
          {...testId('prev').testIdAttributes}
          disabled={!canDecrease}
        >
          <Icon08101VgLeft01 {...iconProps} color={canDecrease ? iconProps.color : theme.palette.grey['400']} />
        </DatePickerInputNavigationButton>
      </DatePickerInputField>
      <DatePickerInputField>
        <DatePicker
          ref={datePickerRef}
          allowSameDay={true}
          customInput={
            <Input
              mode={getInitialMode()}
              startDate={_startDate}
              endDate={_endDate}
              locale={dateFnsLocale}
              isOpen={datePickerRef.current ? datePickerRef.current.isCalendarOpen : () => false}
            />
          }
          onClickOutside={cancel}
          onClickCancel={cancel}
          onClickSubmit={() => submit(startDate, endDate)}
          popperPlacement='bottom'
          dateFormat={getDateFormat(mode)}
          disabledKeyboardNavigation={true}
          startDate={mode === 'WEEK' ? startDate : undefined}
          endDate={mode === 'WEEK' ? endDate : undefined}
          selected={startDate}
          minDate={minDate}
          maxDate={maxDate}
          isClearable={false}
          onChange={handleValueSelected}
          renderCustomHeader={
            // Cannot outsource component, because for some reason, react complains about a violation of some rules of hooks
            (headerProps) => {
              const headerConfig = getHeaderConfig(mode, dateFnsLocale, headerProps);

              return (
                <Box display={'flex'} flexDirection='column' {...testId('header').testIdAttributes}>
                  <ToggleButtonGroup
                    color='primary'
                    value={mode}
                    exclusive
                    onChange={(_, value: DatePickerMode) => {
                      headerProps.changeMonth(getMonth(startDate));
                      headerProps.changeYear(getYear(startDate));
                      setMode(value);
                    }}
                  >
                    {datePickerModes.map((_mode) => (
                      <ToggleButton key={_mode} disabled={mode === _mode} value={_mode}>
                        {t(`VG_DATE_RANGE_PICKER_${_mode}`)}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  <Box mt={4} ml={4} mb={2} display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography>{headerConfig.title}</Typography>
                    <Box>
                      <IconButton onClick={headerConfig.decreaseFn} disabled={headerConfig.decreaseDisabled}>
                        <Icon08101VgLeft01 color={headerConfig.decreaseDisabled ? 'grey.400' : 'primary.main'} />
                      </IconButton>
                      <IconButton onClick={headerConfig.increaseFn} disabled={headerConfig.increaseDisabled}>
                        <Icon08201VgRight01 color={headerConfig.increaseDisabled ? 'grey.400' : 'primary.main'} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            }
          }
          // @ts-ignore issue with passing generic params
          selectsRange={false}
          shouldCloseOnSelect={false}
          showPopperArrow={false}
          showMonthYearPicker={mode === 'MONTH'}
          showYearPicker={mode === 'YEAR'}
          {...datePickerProps}
        />
      </DatePickerInputField>
      <DatePickerInputField>
        <DatePickerInputNavigationButton
          disabled={!canIncrease}
          variant='text'
          onClick={handleIncreaseClick}
          {...testId('next').testIdAttributes}
        >
          <Icon08201VgRight01 {...iconProps} color={canIncrease ? iconProps.color : theme.palette.grey['400']} />
        </DatePickerInputNavigationButton>
      </DatePickerInputField>
    </Container>
  );
};
