import React from 'react';

import { Language } from '../../../../typings/models';
import { useCase } from '../useCase';

interface PresenterParams {
  boilerSerialNumber: string;
  language: Language;
}

export const useTabsPresenter = ({ boilerSerialNumber, language }: PresenterParams) => {
  const { data: chartTabs, isLoading, error } = useCase({ boilerSerialNumber, language });
  const tabs = (chartTabs?.tabs ?? []).map((tab) => {
    return {
      id: tab.id,
      testIdKey: tab.id,
      title: tab.title,
      iconType: tab.icon,
    };
  });
  const [selectedTabId, setSelectedTabId] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (tabs[0]?.id && !selectedTabId) {
      setSelectedTabId(tabs[0].id);
    }
  }, [selectedTabId, tabs]);

  return { tabs, selectedTabId, setSelectedTabId, isLoading, error };
};
