import { pickTextColorBasedOnBgColorSimple } from './color';
import { TranslateFnc } from '../../components/meta/I18nProvider';

interface ErelaxBaseProfile {
  id: number;
  targetTemperature: number;
  domesticHotWater: boolean;
}

export interface FixedProfileType extends ErelaxBaseProfile {
  type: 'DAY' | 'NIGHT' | 'AWAY';
}

export interface CustomProfileType extends ErelaxBaseProfile {
  type: 'CUSTOM';
  index: number;
  title: string;
}

export type ErelaxProfile = FixedProfileType | CustomProfileType;

export const customProfileColors = [
  '#FFA400',
  '#CA63FF',
  '#DFB73A',
  '#FB497C',
  '#72A740',
  '#AEDF3A',
  '#FF5D5D',
  '#DE9013',
  '#006E19',
  '#FF74A9',
  '#81A8D9',
  '#00B7FF',
  '#FF0000'
];

export const fixedProfileColors = {
  DAY: '#3ADF5F',
  AWAY: '#00CDE7',
  NIGHT: '#005787'
};

export const getErelaxProfileTitle = (profile: ErelaxProfile, t: TranslateFnc) => {
  return profile.type === 'CUSTOM' ? profile.title : t(`VG_ERELAX_PROFILE_NAME_${profile.type}`);
};

export const getErelaxProfileColor = (profile: ErelaxProfile) => {
  const bgColor = profile.type === 'CUSTOM' ? customProfileColors[profile.index] : fixedProfileColors[profile.type];

  const fgColor = pickTextColorBasedOnBgColorSimple(bgColor, 'white', 'black');
  return { fgColor, bgColor };
};
