import { AxiosHeaders, AxiosInstance } from 'axios';
import { Country, Language } from '../typings/models';

const authHeader = 'Authorization';

export const registerAuthInterceptor = (
  axiosInstance: AxiosInstance,
  country: Country | null,
  language: Language,
  tokenGetter: () => string | null,
) => {
  const id = axiosInstance.interceptors.request.use((config) => {
    const headers = new AxiosHeaders({
      ...config.headers,
      'x-country': country ?? 'XX',
      'x-language': language ?? 'xx',
    });

    const token = tokenGetter();
    if (token) {
      headers[authHeader] = `Bearer ${token}`;
    } else {
      headers.delete(authHeader);
    }

    const newConfig: typeof config = {
      ...config,
      headers,
    };
    return newConfig;
  });

  return () => {
    axiosInstance.interceptors.request.eject(id);
  };
};
