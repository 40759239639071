import partition from 'lodash/partition';

import { useParams, useSearchParams } from 'react-router-dom';

import { features } from '@vaillant-professional-ui/pui-frontend-common';
import Link from '../../../components/Link';
import { DataPointRepresentation, DataPointsCardProps, Entry } from '../../../components/organisms/DataPointsCard';
import { WriteBooleanDatapointDrawerContent } from './drawerContent/WriteBooleanDatapointDrawerContent';
import { WriteEnumDatapointDrawerContent } from './drawerContent/WriteEnumDatapointDrawerContent';
import { WriteNumericDatapointDrawerContent } from './drawerContent/WriteNumericDatapointDrawerContent';
import { generateKeyFromIndexAndSection } from '@vaillant-professional-ui/pui-frontend-common/src/features/systemSettings/deviceSettings/presenter/generateKeyFromIndexAndSection';
import { components } from '@vaillant-professional-ui/pui-frontend-common/src/api/apis/maintenance/v2/autogenerated-schema';

const isCategorizedDataPoints = (
  categorizedDataPoints: features.systemSettings.deviceSettings.CategorizedDataPoints['dataPoints'][number],
): categorizedDataPoints is features.systemSettings.deviceSettings.CategorizedDataPoints => {
  return 'dataPoints' in categorizedDataPoints;
};

const scheduleDeviceTypeMapper: Partial<Record<components['schemas']['DefaultDevice']['device_type'], string>> = {
  'CONTROL/TLI': 'tli',
  'CONTROL/VRC700': 'vrc700',
};

const getLinkProps = ({
  type,
  metadata,
  mutationId,
  key,
}: Pick<
  features.systemSettings.deviceSettings.DataPointRepresentation,
  'key' | 'mutationId' | 'metadata' | 'type'
>) => {
  if (type === 'link' && metadata) {
    const to = {
      weeklyPlanner: `scheduler/${metadata.index}`,
      heatCurve: `heat-curve/${metadata.index}`,
      dhwWeeklyPlanner: `dhw-schedule`,
      // TODO: clean up routing when implementing TLI via device settings endpoint
      [generateKeyFromIndexAndSection(metadata.index!, metadata.section)]: `schedule/${
        scheduleDeviceTypeMapper[metadata.deviceType as components['schemas']['DefaultDevice']['device_type']]
      }/${metadata.index ?? 0}/${metadata.section?.toLowerCase()}`,
    }[key];

    return to ? { component: Link, to } : undefined;
  }
  return mutationId ? { component: Link, to: '?mutationId=' + encodeURIComponent(mutationId) } : undefined;
};

/**
 * DataPointsCard will only show subcategories for the main categories, but no subcategories of a subcategory.
 * So we can keep the mapping simple, w/o using recursion.
 */
const createDataPointsCardViewModelDataPoints = (
  dataPoints: features.systemSettings.deviceSettings.CategorizedDataPoints['dataPoints'],
): Entry[] => {
  const [categorized, uncategorized] = partition(dataPoints, isCategorizedDataPoints);
  const dataPointsUncategorized = createDataPointsCardDataPointRepresentation(uncategorized);
  const subCategories = categorized.flatMap((category) => {
    const dataPointRepresentations =
      category.dataPoints as features.systemSettings.deviceSettings.DataPointRepresentation[];
    return [{ title: category.title }, createDataPointsCardDataPointRepresentation(dataPointRepresentations)];
  });

  return [dataPointsUncategorized, ...subCategories];
};

const createDataPointsCardDataPointRepresentation = (
  dataPointRepresentations: features.systemSettings.deviceSettings.DataPointRepresentation[],
) => {
  return dataPointRepresentations.map((value): DataPointRepresentation => {
    const { mutationId, key, type, measuredAt, metadata, ...rest } = value;
    return {
      subline: measuredAt,
      linkProps: getLinkProps({ mutationId, key, type, metadata }),
      key: value.key.toString(),
      ...rest,
    };
  });
};

export const getDataPointsCardProps = (
  categorizedDataPoints?: features.systemSettings.deviceSettings.CategorizedDataPoints[],
): DataPointsCardProps[] | null => {
  return (
    categorizedDataPoints?.map((category) => {
      return {
        cardProps: { testIdKey: category.testIdKey },
        title: category.title,
        key: category.title,
        dataPoints: createDataPointsCardViewModelDataPoints(category.dataPoints),
      };
    }) ?? null
  );
};

export const usePresenter = (
  useDeviceSettingsPresenter:
    | typeof features.systemSettings.deviceSettingsTli.useTliPresenter
    | typeof features.systemSettings.deviceSettings.usePresenter,
) => {
  const { systemId, deviceId } = useParams() as { systemId: string; deviceId: string };

  const [searchParams, setSearchParams] = useSearchParams();
  const activeMutation = searchParams.has('mutationId') ? searchParams.get('mutationId') : null;

  const handleCloseDrawer = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('mutationId');
    setSearchParams(newSearchParams);
  };

  const { categorizedDataPoints, writeDatapointPresentationData, refetch, ...props } = useDeviceSettingsPresenter({
    systemId,
    deviceId,
    activeMutation,
  });

  const dataPointsCardsProps = getDataPointsCardProps(categorizedDataPoints);

  const getDrawerContent = () => {
    if (writeDatapointPresentationData) {
      switch (writeDatapointPresentationData.type) {
        case 'enum':
          return <WriteEnumDatapointDrawerContent {...writeDatapointPresentationData} onClose={handleCloseDrawer} />;
        case 'numeric':
          return <WriteNumericDatapointDrawerContent {...writeDatapointPresentationData} onClose={handleCloseDrawer} />;
        case 'boolean':
          return <WriteBooleanDatapointDrawerContent {...writeDatapointPresentationData} onClose={handleCloseDrawer} />;
      }
    }
    return null;
  };
  const drawerContent = getDrawerContent();

  return { drawerContent, handleCloseDrawer, dataPointsCardsProps, refetch, ...props };
};
