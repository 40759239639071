import { useDispatch, useSelector } from 'react-redux';
import { getHasDismissedMaxDatapointsWarning } from '../../../../store/selectors';
import { setHasDismissedMaxDatapointsWarning as setHasDismissedMaxDatapointsWarningAction } from '../../../../store/actions';

export const useCaseMaxDatapointsTooltip = () => {
  const dispatch = useDispatch();

  const hasDismissedMaxDatapointsWarning = useSelector(getHasDismissedMaxDatapointsWarning);

  const setHasDismissedMaxDatapointsWarning = (value: boolean) => {
    dispatch(setHasDismissedMaxDatapointsWarningAction(value));
  };

  return { hasDismissedMaxDatapointsWarning, setHasDismissedMaxDatapointsWarning };
};
