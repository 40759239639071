import Box from '@mui/material/Box';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { useFirebase } from '../../providers/firebase';
import Button from '../Button';

import Select from '../Select';
import { SelectChangeEvent } from '@mui/material';

interface ChooseEmailProps {
  value: string;
  emailAddresses: string[];
  onCancel: () => void;
  onChange: (email: string) => void;
  onSubmit: (email: string) => void;
}

export const ChooseEmail: React.FC<React.PropsWithChildren<ChooseEmailProps>> = ({
  emailAddresses,
  value,
  onCancel,
  onChange,
  onSubmit,
}) => {
  const firebaseService = useFirebase();

  const handleEmailChange = (event: SelectChangeEvent<string>) => {
    firebaseService.logUserAction('selectLoyalityRegisteredEmail');
    onChange(event.target.value);
  };

  const handleOpen = () => {
    firebaseService.logUserAction('addDeviceOpenEmailAddressSelection');
  };

  return (
    <Box mb={4} mt={4}>
      <Box mb={2}>
        <Typography variant='caption' i18nKey={'SG_ADDCUSTOMER_EMAIL'} color='initial' />
      </Box>
      <Box mb={6}>
        <Select
          value={value}
          data-testid='customer-email-select'
          options={emailAddresses.map((address) => ({
            value: address,
            label: address,
          }))}
          variant='outlined'
          fullWidth={true}
          onOpen={handleOpen}
          // @ts-ignore
          onChange={handleEmailChange!}
          inputProps={{
            'data-testid': 'customerEmailInput',
          }}
        />
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <Box mr={2}>
          <Button
            color='primary'
            variant='outlined'
            i18nKey='SG_CANCEL'
            testId='addDeviceOtherSerialNumberBtn'
            onClick={onCancel}
          />
        </Box>
        <Button
          color='primary'
          variant='contained'
          i18nKey='SG_CONTINUE'
          onClick={() => onSubmit(value)}
          testId='addCustomer'
        />
      </Box>
    </Box>
  );
};
