import React from 'react';
import { Box, IconButton, TableCell } from '@mui/material';
import {
  Icon08201VgRight01,
  Icon43801VgQuickActions01,
  Typography,
} from '@vaillant-professional-ui/component-libs-web';
import { TableRow } from './Dashboard.types';
import { selectors } from '@vaillant-professional-ui/pui-frontend-common';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { StatusOccurrenceList } from '../organisms/StatusOccurrenceList';
import { CustomerNameStyled, TableCellWithoutPadding } from './Dashboard.styles';
import {
  Device,
  IBoiler,
  ICustomer,
  ICustomerDetail,
} from '@vaillant-professional-ui/pui-frontend-common/src/typings/models';

export const DashboardTableRow = ({
  item,
  onClickRowActions,
  getBoilerMarketingName,
  getBoiler,
  formatCustomerName,
  formatAddress,
}: {
  item: TableRow;
  onClickRowActions: (event: React.MouseEvent<HTMLElement>, data: TableRow) => void;
  getBoilerMarketingName: (boiler: Device) => string;
  getBoiler: (customer: ICustomer | ICustomerDetail) => IBoiler | null;
  formatCustomerName: (customer?: ICustomer | ICustomerDetail | undefined, reversed?: boolean) => string;
  formatAddress: (customer: ICustomer | ICustomerDetail) => { firstLine: string; secondLine: string };
}) => {
  const testId = useInstanceId('enhancedtable', 'table');
  const {
    id,
    _meta: { customer, customerStatusItems, hasUnreadNotifications },
    _disabled: isDisabled,
  } = item;

  const boiler = getBoiler(customer);
  const address = formatAddress(customer);

  const formattedCustomerName = formatCustomerName(customer, true);

  return (
    <>
      <TableCell>
        <CustomerNameStyled disabled={isDisabled} bold={hasUnreadNotifications}>
          {formattedCustomerName || id}
        </CustomerNameStyled>
      </TableCell>
      <TableCellWithoutPadding colSpan={2}>
        <StatusOccurrenceList items={customerStatusItems} testIdKey={id} />
      </TableCellWithoutPadding>
      <TableCell>
        {boiler ? (
          <>
            <Typography disabled={isDisabled}>{selectors.boiler.getNomenclature(boiler)}</Typography>
            <Typography disabled={isDisabled} variant='caption'>
              {getBoilerMarketingName(boiler)}
            </Typography>
          </>
        ) : null}
      </TableCell>
      <TableCell>
        <>
          <Typography disabled={isDisabled}>{address.firstLine}</Typography>
          <Typography disabled={isDisabled}>{address.secondLine}</Typography>
        </>
      </TableCell>
      <TableCell>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
          <IconButton onClick={(event) => onClickRowActions(event, item)}>
            <Icon43801VgQuickActions01
              width={32}
              height={32}
              color='primary.main'
              {...testId(`row-${id}-actions`).testIdAttributes}
            />
          </IconButton>
          {!isDisabled && (
            <Icon08201VgRight01
              color='primary.main'
              width={32}
              height={32}
              {...testId(`row-${id}-arrowRight`).testIdAttributes}
            />
          )}
        </Box>
      </TableCell>
    </>
  );
};
