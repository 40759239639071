import { models, selectors } from '@vaillant-professional-ui/pui-frontend-common';

export interface Notification {
  id: string;
  heatGeneratorSerialNumber?: string;
  title?: string;
  body?: string;
  dateTime?: string;
  isRead?: boolean;
}
export interface LabelledNotification {
  title: string;
  notification: Notification;
}

export interface NotificationTimeGroup {
  title: string;
  notifications: Notification[];
}

export interface NotificationBlock {
  title: string;
  timeGroups: NotificationTimeGroup[];
}

const {
  customer: { isSelectable, getBoilerSerialNumber, getEndUserFullName, getActiveTroubleCodes },
} = selectors;

export const customersToNotifications = (
  customers: models.ICustomer[],
  readNotifications: string[],
): Notification[] => {
  const notifications: Notification[] = [];
  const activeCustomers = customers.filter((customer) => !selectors.customer.isPending(customer));

  activeCustomers.forEach((customer) => {
    const activeDtcs = getActiveTroubleCodes(customer);
    activeDtcs.forEach((dtc) => {
      const name = getEndUserFullName(customer);
      const heatGeneratorSerialNumber = getBoilerSerialNumber(customer);

      notifications.push({
        id: dtc.id,
        title: `${name ? `${name}: ` : ''}${dtc.name ? `${dtc.name} ` : ''}${dtc.title || heatGeneratorSerialNumber}`,
        body: dtc.description ?? undefined,
        dateTime: dtc.occurredAt,
        isRead: readNotifications.includes(dtc.id),
        heatGeneratorSerialNumber: isSelectable(customer) ? heatGeneratorSerialNumber : '',
      });
    });
  });
  return notifications;
};

export const notificationsToTimeGroups = (
  notifications: Notification[],
  formatDate: (date: string) => string,
  formatRelativeTime: (unixTs: number) => string,
): NotificationTimeGroup[] => {
  const labelledNotifications: LabelledNotification[] = notifications
    .sort((a, b) => {
      return new Date(b.dateTime ?? 0).getTime() / 1000 - new Date(a.dateTime ?? 0).getTime() / 1000;
    })
    .map((notification) => ({
      title: formatRelativeTime(new Date(notification.dateTime ?? 0).getTime() / 1000),
      notification: {
        ...notification,
        dateTime: notification.dateTime ? formatDate(notification.dateTime) : '',
      },
    }));

  const obj: Record<string, Notification[]> = {};
  labelledNotifications.forEach((notification) => {
    const title = notification.title || '';
    if (!obj[title]) {
      obj[title] = [notification.notification];
    } else obj[title] = [...obj[title], notification.notification];
  });
  return Object.keys(obj).map((key) => ({ title: key, notifications: obj[key] }));
};

export const getUnreadNotifications = (notifications: Notification[], readNotifications: string[]): Notification[] => {
  return notifications.filter((notification) => !readNotifications.includes(notification.id));
};
