import * as React from 'react';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { SwitchProps } from './Switch.types';
import { IOSSwitch } from './Switch.styles';

export const Switch: React.FunctionComponent<SwitchProps> = ({ testIdKey, ...rest }) => {
  const testId = useInstanceId('switch', testIdKey);

  return (
    <IOSSwitch focusVisibleClassName='.Mui-focusVisible' disableRipple {...testId('root').testIdAttributes} {...rest} />
  );
};
