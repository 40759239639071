import { last, startCase } from 'lodash';

import { useSelector } from 'react-redux';

import { getFractionDigitsCount, getMeasuredAt, printableUnit } from '../../../../api/apis/maintenance/v2';
import { MeasuredValue } from '../../../../api/apis/maintenance/v2/maintenance.types';
import { DateFormatter, useDateFormatter, useTranslation } from '../../../../libs/hooks';
import { getLanguage } from '../../../../store/selectors';
import { TranslationFnc } from '../../../../typings/models';
import { NumberFormatter, useNumberFormatWithFallback } from '../../../../util/l10n';
import { useDeviceSettingsAndMutations } from '../useCase';
import { Category, findDataPointForMutation, isCategory } from '../useCase/model/category';
import { DataPoint } from '../useCase/model/dataPoint';
import { CategorizedDataPoints } from './model/categorizedDataPoints';
import { writeDatapointPresenter } from './writeDatapointPresenter/writeDatapointPresenter';
import { generateKeyFromIndexAndSection } from './generateKeyFromIndexAndSection';

const translateWithFallback = (datapointTitle: string, t: TranslationFnc, transform: (s: string) => string): string => {
  const title = t(datapointTitle);
  if (title.includes('backend.settings')) {
    return transform(last(title.split('.')) ?? '');
  }
  return title;
};

/**
 * Translate the value,
 * use a transform translation, if the given title is not found in the translation catalogue
 */
const translateDatapointValue = (datapointTitle: string, t: TranslationFnc): string => {
  return translateWithFallback(datapointTitle, t, (s) => startCase(s.toLowerCase()));
};

export const readableMeasuredValue = <T>(
  measuredValue: MeasuredValue | undefined,
  formatNumber: NumberFormatter,
  t: TranslationFnc,
  writeParams?: T | null,
): string => {
  const defaultValue = '--';

  if (!measuredValue) {
    return defaultValue;
  }

  if ('title' in measuredValue && measuredValue.title) {
    return translateDatapointValue(measuredValue.title, t);
  }

  if ('data_type' in measuredValue) {
    if (['string', 'temporal'].includes(measuredValue.data_type)) {
      return measuredValue.value as string;
    }

    if (measuredValue.data_type === 'boolean') {
      return t(measuredValue.value ? 'SG_ON' : 'SG_OFF');
    }

    if (measuredValue.data_type === 'number') {
      if (measuredValue.value === null || measuredValue.value === undefined) {
        return defaultValue;
      }
      const unit = 'unit' in measuredValue && measuredValue.unit ? printableUnit(measuredValue.unit) : '';
      const fractionDigitsCount = getFractionDigitsCount(measuredValue.value as number, writeParams);
      const value = formatNumber(Number(measuredValue.value), {
        minimumFractionDigits: fractionDigitsCount,
        maximumFractionDigits: fractionDigitsCount,
      });
      return `${value} ${unit}`.trim();
    }
  }

  return defaultValue;
};

interface Props {
  systemId: string;
  deviceId: string;
  activeMutation: string | null;
}

const categorizedDataPointMapperFactory = (
  t: TranslationFnc,
  formatNumber: NumberFormatter,
  dateFormatter: DateFormatter,
) => {
  const mapDataPoint = (value: DataPoint) => {
    if (value.type === 'link') {
      return {
        type: value.type,
        metadata: value.metadata,
        key: generateKeyFromIndexAndSection(value.metadata.index!, value.metadata.section),
        title: value.title,
        testIdKey: generateKeyFromIndexAndSection(value.metadata.index!, value.metadata.section),
        disabled: false,
      };
    }
    const measuredAt = getMeasuredAt(value.measuredValue);
    return {
      title: value.title,
      subtitle: readableMeasuredValue(value.measuredValue, formatNumber, t, value.writeParams),
      key: value.id,
      type: value.type,
      measuredAt: (measuredAt && dateFormatter(measuredAt)) ?? undefined,
      mutationId: value.mutationId,
      testIdKey: `settings-${value.id}`,
      disabled: value.mutationId ? !value.writeParams : false,
    };
  };

  const mapCategory = (category: Category): CategorizedDataPoints => {
    return {
      title: category.title,
      dataPoints: category.values.map((value) => {
        if (isCategory(value)) {
          return mapCategory(value);
        }
        return mapDataPoint(value);
      }),
      testIdKey: category.title,
    };
  };

  return mapCategory;
};

export const usePresenter = ({ systemId, deviceId, activeMutation }: Props) => {
  const language = useSelector(getLanguage);

  const { categories, createUseMutation, error, isLoading, refetch } = useDeviceSettingsAndMutations({
    systemId,
    deviceId,
    language,
  });

  const t = useTranslation();
  const formatNumber = useNumberFormatWithFallback();
  const dateFormatter = useDateFormatter();

  const mapCategory = categorizedDataPointMapperFactory(t, formatNumber, dateFormatter);

  const categorizedDataPoints = categories.map(mapCategory);

  const activeMutationDataPoint =
    categories?.length && activeMutation ? findDataPointForMutation(categories, activeMutation) : null;

  const writeDatapointPresentationData = writeDatapointPresenter(
    activeMutationDataPoint,
    activeMutation,
    createUseMutation,
    t,
  );

  return { categorizedDataPoints, writeDatapointPresentationData, error, isLoading, refetch };
};
