import { styled } from '../../../util/styling';
import { Typography as TypographyBase } from '@mui/material';

export const getTextTransform = (letterCase: string = 'default') => {
  if (letterCase === 'allCaps') {
    return 'uppercase';
  }

  if (letterCase === 'sentence') {
    return 'capitalize';
  }

  return undefined;
};

export const TypographyStyled = styled(TypographyBase)<{ $bold?: boolean }>`
  ${({ $bold }) => $bold && `font-weight: bold;`}
`;
