import React from 'react';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { ConditionalWrapper } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { ActivitySpinnerProps, ActivitySpinnerVariant } from './ActivitySpinner.types';
import { Icon, IconContainer, Modal, OuterContainer } from './ActivitySpinner.styled';

const variants: Record<ActivitySpinnerVariant, { containerColor: string; iconColor: string }> = {
  contained: { containerColor: 'common.black', iconColor: 'common.white' },
  transparent: { containerColor: 'transparent', iconColor: 'primary.main' },
};

const ActivitySpinner: React.FC<React.PropsWithChildren<ActivitySpinnerProps>> = ({
  onScreen = false,
  loading,
  showLoadingText,
  loadingText,
  width = 40,
  variant = 'contained',
}) => {
  const t = hooks.useTranslation();

  if (!loading) {
    return null;
  }

  const colors = variants[variant];

  const loader = (
    <OuterContainer>
      <IconContainer bgcolor={colors.containerColor} $isTransparent={variant === 'transparent'}>
        <Icon width={width} height={width} color={colors.iconColor} />
      </IconContainer>
      {showLoadingText && <Typography>{loadingText || t('SG_LOADER')}</Typography>}
    </OuterContainer>
  );

  return (
    <ConditionalWrapper condition={onScreen} wrapper={(children) => <Modal>{children}</Modal>}>
      {loader}
    </ConditionalWrapper>
  );
};
export default ActivitySpinner;
