import * as yup from 'yup';

export const routeParamsSchema = yup.object().shape({
  boilerSerialNumber: yup.string().required(),
  systemId: yup.string().required(),
  deviceId: yup.string().required(),
  zone: yup.string().required(),
});

export type RouteParamsSchema = yup.InferType<typeof routeParamsSchema>;
