import { secondsInDay } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import startOfDay from 'date-fns/startOfDay';
import flatMap from 'lodash/flatMap';
import range from 'lodash/range';
import { SchedulePayload, ScheduleProfile as ErelaxProfile } from '../../apis/schedules/v2/schedules.types';
import {
  ScheduleProfile,
  ScheduleVgComponents,
  ScheduleWeekPeriod,
  SystemSchedule,
  TimeOffset,
  WeekDays,
} from './schedules.erelax.types';
import { util } from '@vaillant-professional-ui/component-libs-common';

const PERIOD_MIN_PIXELS = 10;
const dayStart = [0, 1440, 2880, 4320, 5760, 7200, 8640];
const dayEnd = [1440, 2880, 4320, 5760, 7200, 8640, 10080];
const minutesInDay = secondsInDay / 60;

const filterInRange = (timeOffsets: TimeOffset[], lowerBound: number, upperBound: number) =>
  timeOffsets.filter((activation) => activation.timeOffset >= lowerBound && activation.timeOffset <= upperBound);

const timeOffsetsToWeek = (timeOffsets: TimeOffset[]) => {
  let dayBefore: TimeOffset[] = [];
  const weekDays: WeekDays = [...range(7)].map((_day, index) => {
    const lowerBound = index === 0 ? 0 : dayEnd[index - 1];
    const currentDay = filterInRange(timeOffsets, lowerBound, dayEnd[index]);

    if (currentDay.length < 1) {
      const lastPeriodOfDayBefore = dayBefore[dayBefore.length - 1];
      currentDay.push({ ...lastPeriodOfDayBefore, timeOffset: dayEnd[index] });
    } else {
      dayBefore = currentDay;
    }
    return currentDay;
  });
  return weekDays;
};

const scheduleToTimeOffsets = (activeScheduleDetails: SystemSchedule): TimeOffset[] => {
  return flatMap(activeScheduleDetails.profiles, (profile) => {
    return profile.activations.map((activation) => ({
      id: profile.id,
      name: profile.name,
      type: profile.type,
      timeOffset: activation.timeOffset,
      targetTemperature: profile.centralHeating.roomTemperatureTarget,
      domesticHotWater: profile.domesticHotWater.enabled,
    }));
  }).sort((offsetCurr, offsetPrev) => offsetCurr.timeOffset - offsetPrev.timeOffset);
};

const getCurrentProfile = (activeScheduleDetails: SystemSchedule, now = Date.now()): TimeOffset | undefined => {
  const currentOffset = differenceInMinutes(now, startOfDay(now));
  let previousProfile: TimeOffset | undefined;
  let currentProfile;
  scheduleToTimeOffsets(activeScheduleDetails).forEach((profile) => {
    if (previousProfile) {
      if (currentOffset >= previousProfile.timeOffset && currentOffset <= profile.timeOffset) {
        currentProfile = previousProfile;
      }
    }
    previousProfile = profile;
  });
  return currentProfile;
};

const getProfiles = (activeScheduleDetails: SystemSchedule): ScheduleProfile[] => {
  return activeScheduleDetails.profiles.map((profile) => ({
    name: profile.name,
    type: profile.type,
    id: profile.id,
    domesticHotWater: profile.domesticHotWater.enabled,
    targetTemperature: profile.centralHeating.roomTemperatureTarget,
  }));
};

const transformScheduleToWeekPeriods = (activeScheduleDetails: SystemSchedule): ScheduleWeekPeriod[][] => {
  // combine all profile offsets in one array and sort
  const timeOffsets = scheduleToTimeOffsets(activeScheduleDetails);
  const week = timeOffsetsToWeek(timeOffsets);

  const weekPeriods = [];
  let lastDayPeriod;

  for (let weekIndex = 0; weekIndex < week.length; weekIndex++) {
    const day = week[weekIndex];
    const dayPeriods = [];
    let previousPeriod;

    for (let dayIndex = 0; dayIndex < day.length; dayIndex++) {
      const time = day[dayIndex];

      if (previousPeriod) {
        dayPeriods.push({
          id: previousPeriod.id,
          name: previousPeriod.name,
          periodDuration: time.timeOffset - previousPeriod.timeOffset,
          type: previousPeriod.type,
          targetTemperature: previousPeriod.targetTemperature,
          domesticHotWater: previousPeriod.domesticHotWater,
          timeOffset: previousPeriod.timeOffset,
          size: (time.timeOffset - previousPeriod.timeOffset) / 1.5,
          minSize: PERIOD_MIN_PIXELS,
          isFakePeriod: false,
        });
      } else if (weekIndex > 0 && lastDayPeriod) {
        //Add pre period
        dayPeriods.unshift({
          id: lastDayPeriod.id,
          name: lastDayPeriod.name,
          periodDuration: time.timeOffset - dayStart[weekIndex],
          type: lastDayPeriod.type,
          targetTemperature: lastDayPeriod.targetTemperature,
          domesticHotWater: lastDayPeriod.domesticHotWater,
          size: (time.timeOffset - dayStart[weekIndex]) / 1.5,
          minSize: PERIOD_MIN_PIXELS,
          timeOffset: 1440 * weekIndex,
          isFakePeriod: true,
        });
      }
      previousPeriod = time;
    }
    lastDayPeriod = previousPeriod;

    //Add post period
    const end = dayEnd[weekIndex];
    if (previousPeriod && end - previousPeriod.timeOffset > 0) {
      dayPeriods.push({
        id: previousPeriod.id,
        name: previousPeriod.name,
        periodDuration: end - previousPeriod.timeOffset,
        type: previousPeriod.type,
        targetTemperature: previousPeriod.targetTemperature,
        domesticHotWater: previousPeriod.domesticHotWater,
        timeOffset: previousPeriod.timeOffset,
        size: (end - previousPeriod.timeOffset) / 1.5,
        minSize: PERIOD_MIN_PIXELS,
        isFakePeriod: false,
      });
    }
    weekPeriods.push(dayPeriods);
  }
  return weekPeriods;
};

const getPostPeriodProfile = (
  schedule: ScheduleWeekPeriod[],
  changePeriodProfile: ScheduleWeekPeriod,
): ScheduleWeekPeriod => {
  const postPeriodProfile = schedule.find(
    (schedulePeriod) => schedulePeriod.timeOffset > changePeriodProfile.timeOffset,
  );
  return postPeriodProfile ? postPeriodProfile : schedule[0];
};

const getPrePeriodProfile = (
  schedule: ScheduleWeekPeriod[],
  changePeriodProfile: ScheduleWeekPeriod,
): ScheduleWeekPeriod | undefined => {
  const prePeriodProfile = schedule
    .slice()
    .reverse()
    .find((schedulePeriod) => schedulePeriod.timeOffset < changePeriodProfile.timeOffset);
  return changePeriodProfile.timeOffset === 0 ? schedule[schedule.length - 1] : prePeriodProfile;
};

const changeScheduleByPrecedingPeriod = (
  schedule: ScheduleWeekPeriod[],
  prePeriodProfile: ScheduleWeekPeriod,
  postPeriodProfile: ScheduleWeekPeriod,
  changePeriodProfile: ScheduleWeekPeriod,
) => {
  // case of different post and per periods just delete changePeriod

  if (prePeriodProfile.id !== postPeriodProfile.id) {
    // edge case when changing first period of first day
    if (changePeriodProfile.timeOffset === 0) {
      changePeriodProfile.type = prePeriodProfile.type;
      changePeriodProfile.id = prePeriodProfile.id;

      const updatedSchedule = schedule.filter((period) => period.timeOffset !== 0);
      updatedSchedule.push(changePeriodProfile);

      return updatedSchedule;
    } else {
      return schedule.filter((period) => period.timeOffset !== changePeriodProfile.timeOffset);
    }
  } else if (prePeriodProfile.id === postPeriodProfile.id) {
    if (changePeriodProfile.timeOffset === 0) {
      changePeriodProfile.type = prePeriodProfile.type;
      changePeriodProfile.id = prePeriodProfile.id;

      const updatedSchedule = schedule
        .filter((period) => period.timeOffset !== 0)
        .filter((period) => period.timeOffset !== postPeriodProfile.timeOffset);
      updatedSchedule.push(changePeriodProfile);

      return updatedSchedule;
    } else {
      // case when post and pre periods are the same
      return schedule
        .filter((period) => period.timeOffset !== changePeriodProfile.timeOffset)
        .filter((period) => period.timeOffset !== postPeriodProfile.timeOffset);
    }
  }
  return schedule;
};

const changeScheduleByPostPeriod = (
  schedule: ScheduleWeekPeriod[],
  prePeriodProfile: ScheduleWeekPeriod,
  postPeriodProfile: ScheduleWeekPeriod,
  changePeriodProfile: ScheduleWeekPeriod,
) => {
  if (prePeriodProfile.id !== postPeriodProfile.id) {
    // case of different post and per periods just delete changePeriod
    const updatedPeriod = schedule.find((period) => period.timeOffset === postPeriodProfile.timeOffset);
    if (updatedPeriod) {
      updatedPeriod.timeOffset = changePeriodProfile.timeOffset;
    }

    const updatedSchedule = schedule
      .filter((period) => period.timeOffset !== changePeriodProfile.timeOffset)
      .filter((period) => period.timeOffset !== postPeriodProfile.timeOffset);

    if (updatedPeriod) {
      updatedSchedule.push(updatedPeriod);
    }

    return updatedSchedule;
  } else if (prePeriodProfile.id === postPeriodProfile.id) {
    // case when post and pre periods are the same
    return schedule
      .filter((period) => period.timeOffset !== changePeriodProfile.timeOffset)
      .filter((period) => period.timeOffset !== postPeriodProfile.timeOffset);
  }
  return schedule;
};

const changeScheduleBySelectedPeriod = (
  schedule: ScheduleWeekPeriod[],
  selectedChangeProfile: ScheduleWeekPeriod,
  changePeriodProfile: ScheduleWeekPeriod,
) => {
  const _updatedPeriod: ScheduleWeekPeriod | undefined = schedule.find(
    (period) => period.timeOffset === changePeriodProfile.timeOffset,
  );

  if (!_updatedPeriod) {
    throw new Error('period not found');
  }

  const updatedPeriod = { ..._updatedPeriod };

  updatedPeriod.id = selectedChangeProfile.id;
  updatedPeriod.type = selectedChangeProfile.type;
  updatedPeriod.targetTemperature = selectedChangeProfile.targetTemperature;
  updatedPeriod.name = selectedChangeProfile.name;

  const updatedSchedule = schedule.filter((period) => period.timeOffset !== changePeriodProfile.timeOffset);
  if (updatedPeriod) {
    updatedSchedule.push(updatedPeriod);
  }
  return updatedSchedule;
};

const insertNewPeriodConnectingWithPostPeriod = (
  schedule: ScheduleWeekPeriod[],
  postPeriodProfile: ScheduleWeekPeriod,
  selectedNewProfile: ScheduleProfile,
  newPeriodStart: number,
) => {
  if (selectedNewProfile.id === postPeriodProfile.id) {
    const _updatedPeriod: ScheduleWeekPeriod | undefined = schedule.find(
      (period) => period.timeOffset === postPeriodProfile.timeOffset,
    );

    if (!_updatedPeriod) {
      throw new Error('period not found');
    }
    const updatedPeriod = { ..._updatedPeriod };
    updatedPeriod.timeOffset = newPeriodStart;

    const updatedSchedule = schedule.filter((period) => period.timeOffset !== postPeriodProfile.timeOffset);
    updatedSchedule.push(updatedPeriod);
    return updatedSchedule;
  } else {
    const newPeriod = {
      id: selectedNewProfile.id,
      name: selectedNewProfile.name,
      type: selectedNewProfile.type,
      timeOffset: newPeriodStart,
      targetTemperature: selectedNewProfile.targetTemperature,
      domesticHotWater: selectedNewProfile.domesticHotWater,
    };
    return [...schedule, newPeriod];
  }
};

const insertNewPeriod = (
  schedule: ScheduleWeekPeriod[],
  selectedNewProfile: ScheduleProfile,
  changePeriodProfile: ScheduleProfile,
  newPeriodStart: number,
  newPeriodEnd: number,
) => {
  const newPeriod = {
    id: selectedNewProfile.id,
    name: selectedNewProfile.name,
    type: selectedNewProfile.type,
    timeOffset: newPeriodStart,
    targetTemperature: selectedNewProfile.targetTemperature,
    domesticHotWater: selectedNewProfile.domesticHotWater,
  };
  const newPeriodInChangingPeriod = {
    id: changePeriodProfile.id,
    name: changePeriodProfile.name,
    type: changePeriodProfile.type,
    timeOffset: newPeriodEnd,
    targetTemperature: changePeriodProfile.targetTemperature,
    domesticHotWater: changePeriodProfile.domesticHotWater,
  };
  return [...schedule, newPeriod, newPeriodInChangingPeriod];
};

export const transformWeekDayPeriodsToSchedule = (
  scheduleData: ScheduleVgComponents[],
  serialNumber: string,
): SchedulePayload => {
  const profileData = new Map<number, SystemSchedule['profiles'][0]>();

  scheduleData.forEach((entry, indexDaySchedule) => {
    const offset = indexDaySchedule * minutesInDay;

    const idOfLastTimeFrameOfPreviousDay = indexDaySchedule
      ? scheduleData[indexDaySchedule - 1].values.sort((a, b) => b.start - a.start)[0].profile.id
      : -1;

    for (const timeWindow of entry.values) {
      const id = timeWindow.profile.id;
      if (offset != 0 && timeWindow.start === 0 && id === idOfLastTimeFrameOfPreviousDay) {
        continue;
      }

      const type = timeWindow.profile.type;
      const centralHeating = {
        roomTemperatureTarget: timeWindow.profile.targetTemperature,
      };

      const domesticHotWater = {
        enabled: timeWindow.profile.domesticHotWater,
      };

      const activations = profileData.get(id)?.activations ?? [];
      activations.push({
        timeOffset: timeWindow.start + offset,
      });

      const profile: ErelaxProfile = {
        id,
        type,
        centralHeating,
        domesticHotWater,
        activations,
      };

      if (type === 'CUSTOM') {
        profile.name = timeWindow.profile.title;
      }

      profileData.set(id, profile);
    }
  });

  const profiles: SystemSchedule['profiles'] = [...profileData.values()].sort((lhs, rhs) => lhs.id - rhs.id);
  const result: SchedulePayload = [
    {
      type: 'CONTROL/ERELAX',
      serialNumber,
      profiles,
    },
  ];
  return result;
};

const transformProfile = (profile: ScheduleProfile): util.ErelaxProfile => {
  const erelaxProfile: util.ErelaxProfile = {
    id: profile.id,
    index: -1,
    title: profile.name ?? '',
    type: profile.type as util.ErelaxProfile['type'],
    targetTemperature: profile.targetTemperature,
    domesticHotWater: profile.domesticHotWater,
  };
  return erelaxProfile;
};

const transformScheduleToWeekDayPeriods = (
  activeSchedule: SystemSchedule,
  weekDays: string[],
): ScheduleVgComponents[] => {
  const profiles = new Map<number, ErelaxProfile>();

  const activations = flatMap(
    activeSchedule.profiles.map((profile) => {
      profiles.set(profile.id, profile);
      return profile.activations.map((activation) => [activation.timeOffset, profile.id]);
    }),
  ).sort((a, b) => a[0] - b[0]);

  const week = new Map<number, [number, number][]>();

  for (const [offset, profileId] of activations) {
    const dayInWeek = Math.floor(offset / minutesInDay);
    const newOffset = offset - dayInWeek * minutesInDay;
    const newActivations = week.get(dayInWeek) ?? [];
    newActivations.push([newOffset, profileId]);
    week.set(dayInWeek, newActivations);
  }

  const getLastProfileOfDay = (dayInWeek: number) => {
    const _activations = week.get(dayInWeek) ?? [];
    return _activations ? _activations[_activations.length - 1][1] : -1;
  };

  for (let weekDay = 1; weekDay < 7; ++weekDay) {
    const _activations = week.get(weekDay) ?? [];
    const lastProfileId = getLastProfileOfDay(weekDay - 1);

    // If activation does not start at minute 0, add the previews profile at the beginning
    if (_activations[0]?.[0] !== 0) {
      const newActivations: [number, number][] = [[0, lastProfileId], ..._activations];
      week.set(weekDay, newActivations);
    }
  }

  const result = [];
  const it = week.values();
  let entry = it.next();
  const weekDayIter = weekDays.entries();
  while (!entry.done) {
    result.push({
      label: weekDayIter.next().value[1],
      values: entry.value.map(([startOffset, profileId], idx) => {
        const apiProfile = profiles.get(profileId) as ErelaxProfile;
        const profile = {
          id: apiProfile.id,
          type: apiProfile.type as util.ErelaxProfile['type'],
          targetTemperature: apiProfile.centralHeating.roomTemperatureTarget,
          domesticHotWater: apiProfile.domesticHotWater.enabled,
        } as util.ErelaxProfile;
        if (profile.type === 'CUSTOM') {
          profile.index = -1;
          profile.title = apiProfile.name ?? '-';
        }
        return {
          start: startOffset,
          end: entry.value[idx + 1]?.[0] ?? minutesInDay,
          profile,
        };
      }),
    });
    entry = it.next();
  }

  return result;
};

const getScheduleViewData = (activeSchedule: SystemSchedule, weekDays: string[]) => {
  const schedule = transformScheduleToWeekPeriods(activeSchedule);
  const scheduleVgComponents = transformScheduleToWeekDayPeriods(activeSchedule, weekDays);
  const currentProfile = getCurrentProfile(activeSchedule);
  const profiles = getProfiles(activeSchedule).map((profile) => transformProfile(profile));

  return { schedule, scheduleVgComponents, currentProfile, profiles };
};

export {
  getScheduleViewData,
  transformScheduleToWeekDayPeriods,
  transformScheduleToWeekPeriods,
  timeOffsetsToWeek,
  getCurrentProfile,
  getProfiles,
  getPostPeriodProfile,
  getPrePeriodProfile,
  changeScheduleByPrecedingPeriod,
  changeScheduleByPostPeriod,
  changeScheduleBySelectedPeriod,
  insertNewPeriodConnectingWithPostPeriod,
  insertNewPeriod,
  scheduleToTimeOffsets,
};
