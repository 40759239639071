/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon01500VgAway01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon01500vgaway01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <circle
        cx={464.46}
        cy={252.06}
        r={25.2}
        fill='currentColor'
        className='cls-1'
        transform='rotate(-45 464.459 252.065)'
      />
      <path
        fill='currentColor'
        d='M494.69 282.3h-60.47a10.08 10.08 0 0 0-10.08 10.08v60.47a10.08 10.08 0 0 0 10.08 10.08h5v90.71h50.39v-90.71h5a10.08 10.08 0 0 0 10.08-10.08v-60.47a10.08 10.08 0 0 0-10-10.08zM240.66 77.12a11.33 11.33 0 0 0-16 0L82.89 218.85a11.37 11.37 0 0 0-3.32 8V442.3a11.34 11.34 0 0 0 11.34 11.34h283.47a11.34 11.34 0 0 0 11.34-11.34V226.87a11.34 11.34 0 0 0-3.33-8zM363 431H102.25V231.56l130.39-130.39L363 231.56z'
        className='cls-1'
      />
    </svg>
  );
};
