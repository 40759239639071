import { ToggleButton, ToggleButtonProps } from '@mui/material';
import { styled } from '../../../util/styling';
import { Icon06501VgCheckConfirm01 } from '../../atoms/Icon/Icon06501VgCheckConfirm01';

export const Icon06501VgCheckConfirm01Styled = styled(Icon06501VgCheckConfirm01)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
`;

// TODO Fill for svgs is a temporary fix until we can set the colors of following icons correctly:
// Icon11500VgTimeProgram01, Icon16600VgHydroPumpManual01, Icon17100VgMixerManual01, Icon42200VgWarningMagneticField01
export const ToggleIconButtonStyled: React.FC<ToggleButtonProps> = styled(ToggleButton)`
  background-color: transparent !important;
  border-radius: 0;
  padding-bottom: 0 !important;
  border: none !important;
  background: none;
  .MuiBadge-root {
    > :last-child svg {
      color: ${({ theme }) => theme.palette.common.white} !important;
      background-color: ${({ theme }) => theme.palette.grey['500']} !important;
    }
    > svg {
      fill: ${({ theme }) => theme.palette.grey['500']};
      color: ${({ theme }) => theme.palette.grey['500']} !important;
    }
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
    .MuiBadge-root {
      > :last-child svg {
        color: ${({ theme }) => theme.palette.common.white};
        background-color: ${({ theme }) => theme.palette.primary.main} !important;
      }
      > svg {
        fill: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.main} !important;
      }
    }
  }
`;
