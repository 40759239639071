import React from 'react';
import { models } from '@vaillant-professional-ui/pui-frontend-common';
import { Grid } from '@vaillant-professional-ui/component-libs-web';
import { ContainerAnonymous, FooterLinkContainer } from './Footer.styles';
import { TextLinks } from './TextLinks';

interface FooterAnonymousProps {
  country: models.Country;
}

export const FooterAnonymous: React.FC<React.PropsWithChildren<FooterAnonymousProps>> = ({ country }) => {
  return (
    <ContainerAnonymous component={Grid} container={true} p={0}>
      <FooterLinkContainer px={8} py={[4, 0]} item={true} container={true} alignItems='center'>
        <TextLinks country={country} />
      </FooterLinkContainer>
    </ContainerAnonymous>
  );
};
