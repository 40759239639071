import { AxiosRequestConfig } from 'axios';
import { DataPointName } from '../../../typings/models';

export const historyApi = {
  getData: (
    gatewaySerialNumber: string,
    boilerSerialNumber: string,
    dataPointName: DataPointName,
    query = '',
  ): AxiosRequestConfig<unknown> => ({
    method: 'get',
    url: `/pui/api-gateway/v1/systems/${gatewaySerialNumber}/devices/${boilerSerialNumber}/datapoints/${dataPointName}?${query}`,
    timeout: 40000,
  }),
};
