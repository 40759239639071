import { TableVirtuoso } from 'react-virtuoso';
import { TableComponents } from './VirtualizedTable.components';
import { BaseTableRowData, BaseTableRowDataWithId, VirtualizedTableProps } from './VirtualizedTable.types';

export const VirtualizedTable = <Data extends BaseTableRowData | BaseTableRowDataWithId>({
  onClickRow,
  ...restProps
}: VirtualizedTableProps<Data>) => {
  return (
    <TableVirtuoso components={TableComponents} context={onClickRow ? { onClickRow } : undefined} {...restProps} />
  );
};
