import { DtcTypes } from '../typings/models';

const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/;

export const isEmailValid = (email: string): boolean => {
  return emailRegex.test(email.toUpperCase());
};

export const isDtcLetterValid = (letter: string = '', allowEmpty = false): boolean => {
  const pattern = new RegExp(`^[${DtcTypes.join('')}]$`, 'g');
  return (allowEmpty && letter.length === 0) || pattern.test(letter);
};

export const isDtcCodeValid = (code: string | number): boolean => {
  return /^[0-9]{1,5}$/.test(typeof code === 'string' ? code : String(code));
};

export const isDtcValid = (letter?: string, code?: string | number) => {
  if (letter === undefined || code === undefined) {
    return false;
  }

  return isDtcLetterValid(letter) && isDtcCodeValid(code);
};

export const isWaterPressureInRange = (waterPressure?: number): boolean => {
  return waterPressure !== undefined ? waterPressure >= 0.8 && waterPressure <= 3.0 : false;
};
