import * as React from 'react';
import { Translated, useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import MuiCheckbox from '@mui/material/Checkbox';
import { CheckboxProps } from './Checkbox.types';
import { Icon06501VgCheckConfirm01 } from '../Icon';
import { styled } from '@mui/material';

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({ testIdKey, i18nKey, i18nOptions, ...rest }) => {
  const testId = useInstanceId('checkbox', testIdKey);

  return (
    <Translated
      i18nKey={i18nKey}
      i18nOptions={i18nOptions}
      component={
        <StyledCheckbox
          checkedIcon={CheckedIcon}
          icon={<IconContainer />}
          indeterminateIcon={IndeterminateIcon}
          {...rest}
          {...testId().testIdAttributes}
        />
      }
    />
  );
};

const StyledCheckbox = styled(MuiCheckbox)`
  --background-color: ${({ theme }) => theme.palette.common.white};
  --color: ${({ theme }) => theme.palette.common.white};
  --border: solid 2px ${({ theme }) => theme.palette.grey['400']};

  &.Mui-checked {
    --background-color: ${({ theme }) => theme.palette.primary.main};
    --border: none;
  }

  &.Mui-disabled {
    --background-color: ${({ theme }) => theme.palette.grey['300']};
    --color: ${({ theme }) => theme.palette.grey['400']};
  }
`;

const IconContainer = styled('div')`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: var(--border);
  color: var(--color);
  background-color: var(--background-color);
`;

const CheckedIcon = (
  <IconContainer>
    <Icon06501VgCheckConfirm01 />
  </IconContainer>
);

const IndeterminateBar = styled('div')`
  background-color: var(--color);
  height: 1px;
  width: 14px;
  border-radius: 1px;
`;

const IndeterminateIcon = (
  <IconContainer>
    <IndeterminateBar />
  </IconContainer>
);
