import React from 'react';

import { ConsumptionPage } from './ConsumptionPage';
import { usePresenter } from './ConsumptionPage.hooks';

export const ConsumptionPageContainer: React.FC = () => {
  const startDate = React.useRef(new Date());
  const consumptionPageProps = usePresenter(startDate.current);
  return <ConsumptionPage {...consumptionPageProps} />;
};
