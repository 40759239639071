import { Box, TextField } from '@mui/material';
import { config, util } from '@vaillant-professional-ui/pui-frontend-common';
import { Grid, Typography } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';
import { useFirebase } from '../../providers/firebase';
import Button from '../Button';
import MessageDialogContent from '../MessageDialogContent';
import { RequestConsentContainer } from '../RequestConsent';
import Select from '../Select';
import { IAddCustomerProps } from './AddCustomer.types';
import { emailsToSelectOptions } from './AddCustomer.util';
import AgreementRequired from './AgreementRequired';

const AddCustomer: React.FC<React.PropsWithChildren<IAddCustomerProps>> = ({
  country,
  boilerSerialNumber,
  addCustomer,
  addCustomerResponse,
  loyaltyRegistrations,
  closeModal,
  agreementRequired,
  emailRequired,
  loading,
  isError,
  onResetState,
}) => {
  const [isErrorDialogVisible, setIsErrorDialogVisible] = React.useState<boolean>(false);
  React.useEffect(() => resetComponent, []);
  React.useEffect(() => {
    setIsErrorDialogVisible(isError);
  }, [isError]);

  const appConfig = config.useAppConfig();
  const firebaseService = useFirebase();
  const [serialNo, setSerialNo] = React.useState<string>(boilerSerialNumber ?? '');
  const [email, setEmail] = React.useState<string>('');

  const isEmailValid = React.useMemo(() => util.Validators.isEmailValid(email), [email]);
  const addCustomerDisabled = loading || !util.serialNumber.isValid(serialNo) || (emailRequired && !isEmailValid);
  const emailUsers = loyaltyRegistrations?.find((device) => device.serialNumber === serialNo)?.users;

  const resetComponent = () => {
    setEmail('');
    setSerialNo('');
    onResetState();
  };

  const handleSerialNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value !== '' && serialNo === '') {
      firebaseService.logUserAction('addDeviceFirstInputSerialNumber');
    }
    setSerialNo(value);
    if (value === '') {
      setEmail('');
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '' && email === '') {
      firebaseService.logUserAction('customerEmailChange');
    }
    setEmail(event.target.value);
  };

  const handleSelectEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    firebaseService.logUserAction('selectLoyalityRegisteredEmail');
    setEmail(event.target.value);
  };

  const handleFocus = () => {
    firebaseService.logUserAction('addDeviceFocusSerialNumber');
  };

  const handleOpenEmailDropdown = () => {
    firebaseService.logUserAction('addDeviceOpenEmailAddressSelection');
  };

  const gatewayName = appConfig.distribution.countries[country].gateway;

  if (agreementRequired) {
    return (
      <AgreementRequired
        serialNo={serialNo}
        email={email}
        addCustomer={addCustomer}
        closeModal={closeModal}
        loading={loading}
      />
    );
  }

  if (isErrorDialogVisible) {
    return (
      <MessageDialogContent
        messageId='SG_STATUS_ERROR_TEXT'
        buttonI18nKey='SG_OK'
        onClose={() => setIsErrorDialogVisible(false)}
      />
    );
  }

  if (addCustomerResponse?.responseId) {
    return (
      <MessageDialogContent
        messageId={addCustomerResponse.responseId}
        onClose={closeModal}
        testID='addCustomerNextBtn'
      />
    );
  }

  const hasLoyaltyRegistrations = loyaltyRegistrations.length > 0;

  return (
    <Grid container direction='column'>
      <Grid container item direction='column'>
        {!emailRequired && (
          <>
            <Grid item mb={5}>
              <Typography i18nKey='SG_MANUALSERIAL_SUBTITLE' />
            </Grid>
            <Grid container item mb={5}>
              <Grid item xs={12}>
                <TextField
                  variant='standard'
                  data-testid='customer-serial-input-container'
                  value={serialNo}
                  fullWidth={true}
                  inputProps={{
                    'data-testid': 'customer-serial-input',
                  }}
                  onFocus={handleFocus}
                  onChange={handleSerialNumberChange}
                />
              </Grid>
            </Grid>
          </>
        )}
        {emailRequired && (
          <Box mb={3}>
            <Box mb={2}>
              <Typography
                i18nKey={emailUsers?.length ? 'SG_SELECT_EMAIL_ADDRESS' : 'SG_CUSTOMER_EMAIL_VALIDINPUT'}
                i18nOptions={{
                  0: gatewayName,
                }}
                color='initial'
              />
            </Box>

            <Grid container item mb={5}>
              {emailUsers?.length ? (
                <Select
                  onOpen={handleOpenEmailDropdown}
                  data-testid='customer-email-select'
                  options={emailsToSelectOptions(emailUsers)}
                  variant='outlined'
                  fullWidth={true}
                  onChange={handleSelectEmail}
                  inputProps={{
                    'data-testid': 'customerEmailInput',
                  }}
                />
              ) : (
                <TextField
                  variant='standard'
                  data-testid='customer-email-input'
                  value={email}
                  fullWidth={true}
                  inputProps={{
                    'data-testid': 'customerEmailInput',
                  }}
                  onChange={handleEmailChange}
                />
              )}
            </Grid>
          </Box>
        )}

        <Grid
          item
          container
          justifyContent='center'
          mt={hasLoyaltyRegistrations ? 8 : 0}
          mb={hasLoyaltyRegistrations ? 8 : 3}
        >
          {emailRequired && (
            <Box mr={2}>
              <Button
                i18nKey='SG_OTHER_SERIALNUMBER'
                variant='outlined'
                color='primary'
                testId='addDeviceOtherSerialNumberBtn'
                testIdKey='other-serialnumber'
                onClick={resetComponent}
              />
            </Box>
          )}
          <Box>
            <Button
              i18nKey='SG_ADD_DEVICE'
              testIdKey='add-device'
              variant='contained'
              color='primary'
              disabled={addCustomerDisabled}
              testId='submitCustomer'
              onClick={() => addCustomer(serialNo, email)}
            />
          </Box>
        </Grid>
      </Grid>
      {hasLoyaltyRegistrations && (
        <Box>
          <Box mb={3}>
            <Typography
              i18nKey='SG_ADDCUSTOMER_LOYALTY_REGISTRATIONS_TITLE'
              i18nOptions={{ amount: loyaltyRegistrations.length }}
            />
          </Box>
          {loyaltyRegistrations.map((loyaltyRegistration, index) => {
            return (
              <Box mb={4} key={`${loyaltyRegistration.serialNumber}-${index}`}>
                <RequestConsentContainer loyaltyRegistration={loyaltyRegistration} />
              </Box>
            );
          })}
        </Box>
      )}
    </Grid>
  );
};

export default AddCustomer;
