import { InputLabel, ListItemIcon, ListItemText, Select, SelectProps, Theme } from '@mui/material';
import { styled } from '../../../util/styling';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { Icon02501VgDown01 } from '../Icon/Icon02501VgDown01';

const getLabelVariantStyles = (theme: Theme, $variant: 'h3' | 'body') => {
  const { fontSize, lineHeight }: Partial<TypographyStyleOptions> =
    // @ts-ignore string can't be used to index CSSProperties, but that is how CSSProperties is defined?
    theme.typography[$variant][theme.breakpoints.up('sm')] || {};
  return `
  font-weight:${theme.typography[$variant]?.fontWeight};
  font-size: ${theme.typography[$variant]?.fontSize}px; 
  line-height: ${theme.typography[$variant]?.lineHeight};
  
  ${theme.breakpoints.up('sm')} {
    font-size: ${fontSize}px;
    line-height: ${lineHeight};
  }`;
};

export const InputLabelStyled = styled(InputLabel)<{ $labelVariant: 'h3' | 'body' }>`
  z-index: 10;
  transform: none !important;
  margin-left: 12px !important;
  background-color: transparent !important;

  ${({ theme, $labelVariant }) => {
    if ($labelVariant === 'h3') {
      return `${getLabelVariantStyles(theme, $labelVariant)} top: 14px;`;
    }
    return 'top: 11px !important;';
  }}
`;

export const SelectStyled = styled(Select)<SelectProps & { width?: number; $labelVariant: 'h3' | 'body' }>`
  min-width: ${({ width }) => width || 200}px;
  ${({ $labelVariant, theme }) => {
    if ($labelVariant === 'h3') {
      return `
      .MuiSelect-select {
        box-sizing: border-box;
        min-height: 49px;

        ${theme.breakpoints.up('sm')} {
          min-height: 54px;
        }
      }`;
    }
    return `.MuiSelect-select {
              box-sizing: border-box;
              min-height: 44px;
            }`;
  }}
  &:before,
  &:after {
    display: none;
  }
`;

export const SelectOptionContentStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OptionIconStyled = styled(ListItemIcon)`
  min-width: 0;
  width: fit-content;
`;

export const OptionLabelStyled = styled(ListItemText)<{
  $hasIcon: boolean;
  $labelVariant: 'h3' | 'body';
}>`
  .MuiTypography-root {
    ${({ theme, $labelVariant }) => {
      if ($labelVariant === 'h3') {
        return `${getLabelVariantStyles(theme, $labelVariant)} margin-top: 3px;`;
      }
      const { fontSize } = theme.typography[$labelVariant][theme.breakpoints.up('md')] || {};
      return `
      ${theme.breakpoints.up('sm')} {
      font-size: ${fontSize}px;
      }`;
    }}
  }
  ${({ $hasIcon }) => {
    if ($hasIcon) {
      return `width: fit-content; text-align: start;`;
    }
    return '';
  }}
`;

export const Icon02501VgDown01Styled = styled(Icon02501VgDown01)<{ $size: 'big' | 'normal' }>`
  ${({ $size, theme }) => {
    if ($size === 'normal') {
      return 'width: 32px; height: 24px; top: 9px;';
    }
    return `
    width: 32px;
    height: 32px;
    top: 11px;
    
    ${theme.breakpoints.down('sm')} {
      top: 13px;
      height: 24px;
      width: 24px;
    }
  `;
  }}
`;
