import { State, store } from '@vaillant-professional-ui/pui-frontend-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageSelection } from './LanguageSelection';

export const LanguageSelectionContainer: React.FC = () => {
  const dispatch = useDispatch();
  const userLanguage = useSelector<State, string | undefined>((state) => store.getUserLanguage(state, true));
  const browserLanguage = useSelector<State, string>((state) => store.getBrowserLanguage(state, true));
  const setLanguage = (language: string) => dispatch(store.setUserLanguage(language));

  return <LanguageSelection setLanguage={setLanguage} currentLanguage={userLanguage ?? browserLanguage} />;
};
