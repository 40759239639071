import React from 'react';

import { Box, useTheme } from '@mui/material';
import { useInstanceId, useTranslation, util } from '@vaillant-professional-ui/component-libs-common';
import isEqual from 'lodash/isEqual';
import { Typography } from '../../atoms/Typography';
import { Dialog } from '../Dialog';
import { ButtonProps } from '../Dialog/Dialog.types';
import {
  ErelaxScheduleEditor,
  TliScheduleEditorCentralHeating,
  TliScheduleEditorDomesticHotWater,
} from '../ScheduleEditor';
import { TemperatureConfig } from '../ScheduleEditor/ScheduleEditor.types';
import { ScheduleTable } from '../ScheduleTable/ScheduleTable';
import { ScheduleEditDialogProps } from './ScheduleEditDialog.types';
import { getTimeWindowDisplayDataFactory } from '../ScheduleTable/ScheduleTable.util';

enum ViewType {
  EDIT_VIEW,
  CONFIRM_VIEW,
}

const valuesAreTArray = <T extends util.TimeWindow>(referenceValues: T[], valuesToCheck: any): valuesToCheck is T[] => {
  return referenceValues.every((reference) =>
    Object.keys(reference).every((key) => valuesToCheck.every((value: any) => Object.keys(value).includes(key))),
  );
};

export const ScheduleEditDialog = <T extends util.TimeWindow = util.TimeWindow>({
  testIdKey,
  isOpen,
  onSubmit,
  onClose,
  initialTimeWindows,
  profiles,
  mergeTimeWindows = util.mergeTimeWindows,
  temperatureConfig,
  timeStepWidthInMinutes,
  maxTimeSlotsPerDay = 3,
  label,
}: ScheduleEditDialogProps<T>) => {
  const testId = useInstanceId('scheduleeditdialog', testIdKey);
  const [managedTimeWindows, setManagedTimeWindows] = React.useState<T[]>(initialTimeWindows);
  const [newTimeWindows, setNewTimeWindows] = React.useState<T[]>([]);
  const [mergedTimeWindows, setMergedTimeWindows] = React.useState<T[]>([]);
  const [view, setView] = React.useState(ViewType.EDIT_VIEW);
  const t = useTranslation();
  const theme = useTheme();
  const getTimeWindowDisplayData = (timeWindow: util.TimeWindow) =>
    getTimeWindowDisplayDataFactory(t, timeWindow, theme, '°C', profiles, temperatureConfig?.stepSize);

  const onSubmitEdit = () => {
    const _mergedTimeWindows = mergeTimeWindows([], newTimeWindows);
    if (isEqual(newTimeWindows, _mergedTimeWindows)) {
      onSubmit(_mergedTimeWindows);
      onClose();
    } else {
      setMergedTimeWindows(_mergedTimeWindows);
      setView(ViewType.CONFIRM_VIEW);
    }
  };

  const onCancelEdit = () => {
    onClose();
  };

  const onSubmitMerge = () => {
    onSubmit(mergedTimeWindows);
    onClose();
  };

  const onCancelMerge = () => {
    setView(ViewType.EDIT_VIEW);
    setManagedTimeWindows(newTimeWindows);
  };

  const handleChange = <K extends util.TimeWindow>(values: K[]) => {
    if (valuesAreTArray(managedTimeWindows, values)) {
      setNewTimeWindows(values);
    } else {
      throw Error('Type of given values is invalid');
    }
  };

  const buttonsEdit: ButtonProps[] = [
    {
      label: t('SG_CANCEL'),
      onClick: onCancelEdit,
      variant: 'outlined',
      color: 'primary',
    },
    {
      label: t('SG_ADD_CHANGES'),
      onClick: onSubmitEdit,
      variant: 'contained',
      color: 'secondary',
    },
  ];

  const buttonsMerge: ButtonProps[] = [
    {
      label: t('SG_CANCEL'),
      onClick: onCancelMerge,
      variant: 'outlined',
      color: 'primary',
    },
    {
      label: t('SG_ADD_CHANGES'),
      onClick: onSubmitMerge,
      variant: 'contained',
      color: 'secondary',
    },
  ];

  const buttons = [buttonsEdit, buttonsMerge];
  const titles = [t('SG_SCHEDULE_EDIT_DIALOG_TITLE_EDIT'), t('SG_SCHEDULE_EDIT_DIALOG_TITLE_CONFIRM')];

  return (
    <Dialog
      testIdKey={testId().key}
      buttons={buttons[view]}
      title={titles[view]}
      maxWidth={'md'}
      open={isOpen}
      onClose={onClose}
    >
      {view === ViewType.EDIT_VIEW ? (
        temperatureConfig ? (
          <TliScheduleEditorCentralHeating
            values={managedTimeWindows as unknown as util.TimeWindowWithTargetTemperature[]}
            maxTimeSlotsPerDay={maxTimeSlotsPerDay}
            timeStepWidthInMinutes={timeStepWidthInMinutes}
            temperatureConfig={temperatureConfig as TemperatureConfig}
            onChange={handleChange}
          />
        ) : util.areTimeWindowsWithProfile(managedTimeWindows) && profiles ? (
          <ErelaxScheduleEditor
            values={managedTimeWindows}
            profiles={profiles}
            maxTimeSlotsPerDay={maxTimeSlotsPerDay}
            onChange={handleChange}
            timeStepWidthInMinutes={timeStepWidthInMinutes}
          />
        ) : (
          <TliScheduleEditorDomesticHotWater
            values={managedTimeWindows}
            maxTimeSlotsPerDay={maxTimeSlotsPerDay}
            timeStepWidthInMinutes={timeStepWidthInMinutes}
            onChange={handleChange}
          />
        )
      ) : null}
      {view === ViewType.CONFIRM_VIEW && (
        <div>
          <Typography>{t('VG_SCHEDULE_EDIT_DIALOG_EDIT_DESCRIPTION')}</Typography>
          <Box mt='60px'>
            <ScheduleTable.Row
              label={label}
              values={mergedTimeWindows}
              getTimeWindowDisplayData={getTimeWindowDisplayData}
            ></ScheduleTable.Row>
          </Box>
        </div>
      )}
    </Dialog>
  );
};
