import { IntlShape } from 'react-intl';

import { I18nKeys } from '../i18n';

export interface INote {
  id: string;
  text: string;
  authorId: string; // SF contact id
  authorName: string;
  createdAt: string;
  modifiedAt: string | null;
}

export const manualTypes = ['Installation', 'Operation', 'Maintenance', 'Others'] as const;
export type ManualType = (typeof manualTypes)[number];

export interface Document {
  id: string;
  type: 'MANUAL' | 'UNKNOWN';
  name: string;
  group: ManualType;
  language: string;
  size: number;
}

export interface DocumentationFile {
  version: number;
  filePath: string;
  fileId: number;
}

export interface Documents {
  documentsList: DocumentationFile[];
}

export type CustomerDashboardFilter = 'ERROR' | 'MAINTENANCE' | 'PREDICTION' | 'OFFLINE' | 'PENDING';

export type CustomerIssueFilter = 'ANY_ISSUE' | 'ALL_APPLIANCES' | CustomerDashboardFilter;

export interface IGroupedIssues {
  customers: ICustomer[];
  count: number;
  group: string;
  filter: CustomerDashboardFilter;
}

export type PaymentPlanModality = 'PER_CUSTOMER' | 'TIERED';
export type PaymentStatus = 'NOT_READY' | 'OPEN' | 'IN_PROGRESS' | 'FINISHED' | 'ERROR' | 'EXPIRED';
export type TroubleCodeType = DtcTypeName;

export type ActivationStatus =
  | 'AGREEMENT_REQUIRED'
  | 'COMPLETED'
  | 'EMAIL_REQUIRED'
  | 'INVALID_STATE'
  | 'OPT_IN_PENDING'
  | 'OTHER_CUSTOMER'
  | 'OTHER_INSTALLER'
  | 'PAYMENT_REQUIRED'
  | 'PENDING_CONSENT'
  | 'PENDING_CUSTOMER'
  | 'PENDING_SYSTEM'
  | 'SYSTEM_ACTIVATED'
  | 'SYSTEM_ALREADY_EXISTING'
  | 'SYSTEM_UNKNOWN'
  | 'USER_IS_INSTALLER'
  | 'USER_UNKNOWN';

export interface AddCustomerResponse {
  successful: boolean;
  code: ActivationStatus;
}

export type ExpiryStatus = 'never' | 'soon' | 'expired';

type Coordinate = number | string | null;
export type Point = [Coordinate, Coordinate];

export interface TroubleCodes {
  id: string;
  type: TroubleCodeType;
  code: number;
  occurredAt: string;
  rectifiedAt?: string | null;
  rectified?: boolean;
  name: string;
  title: string | null;
  description: string | null;
  hint: string | null;
  notes?: TroubleCodeNote[];
}

export interface TroubleCodeNote {
  id: string;
  authorName: string;
  authorId: string;
  createdAt: string;
  modifiedAt: string | null;
  text: string;
}

export const statusMap: TroubleCodeType[][] = [
  ['ERROR', 'MAINTENANCE', 'STATUS'],
  ['ERROR'],
  ['MAINTENANCE'],
  ['STATUS'],
];

export type DtcTypeName =
  | 'ERROR'
  | 'STATUS'
  | 'MAINTENANCE'
  | 'DIAGNOSIS'
  | 'LIMP_HOME_MODE'
  // | 'WARNING'
  | 'LIMP_HOME_MODE_REVERSIBLE';

export const DtcTypes = ['F', 'S', 'M', 'D', 'L', 'N', 'I'] as const;
export type DtcType = (typeof DtcTypes)[number];

export interface DtcDetails {
  code: number;
  type: DtcType;
  name: string;
  description?: string;
  title?: string;
  hint?: string;
}

export const TroubleTypeMappingEnum: Record<DtcTypeName, DtcType> = {
  ERROR: 'F',
  STATUS: 'S',
  MAINTENANCE: 'M',
  DIAGNOSIS: 'D',
  LIMP_HOME_MODE_REVERSIBLE: 'L',
  LIMP_HOME_MODE: 'N',
};

export enum TroubleCodeTypeEnum {
  ERROR = 'ERROR',
  STATUS = 'STATUS',
  MAINTENANCE = 'MAINTENANCE',
  WARNING = 'WARNING',
}

// @TODO use DtcType
export type TroubleCodeInsightType =
  | 'ERROR'
  | 'MAINTENANCE'
  | 'LIMP_HOME_MODE'
  | 'STATUS'
  | 'DIAGNOSIS'
  | 'WARNING'
  | 'LIMP_HOME_MODE_REVERSIBLE';

export type ErrorPredictionType = 'F.22';

export interface ErrorPredictions {
  type: ErrorPredictionType;
  predictedFrom: string;
  predictedTo: string;
  predictionComputedAt?: string;
}

export type Country =
  | 'AT'
  | 'BE'
  | 'CH'
  | 'CZ'
  | 'DE'
  | 'DK'
  | 'EE'
  | 'ES'
  | 'FI'
  | 'FR'
  | 'GB'
  | 'HR'
  | 'HU'
  | 'IT'
  | 'NL'
  | 'NO'
  | 'PL'
  | 'RO'
  | 'RO'
  | 'SE'
  | 'SK'
  | 'TR'
  | 'UA';

export const supportedLanguages = [
  'cs',
  'da',
  'de',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'hr',
  'hu',
  'it',
  'no',
  'nl',
  'pl',
  'ro',
  'sk',
  'sv',
  'tr',
  'uk',
] as const;

export type Language = (typeof supportedLanguages)[number];

export type Locale =
  | 'cs-CZ'
  | 'da-DK'
  | 'de-AT'
  | 'de-CH'
  | 'de-DE'
  | 'en-GB'
  | 'es-ES'
  | 'et-EE'
  | 'fi-FI'
  | 'fr-FR'
  | 'hr-HR'
  | 'hu-HU'
  | 'it-IT'
  | 'no-NO'
  | 'nl-BE'
  | 'nl-NL'
  | 'pl-PL'
  | 'ro-RO'
  | 'sk-SK'
  | 'sv-SE'
  | 'tr-TR'
  | 'uk-UA';

export type BrandKey = 'awb' | 'bulex' | 'glow-worm' | 'hermann' | 'saunierduval' | 'vaillant';

export type Brand =
  | 'VAILLANT'
  | 'SAUNIER_DUVAL'
  | 'AWB'
  | 'BULEX'
  | 'GLOW_WORM'
  | 'HERMANN_SAUNIER_DUVAL'
  | 'DEMIR_DOEKUEM'
  | 'PROTHERM';

export type BrandGroup = 'VAILLANT' | 'SDBG';

const mapCountryToRedBrand: Partial<Record<Country, BrandKey>> = {
  GB: 'glow-worm',
  IT: 'hermann',
  NL: 'awb',
  RO: 'saunierduval',
  FR: 'saunierduval',
  ES: 'saunierduval',
  BE: 'bulex',
};

export const getBrandKey = (country: Country, brandGroup: BrandGroup): BrandKey => {
  if (brandGroup === 'VAILLANT') {
    return 'vaillant';
  }
  return mapCountryToRedBrand[country] ?? 'saunierduval';
};

export type Salutation = 'Mr.' | 'Mrs.' | 'Ms.' | 'Miss' | 'Sir' | 'Lord' | 'Lady' | 'Dame' | 'Rev' | 'Dr.';

export interface IUserInfoContact {
  id: string;
  firstName: string;
  lastName: string;
  hash?: string;
}

export interface IProduct extends DeviceWithArticleNumber {
  _id?: string;
  country?: Country;
  language?: Language;
}

export interface Product {
  articleNumber: string;
  nomenclatures?: string[];
  marketingName?: string;
  brand?: Brand | BrandGroup;
}

export interface ProductLine {
  marketingName: string;
  articleNumber: string;
  nomenclatures: string[];
  serialNumber?: string;
}

export interface IUser {
  contact: IUserInfoContact;
  account: IAccount;
}

export interface INotification {
  id: string;
  type: TroubleCodeType;
  code: number;
  name: string;
  occurrenceTimestamp: string;
  title: string;
  description: string;
  hint: string;
  rectificationTimeStamp?: string;
  notes: Array<string>;
}

export interface Device {
  marketingName: string;
  nomenclature: string;
}

export interface DeviceWithArticleNumber extends Device {
  articleNumber: string;
}

export interface DeviceWithSerialNumber extends Device {
  serialNumber: string;
}

export type HeatingDeviceType = 'BOILER' | 'HEAT_PUMP' | 'VENTILATION';

export interface IBoiler extends DeviceWithSerialNumber {
  deviceType?: HeatingDeviceType;
  activeNotification: INotification | null;
  activeTroubleCodes: TroubleCodes[];
}

export type ConnectivitySolution = 'ERELAX' | 'ERELAX_NETCOMV2' | 'MFH' | 'CAG' | 'SPEEDBOAT' | 'UNKNOWN';

export type ControlType = 'TLI';

export interface IGateway {
  serialNumber: string;
  marketingName?: string;
  firmware?: string;
}

export interface IAddressLocation {
  street: string | null;
  houseNumber: string | null;
  extension?: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  geoLocation: Geolocation;
}

export interface Geolocation<T = number | undefined | null> {
  latitude: T; // Coordinate;
  longitude: T; // Coordinate;
}

export interface IAddress {
  salutation?: string | null;
  firstName: string;
  lastName: string;
  phone: string | null;
  address: IAddressLocation;
}

export type ConsentNotificationType = 'EMAIL' | 'PUSH_NOTIFICATION';

export interface ISystemStatus {
  consentGiven: boolean;
  accessExpiresAt?: string | null;
  activation: ActivationStatus;
  errorPredictions?: ErrorPredictions[];
  connected?: boolean;
  online?: boolean;
  lastActivation?: string;
  lastHeartbeatAt?: string;
  consentNotificationType?: ConsentNotificationType;
}

export interface ICustomer {
  customer: IAddress | null;
  /* @deprecated */
  endUser: IAddress | null;
  system: ISystem;
  status: ISystemStatus;
}

export interface ISystem {
  systemId: string;
  boiler: IBoiler;
  connectivitySolution: ConnectivitySolution;
  gateway: IGateway | null;
  control?: { serialNumber: string | null };
}

export interface ICustomerDetail {
  troubleCodes: TroubleCodes[];
  connectivitySolution: ConnectivitySolution;
  control?: { type: ControlType };
  customer: IAddress | null;
  system: Omit<ISystem, 'connectivitySolution'>;
  status: ISystemStatus;
}

export type TokenType = 'Bearer';

export interface IAccount {
  id: string;
  email: string;
  loyalty: Loyalty | null;
  hash?: string;
}

export interface Loyalty {
  id: string;
}

export interface IUserInfo {
  user: {
    contact: IUserInfoContact;
    account: IAccount;
  };
}

export interface IPaymentPlan {
  currentPlan: boolean;
  customerThreshold: number;
  highestAcceptedPlan: boolean;
  modality: string;
  name: string;
  paymentAccepted: boolean;
  price: string;
  tier: string;
  description?: string;
  maxCustomers?: number;
  maxNumberOfCustomers?: number;
  title?: string;
}

export type DataPointName =
  | 'boiler:waterPressure'
  | 'boiler:heatingCircuit:flowTemperature'
  | 'boiler:heatingCircuit:returnTemperature'
  | 'boiler:domesticHotWater:temperature'
  | 'BuildPumpPWM'
  | 'RefrigerantCircuitEvaporationTemperature'
  | 'FlowTemperatureSensor'
  | 'threeWayValve'
  | 'BuildingCircuitHeatExchangerOutletTemperature'
  | 'RefrigerantCircuitHighPressureSensor'
  | 'RefrigerantCircuitCurrentSuperheating'
  | 'RefrigerantCircuitCurrentSubcooling'
  | 'EnvironmentCircuitHeatExchangerInletTemperature'
  | 'HP_BuildFlow_AI';

export interface TimestampRecord<TValue, TDate = string | number | Date> {
  timestamp: TDate;
  value: TValue;
}

export type IsoTimestampRecord<TValue> = TimestampRecord<TValue, string>;

export interface IPagination {
  offset: number;
  limit: number;
  total: number;
}

export interface IHistory {
  gatewaySerial: string;
  deviceSerial: string;
  datapointName: DataPointName;
  unit: string;
  values: IsoTimestampRecord<number>[];
  pagination: IPagination;
}

export interface IDiagnostics {
  environment: Environment;
  boiler: Boiler;
  thermostat: Thermostat;
  gateway: Gateway;
  control: Control;
}

interface Boiler {
  flowTemperature: number;
  returnTemperature: number;
  waterPressure: number;
  modulationLevelPercent: number;
  threeWayValve: string;
  heatingDemand: boolean;
  centralHeatingBlocked: boolean;
  hoursTillService: number;
  domesticHotWaterOperatingHours: number;
  centralHeatingOperatingHours: number;
  averageIgnitionTime: number;
  generatorStatus: string;
  domesticHotWaterBurnerStarts: number;
  centralHeatingBurnerStarts: number;
  powerOutput: PowerOutput;
  hoursTillServiceRange: HoursTillServiceRange;
}

interface HoursTillServiceRange {
  minimum: number;
  maximum: number;
  stepSize: number;
  value?: number;
}

interface PowerOutput {
  centralHeating: HoursTillServiceRange;
  domesticHotWater: HoursTillServiceRange;
}

interface Control {
  maintenanceDate: string;
  temperatures: number;
  heatingCurve: number;
}

interface Environment {
  temperatures: Temperatures;
}

interface Temperatures {
  room: TimestampRecord<number>;
  outdoor: TimestampRecord<number>;
}

interface Gateway {
  connectivity: Connectivity;
  signalStrengths: SignalStrengths;
}

interface Connectivity {
  thermostat: number;
  cloud: number;
}

interface SignalStrengths {
  wifi: Radio;
  radio: Radio;
}

interface Radio {
  powerLevel: number;
  percentage: number;
}

interface Thermostat {
  batteryCharge: BatteryCharge;
}

interface BatteryCharge {
  voltage: number;
  percentage: number;
}

export type LiveMonitorMode = 'dhw' | 'heating' | 'storageTank';
export type LiveMonitorState = 'active' | 'other' | 'inactive';

export interface ILiveMonitorAttribute {
  icon: string;
  label: string;
  color: 'text.primary' | 'inherit' | 'initial' | 'primary' | 'secondary' | 'text.secondary' | 'error';
  description?: string;
  status?: LiveMonitorState;
}

export interface TroubleCodeInsight {
  code: number;
  type: TroubleCodeInsightType;
  language: string;
  title: string;
  description?: string;
  hint?: string;
}

export type ConsentState = 'NONE' | 'GIVEN' | 'PENDING';

export interface LoyaltyRegistration {
  product: IProduct;
  users: { email: string }[];
  state: {
    connected: boolean;
    consentState: ConsentState;
  };
  serialNumber: string;
  registeredAt?: string;
  address?: IAddressLocation;
}

export type TranslationFnc = (i18nKey: I18nKeys | string, values?: Parameters<IntlShape['formatMessage']>[1]) => string;

export type FeedbackType = 'COMPLAINT' | 'TECHNICAL' | 'FEEDBACK' | 'OTHER';

export const FeedbackTranslationMapping: Record<FeedbackType, string> = {
  COMPLAINT: 'SG_FEEDBACK_TOPIC_APP',
  TECHNICAL: 'SG_FEEDBACK_TOPIC_TECH',
  FEEDBACK: 'SG_FEEDBACK_TOPIC_FEEDBACK',
  OTHER: 'SG_FEEDBACK_TOPIC_OTHER',
};

export type FeedbackSubmitFnc = (feedbackId: FeedbackType, message: string) => void;

export interface IFeedbackFormProps {
  deviceData: object | FeedbackDeviceObject;
  handleSubmit: FeedbackSubmitFnc;
}

export interface FeedbackDeviceObject {
  appVersion: string;
  apiLevel: number | null;
  appName: string;
  buildVersion: string;
  bundleId: string;
  deviceBrand: string;
  deviceCountry: string;
  deviceModel: string;
  modelId: string;
  operatingSystem: string;
  timeOpened: string;
}

export interface Temperature {
  timestamp: number;
  unit: string;
}

export interface SystemState {
  status?: 'OK' | 'ERROR';
  centralHeating?: {
    roomTemperature?: number;
    roomTemperatureTarget?: number;
    roomTemperatureTargetSource?:
      | 'SCHEDULE'
      | 'MODE'
      | 'AWAY_OVERRIDE'
      | 'MANUAL_OVERRIDE'
      | 'HOLIDAY_PERIOD'
      | 'SYSTEM';
    outdoorTemperature?: number;
    zones?: boolean;
  };
  domesticHotWater?: {
    enabled?: boolean;
    trigger?: 'NONE' | 'MODE' | 'SCHEDULE' | 'AWAY_OVERRIDE' | 'MANUAL_OVERRIDE' | 'HOLIDAY_PERIOD' | 'HOT_WATER_BOOST';
    temperature?: number;
    temperatureTarget?: number;
  };
  _metadata?: {
    status?: {
      timestamp?: number;
      enum?: ['OK', 'ERROR'];
    };
    centralHeating?: {
      roomTemperature?: Temperature;
      roomTemperatureTarget?: Temperature;
      roomTemperatureTargetSource?: {
        timestamp?: number;
        enum?: ['SCHEDULE', 'MODE', 'AWAY_OVERRIDE', 'MANUAL_OVERRIDE', 'HOLIDAY_PERIOD', 'SYSTEM'];
      };
      outdoorTemperature?: Temperature;
    };
    domesticHotWater?: {
      enabled?: {
        timestamp?: number;
      };
      trigger?: {
        timestamp?: number;
        enum?: ['NONE', 'MODE', 'SCHEDULE', 'AWAY_OVERRIDE', 'MANUAL_OVERRIDE', 'HOLIDAY_PERIOD', 'HOT_WATER_BOOST'];
      };
      temperature?: Temperature;
      temperatureTarget?: {
        timestamp?: number;
        minimum?: number;
        maximum?: number;
        stepSize?: number;
        unit?: string;
      };
    };
  };
}

export interface NumberDataPointMeta {
  timestamp?: number;
  unit?: string;
  minimum?: number | null;
  maximum?: number | null;
  stepSize?: number | null;
}

export interface MapBounds {
  northEast: {
    lat: number;
    lng: number;
  };
  southWest: {
    lat: number;
    lng: number;
  };
}
