import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFirebase } from '../../providers/firebase';
import { IInternalLinkProps } from './Link.types';

const Link: React.FC<React.PropsWithChildren<IInternalLinkProps>> = ({ as, onClick, firebaseProps, ...rest }) => {
  const firebaseService = useFirebase();

  // @ts-ignore
  const trackFirebase = (e) => {
    const { identifier, ...firebaseRest } = firebaseProps || {};
    if (identifier) {
      firebaseService.logUserAction(identifier, { ...firebaseRest });
    }
    !!onClick && onClick(e);
  };

  const testId = firebaseProps?.identifier ? { 'data-testid': firebaseProps.identifier } : {};
  const Component = as ? as : RouterLink;
  // @ts-ignore
  if (as === 'a') {
    // @ts-ignore
    rest.href = rest.to.toString();
  }
  // @ts-ignore
  return <Component {...rest} {...testId} onClick={trackFirebase} />;
};

export default Link;
