export const getBrowserData = (): Record<string, string> => {
  const values = {
    timeOpened: new Date(),
    timezone: new Date().getTimezoneOffset() / 60,
    pageon: window.location.pathname,
    referrer: document.referrer,
    previousSites: window.history.length,
    browserName: navigator.appName,
    browserEngine: navigator.product,
    browserVersion1a: navigator.appVersion,
    browserVersion1b: navigator.userAgent,
    browserLanguage: navigator.language,
    browserOnline: navigator.onLine,
    browserPlatform: navigator.platform,
    javaEnabled: navigator.javaEnabled(),
    sizeScreenW: window.screen.width,
    sizeScreenH: window.screen.height,
    sizeInW: window.innerWidth,
    sizeInH: window.innerHeight,
    sizeAvailW: window.screen.availWidth,
    sizeAvailH: window.screen.availHeight,
    scrColorDepth: window.screen.colorDepth,
    scrPixelDepth: window.screen.pixelDepth,
    sessionId: window.ineum?.('getPageLoadId'),
  };

  return Object.fromEntries(Object.entries(values).map(([k, v]) => [k, JSON.stringify(v)]));
};
