import { AuthService } from './auth.types';

export const authServiceMock: AuthService = {
  getStatus: () => {
    process.env.NODE_ENV !== 'test' && console.log('!! Using status from auth mocks');
    return { isAuthenticated: false, authenticationSource: null, roles: [] };
  },
  loginIdm: () => Promise.resolve(),
  loginDemo: () => Promise.resolve(),
  loginDelegated: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  subscribe: () => () => Promise.resolve(),
};
