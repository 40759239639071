import { useVrc700 } from '../../../api/apis/maintenance/v2/maintenance.hooks';

export const useVrc700Case = ({ systemId, deviceId }: { systemId: string; deviceId: string }) => {
  const vrc700Query = useVrc700(
    { systemId, deviceId },
    {
      enabled: !!systemId && !!deviceId,
    },
  );

  return { vrc700Query };
};
