import { orderBy } from 'lodash';

import { DeviceDetailsResponseTli, TliZone } from '../../../../../api/apis/maintenance/v2';
import { TranslationFnc } from '../../../../../typings/models';
import { nonNullable } from '../../../../../util';
import { Category, createCategory } from '../../../deviceSettings/useCase/model/category';
import { mapBooleanMeasuredValue, mapEnumMeasuredValue, mapNumericMeasuredValue } from './mapMeasuredValue';
import { dataPointKeyToTranslationMap } from './translationMap';

const mapHeating = (zone: TliZone, t: TranslationFnc): Category['values'] => {
  return [
    mapNumericMeasuredValue({
      id: 'setbackTemperature',
      measuredValue: zone.heating?.setback_temperature,
      mutationId: `setbackTemperature.${zone.index}`,
      action: zone._actions?.update_setback_temperature,
      actionPropertyKey: 'setbackTemperature',
      t,
      payloadMetadata: { zoneIndex: zone.index },
    }),
    zone.heating?.schedule
      ? {
          title: t(dataPointKeyToTranslationMap['weeklyPlanner']),
          type: 'link' as const,
          id: 'weeklyPlanner' as const,
          metadata: { index: zone.index },
        }
      : null,
    mapEnumMeasuredValue({
      id: 'operationMode',
      measuredValue: zone.operation_mode,
      mutationId: `operationMode.${zone.index}`,
      action: zone._actions?.update_central_heating_operation_mode,
      actionPropertyKey: 'operationMode',
      payloadMetadata: {
        zoneIndex: zone.index,
      },
      t,
    }),
    mapNumericMeasuredValue({
      id: 'heatingManualModeSetpoint',
      measuredValue: zone.manual_mode_setpoint,
      mutationId: `heatingManualModeSetpoint.${zone.index}`,
      action: zone._actions?.update_central_heating_manual_mode_setpoint,
      actionPropertyKey: 'setpoint',
      payloadMetadata: {
        zoneIndex: zone.index,
        type: 'HEATING',
      },
      t,
    }),
  ].filter(nonNullable);
};

const mapCooling = (zone: TliZone, t: TranslationFnc): Category['values'] => {
  return [
    mapEnumMeasuredValue({
      id: 'coolingOperationMode',
      measuredValue: zone.cooling_operation_mode,
      mutationId: `coolingOperationMode.${zone.index}`,
      action: zone._actions?.update_cooling_operation_mode,
      actionPropertyKey: 'operationMode',
      payloadMetadata: { type: 'COOLING', zoneIndex: zone.index },
      t,
    }),
    mapNumericMeasuredValue({
      id: 'roomTemperatureSetpointCoolingManual',
      measuredValue: zone.room_temperature_setpoint_cooling_manual,
      mutationId: `roomTemperatureSetpointCoolingManual.${zone.index}`,
      action: zone._actions?.update_cooling_manual_mode_setpoint,
      actionPropertyKey: 'setpoint',
      payloadMetadata: { zoneIndex: zone.index, type: 'COOLING' },
      t,
    }),
  ].filter(nonNullable);
};

export const createZones = (response: DeviceDetailsResponseTli, t: TranslationFnc) => {
  return (
    orderBy(response.zones, (zone) => zone.index, 'asc')?.map((zone) => {
      return createCategory(
        zone.zone_name?.value || t('SG_ZONE_NO', { 0: zone.index + 1 }),
        [
          mapNumericMeasuredValue({ id: 'roomTemperature', measuredValue: zone.room_temperature, t }),
          mapNumericMeasuredValue({ id: 'humidity', measuredValue: zone.humidity, t }),
          mapBooleanMeasuredValue({ id: 'zoneActive', measuredValue: zone.zone_active, t }),
          createCategory(t('SG_HEATING'), mapHeating(zone, t)),
          createCategory(t('SG_COOLING'), mapCooling(zone, t)),
        ].filter(nonNullable),
      );
    }) ?? []
  );
};
