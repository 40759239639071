import { AxiosRequestConfig } from 'axios';
import { EmfBucketsRequestParams } from './emf.types';
import { sanitizeParams } from './emf.util';

export const emfApi = {
  getEmfDevices: (systemId: string): AxiosRequestConfig<never> => ({
    url: `/pui/api-gateway/v1/systems/${systemId}/emf/devices`,
    timeout: 40000,
  }),

  getEmfBuckets: ({ systemId, deviceId, ...queryParams }: EmfBucketsRequestParams): AxiosRequestConfig<never> => ({
    method: 'get',
    url: `/pui/api-gateway/v1/systems/${systemId}/emf/devices/${deviceId}/buckets`,
    params: sanitizeParams(queryParams),
    timeout: 40000,
  }),
};
