import { useNavigate, useParams } from 'react-router-dom';
import { features as commonFeatures } from '@vaillant-professional-ui/pui-frontend-common';

export const useWebPresenter = () => {
  const { boilerSerialNumber: boilerSerialNumberFromParams } = useParams<{
    boilerSerialNumber: string;
  }>();

  const boilerSerialNumber = boilerSerialNumberFromParams as string;

  const navigate = useNavigate();

  const {
    customer,
    isLoading: isLoadingCustomer,
    error,
    errorDescription,
    customerFullName,
    dtcDeviceId,
    language,
    country,
    isAddTroublePending,
    isUpdateTroublePending,
    isDeleteTroublePending,
    addNote,
    updateNote,
    deleteNote,
    userContactId,
  } = commonFeatures.customerDetails.usePresenter({ boilerSerialNumber });

  return {
    boilerSerialNumber,
    navigate,
    customer,
    isLoading: isLoadingCustomer,
    error,
    errorDescription,
    customerFullName,
    dtcDeviceId,
    language,
    country,
    isAddTroublePending,
    isUpdateTroublePending,
    isDeleteTroublePending,
    addNote,
    updateNote,
    deleteNote,
    userContactId,
  };
};
