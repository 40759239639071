import React from 'react';
import { useConsumptionDetailsContext } from '../../pages/ConsumptionPage';
import { EmfChart } from '../EmfChart';

export const EnvironmentalYieldDetails: React.FC = () => {
  const { filters, data, isLoading } = useConsumptionDetailsContext();
  return (
    <EmfChart
      buckets={data.categories.environmentalYield.buckets}
      dateRangePeriod={filters.dateRangePeriod}
      emfCategory='environmentalYield'
      maxDomainY={data.categories.environmentalYield.highestValueByResolution}
      totalEnergyValue={data.categories.environmentalYield.total}
      isLoading={isLoading}
    />
  );
};
