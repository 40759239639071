import React from 'react';
import { TableRow } from './Dashboard.types';
import { Menu, MenuItem } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { enableResendConsentMail, enableRetryAddCustomer } from './Dashboard.util';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';

export const useRowActionsMenuState = () => {
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const [selectedRowData, setSelectedRowData] = React.useState<TableRow | null>(null);

  const onClickRowActions = (event: React.MouseEvent<HTMLElement>, data: TableRow) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchor(event.currentTarget);
    setSelectedRowData(data);
  };

  const onCloseMenu = () => {
    setAnchor(null);
    setSelectedRowData(null);
  };

  return { anchor, selectedRowData, onClickRowActions, onCloseMenu };
};

export const RowActionsMenu = ({
  anchor,
  onClose,
  selectedRowData,
  openRemoveCustomer,
  openResendConsent,
  retryValidation,
}: {
  anchor: HTMLElement | null;
  onClose: () => void;
  selectedRowData: TableRow | null;
  openRemoveCustomer: (row: TableRow) => void;
  openResendConsent: (row: TableRow) => void;
  retryValidation: (row: TableRow) => void;
}) => {
  const t = hooks.useTranslation();
  const testId = useInstanceId('enhancedtable', 'menu');

  const rowActions = [
    { label: t('SG_REMOVE_CUSTOMER_CONFIRMATION'), action: openRemoveCustomer },
    {
      label: t('SG_RESEND_CONSENT_TITLE'),
      action: openResendConsent,
      disabled: (record: TableRow) => !enableResendConsentMail(record),
    },
    {
      label: t('SG_RETRY_VALIDATION'),
      action: retryValidation,
      disabled: (record: TableRow) => !enableRetryAddCustomer(record),
    },
  ];

  return (
    <Menu anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={onClose}>
      {rowActions.map((rowAction, index) => {
        return (
          <MenuItem
            {...testId(`menuitem-${index}`).testIdAttributes}
            key={index}
            onClick={() => {
              selectedRowData && rowAction.action(selectedRowData);
              onClose();
            }}
            disabled={(selectedRowData && rowAction.disabled?.(selectedRowData)) ?? false}
          >
            {rowAction.label}
          </MenuItem>
        );
      })}
    </Menu>
  );
};
