import React from 'react';
import deburr from 'lodash/deburr';

export const getMatches = (value: string, query: string): [number, number][] => {
  const indexes: number[] = [];
  let i: number = -1;
  const normalizedValue = deburr(value).toLowerCase();
  const normalizedQuery = deburr(query).toLowerCase();
  while ((i = normalizedValue.indexOf(normalizedQuery, i + 1)) !== -1) {
    indexes.push(i);
  }
  return indexes.map((index) => [index, index + query.length]);
};

const getHighlighted = (value: string, matches: [number, number][]): [string, boolean][] => {
  let currentPosition = 0;
  const bits: [string, boolean][] = [];
  matches.forEach((match, index) => {
    bits.push([value.slice(currentPosition, match[0]), false]);
    bits.push([value.slice(match[0], match[1]), true]);
    const newPosition = index < matches.length - 1 ? matches[index + 1][0] : value.length;
    bits.push([value.slice(match[1], newPosition), false]);
    currentPosition = newPosition;
  });
  if (currentPosition < value.length - 1) {
    bits.push([value.slice(currentPosition), false]);
  }
  return bits.filter((bit) => !!bit[0]);
};

export const highlightStringBeginsWith = (
  // eslint-disable-next-line default-param-last
  value: string = '',
  // eslint-disable-next-line default-param-last
  query: string = '',
  highlightColor: string
): React.ReactNode[] => {
  if (query !== '' && value.toLowerCase().startsWith(query.toLowerCase())) {
    return [<span style={{ color: highlightColor }}>{value.slice(0, query.length)}</span>, value.slice(query.length)];
  }
  return [value];
};

export const highlightStringEndsWith = (
  // eslint-disable-next-line default-param-last
  value: string = '',
  // eslint-disable-next-line default-param-last
  query: string = '',
  highlightColor: string
): React.ReactNode[] => {
  if (query !== '' && value.toLowerCase().endsWith(query.toLowerCase())) {
    return [
      value.slice(0, value.length - query.length),
      <span style={{ color: highlightColor }}>{value.slice(value.length - query.length)}</span>
    ];
  }
  return [value];
};

export const highlightStringContains = (value: string, query: string, highlightColor: string): React.ReactNode[] => {
  if (!query) {
    return [value];
  }

  const positions = getMatches(value, query);
  const parsed = getHighlighted(value, positions);
  return parsed.map((bit) => (bit[1] ? <span style={{ color: highlightColor }}>{bit[0]}</span> : bit[0]));
};
