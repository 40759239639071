import { useGetLoyaltyRegistrations } from '../../apis/management';
import { usePermissions } from '../../../components/meta/PermissionGate';
import { getBoilerSerialNumber } from '../../../selectors/customer';
import { availableDevice } from '../../../util/devices';
import { useCustomerList } from '../../apis/maintenance/v1';

/**
 * Returns all loyalty registrations that are actually available for the user:
 *
 * - User has permission to retrieve loyalty registrations
 * - serial number not empty
 * - connected devices only
 * - consent given only
 * - Not already added
 *
 */
export const useLoyaltyRegistrationsWithPermissions = () => {
  // TODO: permission checks like that belong to the backend
  const { hasPermissions: mayGetLoyaltyRegistrations } = usePermissions(['LOAD_LOYALTY_REGISTRATIONS']);
  const { data: customerList } = useCustomerList();
  const { data: loyaltyRegistrationResponse } = useGetLoyaltyRegistrations({
    enabled: mayGetLoyaltyRegistrations && !!customerList,
  });

  const ignoreBoilerSerialNumbers = (customerList || []).map(getBoilerSerialNumber).filter(Boolean);

  const loyaltyRegistrations = (loyaltyRegistrationResponse || []).filter((device) =>
    availableDevice(device, undefined, ignoreBoilerSerialNumbers),
  );
  return { loyaltyRegistrations };
};
