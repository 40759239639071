/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon02900VgHeatPump01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon02900vgheatpump01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M357.37 351.59H221.31v-28.34h62.36A11.35 11.35 0 0 0 295 311.91v-63.34l26 26a11.33 11.33 0 0 0 16-16l-45.35-45.36a11.35 11.35 0 0 0-16 0l-45.35 45.33a11.33 11.33 0 1 0 16 16l26-26v52H210a11.34 11.34 0 0 0-11.34 11.34v51A11.34 11.34 0 0 0 210 374.27h136v28.35H210A11.34 11.34 0 0 0 198.63 414v51A11.34 11.34 0 0 0 210 476.32h62.36v51H295V465a11.34 11.34 0 0 0-11.34-11.34h-62.35v-28.37h136.06A11.34 11.34 0 0 0 368.71 414v-51a11.34 11.34 0 0 0-11.34-11.41z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M433.42 190.5 291.68 48.77a11.33 11.33 0 0 0-16 0L133.92 190.5a11.33 11.33 0 0 0-3.32 8V414a11.33 11.33 0 0 0 11.34 11.33h34v-22.71h-22.67v-199.4l130.4-130.4 130.39 130.4v199.4h-22.67v22.67h34A11.34 11.34 0 0 0 436.74 414V198.52a11.37 11.37 0 0 0-3.32-8.02z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
