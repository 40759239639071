/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon46901VgQuestion201: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon46901vgquestion201', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M283.65 170.08H283c-31.49.31-44.63 9.61-65.15 26l-1 .82L229 211.76c2.52 2.89 5.24 4.36 8.08 4.36a11.53 11.53 0 0 0 6.83-2.62c12.93-9.2 22.38-13.86 38.17-13.86 24.41 0 37.31 9.83 37.31 28.43 0 16-4.39 23.21-19.58 32-32.76 19.11-38.95 30.15-38.95 69.46v4.36h31.09v-1.3c0-4.62 0-11.59.31-15.23 1.19-17.25 5.57-23.84 22.34-33.59 25.93-15.06 35.58-30.64 35.58-57.51-.03-27.63-24.91-56.18-66.53-56.18zM276.53 355c-11.31 0-20.74 9.8-20.74 21.12a20.75 20.75 0 0 0 41.49 0c0-11.33-9.44-21.12-20.75-21.12z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M283.46 102.05c-100 0-181.41 81.38-181.41 181.41s81.38 181.42 181.41 181.42 181.42-81.38 181.42-181.42-81.38-181.41-181.42-181.41zm0 340.16c-87.53 0-158.74-71.22-158.74-158.75s71.21-158.74 158.74-158.74S442.2 195.94 442.2 283.46 371 442.21 283.46 442.21z'
        className='cls-1'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
