/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon45101VgClose201: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon45101vgclose201', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M299.5 283.7 419 164.16a11.34 11.34 0 0 0-16-16L283.47 267.67 163.92 148.12a11.34 11.34 0 1 0-16 16L267.43 283.7 147.89 403.24a11.34 11.34 0 1 0 16 16l119.57-119.5L403 419.28a11.34 11.34 0 0 0 16-16z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
