/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon01000VgTimer01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon01000vgtimer01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M283.47 102.13c-100 0-181.34 81.35-181.34 181.34S183.48 464.8 283.47 464.8 464.8 383.46 464.8 283.47s-81.34-181.34-181.33-181.34zm0 340c-87.47 0-158.66-71.13-158.66-158.66S196 124.81 283.47 124.81 442.13 196 442.13 283.47 371 442.13 283.47 442.13z'
        className='cls-1'
      />
      <path
        fill='currentColor'
        d='M349.88 323.32a11.34 11.34 0 0 0-3.88-15.56l-51.2-30.71v-124a11.34 11.34 0 1 0-22.68 0v136.84l62.2 37.31a11.33 11.33 0 0 0 15.55-3.88zm-66.42 79.21a14.17 14.17 0 1 0 14.18 14.16 14.16 14.16 0 0 0-14.18-14.16zM164.41 283.46a14.17 14.17 0 1 0-14.17 14.18 14.17 14.17 0 0 0 14.17-14.18zm59.53-103.11a14.17 14.17 0 1 0-19.36-5.17 14.16 14.16 0 0 0 19.36 5.17zM343 386.58a14.17 14.17 0 1 0 19.36 5.18 14.17 14.17 0 0 0-19.36-5.18zm-182-48.76a14.17 14.17 0 1 0 19.35 5.18 14.17 14.17 0 0 0-19.35-5.18zm0-108.69a14.17 14.17 0 1 0-5.19-19.35 14.17 14.17 0 0 0 5.19 19.35zm244.93 108.68a14.17 14.17 0 1 0 5.2 19.35 14.18 14.18 0 0 0-5.2-19.35zm-182 48.76a14.17 14.17 0 1 0 5.2 19.36 14.18 14.18 0 0 0-5.2-19.36z'
        className='cls-1'
      />
    </svg>
  );
};
