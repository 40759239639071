import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { createRoot } from 'react-dom/client';
import 'react-leaflet-markercluster/dist/styles.min.css';

import * as features from './features';

import { config } from '@vaillant-professional-ui/pui-frontend-common';

import App from './components/App';
import './index.css';
import { reportEvent } from './services/Utility';

declare global {
  interface Window {
    REACT_APP_BRAND_KEY?: string;
  }
}

window.addEventListener('unhandledrejection', (event) => {
  const { reason } = event;
  const stack = new Error().stack ?? 'no-stacktrace';
  reportEvent('unhandledrejection', { reason, stack });
});

// @ts-ignore
const appConfig = config.getAppConfig(window.REACT_APP_BRAND_KEY ? window : process.env);

if (process.env.NODE_ENV === 'development') {
  const themeName = appConfig.distribution.theme === 'puiGreen' ? 'green' : 'red';
  void import('@vaillant-professional-ui/component-libs-web/src/assets/css/' + themeName + '.css').then(() => {
    return;
  });
}

const apiClient = axios.create({
  baseURL: appConfig.apiRootUrl,
  timeout: 18000,
  headers: {
    'Ocp-Apim-Subscription-Key': appConfig.subscriptionKey,
    'x-client': 'professional-ui',
    'x-brand': appConfig.distribution.brand.group,
  },
});

const app = document.getElementById('root');

if (!app) {
  throw new Error('Could not mount app: #root not found.');
}

const root = createRoot(app);

const featureConfig = {
  'system-settings': {
    // enable to test this feature for installers w/o permission in local development environment
    // runtimeContexts: ['debug'],
    runtimeContexts: [],
  },
};

root.render(<App appConfig={appConfig} apiClient={apiClient} initialFeatures={featureConfig} />);

export { features };
