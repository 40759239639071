import * as React from 'react';
import { Box, Hidden, Link } from '@mui/material';
import {
  useInstanceId,
  useTranslation,
  useConfiguration,
  ThemeVariation,
} from '@vaillant-professional-ui/component-libs-common';
import {
  AlignedTypography,
  AppStoreBadge,
  ContainerLeft,
  ContainerRight,
  ContentContainer,
  DemoButton,
  HeroImage,
  LeftTitle,
  LoginButton,
  PlayStoreBadge,
  TitleWrapper,
} from './IdmLogin.styles';
import { Typography } from '../../atoms/Typography';
import { CountrySelect, CountrySelectProps } from '../../atoms/CountrySelect';
import { assets } from './IdmLogin.assets';
import { CountryCode } from '../../../util/i18n';
import { IdmLoginProps } from './IdmLogin.types';

export const IdmLogin: React.FunctionComponent<IdmLoginProps> = ({
  countries,
  isPending,
  onSelectDemoMode,
  onSelectCountry,
  onLogin,
  productName,
  leftTitle,
  leftImageSrcSet,
  initialCountryCode,
  appStoreLink,
  playStoreLink,
  testIdKey,
}) => {
  const t = useTranslation();
  const testId = useInstanceId('login', testIdKey);

  const { themeName: themeNameUntyped } = useConfiguration(); // TODO: Fix typing in common/src/components/meta/ConfigurationProvider/ConfigurationProvider.types.ts
  const themeName = (themeNameUntyped as ThemeVariation) ?? '';

  const [countryCode, setCountryCode] = React.useState<CountryCode | null>(initialCountryCode || null);

  const hasCountries = !!countries;
  const isDemoEnabled = !isPending && (!hasCountries || !!countryCode);
  const isLoginEnabled = !isPending && (!hasCountries || !!countryCode);

  const selectCountry: CountrySelectProps['onChange'] = (e) => {
    const value = e.target.value as string;
    setCountryCode(value);
    !!onSelectCountry && onSelectCountry(value);
  };

  return (
    <Box
      onSubmit={(e) => {
        e.preventDefault();
        onLogin();
      }}
      component='form'
      display='flex'
      flexGrow={1}
      justifyContent='stretch'
      alignItems='stretch'
      {...testId('root').testIdAttributes}
    >
      <ContentContainer container={true} $themeName={themeName}>
        <Hidden smDown>
          <ContainerLeft
            $themeName={themeName}
            {...testId('left').testIdAttributes}
            container={true}
            item={true}
            xs={12}
            lg={6}
          >
            <Box display='flex' flexGrow={1} flexDirection='column' alignItems='center'>
              <TitleWrapper $themeName={themeName} alignItems='center'>
                <LeftTitle {...testId('left-title').testIdAttributes} variant='h2' color='inherit'>
                  {leftTitle}
                </LeftTitle>
              </TitleWrapper>
              <HeroImage
                heroImageSm={leftImageSrcSet[0].imageSrc}
                heroImageMd={leftImageSrcSet[1].imageSrc}
                heroImageLg={leftImageSrcSet[2].imageSrc}
                heroImageXl={leftImageSrcSet[3].imageSrc}
                {...testId('hero-image').testIdAttributes}
              />
            </Box>
          </ContainerLeft>
        </Hidden>
        <ContainerRight
          $themeName={themeName}
          {...testId('right').testIdAttributes}
          container={true}
          item={true}
          xs={12}
          lg={6}
          px={[0, 0, 2, 20]}
        >
          <Box display='flex' flexDirection='column' alignItems={'stretch'}>
            <Box display='flex' flexDirection='column' alignItems={['center', 'center', 'center', 'flex-start']}>
              <TitleWrapper $themeName={themeName} mb={8}>
                <AlignedTypography {...testId('login-title').testIdAttributes} variant='h1' color='text.primary'>
                  {t('VG_IDMLOGIN_FORM_TITLE')}
                </AlignedTypography>
                <AlignedTypography
                  {...testId('product-name').testIdAttributes}
                  variant='h1'
                  color='primary.main'
                  bold={true}
                >
                  {productName}
                </AlignedTypography>
              </TitleWrapper>
              <Box mb={[6, 6, 6, 8]}>
                <Typography variant='h3' {...testId('login-description').testIdAttributes}>
                  {t('VG_IDMLOGIN_LOGIN_DESCRIPTION')}
                </Typography>
              </Box>
            </Box>

            {!!onSelectCountry && (
              <Box mb={12} display='flex' justifyContent='stretch' flexDirection='column'>
                <CountrySelect
                  initialValue={countryCode || undefined}
                  disabled={isPending}
                  variant='outlined'
                  countryList={countries}
                  onChange={selectCountry}
                  testIdKey={testId('country-select').key}
                />
              </Box>
            )}
          </Box>
          <Box
            display='flex'
            justifyContent={['stretch', 'stretch', 'center', 'flex-start']}
            alignItems={['stretch', 'center']}
            flexDirection={['column', 'column', 'row']}
          >
            <LoginButton
              disabled={!isLoginEnabled}
              {...testId('submit').testIdAttributes}
              type='submit'
              variant='contained'
              color='secondary'
            >
              {t('VG_IDMLOGIN_SUBMIT_TEXT')}
            </LoginButton>
            {!!onSelectDemoMode && (
              <DemoButton
                disabled={!isDemoEnabled}
                onClick={onSelectDemoMode}
                {...testId('demo').testIdAttributes}
                type='button'
                variant='outlined'
                color='primary'
              >
                {t('VG_IDMLOGIN_SUBMIT_DEMO_MODE')}
              </DemoButton>
            )}
          </Box>
          <Box display='flex' flexDirection='column' justifyContent={['center', 'center', 'center', 'flex-start']}>
            {(!!appStoreLink || !!playStoreLink) && (
              <React.Fragment>
                <Box mt={[6, 6, 6, 8]} mb={1}>
                  <AlignedTypography {...testId('store-headline').testIdAttributes} variant='caption'>
                    {t('VG_IDMLOGIN_STORE_TITLE')}
                  </AlignedTypography>
                </Box>
                <Box mb={4} display='flex' justifyContent={['center', 'center', 'center', 'flex-start']}>
                  <Link href={appStoreLink} {...testId('app-store').testIdAttributes}>
                    <AppStoreBadge src={assets.appStore} alt='Apple App Store' />
                  </Link>
                  <Link href={playStoreLink} {...testId('play-store').testIdAttributes}>
                    <PlayStoreBadge src={assets.playStore} alt='Google Play Store' />
                  </Link>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </ContainerRight>
      </ContentContainer>
    </Box>
  );
};
