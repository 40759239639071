import React from 'react';

import { api } from '@vaillant-professional-ui/pui-frontend-common';
import { useValidatedParams } from '../../../lib/hooks';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { TliHeatCurvePage } from './TliHeatCurvePage';
import { TliHeatCurveParamsType } from './TliHeatCurvePage.types';
import { urlParamsSchema } from './TliHeatCurvePage.util';

export const TliHeatCurvePageContainer: React.FC<React.PropsWithChildren> = () => {
  const { deviceId, circuitIndex, systemId, boilerSerialNumber } =
    useValidatedParams<TliHeatCurveParamsType>(urlParamsSchema);

  const {
    error,
    isLoading,
    refetch,
    presentationData: tliHeatCurvePageProps,
  } = api.useCases.tliHeatCurve.presenter.useHeatCurvePresenter({
    systemId,
    deviceId,
    boilerSerialNumber,
    circuitIndex: parseInt(circuitIndex),
  });

  return (
    <LoadingErrorWrapper error={error} isPending={isLoading} showLoadingSpinner={true} onRetry={void refetch}>
      <TliHeatCurvePage {...tliHeatCurvePageProps} />
    </LoadingErrorWrapper>
  );
};
