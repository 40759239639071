import * as React from 'react';

import { api, hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useFirebase } from '../../../providers/firebase';
import { ConsumptionOutletContext } from './ConsumptionPage.types';
import { getTitleByPath } from './ConsumptionPage.util';
import { schema } from './ConsumptionPage.validation';

const serialize = (val: unknown) => JSON.stringify(val);

const deserialize = (valuesStr: string) => {
  const result = JSON.parse(valuesStr) as api.useCases.emf.ConsumptionFilters;
  result.startDate = new Date(result.startDate);
  result.endDate = new Date(result.endDate);
  return result;
};

const deserialiseFiltersFromUrlSearchParams = (search: string, defaultFilters: api.useCases.emf.ConsumptionFilters) => {
  const params = new URLSearchParams(search);
  const fromUrl = params.get('filters');
  if (!fromUrl) {
    return defaultFilters;
  }

  try {
    const deserialized = deserialize(fromUrl);
    schema.validateSync(deserialized);
    return {
      ...defaultFilters,
      ...deserialize(fromUrl),
    };
  } catch (e) {
    console.warn('Could not validate filters', fromUrl, e);
    return defaultFilters;
  }
};

const usePersistedFilters = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getPersistedFilters = React.useCallback(
    (defaultFilters: api.useCases.emf.ConsumptionFilters) =>
      deserialiseFiltersFromUrlSearchParams(location.search, defaultFilters),
    [location.search],
  );

  const persistFilters = (values: api.useCases.emf.ConsumptionFilters) => {
    const serialized = serialize(values);
    const params = new URLSearchParams({ filters: serialized });
    navigate(`?${params.toString()}`);
  };

  return { getPersistedFilters, persistFilters };
};

const useInterpretCurrentPath = (boilerSerialNumber: string) => {
  const t = hooks.useTranslation();
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop();
  const pageTitle = getTitleByPath(t, currentPath);
  const shouldShowBackLink = currentPath !== 'consumption';
  const backLinkPath = `/customers/${boilerSerialNumber}/consumption`.concat(location.search);
  return { pageTitle, shouldShowBackLink, backLinkPath };
};

const useLogCalendarActions = () => {
  const firebaseService = useFirebase();
  const logCalendarOpen = () => firebaseService.logUserAction('emf_openDateRangePicker');
  const logCalendarDateRangeChanged = (mode: api.useCases.emf.DateRangePeriod) => {
    firebaseService.logUserAction('emf_dateRangePickerValueChange', { mode });
  };
  return { logCalendarOpen, logCalendarDateRangeChanged };
};

export const usePresenter = (startDate: Date) => {
  const { boilerSerialNumber } = useParams<{ boilerSerialNumber: string }>() as { boilerSerialNumber: string };
  const { logCalendarOpen, logCalendarDateRangeChanged } = useLogCalendarActions();
  const { getPersistedFilters, persistFilters } = usePersistedFilters();

  const {
    loadingDevicesError,
    loadingConsumptionDataError,
    isLoadingCustomerOrDevices,
    isLoadingConsumptionData,
    setFilters,
    mainHeatingDeviceType,
    devices,
    filters,
    handleDateChange,
    handleCalendarOpen,
    hasCop,
    now,
    isHybridSystem,
    customer,
    data,
  } = api.useCases.emf.useConsumptionPagePresenter({
    boilerSerialNumber,
    logCalendarOpen,
    logCalendarDateRangeChanged,
    getPersistedFilters,
    persistFilters,
    startDate,
  });

  const { pageTitle, shouldShowBackLink, backLinkPath } = useInterpretCurrentPath(boilerSerialNumber);

  return {
    loadingDevicesError,
    loadingConsumptionDataError,
    isLoadingCustomerOrDevices,
    isLoadingConsumptionData,
    setFilters,
    mainHeatingDeviceType,
    devices,
    filters,
    handleDateChange,
    handleCalendarOpen,
    hasCop,
    now,
    pageTitle,
    isHybridSystem,
    customer,
    data,
    shouldShowBackLink,
    backLinkPath,
  };
};

export const useConsumptionDetailsContext = () => useOutletContext<ConsumptionOutletContext>();
