import * as React from 'react';
import { I18nContext, localeMap } from './I18nProvider';
import { useConfiguration } from '../ConfigurationProvider';
import { PrimitiveOrTranslationProps } from '../Translated';
import { TranslateFnc } from './I18nProvider.types';

export const useLiteralOrTranslation = (
  primitiveOrTranslation?: PrimitiveOrTranslationProps
): string | number | null => {
  const t = useTranslation();

  if (typeof primitiveOrTranslation === 'string' || typeof primitiveOrTranslation === 'number') {
    return primitiveOrTranslation;
  }

  if (primitiveOrTranslation?.i18nKey) {
    return t(primitiveOrTranslation.i18nKey, primitiveOrTranslation.i18nOptions) ?? null;
  }

  return null;
};

export const useTranslation = (): TranslateFnc => {
  const { locale } = useConfiguration();
  const translate = React.useContext(I18nContext).translate || ((f) => f);
  const t: TranslateFnc = (id, params) => {
    const result = translate(id, params);
    if (id === result && !!locale) {
      return localeMap[locale]?.translations?.[id]
        ? localeMap[locale].translations[id]
        : localeMap['en-gb'].translations[id] || id;
    }
    return result;
  };
  return t;
};
