import { Button, ButtonProps, Icon02301VgPlus01 } from '@vaillant-professional-ui/component-libs-web';
import React from 'react';

export const AddButton: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  return (
    <Button
      color='secondary'
      variant='contained'
      startIcon={<Icon02301VgPlus01 color='white' />}
      i18nKey={'SG_ADD_DEVICE'}
      {...props}
    />
  );
};
