/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon08500VgWaitingTime01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon08500vgwaitingtime01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path fill='currentColor' d='m248.17 204.19 35.5 74.23 35.5-74.23h-71z' className='cls-1' />
      <path
        fill='currentColor'
        d='m318.91 283.56 60-125.5a11.34 11.34 0 0 0-10.23-16.23H198.63a11.34 11.34 0 0 0-10.23 16.23l60 125.5-60 125.5a11.34 11.34 0 0 0 10.23 16.23h170.08a11.34 11.34 0 0 0 10.23-16.23zm7 119.06-30-62.77a13.58 13.58 0 0 0-24.5 0l-30 62.77h-24.79l54.6-114.17a11.33 11.33 0 0 0 0-9.78l-54.6-114.17h134.1l-54.6 114.17a11.35 11.35 0 0 0 0 9.84c1.1 2.28 38.11 79.68 54.58 114.11z'
        className='cls-1'
      />
    </svg>
  );
};
