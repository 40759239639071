import { useTheme } from '@vaillant-professional-ui/component-libs-web';
import { BreakPoint, useBreakPoint } from '../../../Utils/breakpoint';

const getBreakPointDependentStyles = (
  defaultTickLabelsStyle: {
    fill: string;
    fontFamily?: React.CSSProperties['fontFamily'];
  },
  breakPoint: BreakPoint,
) => {
  return {
    sm: {
      tickLabelsX: {
        ...defaultTickLabelsStyle,
        padding: 4,
        fontSize: 16,
      },
      tickLabelsY: {
        ...defaultTickLabelsStyle,
        fontSize: 16,
      },
      padding: { top: 8, left: 4, right: 4, bottom: 24 },
    },
    md: {
      tickLabelsX: {
        ...defaultTickLabelsStyle,
        padding: 4,
        fontSize: 12,
      },
      tickLabelsY: {
        ...defaultTickLabelsStyle,
        fontSize: 12,
      },
      padding: { top: 8, left: 0, right: 0, bottom: 24 },
    },
    lg: {
      tickLabelsX: {
        ...defaultTickLabelsStyle,
        padding: 4,
        fontSize: 16,
      },
      tickLabelsY: {
        ...defaultTickLabelsStyle,
        fontSize: 16,
      },
      padding: { top: 8, left: 0, right: 0, bottom: 16 },
    },
    xl: {
      tickLabelsX: {
        ...defaultTickLabelsStyle,
        padding: 4,
        fontSize: 16,
      },
      tickLabelsY: {
        ...defaultTickLabelsStyle,
        fontSize: 16,
      },
      padding: { top: 8, left: 0, right: 0, bottom: 16 },
    },
    default: {
      tickLabelsX: {
        ...defaultTickLabelsStyle,
        padding: 4,
        fontSize: 16,
      },
      tickLabelsY: {
        ...defaultTickLabelsStyle,
        fontSize: 16,
      },
      padding: { top: 8, left: 0, right: 0, bottom: 11 },
    },
  }[breakPoint];
};

export const useColorsByOperationMode = (): Record<string, string> => {
  const theme = useTheme();
  return {
    COOLING: theme.palette.primary.main,
    HEATING: theme.palette.primary.dark,
    DOMESTIC_HOT_WATER: theme.palette.primary.light,
  };
};

export const useStyles = () => {
  const theme = useTheme();
  const breakPoint = useBreakPoint();
  const fillColorsByOperationMode = useColorsByOperationMode();

  const tickLabels = {
    fontFamily: theme.typography.fontFamily,
    fill: theme.palette.grey['500'],
  };
  const breakpointDependentStyles = getBreakPointDependentStyles(tickLabels, breakPoint);

  return {
    ...breakpointDependentStyles,
    axis: { stroke: 'none' },
    grid: {
      stroke: theme.palette.grey['200'],
      strokeWidth: 2,
      strokeDasharray: '2,2',
      strokeLinejoin: 'miter',
      strokeLinecap: 'butt',
    },
    bar: (operationMode: string) => {
      const fill = fillColorsByOperationMode[operationMode];
      return {
        fill,
      };
    },
  };
};
