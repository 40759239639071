import React from 'react';

import { Typography } from '@vaillant-professional-ui/component-libs-web';
import { util } from '@vaillant-professional-ui/pui-frontend-common';
import { AddressCellProps } from './AddressCell.types';
import { HighlightText } from '../HighlightText';

export const AddressCell: React.FC<AddressCellProps> = ({ address, formatAddressUK, markText }) => {
  return (
    <div>
      <HighlightText markText={markText}>
        {(util.address.formatAddress(address, formatAddressUK) as string) || '-'}
      </HighlightText>
      <Typography color='text.secondary'>
        <HighlightText markText={markText}>
          {[address?.postalCode ?? '', address?.city ?? ''].join(' ').trim() || '-'}
        </HighlightText>
      </Typography>
    </div>
  );
};
