import { useTranslation } from '@vaillant-professional-ui/component-libs-common';
import { api, hooks, useLoggingContext } from '@vaillant-professional-ui/pui-frontend-common';
import { useCases } from '@vaillant-professional-ui/pui-frontend-common/src/api';
import parseISO from 'date-fns/parseISO';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import { CallbackArgs } from 'victory';
import { useConsumptionDetailsContext } from '../../pages/ConsumptionPage';
import { useStyles } from './EmfChart.styles';
import { BarsProps, EmfDataPoint } from './EmfChart.types';
import { BarDisplayOrder, barRatioMap, getEndDateForDay, translationMap } from './EmfChart.util';
import { orderBy } from 'lodash';

const useFormatTickLabelFactory = (
  reportError: ReturnType<typeof useLoggingContext>['reportError'],
  dateFormat: string,
) => {
  const format = hooks.useConfigLocalizedDateFormat();
  return (date: string) => {
    try {
      return format(parseISO(date), dateFormat).toUpperCase();
    } catch (err) {
      reportError('EmfChart parse date', err as Error);
    }
    return date;
  };
};

export const useTickFormat = ({ dateFormat, tickFrequency }: { dateFormat: string; tickFrequency?: number }) => {
  const { reportError } = useLoggingContext();
  const formatTickLabel = useFormatTickLabelFactory(reportError, dateFormat);
  return (date: string, index: number) => {
    if (tickFrequency) {
      const isFirstEntry = index === 0;
      const shouldShowAxisLabel = !!((index + 1) % tickFrequency);

      if (!isFirstEntry && shouldShowAxisLabel) {
        return '';
      }
    }
    return formatTickLabel(date);
  };
};

export const useCalculateCornerRadius = (barsProps: BarsProps[]) => {
  const groupedBars = groupBy<BarsProps['data'][0]>(
    orderBy(
      flatMap(barsProps, 'data').filter((pr) => !!pr.value),
      (x) => BarDisplayOrder[x.key],
      'desc',
    ),
    'startDate',
  );

  return (...args: [CallbackArgs]) => {
    const groupedData = groupedBars?.[args[0].datum.xName];
    if (groupedData == null) {
      return 0;
    }
    const isCurrentDataDisplayedTop = groupedData?.[0].value === args[0].datum.value;
    return isCurrentDataDisplayedTop ? 2 : 0;
  };
};

export const useFormatTitle = () => {
  const formatDate = hooks.useConfigLocalizedDateFormat();
  const filter = useConsumptionDetailsContext();

  return (dataPoint?: EmfDataPoint) => {
    const dateRangePeriod = filter?.filters?.dateRangePeriod;

    if (!dateRangePeriod || !dataPoint?.startDate) {
      return '';
    }

    const startDate = new Date(dataPoint.startDate);

    switch (dateRangePeriod) {
      case 'DAY': {
        const endDate = getEndDateForDay(dataPoint.startDate, dataPoint.endDate);

        return `${formatDate(startDate, 'p')} - ${formatDate(endDate, 'p')}`;
      }
      case 'MONTH':
      case 'WEEK':
        return formatDate(startDate, 'PPPP');
      case 'YEAR':
        return `${formatDate(startDate, 'MMMM')}, ${formatDate(startDate, 'Y')}`;
    }
  };
};

export const useChartComponentsProps = (
  buckets: Partial<Record<api.emf.EmfOperationMode, api.emf.EmfGraphValueResponse[]>>,
  styles: ReturnType<typeof useStyles>,
  dateRangePeriod: useCases.emf.DateRangePeriod,
) => {
  const t = useTranslation();
  const formatTitle = useFormatTitle();

  const barsProps = sortBy(
    Object.entries(buckets).map(([name, graphValues]) => {
      const style = { data: styles.bar(name) };
      return {
        name: name,
        data: graphValues.map((graphValue) => ({
          ...graphValue,
          value: graphValue.value,
          name: t(translationMap[name] ?? name),
          key: name,
          unit: 'kWh',
        })),
        x: 'startDate',
        y: 'value',
        barRatio: barRatioMap[dateRangePeriod],
        style,
      };
    }),
    (barProps) => BarDisplayOrder[barProps.name],
  );
  const dayCount = max(barsProps.map(({ data }) => data.length));

  const legendProps = Object.keys(buckets).map((name) => {
    const style = { data: styles.bar(name) };
    return { color: style.data.fill, label: t(translationMap[name] ?? name) };
  });

  return { barsProps, legendProps, dayCount, formatTitle };
};
