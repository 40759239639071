import React, { useState } from 'react';

import { Box, Hidden, IconButton, Toolbar } from '@mui/material';
import { Button, Typography } from '@vaillant-professional-ui/component-libs-web';
import { Icon04601VgMenu01 } from '@vaillant-professional-ui/component-libs-web';
import { DrawerContent } from '../DrawerContent';
import { LanguageSelectionContainer } from '../LanguageSelection';
import { NotificationsMenuContainer } from '../NotificationsMenu';
import { PermissionGate } from '../PermissionGate';
import { Container, HomeLink, StyledAppBar, StyledDrawer, StyledToolbar } from './NavigationBar.styles';
import { NavigationBarProps } from './NavigationBar.types';

export const NavigationBar: React.FC<React.PropsWithChildren<NavigationBarProps>> = ({
  isDemo,
  isTermsAccepted,
  onLogout,
  pathName,
  platformName,
  user,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <Container>
      <StyledAppBar elevation={4}>
        <StyledToolbar>
          <Hidden mdUp>
            <PermissionGate requiredPermissions={['VIEW_SIDEBAR']}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={() => setIsDrawerOpen((state) => !state)}
                edge='start'
                size='large'
              >
                <Icon04601VgMenu01 width={40} height={40} color='white' />
              </IconButton>
              <StyledDrawer
                anchor='left'
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                variant='temporary'
                PaperProps={{
                  sx: {
                    width: ['100%', 280],
                  },
                }}
              >
                <DrawerContent
                  pathName={pathName}
                  isDemo={isDemo}
                  onLogout={() => onLogout()}
                  close={() => setIsDrawerOpen(false)}
                  user={user}
                />
              </StyledDrawer>
              <Toolbar />
            </PermissionGate>
          </Hidden>

          <HomeLink to='/'>
            <Box pl={3} alignItems='center' minHeight={64} display='flex'>
              <Box color='common.white'>
                <Typography color='inherit' variant='h2' sx={{ sm: { variant:'h3' }, lg:{ variant:'h2' } }}>
                  {platformName}
                </Typography>
              </Box>
            </Box>
          </HomeLink>

          <Box display='flex' justifyContent='flex-end' alignItems='center' minHeight={64} gap={2}>
            <LanguageSelectionContainer />
            {(isDemo || isTermsAccepted) && (
              <PermissionGate requiredPermissions={['VIEW_NOTIFICATIONS']}>
                <NotificationsMenuContainer />
              </PermissionGate>
            )}
            <PermissionGate requiredPermissions={['VIEW_NAVBAR_LOGIN']}>
              <Button onClick={() => onLogout()} i18nKey='SG_LOGIN_LOGINBTN' variant='contained' color='secondary' />
            </PermissionGate>
          </Box>
        </StyledToolbar>
      </StyledAppBar>

      <Hidden mdDown>
        <PermissionGate requiredPermissions={['VIEW_SIDEBAR']}>
          <StyledDrawer
            variant='permanent'
            PaperProps={{
              sx: {
                width: [280],
              },
            }}
          >
            <DrawerContent
              pathName={pathName}
              isDemo={isDemo}
              onLogout={() => onLogout()}
              close={() => setIsDrawerOpen(false)}
              user={user}
            />
          </StyledDrawer>
        </PermissionGate>
      </Hidden>
    </Container>
  );
};
