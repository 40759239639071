import { ToastProviderProps, ToastState, Severity, ToastContextInterface } from './ToastProvider.types';
import React, { useState } from 'react';

const ToastContext = React.createContext<ToastContextInterface>({
  showToast: () => {
    console.warn('no showToast function defined!');
  },
});

export const ToastProvider: React.FC<React.PropsWithChildren<ToastProviderProps>> = ({
  duration = 5000,
  Toast,
  children,
}) => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [toastProps, setToastProps] = useState<ToastState>();

  const memoizedShowToast = React.useMemo(
    () => ({
      showToast: (severity: Severity, body: string, title?: string) => {
        setToastProps({ severity, body, title });
        setIsToastVisible(true);
      },
    }),
    [],
  );

  return (
    <ToastContext.Provider value={memoizedShowToast}>
      {toastProps && (
        <Toast
          id='0'
          isOpen={isToastVisible}
          duration={duration}
          severity={toastProps.severity}
          onClose={() => {
            setIsToastVisible(false);
          }}
          title={toastProps.title}
          body={toastProps.body}
        />
      )}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastContext);
