import { useSelector } from 'react-redux';
import { useChartDetails } from '../../../../api/apis/maintenance/v2/maintenance.hooks';
import { store } from '../../../../store/store';
import { ChartDetailsResponse } from '../../../../api/apis/maintenance/v2/maintenance.types';
import { printableUnit } from '../../../../api/apis/maintenance/v2';

interface UseCaseParams {
  boilerSerialNumber: string;
  url: string;
  start: Date;
  end: Date;
  selectedTimeSeries: string[] | null;
}

export const useCase = ({ start, end, url, selectedTimeSeries }: UseCaseParams) => {
  const language = useSelector(store.getLanguage);

  const {
    data: chartDetails,
    isLoading,
    isFetching,
    isError,
  } = useChartDetails({ chartUrl: new URL(url), start, end, selectedTimeSeries, language }, { keepPreviousData: true });

  const transform = (queryData: ChartDetailsResponse) => {
    const unit = printableUnit(queryData.unit);
    const categories = queryData.categories.map((category) => {
      const { time_series, title } = category;
      const timeSeries = time_series.map((ts) => {
        const { id, title, enabled: isEnabled } = ts;
        return { id, title, isEnabled, unit };
      });

      return {
        title,
        timeSeries,
      };
    });

    const timeSeriesData = (queryData.time_series_data ?? []).map((timeSeries) => {
      const values = timeSeries.values.map((value) => {
        return {
          ...value,
          timestamp: new Date(value.timestamp),
        };
      });

      return {
        ...timeSeries,
        values,
      };
    });

    return {
      title: queryData.title,
      categories,
      timeSeriesData,
      maxTimeSeries: queryData.max_time_series,
      ranges: queryData.ranges,
    };
  };

  const data = chartDetails && transform(chartDetails);

  return {
    data,
    isLoading,
    isFetching,
    isError,
  };
};
