import React from 'react';

import { Box } from '@mui/material';

import { Card, CardContent, CardHeader, Grid } from '@vaillant-professional-ui/component-libs-web';

import { ZoomChart } from '../ZoomChart';
import { ZoomChartSkeleton } from '../ZoomChart/ZoomChart.skeleton';
import { SystemMonitoringChartsProps } from './SystemMonitoringCharts.types';

export const SystemMonitoringCharts: React.FC<SystemMonitoringChartsProps> = ({
  dataProps,
  isLoading,
  ...zoomChartProps
}) => {
  if (isLoading) {
    return (
      <Grid container spacing={4} flexDirection='row'>
        {[...Array(3).keys()].map((_, index) => {
          return (
            <Grid item xs={12} xl={6} key={index}>
              <Box mb={2}>
                <Card>
                  <CardContent>
                    <ZoomChartSkeleton />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Grid container spacing={4} flexDirection='row'>
      {dataProps.map((props, index) => {
        return (
          <Grid item xs={12} xl={6} key={index}>
            <Box mb={2}>
              <Card>
                <CardHeader title={props.title} testIdKey={`zoom-chart-${index}`} />
                <CardContent>
                  <ZoomChart dataProps={props} {...zoomChartProps} />
                </CardContent>
              </Card>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
