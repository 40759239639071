import React from 'react';
import ActivitySpinner from '../ActivitySpinner';
import { LoadingErrorWrapperProps } from './LoadingErrorWrapper.types';
import { LoadingErrorPanel } from '@vaillant-professional-ui/component-libs-web';
import { Box } from '@mui/material';

export const LoadingErrorWrapper: React.FC<React.PropsWithChildren<LoadingErrorWrapperProps>> = ({
  children,
  error,
  isPending,
  showLoadingSpinner,
  onRetry,
  showOnScreen = true,
}) => {
  if (isPending && showLoadingSpinner) {
    return <ActivitySpinner loading={true} onScreen={showOnScreen} />;
  }

  if (!error || isPending) {
    return <>{children}</>;
  }

  return (
    <Box mb={4} display='flex' alignItems='center' justifyContent='center' flexGrow={1}>
      <LoadingErrorPanel statusCode={error?.response?.status} onRetry={onRetry} />
    </Box>
  );
};
