import React, { useMemo } from 'react';

import {
  createContainer,
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryBrushContainer,
  VictoryChart,
  VictoryCursorContainerProps,
  VictoryZoomContainerProps,
} from 'victory';

import { Box, useMediaQuery } from '@mui/material';

import { useTheme } from '@vaillant-professional-ui/component-libs-web';
import { features, hooks } from '@vaillant-professional-ui/pui-frontend-common';

import { HandleComponent } from './HandleComponent';
import { TimeSeriesGraphs } from './TimeSeriesGraphs';
import { getThemeForVictory } from './ZoomChart.styles';
import { ZoomChartProps } from './ZoomChart.types';
import { useElementSize } from '../../../../lib/hooks';
import LegendButton from '../../../../components/LegendButton';
import { CursorChartTooltip } from '../../../../components/organisms/CursorChartTooltip';

const VictoryZoomCursorContainer = createContainer<VictoryZoomContainerProps, VictoryCursorContainerProps>(
  'zoom',
  'cursor',
);

export const ZoomChart: React.FC<ZoomChartProps> = ({ dataProps, containerProps }) => {
  const { setZoomRange, xDomain } = containerProps;
  const { lines, dtcs, yDomain, getTooltipValuesFactory, zoomDomain } = dataProps;

  const { ref: wrapperRef, width: wrapperWidth } = useElementSize();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const df = hooks.useDateFormatter();

  const handleZoom: VictoryZoomContainerProps['onZoomDomainChange'] = (newDomain) => {
    if (newDomain?.x) {
      setZoomRange(newDomain.x as features.historyCharts.historyChartsCard.DateTimeRange);
    }
  };

  const domain = { x: xDomain ?? undefined, y: yDomain ?? undefined };
  const [visibleLineNames, toggleVisibleLineNames] = hooks.useToggleListItem(lines.map((line) => line.name));
  const [visibleDtcNames, toggleVisibleDtcNames] = hooks.useToggleListItem(dtcs.map((line) => line.name));

  const visibleLines = useMemo(
    () => lines.filter((line) => visibleLineNames.includes(line.name)),
    [lines, visibleLineNames],
  );

  const visibleDtcs = useMemo(
    () => dtcs.filter((line) => visibleDtcNames.includes(line.name)),
    [dtcs, visibleDtcNames],
  );

  const getTooltipValues = useMemo(
    () => getTooltipValuesFactory(visibleLineNames, visibleDtcNames),
    [getTooltipValuesFactory, visibleLineNames, visibleDtcNames],
  );

  return (
    <>
      <Box display='flex' flexDirection='column' gap={4}>
        <Box display='flex' flexWrap='wrap' justifyContent='flex-start' gap={4}>
          {lines.map((line) => {
            return (
              <LegendButton
                type='circle'
                color={line.color}
                key={line.name}
                active={visibleLineNames.includes(line.name)}
                handleClick={() => toggleVisibleLineNames(line.name)}
              >
                {line.name}
              </LegendButton>
            );
          })}
        </Box>
        <Box display='flex' flexWrap='wrap' justifyContent='flex-start' gap={4} pb={8}>
          {dtcs.map((dtc) => {
            return (
              <LegendButton
                type='bar'
                color={dtc.color}
                key={dtc.name}
                active={visibleDtcNames.includes(dtc.name)}
                handleClick={() => toggleVisibleDtcNames(dtc.name)}
              >
                {dtc.name}
              </LegendButton>
            );
          })}
        </Box>
      </Box>
      <Box mx={-2} p={0} ref={wrapperRef}>
        <VictoryChart
          theme={getThemeForVictory(theme)}
          width={wrapperWidth}
          scale={{ x: 'time' }}
          domain={domain}
          padding={{ left: 32, right: 26, top: 24, bottom: 24 }}
          containerComponent={
            <VictoryZoomCursorContainer
              name='zoom-cursor'
              allowPan={false}
              cursorDimension='x'
              responsive={false}
              zoomDimension='x'
              zoomDomain={zoomDomain}
              onZoomDomainChange={handleZoom}
              cursorLabel={() => ' '}
              cursorLabelComponent={
                <CursorChartTooltip
                  width={wrapperWidth}
                  titleRenderer={(titleData) =>
                    (titleData && `${df(titleData.timeRange[0])} - ${df(titleData.timeRange[1])}`) ?? ''
                  }
                  getTooltipValues={getTooltipValues}
                />
              }
            />
          }
        >
          <VictoryAxis
            offsetY={24}
            orientation='bottom'
            style={{
              axis: { stroke: 'lightgray' },
              grid: {
                stroke: 'lightgray',
              },
              tickLabels: { fill: '#919191', fontFamily: theme.typography.fontFamily, fontSize: '12px' },
            }}
          />
          <VictoryAxis
            dependentAxis={true}
            style={{
              axis: { stroke: 'lightgray' },
              grid: {
                stroke: 'lightgray',
              },
              tickLabels: { fill: '#919191', fontFamily: theme.typography.fontFamily, fontSize: '12px' },
            }}
          />

          {visibleDtcs.map((dtcType) => {
            return (
              <VictoryBar
                key={dtcType.name}
                style={{
                  data: {
                    fill: dtcType.color,
                    width: 6,
                    opacity: 0.5,
                  },
                }}
                data={dtcType.coordinates.map((value) => [value[0], yDomain?.[1]])}
                x={0}
                y={1}
              />
            );
          })}
          {!!yDomain &&
            yDomain[0] < 0 &&
            visibleDtcs.map((dtcType) => {
              return (
                <VictoryBar
                  key={dtcType.name}
                  style={{
                    data: {
                      fill: dtcType.color,
                      width: 6,
                      opacity: 0.5,
                    },
                  }}
                  data={dtcType.coordinates.map((value) => [value[0], yDomain?.[0]])}
                  x={0}
                  y={1}
                />
              );
            })}

          {visibleLines.map((line) => (
            <TimeSeriesGraphs key={line.name} line={line} />
          ))}
        </VictoryChart>

        {isSm && (
          <VictoryChart
            height={150}
            width={wrapperWidth}
            scale={{ x: 'time' }}
            domain={domain}
            padding={{ left: 32, right: 26, top: 48, bottom: 48 }}
            containerComponent={
              <VictoryBrushContainer
                brushStyle={{ stroke: 'transparent', fill: '#9BC2A5', fillOpacity: 0.5 }}
                // @ts-ignore this is fine. Victory injects the missing props.
                handleComponent={<HandleComponent />}
                handleWidth={13}
                name='brush-container'
                responsive={false}
                brushDimension='x'
                brushDomain={zoomDomain}
                onBrushDomainChangeEnd={handleZoom}
              />
            }
          >
            {visibleDtcs.map((dtcType) => {
              return (
                <VictoryBar
                  key={dtcType.name}
                  style={{
                    data: {
                      fill: dtcType.color,
                      width: 2,
                      opacity: 0.5,
                    },
                  }}
                  data={dtcType.coordinates.map((value) => [value[0], yDomain?.[1]])}
                  x={0}
                  y={1}
                />
              );
            })}
            {yDomain &&
              yDomain[0] < 0 &&
              visibleDtcs.map((dtcType) => {
                return (
                  <VictoryBar
                    key={dtcType.name}
                    style={{
                      data: {
                        fill: dtcType.color,
                        width: 2,
                        opacity: 0.5,
                      },
                    }}
                    data={dtcType.coordinates.map((value) => [value[0], yDomain?.[0]])}
                    x={0}
                    y={1}
                  />
                );
              })}
            {visibleLines.map((line) => (
              <VictoryArea
                groupComponent={<g />}
                interpolation='natural'
                key={line.name}
                style={{
                  data: { stroke: '#ddd', fill: '#ddd', strokeWidth: 1, opacity: 0.5 },
                  parent: { border: '1px solid #ccc' },
                }}
                data={line.coordinates}
                x={0}
                y={1}
              />
            ))}
            <VictoryAxis
              offsetY={50}
              orientation='bottom'
              style={{
                axis: { stroke: '#ddd' },
                grid: {
                  stroke: 'none',
                },
                tickLabels: { fill: '#919191', fontFamily: theme.typography.fontFamily, fontSize: '12px' },
              }}
            />
          </VictoryChart>
        )}
      </Box>
    </>
  );
};
