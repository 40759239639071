import { useIntl } from 'react-intl';
import { useDateFormatter, useTranslation } from '../../../libs/hooks';
import { getGatewaySerialNumber, getSystemId } from '../../../selectors/customer';
import { ICustomerDetail } from '../../../typings/models';
import { useCase } from './usecase';

export const usePresenter = (customer: ICustomerDetail) => {
  const t = useTranslation();
  const df = useDateFormatter();

  const { formatDate, formatTime } = useIntl();

  const gatewaySerialNumber = getGatewaySerialNumber(customer);
  const systemId = getSystemId(customer);

  const { data, error, refetch, isLoading, dataUpdatedAt } = useCase(gatewaySerialNumber, t, df);

  const lastUpdatedText = t('SG_MONITORING_LAST_UPDATED', {
    date: `${formatDate(new Date(dataUpdatedAt))}, ${formatTime(new Date(dataUpdatedAt))}`,
  });

  const title = t('SG_SYSTEM_SETTINGS_TITLE');
  const callToActionLabel = t('SG_MONITORING_DETAILS_LINK');

  return { systemId, data, error, refetch, isLoading, lastUpdatedText, title, callToActionLabel };
};
