import React from 'react';
import { Box } from '@mui/system';
import { CheckableDeviceInfo, Typography } from '@vaillant-professional-ui/component-libs-web';
import { hooks } from '@vaillant-professional-ui/pui-frontend-common';
import { SectionProps } from './EmfDeviceSelection.types';
import { deviceTypeIntlKey } from './EmfDeviceSelection.constants';

export const Section = ({ i18nKey, devices, onChange, filters }: SectionProps) => {
  const t = hooks.useTranslation();

  return (
    <>
      <Box mb={2}>
        <Typography letterCase='allCaps' variant='caption' color='text.secondary' i18nKey={i18nKey} />
      </Box>

      <Box minWidth={['100%', '100%', '100%', 500]}>
        {devices.map((device) => {
          const isChecked = Boolean(filters.selectedDevices === 'all' || filters.selectedDevices?.includes(device.id));

          return (
            <Box mb={4} key={device.id}>
              <CheckableDeviceInfo
                testIdKey={device.id}
                onChange={(_, checked) => onChange(device.id, checked)}
                isChecked={isChecked}
                deviceCategory={t(deviceTypeIntlKey[device.type])}
                serialNumber={device.serialNumber}
                marketingName={device.productName}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};
