import React from 'react';

import { Box, ListItemText } from '@mui/material';
import { SideMenuItem, Typography } from '@vaillant-professional-ui/component-libs-web';
import LinkBase from '../Link/Link';
import { getStyles } from './Link.styles';
import { LinkProps } from './Link.types';

export const createLinkComponent: (
  pathName: string,
  onClose: () => void,
) => React.FC<React.PropsWithChildren<LinkProps>> = (pathName, onClose) =>
  function Link({ i18nKey, to, label, testIdKey, icon: Icon, suffix, ...rest }: LinkProps) {
    const isActive = pathName.startsWith(to);
    const { icon, typography, sideMenuItem } = getStyles(isActive);

    return (
      <LinkBase onClick={onClose} style={{ textDecoration: 'none' }} to={to} {...rest}>
        <SideMenuItem
          style={sideMenuItem}
          testIdKey={testIdKey}
          isActive={isActive}
          leftComponent={Icon && <Icon height='32' width='32' color={icon.color} />}
          contentComponent={
            <ListItemText>
              <Box display='flex' gap={2}>
                <Typography
                  width='fit-content'
                  i18nKey={i18nKey}
                  fontWeight={typography.fontWeight}
                  color={typography.color}
                >
                  {label}
                </Typography>
                {suffix && (
                  <Typography bold width='fit-content' color={typography.color}>
                    {suffix}
                  </Typography>
                )}
              </Box>
            </ListItemText>
          }
        />
      </LinkBase>
    );
  };
