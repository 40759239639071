import { Box } from '@mui/material';
import { styled } from '../../../util/styling';

export const DataPointCardList = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  ${(props) => props.theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr;
  }

  padding: 0;
  margin-bottom: 8px;
  gap: ${({ theme }) => theme.spacing(1)} 1%;
`;
