import * as React from 'react';
import { Box, Skeleton } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';
import { DataPointCardItemSkeletonProps } from './DataPointCardItem.types';
import { Card } from './DataPointCardItem.styles';
import { variantMap } from './DataPointCardItem.variants';

export const DataPointCardItemSkeleton: React.FunctionComponent<DataPointCardItemSkeletonProps> = ({
  testIdKey,
  variant = 'default',
  ...rest
}) => {
  const testId = useInstanceId('datapointcarditem-skeleton', testIdKey);
  const variantConfig = variantMap[variant];

  return (
    <Card $severityLevel='info' display='flex' elevation={0} flexGrow={1} testIdKey={testId('root').key} {...rest}>
      <Box display='flex' flexDirection='row' alignItems='flex-start' flexGrow={1}>
        <Box display='flex' mr={1}>
          <Skeleton variant='circular' width={variantConfig.iconSize} height={variantConfig.iconSize} />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='left' mr={1} gap={variantConfig.title.gap} flexGrow={1}>
          <Typography variant={variantConfig.title.variant} bold>
            <Skeleton variant='text' animation='wave' width={'30%'} />
          </Typography>
          <Typography variant={variantConfig.subtitle.variant}>
            <Skeleton variant='text' animation='wave' width={'60%'} />
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
