/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon04701VgInformation01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon04701vginformation01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M311.81 176.1c0 15.32-13.27 28.09-28.09 28.09-15.32 0-28.6-12.77-28.6-28.09s13.28-28.6 28.6-28.6c14.82 0 28.09 13.28 28.09 28.6zm-51 249.19V247.86c0-9.7 5.62-15.32 15.32-15.32h30.14v192.75z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
