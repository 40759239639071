import React from 'react';
import { maskString } from '../util/string';
import { Device, HeatingDeviceType, IBoiler } from '../typings/models';

const SERIAL_NUMBER_PATTERN = /^(21)([0-9]{2}([0-4][0-9]|5[0-3]))([0-9]{6})([0-9]{4}|<{4})([0-9]{10})[N][0-9]$/;
const SERIAL_NUMBER_MASK = '21000011111122223333333333N0';
const SERIAL_NUMBER_MASK2 = '210000111111<<<<3333333333N0';

const UUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const getHeatingDeviceType = (boiler: IBoiler): HeatingDeviceType => boiler.deviceType ?? 'BOILER';

export const getSerialNumber = (boiler: IBoiler): string => boiler.serialNumber || '';
export const getMarketingName = (boiler: Device): string => boiler.marketingName || '';
export const getNomenclature = (boiler: Device): string => boiler.nomenclature || '';

export const isSerialNumberValid = (serialNumber: string, strictTest?: boolean) => {
  if (!SERIAL_NUMBER_PATTERN.test(serialNumber)) {
    return false;
  }

  if (strictTest) {
    const charValueModifier = 32;
    const indexCorrection = 1;

    const charCodes = serialNumber
      .substring(0, serialNumber.length - 1)
      .split('')
      .map((ch) => ch.charCodeAt(0) - charValueModifier);

    const sum = charCodes.reduce((acc, el, index) => acc + el * (index + indexCorrection));
    const checkNumber = sum % 10;
    return serialNumber.endsWith('N' + checkNumber);
  }
  return true;
};

export const isPassing = (input: string): boolean => {
  if (input.length === 0) {
    return true;
  }

  let neededMask = '';

  if (input.length > 12 && input[12] === '<') {
    neededMask = SERIAL_NUMBER_MASK2.substr(input.length);
  } else {
    neededMask = SERIAL_NUMBER_MASK.substr(input.length);
  }
  return isSerialNumberValid(maskString(28, input, neededMask), input.length === 28);
};

export const extractArticleNumber = (serialNumber: string): string => {
  return isSerialNumberValid(serialNumber) ? serialNumber.substring(6, 16).replace(/</g, '') : '';
};

export const getHighlightedArticleNumber = (
  serialNumber: string,
  highlightStyle = { fontWeight: 'bold' } as React.CSSProperties,
): React.ReactNode => {
  if (!isSerialNumberValid(serialNumber)) {
    return serialNumber;
  }

  const articleNumber = extractArticleNumber(serialNumber);
  const bits = serialNumber.split(articleNumber);
  return (
    <>
      {bits[0]}
      <span style={highlightStyle}>{articleNumber}</span>
      {bits[1]}
    </>
  );
};

export const isSerialNumberUUID = (serialNumber: string) => UUID_PATTERN.test(serialNumber);
