import React from 'react';
import {
  ControllerType,
  SelectTLITemperatureProps,
  TliScheduleEditorCentralHeatingProps,
} from './ScheduleEditor.types';
import { createDefaultTimeWindowWithTargetTemperature, generateTemperatureSelectOptions } from './ScheduleEditor.util';
import { useScheduleEditor } from './ScheduleEditor.hooks';
import { util } from '@vaillant-professional-ui/component-libs-common';
import { SelectChangeEvent } from '@mui/material';
import { Select } from '../../atoms/Select';
import { ScheduleEditor } from './ScheduleEditor';

const SelectTLITemperature: React.FC<SelectTLITemperatureProps> = ({
  timeWindowIndex,
  temperatureConfig,
  timespan,
  onChange,
  testIdKey,
}) => {
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const changedTemperature: Partial<util.TimeWindowWithTargetTemperature> = {
      temperatureTarget: e.target.value as number,
    };
    onChange(timeWindowIndex, changedTemperature);
  };

  const options =
    generateTemperatureSelectOptions(
      temperatureConfig.minimum,
      temperatureConfig.maximum,
      temperatureConfig.stepSize,
    ) ?? [];
  return (
    <Select initialValue={timespan.temperatureTarget} options={options} onChange={handleChange} testIdKey={testIdKey} />
  );
};

export const TliScheduleEditorCentralHeating: React.FC<TliScheduleEditorCentralHeatingProps> = ({
  values,
  maxTimeSlotsPerDay: maxTimeWindows,
  timeStepWidthInMinutes,
  temperatureConfig,
  onChange,
}) => {
  const { managedValues, onAddTimeWindow, deleteTimespan, changeTimeWindow, maximumStartTime } =
    useScheduleEditor<util.TimeWindowWithTargetTemperature>(
      values,
      timeStepWidthInMinutes,
      onChange,
      createDefaultTimeWindowWithTargetTemperature,
    );
  const renderSelectComponent = (index: number, timespan: any) => (
    <SelectTLITemperature
      timeWindowIndex={index}
      temperatureConfig={temperatureConfig}
      onChange={changeTimeWindow}
      timespan={timespan}
      testIdKey='tli-heating-select-temperature'
    />
  );

  return (
    <ScheduleEditor
      onChangeTimeWindow={changeTimeWindow}
      renderSelectComponent={renderSelectComponent}
      managedValues={managedValues}
      timeStepWidthInMinutes={timeStepWidthInMinutes}
      maximumStartTime={maximumStartTime}
      maxTimeWindows={maxTimeWindows}
      deleteTimespan={deleteTimespan}
      onAddTimeWindow={onAddTimeWindow}
      controllerType={ControllerType.TLI}
      testIdKey='tli-heating'
    />
  );
};
