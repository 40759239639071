import React from 'react';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { useCustomerListPresenter } from './CustomerList.presenter';
import { CustomerListPage } from './CustomerListPage';

export const CustomerListPageContainer: React.FC<React.PropsWithChildren> = () => {
  const {
    customers,
    error,
    hasNoCustomers,
    isLoading,
    loyaltyRegistrations,
    query,
    refetch,
    setQuery,
    shouldShowLoyaltyRegistrations,
    hasNoSearchResult,
    shouldShowTable,
    shouldShowAddCustomerInHeader,
    shouldShowAsCards,
    shouldShowAsTable,
    shouldShowPageHeader,
    sorting,
  } = useCustomerListPresenter();

  return (
    <LoadingErrorWrapper
      error={error}
      isPending={isLoading}
      showLoadingSpinner
      onRetry={() => {
        void refetch();
      }}
    >
      <CustomerListPage
        customers={customers}
        hasNoSearchResult={hasNoSearchResult}
        error={error}
        hasNoCustomers={hasNoCustomers}
        isLoading={isLoading}
        loyaltyRegistrations={loyaltyRegistrations}
        query={query}
        refetch={refetch}
        setQuery={setQuery}
        shouldShowLoyaltyRegistrations={shouldShowLoyaltyRegistrations}
        shouldShowTable={shouldShowTable}
        shouldShowAddCustomerInHeader={shouldShowAddCustomerInHeader}
        shouldShowAsCards={shouldShowAsCards}
        shouldShowAsTable={shouldShowAsTable}
        shouldShowPageHeader={shouldShowPageHeader}
        sorting={sorting}
      />
    </LoadingErrorWrapper>
  );
};
