const env = window.REACT_APP_BRAND_KEY === undefined ? process.env : window;

const EnvEnums = {
  COMPANY_NAME: env.REACT_APP_BRAND_KEY,
  API_BRAND: env.REACT_APP_BRAND_KEY === 'vaillant' ? 'Vaillant' : 'sdbg',
};

const companyComponentSwitch = <T>(RedComponent: T, GreenComponent: T) => {
  switch (EnvEnums.COMPANY_NAME) {
    case 'awb':
    case 'bulex':
    case 'glow-worm':
    case 'saunierduval':
      return RedComponent;
    case 'vaillant':
      return GreenComponent;
    default:
      return GreenComponent;
  }
};

export { companyComponentSwitch };
