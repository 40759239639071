import React from 'react';
import { api, selectors } from '@vaillant-professional-ui/pui-frontend-common';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { useValidatedParams } from '../../../lib/hooks';
import { TliSchedulerPage } from './TliSchedulerPage';
import { RouteParamsSchema, routeParamsSchema } from './TliSchedulerPage.schema';

export const TliSchedulerPageContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    boilerSerialNumber,
    systemId,
    deviceId,
    zone: _zone,
  } = useValidatedParams<RouteParamsSchema>(routeParamsSchema);
  const zoneIndex = parseInt(_zone, 10);

  const {
    error: errorUser,
    customer,
    isLoading: isLoadingUser,
    fullName,
  } = api.useCases.useCustomerDetails(boilerSerialNumber);

  const gatewaySerialNumber = customer ? selectors.customer.getGatewaySerialNumber(customer) : null;
  const controlSerialNumber = customer ? selectors.customer.getControlSerialNumber(customer) : null;

  const {
    presentationData,
    isLoading: isLoadingPresenter,
    error: errorPresenter,
    patchHeatingSchedule,
  } = api.useCases.tliScheduler.useTliScheduler({
    gatewaySerialNumber: gatewaySerialNumber ?? '',
    controlSerialNumber: controlSerialNumber ?? '',
    systemId,
    deviceId,
    zoneIndex,
    options: {
      enabled: !!gatewaySerialNumber && !!controlSerialNumber,
    },
  });

  const isLoading = isLoadingUser || isLoadingPresenter;
  const error = errorPresenter || errorUser;
  const zoneData = presentationData?.zoneSchedules?.find(
    (z: api.useCases.tliScheduler.TliZone) => z.index === zoneIndex,
  );

  return (
    <LoadingErrorWrapper error={error} isPending={isLoading} showLoadingSpinner={true}>
      {zoneData && <TliSchedulerPage customerName={fullName ?? ''} zoneData={zoneData} onSave={patchHeatingSchedule} />}
    </LoadingErrorWrapper>
  );
};
