import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getFiltered, useHistory } from '../../apis/history';
import { useWaterPressureRefills } from '../../apis/maintenance/v1';
import { UseMonitoringDetails } from './MonitoringDetails.hooks.types';
import {
  transformToLiterPerHourChartData,
  transformToPressureChartData,
  transformToTemperatureChartData,
} from './MonitoringDetails.hooks.utils';

export const useMonitoringDetails: UseMonitoringDetails = ({
  boilerSerialNumber,
  gatewaySerialNumber,
  now = Date.now(),
  hoursToLookBack,
}) => {
  const { formatDate } = useIntl();
  const history = useHistory({ boilerSerialNumber, gatewaySerialNumber, hoursToLookBack, now });
  const historyData = useMemo(() => getFiltered(history.data, hoursToLookBack), [history.data, hoursToLookBack]);

  const isHistoryValuesAvailable = Boolean(
    !history.isFetching &&
      (historyData?.waterPressure?.values?.length ||
        historyData?.returnTemperature?.values?.length ||
        historyData?.dhwTemperature?.values?.length ||
        historyData?.flowTemperature?.values?.length),
  );

  const waterPressureRefills = useWaterPressureRefills({ boilerSerialNumber, now, hoursToLookBack });

  const isFetching = history.isFetching || waterPressureRefills.isFetching;
  const isLoading = history.isLoading || waterPressureRefills.isLoading;
  const isError = waterPressureRefills.isError || history.isError;
  const isSuccess = !!history.data;

  const temperatureChartData = useMemo(
    () => (!isFetching ? transformToTemperatureChartData(historyData) : []),
    [isFetching, historyData],
  );

  const literPerHourChartData = useMemo(
    () => (!isFetching ? transformToLiterPerHourChartData(historyData) : []),
    [isFetching, historyData],
  );

  const pressureChartData = useMemo(
    () => (!isFetching ? transformToPressureChartData(formatDate, historyData, waterPressureRefills?.data) : []),
    [formatDate, historyData, isFetching, waterPressureRefills?.data],
  );

  return {
    isFetching,
    isLoading,
    isError,
    isSuccess,
    temperatureChartData,
    pressureChartData,
    literPerHourChartData,
    isHistoryValuesAvailable,
  };
};
