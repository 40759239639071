import { Box } from '@mui/material';
import { styled } from '../../../util/styling';

export const TabContainer = styled(Box)`
  display: flex;
`;

export const TabContentStyled = styled(Box)<{ isVisible: boolean }>`
  display: block;
  margin-right: -100%;
  width: 100%;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
