export const errorCodes = {
  fallback: 0,
  noGatewaySerialNumber: 1,
  customerNotFound: 2,
  invalidToken: 3,
  expiredToken: 4,
} as const;

export const errorCodeTranslationKeys = {
  0: 'SG_PERMISSION_DENIED_TEXT',
  1: 'SG_PERMISSION_DENIED_TEXT_NO_GW',
  2: 'SG_PERMISSION_DENIED_TEXT_GW_NOT_FOUND',
  3: 'SG_PERMISSION_DENIED_TEXT_TOKEN_INVALID',
  4: 'SG_PERMISSION_DENIED_TEXT_TOKEN_EXPIRED',
} as const;
