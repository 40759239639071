const globals = {
  responseStatus: {
    PAYMENT_REQUIRED: 'PAYMENT_REQUIRED',
  },
  FEATURE: {
    enablePayment: false,
  },
  MANDATORY_DISCLOSURE: '*',
  FORMATTING: {
    datetime: {
      de: 'DD.MM.YYYY, HH:mm:ss',
      en: 'DD/MM/YYYY HH:mm:ss',
    },
    dateShort: {
      de: 'DD.MM.YY',
      en: 'DD/MM/YY',
    },
  },
  COMPANY_TO_BRANDGROUP: {
    'Saunier Duval': 'SDBG',
    Vaillant: 'vaillant',
  },
  SERIAL_NUMBER: {
    BOILER: {
      LENGTH: 28,
    },
  },
  SPARE_PARTS_LINK: {
    VAILLANT: 'https://sparepart.vaillant-group.com/',
    SAUNIER_DUVAL: '',
    BULEX: '',
  },
};

export default globals;
