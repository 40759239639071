import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../lib/hooks';

export const useEnforceListViewOnMobile = () => {
  const theme = useTheme();
  const query = useQuery();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const viewMode = query.get('view');

  if (matches && viewMode === 'map') {
    navigate('?view=list');
  }
};
