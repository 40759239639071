/* eslint-disable */
import * as React from 'react';
import { IconProps } from './Icon.types';
import { useThemedIconProps } from './Icon.hooks';

export const Icon12201VgUpdate01: React.FC<IconProps> = (unthemedProps) => {
  const props = useThemedIconProps('icon12201vgupdate01', unthemedProps);
  return (
    <svg data-name='Ebene 1' viewBox='0 0 566.93 566.93' {...props}>
      <path
        fill='currentColor'
        d='M113.52 258.42a11.38 11.38 0 0 0 8.66-.54L187 226.14a11.34 11.34 0 0 0-10-20.37l-41.59 20.37a158.79 158.79 0 0 1 303.09 23.05 11.35 11.35 0 0 0 11.06 8.89 11.6 11.6 0 0 0 2.47-.27 11.33 11.33 0 0 0 8.61-13.52 181.41 181.41 0 0 0-139-138.18c-87.5-18.82-174.85 29.19-206.94 110.73L95.63 177.9a11.34 11.34 0 1 0-20.36 10L107 252.68a11.31 11.31 0 0 0 6.52 5.74zm378.14 120.63-31.74-64.81a11.33 11.33 0 0 0-15.17-5.19L380 340.79a11.34 11.34 0 0 0 10 20.37l41.58-20.37a158.78 158.78 0 0 1-303.08-23.06 11.34 11.34 0 0 0-22.14 4.91 181.46 181.46 0 0 0 345.94 27.45l19 38.91a11.34 11.34 0 1 0 20.36-10z'
      />
      <path fill='none' d='M0 0h566.93v566.93H0z' />
    </svg>
  );
};
