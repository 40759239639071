import { ToggleButton } from '@mui/material';
import { styled } from '../../../util/styling';

export const StyledToggleButton = styled(ToggleButton)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
  border-width: 2px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  font-size: inherit;
  line-height: 25px;
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2)};
  text-transform: none;

  ${({ disabled, theme, selected, color }) => {
    return disabled
      ? `border-color: ${theme.palette.grey['350']} !important`
      : `color: ${theme.palette.common.black} !important;
    border-color: ${selected ? theme.palette[color].main : theme.palette.grey['500']} !important;
    background-color: ${selected ? theme.palette[color].lightest : 'transparent'} !important;
    svg {
        ${selected ? `color: ${theme.palette[color].main} !important;` : ''}
      }`;
  }};
`;
