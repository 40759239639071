import * as React from 'react';
import { TabContentStyled } from './TabContent.styled';

export interface TabContentProps extends React.PropsWithChildren {
  value: number | string;
  index: number | string;
}

export const TabContent: React.FC<TabContentProps> = ({ value, index, ...rest }) => {
  return <TabContentStyled isVisible={value === index} role='tabpanel' {...rest} />;
};
