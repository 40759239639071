import { head } from 'lodash';
import { SystemOverviewDevice } from '../../../api/useCases';

import { useTranslation } from '../../../libs/hooks';
import { isErelax } from '../../../selectors/customer';
import { useSystemSettingsData } from './useCase';

export interface SelectionOption {
  key: string;
  value: string;
  label: string;
}

const createSelectionOption = (device: SystemOverviewDevice): SelectionOption => ({
  key: device.id,
  value: device.id,
  label: device.label,
});

interface Props {
  boilerSerialNumber: string;
  systemId: string;
  deviceId?: string;
}

export const usePresenter = ({ boilerSerialNumber, systemId, deviceId }: Props) => {
  const { devices, customer, error, isLoading } = useSystemSettingsData({
    boilerSerialNumber,
    systemId,
    deviceId,
  });

  const device = deviceId ? devices?.find((device) => device.id === deviceId) : head(devices ?? []);

  const selectedDevice = device
    ? {
        id: device.id,
        serialNumber: device.serialNumber,
        marketingName: device.marketingName,
        nomenclature: device.nomenclature,
      }
    : null;

  const t = useTranslation();
  const pageTitle = t('SG_SYSTEM_SETTINGS_TITLE');

  const shouldShowErelaxScheduler = device?.genericType === 'CONTROL' && customer ? isErelax(customer) : false;
  const shouldShowTliControlComponent = device?.type === 'CONTROL/TLI';
  const shouldShowDeviceSettingsComponent = !shouldShowErelaxScheduler && !shouldShowTliControlComponent;

  const deviceSelectionOptions = devices?.map(createSelectionOption) ?? [];

  return {
    selectedDevice,
    customer,
    error,
    isLoading,
    deviceSelectionOptions,
    pageTitle,
    shouldShowErelaxScheduler,
    shouldShowTliControlComponent,
    shouldShowDeviceSettingsComponent,
    systemId,
  };
};
