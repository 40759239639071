import React from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryScatter,
  VictoryChartProps,
} from '../../lib/victory';
import { Defs, LinearGradient, Stop } from '../../lib/svg';
import * as hooks from '../../../libs/hooks';
import { calculateFlowTemperature } from './HeatCurve.utils';

export interface HeatCurveChartProps extends VictoryChartProps {
  slope: number;
  minTemperature: number;
  maxTemperature?: number;
  mainColor: string;
  fontFamily?: string;
  axisLabelSize: number;
  scatterSize: number;
}

const setPointTemperature = 21;
const outsideTemperatures = [20, 10, 0, -10, -20];

export const HeatCurveChart: React.FC<React.PropsWithChildren<HeatCurveChartProps>> = ({
  slope,
  minTemperature,
  fontFamily,
  axisLabelSize,
  scatterSize,
  mainColor,
  ...rest
}) => {
  const data = outsideTemperatures.map((temp) => ({
    x: temp,
    y: calculateFlowTemperature({
      outsideTemperature: temp,
      setPointTemperature,
      heatCurve: slope,
      minTemp: minTemperature,
    }),
  }));

  const t = hooks.useTranslation();

  const axisLabelStyle = {
    fill: '#919191',
    fontFamily: fontFamily,
    fontSize: axisLabelSize,
  };

  return (
    <VictoryChart domainPadding={{ x: [0, 0], y: [0, 0] }} {...rest}>
      <Defs>
        <LinearGradient id='area' x1='0%' y1='100%' x2='0%' y2='0%' gradientUnits='userSpaceOnUse'>
          <Stop offset='20%' stopColor='white' stopOpacity='0' />
          <Stop offset='100%' stopColor={mainColor} />
        </LinearGradient>
        <LinearGradient id='myGradient' x1='0%' y1='100%' x2='0%' y2='0%' gradientUnits='userSpaceOnUse'>
          <Stop offset='5%' stopColor='white' stopOpacity='0' />
          <Stop offset='100%' stopColor='grey' />
        </LinearGradient>
      </Defs>
      <VictoryLabel
        textAnchor='end'
        verticalAnchor='start'
        dy={'0'}
        dx={'-20%'}
        style={axisLabelStyle}
        angle={-90}
        text={t('SG_HEAT_CURVE_FLOW_TEMPERATURE').toUpperCase()}
      />
      <VictoryArea
        interpolation={'natural'}
        style={{ data: { fill: `url(#area)`, stroke: mainColor, strokeWidth: 1 } }}
        data={data}
      />
      <VictoryAxis
        offsetY={60}
        style={{
          axis: { stroke: 'none' },
          tickLabels: { fill: '#919191', fontFamily: fontFamily, fontSize: 11 },
          grid: { stroke: 'url(#myGradient)', strokeWidth: 0.5 },
          axisLabel: { ...axisLabelStyle, padding: 45 },
        }}
        tickValues={[20, 10, 0, -10, -20]}
        tickFormat={(t) => `${t > 0 ? '+' : ''}${t} °C`}
        invertAxis={true}
        label={t('SG_HEAT_CURVE_OUTSIDE_TEMPERATURE').toUpperCase()}
      />

      <VictoryScatter
        x='x'
        y='y'
        data={data}
        size={scatterSize}
        style={{ data: { fill: mainColor } }}
        labels={({ datum }) => `${datum.y} °C`}
        labelComponent={<VictoryLabel dy={6} style={{ fill: '#fff', fontFamily: fontFamily, fontSize: '10' }} />}
      />
    </VictoryChart>
  );
};
