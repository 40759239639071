import React from 'react';
import camelCase from 'lodash/camelCase';
import { Box } from '@mui/material';
import { config } from '@vaillant-professional-ui/pui-frontend-common';
import { NavigationBarAnonymousContainer, NavigationBarContainer } from '../NavigationBar';
import * as images from '../../assets/images';
import { ContentBox } from './Layout.styles';
import { LayoutProps } from './Layout.types';
import { Footer } from './Footer';
import { FooterAnonymous } from './FooterAnonymous';
import { DemoDisclaimer } from './DemoDisclaimer';

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  canViewSidebar,
  country,
  isAuthenticated,
  isDemo,
  children,
}) => {
  const appConfig = config.useAppConfig();
  const isAccessedFromMobileApp = window.document.location.search.indexOf('mobileaccess') !== -1;

  const imageName = `${camelCase(appConfig.distribution.brand.key)}Logo${country?.toUpperCase()}`;
  // @ts-ignore
  const image = images[imageName];

  return (
    <>
      <DemoDisclaimer isDemo={isDemo} />
      <Box
        display='flex'
        flexDirection='column'
        flexWrap='nowrap'
        justifyContent='stretch'
        alignItems='stretch'
        flexGrow={1}
        id='content-box'
        bgcolor={isAuthenticated ? 'grey.200' : 'common.white'}
      >
        {!isAccessedFromMobileApp && (
          <Box>{isAuthenticated ? <NavigationBarContainer /> : <NavigationBarAnonymousContainer />}</Box>
        )}
        <ContentBox
          flexGrow={1}
          display='flex'
          alignItems='stretch'
          justifyContent='stretch'
          $isAuthenticated={isAuthenticated}
          $indentContent={canViewSidebar}
        >
          {children}
        </ContentBox>

        <Box>
          {isAuthenticated ? (
            <Footer country={country || 'GB'} imgSrcLogo={image} />
          ) : (
            !isAccessedFromMobileApp && <FooterAnonymous country={country || 'GB'} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
