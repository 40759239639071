import { Box } from '@mui/material';
import { styled, TabContainer as TabContainerBase } from '@vaillant-professional-ui/component-libs-web';

export const ItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ItemLabel = styled('div')`
  flex: 2;
`;

export const ItemValue = styled('div')`
  flex: 1;
  text-align: right;
`;

export const TabContainer = styled(TabContainerBase)`
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  margin-right: ${({ theme }) => theme.spacing(10)};
`;

export const Row = styled(Box)`
  border-bottom: 1px ${({ theme }) => theme.palette.grey['100']} solid;
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
