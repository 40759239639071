import { AxiosRequestConfig } from 'axios';
import {
  HeatCurvePayload,
  SetBackTemperaturePayload,
  TargetTemperatureDhwPayload,
  MinFlowTargetTemperaturePayload,
  OperationModeDhwPayload,
  OperationModeHeatingPayload,
  SetBackTemperatureVariables,
  OperationModeHeatingVariables,
  OperationModeDhwVariables,
  HeatCurveVariables,
  MinFlowTargetTemperatureVariables,
  TargetTemperatureDhwVariables,
} from './settings.types';

export const settingsApi = {
  patchSetBackTemperature: (
    gatewaySerialNumber: string,
    data: SetBackTemperatureVariables,
  ): AxiosRequestConfig<SetBackTemperaturePayload> => ({
    method: 'patch',
    url: `/pui/api-gateway/v1/systems/${gatewaySerialNumber}/settings-v2/zones/${data.zoneIndex}/setback-temperature`,
    data: { setbackTemperature: data.setbackTemperature },
  }),

  patchOperationModeHeating: (
    gatewaySerialNumber: string,
    data: OperationModeHeatingVariables,
  ): AxiosRequestConfig<OperationModeHeatingPayload> => ({
    method: 'patch',
    url: `/pui/api-gateway/v1/systems/${gatewaySerialNumber}/settings-v2/heating/zones/${data.zoneIndex}/operation-mode`,
    data: { operationMode: data.operationMode },
  }),

  patchOperationModeDhw: (
    gatewaySerialNumber: string,
    data: OperationModeDhwVariables,
  ): AxiosRequestConfig<OperationModeDhwPayload> => ({
    method: 'patch',
    url: `/pui/api-gateway/v1/systems/${gatewaySerialNumber}/settings-v2/domestic-hot-water/operation-mode`,
    data,
  }),

  patchHeatCurve: (gatewaySerialNumber: string, data: HeatCurveVariables): AxiosRequestConfig<HeatCurvePayload> => ({
    method: 'patch',
    url: `pui/api-gateway/v1/systems/${gatewaySerialNumber}/settings-v2/circuits/${data.circuitIndex}/heat-curve`,
    data: { heatCurve: data.heatCurve },
  }),

  patchMinFlowTemperature: (
    gatewaySerialNumber: string,
    data: MinFlowTargetTemperatureVariables,
  ): AxiosRequestConfig<MinFlowTargetTemperaturePayload> => ({
    method: 'patch',
    url: `pui/api-gateway/v1/systems/${gatewaySerialNumber}/settings-v2/circuits/${data.circuitIndex}/flow-target-temperature`,
    data: { minFlowTargetTemperature: data.minFlowTargetTemperature },
  }),

  patchTargetTemperatureDhw: (
    gatewaySerialNumber: string,
    data: TargetTemperatureDhwVariables,
  ): AxiosRequestConfig<TargetTemperatureDhwPayload> => ({
    method: 'patch',
    url: `/pui/api-gateway/v1/systems/${gatewaySerialNumber}/settings-v2/domestic-hot-water/target-temperature`,
    data,
  }),
};
