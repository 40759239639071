import { Box, List, ListItem, Toolbar } from '@mui/material';
import { styled } from '@vaillant-professional-ui/component-libs-web';

export const Container = styled(Box)`
  height: calc(100% - 64px);
`;

export const DrawerHeader = styled(Toolbar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 72px;
`;

export const Content = styled(Box)`
  overflow: auto;
  height: inherit;
  box-sizing: border-box;
`;

export const MenuList = styled(List)`
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing(8)};
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const DemoListItem = styled(ListItem)`
  padding-left: ${({ theme }) => theme.spacing(10)};
`;

export const Divider = styled('div')`
  height: ${({ theme }) => theme.spacing(8)};
`;
