import { nonNullable } from '../../../util';
import { DeviceDetailsResponseTli } from '../../apis/maintenance/v2';
import { HeatingSchedule, SchedulePayload, TliSchedulePayload } from '../../apis/schedules/v2';
import { UpdateFnc } from '../../hooks';
import {
  TliDhwTimeWindow,
  TliHeatingTimeWindow,
  TliScheduleDay,
  TliSchedulerPresentationData,
} from './TliScheduler.types';

type WeekdayShort = keyof typeof shortToLongWeekday;
type WeekdayLong = typeof shortToLongWeekday[keyof typeof shortToLongWeekday];

const shortToLongWeekday = {
  mon: 'monday',
  tue: 'tuesday',
  wed: 'wednesday',
  thu: 'thursday',
  fri: 'friday',
  sat: 'saturday',
  sun: 'sunday',
} as const;

export const longToShortWeekday = Object.fromEntries(
  Object.entries(shortToLongWeekday).map(([key, value]) => [value, key]),
);

export const updateSchedules: UpdateFnc<DeviceDetailsResponseTli, SchedulePayload> = (payloadArray, previousValue) => {
  const payload = payloadArray[0] as TliSchedulePayload[0];

  const getMeasuredValue = (value: number) => ({
    value,
    measured_at: new Date().toISOString(),
    unit: 'MINUTES',
    data_type: 'number',
  });
  const previousDhwSchedule = previousValue.data.domestic_hot_water?.[0];
  const newDhwSchedule =
    payload.domesticHotWater && previousDhwSchedule?.schedule
      ? {
          ...previousDhwSchedule,
          schedule: Object.fromEntries(
            Object.entries(previousDhwSchedule.schedule).map(([key, value]) => {
              const weekday = longToShortWeekday[key] as keyof typeof shortToLongWeekday;
              const schedulePayload = payload.domesticHotWater?.hotWaterSchedule?.[weekday];

              if (weekday && schedulePayload) {
                const newValue = schedulePayload.map((activation) => ({
                  start: getMeasuredValue(activation.start),
                  end: getMeasuredValue(activation.end),
                }));
                return [key, newValue];
              } else {
                return [key, value];
              }
            }),
          ),
        }
      : previousDhwSchedule;

  const previousZones = previousValue.data.zones;
  const newZones = payload.centralHeating
    ? previousZones?.map((previousZone) => {
        const newZone = payload.centralHeating?.heatingSchedules?.zones?.find(
          (newZone) => newZone.index === previousZone.index,
        );

        if (!newZone) {
          return previousZone;
        }

        return {
          ...previousZone,
          heating: {
            ...previousZone.heating,
            schedule: {
              config: previousZone.heating?.schedule?.config,
              ...Object.fromEntries(
                Object.entries(newZone)
                  .map(([key, value]) =>
                    shortToLongWeekday[key as WeekdayShort] && typeof value !== 'number'
                      ? [
                          shortToLongWeekday[key as WeekdayShort],
                          value.map((timeslot, index) => {
                            const existingTimeslot =
                              previousZone.heating?.schedule?.[
                                shortToLongWeekday[key as WeekdayShort] as WeekdayLong
                              ]?.[index];
                            return {
                              start: {
                                ...(existingTimeslot?.start ?? {}),
                                value: timeslot.start,
                              },
                              end: {
                                ...(existingTimeslot?.end ?? {}),
                                value: timeslot.end,
                              },
                              set_point: {
                                ...(existingTimeslot?.set_point ?? {}),
                                value: timeslot.targetRoomTemperature,
                              },
                            };
                          }),
                        ]
                      : null,
                  )
                  .filter(nonNullable),
              ),
            },
          },
        };
      })
    : previousZones;

  return {
    ...previousValue,
    data: {
      ...previousValue.data,
      zones: newZones,
      domestic_hot_water: newDhwSchedule ? [newDhwSchedule] : previousValue.data?.domestic_hot_water,
    },
  };
};

export const tliScheduleDayToHeatingSchedulePayload = (
  controlSerialNumber: string,
  patchData: TliScheduleDay<TliHeatingTimeWindow>[],
  presentationData: TliSchedulerPresentationData,
  zoneIndex: number,
): TliSchedulePayload => {
  const currentSchedule = presentationData.zoneSchedules;
  const zones = currentSchedule.map((existingSchedule) => {
    const dataset = existingSchedule.index === zoneIndex ? patchData : existingSchedule.schedule;
    const grouped = dataset.reduce((acc, item) => {
      return {
        ...acc,
        [longToShortWeekday[item.key]]: item.values.map((value) => ({
          start: value.start,
          end: value.end,
          targetRoomTemperature: value.temperatureTarget,
        })),
      };
    }, {} as HeatingSchedule);

    return {
      index: existingSchedule.index,
      ...grouped,
    };
  });

  return [
    {
      serialNumber: controlSerialNumber,
      type: 'CONTROL/VRT-380',
      centralHeating: { heatingSchedules: { zones } },
    },
  ];
};

export const tliScheduleDayToDHWSchedulePayload = (
  controlSerialNumber: string,
  patchData: TliScheduleDay<TliDhwTimeWindow>[],
): TliSchedulePayload => {
  const dhwSchedule = Object.fromEntries(
    patchData.map((scheduleDay) => [longToShortWeekday[scheduleDay.key], scheduleDay.values]),
  );

  return [
    {
      serialNumber: controlSerialNumber,
      type: 'CONTROL/VRT-380',
      domesticHotWater: { hotWaterSchedule: dhwSchedule },
    },
  ];
};
