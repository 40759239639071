import * as React from 'react';
import { styled } from '../../../util/styling';

export interface BigTabsPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  hasPadding: boolean;
}

const Container = styled('div')<{ hasPadding: boolean }>`
  ${({ hasPadding, theme }) =>
    hasPadding
      ? `
	padding-top: ${theme.spacing(5)};
	padding-right: ${theme.spacing(12)};
	padding-bottom: ${theme.spacing(5)};
	padding-left: ${theme.spacing(12)};
	`
      : ''}
`;

export const BigTabsPanel: React.FC<React.PropsWithChildren<BigTabsPanelProps>> = ({
  children,
  hasPadding = true,
  ...rest
}) => {
  return (
    <Container hasPadding={hasPadding} {...rest}>
      {children}
    </Container>
  );
};
