import identity from 'lodash/identity';
import { Country } from '../../../typings/models';
import { CountryRules } from '../../../libs/permissions';

export const isFeatureAvailableInCountry = (feature: string, country?: Country, countryRules?: CountryRules) => {
  if (!country || !countryRules || !(feature in countryRules)) {
    return true;
  }
  return countryRules[feature].includes(country);
};

export const areFeaturesAvailableInCountry = (features: string[], country?: Country, countryRules?: CountryRules) =>
  features.map((feature) => isFeatureAvailableInCountry(feature, country, countryRules)).every(identity);
