import * as React from 'react';
import MuiButton from '@mui/material/Button';
import { Translated, useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { ButtonProps } from './Button.types';
import { Label } from './Button.styles';

const ButtonComponent: React.FC<React.PropsWithChildren<ButtonProps>> = ({ children, testIdKey, ...props }) => {
  const testId = useInstanceId('button', testIdKey);
  return (
    <MuiButton {...props} {...testId().testIdAttributes}>
      {typeof children === 'string' ? <Label>{children}</Label> : children}
    </MuiButton>
  );
};

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({ i18nKey, i18nOptions, ...rest }) => {
  return (
    <Translated
      i18nKey={i18nKey}
      i18nOptions={i18nOptions}
      component={<ButtonComponent disableElevation {...rest} />}
    />
  );
};
