import { AxiosResponse } from 'axios';
import { DateFormatter } from '../../../libs/hooks';
import { NumberDataPointMeta, TranslationFnc } from '../../../typings/models';
import { DataPoint, DiagnosticsData, DiagnosticsDeviceEntry, DiagnosticsResponse } from './diagnostics.types';
import pick from 'lodash/pick';

const transformTimestamp = (metadata: NumberDataPointMeta) => {
  if (metadata?.timestamp) {
    const ts = metadata.timestamp * 1000;
    const timestamp = new Date(ts);
    return { ...metadata, timestamp };
  }
  return metadata;
};

// TODO: add typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const merge = (data: object, _metadata: any): any => {
  return Object.fromEntries(
    Object.entries(data).map(([k, v]) => {
      if (['serialNumber', 'type'].includes(k)) {
        return [k, v];
      }
      const metadata: NumberDataPointMeta = _metadata[k]
        ? _metadata[k]
        : // Dirty hack to fix naming inconsistencies in diagnostics v2 response
          _metadata[
            k
              .replace(/^radio$/, 'radioStrength')
              .replace(/^wifi/, 'wifiStrength')
              .replace(/^powerLevel/, 'powerlevel')
          ];
      if (v === null || v === undefined) {
        return [k, { value: null }];
      }
      if (typeof v === 'object') {
        return [k, merge(v, metadata)];
      }
      return [k, { value: v, ...transformTimestamp(metadata) }];
    }),
  );
};

const mergeValuesWithMetadata = (entry: DiagnosticsDeviceEntry) => {
  const { _metadata, ...data } = entry;
  return merge(data, _metadata);
};

export const transformResponse = (response: DiagnosticsResponse) => {
  return Object.fromEntries(
    (response || []).map(mergeValuesWithMetadata).map((entry) => [entry.type.toLowerCase(), entry]),
  );
};

export const createDataPoints = (
  // TODO: add typing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  diagnosticsData: any,
  t: TranslationFnc,
  df: DateFormatter,
): DiagnosticsData['dataPoints'] => {
  const heatGenerator = { ...diagnosticsData?.boiler, ...diagnosticsData?.heat_pump };
  const gateway = diagnosticsData?.gateway;
  const control = diagnosticsData?.control;

  const map = {
    averageIgnitionTime: { label: 'SG_MONITORING_IGTIM', value: heatGenerator?.averageIgnitionTime },
    centralHeatingBlocked: {
      label: 'SG_MONITORING_HTBLK',
      value: heatGenerator?.centralHeating?.blocked,
      valueI18n: {
        true: 'SG_MONITORING_HTBLK_TRUE',
        false: 'SG_MONITORING_HTBLK_FALSE',
      },
    },
    centralHeatingBurnerStarts: {
      label: 'SG_MONITORING_BURNER_STARTS',
      value: heatGenerator?.centralHeating?.burnerStarts,
    },
    centralHeatingFlowTemperature: {
      label: 'SG_MONITORING_FLTMP',
      icon: '01600-vg-temperature01',
      value: heatGenerator?.centralHeating?.temperatures?.flowTemperature,
    },
    centralHeatingOperatingHours: {
      label: 'SG_MONITORING_HTOPS',
      value: heatGenerator?.centralHeating?.operatingHours,
    },
    centralHeatingPowerOutput: {
      label: 'SG_MONITORING_POWER_OUTPUT',
      value: heatGenerator?.centralHeating?.powerOutput,
    },
    centralHeatingReturnTemperature: {
      label: 'SG_MONITORING_RETTMP',
      icon: '01600-vg-temperature01',
      value: heatGenerator?.centralHeating?.temperatures?.returnTemperature,
    },
    centralHeatingWaterPressure: {
      label: 'SG_MONITORING_WATPR',
      icon: '08302-vg-pressure01',
      value: heatGenerator?.centralHeating?.waterPressure,
    },
    domesticHotWaterBurnerStarts: {
      label: 'SG_MONITORING_BURNER_STARTS',
      value: heatGenerator?.domesticHotWater?.burnerStarts,
    },
    domesticHotWaterDemand: {
      label: 'SG_MONITORING_DEMAND',
      value: heatGenerator?.domesticHotWater?.demand,
      valueI18n: {
        true: 'SG_MONITORING_HTDEM_TRUE',
        false: 'SG_MONITORING_HTDEM_FALSE',
      },
    },
    domesticHotWaterOperatingHours: {
      label: 'SG_MONITORING_HWOPS',
      value: heatGenerator?.domesticHotWater?.operatingHours,
    },
    domesticHotWaterPowerOutput: {
      label: 'SG_MONITORING_POWER_OUTPUT',
      value: heatGenerator?.domesticHotWater?.powerOutput,
    },
    generatorStatus: { label: 'SG_MONITORING_GENSTAT', value: heatGenerator?.generatorStatus },
    heatingDemand: {
      label: 'SG_MONITORING_HTDEM',
      value: heatGenerator?.heatingDemand,
      valueI18n: {
        true: 'SG_MONITORING_HTDEM_TRUE',
        false: 'SG_MONITORING_HTDEM_FALSE',
      },
    },
    hoursTillService: { label: 'SG_MONITORING_SERVICE', value: heatGenerator?.hoursTillService },
    modulationLevelPercent: {
      label: 'SG_MONITORING_MODLVL',
      icon: '54900-vg-field-intensity-gsm01',
      value: heatGenerator?.modulationLevel,
    },
    outdoorTemperature: { label: 'SG_MONTORING_OUTDOOR_TEMPERATURE', value: control?.outdoorTemperature },
    radioPercentage: { label: 'SG_MONITORING_RDSIG', value: gateway?.radioPercentage },
    threeWayValve: {
      label: 'SG_MONITORING_VALVE',
      value: heatGenerator?.threeWayValve,
      valueI18n: {
        DOMESTIC_HOT_WATER: 'SG_MONITORING_VALVE_DOM',
        MIDDLE_POSITION: 'SG_MONITORING_VALVE_MID',
        CENTRAL_HEATING: 'SG_MONITORING_VALVE_CTRL',
      },
    },
  };

  const heatPumpDataPointKeys = [
    'centralHeatingBlocked',
    'centralHeatingFlowTemperature',
    'centralHeatingOperatingHours',
    'centralHeatingPowerOutput',
    'centralHeatingReturnTemperature',
    'centralHeatingWaterPressure',
    'domesticHotWaterDemand',
    'domesticHotWaterOperatingHours',
    'domesticHotWaterPowerOutput',
    'generatorStatus',
    'heatingDemand',
    'hoursTillService',
    'outdoorTemperature',
    'radioPercentage',
    'threeWayValve',
  ];

  const boilerDataPointKeys = [
    ...heatPumpDataPointKeys,
    'averageIgnitionTime',
    'modulationLevelPercent',
    'centralHeatingBurnerStarts',
    'domesticHotWaterBurnerStarts',
  ];

  const isHeatPumpSystem = !!diagnosticsData?.heat_pump && !diagnosticsData?.boiler;
  const deviceMap = pick(map, isHeatPumpSystem ? heatPumpDataPointKeys : boilerDataPointKeys);

  const getValue = (
    rawValue: string | number | boolean | undefined,
    unit = '',
    i18nValues?: Record<string, string>,
  ) => {
    const value = typeof rawValue === 'number' && !Number.isInteger(rawValue) ? rawValue.toFixed(1) : rawValue;

    if (value === undefined || (!unit && typeof value === 'number')) {
      return value;
    }

    const i18nValueKey = i18nValues?.[value.toString()];
    if (i18nValueKey) {
      return `${t(i18nValueKey)} ${unit}`.trim();
    }
    return `${value} ${unit}`.trim();
  };

  return Object.entries(deviceMap).map(([key, mapEntry]) => {
    const valueI18n = 'valueI18n' in mapEntry ? mapEntry.valueI18n : undefined;
    return [
      key,
      {
        ...mapEntry,
        readable: {
          label: t(mapEntry.label),
          value: getValue(mapEntry.value?.value, mapEntry.value?.unit, valueI18n),
          timestamp: mapEntry.value?.timestamp ? df(mapEntry.value.timestamp) ?? undefined : undefined,
        },
      },
    ] as DataPoint;
  });
};

export const selectDiagnosticsResponse =
  (df: DateFormatter, t: TranslationFnc) =>
  (result: AxiosResponse<DiagnosticsResponse>): DiagnosticsData => {
    let transformedData = transformResponse(result.data);
    transformedData = Object.fromEntries(Object.entries(transformedData).map(([k, v]) => [k.split('/')[0], v]));

    return {
      data: transformedData,
      dataPoints: createDataPoints(transformedData, t, df),
    };
  };
