import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Skeleton } from '@mui/material';
import { useInstanceId } from '@vaillant-professional-ui/component-libs-common';
import { Typography } from '../../atoms/Typography';
import { DataPointCardItemSkeleton } from '../../molecules/DataPointCardItem';
import { HeroDataPointCardItemSkeletonProps } from './HeroDataPointCardItem.types';

export const HeroDataPointCardItemSkeleton: React.FunctionComponent<HeroDataPointCardItemSkeletonProps> = ({
  testIdKey,
  ...rest
}) => {
  const testId = useInstanceId('herodatapointcard-skeleton', testIdKey);

  return (
    <Card {...rest} {...testId('root').testIdAttributes}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        p={[0, 8, 8, 6]}
        pt={[6, 8, 8, 8, 12]}
        gap={[8, 8, 8, 10]}
      >
        <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
          <Skeleton variant='text' animation='wave' width={200}>
            <Typography variant='hero' bold>
              Loading…
            </Typography>
          </Skeleton>

          <Skeleton variant='text' animation='wave' width={300}>
            <Typography variant='large'>Loading…</Typography>
          </Skeleton>
        </Box>
        <Box display='flex' gap={1} justifyContent='stretch' width={300}>
          <DataPointCardItemSkeleton />
          <DataPointCardItemSkeleton />
        </Box>
      </Box>
    </Card>
  );
};
