import { AuthStatus, AuthStatusDelegated, AuthStatusIdm, TokenData } from './auth.types';

export const getAuthenticationStatus = (
  authenticationSource: AuthStatus['authenticationSource'],
  token?: string | null | TokenData,
): AuthStatus => {
  if (!authenticationSource) {
    return {
      authenticationSource: null,
      isAuthenticated: false,
      roles: ['ANONYMOUS'],
    };
  }

  if (authenticationSource === 'idm') {
    return {
      authenticationSource: 'idm',
      isAuthenticated: true,
      roles: ['AUTHENTICATED', 'LOGIN_REGULAR'],
      token: token as TokenData,
    };
  }

  if (authenticationSource === 'delegated') {
    return {
      authenticationSource: 'delegated',
      isAuthenticated: true,
      roles: ['AUTHENTICATED', 'LOGIN_DELEGATED'],
      token: token as string,
    };
  }

  if (authenticationSource === 'demo') {
    return {
      authenticationSource: 'demo',
      isAuthenticated: true,
      roles: ['AUTHENTICATED', 'LOGIN_DEMO'],
    };
  }

  // Unknown authentication source: fallback to demo
  return {
    authenticationSource: 'demo',
    isAuthenticated: true,
    roles: ['AUTHENTICATED', 'LOGIN_DEMO'],
  };
};

export const getAccessToken = (status: AuthStatus) => {
  switch (status.authenticationSource) {
    case 'idm':
      return (status as AuthStatusIdm).token?.accessToken ?? null;

    case 'delegated':
      return (status as AuthStatusDelegated).token;

    default:
      return null;
  }
};
