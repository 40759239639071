import {
  BooleanMeasuredValue,
  DeviceSettingsResponse,
  NumberMeasuredValue,
  StringMeasuredValue,
} from '../../../../../api/apis/maintenance/v2';
import { components } from '../../../../../api/apis/maintenance/v2/autogenerated-schema';
import { DeviceSettingsSettingValue } from '../../../../../api/apis/maintenance/v2/maintenance.types';
import { TranslationFnc } from '../../../../../typings/models';
import { nonNullable } from '../../../../../util';
import { translateStringMeasuredValueToTitle } from '../i18n/stringMeasuredValueTitle';
import { createCategory } from '../model/category';
import { BaseWriteParams, DataPoint } from '../model/dataPoint';

const linkTypes: DeviceSettingsSettingValue['type'][] = ['weekly_planner'];

const mapDatapoint = (srcDatapoint: DeviceSettingsSettingValue, t: TranslationFnc): DataPoint | null => {
  const baseDataPoint = {
    id: srcDatapoint.id as string, // we know it is always set to an uniq value by the backend
    title: srcDatapoint.title as string, // same here
  };

  if (srcDatapoint.type && linkTypes.includes(srcDatapoint.type.toLowerCase() as DeviceSettingsSettingValue['type'])) {
    // TODO: This should not be an optional attribute -> Fix in backend
    if (!srcDatapoint.metadata_value) {
      return null;
    }
    const metadata = {
      index: srcDatapoint.metadata_value.index,
      section: srcDatapoint.metadata_value.section,
      // TODO: backend schema says device_type is string but it should be the enum, ask backend to fix
      deviceType: srcDatapoint.metadata_value.device_type as components['schemas']['DefaultDevice']['device_type'],
    };
    return {
      type: 'link',
      metadata,
      ...baseDataPoint,
    };
  }

  const srcDataType = srcDatapoint.measured_value?.data_type;
  if (!srcDataType) {
    return null;
  }

  const action = srcDatapoint._actions?.[0] ?? null;

  if (srcDataType === 'string') {
    const measuredValue = srcDatapoint.measured_value as StringMeasuredValue;
    let writeParams;
    if (action?.schema?.properties) {
      const [actionPropertyKey, actionProperty] = Object.entries(action.schema?.properties)[0];
      writeParams = {
        url: action.href as string,
        method: action.method as BaseWriteParams['method'],
        initialValue: measuredValue.value as string,
        possibleValues: (actionProperty.enum ?? []).map((v) => ({
          value: v,
          title: translateStringMeasuredValueToTitle(srcDatapoint, v, t),
        })),
        actionPropertyKey,
      };
    }
    return {
      type: 'enum',
      measuredValue: srcDatapoint.measured_value as StringMeasuredValue,
      writeParams,
      mutationId: writeParams ? baseDataPoint.id : undefined,
      ...baseDataPoint,
    };
  }

  if (srcDataType === 'number') {
    const measuredValue = srcDatapoint.measured_value as NumberMeasuredValue;
    let writeParams;
    if (action?.schema?.properties) {
      const [actionPropertyKey, actionProperty] = Object.entries(action.schema?.properties)[0];
      writeParams = {
        url: action.href as string,
        method: action.method as BaseWriteParams['method'],
        initialValue: measuredValue.value as number,
        minimum: actionProperty.minimum as number,
        maximum: actionProperty.maximum as number,
        stepSize: actionProperty.multipleOf as number,
        actionPropertyKey,
      };
    }
    return {
      type: 'numeric',
      measuredValue: measuredValue,
      writeParams,
      mutationId: writeParams ? baseDataPoint.id : undefined,
      ...baseDataPoint,
    };
  }

  if (srcDataType === 'boolean') {
    const measuredValue = srcDatapoint.measured_value as BooleanMeasuredValue;
    let writeParams;
    if (action?.schema?.properties) {
      const [actionPropertyKey] = Object.entries(action.schema?.properties)[0];
      writeParams = {
        url: action.href as string,
        method: action.method as BaseWriteParams['method'],
        initialValue: measuredValue.value as boolean,
        actionPropertyKey,
      };
    }
    return {
      type: 'boolean',
      measuredValue,
      writeParams,
      mutationId: writeParams ? baseDataPoint.id : undefined,
      ...baseDataPoint,
    };
  }

  if (srcDataType === 'temporal') {
    return null;
  }

  return null;
};

export const mapResponseCategoriesToDataModel = (
  categories: NonNullable<DeviceSettingsResponse['categories']>,
  t: TranslationFnc,
) => {
  const result = categories.map((cat) => {
    const dataPoints = (cat.values ?? [])
      .map((entry) => {
        return mapDatapoint(entry, t);
      })
      .filter(nonNullable);

    const category = createCategory(cat.title ?? '', dataPoints);

    // @ts-ignore: TODO: API spec not up to date
    if (cat.subcategories) {
      // @ts-ignore: TODO: API spec not up to date
      const subcategories = mapResponseCategoriesToDataModel(cat.subcategories, t);
      category.values = category.values.concat(subcategories);
    }
    return category;
  });
  return result;
};
